const devHost = "realfast.ru";
const hostname = window.location.hostname;
export const IS_VERBOSE =
  hostname === "localhost" || hostname.endsWith(devHost);

export enum SearchParamKey {
  ClientId = "cid",
  AgentId = "aid",
  HallId = "hid",
  CashierId = "csid",
  KioskId = "kid",
  PlayerId = "pid",
  ProviderId = "prid",
  PayerAgentId = "paid",
  RecipientAgentId = "raid",
  RecipientHallId = "rhid",
  GameId = "gid",
  Currency = "c",
  DateFrom = "df",
  DateTo = "dt",
  TimeZone = "tz",
  SearchQuery = "sq",
}

export const JACKPOT_ID = 3;

export enum ERROR_CODES {
  ACCESS_DENIED = -32002,
  NOT_AUTHORIZED = -32001,
  PANIC_ENABLED = -32003,
}
