import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { CashierLimitEdited, EditCashierLimit } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

type Props = {};

export const Modal: FC<Props> = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Edit cashier's limit" })}
      openEvent={EditCashierLimit}
      closeEvent={CashierLimitEdited}
    >
      {({ cashierId }) => <Form cashierId={cashierId} />}
    </EventBasedModal>
  );
});

Modal.displayName = "EditLimit.Modal";
