import { Select } from "antd";
import { RefSelectProps } from "antd/lib/select";
import { observer } from "mobx-react-lite";
import { CSSProperties, forwardRef, useMemo } from "react";
import { useIntl } from "react-intl";

import { useGlobalStore } from "@/stores";

type TProps = {
  value?: string;
  onChange?: (value?: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
};

function CurrencySelector(
  { value, onChange, style, disabled }: TProps,
  ref: React.Ref<RefSelectProps>,
) {
  const { currencyStore } = useGlobalStore();
  const options = useMemo(
    () =>
      currencyStore.currencies.map((currency) => ({
        label: currency.code,
        value: currency.code,
      })),
    [currencyStore.currencies],
  );
  const intl = useIntl();
  return (
    <Select
      ref={ref}
      placeholder={intl.formatMessage({ defaultMessage: "Select currency" })}
      showSearch
      showArrow
      allowClear
      value={value}
      onChange={onChange}
      options={options}
      style={style}
      disabled={disabled}
    />
  );
}

export default observer(forwardRef(CurrencySelector));
