import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { FormState } from "./FormState";
import { FormView } from "./FormView";

export type Props = {
  cashierId: number;
};

export const Form: FC<Props> = observer((props) => {
  const { eventBusService, intlService, userStore } = useGlobalStore();
  const vm = useViewModel(
    () => new FormState(eventBusService, intlService, userStore),
    props,
  );
  return <FormView state={vm} />;
});
