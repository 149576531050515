import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI, GameAPI, GamesOrderAPI } from "@/api";
import { GameOrderEdited } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService } from "@/services";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(private eventBusService: EventBusService) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _agentId?: number;

  private get agentId() {
    return this._agentId;
  }

  private set agentId(id: number | undefined) {
    this._agentId = id;
  }

  private _gameId?: number;

  private get gameId() {
    return this._gameId;
  }

  private set gameId(id: number | undefined) {
    this._gameId = id;
  }

  private _gameOrder?: number;

  public get gameOrder() {
    return this._gameOrder;
  }

  private set gameOrder(order: number | undefined) {
    this._gameOrder = order;
  }

  onViewMount = async ({ agentId, gameId, gameOrder }: Props) => {
    this.agentId = agentId;
    this.gameId = gameId;
    this.gameOrder = gameOrder ?? undefined;

    this.fetchTask.submit({ agentId, gameId });
  };

  onViewUnmount = () => {
    this.agentId = undefined;
  };

  fetchTask = new Query(
    async ({ agentId, gameId }: Pick<Props, "agentId" | "gameId">) => {
      const agent = await AgentAPI.getById({ id: agentId });
      const game = await GameAPI.getById({ id: gameId });
      return { agent, game };
    },
  );

  submitTask = new Query(async ({ order }: ISchema) => {
    if (!this.agentId || !this.gameId) {
      return;
    }

    await GamesOrderAPI.update({
      agentId: this.agentId,
      gameId: this.gameId,
      gameOrder: order ?? null,
    });

    notification.success({
      message: "",
      description: "The game order has been successfully updated.",
    });

    const event = new GameOrderEdited({
      agentId: this.agentId,
      gameId: this.gameId,
    });
    this.eventBusService.publish(event);
  });
}
