import { observer } from "mobx-react-lite";

import { useHallOtherSettingsState } from "./hall-other-settings.state";
import { HallOtherSettingsView } from "./hall-other-settings.view";

export interface HallOtherSettingsProps {
  hallId: number;
}

export const HallOtherSettings = observer((props: HallOtherSettingsProps) => {
  const state = useHallOtherSettingsState(props);
  return <HallOtherSettingsView state={state} />;
});
