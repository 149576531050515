import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Row, Space, Table, Tabs, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { UseFormReturn } from "react-hook-form";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import {
  FormCheckbox,
  FormInput,
  FormItem,
  FormSelect,
  FormSwitch,
} from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { DailyLoginRewardsFormSchema } from "./form.schema";
import { DailyLoginRewardsFormState } from "./form.state";

type Props = { state: DailyLoginRewardsFormState };

export const DailyLoginRewardsFormView = observer<Props>(({ state }) => {
  if (state.query.isIdle || state.query.isPending) {
    return <FormSpinner />;
  }

  if (state.query.isRejected) {
    return <ErrorsFormatter queries={[state.query]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.mutation]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={state.handleSubmit}
      >
        <FormItem
          form={state.form}
          path="isActive"
          label={state.intl.formatMessage({ defaultMessage: "Enabled" })}
        >
          <FormSwitch form={state.form} path="isActive" />
        </FormItem>
        <FormItem
          form={state.form}
          path="cooldown"
          label={state.intl.formatMessage({
            defaultMessage: "Cooldown (hours)",
          })}
        >
          <FormInput
            form={state.form}
            path="cooldown"
            placeholder={state.intl.formatMessage({
              defaultMessage: "Enter cooldown",
            })}
          />
        </FormItem>
        <Tabs
          className="daily-login-rewards-tabs"
          items={[
            {
              key: "0",
              label: state.intl.formatMessage({ defaultMessage: "None" }),
              children: <DailySettings state={state} category={"0"} />,
            },
            {
              key: "1",
              label: "1",
              children: <DailySettings state={state} category={"1"} />,
            },
            {
              key: "2",
              label: "2",
              children: <DailySettings state={state} category={"2"} />,
            },
            {
              key: "3",
              label: "3",
              children: <DailySettings state={state} category={"3"} />,
            },
            {
              key: "4",
              label: "4",
              children: <DailySettings state={state} category={"4"} />,
            },
            {
              key: "5",
              label: "5",
              children: <DailySettings state={state} category={"5"} />,
            },
          ]}
        />
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={state.mutation.isPending}
          >
            {state.intl.formatMessage({ defaultMessage: "Save" })}
          </Button>
        </Row>
      </Form>
    </Space>
  );
});

type DailySettingsProps = {
  state: DailyLoginRewardsFormState;
  category: "0" | "1" | "2" | "3" | "4" | "5";
};

const DailySettings = observer<DailySettingsProps>(({ state, category }) => {
  const sequenceSettings =
    state.form.watch(`sequenceSettings.${category}`) ?? [];

  function addSequence() {
    const sequence = Math.min(
      Math.max(0, ...sequenceSettings.map((s) => s.sequence)) + 1,
      10,
    );
    state.form.setValue(`sequenceSettings.${category}`, [
      ...sequenceSettings,
      { isActive: false, sequence, reward: "", rewardType: "credits" },
    ]);
  }

  function removeSequence(index: number) {
    state.form.setValue(
      `sequenceSettings.${category}`,
      sequenceSettings.filter((_, i) => i !== index),
    );
  }

  return (
    <Space direction="vertical">
      <Table
        key={sequenceSettings.length}
        size="small"
        rowKey={(_, index) => category + "." + index}
        dataSource={sequenceSettings}
        columns={[
          {
            title: state.intl.formatMessage({ defaultMessage: "Enabled" }),
            dataIndex: "isActive",
            key: "isActive",
            render: (_0, _1, index) => (
              <FormCheckbox
                form={state.form}
                path={`sequenceSettings.${category}.${index}.isActive`}
              />
            ),
          },
          {
            title: state.intl.formatMessage({ defaultMessage: "Sequence" }),
            dataIndex: "sequence",
            key: "sequence",
            render: (_0, _1, index) => {
              return (
                <SequenceCell
                  form={state.form}
                  category={category}
                  index={index}
                />
              );
            },
          },
          {
            title: state.intl.formatMessage({ defaultMessage: "Name" }),
            dataIndex: "reward",
            key: "reward",
            render: (_0, _1, index) => {
              return (
                <NameCell form={state.form} category={category} index={index} />
              );
            },
          },
          {
            title: state.intl.formatMessage({ defaultMessage: "Type" }),
            dataIndex: "rewardType",
            key: "rewardType",
            render: (_0, _1, index) => (
              <FormSelect
                form={state.form}
                path={`sequenceSettings.${category}.${index}.rewardType`}
                options={[{ value: "credits", label: "Credits" }]}
                size="small"
              />
            ),
          },
          {
            title: state.intl.formatMessage({ defaultMessage: "Actions" }),
            key: "actions",
            align: "right",
            render: (_0, _1, index) => (
              <Row justify="end">
                {sequenceSettings.length > 1 ? (
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => removeSequence(index)}
                  />
                ) : (
                  "—"
                )}
              </Row>
            ),
          },
        ]}
        pagination={false}
      />
      <Row justify="end">
        <Button
          size="small"
          onClick={addSequence}
          disabled={sequenceSettings.length >= 10}
        >
          {state.intl.formatMessage({ defaultMessage: "Add" })}
        </Button>
      </Row>
    </Space>
  );
});

type SequenceCellProps = {
  form: UseFormReturn<DailyLoginRewardsFormSchema>;
  category: "0" | "1" | "2" | "3" | "4" | "5";
  index: number;
};

function SequenceCell({ form, category, index }: SequenceCellProps) {
  const error =
    form.formState.errors.sequenceSettings?.[category]?.[index]?.sequence
      ?.message ?? null;

  return (
    <Tooltip
      open={!!error}
      overlay={error}
      color="red"
      getTooltipContainer={(node) => node.closest(".ant-tabs-content-holder")!}
    >
      <FormInput
        form={form}
        path={`sequenceSettings.${category}.${index}.sequence`}
        size="small"
      />
    </Tooltip>
  );
}

function NameCell({ form, category, index }: SequenceCellProps) {
  const error =
    form.formState.errors.sequenceSettings?.[category]?.[index]?.reward
      ?.message ?? null;

  return (
    <Tooltip
      open={!!error}
      overlay={error}
      color="red"
      getTooltipContainer={(node) => node.closest(".ant-tabs-content-holder")!}
    >
      <FormInput
        form={form}
        path={`sequenceSettings.${category}.${index}.reward`}
        size="small"
      />
    </Tooltip>
  );
}
