import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space, Spin, Switch } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useResetForm } from "@/hooks";
import { useCashierUpdateSchema, TCashierUpdateSchema } from "@/schemas";
import { CashierUpdateStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Password from "@/ui/_common_/password";
import { generatePassword } from "@/utilities";

type TProps = {
  cashierUpdateStore: CashierUpdateStore;
};

function CashierUpdateForm({ cashierUpdateStore }: TProps) {
  const cashier = useMemo(
    () => cashierUpdateStore.cashierStore.cashier,
    [cashierUpdateStore.cashierStore.cashier],
  );
  const schema = useCashierUpdateSchema();
  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm<TCashierUpdateSchema>({
    resolver,
    defaultValues: {
      isShiftModeEnabled: cashier.isShiftModeEnabled,
    },
  });

  useResetForm(cashierUpdateStore.updateQuery, reset);

  const intl = useIntl();

  return (
    <Spin spinning={cashierUpdateStore.updateQuery.isPending}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[cashierUpdateStore.updateQuery]} />
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleSubmit(cashierUpdateStore.handleSubmit)}
        >
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Shop",
            })}
          >
            <Input
              value={
                cashierUpdateStore.hallSelectorStore.selectedItem?.name ?? "—"
              }
              disabled
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ defaultMessage: "Name" })}>
            <Input value={cashier.name} disabled />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Username",
            })}
          >
            <Input value={cashier.login} disabled />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Password" })}
            validateStatus={has(errors, "password") ? "error" : ""}
            help={errors.password?.message}
          >
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter password",
                  })}
                  onCopy={() => {
                    const password = getValues().password ?? "";
                    if (password) {
                      navigator.clipboard.writeText(password);
                    }
                  }}
                  onGenerate={() => setValue("password", generatePassword())}
                  autoComplete={`cashier-${cashier.login}-password`}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Shift mode",
            })}
            validateStatus={has(errors, "isShiftModeEnabled") ? "error" : ""}
            help={errors.isShiftModeEnabled?.message}
          >
            <Controller
              control={control}
              name="isShiftModeEnabled"
              render={({ field }) => (
                <Switch checked={field.value} onChange={field.onChange} />
              )}
            />
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({ defaultMessage: "Save" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
}

export default observer(CashierUpdateForm);
