import { observer } from "mobx-react-lite";

import { useNewCategoryFormState } from "./form.state";
import { NewCategoryFormView } from "./form.view";

export type NewCategoryFormProps = {
  parentId: number | null;
};

export const NewCategoryForm = observer<NewCategoryFormProps>((props) => {
  const state = useNewCategoryFormState(props);
  return <NewCategoryFormView state={state} />;
});
