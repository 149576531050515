import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { SportPartnerAPI } from "@/api";
import { PartnerEdited } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditPartnerFormProps } from "./form";
import { EditPartnerFormSchema, useEditPartnerFormSchema } from "./form.schema";

export function useEditPartnerFormState({ id }: EditPartnerFormProps) {
  const intl = useIntl();

  const schema = useEditPartnerFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditPartnerFormSchema>({ resolver });

  const query = useMemo(() => {
    const query = new Query(async () => {
      const partner = await SportPartnerAPI.getById({ id });

      const values = schema.cast(partner, {
        assert: false,
        stripUnknown: true,
      });
      form.reset(values);

      return partner;
    });
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (values: EditPartnerFormSchema) => {
      await SportPartnerAPI.update({ id, ...values });

      notification.success({
        message: "",
        description: "Partner updated successfully!",
      });

      eventBusService.publish(new PartnerEdited({}));
    });
  }, [eventBusService, id]);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  return {
    intl,
    query,
    form,
    handleSubmit,
    mutation,
  };
}

export type EditPartnerFormState = ReturnType<typeof useEditPartnerFormState>;
