import { makeAutoObservable } from "mobx";

import { KnowledgeBaseAPI, TArticle, TCategory } from "@/api";
import {
  ArticleCreated,
  ArticleUpdated,
  CreateArticle,
  UpdateArticle,
} from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService } from "@/services";
import { PermissionsStore } from "@/stores";

export class ContentState implements ViewModel<unknown> {
  constructor(
    private eventBusService: EventBusService,
    private permissionsStore: PermissionsStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = async () => {
    await this.fetchArticles();

    this.eventBusService.subscribe(ArticleCreated, this.fetchArticles);
    this.eventBusService.subscribe(ArticleUpdated, this.fetchArticles);
  };

  onViewUnmount = async () => {
    this.eventBusService.unsubscribe(ArticleCreated, this.fetchArticles);
    this.eventBusService.unsubscribe(ArticleUpdated, this.fetchArticles);
  };

  get isCreateAllowed() {
    return this.permissionsStore.has("CreateArticle");
  }

  get isUpdateAllowed() {
    return this.permissionsStore.has("UpdateArticle");
  }

  handleCreateClick = () => {
    const event = new CreateArticle({});
    this.eventBusService.publish(event);
  };

  handleEditClick = (articleId: number) => {
    const event = new UpdateArticle({ articleId });
    this.eventBusService.publish(event);
  };

  articlesQuery = new Query(KnowledgeBaseAPI.getArticles);

  get articles() {
    return this.articlesQuery.data!.data;
  }

  get categories() {
    const categories: Array<TCategory & { articles: TArticle[] }> = [];
    for (const article of this.articles) {
      const category = categories.find(
        (category) => category.id === article.category.id,
      );
      if (!category) {
        categories.push({
          ...article.category,
          articles: [article],
        });
      } else {
        category.articles.push(article);
      }
    }
    return categories;
  }

  private fetchArticles = async () => {
    await this.articlesQuery.submit({});
  };
}
