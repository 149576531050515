import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { HallAPI } from "@/api";
import Query from "@/models/query";

import { HallOtherSettingsProps } from "./hall-other-settings";
import {
  HallOtherSettingsSchema,
  useHallOtherSettingsSchema,
} from "./hall-other-settings.schema";

export function useHallOtherSettingsState({ hallId }: HallOtherSettingsProps) {
  const intl = useIntl();

  const schema = useHallOtherSettingsSchema();
  const form = useForm<HallOtherSettingsSchema>({
    resolver: yupResolver(schema),
  });
  const formValues = form.watch();

  const hallQuery = useMemo(() => {
    const hallQuery = new Query(HallAPI.getById);
    hallQuery.submit({ id: hallId }).then(() => {
      const agent = hallQuery.data!;
      const values = schema.cast(agent, { stripUnknown: true });
      form.reset(values);
    });
    return hallQuery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuery = useMemo(() => {
    return new Query(async (values: HallOtherSettingsSchema) => {
      await HallAPI.update({ id: hallId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage:
            "The hall's other settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hallId,
    intl,
    form,
    formValues,
    hallQuery,
    submitQuery,
  };
}

export type HallOtherSettingsState = ReturnType<
  typeof useHallOtherSettingsState
>;
