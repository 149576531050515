import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

import { PlayerBonusesChecked, CheckPlayerBonuses } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { InitialView } from "./initial.view";

export const PlayerActiveBonusesModal: FC = observer(() => {
  const intl = useIntl();
  const [playerName, setPlayerName] = useState<string>();

  return (
    <EventBasedModal
      title={
        playerName ? (
          <>
            {intl.formatMessage({ defaultMessage: "Active Bonus" })}:{" "}
            <Typography.Text type="secondary">{playerName}</Typography.Text>
          </>
        ) : (
          <>{intl.formatMessage({ defaultMessage: "Active Bonus" })}</>
        )
      }
      onClose={() => setPlayerName(undefined)}
      openEvent={CheckPlayerBonuses}
      closeEvent={PlayerBonusesChecked}
    >
      {({ playerId }) => {
        return (
          <InitialView playerId={playerId} setPlayerName={setPlayerName} />
        );
      }}
    </EventBasedModal>
  );
});
