import { Empty, Select, SelectProps, Spin } from "antd";
import { observer } from "mobx-react-lite";

import { SelectorStore } from "@/stores";

type TProps = {
  store: SelectorStore<any, any, any>;
} & Pick<SelectProps<number>, "placeholder" | "disabled" | "style">;

function Selector(props: TProps) {
  const { store, ...selectProps } = props;
  return (
    <Select
      className="selector"
      showSearch
      allowClear
      loading={
        store.filterQuery.state === "pending" ||
        (!!store.selectedId && store.getByIdQuery.state === "pending")
      }
      options={store.options}
      value={
        store.selectedItem
          ? {
              value: store.selectedItem["id"],
              label: store.renderLabel
                ? store.renderLabel(store.selectedItem)
                : store.labelKey
                ? store.selectedItem[store.labelKey]
                : "—",
            }
          : undefined
      }
      labelInValue={true}
      onSelect={store.handleSelect}
      onSearch={store.handleSearch}
      onClear={store.handleClear}
      onPopupScroll={store.handleScroll}
      onDropdownVisibleChange={(isVisible) =>
        (document.body.style.overflow = isVisible ? "hidden" : "")
      }
      dropdownRender={(menu) => (
        <Spin spinning={store.filterQuery.isPending}>{menu}</Spin>
      )}
      filterOption={false}
      notFoundContent={
        store.filterQuery.state !== "fulfilled" ? (
          <Spin>
            <Empty
              style={{ opacity: 0 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </Spin>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      style={{ width: "100%", ...selectProps.style }}
      {...selectProps}
    />
  );
}

export default observer(Selector);
