import yup from "@/yup";

export function useHallCreateSchema() {
  return yup.object({
    name: yup.string().required().min(3),
    login: yup.string().required().min(3),
    password: yup.string().required().min(6),
    isBalanceUnlimited: yup.boolean().required(),
    currency: yup.string().required(),
    autoPrintReceipts: yup.boolean().required(),
    lobbySettings: yup
      .object({
        playersIdleTimeout: yup.number().integer().min(0).required(),
        isLogOutButtonEnabled: yup.boolean().required(),
      })
      .required(),
  });
}

export type THallCreateSchema = yup.InferType<
  ReturnType<typeof useHallCreateSchema>
>;
