import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { DepositCashRegister, CashRegisterDeposited } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({
        defaultMessage: "Cash inserting",
      })}
      openEvent={DepositCashRegister}
      closeEvent={CashRegisterDeposited}
    >
      {({ cashierId }) => <Form cashierId={cashierId} />}
    </EventBasedModal>
  );
});
