import { Breadcrumb, Card, Result, Space } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { APIError } from "@/api";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { Frame } from "@/ui/_common_/frame";

import { ContentState, HTTPError } from "./ContentState";

type Props = {
  state: ContentState;
};

export const ContentView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();

  if (state.articleQuery.isIdle || state.articleQuery.isPending) {
    return (
      <Card size="small">
        <FormSpinner />
      </Card>
    );
  }

  if (state.articleQuery.isRejected) {
    const { error } = state.articleQuery;
    if (error instanceof HTTPError) {
      return (
        <Result
          status={state.mapHTTPStatusToResultStatus(error.status)}
          title={error.status.toString()}
          subTitle={intl.formatMessage({
            defaultMessage: "The article does not exist.",
          })}
          extra={
            <Link to="/kb">
              {intl.formatMessage({ defaultMessage: "Back" })}
            </Link>
          }
        />
      );
    }
    if (
      error instanceof APIError &&
      (error.code === 22001 || error.code === 22003)
    ) {
      return (
        <Result
          status={404}
          title={404}
          subTitle={intl.formatMessage({
            defaultMessage: "The article does not exist.",
          })}
          extra={
            <Link to="/kb">
              {intl.formatMessage({ defaultMessage: "Back" })}
            </Link>
          }
        />
      );
    }
    return <ErrorsFormatter queries={[state.articleQuery]} />;
  }

  return (
    <Space direction="vertical">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/kb`}>
            {intl.formatMessage({ defaultMessage: "Articles" })}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/kb/${state.article.slug}`}>{state.article.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card size="small">
        <Frame>{state.article.html}</Frame>
      </Card>
    </Space>
  );
});
