import { isUndefined } from "lodash-es";
import { makeAutoObservable } from "mobx";

import { VoucherAPI } from "@/api";
import Query from "@/models/query";
import { TVoucherRedeemSchema } from "@/schemas";
import { IntlService } from "@/services";

export class VoucherRedeemStore {
  constructor(private intlService: IntlService) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.intlService.intl;
  }

  redeemQuery = new Query(VoucherAPI.redeem);

  isModalVisible = false;
  private hallId?: number;

  openModal = (hallId: number) => {
    this.hallId = hallId;
    this.isModalVisible = true;
  };

  closeModal = () => {
    this.isModalVisible = false;
    this.hallId = undefined;
    this.redeemQuery.reset();
  };

  handleSubmit = async (values: TVoucherRedeemSchema) => {
    if (isUndefined(this.hallId)) {
      throw new Error("The hallId is undefined.");
    }

    await this.redeemQuery.submit({
      hallId: this.hallId,
      ...values,
    });
  };
}
