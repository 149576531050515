import MethodBuilder from "../../method-builder";
import { TSummaryReportAggregatedItem, TPaging } from "../../types";

export class SummaryReportAPI {
  static aggregate = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
      | { kioskId: number }
    ) & {
      groupBy: "currency" | "hall";
      currency?: string;
      dateTimeRange?: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      paging: TPaging;
    },
    {
      data: TSummaryReportAggregatedItem[];
      total: number;
    }
  >()
    .withName("SummaryReport.aggregate")
    .build();
}
