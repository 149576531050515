import { makeAutoObservable } from "mobx";

import {
  AgentAPI,
  CashierAPI,
  ClientAPI,
  HallAPI,
  ShiftAPI,
  TAgent,
  TCashier,
  TClient,
  THall,
  TShift,
  TUser,
  UserAPI,
} from "@/api";
import { PlayerCreated, StartShift } from "@/events";
import Query from "@/models/query";
import { TUserLogInSchema } from "@/schemas";
import { Entity } from "@/types";

import { GlobalStore } from "../global";

import { UserUpdateStore } from "./user-update-store";

export class UserStore {
  constructor(private readonly globalStore: GlobalStore) {
    makeAutoObservable(this);

    this.eventBusService.subscribe(PlayerCreated, () => {
      if (this.user.role === "Cashier") {
        this.fetchCashier(this.user.cashierId);
      }
    });
  }

  private get eventBusService() {
    return this.globalStore.eventBusService;
  }

  private get routerService() {
    return this.globalStore.routerService;
  }

  private get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  readonly userUpdateStore = new UserUpdateStore(this.globalStore);

  private _user!: TUser;

  get user() {
    return this._user;
  }

  private setUser = (user: TUser) => {
    this._user = user;
  };

  get entity(): Entity | null {
    if (this.user.role === "ClientAdmin") {
      return { clientId: this.user.clientId };
    } else if (this.user.role === "AgentAdmin") {
      return { agentId: this.user.agentId };
    } else if (this.user.role === "HallAdmin" || this.user.role === "Cashier") {
      return { hallId: this.user.hallId };
    }
    return null;
  }

  readonly userQuery = new Query(UserAPI.getCurrent);

  fetchUser = async () => {
    await this.userQuery.submit();

    if (this.userQuery.isRejected || !this.userQuery.isFulfilled) {
      return;
    }

    const user = this.userQuery.data;
    this.setUser(user);
    this.handleLogIn();
  };

  readonly logInQuery = new Query(UserAPI.logIn);

  logIn = async (values: TUserLogInSchema) => {
    await this.logInQuery.submit(values);

    if (!this.logInQuery.isFulfilled) {
      return;
    }
    const user = this.logInQuery.data;
    this.setUser(user);
    this.userQuery.resolve(void 0, user);

    await this.handleLogIn();

    if (this.cashier?.isShiftModeEnabled && !this.cashier?.isShiftStarted) {
      this.eventBusService.publish(
        new StartShift({
          cashierId: this.cashier.id,
        }),
      );
    }
  };

  readonly logOutQuery = new Query(UserAPI.logOut);

  logOut = async () => {
    await this.logOutQuery.submit();
    window.location.href = "/log-in";
  };

  fetchDetails = async () => {
    const promises = [];
    if (this.user.role !== "HallAdmin" && this.user.role !== "Cashier") {
      promises.push(this.globalStore.currencyStore.fetch());
    }
    switch (this.user.role) {
      case "ClientAdmin":
        promises.push(this.fetchClient(this.user.clientId));
        break;
      case "AgentAdmin":
        promises.push(this.fetchAgent(this.user.agentId));
        break;
      case "HallAdmin":
        promises.push(this.fetchHall(this.user.hallId));
        break;
      case "Cashier":
        promises.push(this.fetchHall(this.user.hallId));
        promises.push(this.fetchCashier(this.user.cashierId));
        break;
    }
    await Promise.all(promises);
  };

  private _client?: TClient;

  get client() {
    return this._client;
  }

  public get clientMode() {
    return (
      this._client?.clientMode ??
      this._agent?.clientMode ??
      this._hall?.clientMode ??
      null
    );
  }

  private setClient = (client: TClient) => {
    this._client = client;
  };

  readonly clientQuery = new Query(ClientAPI.getById);

  fetchClient = async (id: number) => {
    await this.clientQuery.submit({ id });

    if (!this.clientQuery.isFulfilled) {
      return;
    }

    const client = this.clientQuery.data;
    this.setClient(client);
  };

  private _agent?: TAgent;

  get agent() {
    return this._agent;
  }

  private setAgent = (agent: TAgent) => {
    this._agent = agent;
  };

  readonly agentQuery = new Query(AgentAPI.getById);

  fetchAgent = async (id: number) => {
    await this.agentQuery.submit({ id });

    if (!this.agentQuery.isFulfilled) {
      return;
    }

    const agent = this.agentQuery.data;
    this.setAgent(agent);
  };

  private _hall?: THall;

  get hall() {
    return this._hall;
  }

  private setHall = (hall: THall) => {
    this._hall = hall;
  };

  readonly hallQuery = new Query(HallAPI.getById);

  fetchHall = async (id: number) => {
    await this.hallQuery.submit({ id });

    if (!this.hallQuery.isFulfilled) {
      return;
    }

    const hall = this.hallQuery.data;
    this.setHall(hall);
  };

  private _cashier?: TCashier;

  get cashier() {
    return this._cashier;
  }

  private setCashier = (cashier: TCashier) => {
    this._cashier = cashier;
  };

  readonly cashierQuery = new Query(CashierAPI.getById);

  fetchCashier = async (id: number) => {
    await this.cashierQuery.submit({ id });

    if (!this.cashierQuery.isFulfilled) {
      return;
    }

    const cashier = this.cashierQuery.data;
    this.setCashier(cashier);

    if (cashier.isShiftModeEnabled && cashier.isShiftStarted) {
      await this.fetchShift();
    } else {
      this.shift = undefined;
    }
  };

  private _shift?: TShift;

  get shift() {
    return this._shift;
  }

  private set shift(shift: TShift | undefined) {
    this._shift = shift;
  }

  readonly shiftQuery = new Query(ShiftAPI.get);

  fetchShift = async () => {
    if (!this.cashier) {
      return;
    }

    await this.shiftQuery.submit({
      cashierId: this.cashier.id,
    });

    if (!this.shiftQuery.isFulfilled) {
      return;
    }

    this.shift = this.shiftQuery.data;
  };

  private handleLogIn = async () => {
    await this.fetchDetails();

    if ("from" in this.routerService.searchParams) {
      const url = new URL(
        this.routerService.searchParams["from"],
        window.location.origin,
      );
      this.routerService.push({
        pathname: url.pathname,
        search: url.search,
        hash: url.hash,
      });
    } else {
      this.routerService.push({
        pathname: this.permissionsStore.defaultRoute,
      });
    }
  };

  fetchInBackground = () => {
    UserAPI.getCurrent().then(this.setUser).then(this.fetchDetails);
  };
}
