import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { pick } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  useEffect(() => {
    if (state.client) {
      form.reset(pick(state.client, Object.keys(schema.fields)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.client]);

  if (state.clientQuery.isIdle || state.clientQuery.isPending) {
    return <FormSpinner />;
  }

  if (state.clientQuery.isRejected) {
    return <ErrorsFormatter queries={[state.clientQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.updateQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Contacts" })}>
          <Controller
            control={form.control}
            name="contacts"
            defaultValue={state.client.contacts}
            render={({ field }) => (
              <Input.TextArea {...field} value={field.value ?? undefined} />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Contract conditions" })}
        >
          <Controller
            control={form.control}
            name="termsAndConditions"
            defaultValue={state.client.termsAndConditions}
            render={({ field }) => <Input.TextArea {...field} />}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Notes" })}>
          <Controller
            control={form.control}
            name="notes"
            defaultValue={state.client.notes}
            render={({ field }) => <Input.TextArea {...field} />}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Created at" })}>
          <Input
            value={new Date(state.client.createdAt).toLocaleString()}
            disabled
          />
        </Form.Item>
        <FormItem
          form={form}
          path="cashierSettings.isShortPlayerCreation"
          label={intl.formatMessage({
            defaultMessage: "Player creation: short form",
          })}
          isColonShown={false}
        >
          <FormSwitch
            form={form}
            path="cashierSettings.isShortPlayerCreation"
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.updateQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
