import yup from "@/yup";

export function useHallOtherSettingsSchema() {
  return yup.object({
    cashierSettings: yup.object({
      isShortPlayerCreation: yup.boolean(),
    }),
  });
}

export type HallOtherSettingsSchema = yup.InferType<
  ReturnType<typeof useHallOtherSettingsSchema>
>;
