import yup from "@/yup";

export function useUserLogInSchema() {
  return yup.object({
    login: yup.string().required().min(3),
    password: yup.string().required().min(6),
  });
}

export type TUserLogInSchema = yup.InferType<
  ReturnType<typeof useUserLogInSchema>
>;
