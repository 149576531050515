import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";

import { CurrentBonusState } from "./state";
import { CurrentBonusTable } from "./table";

export type Props = {
  playerId: number;
  setPlayerName(name: string): void;
};

export const InitialView: FC<Props> = observer((props) => {
  const state = useViewModel(() => new CurrentBonusState(), props);
  return <CurrentBonusTable state={state} />;
});
