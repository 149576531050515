import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { JackpotSlotTemplateAPI } from "@/api";
import { useExportHandler, useImportHandler } from "@/hooks";
import Query from "@/models/query";

function JackpotSlotTemplate() {
  const [getAllQuery] = useState(
    () => new Query(JackpotSlotTemplateAPI.getAll),
  );
  const [importQuery] = useState(
    () => new Query(JackpotSlotTemplateAPI.import),
  );
  const [exportQuery] = useState(
    () => new Query(JackpotSlotTemplateAPI.export),
  );

  const handleImportClick = useImportHandler({ getAllQuery, importQuery });

  const handleExportClick = useExportHandler({
    exportQuery,
    fileName: "jackpot-slot-templates.csv",
  });

  useEffect(() => {
    getAllQuery.submit();
  }, [getAllQuery]);

  return (
    <Space direction="vertical">
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              loading={importQuery.isPending}
              onClick={handleImportClick}
            >
              {"Import"}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<UploadOutlined />}
              loading={exportQuery.isPending}
              onClick={handleExportClick}
            >
              {"Export"}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Table
          loading={getAllQuery.isPending}
          dataSource={getAllQuery.isFulfilled ? getAllQuery.data.data : []}
          pagination={false}
          size="small"
          rowKey={(template) => `${template.jackpotTemplateId}_${template.id}`}
        >
          <Table.Column title={"ID"} dataIndex="id" />
          <Table.Column title={"Name"} dataIndex="name" width="100%" />
          <Table.Column
            title={"Expected Monthly ΣBET"}
            dataIndex="expectedMonthlyBetSum"
          />
          <Table.Column
            title={"Payout enabled"}
            dataIndex="isPayoutEnabled"
            render={(isPayoutEnabled) => (isPayoutEnabled ? "Yes" : "No")}
          />
          <Table.Column
            title={"Enabled"}
            dataIndex="isEnabled"
            render={(isEnabled) => (isEnabled ? "Yes" : "No")}
          />
        </Table>
      </Card>
    </Space>
  );
}

export default observer(JackpotSlotTemplate);
