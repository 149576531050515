import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Select, Space, Switch } from "antd";
import { TimePicker, Typography } from "antd/es";
import { has } from "lodash-es";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { TBonusTemplate } from "@/api";
import { ConfiguredByParentAlert } from "@/ui/_common_/configured-by-parent-alert";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { CurrencyUtilities } from "@/utilities";

import { FormState } from "./FormState";
import {
  useBouncebackSchema,
  useCashbackSchema,
  useLastchanceSchema,
  IBouncebackSchema,
  ICashbackSchema,
  ILastchanceSchema,
} from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const bouncebackSchema = useBouncebackSchema();
  const cashbackSchema = useCashbackSchema();
  const lastchanceSchema = useLastchanceSchema();
  const schema = {
    bounceback: bouncebackSchema,
    cashback: cashbackSchema,
    lastchance: lastchanceSchema,
  }[state.bonusType!];
  const resolver = yupResolver(schema);
  const form = useForm<ILastchanceSchema & IBouncebackSchema & ICashbackSchema>(
    { resolver },
  );
  const { errors } = form.formState;

  const values = form.watch();

  useEffect(() => {
    if (!state.bonus) {
      return;
    }

    if (state.bonus.type === "lastchance") {
      return;
    }

    if (
      state.parentBonus?.type === "lastchance" ||
      state.parentBonus?.happyHours?.isEnabled === false
    ) {
      return;
    }

    form.setValue("happyHours", toJS(state.bonus.happyHours));
  }, [form, state.bonus, state.parentBonus]);

  const handleTemplateSelect = useCallback(
    (id: number) => {
      const template = state.templates.find((t) => t.id === id);
      if (!template) {
        return;
      }
      const keys = Object.keys(template) as Array<keyof TBonusTemplate>;
      keys.forEach((key) => {
        if (key === "id" || key === "name" || key === "type") {
          return;
        }
        form.setValue(key, template[key]);
      });
    },
    [state.templates, form],
  );

  if (state.isIdleOrPending) {
    return <FormSpinner />;
  }

  return (
    <Space direction="vertical">
      <ConfiguredByParentAlert />
      <ErrorsFormatter
        queries={[
          state.grandAccessQuery,
          state.revokeAccessQuery,
          state.updateQuery,
        ]}
      />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        {!state.selfEdit && (
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Has access" })}
          >
            <Switch
              loading={
                state.grandAccessQuery.isPending ||
                state.revokeAccessQuery.isPending
              }
              checked={!!state.bonus}
              disabled={state.readOnly}
              onChange={state.toggleAccess}
            />
          </Form.Item>
        )}
        {state.bonus && (
          <>
            <Form.Item hidden>
              <input
                type="hidden"
                {...form.register("id")}
                value={state.bonus.id}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Template" })}
            >
              <Select
                placeholder={intl.formatMessage({
                  defaultMessage: "Select template",
                })}
                options={state.templates.map((template) => ({
                  label: template.name,
                  value: template.id,
                }))}
                disabled={state.readOnly}
                onChange={handleTemplateSelect}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Enabled" })}
              validateStatus={has(errors, "isEnabled") ? "error" : ""}
              help={errors.isEnabled?.message}
            >
              <Controller
                control={form.control}
                name="isEnabled"
                defaultValue={state.bonus.isEnabled}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    {...field}
                    disabled={state.readOnly}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                defaultMessage: "Percentage of deposit",
              })}
              validateStatus={has(errors, "depositPercentage") ? "error" : ""}
              help={errors.depositPercentage?.message}
            >
              <Controller
                control={form.control}
                name="depositPercentage"
                defaultValue={state.bonus?.depositPercentage}
                render={({ field }) => (
                  <Input {...field} disabled={state.readOnly} />
                )}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                defaultMessage: "Threshold for automatic issue",
              })}
              validateStatus={
                has(errors, "thresholdForAutoIssue") ? "error" : ""
              }
              help={errors.thresholdForAutoIssue?.message}
            >
              <Controller
                control={form.control}
                name="thresholdForAutoIssue"
                defaultValue={CurrencyUtilities.toMainUnits(
                  state.bonus!.thresholdForAutoIssue,
                )}
                render={({ field }) => (
                  <Input {...field} disabled={state.readOnly} />
                )}
              />
            </Form.Item>
            {state.bonus!.type !== "lastchance" && (
              <>
                <Form.Item
                  label={intl.formatMessage({ defaultMessage: "Min. deposit" })}
                  validateStatus={has(errors, "minDeposit") ? "error" : ""}
                  help={errors.minDeposit?.message}
                >
                  <Controller
                    control={form.control}
                    name="minDeposit"
                    defaultValue={CurrencyUtilities.toMainUnits(
                      state.bonus!.minDeposit,
                    )}
                    render={({ field }) => (
                      <Input {...field} disabled={state.readOnly} />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ defaultMessage: "Max. deposit" })}
                  validateStatus={has(errors, "maxDeposit") ? "error" : ""}
                  help={errors.maxDeposit?.message}
                >
                  <Controller
                    control={form.control}
                    name="maxDeposit"
                    defaultValue={CurrencyUtilities.toMainUnits(
                      state.bonus!.maxDeposit,
                    )}
                    render={({ field }) => (
                      <Input {...field} disabled={state.readOnly} />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({
                    defaultMessage: "Max. activations per player",
                  })}
                  validateStatus={
                    has(errors, "maxActivationsCount") ? "error" : ""
                  }
                  help={errors.maxActivationsCount?.message}
                >
                  <Controller
                    control={form.control}
                    name="maxActivationsCount"
                    defaultValue={state.bonus!.maxActivationsCount}
                    render={({ field }) => (
                      <Input {...field} disabled={state.readOnly} />
                    )}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item
              label={intl.formatMessage({
                defaultMessage: "Cooldown Period (hours)",
              })}
              validateStatus={has(errors, "cooldown") ? "error" : ""}
              help={errors.cooldown?.message}
            >
              <Controller
                control={form.control}
                name="cooldown"
                defaultValue={state.bonus!.cooldown}
                render={({ field }) => (
                  <Input {...field} disabled={state.readOnly} />
                )}
              />
            </Form.Item>
            {state.bonus!.type !== "bounceback" && (
              <Form.Item
                label={intl.formatMessage({
                  defaultMessage: "Player balance threshold",
                })}
                validateStatus={
                  has(errors, "playerBalanceThreshold") ? "error" : ""
                }
                help={errors.playerBalanceThreshold?.message}
              >
                <Controller
                  control={form.control}
                  name="playerBalanceThreshold"
                  defaultValue={CurrencyUtilities.toMainUnits(
                    state.bonus!.playerBalanceThreshold,
                  )}
                  render={({ field }) => (
                    <Input {...field} disabled={state.readOnly} />
                  )}
                />
              </Form.Item>
            )}
            {state.bonus.type !== "lastchance" && (
              <>
                <Form.Item
                  label={intl.formatMessage({
                    defaultMessage: "Wager enabled",
                  })}
                  validateStatus={has(errors, "isWagerEnabled") ? "error" : ""}
                  help={errors.isWagerEnabled?.message}
                >
                  <Controller
                    control={form.control}
                    name="isWagerEnabled"
                    defaultValue={state.bonus!.isWagerEnabled}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        {...field}
                        disabled={state.readOnly}
                      />
                    )}
                  />
                </Form.Item>
                {(!state.parentBonus ||
                  (state.parentBonus.type !== "lastchance" &&
                    state.parentBonus.happyHours.isEnabled)) && (
                  <>
                    <Row>
                      <Typography.Text strong>
                        {intl.formatMessage({
                          defaultMessage: "Happy Hours",
                        })}
                      </Typography.Text>
                    </Row>
                    <Form.Item
                      label={intl.formatMessage({
                        defaultMessage: "Enabled",
                      })}
                      validateStatus={
                        has(errors, "happyHours.isEnabled") ? "error" : ""
                      }
                      help={errors.happyHours?.isEnabled?.message}
                    >
                      <Controller
                        control={form.control}
                        name="happyHours.isEnabled"
                        defaultValue={state.bonus!.happyHours.isEnabled}
                        render={({ field }) => (
                          <Switch
                            checked={field.value}
                            {...field}
                            disabled={state.readOnly}
                          />
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ defaultMessage: "Hours" })}
                      validateStatus={
                        has(errors, "happyHours.dateTimeRange.from") ||
                        has(errors, "happyHours.dateTimeRange.to")
                          ? "error"
                          : ""
                      }
                      help={
                        errors?.happyHours?.dateTimeRange?.from?.message ||
                        errors?.happyHours?.dateTimeRange?.to?.message
                      }
                    >
                      <TimePicker.RangePicker
                        placeholder={[
                          intl.formatMessage({ defaultMessage: "From" }),
                          intl.formatMessage({ defaultMessage: "To" }),
                        ]}
                        defaultValue={[
                          moment(state.bonus!.happyHours.dateTimeRange.from),
                          moment(state.bonus!.happyHours.dateTimeRange.to),
                        ]}
                        onChange={(range) => {
                          form.setValue(
                            "happyHours.dateTimeRange.from",
                            range?.[0]?.toISOString(true) ?? "",
                          );
                          form.setValue(
                            "happyHours.dateTimeRange.to",
                            range?.[1]?.toISOString(true) ?? "",
                          );
                        }}
                        order={false}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({
                        defaultMessage: "Boost (as a percentage)",
                      })}
                      validateStatus={
                        has(errors, "happyHours.boost") ? "error" : ""
                      }
                      help={errors.happyHours?.boost?.message}
                    >
                      <Controller
                        control={form.control}
                        name="happyHours.boost"
                        defaultValue={state.bonus!.happyHours.boost}
                        render={({ field }) => (
                          <Input {...field} disabled={state.readOnly} />
                        )}
                      />
                      <div>
                        <FormattedMessage
                          defaultMessage="The percentage of the deposit during the happy hour is <b>{value}%</b>."
                          values={{
                            b: (children) => <b>{children}</b>,
                            value:
                              ("happyHours" in values
                                ? +(values?.happyHours?.boost ?? 0)
                                : 0) + +(values?.depositPercentage ?? 0),
                          }}
                        />
                      </div>
                    </Form.Item>
                  </>
                )}
              </>
            )}
            {!state.readOnly && (
              <Row justify="end" gutter={12}>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={state.updateQuery.isPending}
                  >
                    {intl.formatMessage({ defaultMessage: "Save" })}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
      </Form>
    </Space>
  );
});
