import { endOfDay, startOfDay, isValid } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";

import {
  AgentAPI,
  BonusReportAPI,
  ClientAPI,
  DictionaryAPI,
  HallAPI,
} from "@/api";
import { SearchParamKey } from "@/constants";
import { ViewModel } from "@/hooks/use-view-model";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import { GlobalStore, SelectorStore } from "@/stores";
import { parseInteger } from "@/utilities";

export class BonusReportState implements ViewModel<unknown> {
  constructor(private globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount() {
    this.useInitialSearchParams();
    this.updateSearchParams();
    this.updateSelectorParameters();
    this.apply();
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get userStore() {
    return this.globalStore.userStore;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  private handleClientSelect = () => {
    this.agentSelectorStore.setSelectedId();
    this.hallSelectorStore.setSelectedId();
    this.playerSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleAgentSelect = () => {
    this.hallSelectorStore.setSelectedId();
    this.playerSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleSubagentSelect = () => {
    this.hallSelectorStore.setSelectedId();
    this.playerSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleHallSelect = () => {
    this.playerSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handlePlayerSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  public readonly clientSelectorStore = new SelectorStore({
    filterMethod: ClientAPI.filter,
    getByIdMethod: ClientAPI.getById,
    labelKey: "name",
    onSelect: this.handleClientSelect,
  });

  public readonly agentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleAgentSelect,
  });

  public readonly subagentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleSubagentSelect,
  });

  public readonly hallSelectorStore = new SelectorStore({
    filterMethod: HallAPI.filter,
    getByIdMethod: HallAPI.getById,
    labelKey: "name",
    onSelect: this.handleHallSelect,
  });

  public readonly playerSelectorStore = new SelectorStore({
    filterMethod: DictionaryAPI.getPlayers,
    getByIdMethod: DictionaryAPI.getPlayer,
    renderLabel(item) {
      if (item.isDeleted) {
        return <span style={{ color: "gray" }}>{item.login}</span>;
      }
      return item.login;
    },
    onSelect: this.handlePlayerSelect,
  });

  private _currency?: string = (() => {
    if (this.userStore.hall) {
      return this.userStore.hall.currency;
    } else if (this.userStore.agent) {
      return this.userStore.agent.currency ?? undefined;
    } else if (this.userStore.client) {
      return this.userStore.client.currency ?? undefined;
    }
  })();

  get currency() {
    return this._currency;
  }

  setCurrency = (currency?: string) => {
    this._currency = currency;
    this.updateSearchParams();
  };

  private _dateTimeRange: [Date, Date] = [
    startOfDay(new Date()),
    endOfDay(new Date()),
  ];

  get dateTimeRange() {
    return this._dateTimeRange;
  }

  setDateTimeRange = (dateTimeRange: [Date, Date]) => {
    this._dateTimeRange = dateTimeRange;
    this.updateSearchParams();
  };

  get entity() {
    let params:
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
      | null = null;
    if (this.playerSelectorStore.selectedId) {
      params = { playerId: this.playerSelectorStore.selectedId };
    } else if (this.hallSelectorStore.selectedId) {
      params = { hallId: this.hallSelectorStore.selectedId };
    } else if (this.subagentSelectorStore.selectedId) {
      params = { agentId: this.subagentSelectorStore.selectedId };
    } else if (this.agentSelectorStore.selectedId) {
      params = { agentId: this.agentSelectorStore.selectedId };
    } else if (this.clientSelectorStore.selectedId) {
      params = { clientId: this.clientSelectorStore.selectedId };
    }
    return params;
  }

  filterQuery = new ItemsQuery(BonusReportAPI.filter, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  filter = async (options?: TSubmitOptions) => {
    if (this.entity === null) {
      return;
    }

    await this.filterQuery.submit(
      {
        ...this.entity,
        currency: this.currency,
        dateTimeRange: {
          from: this.dateTimeRange[0].toISOString(),
          to: this.dateTimeRange[1].toISOString(),
        },
      },
      options,
    );
  };

  apply = async () => {
    await this.filter();
  };

  private useInitialSearchParams = () => {
    const { client, agent, hall } = this.userStore;

    if (client) {
      this.clientSelectorStore.setSelectedId(client.id, false);
    } else if (agent) {
      this.clientSelectorStore.setSelectedId(agent.clientId, false);
      this.agentSelectorStore.setSelectedId(agent.id, false);
    } else if (hall) {
      this.clientSelectorStore.setSelectedId(hall.clientId, false);
      this.agentSelectorStore.setSelectedId(hall.agentId ?? undefined, false);
      this.hallSelectorStore.setSelectedId(hall.id, false);
    }

    const { searchParams } = this.routerService;
    const initialClientId = parseInteger(searchParams[SearchParamKey.ClientId]);
    const initialAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.AgentId])
        : undefined;
    const initialHallId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.HallId])
        : undefined;
    const initialPlayerId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.PlayerId])
        : undefined;

    runInAction(() => {
      this._currency = searchParams[SearchParamKey.Currency];

      const df = new Date(searchParams[SearchParamKey.DateFrom]);
      const dt = new Date(searchParams[SearchParamKey.DateTo]);
      if (isValid(df) && isValid(dt) && df < dt) {
        this._dateTimeRange = [df, dt];
      }
    });

    if (this.permissionsStore.has("SelectClient")) {
      this.clientSelectorStore.setSelectedId(initialClientId);
    }
    if (this.clientSelectorStore.selectedId) {
      if (this.permissionsStore.has("SelectAgent")) {
        this.agentSelectorStore.setSelectedId(initialAgentId);
      }
      if (
        this.permissionsStore.has("SelectSubagent") &&
        agent?.id !== initialAgentId
      ) {
        this.subagentSelectorStore.setSelectedId(initialAgentId);
      }
      if (this.permissionsStore.has("SelectHall")) {
        this.hallSelectorStore.setSelectedId(initialHallId);
      }
    }

    this.playerSelectorStore.setSelectedId(initialPlayerId);
  };

  private updateSearchParams() {
    const urlSearchParams = new URLSearchParams(
      this.routerService.location.search,
    );

    if (this.clientSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ClientId,
        this.clientSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ClientId);
    }

    if (this.subagentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.subagentSelectorStore.selectedId.toString(),
      );
    } else if (this.agentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.agentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.AgentId);
    }

    if (this.hallSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.HallId,
        this.hallSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.HallId);
    }

    if (this.playerSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.PlayerId,
        this.playerSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.PlayerId);
    }

    if (this.currency) {
      urlSearchParams.set(SearchParamKey.Currency, this.currency);
    } else {
      urlSearchParams.delete(SearchParamKey.Currency);
    }

    urlSearchParams.set(
      SearchParamKey.DateFrom,
      this.dateTimeRange[0].toISOString(),
    );
    urlSearchParams.set(
      SearchParamKey.DateTo,
      this.dateTimeRange[1].toISOString(),
    );

    this.routerService.replace({
      search: urlSearchParams.toString(),
    });
  }

  private updateSelectorParameters = () => {
    const oldClientParameters = this.clientSelectorStore.parameters;
    const oldAgentParameters = this.agentSelectorStore.parameters;
    const oldSubagentParameters = this.subagentSelectorStore.parameters;
    const oldHallParameters = this.hallSelectorStore.parameters;
    const oldPlayerParameters = this.playerSelectorStore.parameters;

    let newClientParameters = oldClientParameters;
    let newAgentParameters = oldAgentParameters;
    let newSubagentParameters = oldSubagentParameters;
    let newHallParameters = oldHallParameters;
    let newPlayerParameters = oldPlayerParameters;

    const clientId = this.clientSelectorStore.selectedId;
    const agentId = this.agentSelectorStore.selectedId;
    const subagentId = this.subagentSelectorStore.selectedId;
    const hallId = this.hallSelectorStore.selectedId;

    const { user } = this.userStore;
    newClientParameters = {
      managerId: user.role === "Manager" ? user.id : undefined,
    };

    if (clientId) {
      newAgentParameters = { clientId };
      newSubagentParameters = { clientId };
      newHallParameters = { clientId };
      newPlayerParameters = { clientId };
    }

    if (agentId) {
      newSubagentParameters = { parentAgentId: agentId };
      newHallParameters = { agentId };
      newPlayerParameters = { agentId };
    }

    if (subagentId) {
      const agentId = subagentId;
      newHallParameters = { agentId };
      newPlayerParameters = { agentId };
    }

    if (hallId) {
      newPlayerParameters = { hallId };
    }

    if (
      this.permissionsStore.has("SelectClient") &&
      JSON.stringify(oldClientParameters) !==
        JSON.stringify(newClientParameters)
    ) {
      this.clientSelectorStore.setParameters(newClientParameters);
      this.clientSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldAgentParameters) !== JSON.stringify(newAgentParameters)
    ) {
      this.agentSelectorStore.setParameters(newAgentParameters);
      this.agentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectSubagent") &&
      JSON.stringify(oldSubagentParameters) !==
        JSON.stringify(newSubagentParameters)
    ) {
      this.subagentSelectorStore.setParameters(newSubagentParameters);
      this.subagentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectHall") &&
      JSON.stringify(oldHallParameters) !== JSON.stringify(newHallParameters)
    ) {
      this.hallSelectorStore.setParameters(newHallParameters);
      this.hallSelectorStore.fetchItems();
    }

    if (
      JSON.stringify(oldPlayerParameters) !==
      JSON.stringify(newPlayerParameters)
    ) {
      this.playerSelectorStore.setParameters(newPlayerParameters);
      this.playerSelectorStore.fetchItems();
    }
  };
}
