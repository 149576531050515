import { Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

interface Props<FV extends FieldValues> {
  form: UseFormReturn<FV>;
  path: Path<FV>;
  isConfirmationNeeded?: boolean;
  disabled?: boolean;
}

export const FormCheckbox = observer(
  <FV extends FieldValues>({ form, path }: Props<FV>) => {
    return (
      <Controller
        control={form.control}
        name={path}
        render={({ field }) => <Checkbox {...field} checked={field.value} />}
      />
    );
  },
);
