import { observer } from "mobx-react-lite";
import { FC } from "react";

import { CreateGame, GameCreated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  return (
    <EventBasedModal
      title={"New game"}
      openEvent={CreateGame}
      closeEvent={GameCreated}
    >
      {(props) => <Form {...props} />}
    </EventBasedModal>
  );
});
