import { observer } from "mobx-react-lite";

import { usePanicService } from "@/services";
import { useGlobalStore } from "@/stores";
import FullscreenSpinner from "@/ui/_common_/fullscreen-spinner";
import DepositShift from "@/ui/shifts/deposit";
import FinishShift from "@/ui/shifts/finish";
import StartShift from "@/ui/shifts/start";
import WithdrawShift from "@/ui/shifts/withdraw";

import { Router } from "./router";
import { PasswordAlert } from "./ui/password-alert";

function App() {
  usePanicService();

  const { intlService } = useGlobalStore();
  return (
    <>
      <FullscreenSpinner isVisible={!intlService.isInitialized} />
      {intlService.isInitialized && (
        <>
          <StartShift.Modal />
          <FinishShift.Modal />
          <DepositShift.Modal />
          <WithdrawShift.Modal />
          <PasswordAlert />
        </>
      )}
      <Router />
    </>
  );
}

export default observer(App);
