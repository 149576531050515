import yup from "@/yup";

export function useEditPartnerFormSchema() {
  return yup.object({
    description: yup.string().min(3).default("").required(),
  });
}

export type EditPartnerFormSchema = yup.InferType<
  ReturnType<typeof useEditPartnerFormSchema>
>;
