import {
  Space,
  Form,
  Switch,
  Select,
  Row,
  Col,
  Alert,
  Button,
  Input,
} from "antd";
import { observer } from "mobx-react-lite";

import { CurrencyUtilities } from "@/utilities";

import { ConfiguredByParentAlert } from "../_common_/configured-by-parent-alert";
import ErrorsFormatter from "../_common_/errors-formatter";
import { FormInput, FormItem, FormSwitch } from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";
import { InfoIcon } from "../_common_/icons";

import { JackpotSettingsFormState } from "./jackpot-settings-form.state";

interface Props {
  state: JackpotSettingsFormState;
}

export const JackpotSettingsFormView = observer(({ state }: Props) => {
  const {
    intl,
    form,
    entity,
    isLoading,
    jackpot,
    templates,
    toggleAccessQuery,
    handleTemplateSelect,
    totalWins,
    totalWinSum,
    avgPercentage,
    refreshQuery,
    resetQueries,
    resetQuery,
    syncQuery,
    submitQuery,
    isPayoutWarningVisible,
    isCooldownWarningVisibile,
  } = state;

  if (isLoading) {
    return <FormSpinner />;
  }

  return (
    <Space direction="vertical">
      <ConfiguredByParentAlert />
      <ErrorsFormatter
        queries={[toggleAccessQuery, submitQuery]}
        isSticky={true}
      />
      <Form
        labelCol={{ span: 10 }}
        labelAlign="left"
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Has access" })}>
          <Switch
            loading={toggleAccessQuery.isPending}
            checked={!!jackpot}
            onChange={toggleAccessQuery.submit}
          />
        </Form.Item>
        {jackpot && (
          <>
            <Form.Item
              label={intl.formatMessage({
                defaultMessage: "Jackpot template",
              })}
            >
              <Select
                placeholder={intl.formatMessage({
                  defaultMessage: "Select jackpot template",
                })}
                options={templates.map((template) => ({
                  label: template.name,
                  value: template.id,
                }))}
                onChange={handleTemplateSelect}
              />
            </Form.Item>
            <FormItem
              form={form}
              path="isEnabled"
              label={intl.formatMessage({ defaultMessage: "Enabled" })}
              tooltip={intl.formatMessage({
                defaultMessage: "on = Display Jackpots in the lobby",
              })}
            >
              <FormSwitch form={form} path="isEnabled" />
            </FormItem>
            <FormItem
              form={form}
              path="isPayoutEnabled"
              label={intl.formatMessage({
                defaultMessage: "Payout enabled",
              })}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "off = jackpots are accumulated, but not paid to players",
              })}
            >
              <FormSwitch form={form} path="isPayoutEnabled" />
            </FormItem>
            <FormItem
              form={form}
              path="expectedMonthlyBetSum"
              label={intl.formatMessage({
                defaultMessage: "Expected Σ BET value",
              })}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "expected value of sum bets (only bets, not GGR or so) for month",
              })}
            >
              <FormInput
                form={form}
                path="expectedMonthlyBetSum"
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter expected Σ BET value",
                })}
              />
            </FormItem>
            <Form.Item label={<></>} colon={false}>
              <Row
                gutter={[8, 8]}
                justify="center"
                style={{ whiteSpace: "nowrap" }}
              >
                <Col span={6} className="slot">
                  {intl.formatMessage({ defaultMessage: "Slot 1" })}
                  <br />
                  {intl.formatMessage({ defaultMessage: "(mini)" })}
                </Col>
                <Col span={6} className="slot">
                  {intl.formatMessage({ defaultMessage: "Slot 2" })}
                  <br />
                  {intl.formatMessage({ defaultMessage: "(major)" })}
                </Col>
                <Col span={6} className="slot">
                  {intl.formatMessage({ defaultMessage: "Slot 3" })}
                  <br />
                  {intl.formatMessage({ defaultMessage: "(grand)" })}
                </Col>
                <Col span={6} className="slot">
                  {intl.formatMessage({ defaultMessage: "Slot 4" })}
                  <br />
                  {intl.formatMessage({ defaultMessage: "(ultimate)" })}
                </Col>
              </Row>
            </Form.Item>
            {"hallId" in entity && (
              <Form.Item
                label={intl.formatMessage({
                  defaultMessage: "Current value",
                })}
              >
                <Space direction="vertical">
                  <Space>
                    {jackpot.slots.map((slot) => (
                      <Input
                        key={slot.id}
                        value={CurrencyUtilities.toMainUnits(slot.value)}
                        style={{ textAlign: "right" }}
                        bordered={false}
                        readOnly
                      />
                    ))}
                  </Space>
                  {isPayoutWarningVisible && (
                    <Alert
                      type="warning"
                      showIcon
                      icon={<InfoIcon style={{ width: 20 }} />}
                      message={intl.formatMessage(
                        {
                          defaultMessage:
                            "Attention! Jackpot payout is disabled.{br}In order for the jackpots to be triggered, you need to enable the payout.",
                        },
                        { br: <br /> },
                      )}
                    />
                  )}
                </Space>
              </Form.Item>
            )}
            {"hallId" in entity && (
              <Form.Item label={<></>} colon={false}>
                <Row gutter={8} wrap={false}>
                  {jackpot.slots.map((slot, index) => (
                    <Col key={slot.id} span={6}>
                      <Button
                        size="small"
                        onClick={() => resetQueries[index].submit()}
                        loading={resetQueries[index].isPending}
                        style={{ width: "100%" }}
                      >
                        {intl.formatMessage({ defaultMessage: "Reset" })}
                      </Button>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            )}
            <FormItem
              form={form}
              path="slots.*.minValue"
              label={intl.formatMessage({ defaultMessage: "Min. value" })}
              tooltip={intl.formatMessage({
                defaultMessage: "minimum jackpot value",
              })}
            >
              <Space>
                {jackpot.slots.map((slot) => (
                  <FormInput
                    key={slot.id}
                    form={form}
                    path={`slots.${slot.id}.minValue`}
                    align="right"
                  />
                ))}
              </Space>
            </FormItem>
            <FormItem
              form={form}
              path="slots.*.maxValue"
              label={intl.formatMessage({ defaultMessage: "Max. value" })}
              tooltip={intl.formatMessage({
                defaultMessage: "maximum jackpot value",
              })}
            >
              <Space>
                {jackpot.slots.map((slot) => (
                  <FormInput
                    key={slot.id}
                    form={form}
                    path={`slots.${slot.id}.maxValue`}
                    align="right"
                  />
                ))}
              </Space>
            </FormItem>
            <FormItem
              form={form}
              path="slots.*.defaultValue"
              label={intl.formatMessage({ defaultMessage: "Default value" })}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "is the value, from which JP will start grow after it has been won",
              })}
            >
              <Space>
                {jackpot.slots.map((slot) => (
                  <FormInput
                    key={slot.id}
                    form={form}
                    path={`slots.${slot.id}.defaultValue`}
                    align="right"
                  />
                ))}
              </Space>
            </FormItem>
            <Form.Item
              label={intl.formatMessage({
                defaultMessage: "Community jackpot",
              })}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "on = every active player in the shop wins part of the jackpot",
              })}
            >
              <Row>
                {jackpot.slots.map((slot) => (
                  <Col
                    key={slot.id}
                    flex={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FormSwitch
                      form={form}
                      path={`slots.${slot.id}.isCommunity`}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Item>
            <FormItem
              form={form}
              path="slots.*.communityCooldown"
              label={intl.formatMessage({
                defaultMessage: "Community cooldown",
              })}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "time interval (milliseconds) between the bonus activation is available",
              })}
            >
              <Space direction="vertical">
                <Space>
                  {jackpot.slots.map((slot) => (
                    <FormInput
                      key={slot.id}
                      form={form}
                      path={`slots.${slot.id}.communityCooldown`}
                      align="right"
                    />
                  ))}
                </Space>
                {isCooldownWarningVisibile && (
                  <Alert
                    type="warning"
                    showIcon
                    icon={<InfoIcon style={{ width: 20 }} />}
                    message={intl.formatMessage(
                      {
                        defaultMessage:
                          "Attention! It is necessary to set the Cooldown value in milliseconds.{br}If the value is zero, the jackpot may not work correctly.",
                      },
                      { br: <br /> },
                    )}
                  />
                )}
              </Space>
            </FormItem>
            <FormItem
              form={form}
              path="slots.*.expectedMonthlyWinsCount"
              label={intl.formatMessage({
                defaultMessage: "Expected Number of Monthly Pieces",
              })}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "number of jackpots to be won over the course of one month",
              })}
            >
              <Space>
                {jackpot.slots.map((slot) => (
                  <FormInput
                    key={slot.id}
                    form={form}
                    path={`slots.${slot.id}.expectedMonthlyWinsCount`}
                    align="right"
                  />
                ))}
              </Space>
            </FormItem>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Total wins" })}
            >
              <Space>
                {jackpot.slots.map((slot) => (
                  <Input
                    key={slot.id}
                    value={totalWins[slot.id]}
                    style={{ textAlign: "right" }}
                    bordered={false}
                    readOnly
                  />
                ))}
              </Space>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Total win Σ" })}
            >
              <Input
                value={totalWinSum}
                style={{ textAlign: "right" }}
                bordered={false}
                readOnly
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                defaultMessage: "Average (theoretical) percentage",
              })}
            >
              <Input
                value={`${avgPercentage.toFixed(2)}%`}
                style={{ textAlign: "right" }}
                bordered={false}
                readOnly
              />
            </Form.Item>
            {jackpot.isSyncButtonEnabled && (
              <Alert
                type="warning"
                showIcon
                description={intl.formatMessage(
                  {
                    defaultMessage:
                      'Synchronization is required.{br}Jackpot settings have been changed.{br}Without synchronization, jackpots may not work as expected.{br}After completing the settings, be sure to synchronize the jackpots.{br}Click the "Sync" button to synchronize the jackpots.',
                  },
                  { br: <br /> },
                )}
              />
            )}
            <Row justify="end" gutter={[8, 8]}>
              {"hallId" in entity && (
                <>
                  <Col>
                    <Button
                      onClick={() => refreshQuery.submit()}
                      loading={refreshQuery.isPending}
                    >
                      {intl.formatMessage({ defaultMessage: "Refresh" })}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => resetQuery.submit()}
                      loading={resetQuery.isPending}
                    >
                      {intl.formatMessage({ defaultMessage: "Reset" })}
                    </Button>
                  </Col>
                </>
              )}
              {jackpot.isSyncButtonEnabled && (
                <Col>
                  <Button
                    size="middle"
                    type="primary"
                    onClick={() => syncQuery.submit()}
                    loading={syncQuery.isPending}
                    danger
                  >
                    {intl.formatMessage({ defaultMessage: "Sync" })}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={submitQuery.isPending}
                >
                  {intl.formatMessage({ defaultMessage: "Save" })}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Space>
  );
});
