/**
 * @link https://en.wikipedia.org/wiki/Decimalisation
 * @link https://en.wikipedia.org/wiki/ISO_4217
 * @link https://en.wikipedia.org/wiki/ISO_4217#Minor_units_of_currency
 */
export class CurrencyUtilities {
  /**
   * @alias CurrencyUtilities.fromMinorUnits
   */
  static toMainUnits(minorUnits: number) {
    const { sign, integerPart, decimalPart } = this.parseMinorUnits(minorUnits);
    const mainUnitsString = `${sign}${integerPart}.${decimalPart}`;
    const mainUnits = Number.parseFloat(mainUnitsString);
    if (Number.isNaN(mainUnits)) {
      throw new InvalidMinorUnitsError(minorUnits);
    }
    return mainUnits;
  }

  static parseMinorUnits(minorUnits: number) {
    if (!Number.isSafeInteger(minorUnits)) {
      throw new InvalidMinorUnitsError(minorUnits);
    }
    let minorUnitsString = minorUnits.toString();
    if (!/^-?\d+$/.test(minorUnitsString)) {
      throw new InvalidMinorUnitsError(minorUnits);
    }
    const isNegative = minorUnitsString.startsWith("-");
    if (isNegative) {
      minorUnitsString = minorUnitsString.substring(1);
    }
    minorUnitsString = minorUnitsString.padStart(3, "0");
    const sign = isNegative ? "-" : "";
    const integerPart = minorUnitsString.slice(0, -2);
    const decimalPart = minorUnitsString.slice(-2);
    return {
      sign,
      integerPart,
      decimalPart,
    };
  }

  /**
   * @alias CurrencyUtilities.toMinorUnits
   */
  static fromMainUnits(mainUnits: number) {
    const mainUnitsString = mainUnits.toString();
    // XXX, XXX.X, XXX.XX
    if (!/^-?\d+(\.\d{1,2})?$/.test(mainUnitsString)) {
      throw new InvalidMainUnitsError(mainUnits);
    }
    const [integerPart, decimalPart] = mainUnitsString.split(".");
    const minorUnitsString = integerPart + (decimalPart ?? "").padEnd(2, "0");
    const minorUnits = Number.parseInt(minorUnitsString, 10);
    if (!Number.isSafeInteger(minorUnits)) {
      throw new InvalidMainUnitsError(mainUnits);
    }
    return minorUnits;
  }

  /**
   * @alias CurrencyUtilities.fromMainUnits
   */
  static toMinorUnits(mainUnits: number) {
    return CurrencyUtilities.fromMainUnits(mainUnits);
  }

  /**
   * @alias CurrencyUtilities.toMainUnits
   */
  static fromMinorUnits(minorUnits: number) {
    return CurrencyUtilities.toMainUnits(minorUnits);
  }

  static isMainUnitsValid(mainUnits: number) {
    try {
      CurrencyUtilities.fromMainUnits(mainUnits);
      return true;
    } catch (error) {
      if (error instanceof InvalidMainUnitsError) {
        return false;
      }
      throw error;
    }
  }

  static isMinorUnitsValid(minorUnits: number) {
    try {
      CurrencyUtilities.fromMinorUnits(minorUnits);
      return true;
    } catch (error) {
      if (error instanceof InvalidMinorUnitsError) {
        return false;
      }
      throw error;
    }
  }
}

class InvalidMainUnitsError extends Error {
  constructor(value: number) {
    super(`Invalid main units: ${value}`);
  }
}

class InvalidMinorUnitsError extends Error {
  constructor(value: number) {
    super(`Invalid minor units: ${value}`);
  }
}
