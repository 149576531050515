import yup from "@/yup";

export function useSchema() {
  return yup.object({
    beginningCash: yup.number().money().min(0).required(),
    printReceipt: yup.boolean().defined(),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;
