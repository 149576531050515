import yup from "@/yup";

export function useSchema() {
  return yup.object({
    contacts: yup.string().nullable(),
    termsAndConditions: yup.string(),
    notes: yup.string(),
    cashierSettings: yup.object({
      isShortPlayerCreation: yup.boolean(),
    }),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;
