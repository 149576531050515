import { Modal } from "antd";
import { observer } from "mobx-react-lite";

import { NewCategoryForm } from "./form";
import { NewCategoryModalState } from "./modal.state";

type Props = { state: NewCategoryModalState };

export const NewCategoryModalView = observer<Props>(({ state }) => {
  return (
    <Modal
      open={state.opened}
      onCancel={state.close}
      title={"New game category"}
      footer={null}
      destroyOnClose
    >
      <NewCategoryForm parentId={state.parentId} />
    </Modal>
  );
});
