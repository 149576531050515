import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { KnowledgeBaseAPI } from "@/api";
import { ArticleCreated } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { UserStore } from "@/stores";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = async () => {
    await this.categoriesQuery.submit();
  };

  categoriesQuery = new Query(KnowledgeBaseAPI.getCategories);

  get categories() {
    return this.categoriesQuery.data!.data;
  }

  createQuery = new Query(KnowledgeBaseAPI.createArticle);

  handleSubmit = async (values: ISchema) => {
    await this.createQuery.submit(values);

    if (!this.createQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The article has been successfully created.",
      }),
    });

    const event = new ArticleCreated({});
    this.eventBusService.publish(event);
  };
}
