import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { PaymentAPI } from "@/api/modules/payment";
import Query from "@/models/query";

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const PaymentProviderSelect = observer<Props>(({ value, onChange }) => {
  const intl = useIntl();

  const query = useMemo(() => {
    const query = new Query(PaymentAPI.getProviders);
    query.submit();
    return query;
  }, []);

  const options =
    query.data?.map((item) => ({
      label: item.name,
      value: item.stringid,
    })) ?? [];

  return (
    <Select
      loading={query.isIdle || query.isPending}
      placeholder={intl.formatMessage({ defaultMessage: "Select provider" })}
      options={options}
      value={value}
      onChange={onChange}
      onClear={onChange}
      style={{ width: "100%" }}
      allowClear
    />
  );
});
