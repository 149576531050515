import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import BasePage from "@/ui/_common_/page";

import * as EditArticle from "../../article/edit";
import * as NewArticle from "../../article/new";
import { Content } from "../content";

export const Page: FC<{}> = observer(() => {
  const intl = useIntl();
  return (
    <BasePage title={intl.formatMessage({ defaultMessage: "Knowledge base" })}>
      <NewArticle.Modal />
      <EditArticle.Modal />
      <Content />
    </BasePage>
  );
});
