import { observer } from "mobx-react-lite";
import { FC } from "react";

import { BrandCreated, CreateBrand } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  return (
    <EventBasedModal
      title={"New brand"}
      openEvent={CreateBrand}
      closeEvent={BrandCreated}
    >
      {() => <Form />}
    </EventBasedModal>
  );
});
