import { Tabs } from "antd";
import { FC } from "react";

import Page from "@/ui/_common_/page";

import BonusTemplates from "./bonus-templates";
import BonusWagers from "./bonus-wagers";

const Bonuses: FC = () => {
  return (
    <Page title={"Bonuses"}>
      <Tabs defaultActiveKey="templates">
        <Tabs.TabPane tab={"Templates"} key="templates">
          <BonusTemplates />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Wager"} key="wagers">
          <BonusWagers />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

export default Bonuses;
