import * as yup from "yup";

declare module "yup" {
  export interface StringSchema {
    slug(): StringSchema;
    filePath(): StringSchema;
    pairingCode(): StringSchema;
    cvv(): StringSchema;
    pin(): StringSchema;
  }

  export interface NumberSchema {
    money(): NumberSchema;
  }
}

export default yup;
