import { observer } from "mobx-react-lite";

import { usePlayerUpdateFormState } from "./player-update-form.state";
import { PlayerUpdateFormView } from "./player-update-form.view";

export interface PlayerUpdateFormProps {
  playerId: number;
  setPlayerName(name: string): void;
}

export const PlayerUpdateForm = observer((props: PlayerUpdateFormProps) => {
  const state = usePlayerUpdateFormState(props);
  return <PlayerUpdateFormView state={state} />;
});
