import { red } from "@ant-design/colors";
import {
  EditOutlined,
  InfoCircleOutlined,
  LockOutlined,
  ReloadOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { Observer, observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import { CashierLimitEdited, EditCashierLimit, FinishShift } from "@/events";
import { CashiersStore, CashierStore, useGlobalStore } from "@/stores";
import AreYouSure from "@/ui/_common_/are-you-sure";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";
import { DeleteAction } from "@/ui/delete-action";

import CashierUpdateModal from "../cashier-update-modal";

type TProps = {
  cashiersStore: CashiersStore;
};

function CashiersTable({ cashiersStore: state }: TProps) {
  const { eventBusService } = useGlobalStore();

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<CashierStore> | SorterResult<CashierStore>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        (sorter.columnKey === "id" || sorter.columnKey === "login")
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
      state.filter();
    },
    [state],
  );

  useEffect(() => {
    const listener = (event: CashierLimitEdited) => {
      const store = state.cashierStores.find(
        (s) => s.cashier.id === event.payload.cashierId,
      );
      store?.fetch();
    };
    state.eventBusService.subscribe(CashierLimitEdited, listener);
    return () => {
      state.eventBusService.unsubscribe(CashierLimitEdited, listener);
    };
  }, [state.cashierStores, state.eventBusService]);

  const intl = useIntl();

  return (
    <Spin spinning={state.filterQuery.isPending || state.shiftsQuery.isPending}>
      <Space direction="vertical">
        <Row>
          <Col xs={0} sm={24}>
            <Table
              dataSource={state.cashierStores}
              showHeader={!!state.cashierStores.length}
              size="small"
              rowKey={(cashierStore) => cashierStore.cashier.id}
              bordered
              pagination={false}
              components={{ body: { row: CashiersTableRow } }}
              onChange={handleTableChange}
            >
              <Table.Column
                key="id"
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex={["cashier", "id"]}
                align="right"
                sorter={true}
                defaultSortOrder={
                  state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
                }
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="login"
                title={intl.formatMessage({ defaultMessage: "Username" })}
                dataIndex={["cashier", "login"]}
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="name"
                title={intl.formatMessage({ defaultMessage: "Name" })}
                dataIndex={["cashier", "name"]}
                width="100%"
                render={(name: string, cashierStore: CashierStore) => (
                  <>
                    {name}{" "}
                    {!!cashierStore.cashier.deletedAt && (
                      <Tag>
                        {intl.formatMessage({ defaultMessage: "DELETED" })}
                      </Tag>
                    )}
                  </>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Limit" })}
                colSpan={3}
                align="right"
                dataIndex={["cashier", "transactionsSum"]}
                render={(_, cashierStore: CashierStore) => ({
                  props: { style: { borderRight: 0 } },
                  children: (
                    <Observer>
                      {() => (
                        <MoneyFormatter
                          cents={cashierStore.cashier.transactionsSum}
                        />
                      )}
                    </Observer>
                  ),
                })}
              />
              <Table.Column
                colSpan={0}
                align="center"
                render={() => ({
                  props: { style: { borderRight: 0 } },
                  children: "/",
                })}
              />
              <Table.Column
                colSpan={0}
                align="right"
                dataIndex={["cashier", "transactionsLimit"]}
                render={(_, cashierStore: CashierStore) => (
                  <Observer>
                    {() => (
                      <Typography.Link
                        onClick={() =>
                          state.eventBusService.publish(
                            new EditCashierLimit({
                              cashierId: cashierStore.cashier.id,
                            }),
                          )
                        }
                      >
                        <MoneyFormatter
                          isInfinity={!cashierStore.cashier.transactionsLimit}
                          cents={cashierStore.cashier.transactionsLimit}
                        />
                      </Typography.Link>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Shift" })}
                colSpan={3}
                align="right"
                render={(_, cashierStore: CashierStore) => ({
                  props: { style: { borderRight: 0 } },
                  children: (
                    <Observer>
                      {() => {
                        if (
                          !cashierStore.cashier.isShiftModeEnabled ||
                          !cashierStore.shiftQuery ||
                          !cashierStore.shiftQuery.isFulfilled
                        ) {
                          return null;
                        }
                        if (!cashierStore.cashier.isShiftStarted) {
                          return (
                            <>
                              {intl.formatMessage({ defaultMessage: "closed" })}
                            </>
                          );
                        }
                        return (
                          <Typography.Link
                            onClick={() => {
                              eventBusService.publish(
                                new FinishShift({
                                  cashierId: cashierStore.cashier.id,
                                }),
                              );
                            }}
                          >
                            {new Date(
                              cashierStore.shift!.startedAt,
                            ).toLocaleString()}
                          </Typography.Link>
                        );
                      }}
                    </Observer>
                  ),
                })}
              />
              <Table.Column
                align="center"
                colSpan={0}
                render={(_, cashierStore: CashierStore) => ({
                  props: { style: { borderRight: 0 } },
                  children: (
                    <Observer>
                      {() => {
                        if (
                          !cashierStore.cashier.isShiftModeEnabled ||
                          !cashierStore.shiftQuery ||
                          !cashierStore.shiftQuery.isFulfilled
                        ) {
                          return null;
                        }
                        return <>/</>;
                      }}
                    </Observer>
                  ),
                })}
              />
              <Table.Column
                colSpan={0}
                align="right"
                dataIndex={["cashier", "transactionsLimit"]}
                render={(_, cashierStore: CashierStore) => {
                  return {
                    children: (
                      <Observer>
                        {() => {
                          if (
                            !cashierStore.cashier.isShiftModeEnabled ||
                            !cashierStore.cashier.isShiftStarted ||
                            !cashierStore.shiftQuery
                          ) {
                            return <>—</>;
                          }
                          if (cashierStore.shiftQuery.isRejected) {
                            return (
                              <Tooltip
                                overlay={cashierStore.shiftQuery.error.message}
                              >
                                <InfoCircleOutlined style={{ color: red[5] }} />
                              </Tooltip>
                            );
                          }
                          return (
                            <MoneyFormatter cents={cashierStore.shift!.cash} />
                          );
                        }}
                      </Observer>
                    ),
                  };
                }}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Actions" })}
                render={(_, cashierStore: CashierStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            icon={<ReloadOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Reload",
                            })}
                            onClick={cashierStore.fetch}
                          />
                        </Col>
                        <Col>
                          <CashierUpdateModal
                            cashierUpdateStore={cashierStore.updateStore}
                          />
                          <Button
                            size="small"
                            icon={<EditOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Edit",
                            })}
                            onClick={cashierStore.updateStore.modal.open}
                          />
                        </Col>
                        <Col>
                          <AreYouSure
                            onYes={cashierStore.block}
                            disabled={
                              cashierStore.cashier.isBlocked ||
                              cashierStore.cashier.isShiftStarted
                            }
                          >
                            <Button
                              size="small"
                              icon={
                                cashierStore.cashier.isBlocked ? (
                                  <UnlockOutlined />
                                ) : (
                                  <LockOutlined />
                                )
                              }
                              title={
                                cashierStore.cashier.isBlocked
                                  ? intl.formatMessage({
                                      defaultMessage: "Unblock",
                                    })
                                  : intl.formatMessage({
                                      defaultMessage: "Block",
                                    })
                              }
                              onClick={
                                cashierStore.cashier.isBlocked
                                  ? cashierStore.unblock
                                  : undefined
                              }
                              disabled={cashierStore.cashier.isShiftStarted}
                            />
                          </AreYouSure>
                        </Col>
                        <Col>
                          <DeleteAction
                            size="small"
                            entity={{ cashierId: cashierStore.cashier.id }}
                            isDeleted={!!cashierStore.cashier.deletedAt}
                            onSuccess={state.filter}
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
            </Table>
          </Col>
          <Col xs={24} sm={0}>
            {!state.cashierStores.length && <Empty />}
            <Space direction="vertical">
              {state.cashierStores.map((cashierStore) => (
                <Card
                  key={cashierStore.cashier.id}
                  size="small"
                  className={classNames({
                    "ant-table-row-color-gray":
                      !!cashierStore.cashier.deletedAt,
                  })}
                >
                  <Row gutter={[8, 8]}>
                    {!!cashierStore.cashier.deletedAt && (
                      <Tag>
                        {intl.formatMessage({ defaultMessage: "DELETED" })}
                      </Tag>
                    )}
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col
                      flex={1}
                      style={{
                        fontWeight: "bold",
                        color: cashierStore.cashier.isBlocked ? "red" : "",
                      }}
                    >
                      {cashierStore.cashier.name}
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {cashierStore.cashier.id}
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col style={{ color: "gray" }}>
                      {intl.formatMessage({ defaultMessage: "Login" })}:{" "}
                      {cashierStore.cashier.login}
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]} style={{ color: "gray" }}>
                    <Col style={{ marginRight: "auto" }}>
                      {intl.formatMessage({ defaultMessage: "Limit" })}:{" "}
                    </Col>
                    <Col>
                      <MoneyFormatter
                        cents={cashierStore.cashier.transactionsSum}
                      />
                    </Col>
                    <Col>/</Col>
                    <Col>
                      <Typography.Link
                        onClick={() =>
                          state.eventBusService.publish(
                            new EditCashierLimit({
                              cashierId: cashierStore.cashier.id,
                            }),
                          )
                        }
                      >
                        <MoneyFormatter
                          isInfinity={!cashierStore.cashier.transactionsLimit}
                          cents={cashierStore.cashier.transactionsLimit}
                        />
                      </Typography.Link>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]} style={{ color: "gray" }}>
                    <Col style={{ marginRight: "auto" }}>
                      {intl.formatMessage({ defaultMessage: "Shift" })}:{" "}
                    </Col>
                    <Col>
                      <Observer>
                        {() => {
                          if (
                            !cashierStore.cashier.isShiftModeEnabled ||
                            !cashierStore.shiftQuery ||
                            !cashierStore.shiftQuery.isFulfilled
                          ) {
                            return null;
                          }
                          if (!cashierStore.cashier.isShiftStarted) {
                            return (
                              <>
                                {intl.formatMessage({
                                  defaultMessage: "closed",
                                })}
                              </>
                            );
                          }
                          return (
                            <Typography.Link
                              onClick={() => {
                                eventBusService.publish(
                                  new FinishShift({
                                    cashierId: cashierStore.cashier.id,
                                  }),
                                );
                              }}
                            >
                              {new Date(
                                cashierStore.shift!.startedAt,
                              ).toLocaleString()}
                            </Typography.Link>
                          );
                        }}
                      </Observer>
                    </Col>
                    <Col>
                      <Observer>
                        {() => {
                          if (
                            !cashierStore.cashier.isShiftModeEnabled ||
                            !cashierStore.shiftQuery ||
                            !cashierStore.shiftQuery.isFulfilled
                          ) {
                            return null;
                          }
                          return <>/</>;
                        }}
                      </Observer>
                    </Col>
                    <Col>
                      <Observer>
                        {() => {
                          if (
                            !cashierStore.cashier.isShiftModeEnabled ||
                            !cashierStore.cashier.isShiftStarted ||
                            !cashierStore.shiftQuery
                          ) {
                            return <>—</>;
                          }
                          if (cashierStore.shiftQuery.isRejected) {
                            return (
                              <Tooltip
                                overlay={cashierStore.shiftQuery.error.message}
                              >
                                <InfoCircleOutlined style={{ color: red[5] }} />
                              </Tooltip>
                            );
                          }
                          return (
                            <MoneyFormatter cents={cashierStore.shift!.cash} />
                          );
                        }}
                      </Observer>
                    </Col>
                  </Row>
                  <Divider type="horizontal" style={{ margin: "8px 0" }} />
                  <Row
                    wrap={false}
                    gutter={[8, 8]}
                    align="middle"
                    justify="end"
                  >
                    <Col>
                      <Button
                        icon={<ReloadOutlined />}
                        title={intl.formatMessage({ defaultMessage: "Reload" })}
                        onClick={cashierStore.fetch}
                        loading={cashierStore.fetchQuery.isPending}
                      />
                    </Col>
                    <Col>
                      <Button
                        icon={<EditOutlined />}
                        title={intl.formatMessage({
                          defaultMessage: "Edit",
                        })}
                        onClick={cashierStore.updateStore.modal.open}
                      />
                    </Col>
                    <Col>
                      <AreYouSure
                        onYes={cashierStore.block}
                        disabled={
                          cashierStore.cashier.isBlocked ||
                          cashierStore.cashier.isShiftStarted
                        }
                      >
                        <Button
                          icon={
                            cashierStore.cashier.isBlocked ? (
                              <UnlockOutlined />
                            ) : (
                              <LockOutlined />
                            )
                          }
                          title={
                            cashierStore.cashier.isBlocked
                              ? intl.formatMessage({
                                  defaultMessage: "Unblock",
                                })
                              : intl.formatMessage({
                                  defaultMessage: "Block",
                                })
                          }
                          onClick={
                            cashierStore.cashier.isBlocked
                              ? cashierStore.unblock
                              : undefined
                          }
                          disabled={cashierStore.cashier.isShiftStarted}
                        />
                      </AreYouSure>
                    </Col>
                    <Col>
                      <DeleteAction
                        entity={{ cashierId: cashierStore.cashier.id }}
                        isDeleted={!!cashierStore.cashier.deletedAt}
                        onSuccess={state.filter}
                      />
                    </Col>
                  </Row>
                </Card>
              ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          query={state.filterQuery}
          onChange={() => {
            state.filter({ preservePageNumber: true });
          }}
        />
      </Space>
    </Spin>
  );
}

type TChild = { props: { record: CashierStore } };

type TCashiersTableRowProps = {
  className: string;
  children: TChild | TChild[];
};

const CashiersTableRow = observer(function CashiersTableRow(
  props: TCashiersTableRowProps,
) {
  if (!Array.isArray(props.children)) {
    return <tr {...props} />;
  }
  const cashierStore = props.children[0].props.record;
  if (cashierStore.fetchQuery.isPending || cashierStore.updateQuery.isPending) {
    return (
      <tr className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>
    );
  }
  return (
    <tr
      {...props}
      className={classNames(props.className, {
        "ant-table-row-color-red": cashierStore.cashier.isBlocked,
        "ant-table-row-color-gray": !!cashierStore.cashier.deletedAt,
      })}
    />
  );
});

export default observer(CashiersTable);
