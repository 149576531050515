import yup from "@/yup";

export function useLastchanceSchema() {
  return yup.object({
    id: yup.number().integer().required(),
    thresholdForAutoIssue: yup.number().integer().min(0),
    isEnabled: yup.boolean(),
    depositPercentage: yup.number().integer().min(1).max(100),
    cooldown: yup.number().integer().min(0),
    playerBalanceThreshold: yup.number().min(0),
  });
}

export type ILastchanceSchema = yup.InferType<
  ReturnType<typeof useLastchanceSchema>
>;

export function useBouncebackSchema() {
  return yup.object({
    id: yup.number().integer().required(),
    minDeposit: yup.number().integer().moreThan(0),
    maxDeposit: yup
      .number()
      .integer()
      .when("minDeposit", {
        is: (value: number) => Number.isSafeInteger(value),
        then: (schema) => schema.moreThan(yup.ref("minDeposit")),
      }),
    thresholdForAutoIssue: yup.number().integer().min(0),
    depositPercentage: yup.number().integer().min(1).max(100),
    maxActivationsCount: yup.number().integer().moreThan(0),
    cooldown: yup.number().integer().min(0),
    isWagerEnabled: yup.boolean(),
    isEnabled: yup.boolean(),
    happyHours: yup
      .object({
        isEnabled: yup.boolean().defined(),
        dateTimeRange: yup
          .object({
            from: yup.string().required(),
            to: yup.string().required(),
          })
          .required(),
        boost: yup.number().required(),
      })
      .default(undefined),
  });
}

export type IBouncebackSchema = yup.InferType<
  ReturnType<typeof useBouncebackSchema>
>;

export function useCashbackSchema() {
  return yup.object({
    id: yup.number().integer().required(),
    minDeposit: yup.number().integer().moreThan(0),
    maxDeposit: yup
      .number()
      .integer()
      .when("minDeposit", {
        is: (value: number) => Number.isSafeInteger(value),
        then: (schema) => schema.moreThan(yup.ref("minDeposit")),
      }),
    thresholdForAutoIssue: yup.number().integer().min(0),
    depositPercentage: yup.number().integer().min(1).max(100),
    maxActivationsCount: yup.number().integer().moreThan(0),
    playerBalanceThreshold: yup.number().min(0),
    cooldown: yup.number().integer().min(0),
    isWagerEnabled: yup.boolean(),
    isEnabled: yup.boolean(),
    happyHours: yup
      .object({
        isEnabled: yup.boolean().defined(),
        dateTimeRange: yup
          .object({
            from: yup.string().required(),
            to: yup.string().required(),
          })
          .required(),
        boost: yup.number().required(),
      })
      .default(undefined),
  });
}

export type ICashbackSchema = yup.InferType<
  ReturnType<typeof useCashbackSchema>
>;
