import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  CategoryCreated,
  CloseNewCategoryModal,
  OpenNewCategoryModal,
} from "@/events";
import { useGlobalStore } from "@/stores";

export function useNewCategoryModalState() {
  const intl = useIntl();
  const [opened, setOpened] = useState(false);
  const [parentId, setParentId] = useState<number | null>(null);

  const { eventBusService } = useGlobalStore();

  useEffect(() => {
    const listener = (event: OpenNewCategoryModal) => {
      setOpened(true);
      setParentId(event.payload.parentId);
    };

    eventBusService.subscribe(OpenNewCategoryModal, listener);

    return () => {
      eventBusService.unsubscribe(OpenNewCategoryModal, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function close() {
    setOpened(false);
    setParentId(null);
    eventBusService.publish(new CloseNewCategoryModal({}));
  }

  useEffect(() => {
    const listener = () => close();

    eventBusService.subscribe(CategoryCreated, listener);

    return () => {
      eventBusService.unsubscribe(CategoryCreated, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, opened, close, parentId };
}

export type NewCategoryModalState = ReturnType<typeof useNewCategoryModalState>;
