import { notification } from "antd";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SportClubAPI, SportPartnerAPI } from "@/api";
import { SportSettingsAPI } from "@/api/modules/sport-settings";
import Query from "@/models/query";
import { SelectorStore, useGlobalStore } from "@/stores";

import { SportSettingsProps } from "./form";

export function useSportSettingsState(props: SportSettingsProps) {
  const intl = useIntl();

  const { permissionsStore } = useGlobalStore();

  const clubSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: SportClubAPI.filter,
      getByIdMethod: SportClubAPI.getById,
      renderLabel(item) {
        return item.id + " — " + item.description;
      },
      isPaginationEnabled: true,
    });
    return store;
  }, []);

  const partnerSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: SportPartnerAPI.filter,
      getByIdMethod: SportPartnerAPI.getById,
      renderLabel(item) {
        return item.id + " — " + item.description;
      },
      isPaginationEnabled: true,
      onSelect(item) {
        clubSelectorStore.setSelectedId(undefined);
        clubSelectorStore.setParameters({
          partnerId: item?.id ?? undefined,
        });
        clubSelectorStore.fetchItems();
      },
    });
    store.setParameters({ currency: props.currency });
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currency]);

  const query = useMemo(() => {
    const query = new Query(async () => {
      const settings = await SportSettingsAPI.get(props.entity);

      partnerSelectorStore.setSelectedId(settings.partnerId ?? undefined);
      partnerSelectorStore.setParameters({ currency: props.currency });
      partnerSelectorStore.fetchItems();

      clubSelectorStore.setSelectedId(settings.clubId ?? undefined);
      clubSelectorStore.setParameters({
        partnerId: settings.partnerId ?? undefined,
      });
      clubSelectorStore.fetchItems();

      return settings;
    });
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currency]);

  const mutation = useMemo(() => {
    return new Query(async () => {
      await SportSettingsAPI.update({
        ...props.entity,
        clubId: clubSelectorStore.selectedId ?? null,
      });

      notification.success({
        message: "",
        description: "Settings updated successfully.",
      });

      query.submit({});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetMutation = useMemo(() => {
    return new Query(async () => {
      await SportSettingsAPI.reset(props.entity);

      notification.success({
        message: "",
        description: "Settings reset successfully.",
      });

      query.submit({});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...props,
    intl,

    query,
    mutation,
    resetMutation,

    partnerSelectorStore,
    clubSelectorStore,

    permissionsStore,
  };
}

export type SportSettingsState = ReturnType<typeof useSportSettingsState>;
