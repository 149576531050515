import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI, HallAPI, KioskAPI, WebsiteAPI } from "@/api";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { IntlService } from "@/services";
import { PermissionsStore } from "@/stores";

import { Entity, Props } from "./EditForm";

export type Website = {
  id: number;
  domainName: string;
  isOccupied: boolean;
  hasAccess: boolean;
  isEnabled: boolean;
};

export class EditFormState implements ViewModel<Props> {
  constructor(
    private intlService: IntlService,
    public permissionsStore: PermissionsStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _entity?: Entity;

  get entity() {
    return this._entity!;
  }

  private set entity(entity: Entity) {
    this._entity = entity;
  }

  private _isOccupiedWebsitesHidden = false;

  get isOccupiedWebsitesHidden() {
    return this._isOccupiedWebsitesHidden;
  }

  set isOccupiedWebsitesHidden(value: boolean) {
    this._isOccupiedWebsitesHidden = value;

    this.computeWebsites();
  }

  onViewMount = async ({ entity }: Props) => {
    this.entity = entity;

    this.fetchData();
  };

  onViewUnmount = () => {
    this.agentQuery.reset();
    this.hallQuery.reset();

    this.websitesQuery.reset();
    this.parentWebsitesQuery.reset();
    this.childWebsitesQuery.reset();

    this.grantAccessQuery.reset();
    this.revokeAccessQuery.reset();
  };

  private fetchData = async () => {
    if ("clientId" in this.entity) {
      await Promise.all([
        this.websitesQuery.submit(),
        this.childWebsitesQuery.submit({ clientId: this.entity.clientId }),
      ]);
    } else if ("agentId" in this.entity) {
      await this.agentQuery.submit({ id: this.entity.agentId });
      const agent = this.agentQuery.data!;
      const parentEntity = agent.parentAgentId
        ? { agentId: agent.parentAgentId }
        : { clientId: agent.clientId };
      await Promise.all([
        this.parentWebsitesQuery.submit(parentEntity),
        this.childWebsitesQuery.submit({ agentId: agent.id }),
      ]);
    } else if ("hallId" in this.entity) {
      await this.hallQuery.submit({ id: this.entity.hallId });
      const hall = this.hallQuery.data!;
      const parentEntity = hall.agentId
        ? { agentId: hall.agentId }
        : { clientId: hall.clientId };
      await Promise.all([
        this.parentWebsitesQuery.submit(parentEntity),
        this.childWebsitesQuery.submit({ hallId: hall.id }),
      ]);
    } else if ("kioskId" in this.entity) {
      const { kioskId } = this.entity;
      await this.kioskQuery.submit({ id: kioskId });
      const { hallId } = this.kioskQuery.data!;
      await Promise.all([
        this.parentWebsitesQuery.submit({ hallId }),
        this.childWebsitesQuery.submit({ kioskId }),
      ]);
    }

    this._priorityWebsiteId =
      this.childWebsitesQuery.data!.data.find((w) => w.hasPriority)?.id ?? null;

    this.computeWebsites();
  };

  agentQuery = new Query(AgentAPI.getById);
  hallQuery = new Query(HallAPI.getById);
  kioskQuery = new Query(KioskAPI.getById);
  websitesQuery = new Query(WebsiteAPI.getAll);
  parentWebsitesQuery = new Query(WebsiteAPI.filter);
  childWebsitesQuery = new Query(WebsiteAPI.filter);
  grantAccessQuery = new Query(WebsiteAPI.grantAccess);
  revokeAccessQuery = new Query(WebsiteAPI.revokeAccess);
  enableQuery = new Query(WebsiteAPI.enable);
  disableQuery = new Query(WebsiteAPI.disable);
  setPriorityQuery = new Query(WebsiteAPI.setPriority);

  websites: Website[] = [];

  computeWebsites() {
    const websites =
      this.websitesQuery.data?.data ??
      this.parentWebsitesQuery.data?.data ??
      [];

    this.websites = websites
      .filter((website) => {
        if (this.isOccupiedWebsitesHidden && "clientId" in this.entity) {
          return (
            website.clientId === this.entity.clientId ||
            website.clientId === null
          );
        }
        return website.isEnabled ?? true;
      })
      .map((website) => {
        const childWebsite = this.childWebsitesQuery.data!.data.find(
          (w) => w.id === website.id,
        );

        const isOccupied =
          "clientId" in this.entity &&
          website.clientId !== this.entity.clientId &&
          website.clientId !== null;

        const hasAccess = !isOccupied && !!childWebsite;
        const isEnabled = !isOccupied && (childWebsite?.isEnabled ?? false);

        return {
          id: website.id,
          domainName: website.domainName,
          hasAccess,
          isEnabled,
          isOccupied,
        };
      });
  }

  private _priorityWebsiteId: number | null = null;

  get priorityWebsiteId(): number | null {
    return this._priorityWebsiteId;
  }

  set priorityWebsiteId(id: number | null) {
    this._priorityWebsiteId = id;
  }

  get isIdleOrPending() {
    if (this.entity === undefined) {
      return true;
    }

    if ("clientId" in this.entity) {
      if (this.websitesQuery.isIdle || this.websitesQuery.isPending) {
        return true;
      }
    } else if (
      this.parentWebsitesQuery.isIdle ||
      this.parentWebsitesQuery.isPending
    ) {
      return true;
    }

    if (this.childWebsitesQuery.isIdle || this.childWebsitesQuery.isPending) {
      return true;
    }

    return false;
  }

  get isRejected() {
    return (
      this.agentQuery.isRejected ||
      this.hallQuery.isRejected ||
      this.websitesQuery.isRejected ||
      this.childWebsitesQuery.isRejected ||
      this.parentWebsitesQuery.isRejected
    );
  }

  handlePriorityChange(website: Website) {
    if (this.priorityWebsiteId === website.id) {
      this.priorityWebsiteId = null;
    } else {
      this.priorityWebsiteId = website.id;
    }
  }

  handleHasAccessChange(website: Website, value = !website.hasAccess) {
    website.hasAccess = value;
    if (!website.hasAccess) {
      this.handleEnableChange(website, false);
    }
  }

  handleEnableChange(website: Website, value = !website.isEnabled) {
    website.isEnabled = value;
    if (!value && this.priorityWebsiteId === website.id) {
      this.priorityWebsiteId = null;
    }
  }

  handleSubmit = async () => {
    const websiteIdsToGrantAccess = this.websites
      .filter((w) => w.hasAccess)
      .map((w) => w.id);

    const websiteIdsToRevokeAccess = this.websites
      .filter((w) => !w.hasAccess)
      .map((w) => w.id);

    const websiteIdsToEnable = this.websites
      .filter((w) => w.hasAccess && w.isEnabled)
      .map((w) => w.id);

    const websiteIdsToDisable = this.websites
      .filter((w) => w.hasAccess && !w.isEnabled)
      .map((w) => w.id);

    const promises = [
      this.grantAccessQuery.submit({
        ...this.entity,
        ids: websiteIdsToGrantAccess,
      }),
      this.revokeAccessQuery.submit({
        ...this.entity,
        ids: websiteIdsToRevokeAccess,
      }),
      this.enableQuery.submit({
        ...this.entity,
        ids: websiteIdsToEnable,
      }),
      this.disableQuery.submit({
        ...this.entity,
        ids: websiteIdsToDisable,
      }),
    ];

    if ("hallId" in this.entity) {
      promises.push(
        this.setPriorityQuery.submit({
          hallId: this.entity.hallId,
          websiteId: this.priorityWebsiteId,
        }),
      );
    }

    await Promise.all(promises);

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The website settings have been successfully updated.",
      }),
    });
  };
}
