import { Card, Col, Input, Row } from "antd";
import { Select, Table } from "antd/es";
import { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl/lib";
import { Link } from "react-router-dom";

import { TGlobalSearchEntityType, TGlobalSearchItem } from "@/api";
import { SearchParamKey } from "@/constants";
import { useGlobalStore } from "@/stores";
import Page from "@/ui/_common_/page";

import { GlobalSearchState } from "./global-search.state";

type Props = {
  state: GlobalSearchState;
};

export const GlobalSearchView = observer<Props>(({ state }) => {
  const intl = useIntl();
  const { userStore } = useGlobalStore();
  const { user } = userStore;
  const columns = useMemo(() => {
    const columns: ColumnsType<TGlobalSearchItem> = [
      {
        dataIndex: "id",
        title: intl.formatMessage({ defaultMessage: "ID" }),
      },
      {
        dataIndex: "type",
        title: intl.formatMessage({ defaultMessage: "Type" }),
      },
      {
        dataIndex: "name",
        title: intl.formatMessage({ defaultMessage: "Name" }),
        render: (_, item: TGlobalSearchItem) => {
          const url = new URL(window.location.origin);

          url.pathname = {
            Agent: `/agents`,
            Hall: `/shops`,
            Cashier: `/cashiers`,
            Player: `/players`,
            Kiosk: `/kiosks`,
          }[item.type];

          url.searchParams.set(
            SearchParamKey.ClientId,
            item.clientId.toString(),
          );

          if (item.agentId) {
            url.searchParams.set(
              SearchParamKey.AgentId,
              item.agentId.toString(),
            );
          }

          if (item.hallId) {
            url.searchParams.set(SearchParamKey.HallId, item.hallId.toString());
          }

          if (item.name) {
            url.searchParams.set(SearchParamKey.SearchQuery, item.name);
          }

          return <Link to={url}>{item.name}</Link>;
        },
      },
      {
        dataIndex: "login",
        title: intl.formatMessage({ defaultMessage: "Login" }),
        render: (_, item: TGlobalSearchItem) => {
          const url = new URL(window.location.origin);

          url.pathname = {
            Agent: `/agents`,
            Hall: `/shops`,
            Cashier: `/cashiers`,
            Player: `/players`,
            Kiosk: `/kiosks`,
          }[item.type];

          url.searchParams.set(
            SearchParamKey.ClientId,
            item.clientId.toString(),
          );

          if (item.agentId) {
            url.searchParams.set(
              SearchParamKey.AgentId,
              item.agentId.toString(),
            );
          }

          if (item.hallId) {
            url.searchParams.set(SearchParamKey.HallId, item.hallId.toString());
          }

          if (item.login) {
            url.searchParams.set(SearchParamKey.SearchQuery, item.login);
          }

          return <Link to={url}>{item.login}</Link>;
        },
      },
    ];

    if (user.role !== "ClientAdmin") {
      columns.push({
        dataIndex: "clientName",
        title: intl.formatMessage({ defaultMessage: "Client" }),
        render: (_, item: TGlobalSearchItem) => {
          const url = new URL(window.location.origin);
          url.pathname = "/clients";
          url.searchParams.set(SearchParamKey.SearchQuery, item.clientName);
          return (
            <>
              <span>[{item.clientId}] </span>
              <Link to={`/clients`}>{item.clientName}</Link>
            </>
          );
        },
      });
    }

    columns.push(
      {
        dataIndex: "agentName",
        title: intl.formatMessage({ defaultMessage: "Agent" }),
        render: (_, item: TGlobalSearchItem) => {
          if (!item.agentId) {
            return "—";
          }
          const url = new URL(window.location.origin);
          url.pathname = "/agents";
          url.searchParams.set(
            SearchParamKey.ClientId,
            item.clientId.toString(),
          );
          url.searchParams.set(
            SearchParamKey.SearchQuery,
            item.agentName ?? "",
          );
          return (
            <>
              <span>[{item.agentId}] </span>
              <Link to={url}>{item.agentName}</Link>
            </>
          );
        },
      },
      {
        dataIndex: "hallName",
        title: intl.formatMessage({ defaultMessage: "Shop" }),
        render: (_, item: TGlobalSearchItem) => {
          if (!item.hallId) {
            return "—";
          }
          const url = new URL(window.location.origin);
          url.pathname = "/shops";
          url.searchParams.set(
            SearchParamKey.ClientId,
            item.clientId.toString(),
          );
          if (item.agentId) {
            url.searchParams.set(
              SearchParamKey.AgentId,
              item.agentId.toString(),
            );
          }
          url.searchParams.set(SearchParamKey.SearchQuery, item.hallName ?? "");
          return (
            <>
              <span>[{item.hallId}] </span>
              <Link to={url}>{item.hallName}</Link>
            </>
          );
        },
      },
    );

    return columns;
  }, [intl, user.role]);
  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "Search",
      })}
    >
      <Card size="small">
        <Row justify="space-between" gutter={[8, 8]}>
          <Col xs={24} md={16} lg={12} xl={8}>
            <Select
              mode="multiple"
              placeholder="Please select"
              style={{ width: "100%" }}
              defaultValue={state.searchBy}
              onChange={(value: TGlobalSearchEntityType[]) => {
                state.searchBy = value;
              }}
            >
              <Select.Option value="Agent">
                {intl.formatMessage({ defaultMessage: "Agent" })}
              </Select.Option>
              <Select.Option value="Hall">
                {intl.formatMessage({ defaultMessage: "Shop" })}
              </Select.Option>
              <Select.Option value="Cashier">
                {intl.formatMessage({ defaultMessage: "Cashier" })}
              </Select.Option>
              <Select.Option value="Player">
                {intl.formatMessage({ defaultMessage: "Player" })}
              </Select.Option>
              <Select.Option value="Kiosk">
                {intl.formatMessage({ defaultMessage: "Kiosk" })}
              </Select.Option>
            </Select>
          </Col>
          <Col>
            <Input.Search
              placeholder={intl.formatMessage({
                defaultMessage: "Search...",
              })}
              allowClear
              defaultValue={state.searchQuery}
              onSearch={(value) => {
                state.searchQuery = value;
                state.fetch();
              }}
            />
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Table
          size="small"
          loading={state.filterQuery.isPending}
          dataSource={
            state.filterQuery.isFulfilled ? state.filterQuery.data.data : []
          }
          columns={columns}
          bordered
          pagination={false}
        />
      </Card>
    </Page>
  );
});
