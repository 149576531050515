import { ResultStatusType } from "antd/lib/result";
import { makeAutoObservable } from "mobx";

import { KnowledgeBaseAPI } from "@/api";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { MarkdownService, TemplateService } from "@/services";

import { Props } from "./Content";
import template from "./template.html";

export class ContentState implements ViewModel<Props> {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = async ({ slug }: Props) => {
    await this.articleQuery.submit({ slug });
  };

  articleQuery = new Query(async ({ slug }: { slug: string }) => {
    const article = await KnowledgeBaseAPI.getArticle({ slug });
    const url = new URL(article.fileUrl);
    url.searchParams.set("t", Date.now().toString());
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new HTTPError(response.status, response.statusText);
    }
    const markdown = await response.text();
    const markdownService = new MarkdownService(markdown, article.fileUrl);
    const content = await markdownService.compile();
    const html = TemplateService.compile(template, {
      title: article.name,
      content,
    });
    return {
      ...article,
      html,
    };
  });

  get article() {
    return this.articleQuery.data!;
  }

  mapHTTPStatusToResultStatus = (status: number): ResultStatusType => {
    if (status === 403 || status === 404 || status === 500) {
      return status;
    }
    if (status >= 500) {
      return 500;
    }
    return "error";
  };
}

export class HTTPError extends Error {
  constructor(
    public status: number,
    public statusText: string,
  ) {
    super(`[${status}] ${statusText}`);
  }
}
