import { observer } from "mobx-react-lite";
import { FC } from "react";

import { TBonus, TBonusTemplate } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import { Entity } from "@/types";

import { FormState } from "./FormState";
import { FormView } from "./FormView";

export type Props = {
  entity: Entity;
  readOnly?: boolean;
  selfEdit?: boolean;
  // TODO: Fix backend API to fetch parent bonuses for client
  bonusType: TBonus["type"];
  bonus?: TBonus;
  parentBonus?: TBonus;
  templates: TBonusTemplate[];
};

export const Form: FC<Props> = observer(
  ({ readOnly = false, selfEdit = false, ...props }) => {
    const { eventBusService, intlService, permissionsStore } = useGlobalStore();
    const vm = useViewModel(
      () => new FormState(eventBusService, intlService, permissionsStore),
      {
        readOnly,
        selfEdit,
        ...props,
      },
    );
    return <FormView state={vm} />;
  },
);
