import { useMemo } from "react";
import { useIntl } from "react-intl";

import { TVoucherTemplate, VoucherTemplateAPI } from "@/api";
import Query from "@/models/query";

import { PosTemplateProps } from "./pos-template";

export function usePosTemplateState(props: PosTemplateProps) {
  const intl = useIntl();

  const templateQuery = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await VoucherTemplateAPI.getById(props.entity);
    });
    query.submit();
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuery = useMemo(() => {
    return new Query(async ({ lines }: { lines: TVoucherTemplate[] }) => {
      await VoucherTemplateAPI.update({
        ...props.entity,
        template: JSON.stringify(lines),
      });
      await templateQuery.submit();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteQuery = useMemo(() => {
    return new Query(async (_: void) => {
      await VoucherTemplateAPI.delete({ ...props.entity });
      await templateQuery.submit();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, templateQuery, submitQuery, deleteQuery };
}

export type PosTemplateState = ReturnType<typeof usePosTemplateState>;
