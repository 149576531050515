import { makeAutoObservable } from "mobx";

import { TMethod, TPaging } from "@/api";
import { SearchParamKey } from "@/constants";

import Query from "./query";

type TRequest = { [key: string]: any };

type TResponse<T> = {
  data: T[];
  total: number;
};

export type TSubmitOptions = {
  preservePageNumber?: boolean;
};

type TOrder = [string, "asc" | "desc"];

type ItemsQuery<
  T extends Unpacked<O["data"]> = any,
  I extends TRequest = any,
  O extends TResponse<any> = any,
> = Query<I, O> & {
  items: T[];
  total: number;

  pageNumber: number;
  pageSize: number;
  setPageNumber: (page: number) => void;
  setPageSize: (size: number) => void;

  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;

  order: TOrder;
  setOrder: (order: TOrder) => void;

  submit: (
    request: DistributiveOmit<I, "searchQuery" | "orderBy" | "paging">,
    options?: TSubmitOptions,
  ) => Promise<void>;
};

type TOptions = {
  isPaginationEnabled: boolean;
  isSearchEnabled: boolean;
} & (
  | { isOrderEnabled: false }
  | {
      isOrderEnabled: true;
      initialOrder: TOrder;
    }
);

class ItemsQueryImplementation<T, I extends TRequest, O extends TResponse<T>> {
  constructor(
    private method: TMethod<I, O>,
    private options: TOptions,
  ) {
    makeAutoObservable(this);

    if (options.isOrderEnabled) {
      this._order = options.initialOrder;
    }
  }

  private _pagination: TPaging = { limit: 20, offset: 0 };

  get pageNumber() {
    return Math.trunc(this._pagination.offset / this._pagination.limit) + 1;
  }

  setPageNumber = (pageNumber: number) => {
    this._pagination.offset = this._pagination.limit * (pageNumber - 1);
  };

  get pageSize() {
    return this._pagination.limit;
  }

  setPageSize = (pageSize: number) => {
    this._pagination.limit = pageSize;
  };

  private _searchQuery = (() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(SearchParamKey.SearchQuery) ?? "";
  })();

  get searchQuery() {
    return this._searchQuery;
  }

  setSearchQuery = (searchQuery: string) => {
    this._searchQuery = searchQuery;

    const url = new URL(location.href);
    url.searchParams.set(SearchParamKey.SearchQuery, searchQuery);
    history.replaceState(history.state, "", url);
  };

  private _order: TOrder = ["id", "asc"];
  get order() {
    return this._order;
  }

  setOrder = (order: TOrder) => {
    this._order = order;
  };

  private query = new Query(this.method);

  get items() {
    return this.query.data?.data ?? [];
  }

  get total() {
    return this.query.data?.total ?? 0;
  }

  get request() {
    return this.query.request;
  }

  get state() {
    return this.query.state;
  }

  get error() {
    return this.query.error;
  }

  get isIdle() {
    return this.query.isIdle;
  }

  get isPending() {
    return this.query.isPending;
  }

  get isFulfilled() {
    return this.query.isFulfilled;
  }

  get isRejected() {
    return this.query.isRejected;
  }

  submit = (
    request: DistributiveOmit<I, "searchQuery" | "orderBy" | "paging">,
    { preservePageNumber = false }: TSubmitOptions = {},
  ) => {
    const _request: Record<string, any> = { ...request };

    const searchQuery = this._searchQuery.trim();
    if (this.options.isSearchEnabled && searchQuery.length > 0) {
      _request["searchQuery"] = searchQuery;
    }

    if (this.options.isOrderEnabled) {
      _request["orderBy"] = this._order;
    }

    if (!preservePageNumber) {
      this._pagination.offset = 0;
    }
    if (this.options.isPaginationEnabled) {
      _request["paging"] = this._pagination;
    }
    return this.query.submit(_request as I);
  };

  get abort() {
    return this.query.abort;
  }

  get reset() {
    return this.query.reset;
  }

  get reject() {
    return this.query.reject;
  }

  get resolve() {
    return this.query.resolve;
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ItemsQuery = ItemsQueryImplementation as {
  new <
    T extends Unpacked<O["data"]>,
    I extends TRequest,
    O extends TResponse<any>,
  >(
    method: TMethod<I, O>,
    options: TOptions,
  ): ItemsQuery<T, I, O>;
};

export default ItemsQuery;
