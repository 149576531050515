import { Col, Row, Space, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";
import { CurrentBonusState } from "@/ui/players/withdraw/modal-bonuses/state";

const CurrentBonusTableComponent = ({
  state,
}: {
  state: CurrentBonusState;
}) => {
  const intl = useIntl();

  if (state.isRejected) {
    return <ErrorsFormatter queries={[state.currentBonusQuery]} />;
  }

  return (
    <Space direction="vertical">
      <Row>
        <Col xs={0} sm={24}>
          <Table
            loading={state.isIdleOrPending}
            size="small"
            rowKey={(bonus) => bonus.acceptedTime}
            dataSource={
              state?.isIdleOrPending ? [] : state.currentBonusQuery.items
            }
            bordered
            pagination={false}
          >
            <Table.Column
              key="Date"
              title={intl.formatMessage({ defaultMessage: "Date" })}
              align="center"
              render={({ acceptedTime }) =>
                new Date(acceptedTime).toLocaleString()
              }
            />
            <Table.Column
              key="Bonus"
              title={intl.formatMessage({ defaultMessage: "Bonus" })}
              align="center"
              render={({ type }) => type}
            />
            <Table.Column
              key="Amount"
              title={intl.formatMessage({ defaultMessage: "Amount" })}
              align="center"
              render={({ amount }) => (
                <Col style={{ marginLeft: "auto" }}>
                  <MoneyFormatter cents={amount ?? 0} fractionDigits={2} />
                </Col>
              )}
            />
            <Table.Column
              key="Wager"
              title={intl.formatMessage({ defaultMessage: "Wager" })}
              align="center"
              render={({ wager }) => (
                <Col style={{ marginLeft: "auto" }}>
                  <MoneyFormatter cents={wager ?? 0} fractionDigits={2} />
                </Col>
              )}
            />
            <Table.Column
              key="Wager Remain"
              title={intl.formatMessage({
                defaultMessage: "Wager Remain",
              })}
              align="center"
              render={({ remainingWager }) => (
                <Col style={{ marginLeft: "auto" }}>
                  <MoneyFormatter
                    cents={remainingWager ?? 0}
                    fractionDigits={2}
                  />
                </Col>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Pagination
        query={state.currentBonusQuery}
        onChange={() => {
          state.filter({ preservePageNumber: true });
        }}
      />
    </Space>
  );
};

export const CurrentBonusTable = observer(CurrentBonusTableComponent);
