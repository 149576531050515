import { Button, notification } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { KioskAccessAPI } from "@/api";
import { KioskAccessRemoved } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

interface Props {
  id: number;
  hallId: number;
  clientId: number;
}

export const RemoveKioskAccessButton = observer(
  ({ id, hallId, clientId }: Props) => {
    const intl = useIntl();

    const { eventBusService } = useGlobalStore();

    const query = useMemo(() => {
      return new Query(async () => {
        await KioskAccessAPI.delete({ id, hallId, clientId });

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "The kiosk access was successfully removed.",
          }),
        });

        eventBusService.publish(new KioskAccessRemoved({}));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (query.error?.message) {
        notification.error({
          message: "",
          description: query.error.message,
        });
      }
    }, [query.error?.message]);

    return (
      <Button size="small" loading={query.isPending} onClick={query.submit}>
        {intl.formatMessage({ defaultMessage: "Remove" })}
      </Button>
    );
  },
);
