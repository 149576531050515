import { TJackpotSlotTemplate } from "..";
import MethodBuilder from "../method-builder";

class JackpotSlotTemplateAPI {
  static getAll = new MethodBuilder<void, { data: TJackpotSlotTemplate[] }>()
    .withName("JackpotSlotTemplate.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("JackpotSlotTemplate.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("JackpotSlotTemplate.export")
    .build();
}

export default JackpotSlotTemplateAPI;
