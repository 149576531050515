import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { BrandAPI, GameAPI, ProviderAPI } from "@/api";
import { GameCreated, UpdateGame } from "@/events";
import Query from "@/models/query";
import { SelectorStore, useGlobalStore } from "@/stores";

import { FormProps } from "./form";
import { FormSchema, useFormSchema } from "./form.schema";

export function useFormState(props: FormProps) {
  const intl = useIntl();

  const schema = useFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<FormSchema>({ resolver });

  const providerId = form.watch("providerId");

  const providerSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: ProviderAPI.filter,
      getByIdMethod: ProviderAPI.getById,
      labelKey: "name",
      onSelect(provider) {
        if (provider?.id) {
          form.setValue("providerId", provider.id);
        } else {
          form.resetField("providerId");
        }
      },
    });
    store.setSelectedId(props.providerId);
    store.setParameters({});
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.providerId]);

  const brandSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: BrandAPI.filter,
      getByIdMethod: BrandAPI.getById,
      labelKey: "name",
      onSelect(brand) {
        if (brand?.id) {
          form.setValue("brandId", brand.id);
        } else {
          form.resetField("brandId");
        }
      },
    });
    store.setSelectedId(props.brandId);
    store.setParameters({ providerId });
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId, props.brandId]);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: FormSchema) => {
      const { data } = await GameAPI.massCreate({ games: [values] });
      const { id } = data[0];
      eventBusService.publish(new GameCreated({}));
      eventBusService.publish(new UpdateGame({ id }));
      message.success({
        content: "The game was successfully created.",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = form.handleSubmit((values) => {
    submitQuery.submit(values);
  });

  useEffect(() => {
    form.reset({
      providerId: props.providerId,
      brandId: props.brandId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.brandId, props.providerId]);

  return {
    intl,

    form,
    providerId,

    submitQuery,

    providerSelectorStore,
    brandSelectorStore,

    handleSubmit,
  };
}

export type FormState = ReturnType<typeof useFormState>;
