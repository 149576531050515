import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { CheckPlayerBonuses } from "@/events";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import { CurrencyUtilities } from "@/utilities";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });
  const { eventBusService } = useGlobalStore();

  if (state.isIdleOrPending) {
    return <FormSpinner />;
  }

  if (state.isRejected) {
    return <ErrorsFormatter queries={[state.hallQuery, state.playerQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.withdrawQuery]} />
      <Alert
        type="info"
        message={
          <Space direction="vertical">
            {state.player.balance !== null && (
              <div>
                {intl.formatMessage(
                  { defaultMessage: "Current balance: {balance}" },
                  {
                    balance: (
                      <b>
                        {" "}
                        <MoneyFormatter
                          cents={state.currentBalance}
                          currency={state.hall.currency}
                        />{" "}
                      </b>
                    ),
                  },
                )}
              </div>
            )}
            {state.player.sweepstake && (
              <Space wrap>
                {state.player.sweepstake?.entries !== undefined && (
                  <Tag>
                    {intl.formatMessage(
                      { defaultMessage: "Entries: {value}" },
                      {
                        value: (
                          <MoneyFormatter
                            cents={state.player.sweepstake.entries}
                          />
                        ),
                      },
                    )}
                  </Tag>
                )}
                {state.player.sweepstake?.win !== undefined && (
                  <Tag>
                    {intl.formatMessage(
                      { defaultMessage: "Win: {value}" },
                      {
                        value: (
                          <MoneyFormatter cents={state.player.sweepstake.win} />
                        ),
                      },
                    )}
                  </Tag>
                )}
                {state.player.sweepstake?.credit !== undefined && (
                  <Tag>
                    {intl.formatMessage(
                      { defaultMessage: "Credit: {value}" },
                      {
                        value: (
                          <MoneyFormatter
                            cents={state.player.sweepstake.credit}
                          />
                        ),
                      },
                    )}
                  </Tag>
                )}
                {state.player.sweepstake?.bonus !== undefined && (
                  <Tag>
                    {intl.formatMessage(
                      { defaultMessage: "Bonus: {value}" },
                      {
                        value: (
                          <MoneyFormatter
                            cents={state.player.sweepstake.bonus}
                          />
                        ),
                      },
                    )}
                  </Tag>
                )}
                {state.player.sweepstake?.redeem !== undefined && (
                  <Tag>
                    {intl.formatMessage(
                      { defaultMessage: "Redeem: {value}" },
                      {
                        value: (
                          <MoneyFormatter
                            cents={state.player.sweepstake?.redeem}
                          />
                        ),
                      },
                    )}
                  </Tag>
                )}
              </Space>
            )}
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: "Available balance for withdrawal: {balance}",
                },
                {
                  balance: (
                    <Typography.Link
                      onClick={() =>
                        form.setValue(
                          "amount",
                          CurrencyUtilities.toMainUnits(state.availableBalance),
                        )
                      }
                    >
                      <MoneyFormatter
                        cents={state.availableBalance}
                        currency={state.hall.currency}
                      />
                    </Typography.Link>
                  ),
                },
              )}
            </div>
          </Space>
        }
      />
      {state.player.bonus && state.isBonusAlertShown && (
        <Alert
          type="error"
          message={
            <>
              {intl.formatMessage(
                {
                  id: "Wyh17h",
                  defaultMessage:
                    "The player has {count} unwagered bonuses worth {bonus}, which will be cancelled.",
                },
                {
                  bonus: (
                    <>
                      <b>
                        {CurrencyUtilities.toMainUnits(
                          state.player.bonus.amount,
                        )}
                      </b>
                      {" " + state.player.currency}
                    </>
                  ),
                  count: <b>{state.player.bonus.count}</b>,
                },
              )}

              {!!state.player.bonus?.remainingWager && (
                <>
                  <br />
                  {intl.formatMessage(
                    {
                      id: "VzICLY",
                      defaultMessage:
                        "The remaining amount to be wagered is {wager}",
                    },
                    {
                      wager: (
                        <>
                          <b>
                            {CurrencyUtilities.toMainUnits(
                              state.player.bonus.remainingWager,
                            )}
                          </b>
                          {" " + state.player.currency}
                        </>
                      ),
                    },
                  )}
                </>
              )}
              <Typography.Link
                onClick={async () => {
                  eventBusService.publish(
                    new CheckPlayerBonuses({
                      playerId: state.player.id,
                    }),
                  );
                }}
              >
                {" "}
                {intl.formatMessage({ defaultMessage: "Details" })}
              </Typography.Link>
            </>
          }
        />
      )}
      <Form
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Player" })}>
          <Input disabled value={state.player.login} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Amount" })}
          validateStatus={has(form.formState.errors, "amount") ? "error" : ""}
          help={form.formState.errors.amount?.message}
        >
          <Controller
            control={form.control}
            name="amount"
            render={({ field }) => (
              <Input
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter amount",
                })}
                {...field}
                type="number"
                autoFocus
                suffix={state.hall.currency}
              />
            )}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.isWithdrawPending}
            >
              {intl.formatMessage({ defaultMessage: "Withdraw" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
