import { TBonusWager } from "..";
import MethodBuilder from "../method-builder";

class BonusWagerAPI {
  static getAll = new MethodBuilder<void, { data: TBonusWager[] }>()
    .withName("BonusWager.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("BonusWager.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("BonusWager.export")
    .build();
}

export default BonusWagerAPI;
