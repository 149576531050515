import yup from "@/yup";

export function useUserUpdateSchema() {
  return yup.object({
    password: yup
      .string()
      .min(6)
      .transform((value) => (!value ? undefined : value)),
    autoEnableGames: yup.boolean(),
    cashierSettings: yup.object({
      isShortPlayerCreation: yup.boolean(),
    }),
  });
}

export type TUserUpdateSchema = yup.InferType<
  ReturnType<typeof useUserUpdateSchema>
>;
