import { Select } from "antd";
import { RefSelectProps } from "antd/lib/select";
import { observer } from "mobx-react-lite";
import { CSSProperties, forwardRef } from "react";
import { useIntl } from "react-intl";

type TProps = {
  value?: string;
  onChange?: (value?: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
};

function ModeSelect(
  { value, onChange, style, disabled }: TProps,
  ref: React.Ref<RefSelectProps>,
) {
  const options = [
    {
      value: "normal",
      label: "Normal",
    },
    {
      value: "ss_dual",
      label: "Sweepstakes twin balance",
    },
    {
      value: "ss_single",
      label: "Sweepstakes single balance",
    },
  ];

  const intl = useIntl();
  return (
    <Select
      ref={ref}
      placeholder={intl.formatMessage({
        defaultMessage: "Select mode",
      })}
      showSearch
      showArrow
      allowClear
      value={value}
      onChange={onChange}
      options={options}
      style={style}
      disabled={disabled}
    />
  );
}

export const ClientModeSelect = observer(forwardRef(ModeSelect));
