import yup from "@/yup";

export function useCashierUpdateLimitSchema() {
  return yup.object({
    transactionsLimit: yup.number().money().min(0).required(),
  });
}

export type TCashierUpdateLimitSchema = yup.InferType<
  ReturnType<typeof useCashierUpdateLimitSchema>
>;
