import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Modal,
  Popover,
  Row,
  Spin,
  Table,
  Tag,
} from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useIntl } from "react-intl";

import { THistoryReportAction } from "@/api";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";

import ActionDetails from "../action-details/ActionDetails";

import ActionsModalState from "./ActionsModalState";

type TProps = {
  store: ActionsModalState;
};

function ActionsModal({ store }: TProps) {
  useEffect(() => {
    if (store.modal.isOpen) {
      store.fetch();
      store.filter();
    }
    return () => {
      store.reset();
    };
  }, [store, store.modal.isOpen]);

  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Actions" })}
      open={store.modal.isOpen}
      onCancel={store.modal.close}
      width={854}
      footer={null}
      destroyOnClose={true}
      className="history-report-actions"
    >
      {!store.isReady ? (
        <Spin spinning={true}>
          <div style={{ height: 100 }} />
        </Spin>
      ) : (
        <div className="history-report-actions__content">
          <ErrorsFormatter queries={[store.filterQuery]} />
          <Row gutter={[12, 12]} wrap={true} align="middle">
            <Col flex={1}>
              <Row gutter={[0, 8]} wrap={true}>
                {store.hallQuery.isFulfilled && (
                  <Col>
                    <Tag>
                      {intl.formatMessage({ defaultMessage: "Shop:" })}{" "}
                      {store.hallQuery.data.name}
                    </Tag>
                  </Col>
                )}
                {store.playerQuery.isFulfilled && (
                  <Col>
                    <Tag>
                      {intl.formatMessage({ defaultMessage: "Player:" })}{" "}
                      {store.playerQuery.data.login}
                    </Tag>
                  </Col>
                )}
                {store.kioskQuery.isFulfilled && (
                  <Col>
                    <Tag>
                      {intl.formatMessage({ defaultMessage: "Kiosk:" })}{" "}
                      {store.kioskQuery.data.name}
                    </Tag>
                  </Col>
                )}
                {store.providerQuery.isFulfilled && (
                  <Col>
                    <Tag>
                      {intl.formatMessage({ defaultMessage: "Provider:" })}{" "}
                      {store.providerQuery.data.name}
                    </Tag>
                  </Col>
                )}
                {store.gameQuery.isFulfilled && (
                  <Col>
                    <Tag>
                      {intl.formatMessage({ defaultMessage: "Game:" })}{" "}
                      {store.gameQuery.data.name}
                    </Tag>
                  </Col>
                )}
                <Col>
                  <Tag>
                    {intl.formatMessage({ defaultMessage: "Session:" })}{" "}
                    {store.sessionId}
                  </Tag>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} lg={6} xl={4} style={{ marginLeft: "auto" }}>
              <Input
                placeholder={intl.formatMessage({ defaultMessage: "Enter ID" })}
                allowClear
                defaultValue={store.searchQuery}
                onChange={(event) => {
                  store.searchQuery = event.target.value;
                }}
                suffix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <div className="history-report-actions__table-container">
            <Table
              dataSource={store.filterQuery.items}
              showHeader={!!store.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              loading={store.filterQuery.isPending}
              pagination={false}
              tableLayout="auto"
            >
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "ID",
                })}
                dataIndex="id"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "External ID" })}
                dataIndex="externalId"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Round ID" })}
                dataIndex="roundId"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Type" })}
                dataIndex="type"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Created at" })}
                dataIndex="createdAt"
                render={(_, item: THistoryReportAction) =>
                  new Date(item.createdAt).toLocaleString()
                }
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Bet" })}
                dataIndex="bet"
                render={(_, item: THistoryReportAction) => (
                  <MoneyFormatter cents={item.bet} />
                )}
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Win" })}
                dataIndex="win"
                render={(_, item: THistoryReportAction) => (
                  <MoneyFormatter cents={item.win} />
                )}
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Revenue" })}
                render={(_, item: THistoryReportAction) => (
                  <MoneyFormatter cents={item.revenue} />
                )}
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Balance after" })}
                render={(_, item: THistoryReportAction) => (
                  <MoneyFormatter cents={item.balanceAfter} />
                )}
              />
              {store.hallQuery.data?.clientMode !== "normal" && (
                <>
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "SS credit balance after",
                    })}
                    render={(_, item: THistoryReportAction) => (
                      <MoneyFormatter cents={item.ssCreditBalanceAfter} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "SS bonus balance after",
                    })}
                    render={(_, item: THistoryReportAction) => (
                      <MoneyFormatter cents={item.ssBonusBalanceAfter} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "SS win balance after",
                    })}
                    render={(_, item: THistoryReportAction) => (
                      <MoneyFormatter cents={item.ssWinBalanceAfter} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "SS redeem balance after",
                    })}
                    render={(_, item: THistoryReportAction) => (
                      <MoneyFormatter cents={item.ssRedeemBalanceAfter} />
                    )}
                  />
                </>
              )}
              {store.hallQuery.data?.clientMode !== "ss_single" && (
                <Table.Column
                  align="right"
                  title={intl.formatMessage({
                    defaultMessage: "Bonus balance after",
                  })}
                  render={(_, item: THistoryReportAction) => (
                    <MoneyFormatter cents={item.bonusBalanceAfter} />
                  )}
                />
              )}
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex="currency"
                align="right"
              />
              {(store.userRole === "Admin" || store.userRole === "Manager") && (
                <Table.Column
                  title={intl.formatMessage({ defaultMessage: "Details" })}
                  render={(item: THistoryReportAction) => (
                    <Popover
                      placement="bottomRight"
                      trigger="click"
                      destroyTooltipOnHide={true}
                      content={() => <ActionDetails id={item.id} />}
                      overlayInnerStyle={{
                        overflow: "auto",
                        maxWidth: 480,
                        maxHeight: 480,
                      }}
                      onOpenChange={(isOpen) => {
                        const modalBody = document.querySelector(
                          ".history-report-actions",
                        ) as HTMLDivElement;
                        if (isOpen) {
                          modalBody?.classList.add(
                            "history-report-actions--disable-scroll",
                          );
                        } else {
                          modalBody?.classList.remove(
                            "history-report-actions--disable-scroll",
                          );
                        }
                      }}
                    >
                      <Button size="small">
                        {intl.formatMessage({ defaultMessage: "Details" })}
                      </Button>
                    </Popover>
                  )}
                />
              )}
            </Table>
          </div>
          <Pagination
            query={store.filterQuery}
            onChange={() => {
              store.filter({ preservePageNumber: true });
            }}
          />
        </div>
      )}
    </Modal>
  );
}

export default observer(ActionsModal);
