import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { HallAPI, ClientAPI, AgentAPI } from "@/api";
import Query from "@/models/query";

import { HallLobbySettingsProps } from "./hall-lobby-settings";
import {
  HallLobbySettingsSchema,
  useHallLobbySettingsSchema,
} from "./hall-lobby-settings.schema";

export function useHallLobbySettingsState({ hallId }: HallLobbySettingsProps) {
  const intl = useIntl();

  const schema = useHallLobbySettingsSchema();
  const form = useForm<HallLobbySettingsSchema>({
    resolver: yupResolver(schema),
  });
  const formValues = form.watch();

  const { clientQuery, agentQuery, hallQuery } = useMemo(() => {
    const clientQuery = new Query(ClientAPI.getById);
    const agentQuery = new Query(AgentAPI.getById);
    const hallQuery = new Query(HallAPI.getById);

    hallQuery.submit({ id: hallId });

    return { clientQuery, agentQuery, hallQuery };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hall = hallQuery.data;

    if (!hall) {
      return;
    }

    if (hall.agentId) {
      agentQuery.submit({ id: hall.agentId });
    } else {
      clientQuery.submit({ id: hall.clientId });
    }
    const values = schema.cast(hall, { stripUnknown: true });

    if (values.lobbySettings.playersIdleTimeout) {
      values.lobbySettings.playersIdleTimeout /= 1000 * 60;
    }

    form.reset(values);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hallQuery.data]);

  const isContactsShown = useMemo(() => {
    return (
      agentQuery.data?.lobbySettings?.isContactsShown ??
      clientQuery.data?.lobbySettings?.isContactsShown ??
      false
    );
  }, [clientQuery.data, agentQuery.data]);

  const isAutoEnableGamesShown = useMemo(() => {
    return agentQuery.data?.autoEnableGames ?? true;
  }, [agentQuery.data]);

  const submitQuery = useMemo(() => {
    return new Query(async (values: HallLobbySettingsSchema) => {
      if (values.lobbySettings.playersIdleTimeout) {
        values.lobbySettings.playersIdleTimeout *= 1000 * 60;
      }

      if (!isAutoEnableGamesShown) {
        delete values.autoEnableGames;
      }

      await HallAPI.update({ id: hallId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The lobby settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoEnableGamesShown]);

  return {
    hallId,
    intl,
    form,
    formValues,
    clientQuery,
    agentQuery,
    hallQuery,
    isContactsShown,
    isAutoEnableGamesShown,
    submitQuery,
  };
}

export type HallLobbySettingsState = ReturnType<
  typeof useHallLobbySettingsState
>;
