import { TClientMode } from "@/api";
import yup from "@/yup";

export function useClientCreateSchema() {
  return yup.object({
    name: yup.string().required().min(3),
    login: yup.string().required().min(3),
    password: yup.string().required().min(6),
    currency: yup.string(),
    clientMode: yup
      .mixed<TClientMode>()
      .oneOf(["normal", "ss_dual", "ss_single"])
      .required(),
  });
}

export type TClientCreateSchema = yup.InferType<
  ReturnType<typeof useClientCreateSchema>
>;
