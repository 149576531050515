import { TBrand, TPaging } from "..";
import MethodBuilder from "../method-builder";

type TMassBrandCreate = {
  stringId: string;
  name: string;
  providerId: number;
};

class BrandAPI {
  static getAll = new MethodBuilder<void, { data: TBrand[]; total: number }>()
    .withName("Brand.getAll")
    .build();

  static getById = new MethodBuilder<{ id: number }, TBrand>()
    .withName("Brand.getById")
    .build();

  static filter = new MethodBuilder<
    { providerId?: number; searchQuery?: string; paging: TPaging },
    { data: TBrand[]; total: number }
  >()
    .withName("Brand.filter")
    .build();

  static massBrandCreate = new MethodBuilder<
    { brands: TMassBrandCreate[] },
    { data: TBrand[]; total: number }
  >()
    .withName("Brand.massBrandCreate")
    .build();
}

export default BrandAPI;
