import {
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { yupResolver } from "@hookform/resolvers/yup";
import { message, notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useGlobalStore } from "@/stores";

import { ReceiptEditorProps } from "./editor";
import { ReceiptEditorSchema, useReceiptEditorSchema } from "./editor.schema";
import { OpenReceiptJSONModal, SaveReceiptJSON } from "./events";
import { UniqueLine } from "./types";

export function useReceiptEditorState(props: ReceiptEditorProps) {
  const intl = useIntl();

  const schema = useReceiptEditorSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ReceiptEditorSchema>({
    resolver,
    defaultValues: { lines: [] },
    mode: "onSubmit",
  });

  useEffect(() => {
    form.setValue(
      "lines",
      props.lines.map((line, index) => ({ id: index + 1, ...line })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lines]);

  const lines = form.watch("lines") as UniqueLine[];

  function addLine() {
    const maxId = lines.reduce((id, line) => Math.max(id, line.id), 1);
    form.setValue("lines", [
      ...lines,
      {
        id: maxId + 1,
        type: "text",
        align: "l",
        size: props.defaultFontSize,
        text: "New line",
      },
    ]);
    setSelectedLineId(maxId + 1);
  }

  function removeLine() {
    if (lines.length <= 1) {
      message.error(
        intl.formatMessage({ defaultMessage: "You can't remove last line." }),
      );
      return;
    }

    let lastId: number | null = null;
    const filteredLines = lines.filter((line) => line !== selectedLine);
    form.setValue("lines", filteredLines);
    lastId = filteredLines.at(-1)?.id ?? null;
    setSelectedLineId(lastId);
  }

  function updateLine(updatedLine: UniqueLine) {
    form.setValue(
      "lines",
      lines.map((line) => {
        if (line.id !== updatedLine.id) {
          return line;
        }

        return updatedLine;
      }),
    );
  }

  const [selectedLineId, setSelectedLineId] = useState<number | null>(null);

  useEffect(() => {
    setSelectedLineId(props.lines.length > 0 ? 1 : null);
  }, [props.lines]);

  const selectedLineIndex = useMemo(
    () => lines.findIndex((line) => line.id === selectedLineId) ?? null,
    [lines, selectedLineId],
  );

  const selectedLine = useMemo(
    () => lines.at(selectedLineIndex) ?? null,
    [lines, selectedLineIndex],
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  function handleClick(lineId: number) {
    setSelectedLineId(lineId);
  }

  function handleDragStart({ active }: DragStartEvent) {
    setSelectedLineId(Number(active.id));
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    const lines = form.watch("lines");

    if (over === null) {
      return;
    }

    const oldIndex = lines.findIndex((line) => line.id === active.id);
    const newIndex = lines.findIndex((line) => line.id === over.id);

    if (oldIndex === newIndex) {
      return;
    }

    form.setValue("lines", arrayMove(lines, oldIndex, newIndex));
  }

  const handleSubmit = form.handleSubmit((values) => {
    const lines = values.lines.map(({ id: _, ...line }) => line);
    props.submitQuery.submit({ lines });
  });

  const handleCancel = () => {
    props.cancelQuery?.submit();
  };

  const { eventBusService } = useGlobalStore();

  const handleJSONClick = () => {
    try {
      form.trigger();
      const lines = form.watch("lines");
      const uniqueLines = schema.validateSync({ lines }).lines;
      const event = new OpenReceiptJSONModal({ uniqueLines });
      eventBusService.publish(event);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    const listener = (event: SaveReceiptJSON) => {
      const uniqueLines = event.payload.lines.map((line, index) => ({
        id: index + 1,
        ...line,
      }));
      form.setValue("lines", uniqueLines);
    };
    eventBusService.subscribe(SaveReceiptJSON, listener);
    return () => eventBusService.unsubscribe(SaveReceiptJSON, listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.submitQuery.isFulfilled) {
      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The template was successfully saved.",
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submitQuery.isFulfilled]);

  useEffect(() => {
    if (!form.formState.errors.lines?.length) {
      return;
    }
    const index = form.formState.errors.lines.length - 1;
    setSelectedLineId(lines[index].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.errors]);

  return {
    intl,

    form,

    header: props.header,
    lines,
    scaleFontSize: props.scaleFontSize,

    addLine,
    removeLine,
    updateLine,

    selectedLineIndex,
    selectedLine,

    sensors,
    handleClick,
    handleDragStart,
    handleDragEnd,

    submitQuery: props.submitQuery,
    cancelQuery: props.cancelQuery,

    handleSubmit,
    handleCancel,

    handleJSONClick,
  };
}

export type ReceiptEditorState = ReturnType<typeof useReceiptEditorState>;
