import { Space, Form, Input, Button, Col, Row, Result, Divider } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import { FormInput, FormItem, FormSelect } from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { CreateVouchersFormState } from "./create-vouchers-form.state";

interface Props {
  state: CreateVouchersFormState;
}

export const CreateVouchersFormView = observer<Props>(({ state }) => {
  const {
    intl,
    form,
    websitesQuery,
    websiteOptions,

    submitQuery,

    primaryTitle,
    secondaryTitle,
    note,
    handlePrimaryTitleChange,
    handleSecondaryTitleChange,
    handleNoteChange,

    printQuery,
  } = state;

  if (websitesQuery.isRejected) {
    return <ErrorsFormatter queries={[websitesQuery]} />;
  } else if (!websitesQuery.isFulfilled) {
    return <FormSpinner />;
  }

  if (submitQuery.isFulfilled) {
    return (
      <Result
        status="success"
        title={intl.formatMessage({
          defaultMessage: "Vouchers created successfully",
        })}
        extra={[
          <Button
            key="print-button"
            type="primary"
            onClick={printQuery.submit}
            loading={printQuery.isPending}
          >
            {intl.formatMessage({ defaultMessage: "Print" })}
          </Button>,
        ]}
      />
    );
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="prefix"
          label={intl.formatMessage({ defaultMessage: "Login prefix" })}
        >
          <FormInput
            form={form}
            path="prefix"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter login prefix",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="initialBalance"
          label={intl.formatMessage({ defaultMessage: "Initial balance" })}
          isRequired
        >
          <FormInput
            form={form}
            path="initialBalance"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter initial balance",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="quantity"
          label={intl.formatMessage({ defaultMessage: "Quantity (max 250)" })}
          isRequired
        >
          <FormInput
            form={form}
            path="quantity"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter quantity",
            })}
          />
        </FormItem>
        <Divider />
        <FormItem
          form={form}
          path="websiteId"
          label={intl.formatMessage({ defaultMessage: "Website" })}
          isRequired
        >
          <FormSelect
            form={form}
            path="websiteId"
            options={websiteOptions}
            placeholder={intl.formatMessage({
              defaultMessage: "Select website",
            })}
          />
        </FormItem>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Primary title" })}
        >
          <Input
            value={primaryTitle}
            onChange={handlePrimaryTitleChange}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter primary title",
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Secondary title" })}
        >
          <Input
            value={secondaryTitle}
            onChange={handleSecondaryTitleChange}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter secondary title",
            })}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Note" })}>
          <Input.TextArea
            value={note}
            onChange={handleNoteChange}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter note",
            })}
            rows={6}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Create" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
