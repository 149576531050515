import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { CheckPlayerBonuses } from "@/events";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { InfoIcon } from "@/ui/_common_/icons";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import { CurrencyUtilities } from "@/utilities";

import { BonusInfo } from "../../bonus-info";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const { eventBusService } = useGlobalStore();
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  const [amount, bonusId] = form.watch(["amount", "bonusId"]);

  useEffect(() => {
    if (state.submitTask.isFulfilled) {
      form.reset();
    }
  }, [form, state.submitTask.isFulfilled]);

  if (state.fetchTask.isIdle || state.fetchTask.isPending) {
    return <FormSpinner />;
  }

  if (state.fetchTask.isRejected) {
    return <ErrorsFormatter queries={[state.fetchTask]} />;
  }

  const selectedBonus = state.bonuses.find((b) => b.id === bonusId);

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.submitTask]} />
      {state.availableBalance !== null && (
        <Alert
          type="info"
          message={intl.formatMessage(
            {
              id: "HFlRzl",
              defaultMessage: "Available balance: {balance}.",
            },
            {
              balance: (
                <Typography.Link
                  onClick={() =>
                    form.setValue(
                      "amount",
                      CurrencyUtilities.toMainUnits(state.availableBalance!),
                    )
                  }
                >
                  <MoneyFormatter cents={state.availableBalance} />{" "}
                  {state.hall.currency}
                </Typography.Link>
              ),
            },
          )}
        />
      )}
      <Form
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.submitTask.submit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Player" })}>
          <Input disabled value={state.player.login} />
        </Form.Item>
        <Form.Item
          className="bold-amount"
          label={intl.formatMessage({ defaultMessage: "Amount" })}
          validateStatus={has(form.formState.errors, "amount") ? "error" : ""}
          help={form.formState.errors.amount?.message}
        >
          <Space direction="vertical">
            <Controller
              control={form.control}
              name="amount"
              render={({ field }) => (
                <Input
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter amount",
                  })}
                  {...field}
                  type="number"
                  autoFocus
                  suffix={state.hall.currency}
                />
              )}
            />
            {!!state.hall.quickDepositValues.length && (
              <Row gutter={[8, 8]}>
                {state.hall.quickDepositValues.map((value, index) => (
                  <Col xs={12} lg={8} key={index}>
                    <Button
                      size="small"
                      style={{ width: "100%", textAlign: "center" }}
                      onClick={() =>
                        form.setValue(
                          "amount",
                          CurrencyUtilities.toMainUnits(value),
                        )
                      }
                    >
                      <MoneyFormatter
                        cents={value}
                        fractionDigits={
                          CurrencyUtilities.parseMinorUnits(value)
                            .decimalPart === "00"
                            ? 0
                            : 2
                        }
                      />
                    </Button>
                  </Col>
                ))}
              </Row>
            )}
          </Space>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Bonus" })}>
          <Space direction="vertical">
            <Controller
              control={form.control}
              name="bonusId"
              defaultValue={undefined}
              render={({ field }) => (
                <Select
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select bonus",
                  })}
                  {...field}
                  options={state.bonuses.map((bonus) => ({
                    label: bonus.type,
                    value: bonus.id,
                  }))}
                  allowClear={true}
                  disabled={false}
                />
              )}
            />
            {selectedBonus && (
              <BonusInfo hall={state.hall} bonus={selectedBonus} />
            )}
            {(() => {
              if (
                !selectedBonus ||
                !("minDeposit" in selectedBonus) ||
                !("maxDeposit" in selectedBonus)
              ) {
                return null;
              }
              const { minDeposit, maxDeposit } = selectedBonus;
              try {
                const cents = CurrencyUtilities.toMinorUnits(+amount);
                if (cents >= minDeposit && cents <= maxDeposit) {
                  return null;
                }
                return (
                  <Alert
                    type="error"
                    message={intl.formatMessage({
                      defaultMessage:
                        "The deposit amount is outside the bonus deposit range.",
                    })}
                  />
                );
              } catch (error) {
                return null;
              }
            })()}
          </Space>
        </Form.Item>
        {state.notAvailableWarning !== null && (
          <Alert
            type="warning"
            showIcon
            icon={<InfoIcon style={{ width: 20 }} />}
            description={
              <>
                {state.notAvailableWarning + " "}
                <Typography.Link
                  onClick={async () => {
                    eventBusService.publish(
                      new CheckPlayerBonuses({
                        playerId: state.player.id,
                      }),
                    );
                  }}
                >
                  {" "}
                  {intl.formatMessage({ defaultMessage: "Details" })}
                </Typography.Link>
              </>
            }
          />
        )}
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.submitTask.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Deposit" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
