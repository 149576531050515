import { observer } from "mobx-react-lite";
import { FC, useLayoutEffect, useRef, useState } from "react";

type Props = {
  children: string;
};

export const Frame: FC<Props> = observer(({ children }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLIFrameElement>(null);
  useLayoutEffect(() => {
    const frame = ref.current;
    const window = frame?.contentWindow;
    const document = frame?.contentDocument;

    const updateHeight = () => {
      const height = document?.body.scrollHeight ?? 0;
      setHeight(height);
    };

    document?.open();
    document?.write(children);
    document?.close();

    updateHeight();

    window?.addEventListener("resize", updateHeight);
    window?.addEventListener("load", updateHeight);
    window?.addEventListener("DOMContentLoaded", updateHeight);

    return () => {
      window?.removeEventListener("resize", updateHeight);
      window?.removeEventListener("load", updateHeight);
      window?.removeEventListener("DOMContentLoaded", updateHeight);
    };
  }, [children]);

  return <iframe ref={ref} width="100%" height={height} frameBorder={0} />;
});
