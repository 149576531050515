import client from "./client";

export type TMethod<I, O> = (params: I) => Promise<O>;

export default class MethodBuilder<I, O> {
  constructor() {
    this.withName = this.withName.bind(this);
    this.build = this.build.bind(this);
  }

  private name?: string;

  withName(name: string) {
    this.name = name;
    return this;
  }
  build(): TMethod<I, O> {
    return async (...args: Parameters<TMethod<I, O>>) => {
      return client.send<O>(this.name as string, args[0]);
    };
  }
}
