import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { SportClubAPI } from "@/api";
import { ClubCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { Props } from "./form";
import { NewClubFormSchema, useNewClubFormSchema } from "./form.schema";

export function useNewClubFormState({ partnerId }: Props) {
  const intl = useIntl();

  const schema = useNewClubFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<NewClubFormSchema>({ resolver });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (values: NewClubFormSchema) => {
      await SportClubAPI.create({ partnerId, ...values });

      notification.success({
        message: "",
        description: "Club created successfully!",
      });

      eventBusService.publish(new ClubCreated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId]);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  return {
    intl,
    form,
    partnerId,
    handleSubmit,
    mutation,
  };
}

export type NewClubFormState = ReturnType<typeof useNewClubFormState>;
