import { notification } from "antd";
import {
  autorun,
  IReactionDisposer,
  makeAutoObservable,
  runInAction,
} from "mobx";

import { AgentAPI, HallAPI } from "@/api";
import { UpdateHall } from "@/events";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { THallCreateSchema } from "@/schemas";

import { DisposableStore } from "..";
import { SelectorStore } from "../selectors";

import { HallsStore } from ".";

export class HallCreateStore implements DisposableStore {
  constructor(public readonly hallsStore: HallsStore) {
    makeAutoObservable(this);

    const dispose = autorun(() => {
      const clientId = this.clientSelectorStore.selectedId;
      const agentId = this.agentSelectorStore.selectedId;
      const subagentId = this.subagentSelectorStore.selectedId;

      if (this.permissionsStore.has("SelectSubagent") && agentId) {
        runInAction(() => {
          this.parentAgentSelectorStore.setParameters({
            parentAgentId: agentId,
          });
          this.parentAgentSelectorStore.setSelectedId(subagentId);
          this.parentAgentSelectorStore.fetchItems();
        });
      } else if (this.permissionsStore.has("SelectAgent") && clientId) {
        runInAction(() => {
          this.parentAgentSelectorStore.setParameters({ clientId });
          this.parentAgentSelectorStore.setSelectedId(agentId);
          this.parentAgentSelectorStore.fetchItems();
        });
      }
    });
    this.disposers.push(dispose);
  }
  dispose() {
    this.disposers.forEach((dispose) => dispose());
    this.createQuery.reset();
    this.parentAgentQuery.reset();
  }

  readonly disposers: IReactionDisposer[] = [];

  get intl() {
    return this.hallsStore.globalStore.intlService.intl;
  }

  private get userStore() {
    return this.hallsStore.globalStore.userStore;
  }

  private get permissionsStore() {
    return this.hallsStore.globalStore.permissionsStore;
  }

  private get eventBusService() {
    return this.hallsStore.globalStore.eventBusService;
  }

  private get clientSelectorStore() {
    return this.hallsStore.generalSelectorsStore.clientSelectorStore;
  }

  private get agentSelectorStore() {
    return this.hallsStore.generalSelectorsStore.agentSelectorStore;
  }

  private get subagentSelectorStore() {
    return this.hallsStore.generalSelectorsStore.subagentSelectorStore;
  }

  get parentCurrency() {
    if (
      typeof this.parentAgentSelectorStore.selectedItem?.currency === "string"
    ) {
      return this.parentAgentSelectorStore.selectedItem.currency;
    }
    if (typeof this.subagentSelectorStore.selectedItem?.currency === "string") {
      return this.subagentSelectorStore.selectedItem.currency;
    }
    if (typeof this.agentSelectorStore.selectedItem?.currency === "string") {
      return this.agentSelectorStore.selectedItem.currency;
    }
    if (typeof this.clientSelectorStore.selectedItem?.currency === "string") {
      return this.clientSelectorStore.selectedItem?.currency;
    }
    return null;
  }

  parentAgentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
  });

  modal = new Modal();
  createQuery = new Query(HallAPI.create);
  parentAgentQuery = new Query(AgentAPI.getById);

  handleSubmit = async (values: THallCreateSchema) => {
    if (!this.clientSelectorStore.selectedId) {
      return;
    }

    values.lobbySettings.playersIdleTimeout *= 60 * 1000;

    const agentId =
      this.parentAgentSelectorStore.selectedId ?? this.userStore.agent?.id;
    await this.createQuery.submit({
      clientId: this.clientSelectorStore.selectedId,
      agentId,
      ...values,
    });

    if (!this.createQuery.isFulfilled) {
      return;
    }

    const createdHall = this.createQuery.data;

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The shop has been successfully created.",
      }),
    });

    this.modal.close();

    this.eventBusService.publish(new UpdateHall({ hallId: createdHall.id }));

    this.hallsStore.filter();
  };
}
