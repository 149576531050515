import { Entity } from "@/types";

import { useFortuneWheelSettingsState } from "./fortune-wheel-settings.state";
import { FortuneWheelSettingsView } from "./fortune-wheel-settings.view";

export interface IFortuneWheelSettingsProps {
  entity: Entity;
  selfEdit?: boolean;
  readOnly?: boolean;
}

export function FortuneWheelSettings(props: IFortuneWheelSettingsProps) {
  const state = useFortuneWheelSettingsState(props);
  return <FortuneWheelSettingsView state={state} />;
}
