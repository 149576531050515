import yup from "@/yup";

export function useKioskSettingsFormSchema() {
  return yup.object({
    name: yup.string().min(3),
    type: yup
      .mixed<"kiosk" | "exchanger" | "internetcafe">()
      .oneOf(["kiosk", "exchanger", "internetcafe"]),
    isAlwaysAuthorized: yup
      .mixed<"true" | "false" | "default">()
      .oneOf(["true", "false", "default"]),
    isBlocked: yup.boolean(),
    isPanicEnabled: yup.boolean(),
    isPayoutAllowed: yup.boolean(),
    areReportsAllowed: yup.boolean(),
    title: yup.string(),
  });
}

export type KioskSettingsFormSchema = yup.InferType<
  ReturnType<typeof useKioskSettingsFormSchema>
>;
