import { UploadOutlined } from "@ant-design/icons";
import { Button, Row, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";

import { ImportPlayersFormState } from "./form.state";

type Props = {
  state: ImportPlayersFormState;
};

export const ImportPlayersFormView = observer<Props>(({ state }) => {
  const { intl, handleUpload, mutation } = state;

  return (
    <Space direction="vertical">
      <Typography.Paragraph>
        {intl.formatMessage({
          defaultMessage:
            "In order to import players into the Shop, prepare a file in CSV format, separated by a semicolon.",
        })}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {intl.formatMessage({
          defaultMessage:
            "The file must contain the following columns: Login, Password, Phone, Name, Email",
        })}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {intl.formatMessage({
          defaultMessage: "Example: ",
        })}
        <pre>
          {`
login;password;name;phone;email
player1;password1;Player 1;+79999999999;player1@example.com
player2;password2;Player 2;+79999999998;player2@example.com
player3;password3;Player 3;+79999999997;player3@example.com
`.trim()}
        </pre>
      </Typography.Paragraph>
      <ErrorsFormatter queries={[mutation]} />
      <Row justify="center">
        <Button
          icon={<UploadOutlined />}
          onClick={handleUpload}
          loading={mutation.isPending}
        >
          {intl.formatMessage({ defaultMessage: "Upload CSV file" })}
        </Button>
      </Row>
    </Space>
  );
});
