import { red } from "@ant-design/colors";
import { DeleteOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { memo } from "react";
import { useIntl } from "react-intl";

import { TMessage } from "@/api";

import { IChatProps } from "./types";

interface IDeleteMessageProps
  extends Omit<IChatProps, "conversation" | "player"> {
  item: TMessage;
}

export const DeleteMessage = memo(({ state, item }: IDeleteMessageProps) => {
  const intl = useIntl();

  const handleDelete = () => {
    state.deleteMessage(item.messageId);
  };

  return (
    <>
      {state.deleteMessageQuery.isPending ? (
        <Spin size="small" />
      ) : (
        <DeleteOutlined
          onClick={handleDelete}
          style={{
            fontSize: "12px",
            marginLeft: "8px",
            color: red[4],
            cursor: "pointer",
          }}
          title={intl.formatMessage({ defaultMessage: "Delete message" })}
          aria-label={intl.formatMessage({
            defaultMessage: "Delete message",
          })}
        />
      )}
    </>
  );
});

DeleteMessage.displayName = "DeleteMessage";
