import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { ClientAPI, LanguageAPI } from "@/api";
import Query from "@/models/query";

import { ClientLobbySettingsProps } from "./client-lobby-settings";
import {
  ClientLobbySettingsSchema,
  useClientLobbySettingsSchema,
} from "./client-lobby-settings.schema";

export function useClientLobbySettingsState({
  clientId,
}: ClientLobbySettingsProps) {
  const intl = useIntl();

  const schema = useClientLobbySettingsSchema();
  const form = useForm<ClientLobbySettingsSchema>({
    resolver: yupResolver(schema),
  });
  const formValues = form.watch();

  const clientQuery = useMemo(() => {
    const query = new Query(ClientAPI.getById);
    query.submit({ id: clientId }).then(() => {
      const client = query.data!;
      const values = schema.cast(client, { stripUnknown: true });
      form.reset(values);
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languagesQuery = useMemo(() => {
    const query = new Query(LanguageAPI.getAll);
    query.submit();
    return query;
  }, []);

  const languagesOptions = useMemo(() => {
    const languages = languagesQuery.data?.data ?? [];
    const checkedLanguages = formValues?.lobbySettings?.languages ?? [];
    return languages.map((language) => ({
      name: language.code,
      label: language.name,
      value: checkedLanguages.includes(language.code),
    }));
  }, [formValues, languagesQuery.data]);

  const defaultLanguageOptions = useMemo(() => {
    const languages = languagesQuery.data?.data ?? [];
    const checkedLanguages = formValues?.lobbySettings?.languages ?? [];
    return languages
      .filter((language) => checkedLanguages.includes(language.code))
      .map((language) => ({
        value: language.code,
        label: language.name,
      }));
  }, [formValues, languagesQuery.data]);

  const handleLanguageCheck = useCallback(
    (event: CheckboxChangeEvent) => {
      const checked = event.target.checked;
      const name = event.target.name!;
      const checkedLanguages = new Set(formValues?.lobbySettings?.languages);
      if (checked) {
        checkedLanguages.add(name);
      } else {
        checkedLanguages.delete(name);
      }
      form.setValue("lobbySettings.languages", [...checkedLanguages]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues],
  );

  const submitQuery = useMemo(() => {
    return new Query(async (values: ClientLobbySettingsSchema) => {
      const availableLanguages =
        languagesQuery.data?.data?.map((language) => language.code) ?? [];

      values.lobbySettings.languages = values.lobbySettings?.languages?.filter(
        (code: string) => availableLanguages.includes(code),
      );

      await ClientAPI.update({ id: clientId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The lobby settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intl,
    form,
    formValues,
    clientQuery,
    languagesQuery,
    defaultLanguageOptions,
    languagesOptions,
    handleLanguageCheck,
    submitQuery,
  };
}

export type ClientLobbySettingsState = ReturnType<
  typeof useClientLobbySettingsState
>;
