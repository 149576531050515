import { makeAutoObservable } from "mobx";

import {
  AgentAPI,
  ClientAPI,
  GamesOrderAPI,
  ProviderAPI,
  TGamesOrderItem,
} from "@/api";
import { SearchParamKey } from "@/constants";
import { GameOrderEdited } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import Query from "@/models/query";
import { RouterService } from "@/router";
import { EventBusService } from "@/services";
import { PermissionsStore, SelectorStore, UserStore } from "@/stores";
import { parseInteger } from "@/utilities";

import { Props } from "./GamesOrder";

export class GamesOrderState implements ViewModel<Props> {
  constructor(
    public readonly permissionsStore: PermissionsStore,
    private readonly routerService: RouterService,
    public readonly eventBusService: EventBusService,
    private readonly userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = () => {
    this.useInitialSearchParams();
    this.updateSearchParams();
    this.updateSelectorParameters();

    this.eventBusService.subscribe(GameOrderEdited, this.handleGameOrderChange);

    this.filter();
  };

  onViewUnmount = () => {
    this.eventBusService.unsubscribe(
      GameOrderEdited,
      this.handleGameOrderChange,
    );
  };

  private handleGameOrderChange = () => {
    this.filter();
  };

  private handleClientSelect = () => {
    this.agentSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleAgentSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleProviderSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  public readonly clientSelectorStore = new SelectorStore({
    filterMethod: ClientAPI.filter,
    getByIdMethod: ClientAPI.getById,
    labelKey: "name",
    onSelect: this.handleClientSelect,
  });

  public readonly agentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleAgentSelect,
  });

  public readonly providerSelectorStore = new SelectorStore({
    filterMethod: ProviderAPI.filter,
    getByIdMethod: ProviderAPI.getById,
    labelKey: "name",
    onSelect: this.handleProviderSelect,
    isPaginationEnabled: false,
  });

  private useInitialSearchParams() {
    const { client, agent, hall } = this.userStore;

    if (client) {
      this.clientSelectorStore.setSelectedId(client.id, false);
    } else if (agent) {
      this.clientSelectorStore.setSelectedId(agent.clientId, false);
      this.agentSelectorStore.setSelectedId(agent.id, false);
    } else if (hall) {
      this.clientSelectorStore.setSelectedId(hall.clientId, false);
      this.agentSelectorStore.setSelectedId(hall.agentId ?? undefined, false);
    }

    const { searchParams } = this.routerService;
    const initialClientId = parseInteger(searchParams[SearchParamKey.ClientId]);
    const initialAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.AgentId])
        : undefined;

    if (this.permissionsStore.has("SelectClient")) {
      this.clientSelectorStore.setSelectedId(initialClientId);
    }
    if (this.clientSelectorStore.selectedId) {
      if (this.permissionsStore.has("SelectAgent")) {
        this.agentSelectorStore.setSelectedId(initialAgentId);
      }
    }
  }

  private updateSearchParams() {
    const urlSearchParams = new URLSearchParams(
      this.routerService.location.search,
    );

    if (this.clientSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ClientId,
        this.clientSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ClientId);
    }

    if (this.agentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.agentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.AgentId);
    }

    if (this.providerSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ProviderId,
        this.providerSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ProviderId);
    }

    this.routerService.replace({
      search: urlSearchParams.toString(),
    });
  }

  private updateSelectorParameters = () => {
    const oldClientParameters = this.clientSelectorStore.parameters;
    const oldAgentParameters = this.agentSelectorStore.parameters;
    const oldProviderParameters = this.providerSelectorStore.parameters;

    let newClientParameters = oldClientParameters;
    let newAgentParameters = oldAgentParameters;
    let newProviderParameters = oldProviderParameters;

    const clientId = this.clientSelectorStore.selectedId;

    const { user } = this.userStore;
    newClientParameters = {
      managerId: user.role === "Manager" ? user.id : undefined,
    };

    if (clientId) {
      newAgentParameters = { clientId };
    }

    newProviderParameters = {};

    if (
      this.permissionsStore.has("SelectClient") &&
      JSON.stringify(oldClientParameters) !==
        JSON.stringify(newClientParameters)
    ) {
      this.clientSelectorStore.setParameters(newClientParameters);
      this.clientSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldAgentParameters) !== JSON.stringify(newAgentParameters)
    ) {
      this.agentSelectorStore.setParameters(newAgentParameters);
      this.agentSelectorStore.fetchItems();
    }

    if (
      JSON.stringify(oldProviderParameters) !==
      JSON.stringify(newProviderParameters)
    ) {
      this.providerSelectorStore.setParameters(newProviderParameters);
      this.providerSelectorStore.fetchItems();
    }
  };

  items: TGamesOrderItem[] = [];

  filterQuery = new ItemsQuery(GamesOrderAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    initialOrder: ["realGameOrder", "desc"],
    isPaginationEnabled: true,
  });

  resetTask = new Query<void, void>(async () => {
    const agentId = this.agentSelectorStore.selectedId;
    if (!agentId) {
      return;
    }
    await GamesOrderAPI.reset({ agentId });
    await this.filter();
  });

  exportQuery = new Query(GamesOrderAPI.export);

  importQuery = new Query(GamesOrderAPI.import);

  filter = async (options?: TSubmitOptions) => {
    const agentId = this.agentSelectorStore.selectedId;
    const providerId = this.providerSelectorStore.selectedId;
    if (!agentId) {
      return;
    }
    await this.filterQuery.submit({ agentId, providerId }, options);
    this.items = this.filterQuery.items;
  };

  export = async () => {
    const agentId = this.agentSelectorStore.selectedId;
    if (!agentId) {
      throw new Error("Agent is not selected");
    }
    await this.exportQuery.submit({ agentId, format: "csv" });
    if (!this.exportQuery.isFulfilled) {
      throw new Error("Export failed");
    }
    const { data } = this.exportQuery.data;
    return { data };
  };

  import = async (data: string) => {
    const agentId = this.agentSelectorStore.selectedId;
    if (!agentId) {
      throw new Error("Agent is not selected");
    }
    await this.importQuery.submit({ agentId, data, format: "csv" });
    if (!this.importQuery.isFulfilled) {
      throw new Error("Import failed");
    }
  };
}
