import { Alert, Button, Col, Input, Row, Space, message } from "antd";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";

import { useGlobalStore } from "@/stores";

import { useReceiptEditorSchema } from "./editor.schema";
import { CloseReceiptJSONModal, SaveReceiptJSON } from "./events";
import { UniqueLine } from "./types";

export type JSONFormProps = {
  uniqueLines: UniqueLine[];
};

export const JSONForm = observer<JSONFormProps>((props) => {
  const intl = useIntl();
  const json = useRef(
    JSON.stringify(
      props.uniqueLines.map(({ id: _, ...line }) => line),
      null,
      2,
    ),
  );
  const { eventBusService } = useGlobalStore();
  const schema = useReceiptEditorSchema();
  const [showAlert, setShowAlert] = useState(false);

  const handleCancel = () => {
    eventBusService.publish(new CloseReceiptJSONModal({}));
  };

  const handleCopy = () => {
    window.navigator.clipboard.writeText(json.current);
    message.success({
      content: intl.formatMessage({
        defaultMessage: "The JSON was successfully copied.",
      }),
    });
  };

  const handleSave = () => {
    try {
      let lines = JSON.parse(json.current);
      if (Array.isArray(lines)) {
        lines = lines.map((line, index) => ({
          id: index + 1,
          ...line,
        }));
      }
      const values = schema.validateSync(
        { lines },
        { strict: true, stripUnknown: true },
      );

      eventBusService.publish(new SaveReceiptJSON({ lines: values.lines }));
      eventBusService.publish(new CloseReceiptJSONModal({}));

      setShowAlert(false);
    } catch {
      setShowAlert(true);
    }
  };

  return (
    <Space direction="vertical">
      <Input.TextArea
        defaultValue={json.current}
        onChange={(event) => (json.current = event.currentTarget.value)}
        rows={16}
        style={{ fontFamily: "monospace" }}
      />
      {showAlert && (
        <Alert
          type="error"
          message={intl.formatMessage({ defaultMessage: "Invalid JSON" })}
        />
      )}
      <Row justify="end" gutter={[12, 12]}>
        <Col>
          <Button onClick={handleCancel}>
            {intl.formatMessage({ defaultMessage: "Close" })}
          </Button>
        </Col>
        <Col>
          <Button onClick={handleCopy}>
            {intl.formatMessage({ defaultMessage: "Copy" })}
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleSave}>
            {intl.formatMessage({ defaultMessage: "Save" })}
          </Button>
        </Col>
      </Row>
    </Space>
  );
});
