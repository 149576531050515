import yup from "@/yup";

export function useCreateVoucherFormSchema() {
  return yup.object({
    initialBalance: yup.number().min(0).money().required(),
  });
}

export type CreateVoucherFormSchema = yup.InferType<
  ReturnType<typeof useCreateVoucherFormSchema>
>;
