import { makeAutoObservable } from "mobx";

import { AgentAPI } from "@/api";
import { AgentUpdated } from "@/events";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";

import {
  AgentCreateStore,
  AgentStore,
  GeneralSelectorsStore,
  GlobalStore,
} from "..";

export class AgentsStore {
  constructor(public readonly globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    const { searchParams } = this.routerService;

    if (searchParams["action"] === "new") {
      this.agentCreateStore.modal.open();
    }

    this.generalSelectorsStore = new GeneralSelectorsStore(
      this.globalStore,
      this.filter,
      true,
      true,
      true,
      false,
    );

    this.filter();

    this.eventBusService.subscribe(AgentUpdated, async (event) => {
      const { agentId } = event.payload;
      const agentStore = this.agentStores.find(
        (agentStore) => agentStore.agent.id === agentId,
      )!;
      const promises = [agentStore.fetch()];

      function recursiveChildrenFetch(agentStore: AgentStore) {
        (agentStore.children ?? []).forEach((agentStore) => {
          promises.push(agentStore.fetch());
          recursiveChildrenFetch(agentStore);
        });
      }

      function recursiveParentFetch(agentStore: AgentStore) {
        if (agentStore.parentAgentStore) {
          promises.push(agentStore.parentAgentStore.fetch());
          recursiveParentFetch(agentStore.parentAgentStore);
        }
      }

      recursiveChildrenFetch(agentStore);
      recursiveParentFetch(agentStore);

      await Promise.all(promises);
    });
  }

  get intlService() {
    return this.globalStore.intlService;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get eventBusService() {
    return this.globalStore.eventBusService;
  }

  public readonly generalSelectorsStore: GeneralSelectorsStore;

  agentCreateStore = new AgentCreateStore(this);

  private _agentStores: AgentStore[] = [];

  get agentStores() {
    return this.flatAgentStores(this._agentStores);
  }

  private flatAgentStores = (agentStores: AgentStore[]): AgentStore[] => {
    return agentStores.reduce(
      (previousValue: AgentStore[], currentValue: AgentStore) => {
        return [
          ...previousValue,
          currentValue,
          ...this.flatAgentStores(currentValue.children ?? []),
        ];
      },
      [],
    );
  };

  setAgentStores = (agentStores: AgentStore[]) => {
    this._agentStores = agentStores;
  };

  filterQuery = new ItemsQuery(AgentAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
    initialOrder: ["id", "desc"],
  });

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
    this.filter();
  }

  get includeDeletedParams() {
    return this.permissionsStore.has("ViewDeletedAgents")
      ? { includeDeleted: this.includeDeleted }
      : {};
  }

  private _currencyCode?: string;

  get currencyCode() {
    return this._currencyCode;
  }

  setCurrencyCode = (currencyCode?: string) => {
    this._currencyCode = currencyCode;
  };

  filter = async (options?: TSubmitOptions) => {
    if (!this.generalSelectorsStore.clientSelectorStore.selectedId) {
      this.setAgentStores([]);
      return;
    }

    const commonParams = {
      nestingLevel: undefined as number | undefined,
      currency: this._currencyCode,
      ...this.includeDeletedParams,
    };
    if (!this.filterQuery.searchQuery.length) {
      commonParams.nestingLevel = 0;
    }
    const clientId = this.generalSelectorsStore.clientSelectorStore.selectedId;
    const parentAgentId =
      this.generalSelectorsStore.subagentSelectorStore.selectedId ??
      this.generalSelectorsStore.agentSelectorStore.selectedId;
    const params = parentAgentId
      ? { parentAgentId, ...commonParams }
      : { clientId, ...commonParams };

    await this.filterQuery.submit(params, options);

    if (!this.filterQuery.isFulfilled) {
      return;
    }

    const { items } = this.filterQuery;
    const agentStores = items.map(
      (agent) => new AgentStore(agent, this, undefined, 0, this.intlService),
    );
    this.setAgentStores(agentStores);
  };
}
