import { TAccessControlList, TArticle, TCategory } from "..";
import MethodBuilder from "../method-builder";

class KnowledgeBaseAPI {
  static getCategories = new MethodBuilder<
    void,
    {
      data: TCategory[];
      total: number;
    }
  >()
    .withName("KnowledgeBase.getCategories")
    .build();

  static createArticle = new MethodBuilder<
    {
      categoryId: number;
      slug: string;
      name: string;
      // Здесь путь до MD файла, но в ответах должен быть уже URL
      filePath: string;
      accessControlList: TAccessControlList;
      isEnabled: boolean;
    },
    void
  >()
    .withName("KnowledgeBase.createArticle")
    .build();

  static updateArticle = new MethodBuilder<
    {
      id: number;

      categoryId: number;
      slug: string;
      name: string;
      filePath: string;
      accessControlList: TAccessControlList;
      isEnabled: boolean;
    },
    void
  >()
    .withName("KnowledgeBase.updateArticle")
    .build();

  static getArticles = new MethodBuilder<
    { categoryId?: number },
    {
      data: TArticle[];
      total: number;
    }
  >()
    .withName("KnowledgeBase.getArticles")
    .build();

  static getArticle = new MethodBuilder<
    { id: number } | { slug: string },
    TArticle
  >()
    .withName("KnowledgeBase.getArticle")
    .build();
}

export default KnowledgeBaseAPI;
