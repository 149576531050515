import { observer } from "mobx-react-lite";

import { EditClub, ClubEdited } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditClubForm } from "./form";

export const EditClubModal = observer(() => {
  return (
    <EventBasedModal
      title="Edit Club"
      openEvent={EditClub}
      closeEvent={ClubEdited}
    >
      {({ id }) => <EditClubForm id={id} />}
    </EventBasedModal>
  );
});
