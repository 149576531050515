import yup from "@/yup";

export function useEditClubFormSchema() {
  return yup.object({
    description: yup.string().min(3).default("").required(),
  });
}

export type EditClubFormSchema = yup.InferType<
  ReturnType<typeof useEditClubFormSchema>
>;
