import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { ExchangePlayerBalance } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({
        defaultMessage: "Exchange Sweepstakes balance",
      })}
      openEvent={ExchangePlayerBalance}
    >
      {({ playerId, operationType }) => (
        <Form playerId={playerId} operationType={operationType} />
      )}
    </EventBasedModal>
  );
});
