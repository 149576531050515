import classNames from "classnames";
import { observer } from "mobx-react-lite";

type TProps = {
  isExpandable: boolean;
  isExpanded: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

function ExpandButton({ isExpandable, isExpanded, onClick }: TProps) {
  const iconPrefix = `ant-table-row-expand-icon`;
  return (
    <button
      type="button"
      onClick={(event) => {
        onClick(event);
        event.stopPropagation();
      }}
      className={classNames(iconPrefix, {
        [`${iconPrefix}-spaced`]: !isExpandable,
        [`${iconPrefix}-expanded`]: isExpandable && isExpanded,
        [`${iconPrefix}-collapsed`]: isExpandable && !isExpanded,
      })}
    />
  );
}

export default observer(ExpandButton);
