import { observer } from "mobx-react-lite";

import { Entity } from "@/types";

import { useAddBannerFormState } from "./add-form.state";
import { AddBannerFormView } from "./add-form.view";

export type AddBannerFormProps = {
  sliderId: number;
  entity: Entity | null;
};

export const AddBannerForm = observer<AddBannerFormProps>((props) => {
  const state = useAddBannerFormState(props);
  return <AddBannerFormView state={state} />;
});
