import { green, red } from "@ant-design/colors";
import { ArrowLeftOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, List, Row, Space } from "antd";
import { throttle } from "lodash-es";
import { observer } from "mobx-react-lite";
import moment from "moment/moment";
import React, { SyntheticEvent, useLayoutEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { TConversation, TPlayerInfo } from "@/api";
import { SearchParamKey } from "@/constants";
import { useEntitiesSearchParams } from "@/hooks";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import FormItem from "@/ui/_common_/form-item";

import { InstantMessagesDetailsState } from "../instant-messages-details-state";

import { Message } from "./message";
import { IChatProps } from "./types";

const SCROLL_THRESHOLD = 50;
const MESSAGE_INPUT_MAX_LENGTH = 4096;
const SCROLL_THROTTLE_DELAY_MS = 300;

export const Chat = observer(({ state, player, conversation }: IChatProps) => {
  const intl = useIntl();
  const scrollChatContainerRef = useRef<HTMLDivElement>(null);
  const lastScrollAreaHeightRef = useRef(0);
  const isScrollAtBottom = useRef(true);

  const reversedMessages = useMemo(
    () => [...state.messages].reverse(),
    [state.messages],
  );

  const throttledHandleScroll = useRef(
    throttle(
      (scrollTop: number, scrollHeight: number, clientHeight: number) => {
        isScrollAtBottom.current =
          scrollHeight - scrollTop - clientHeight < SCROLL_THRESHOLD;

        if (scrollTop === 0 && !state.isNextMessageSequenceLoading) {
          state.nextMessageSequence();
        }
      },
      SCROLL_THROTTLE_DELAY_MS,
    ),
  ).current;

  const scrollToBottom = () => {
    if (scrollChatContainerRef.current) {
      scrollChatContainerRef.current.scrollTo({
        top: scrollChatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useLayoutEffect(() => {
    const scrollContainer = scrollChatContainerRef.current;

    if (!scrollContainer) {
      return;
    }

    if (!state.isNextMessageSequenceLoading) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight - lastScrollAreaHeightRef.current,
        behavior: "auto",
      });
    }

    lastScrollAreaHeightRef.current = scrollContainer.scrollHeight;
  }, [state.isNextMessageSequenceLoading]);

  useLayoutEffect(() => {
    if (isScrollAtBottom.current || state.sendMessageQuery.isPending) {
      scrollToBottom();
    }
  }, [state.sendMessageQuery.isPending, state.messages]);

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (!target) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = target;

    throttledHandleScroll(scrollTop, scrollHeight, clientHeight);
  };

  const handleSendMessage = () => {
    if (state.message.trim()) {
      state.sendMessage();
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSendMessage();
  };

  return (
    <Card
      style={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      title={
        <ChatHeader player={player} conversation={conversation} state={state} />
      }
    >
      <div
        className="chat-list"
        onScroll={handleScroll}
        ref={scrollChatContainerRef}
        style={{
          maxHeight: "100%",
          height: "75vh",
          overflowY: "auto",
          flexGrow: 1,
          marginBottom: "12px",
        }}
      >
        <List
          className="chat"
          itemLayout="vertical"
          dataSource={reversedMessages}
          loading={
            state.sendMessageQuery.isPending ||
            state.deleteMessageQuery.isPending ||
            state.isNextMessageSequenceLoading
          }
          renderItem={(item) => (
            <List.Item key={item.messageId}>
              <Message item={item} state={state} player={player} />
            </List.Item>
          )}
        />
      </div>
      <Form
        disabled={state.sendMessageQuery.isPending}
        style={{ minWidth: "100%" }}
      >
        {(state.sendMessageQuery.isRejected ||
          state.deleteMessageQuery.isRejected ||
          state.nextMessageSequenceQuery.isRejected ||
          state.messagesQuery.isRejected) && (
          <ErrorsFormatter
            queries={[
              state.sendMessageQuery,
              state.deleteMessageQuery,
              state.messagesQuery,
              state.nextMessageSequenceQuery,
            ]}
          />
        )}
        <FormItem>
          <Row wrap={false} gutter={[2, 2]}>
            <Col flex={1}>
              <Input
                maxLength={MESSAGE_INPUT_MAX_LENGTH}
                inputMode="text"
                value={state.message}
                onChange={(e) => (state.message = e.target.value)}
                placeholder={`${intl.formatMessage({
                  defaultMessage: "Type a message",
                })}...`}
              />
            </Col>
            <Col>
              <Button
                onClick={handleFormSubmit}
                type="primary"
                htmlType="submit"
              >
                {intl.formatMessage({ defaultMessage: "Send" })}
              </Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </Card>
  );
});

function ChatHeader({
  state,
  conversation,
}: {
  player: TPlayerInfo;
  conversation: TConversation;
  state: InstantMessagesDetailsState;
}) {
  const search = useEntitiesSearchParams();
  const intl = useIntl();

  return (
    <Row gutter={[12, 12]} align="middle">
      <Col>
        <Link
          style={{ color: "inherit" }}
          to={{
            pathname: "/instant-messages",
            search:
              search ||
              new URLSearchParams({
                [SearchParamKey.ClientId]: conversation.clientId?.toString(),
              }).toString(),
          }}
        >
          <ArrowLeftOutlined />
        </Link>
      </Col>
      <Col>{intl.formatMessage({ defaultMessage: "Chat" })}</Col>
      <Col>
        <Space direction="horizontal">
          <ClockCircleOutlined />
          <span>
            {conversation.createdAt !== null
              ? moment(new Date(conversation.createdAt)).format(
                  "DD.MM.YYYY HH:mm",
                )
              : "—"}
          </span>
        </Space>
      </Col>
      <Col style={{ marginLeft: "auto" }}>
        <Space direction="horizontal">
          <span
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: conversation?.isActive ? green[6] : red[6],
            }}
          >
            {conversation?.isActive
              ? intl.formatMessage({ defaultMessage: "Active" })
              : intl.formatMessage({ defaultMessage: "Closed" })}
          </span>
          <Button
            type="primary"
            onClick={state.closeConversation}
            loading={state.conversationCloseQuery.isPending}
            disabled={!conversation.isActive}
          >
            {intl.formatMessage({ defaultMessage: "Solved" })}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
