import { useIntl } from "react-intl";

import { OpenNewCategoryModal } from "@/events";
import { useGlobalStore } from "@/stores";

export function useRubricatorPageState() {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  function openNewCategoryModal(parentId: number | null) {
    eventBusService.publish(new OpenNewCategoryModal({ parentId }));
  }

  return { intl, openNewCategoryModal };
}

export type RubricatorPageState = ReturnType<typeof useRubricatorPageState>;
