import { WarningFilled } from "@ant-design/icons";
import { Col, Modal, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { useGlobalStore } from "@/stores";

export const PasswordAlert = observer(function PasswordAlert() {
  const intl = useIntl();
  const { userStore } = useGlobalStore();
  const [isOpen, setIsOpen] = useState(true);

  if (!userStore.user?.needChangePassword) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Important! Change the password needed.",
      })}
      onCancel={() => setIsOpen(false)}
      okText={intl.formatMessage({ defaultMessage: "Change password" })}
      onOk={() => {
        setIsOpen(false);
        userStore.userUpdateStore.modal.open();
      }}
      cancelText={intl.formatMessage({ defaultMessage: "Later" })}
    >
      <Row gutter={[24, 24]} wrap={false}>
        <Col>
          <WarningFilled style={{ fontSize: 48, color: "#f00" }} />
        </Col>
        <Col>
          <Typography.Text>
            {intl.formatMessage(
              {
                defaultMessage:
                  "Your password has not been changed for a long time.{br}The last time you changed your password was {days} days ago.{br}In order to ensure the security of your account, please change your password.",
              },
              { br: <br />, days: userStore.user.untilChangePassword },
            )}
          </Typography.Text>
        </Col>
      </Row>
    </Modal>
  );
});
