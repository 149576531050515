import { observer } from "mobx-react-lite";

import { useNewClubFormState } from "./form.state";
import { NewClubFormView } from "./form.view";

export type Props = {
  partnerId: number;
};

export const NewClubForm = observer<Props>((props) => {
  const state = useNewClubFormState(props);
  return <NewClubFormView state={state} />;
});
