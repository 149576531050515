import { yupResolver } from "@hookform/resolvers/yup";
import { MenuProps, notification } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  BannerAPI,
  BonusAPI,
  GameAPI,
  LanguageAPI,
  ProviderAPI,
  TagAPI,
} from "@/api";
import { CloseAddBannerModal, OpenEditBannerModal } from "@/events";
import Query from "@/models/query";
import { SelectorStore, useGlobalStore } from "@/stores";

import { AddBannerFormProps } from "./add-form";
import { AddBannerFormSchema, useAddBannerFormSchema } from "./add-form.schema";

export function useAddBannerFormState({
  sliderId,
  entity,
}: AddBannerFormProps) {
  const intl = useIntl();

  const schema = useAddBannerFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<AddBannerFormSchema>({
    resolver,
    defaultValues: {
      type: "simple",
      titles: { en: "" },
      subtitles: { en: "" },
      actionType: null,
      actionValue: null,
      bonusId: undefined,
    },
  });

  function handleTabRemove(key: string) {
    const titles = form.watch("titles", {});
    const subtitles = form.watch("subtitles", {});

    const filteredTitles = Object.fromEntries(
      Object.entries(titles).filter(([lang]) => lang !== key),
    );
    const filteredSubtitles = Object.fromEntries(
      Object.entries(subtitles).filter(([lang]) => lang !== key),
    );

    form.setValue("titles", filteredTitles);
    form.setValue("subtitles", filteredSubtitles);

    form.trigger("titles");
    form.trigger("subtitles");
  }

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(
    () =>
      new Query(async (values: AddBannerFormSchema) => {
        const titles = Object.entries(values.titles).map(([lang, value]) => ({
          lang,
          value,
        }));
        const subtitles = Object.entries(values.subtitles).map(
          ([lang, value]) => ({
            lang,
            value,
          }),
        );

        const { id } = await BannerAPI.create({
          sliderId,
          ...values,
          titles,
          subtitles,
        });

        notification.success({
          message: "",
          description: "The banner was successfully created.",
        });

        eventBusService.publish(new CloseAddBannerModal({}));
        eventBusService.publish(new OpenEditBannerModal({ id, entity }));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit(mutation.submit);

  const providerSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: ProviderAPI.filter,
      getByIdMethod: ProviderAPI.getById,
      labelKey: "name",
    });
    store.setParameters({});
    store.fetchItems();
    return store;
  }, []);

  const gameSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: GameAPI.filter,
      getByIdMethod: GameAPI.getById,
      labelKey: "name",
    });
    store.setParameters({ ...entity });
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagsQuery = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await TagAPI.getAll();
    });
    query.submit();
    return query;
  }, []);

  const languagesQuery = useMemo(() => {
    const query = new Query(LanguageAPI.getAll);
    query.submit();
    return query;
  }, []);

  const bonusesQuery = useMemo(() => {
    const query = new Query(BonusAPI.getAll);
    query.submit();
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bonusesOptions =
    bonusesQuery.data?.data.map((bonus) => ({
      label: bonus.type,
      value: bonus.id,
    })) ?? [];

  const type = form.watch("type");
  const titles = form.watch("titles");
  const subtitles = form.watch("subtitles");
  const actionType = form.watch("actionType");
  const tagsOptions =
    tagsQuery.data?.data?.map((tag) => ({
      label: tag.name,
      value: tag.id.toString(),
    })) ?? [];

  const usedLanguageCodes = useMemo(() => {
    const titleLanguages = Object.keys(titles);
    const subtitleLanguages = Object.keys(subtitles);
    const languages = [...new Set([...titleLanguages, ...subtitleLanguages])];
    languages.sort((a, b) => {
      if (a === "en") {
        return -Infinity;
      }
      if (b === "en") {
        return Infinity;
      }
      return a.localeCompare(b);
    });
    return languages;
  }, [subtitles, titles]);

  const unusedLanguageCodes = useMemo(() => {
    return (
      languagesQuery.data?.data
        ?.filter((language) => !usedLanguageCodes.includes(language.code))
        ?.map((language) => language.code) ?? []
    );
  }, [languagesQuery.data?.data, usedLanguageCodes]);

  const addLanguageMenu: MenuProps = {
    onClick(item) {
      const titles = form.watch("titles", {});
      const subtitles = form.watch("titles", {});

      form.setValue("titles", { ...titles, [item.key]: "" });
      form.setValue("subtitles", { ...subtitles, [item.key]: "" });
    },
    items: unusedLanguageCodes.map((code) => ({
      key: code,
      label: code.toUpperCase(),
    })),
  };

  useEffect(() => {
    if (actionType === "provider") {
      form.setValue(
        "actionValue",
        providerSelectorStore.selectedId?.toString(),
      );
    } else if (actionType === "game") {
      gameSelectorStore.setParameters({
        providerId: providerSelectorStore.selectedId,
      });
      gameSelectorStore.setSelectedId();
      gameSelectorStore.fetchItems();
      form.resetField("actionValue");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, providerSelectorStore.selectedId]);

  useEffect(() => {
    if (actionType === "game") {
      form.setValue("actionValue", gameSelectorStore.selectedId?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, gameSelectorStore.selectedId]);

  return {
    intl,

    form,

    mutation,

    type,
    titles,
    subtitles,
    actionType,

    handleSubmit,
    handleTabRemove,

    providerSelectorStore,
    gameSelectorStore,

    tagsOptions,
    bonusesOptions,

    usedLanguageCodes,
    unusedLanguageCodes,
    addLanguageMenu,
  };
}

export type AddBannerFormState = ReturnType<typeof useAddBannerFormState>;
