import { message } from "antd";
import { useMemo } from "react";

import { HallAPI } from "@/api";
import Query from "@/models/query";

import { HallSpecialSettingsProps } from "./hall-special-settings";

export function useHallSpecialSettingsState(props: HallSpecialSettingsProps) {
  const warmUpMutation = useMemo(() => {
    return new Query(async () => {
      await HallAPI.warmUp({ id: props.hallId });
      message.success("Hall is warmed up");
    });
  }, [props.hallId]);

  return { ...props, warmUpMutation };
}

export type HallSpecialSettingsState = ReturnType<
  typeof useHallSpecialSettingsState
>;
