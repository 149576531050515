import { makeAutoObservable } from "mobx";

import { PlayerAPI } from "@/api";
import { ViewModel } from "@/hooks/use-view-model";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import Query from "@/models/query";

import { Props } from "./initial.view";

export class CurrentBonusState implements ViewModel<Props> {
  private playerId: number | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  currentBonusQuery = new ItemsQuery(PlayerAPI.getCurrentBonus, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  playerQuery = new Query(PlayerAPI.getById);

  filter = async (options?: TSubmitOptions) => {
    await this.currentBonusQuery.submit({ id: this.playerId! }, options);
  };

  get bonuses() {
    return this.currentBonusQuery.data!;
  }

  get isIdleOrPending() {
    return (
      this.currentBonusQuery.isIdle ||
      this.currentBonusQuery.isPending ||
      this.playerQuery.isIdle ||
      this.playerQuery.isPending
    );
  }

  get isRejected() {
    return this.currentBonusQuery.isRejected;
  }

  onViewMount = async ({ playerId, setPlayerName }: Props) => {
    await Promise.allSettled([
      this.playerQuery.submit({ id: playerId }),
      this.currentBonusQuery.submit({ id: playerId }),
    ]);

    this.playerId = playerId;

    if (this.playerQuery.isFulfilled) {
      setPlayerName(this.playerQuery.data.login);
    }
  };

  onViewUnmount = () => {
    this.currentBonusQuery.reset();
  };
}
