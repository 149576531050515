import { observer } from "mobx-react-lite";

import { useFormState } from "./form.state";
import { FormView } from "./form.view";

export type FormProps = {
  id: number;
};

export const Form = observer((props: FormProps) => {
  const state = useFormState(props);
  return <FormView state={state} />;
});
