import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI, ClientAPI } from "@/api";
import Query from "@/models/query";

import { AgentLobbySettingsProps } from "./agent-lobby-settings";
import {
  AgentLobbySettingsSchema,
  useAgentLobbySettingsSchema,
} from "./agent-lobby-settings.schema";

export function useAgentLobbySettingsState({
  agentId,
}: AgentLobbySettingsProps) {
  const intl = useIntl();

  const schema = useAgentLobbySettingsSchema();
  const form = useForm<AgentLobbySettingsSchema>({
    resolver: yupResolver(schema),
  });
  const formValues = form.watch();

  const { clientQuery, parentAgentQuery, agentQuery } = useMemo(() => {
    const clientQuery = new Query(ClientAPI.getById);
    const parentAgentQuery = new Query(AgentAPI.getById);
    const agentQuery = new Query(AgentAPI.getById);
    agentQuery.submit({ id: agentId }).then(() => {
      const agent = agentQuery.data!;
      if (agent.parentAgentId) {
        parentAgentQuery.submit({ id: agent.parentAgentId });
      } else {
        clientQuery.submit({ id: agent.clientId });
      }
      const values = schema.cast(agent, { stripUnknown: true });
      form.reset(values);
    });
    return { clientQuery, parentAgentQuery, agentQuery };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isContactsShown = useMemo(() => {
    return (
      parentAgentQuery.data?.lobbySettings?.isContactsShown ??
      clientQuery.data?.lobbySettings?.isContactsShown ??
      false
    );
  }, [clientQuery.data, parentAgentQuery.data]);

  const isAutoEnableGamesShown = useMemo(() => {
    return parentAgentQuery.data?.autoEnableGames ?? true;
  }, [parentAgentQuery.data]);

  const submitQuery = useMemo(() => {
    return new Query(async (values: AgentLobbySettingsSchema) => {
      await AgentAPI.update({ id: agentId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The lobby settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    agentId,
    intl,
    form,
    formValues,
    clientQuery,
    parentAgentQuery,
    agentQuery,
    isContactsShown,
    isAutoEnableGamesShown,
    submitQuery,
  };
}

export type AgentLobbySettingsState = ReturnType<
  typeof useAgentLobbySettingsState
>;
