import { yupResolver } from "@hookform/resolvers/yup";
import { MenuProps, notification, Upload } from "antd";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { IntlShape, useIntl } from "react-intl";

import {
  BannerAPI,
  BonusAPI,
  GameAPI,
  LanguageAPI,
  ProviderAPI,
  TagAPI,
} from "@/api";
import Query from "@/models/query";
import { SelectorStore } from "@/stores";

import { EditBannerFormProps } from "./edit-form";
import {
  EditBannerFormSchema,
  useEditBannerFormSchema,
} from "./edit-form.schema";

type FileChangeEvent = Parameters<
  Exclude<Parameters<typeof Upload>[0]["customRequest"], undefined>
>[0];

interface UpdateImageParams {
  id: number;
  type: "desktop" | "desktop_extrabet" | "mobile";
  file: File;
  intl: IntlShape;
}

async function updateImage({ id, type, file, intl }: UpdateImageParams) {
  const body = new FormData();
  body.append("img", file);

  const response = await fetch(`/media/banner?id=${id}&type=${type}`, {
    method: "POST",
    body: body,
  });

  if (response.status !== 200) {
    const { status, title } = await response.json();

    if (status === 500) {
      throw new Error(title);
    }

    const _ = intl.formatMessage;

    throw new Error(_({ id: `media_api_errors/code_${status}` }));
  }
}

export function useEditBannerFormState({ id, entity }: EditBannerFormProps) {
  const intl = useIntl();

  const query = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await BannerAPI.get({ id });
    });
    query.submit();
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useEditBannerFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditBannerFormSchema>({ resolver });

  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (!query.data) {
      return;
    }

    const titles = Object.fromEntries(
      query.data.titles.map((title) => [title.lang, title.value]),
    );
    const subtitles = Object.fromEntries(
      query.data.subtitles.map((title) => [title.lang, title.value]),
    );

    if (query.data.actionType === "provider" && query.data.actionValue) {
      providerSelectorStore.setSelectedId(+query.data.actionValue);
    }

    if (query.data.actionType === "game" && query.data.actionValue) {
      const id = +query.data.actionValue;
      GameAPI.getById({ id })
        .then((game) => {
          providerSelectorStore.setSelectedId(game.providerId);
          gameSelectorStore.setSelectedId(id);
        })
        .catch(() => {
          //
        });
    }

    const values = schema.cast(
      { ...query.data, titles, subtitles },
      {
        assert: false,
        stripUnknown: true,
      },
    );

    form.reset(values);

    isInitializedRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  const desktopImageQuery = useMemo(
    () =>
      new Query(async (event: FileChangeEvent) => {
        if (!(event.file instanceof File)) {
          throw new Error("Invalid file");
        }

        await updateImage({
          id,
          type: "desktop",
          file: event.file,
          intl,
        });

        const data = await BannerAPI.get({ id });
        query.resolve(undefined, data);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const desktopExtrabetImageQuery = useMemo(
    () =>
      new Query(async (event: FileChangeEvent) => {
        if (!(event.file instanceof File)) {
          throw new Error("Invalid file");
        }

        await updateImage({
          id,
          type: "desktop_extrabet",
          file: event.file,
          intl,
        });

        const data = await BannerAPI.get({ id });
        query.resolve(undefined, data);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const mobileImageQuery = useMemo(
    () =>
      new Query(async (event: FileChangeEvent) => {
        if (!(event.file instanceof File)) {
          throw new Error("Invalid file");
        }

        await updateImage({
          id,
          type: "mobile",
          file: event.file,
          intl,
        });

        const data = await BannerAPI.get({ id });
        query.resolve(undefined, data);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function handleTabRemove(key: string) {
    const titles = form.watch("titles", {});
    const subtitles = form.watch("subtitles", {});

    const filteredTitles = Object.fromEntries(
      Object.entries(titles).filter(([lang]) => lang !== key),
    );
    const filteredSubtitles = Object.fromEntries(
      Object.entries(subtitles).filter(([lang]) => lang !== key),
    );

    form.setValue("titles", filteredTitles);
    form.setValue("subtitles", filteredSubtitles);

    form.trigger("titles");
    form.trigger("subtitles");
  }

  const mutation = useMemo(
    () =>
      new Query(async (values: EditBannerFormSchema) => {
        const titles = Object.entries(values.titles).map(([lang, value]) => ({
          lang,
          value,
        }));
        const subtitles = Object.entries(values.subtitles).map(
          ([lang, value]) => ({
            lang,
            value,
          }),
        );

        await BannerAPI.update({
          id,
          ...values,
          titles,
          subtitles,
        });

        notification.success({
          message: "",
          description: "The banner settings have been successfully saved.",
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit(mutation.submit);

  const providerSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: ProviderAPI.filter,
      getByIdMethod: ProviderAPI.getById,
      labelKey: "name",
      onSelect(item) {
        if (form.watch("actionType") === "provider") {
          if (item) {
            form.setValue("actionValue", item.id.toString());
          } else {
            form.resetField("actionValue");
          }
        }
        gameSelectorStore.setParameters(item ? { providerId: item.id } : {});
        gameSelectorStore.setSelectedId(undefined);
        gameSelectorStore.fetchItems();
      },
    });
    store.setParameters({});
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gameSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: GameAPI.filter,
      getByIdMethod: GameAPI.getById,
      labelKey: "name",
      onSelect(item) {
        if (form.watch("actionType") === "game") {
          if (item) {
            form.setValue("actionValue", item.id.toString());
          } else {
            form.resetField("actionValue");
          }
        }
      },
    });
    store.setParameters({ ...entity });
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagsQuery = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await TagAPI.getAll();
    });
    query.submit();
    return query;
  }, []);

  const bonusesQuery = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await BonusAPI.getAll();
    });
    query.submit();
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languagesQuery = useMemo(() => {
    const query = new Query(LanguageAPI.getAll);
    query.submit();
    return query;
  }, []);

  const banner = query.data!;
  const type = form.watch("type");
  const titles = form.watch("titles", {});
  const subtitles = form.watch("subtitles", {});
  const actionType = form.watch("actionType");
  const tagsOptions =
    tagsQuery.data?.data?.map((tag) => ({
      label: tag.name,
      value: tag.id.toString(),
    })) ?? [];
  const bonusesOptions =
    bonusesQuery.data?.data?.map((bonus) => ({
      label: bonus.type,
      value: bonus.id,
    })) ?? [];

  const usedLanguageCodes = useMemo(() => {
    const titleLanguages = Object.keys(titles);
    const subtitleLanguages = Object.keys(subtitles);
    const languages = [...new Set([...titleLanguages, ...subtitleLanguages])];
    languages.sort((a, b) => {
      if (a === "en") {
        return -Infinity;
      }
      if (b === "en") {
        return Infinity;
      }
      return a.localeCompare(b);
    });
    return languages;
  }, [subtitles, titles]);

  const unusedLanguageCodes = useMemo(() => {
    return (
      languagesQuery.data?.data
        ?.filter((language) => !usedLanguageCodes.includes(language.code))
        ?.map((language) => language.code) ?? []
    );
  }, [languagesQuery.data?.data, usedLanguageCodes]);

  const addLanguageMenu: MenuProps = {
    onClick(item) {
      const titles = form.watch("titles", {});
      const subtitles = form.watch("titles", {});

      form.setValue("titles", { ...titles, [item.key]: "" });
      form.setValue("subtitles", { ...subtitles, [item.key]: "" });
    },
    items: unusedLanguageCodes.map((code) => ({
      key: code,
      label: code.toUpperCase(),
    })),
  };

  const canChangeActionValue = useRef(false);

  useEffect(() => {
    if (canChangeActionValue.current) {
      form.setValue("actionValue", null);
    } else if (isInitializedRef.current) {
      canChangeActionValue.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  return {
    id,

    intl,

    form,

    query,
    mutation,
    desktopImageQuery,
    desktopExtrabetImageQuery,
    mobileImageQuery,

    banner,

    type,
    titles,
    subtitles,
    actionType,

    handleSubmit,
    handleTabRemove,

    providerSelectorStore,
    gameSelectorStore,

    tagsOptions,
    bonusesOptions,

    usedLanguageCodes,
    unusedLanguageCodes,
    addLanguageMenu,
  };
}

export type EditBannerFormState = ReturnType<typeof useEditBannerFormState>;
