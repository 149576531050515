import { Button, Col, Form, Row, Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { AgentAPI, AlternativePatchAPI, HallAPI } from "@/api";
import Query from "@/models/query";

import ErrorsFormatter from "../_common_/errors-formatter";

interface IProps {
  entity: { agentId: number } | { hallId: number };
  currency: string;
}

export const AlternativePatch = observer(({ entity, currency }: IProps) => {
  const intl = useIntl();

  const patchesQuery = useMemo(() => {
    const query = new Query(AlternativePatchAPI.filter);
    query.submit({ currency });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [patchId, setPatchId] = useState<number | null>(null);

  const options = (patchesQuery.data?.data ?? []).map((patch) => ({
    label: patch.name,
    value: patch.id,
  }));

  const patchQuery = useMemo(() => {
    const query = new Query<void, number | null>(async () => {
      if ("agentId" in entity) {
        const agent = await AgentAPI.getById({ id: entity.agentId });
        return agent.alternativePatchId;
      } else {
        const hall = await HallAPI.getById({ id: entity.hallId });
        return hall.alternativePatchId;
      }
    });
    query.submit().then(() => {
      setPatchId(query.data!);
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeQuery = useMemo(
    () =>
      new Query<void, void>(async () => {
        await AlternativePatchAPI.apply({ ...entity, patchId: null });
      }),
    [entity],
  );

  const applyQuery = useMemo(
    () =>
      new Query<void, void>(async () => {
        await AlternativePatchAPI.apply({ ...entity, patchId });
      }),
    [entity, patchId],
  );

  function handleRemoveClick() {
    setPatchId(null);
    removeQuery.submit();
  }

  function handleApplyClick() {
    applyQuery.submit();
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[removeQuery, applyQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Alternative patch" })}
        >
          <Select
            loading={patchesQuery.isPending || patchQuery.isPending}
            placeholder={intl.formatMessage({
              defaultMessage: "Select an alternative patch",
            })}
            style={{ flex: "1" }}
            options={options}
            value={patchId}
            onChange={(patchId: number | undefined) =>
              setPatchId(patchId ?? null)
            }
          />
        </Form.Item>
        <Row justify="end" gutter={[8, 8]}>
          {(patchId !== null || removeQuery.isPending) && (
            <Col>
              <Button
                onClick={handleRemoveClick}
                loading={removeQuery.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Remove" })}
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="primary"
              onClick={handleApplyClick}
              loading={applyQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
