import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI, HallAPI, TAgent } from "@/api";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { THallDepositSchema } from "@/schemas";
import { CurrencyUtilities } from "@/utilities";

import { HallStore } from ".";

export class HallDepositStore {
  constructor(public readonly hallStore: HallStore) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.hallStore.intlService.intl;
  }

  modal = new Modal();
  depositQuery = new Query(HallAPI.depositMoney);

  private _amount?: number;

  get amount() {
    return this._amount;
  }

  setAmount = (amount?: number) => {
    this._amount = amount;
  };

  handleSubmit = async (values: THallDepositSchema) => {
    await this.depositQuery.submit({
      ...values,
      id: this.hallStore.hall.id,
      amount: CurrencyUtilities.toMinorUnits(values.amount),
    });

    if (!this.depositQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage:
          "The money has been successfully deposited to the balance.",
      }),
    });

    this.modal.close();

    await this.hallStore.fetch();
  };

  agentQuery = new Query(AgentAPI.getById);

  private _agent?: TAgent;

  get agent() {
    return this._agent;
  }

  setAgent = (agent?: TAgent) => {
    this._agent = agent;
  };

  fetchAgent = async () => {
    const { agentId } = this.hallStore.hall;
    if (agentId === null) {
      return;
    }

    await this.agentQuery.submit({ id: agentId });

    if (!this.agentQuery.isFulfilled) {
      return;
    }

    const agent = this.agentQuery.data;
    this.setAgent(agent);
  };

  get isAvailableBalanceShown() {
    if (this.hallStore.hall.agentId === null) {
      return false;
    }
    if (!this.agentQuery.isFulfilled) {
      return false;
    }
    return !this.agentQuery.data.isBalanceUnlimited;
  }

  get availableBalance() {
    return this.agent?.balance ?? 0;
  }
}
