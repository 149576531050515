import ANTD_EN_US_LOCALE from "antd/lib/locale/en_US.js";
import { Locale } from "antd/lib/locale-provider";
import { makeAutoObservable } from "mobx";
import { createIntl } from "react-intl";

import { APIError } from "@/api";
import { TLocaleCode } from "@/locales";
import Query from "@/models/query";

const DEFAULT_LOCALE = "en";

const iso639ToIETF = {
  en: "en_US",
  de: "de_DE",
  es: "es_ES",
  fr: "fr_FR",
  tr: "tr_TR",
  pt: "pt_BR",
};

type Messages = Record<string, string>;

export class IntlService {
  constructor() {
    makeAutoObservable(this);

    this.initialize();
  }

  private _groupSeparator = " ";

  public get groupSeparator() {
    return this._groupSeparator;
  }

  public set groupSeparator(value) {
    this._groupSeparator = value;
    window.localStorage.setItem("NumberFormatSettings.GroupSeparator", value);
  }

  private _getDefaultGroupSeparator(locale: string) {
    const formatter = new Intl.NumberFormat(locale.replace("_", "-"));
    const parts = formatter.formatToParts(1_000_000);
    const separator = parts.find((part) => part.type === "group")?.value ?? "";
    return separator;
  }

  private _decimalSeparator = ".";

  public get decimalSeparator() {
    return this._decimalSeparator;
  }

  public set decimalSeparator(value) {
    this._decimalSeparator = value;
    window.localStorage.setItem("NumberFormatSettings.DecimalSeparator", value);
  }

  private _getDefaultDecimalSeparator(locale: string) {
    const formatter = new Intl.NumberFormat(locale.replace("_", "-"));
    const parts = formatter.formatToParts(1.1);
    const separator =
      parts.find((part) => part.type === "decimal")?.value ?? "";
    return separator;
  }

  private _locale = "en";

  public get locale() {
    return this._locale;
  }

  public async setLocale(locale: string) {
    this._locale = locale;

    await Promise.all([
      this.messagesQuery.submit(this._locale),
      this.antdLocaleQuery.submit(this._locale),
    ]);

    if (this.messagesQuery.isRejected || this.antdLocaleQuery.isRejected) {
      // fallback lang
      this._locale = DEFAULT_LOCALE;
      await Promise.all([
        this.messagesQuery.submit(this._locale),
        this.antdLocaleQuery.submit(this._locale),
      ]);
    }

    if (this.messagesQuery.isFulfilled && this.antdLocaleQuery.isFulfilled) {
      this.intl = createIntl({
        locale: this._locale.replace("_", "-"),
        messages: this.messagesQuery.data,
      });
      this.antdLocale = this.antdLocaleQuery.data;
    }

    window.localStorage.setItem("locale", this._locale);
  }

  private _intl = createIntl({
    locale: DEFAULT_LOCALE.replace("_", "-"),
  });

  public get intl() {
    return this._intl;
  }

  private set intl(value) {
    this._intl = value;
  }

  private _antdLocale = ANTD_EN_US_LOCALE;

  public get antdLocale() {
    return this._antdLocale;
  }

  private set antdLocale(value) {
    this._antdLocale = value;
  }

  private _isInitialized = false;

  public get isInitialized() {
    return this._isInitialized;
  }

  private set isInitialized(value) {
    this._isInitialized = value;
  }

  private initialize = async () => {
    // selected lang
    let locale = window.localStorage.getItem("locale");
    if (locale === null) {
      // browser lang
      const language = window.navigator.language;
      if (language.length === 5) {
        locale = language.substring(0, 2).toLowerCase();
      } else {
        locale = DEFAULT_LOCALE;
      }
    }

    await this.setLocale(locale);

    this.groupSeparator =
      window.localStorage.getItem("NumberFormatSettings.GroupSeparator") ??
      this._getDefaultGroupSeparator(locale);

    this.decimalSeparator =
      window.localStorage.getItem("NumberFormatSettings.DecimalSeparator") ??
      this._getDefaultDecimalSeparator(locale);

    this.isInitialized = true;
  };

  private fetchMessages = async (locale: string): Promise<Messages> => {
    const { default: messages } = await import(
      `../i18n/translations/${locale}.json`
    );
    return messages;
  };

  private isLocaleSupported = (locale: string): locale is TLocaleCode => {
    return locale in iso639ToIETF;
  };

  private fetchAntdLocale = async (locale: string): Promise<Locale> => {
    const verifiedLocale = this.isLocaleSupported(locale) ? locale : "en";
    const { default: antdLocale } = await import(
      `antd/lib/locale/${iso639ToIETF[verifiedLocale]}.js`
    );
    return antdLocale;
  };

  formatError = (error: unknown) => {
    const _ = this.intl.formatMessage;
    if (error instanceof APIError) {
      return (
        `[${error.code}] ` +
        _({
          id: `api_errors/code_${error.code}`,
          defaultMessage: error.message,
        })
      );
    }
    if (error instanceof Error) {
      return error.message;
    }
    return String(error);
  };

  messagesQuery = new Query(this.fetchMessages);
  antdLocaleQuery = new Query(this.fetchAntdLocale);
}
