import { Button, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { SportSettings } from "@/ui/sport-settings/form";

import { HallSpecialSettingsState } from "./hall-special-settings.state";

type Props = {
  state: HallSpecialSettingsState;
};

export const HallSpecialSettingsView = observer<Props>(({ state }) => {
  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.warmUpMutation]} />
      <Button
        onClick={state.warmUpMutation.submit}
        loading={state.warmUpMutation.isPending}
      >
        C27: Warm up the shop cache
      </Button>
      <SportSettings
        entity={{ hallId: state.hallId }}
        currency={state.currency}
      />
    </Space>
  );
});
