import { Alert, Space } from "antd";
import { observer } from "mobx-react-lite";
import { CSSProperties, FC, useMemo } from "react";
import { useIntl } from "react-intl";

import { APIError } from "@/api";
import { useIsLG, useIsSM } from "@/hooks";
import Query from "@/models/query";

type TProps = {
  queries: Query[];
  isClosable?: boolean;
  isSticky?: boolean;
  style?: CSSProperties;
};

function ErrorsFormatter({
  queries,
  isClosable = true,
  isSticky = false,
  style: defaultStyle,
}: TProps) {
  const isSM = useIsSM();
  const isLG = useIsLG();

  if (queries.every((query) => !query.isRejected)) {
    return null;
  }

  const top = isLG ? -20 : isSM ? -12 : -4;

  const baseStyle = { width: "100%", ...defaultStyle };

  const style: CSSProperties = isSticky
    ? {
        ...baseStyle,
        position: "sticky",
        top,
        zIndex: 100,
      }
    : baseStyle;

  return (
    <Space direction="vertical" style={style}>
      {queries
        .filter((query) => query.isRejected)
        .map((query, index) => (
          <ErrorFormatter key={index} query={query} isClosable={isClosable} />
        ))}
    </Space>
  );
}

type TErrorFormatterProps = {
  query: Query;
  isClosable?: boolean;
};

const ErrorFormatter: FC<TErrorFormatterProps> = observer(
  ({ query, isClosable = true }) => {
    const intl = useIntl();
    const _ = useMemo(() => intl.formatMessage, [intl.formatMessage]);
    const message = useMemo(() => {
      if (!query.isRejected) {
        return null;
      }
      const { error } = query;
      if (error instanceof APIError) {
        return (
          `[${error.code}] ` +
          _({
            id: `api_errors/code_${error.code}`,
            defaultMessage: error.message,
          })
        );
      }
      return error.message;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_, query.isRejected, query]);

    if (!query.isRejected) {
      return null;
    }

    return (
      <Alert
        showIcon
        message={message}
        type="error"
        closable={isClosable}
        style={{ width: "100%" }}
      />
    );
  },
);

export default observer(ErrorsFormatter);
