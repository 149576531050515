import { Modal } from "antd";
import { useObserver } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { HallAPI } from "@/api";
import { HallPanicUpdated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { Siren } from "../icons";

import { iconStyle } from "./sidebar";

export function usePanicItem() {
  const intl = useIntl();
  const { userStore, eventBusService } = useGlobalStore();

  const id = useObserver(() => userStore.hall?.id ?? null);

  const hallQuery = useMemo(() => {
    if (!id) {
      return null;
    }

    const query = new Query(async () => HallAPI.getById({ id }));
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isPanicEnabled = useObserver(
    () => hallQuery?.data?.lobbySettings?.isPanicEnabled ?? false,
  );

  const [enablePanicQuery] = useState(
    () =>
      new Query(async () => {
        if (!id) {
          return;
        }
        await HallAPI.enablePanic({ id });
        await hallQuery?.submit({});
      }),
  );
  const [disablePanicQuery] = useState(
    () =>
      new Query(async () => {
        if (!id) {
          return;
        }
        await HallAPI.disablePanic({ id });
        await hallQuery?.submit({});
      }),
  );

  const handlePanicUpdate = useCallback(async () => {
    if (!id) {
      return;
    }
    await hallQuery?.submit({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventBusService.subscribe(HallPanicUpdated, handlePanicUpdate);
    return () => {
      eventBusService.unsubscribe(HallPanicUpdated, handlePanicUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const label = (() => {
    if (hallQuery?.isPending) {
      return intl.formatMessage({ defaultMessage: "Loading..." });
    }
    if (enablePanicQuery.isPending) {
      return intl.formatMessage({ defaultMessage: "Enabling..." });
    }
    if (disablePanicQuery.isPending) {
      return intl.formatMessage({ defaultMessage: "Disabling..." });
    }
    if (isPanicEnabled) {
      return intl.formatMessage({ defaultMessage: "Panic enabled" });
    }
    return intl.formatMessage({ defaultMessage: "Panic disabled" });
  })();

  return {
    key: "/panic",
    className: "panic",
    icon: <Siren style={iconStyle} />,
    label,
    async onClick() {
      if (isPanicEnabled) {
        return disablePanicQuery.submit({});
      }

      Modal.confirm({
        title: intl.formatMessage({ defaultMessage: "Panic" }),
        content: intl.formatMessage({ defaultMessage: "Are you sure?" }),
        okText: intl.formatMessage({ defaultMessage: "Yes" }),
        cancelText: intl.formatMessage({ defaultMessage: "No" }),
        onOk() {
          return enablePanicQuery.submit({});
        },
      });
    },
  };
}
