import { Button, Card, Col, Row, Space, Spin, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TJackpotsReportAggregatedItem, TJackpotsReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { JackpotsReportState } from "./JackpotsReportState";

function JackpotsReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new JackpotsReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  const { formatMessage: _ } = intl;
  const onTableChange = (_: unknown, filters: { slotId?: number[] }) => {
    state.setSlotId(filters.slotId?.[0]);
  };
  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "Jackpots report",
      })}
    >
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.kioskSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select kiosk",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Spin
        spinning={
          state.filterQuery.isPending ||
          state.aggregateByCurrencyQuery.isPending ||
          state.aggregateByHallQuery.isPending ||
          state.aggregateByPlayerQuery.isPending
        }
      >
        <Card size="small">
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                state.filterQuery,
                state.aggregateByCurrencyQuery,
                state.aggregateByHallQuery,
                state.aggregateByPlayerQuery,
              ]}
            />
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={state.tab}
              onChange={(value) => (state.tab = value)}
            >
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Detailed" })}
                key="detailed"
              >
                <Table
                  dataSource={state.filterQuery.items}
                  rowKey="id"
                  size="small"
                  bordered
                  pagination={false}
                  onChange={onTableChange}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "ID",
                    })}
                    dataIndex="id"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Date/time" })}
                    dataIndex="createdAt"
                    render={(_, item: TJackpotsReportItem) =>
                      new Date(item.createdAt).toLocaleString()
                    }
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Agent",
                    })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Shop",
                    })}
                    dataIndex="hallName"
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Player",
                    })}
                    dataIndex="playerName"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Game" })}
                    dataIndex="gameName"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Slot",
                    })}
                    dataIndex="slotId"
                    render={(slotId) => slotId + 1}
                    filters={[
                      { text: "1", value: 0 },
                      { text: "2", value: 1 },
                      { text: "3", value: 2 },
                      { text: "4", value: 3 },
                    ]}
                    filterMultiple={false}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Status",
                    })}
                    dataIndex="status"
                    render={(status) =>
                      _({
                        id: `reports/jackpots/status/${status}`,
                        defaultMessage: status,
                      })
                    }
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Amount",
                    })}
                    render={(_, item: TJackpotsReportItem) => (
                      <MoneyFormatter cents={item.amount} />
                    )}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.filterQuery}
                  onChange={() => {
                    state.filter({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                key="currency"
              >
                <Table
                  dataSource={state.aggregateByCurrencyQuery.items}
                  showHeader={!!state.aggregateByCurrencyQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByCurrencyQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Amount",
                    })}
                    render={(_, item: TJackpotsReportItem) => (
                      <MoneyFormatter cents={item.amount} />
                    )}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.aggregateByCurrencyQuery}
                  onChange={() => {
                    state.aggregateByCurrency({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Shops" })}
                key="halls"
              >
                <Table
                  dataSource={state.aggregateByHallQuery.items}
                  showHeader={!!state.aggregateByHallQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByHallQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Agent",
                    })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Shop",
                    })}
                    dataIndex="hallName"
                    width="100%"
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 1"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[0]) {
                        return (
                          <>
                            {item.slots[0].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[0].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 2"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[1]) {
                        return (
                          <>
                            {item.slots[1].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[1].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 3"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[2]) {
                        return (
                          <>
                            {item.slots[2].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[2].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 4"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[3]) {
                        return (
                          <>
                            {item.slots[3].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[3].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Amount",
                    })}
                    render={(_, item: TJackpotsReportAggregatedItem) => (
                      <MoneyFormatter cents={item.amount} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Count",
                    })}
                    render={(_, item: TJackpotsReportAggregatedItem) =>
                      "count" in item ? item.count : "—"
                    }
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.aggregateByHallQuery}
                  onChange={() => {
                    state.aggregateByHall({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Players" })}
                key="players"
              >
                <Table
                  dataSource={state.aggregateByPlayerQuery.items}
                  showHeader={!!state.aggregateByPlayerQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByPlayerQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Agent",
                    })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Shop",
                    })}
                    dataIndex="hallName"
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Player",
                    })}
                    dataIndex="playerName"
                    width="100%"
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 1"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[0]) {
                        return (
                          <>
                            {item.slots[0].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[0].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 2"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[1]) {
                        return (
                          <>
                            {item.slots[1].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[1].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 3"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[2]) {
                        return (
                          <>
                            {item.slots[2].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[2].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={
                      intl.formatMessage({
                        defaultMessage: "Slot",
                      }) + " 4"
                    }
                    render={(_, item: TJackpotsReportAggregatedItem) => {
                      if ("slots" in item && item.slots[3]) {
                        return (
                          <>
                            {item.slots[3].count}
                            {" / "}
                            <MoneyFormatter cents={item.slots[3].amount} />
                          </>
                        );
                      }
                      return "—";
                    }}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Amount",
                    })}
                    render={(_, item: TJackpotsReportAggregatedItem) => (
                      <MoneyFormatter cents={item.amount} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Count",
                    })}
                    render={(_, item: TJackpotsReportAggregatedItem) =>
                      "count" in item ? item.count : "—"
                    }
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.aggregateByPlayerQuery}
                  onChange={() => {
                    state.aggregateByPlayer({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Space>
        </Card>
      </Spin>
    </Page>
  );
}

export default observer(JackpotsReport);
