import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { UpdateClient } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditClientTabs } from "../tabs";

export const Modal = observer(() => {
  const intl = useIntl();
  const [clientName, setClientName] = useState<string>();
  return (
    <EventBasedModal
      title={
        clientName ? (
          <>
            {intl.formatMessage({ defaultMessage: "Edit client" })}:{" "}
            <Typography.Text type="secondary">{clientName}</Typography.Text>
          </>
        ) : (
          intl.formatMessage({ defaultMessage: "Edit client" })
        )
      }
      openEvent={UpdateClient}
      className="modal_with-tabs"
      onClose={() => setClientName(undefined)}
    >
      {({ clientId }) => (
        <EditClientTabs clientId={clientId} setClientName={setClientName} />
      )}
    </EventBasedModal>
  );
});
