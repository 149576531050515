import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { PlayersImported, OpenImportPlayersModal } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { ImportPlayersForm } from "./form";

export const ImportPlayersModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Import players" })}
      openEvent={OpenImportPlayersModal}
      closeEvent={PlayersImported}
    >
      {(props) => <ImportPlayersForm {...props} />}
    </EventBasedModal>
  );
});
