import {
  DollarOutlined,
  LockOutlined,
  LogoutOutlined,
  ReloadOutlined,
  SettingOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Popconfirm,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  StartShift,
  FinishShift,
  DepositCashRegister,
  WithdrawCashRegister,
} from "@/events";
import { useGlobalStore } from "@/stores";
import MoneyFormatter from "@/ui/_common_/money-formatter";

import { useAgentId, useBalance, useClientId, useHallId } from "./hooks";
import UserItem from "./user-item";
import UserUpdateModal from "./user-update-modal";

function UserInfo() {
  const intl = useIntl();
  const _ = intl.formatMessage;
  const { eventBusService, userStore } = useGlobalStore();
  const { user, client, agent, hall, cashier, shift } = userStore;
  const clientId = useClientId(client, agent, hall);
  const agentId = useAgentId(agent);
  const hallId = useHallId(user);
  const balance = useBalance(agent, hall);
  const isRefreshButtonShown = useMemo(() => {
    if (
      balance?.isUnlimited === false ||
      (cashier?.transactionsLimit ?? 0) > 0 ||
      cashier?.isShiftModeEnabled
    ) {
      return true;
    }
    return false;
  }, [
    balance?.isUnlimited,
    cashier?.isShiftModeEnabled,
    cashier?.transactionsLimit,
  ]);
  const [isShiftPopoverOpen, setIsShiftPopoverOpen] = useState(false);
  return (
    <>
      <UserUpdateModal userUpdateStore={userStore.userUpdateStore} />
      <Row style={{ padding: "16px 16px 10px 16px" }}>
        <Row align="middle" style={{ width: "100%" }} wrap={false}>
          <Col style={{ overflow: "hidden" }}>
            <Typography.Text
              strong
              style={{ margin: 0, width: "100%" }}
              ellipsis={true}
            >
              {user.login}
            </Typography.Text>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Row gutter={[8, 8]} wrap={false}>
              {userStore.user.role !== "Admin" &&
                userStore.user.role !== "Manager" && (
                  <Col>
                    <Button
                      icon={<SettingOutlined />}
                      size="small"
                      onClick={userStore.userUpdateStore.modal.open}
                    />
                  </Col>
                )}
              <Col>
                <Popconfirm
                  title={intl.formatMessage({
                    defaultMessage:
                      "The shift is not closed. Do you want to close it?",
                  })}
                  okText={intl.formatMessage({ defaultMessage: "Yes" })}
                  onConfirm={() => {
                    eventBusService.publish(
                      new FinishShift({ cashierId: cashier!.id }),
                    );
                  }}
                  cancelText={intl.formatMessage({ defaultMessage: "No" })}
                  onCancel={userStore.logOut}
                  disabled={!cashier?.isShiftStarted}
                >
                  <Button
                    icon={<LogoutOutlined />}
                    size="small"
                    onClick={
                      !cashier?.isShiftStarted ? userStore.logOut : undefined
                    }
                  />
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
        {(clientId || agentId || hallId) && (
          <UserItem
            label={
              [
                !!clientId && intl.formatMessage({ defaultMessage: "Client" }),
                !!agentId && intl.formatMessage({ defaultMessage: "Agent" }),
                !!hallId && intl.formatMessage({ defaultMessage: "Shop" }),
              ]
                .filter(Boolean)
                .join(" / ") +
              " " +
              intl.formatMessage({ defaultMessage: "ID" })
            }
            value={[
              [clientId, agentId, hallId].filter(Boolean).join(" / "),
              userStore.clientMode === "ss_single"
                ? intl.formatMessage({ defaultMessage: "SB" })
                : "",
              userStore.clientMode === "ss_dual"
                ? intl.formatMessage({ defaultMessage: "SS" })
                : "",
            ]
              .filter(Boolean)
              .join(" ")}
          />
        )}
        <UserItem
          label={intl.formatMessage({ defaultMessage: "Role" })}
          value={_({ id: `role/${user.role}` })}
        />
        {hall && (
          <UserItem
            label={intl.formatMessage({ defaultMessage: "Currency" })}
            value={hall.currency}
          />
        )}
        {balance && (
          <UserItem
            label={intl.formatMessage({ defaultMessage: "Balance" })}
            value={
              <Space direction="horizontal" style={{ columnGap: 4 }}>
                <MoneyFormatter
                  cents={balance.value}
                  isInfinity={balance.isUnlimited}
                />
              </Space>
            }
          />
        )}
        {!!cashier?.transactionsLimit && hall && (
          <UserItem
            label={intl.formatMessage({
              defaultMessage: "Limit",
            })}
            value={
              <Space direction="horizontal" style={{ columnGap: 4 }}>
                <MoneyFormatter cents={cashier.transactionsSum} />
                {"/"}
                <MoneyFormatter
                  isInfinity={!cashier.transactionsLimit}
                  cents={cashier.transactionsLimit}
                />
              </Space>
            }
          />
        )}
        {!!cashier?.isShiftModeEnabled &&
          (shift ? (
            <>
              <UserItem
                label={intl.formatMessage({
                  defaultMessage: "Shift opened at",
                })}
                value={
                  <Tooltip overlay={new Date(shift.startedAt).toLocaleString()}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        eventBusService.publish(
                          new FinishShift({ cashierId: cashier.id }),
                        );
                      }}
                    >
                      {new Date(shift.startedAt).toLocaleTimeString()}{" "}
                      <UnlockOutlined />
                    </div>
                  </Tooltip>
                }
              />
              <UserItem
                label={intl.formatMessage({
                  defaultMessage: "Cash register",
                })}
                value={
                  <Popover
                    trigger="click"
                    content={
                      <Row gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            onClick={() => {
                              setIsShiftPopoverOpen(false);
                              eventBusService.publish(
                                new DepositCashRegister({
                                  cashierId: cashier.id,
                                }),
                              );
                            }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Insert cash",
                            })}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            onClick={() => {
                              setIsShiftPopoverOpen(false);
                              eventBusService.publish(
                                new WithdrawCashRegister({
                                  cashierId: cashier.id,
                                }),
                              );
                            }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Extract cash",
                            })}
                          </Button>
                        </Col>
                      </Row>
                    }
                    onOpenChange={setIsShiftPopoverOpen}
                    open={isShiftPopoverOpen}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsShiftPopoverOpen(true)}
                    >
                      <MoneyFormatter cents={shift.cash} /> <DollarOutlined />
                    </div>
                  </Popover>
                }
              />
            </>
          ) : (
            <UserItem
              label={intl.formatMessage({ defaultMessage: "Shift" })}
              value={
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    eventBusService.publish(
                      new StartShift({ cashierId: cashier.id }),
                    );
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "closed" })}{" "}
                  <LockOutlined />
                </div>
              }
            />
          ))}
        {isRefreshButtonShown && (
          <Row
            align="middle"
            justify="end"
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Button
              loading={
                userStore.userQuery.isPending ||
                userStore.agentQuery.isPending ||
                userStore.hallQuery.isPending
              }
              icon={<ReloadOutlined />}
              size="small"
              onClick={userStore.fetchInBackground}
            >
              {intl.formatMessage({ defaultMessage: "Refresh" })}
            </Button>
          </Row>
        )}
      </Row>
    </>
  );
}

export default observer(UserInfo);
