import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import { has, omit } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, DeepMap, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { AssertsShape } from "yup/lib/object";

import { TJackpot, TJackpotSlotTemplate } from "@/api";
import { useJackpotUpdateSchema, TJackpotUpdateSchema } from "@/schemas";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import FormItem from "@/ui/_common_/form-item";
import { InfoIcon } from "@/ui/_common_/icons";
import LabelWithTooltip from "@/ui/_common_/label-with-tooltip";
import { CurrencyUtilities } from "@/utilities";

import { JackpotUpdateStore } from "./jackpot-update-store";

export type TEntity =
  | {
      clientId: number;
    }
  | {
      agentId: number;
    }
  | {
      hallId: number;
    };

type TProps = {
  readOnly?: boolean;
  entity: TEntity;
  jackpot: TJackpot;
  onRefresh: () => void;
};

function JackpotUpdateForm({
  readOnly = false,
  entity,
  jackpot,
  onRefresh,
}: TProps) {
  const { intlService } = useGlobalStore();
  const store = useMemo(
    () => new JackpotUpdateStore(entity, jackpot, onRefresh, intlService),
    [entity, jackpot, onRefresh, intlService],
  );

  useEffect(() => {
    if (!readOnly) {
      store.fetchTemplates();
    }
  }, [readOnly, store]);
  const schema = useJackpotUpdateSchema();
  const resolver = yupResolver(schema);
  const form = useForm<TJackpotUpdateSchema>({
    defaultValues: {
      ...omit(jackpot, "isSyncButtonEnabled"),
      expectedMonthlyBetSum: CurrencyUtilities.toMainUnits(
        jackpot.expectedMonthlyBetSum,
      ),
      slots: jackpot.slots.map((slot) => ({
        ...omit(slot, "value"),
        minValue: CurrencyUtilities.toMainUnits(slot.minValue),
        maxValue: CurrencyUtilities.toMainUnits(slot.maxValue),
        defaultValue: CurrencyUtilities.toMainUnits(slot.defaultValue),
        value: CurrencyUtilities.toMainUnits(slot.value),
      })),
    },
    resolver,
  });

  const handleJackpotTemplateIdSelect = useCallback(
    (jackpotTemplateId) => {
      const jackpotTemplate = store.jackpotTemplates.find(
        (jt) => jt.id === jackpotTemplateId,
      );
      const jackpotSlotTemplates = store.jackpotSlotTemplates.filter(
        (jst) => jst.jackpotTemplateId === jackpotTemplateId,
      );
      if (jackpotTemplate === undefined) {
        return;
      }
      if (jackpotTemplate.isEnabled !== null) {
        form.setValue("isEnabled", jackpotTemplate.isEnabled);
      }
      if (jackpotTemplate.isPayoutEnabled !== null) {
        form.setValue("isPayoutEnabled", jackpotTemplate.isPayoutEnabled);
      }
      if (jackpotTemplate.expectedMonthlyBetSum !== null) {
        form.setValue(
          "expectedMonthlyBetSum",
          jackpotTemplate.expectedMonthlyBetSum,
        );
      }
      jackpotSlotTemplates.forEach((jackpotSlotTemplate) => {
        const keys = Object.keys(jackpotSlotTemplate) as Array<
          keyof TJackpotSlotTemplate
        >;
        keys.forEach((key) => {
          if (
            key === "id" ||
            key === "jackpotTemplateId" ||
            key === "jackpotTemplateName"
          ) {
            return;
          }
          if (!jackpotSlotTemplate[key]) {
            return;
          }
          form.setValue(
            `slots.${jackpotSlotTemplate.id}.${key}`,
            jackpotSlotTemplate[key] as any,
          );
        });
      });
    },
    [store.jackpotTemplates, store.jackpotSlotTemplates, form],
  );

  const intl = useIntl();

  const values = form.watch();
  const errors = form.formState.errors;

  const isPayoutWarningVisible = useMemo(() => {
    return (
      !values.isPayoutEnabled &&
      values.slots?.some(({ id, maxValue }) => {
        const value = jackpot.slots.find((s) => s.id === id)?.value;
        if (value === undefined) {
          return false;
        }
        return CurrencyUtilities.toMainUnits(value) > maxValue;
      })
    );
  }, [jackpot, values]);

  const isCooldownWarningVisibile = useMemo(() => {
    return values.slots?.some(
      (slot) => slot.isCommunity && !+slot.communityCooldown,
    );
  }, [values]);

  return (
    <Spin
      spinning={
        store.jackpotTemplatesQuery.isPending ||
        store.jackpotSlotTemplatesQuery.isPending ||
        store.updateQuery.isPending ||
        store.syncQuery.isPending
      }
    >
      {!readOnly && (
        <>
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                store.jackpotTemplatesQuery,
                store.jackpotSlotTemplatesQuery,
              ]}
            />
            <Form labelCol={{ span: 10 }} labelAlign="left">
              <Form.Item
                label={intl.formatMessage({
                  defaultMessage: "Jackpot template",
                })}
              >
                <Select
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select jackpot template",
                  })}
                  options={store.jackpotTemplates.map((jackpotTemplate) => ({
                    label: jackpotTemplate.name,
                    value: jackpotTemplate.id,
                  }))}
                  onChange={handleJackpotTemplateIdSelect}
                  disabled={readOnly}
                />
              </Form.Item>
            </Form>
          </Space>
          <Divider />
        </>
      )}
      <Space direction="vertical">
        <ErrorsFormatter queries={[store.updateQuery, store.syncQuery]} />
        <Form
          labelCol={{ span: 10 }}
          labelAlign="left"
          onFinish={form.handleSubmit(store.update)}
        >
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({ defaultMessage: "Enabled" })}
                tooltip={intl.formatMessage({
                  defaultMessage: "on = Display Jackpots in the lobby",
                })}
              />
            }
            error={errors.isEnabled}
          >
            <Controller
              control={form.control}
              name="isEnabled"
              render={({ field }) => (
                <Switch {...field} checked={field.value} disabled={readOnly} />
              )}
            />
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({ defaultMessage: "Payout enabled" })}
                tooltip={intl.formatMessage({
                  defaultMessage:
                    "off = jackpots are accumulated, but not paid to players",
                })}
              />
            }
            error={errors.isPayoutEnabled}
          >
            <Controller
              control={form.control}
              name="isPayoutEnabled"
              render={({ field }) => (
                <Switch {...field} checked={field.value} disabled={readOnly} />
              )}
            />
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({
                  defaultMessage: "Expected Σ BET value",
                })}
                tooltip={intl.formatMessage({
                  defaultMessage:
                    "expected value of sum bets (only bets, not GGR or so) for month",
                })}
              />
            }
            error={errors.expectedMonthlyBetSum}
          >
            <Controller
              control={form.control}
              name="expectedMonthlyBetSum"
              render={({ field }) => <Input {...field} disabled={readOnly} />}
            />
          </FormItem>
          <Form.Item label={<></>} colon={false}>
            <Row
              gutter={[8, 8]}
              justify="center"
              style={{ whiteSpace: "nowrap" }}
            >
              <Col span={6} className="slot">
                {intl.formatMessage({ defaultMessage: "Slot 1" })}
                <br />
                {intl.formatMessage({ defaultMessage: "(mini)" })}
              </Col>
              <Col span={6} className="slot">
                {intl.formatMessage({ defaultMessage: "Slot 2" })}
                <br />
                {intl.formatMessage({ defaultMessage: "(major)" })}
              </Col>
              <Col span={6} className="slot">
                {intl.formatMessage({ defaultMessage: "Slot 3" })}
                <br />
                {intl.formatMessage({ defaultMessage: "(grand)" })}
              </Col>
              <Col span={6} className="slot">
                {intl.formatMessage({ defaultMessage: "Slot 4" })}
                <br />
                {intl.formatMessage({ defaultMessage: "(ultimate)" })}
              </Col>
            </Row>
          </Form.Item>
          {"hallId" in entity && (
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Current value" })}
            >
              <Space direction="vertical">
                <Space>
                  {jackpot.slots.map((slot) => (
                    <Input
                      key={slot.id}
                      disabled
                      readOnly={true}
                      value={CurrencyUtilities.toMainUnits(slot.value)}
                      style={{ textAlign: "right" }}
                    />
                  ))}
                </Space>
                {isPayoutWarningVisible && (
                  <Alert
                    type="warning"
                    showIcon
                    icon={<InfoIcon style={{ width: 20 }} />}
                    message={intl.formatMessage(
                      {
                        defaultMessage:
                          "Attention! Jackpot payout is disabled.{br}In order for the jackpots to be triggered, you need to enable the payout.",
                      },
                      { br: <br /> },
                    )}
                  />
                )}
              </Space>
            </Form.Item>
          )}
          {"hallId" in entity && !readOnly && (
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Reset value" })}
            >
              <Row gutter={8} wrap={false}>
                {jackpot.slots.map((slot) => (
                  <Col key={slot.id} flex={1}>
                    <Button
                      key={slot.id}
                      onClick={() => store.reset([slot.id])}
                      disabled={readOnly}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      {intl.formatMessage({ defaultMessage: "Reset" })}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Form.Item>
          )}
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({ defaultMessage: "Min. value" })}
                tooltip={intl.formatMessage({
                  defaultMessage: "minimum jackpot value",
                })}
              />
            }
            error={extractSlotErrors(errors, "minValue")}
          >
            <Space>
              {jackpot.slots.map((slot) => (
                <Form.Item noStyle key={slot.id}>
                  <Controller
                    control={form.control}
                    name={`slots.${slot.id}.minValue`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        style={{ textAlign: "right" }}
                        disabled={readOnly}
                      />
                    )}
                  />
                </Form.Item>
              ))}
            </Space>
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({ defaultMessage: "Max. value" })}
                tooltip={intl.formatMessage({
                  defaultMessage: "maximum jackpot value",
                })}
              />
            }
            error={extractSlotErrors(errors, "maxValue")}
          >
            <Space>
              {jackpot.slots.map((slot) => (
                <Form.Item noStyle key={slot.id} validateStatus="error">
                  <Controller
                    control={form.control}
                    name={`slots.${slot.id}.maxValue`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        style={{ textAlign: "right" }}
                        disabled={readOnly}
                      />
                    )}
                  />
                </Form.Item>
              ))}
            </Space>
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({ defaultMessage: "Reset value" })}
                tooltip={intl.formatMessage({
                  defaultMessage:
                    "is the value, from which JP will start grow after it has been won",
                })}
              />
            }
            error={extractSlotErrors(errors, "defaultValue")}
          >
            <Space>
              {jackpot.slots.map((slot) => (
                <Form.Item noStyle key={slot.id}>
                  <Controller
                    control={form.control}
                    name={`slots.${slot.id}.defaultValue`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        style={{ textAlign: "right" }}
                        disabled={readOnly}
                      />
                    )}
                  />
                </Form.Item>
              ))}
            </Space>
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({
                  defaultMessage: "Community jackpot",
                })}
                tooltip={intl.formatMessage({
                  defaultMessage:
                    "on = every active player in the shop wins part of the jackpot",
                })}
              />
            }
            error={extractSlotErrors(errors, "isCommunity")}
          >
            <Row>
              {jackpot.slots.map((slot) => (
                <Col
                  key={slot.id}
                  flex={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Controller
                    control={form.control}
                    name={`slots.${slot.id}.isCommunity`}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        disabled={readOnly}
                      />
                    )}
                  />
                </Col>
              ))}
            </Row>
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({
                  defaultMessage: "Community cooldown",
                })}
                tooltip={intl.formatMessage({
                  defaultMessage:
                    "time interval (milliseconds) between the bonus activation is available",
                })}
              />
            }
            error={extractSlotErrors(errors, "communityCooldown")}
          >
            <Space direction="vertical">
              <Space>
                {jackpot.slots.map((slot, index) => (
                  <Form.Item
                    key={slot.id}
                    validateStatus={
                      values.slots?.[index].isCommunity &&
                      !+values.slots?.[index].communityCooldown
                        ? "error"
                        : undefined
                    }
                  >
                    <Controller
                      control={form.control}
                      name={`slots.${slot.id}.communityCooldown`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ textAlign: "right" }}
                          disabled={readOnly}
                        />
                      )}
                    />
                  </Form.Item>
                ))}
              </Space>
              {isCooldownWarningVisibile && (
                <Alert
                  type="warning"
                  showIcon
                  icon={<InfoIcon style={{ width: 20 }} />}
                  message={intl.formatMessage(
                    {
                      defaultMessage:
                        "Attention! It is necessary to set the Cooldown value in milliseconds.{br}If the value is zero, the jackpot may not work correctly.",
                    },
                    { br: <br /> },
                  )}
                />
              )}
            </Space>
          </FormItem>
          <FormItem
            label={
              <LabelWithTooltip
                label={intl.formatMessage({
                  defaultMessage: "Expected Number of Monthly Pieces",
                })}
                tooltip={intl.formatMessage({
                  defaultMessage:
                    "number of jackpots to be won over the course of one month",
                })}
              />
            }
            error={extractSlotErrors(errors, "expectedMonthlyWinsCount")}
          >
            <Space>
              {jackpot.slots.map((slot) => (
                <Form.Item noStyle key={slot.id}>
                  <Controller
                    control={form.control}
                    name={`slots.${slot.id}.expectedMonthlyWinsCount`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        style={{ textAlign: "right" }}
                        disabled={readOnly}
                      />
                    )}
                  />
                </Form.Item>
              ))}
            </Space>
          </FormItem>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Total wins" })}
          >
            <Space>
              {jackpot.slots.map((slot) => (
                <Form.Item noStyle key={slot.id}>
                  <Input
                    disabled={true}
                    readOnly={true}
                    value={calculateTotalWin(values, slot.id)}
                    style={{ textAlign: "right" }}
                  />
                </Form.Item>
              ))}
            </Space>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Total win Σ" })}
          >
            <Input
              disabled={true}
              readOnly={true}
              value={calculateTotalWinSum(values)}
              style={{ textAlign: "right" }}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Average (theoretical) percentage",
            })}
          >
            <Input
              disabled={true}
              readOnly={true}
              value={calculateExpectedAveragePercentage(values).toFixed(4)}
              addonAfter="%"
              style={{ textAlign: "right" }}
            />
          </Form.Item>
          {jackpot.isSyncButtonEnabled && (
            <Alert
              type="warning"
              showIcon
              description={intl.formatMessage(
                {
                  defaultMessage:
                    'Synchronization is required.{br}Jackpot settings have been changed.{br}Without synchronization, jackpots may not work as expected.{br}After completing the settings, be sure to synchronize the jackpots.{br}Click the "Sync" button to synchronize the jackpots.',
                },
                { br: <br /> },
              )}
            />
          )}
          {!readOnly && (
            <Row justify="end" gutter={[8, 8]}>
              {"hallId" in entity && (
                <>
                  {onRefresh && (
                    <Col>
                      <Button onClick={onRefresh}>
                        {intl.formatMessage({
                          defaultMessage: "Show current JP value",
                        })}
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      onClick={store.resetAll}
                      loading={store.resetQuery.isPending}
                    >
                      {intl.formatMessage({ defaultMessage: "Reset all" })}
                    </Button>
                  </Col>
                </>
              )}
              {jackpot.isSyncButtonEnabled && (
                <Col>
                  <Button
                    size="middle"
                    type="primary"
                    onClick={store.sync}
                    loading={store.syncQuery.isPending}
                    danger
                  >
                    {intl.formatMessage({ defaultMessage: "Sync" })}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={store.updateQuery.isPending}
                >
                  {intl.formatMessage({ defaultMessage: "Save" })}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </Space>
    </Spin>
  );
}

type ExtractAssertsShape<T> = T extends AssertsShape<infer X> ? X : never;
function extractSlotErrors(
  errors: DeepMap<AssertsShape<any>, any> | undefined,
  key: keyof ExtractAssertsShape<Unpacked<TJackpotUpdateSchema["slots"]>>,
) {
  const error = errors?.slots?.find((slot: any) => has(slot, key));
  return error?.[key];
}

function calculateTotalWin(values: TJackpotUpdateSchema, slotId: number) {
  const slot = values.slots?.[slotId];
  const a = +(slot?.minValue ?? 0);
  const b = +(slot?.maxValue ?? 0);
  const c = +(slot?.expectedMonthlyWinsCount ?? 0);
  const result = ((a + b) / 2) * c;
  if (!Number.isFinite(result) || Number.isNaN(result)) {
    return 0;
  }
  return result;
}

function calculateTotalWinSum(values: TJackpotUpdateSchema) {
  const result = (values.slots ?? [])
    .map((value, id) => calculateTotalWin(values, id))
    .reduce((a, b) => a + b, 0);
  if (!Number.isFinite(result) || Number.isNaN(result)) {
    return 0;
  }
  return result;
}

function calculateExpectedAveragePercentage(values: TJackpotUpdateSchema) {
  const G = (values.slots ?? [])
    .map((value, id) => {
      const d = calculateTotalWin(values, id);
      const e = +value.defaultValue;
      const c = +value.expectedMonthlyWinsCount;
      const g = d - e * c;
      return g;
    })
    .reduce((a, b) => a + b, 0);
  const f = +(values.expectedMonthlyBetSum ?? 0);
  const j = 1;
  const result = (G / f) * j * 100;
  if (!Number.isFinite(result) || Number.isNaN(result)) {
    return 0;
  }
  return result;
}

export default observer(JackpotUpdateForm);
