import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI } from "@/api";
import Query from "@/models/query";

import { AgentOtherSettingsProps } from "./agent-other-settings";
import {
  AgentOtherSettingsSchema,
  useAgentOtherSettingsSchema,
} from "./agent-other-settings.schema";

export function useAgentOtherSettingsState({
  agentId,
}: AgentOtherSettingsProps) {
  const intl = useIntl();

  const schema = useAgentOtherSettingsSchema();
  const form = useForm<AgentOtherSettingsSchema>({
    resolver: yupResolver(schema),
  });
  const formValues = form.watch();

  const agentQuery = useMemo(() => {
    const agentQuery = new Query(AgentAPI.getById);
    agentQuery.submit({ id: agentId }).then(() => {
      const agent = agentQuery.data!;
      const values = schema.cast(agent, { stripUnknown: true });
      form.reset(values);
    });
    return agentQuery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuery = useMemo(() => {
    return new Query(async (values: AgentOtherSettingsSchema) => {
      await AgentAPI.update({ id: agentId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage:
            "The agent's other settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    agentId,
    intl,
    form,
    formValues,
    agentQuery,
    submitQuery,
  };
}

export type AgentOtherSettingsState = ReturnType<
  typeof useAgentOtherSettingsState
>;
