import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { CreateCashier, CashierCreated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { CashierCreateForm } from "./cashier-create-form";

export const CashierCreateModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      openEvent={CreateCashier}
      closeEvent={CashierCreated}
      title={intl.formatMessage({ defaultMessage: "New cashier" })}
    >
      {({ hallId }) => <CashierCreateForm hallId={hallId} />}
    </EventBasedModal>
  );
});
