import { Button, Col, Form, Row, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem, FormSelect } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { EditWebsiteDomainFormState } from "./form.state";

type Props = {
  state: EditWebsiteDomainFormState;
};

export const EditWebsiteDomainFormView = observer<Props>(({ state }) => {
  const {
    intl,
    query,
    imagePacksQuery,
    templatesQuery,
    handleSubmit,
    form,
    mutation,
  } = state;

  if (
    query.isIdle ||
    query.isPending ||
    imagePacksQuery.isIdle ||
    imagePacksQuery.isPending ||
    templatesQuery.isIdle ||
    templatesQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (
    query.isRejected ||
    imagePacksQuery.isRejected ||
    templatesQuery.isRejected
  ) {
    return (
      <ErrorsFormatter queries={[query, imagePacksQuery, templatesQuery]} />
    );
  }

  const domain = query.data;
  const imagePacks = imagePacksQuery.data.data;
  const templates = templatesQuery.data.data;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[mutation]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "ID" })}>
          <Typography.Text>{domain.id}</Typography.Text>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Domain" })}>
          <Typography.Text>{domain.domain}</Typography.Text>
        </Form.Item>
        <FormItem
          form={form}
          path="title"
          label={intl.formatMessage({ defaultMessage: "Title" })}
        >
          <FormInput
            form={form}
            path="title"
            placeholder={intl.formatMessage({ defaultMessage: "Enter title" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="imgPackId"
          label={intl.formatMessage({ defaultMessage: "Image pack" })}
        >
          <FormSelect
            form={form}
            path="imgPackId"
            placeholder={intl.formatMessage({
              defaultMessage: "Select image pack",
            })}
            options={imagePacks.map((pack) => ({
              value: pack.id,
              label: pack.name,
            }))}
          />
        </FormItem>
        <FormItem
          form={form}
          path="templateId"
          label={intl.formatMessage({ defaultMessage: "Template" })}
        >
          <FormSelect
            form={form}
            path="templateId"
            placeholder={intl.formatMessage({
              defaultMessage: "Select template",
            })}
            options={templates.map((template) => ({
              value: template.id,
              label: template.name,
            }))}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
