import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SportPartnerAPI } from "@/api";
import { CreateClub } from "@/events";
import { SelectorStore, useGlobalStore } from "@/stores";

export function useSportClubsPageState() {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const [searchParams, setSearchParams] = useSearchParams();

  const partnerId = useMemo(() => {
    const partnerId = searchParams.get("pid");
    return partnerId ? Number.parseInt(partnerId, 10) : undefined;
  }, [searchParams]);

  const partnerStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: SportPartnerAPI.filter,
      getByIdMethod: SportPartnerAPI.getById,
      labelKey: "description",
      isPaginationEnabled: true,
      onSelect(item) {
        if (item) {
          searchParams.set("pid", item.id.toString());
        } else {
          searchParams.delete("pid");
        }
        setSearchParams(searchParams);
      },
    });
    store.setSelectedId(partnerId);
    store.setParameters({});
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams]);

  function openNewClubModal() {
    if (!partnerId) {
      return;
    }
    eventBusService.publish(new CreateClub({ partnerId }));
  }

  const navigate = useNavigate();

  function goBack() {
    navigate("/handbook/sport-partners", { replace: true });
  }

  return { intl, openNewClubModal, partnerStore, partnerId, goBack };
}

export type SportClubsPageState = ReturnType<typeof useSportClubsPageState>;
