import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { HallAPI } from "@/api";
import Query from "@/models/query";

import { KiosksSettingsFormProps } from "./form";
import {
  KiosksSettingsFormSchema,
  useKiosksSettingsFormSchema,
} from "./form.schema";

export function useKiosksSettingsFormState({
  hallId,
}: KiosksSettingsFormProps) {
  const intl = useIntl();

  const schema = useKiosksSettingsFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<KiosksSettingsFormSchema>({ resolver });

  const mutation = useMemo(
    () =>
      new Query(async (values: KiosksSettingsFormSchema) => {
        await HallAPI.setSettings({ id: hallId, ...values });

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "The settings have been successfully saved.",
          }),
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit(mutation.submit);

  const query = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await HallAPI.getSettings({ id: hallId });
    });

    query.submit().then(() => {
      if (query.data!.lobbyThemeStringId === null) {
        form.resetField("lobbyThemeStringId");
      } else {
        form.setValue("lobbyThemeStringId", query.data!.lobbyThemeStringId);
      }

      if (query.data!.frontendThemeStringId === null) {
        form.resetField("frontendThemeStringId");
      } else {
        form.setValue(
          "frontendThemeStringId",
          query.data!.frontendThemeStringId,
        );
      }
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeOptions =
    query.data?.themesEnum.map((theme) => ({
      label: theme,
      value: theme,
    })) ?? [];

  const frontendThemeOptions =
    query.data?.themesV3Enum.map((theme) => ({
      label: theme,
      value: theme,
    })) ?? [];

  return {
    intl,
    form,
    handleSubmit,
    query,
    mutation,
    themeOptions,
    frontendThemeOptions,
  };
}

export type KiosksSettingsFormState = ReturnType<
  typeof useKiosksSettingsFormState
>;
