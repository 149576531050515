import { observer } from "mobx-react-lite";

import { useSportSettingsState } from "./form.state";
import { SportSettingsView } from "./form.view";

export type SportSettingsProps = {
  entity: { agentId: number } | { hallId: number };
  currency?: string;
};

export const SportSettings = observer<SportSettingsProps>((props) => {
  const state = useSportSettingsState(props);
  return <SportSettingsView state={state} />;
});
