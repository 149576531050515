import { observer } from "mobx-react-lite";

import { CreatePartner, PartnerCreated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { NewPartnerForm } from "./form";

export const NewPartnerModal = observer(() => {
  return (
    <EventBasedModal
      title="New partner"
      openEvent={CreatePartner}
      closeEvent={PartnerCreated}
    >
      {() => <NewPartnerForm />}
    </EventBasedModal>
  );
});
