import { makeAutoObservable } from "mobx";

import { TMethod } from "@/api";
import { IS_VERBOSE } from "@/constants";
import { Logger } from "@/utilities";

enum STATE {
  IDLE = "idle",
  PENDING = "pending",
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

type TBaseQuery<I, O> = {
  submit: (request: I) => Promise<void>;

  abort: () => void;

  reset: () => void;
  resolve: (request: I, data: O) => void;
  reject: (request: I, error: Error) => void;
};

type TIdleQuery<I, O> = TBaseQuery<I, O> & {
  request: undefined;

  state: STATE.IDLE;
  data: undefined;
  error: undefined;

  isIdle: true;
  isPending: false;
  isFulfilled: false;
  isRejected: false;
};

type TPendingQuery<I, O> = TBaseQuery<I, O> & {
  request: I;

  state: STATE.PENDING;
  data?: O;
  error: undefined;

  isIdle: false;
  isPending: true;
  isFulfilled: false;
  isRejected: false;
};

type TFulfilledQuery<I, O> = TBaseQuery<I, O> & {
  request: I;

  state: STATE.FULFILLED;
  data: O;
  error: undefined;

  isIdle: false;
  isPending: false;
  isFulfilled: true;
  isRejected: false;
};

type TRejectedQuery<I, O> = TBaseQuery<I, O> & {
  request: I;

  state: STATE.REJECTED;
  data?: O;
  error: Error;

  isIdle: false;
  isPending: false;
  isFulfilled: false;
  isRejected: true;
};

type Query<I = any, O = any> =
  | TIdleQuery<I, O>
  | TPendingQuery<I, O>
  | TFulfilledQuery<I, O>
  | TRejectedQuery<I, O>;

class QueryImplementation<I, O> {
  constructor(private method: TMethod<I, O>) {
    makeAutoObservable(this);
  }

  private _request?: I;

  get request() {
    return this._request;
  }

  private _state = STATE.IDLE;

  get state() {
    return this._state;
  }

  private _data?: O;

  get data() {
    return this._data;
  }

  private _error?: Error;

  get error() {
    window.clearTimeout(this._errorTimeout);
    return this._error;
  }

  private set error(value: Error | undefined) {
    this._error = value;

    if (value && IS_VERBOSE) {
      this._errorTimeout = window.setTimeout(() => {
        const error = new Error(
          "The request failed, but the error is not used after 5 seconds.",
        );
        Logger.error(error);
      }, 5000);
    }
  }

  private _requestId = 0;
  private _abortedRequestId = 0;
  private _errorTimeout = 0;

  get isIdle() {
    return this._state === STATE.IDLE;
  }

  get isPending() {
    return this._state === STATE.PENDING;
  }

  get isFulfilled() {
    return this._state === STATE.FULFILLED;
  }

  get isRejected() {
    window.clearTimeout(this._errorTimeout);
    return this._state === STATE.REJECTED;
  }

  submit = async (request: I) => {
    this.abort();

    this._request = request;
    this._state = STATE.PENDING;
    this.error = undefined;

    const requestId = ++this._requestId;
    await this.method(request).then(
      this.handleQueryFulfilled.bind(this, requestId),
      this.handleQueryRejected.bind(this, requestId),
    );
  };

  abort = () => {
    this._abortedRequestId = this._requestId;

    if (this.error) {
      this._state = STATE.REJECTED;
    } else if (this.data) {
      this._state = STATE.FULFILLED;
    } else {
      this._state = STATE.IDLE;
    }
  };

  reset = () => {
    this.abort();

    this._request = undefined;
    this._state = STATE.IDLE;
    this._data = undefined;
    this.error = undefined;
  };

  resolve = (request: I, data: O) => {
    this.abort();

    this._request = request;
    this._state = STATE.FULFILLED;
    this._data = data;
    this.error = undefined;
  };

  reject = (request: I, error: Error) => {
    this.abort();

    this._request = request;
    this._state = STATE.REJECTED;
    this.error = error;
  };

  private handleQueryFulfilled = (requestId: number, value: O) => {
    if (this._abortedRequestId >= requestId) {
      return;
    }

    this._state = STATE.FULFILLED;
    this._data = value;
    this.error = undefined;
  };

  private handleQueryRejected = (requestId: number, error: Error) => {
    if (this._abortedRequestId >= requestId) {
      return;
    }

    this._state = STATE.REJECTED;
    this.error = error;
  };
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Query = QueryImplementation as {
  new <I, O>(method: TMethod<I, O>): Query<I, O>;
};

export default Query;
