import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { UpdatePlayer, PlayerUpdated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { PlayerUpdateForm } from "./player-update-form";

export const PlayerUpdateModal = observer(() => {
  const intl = useIntl();
  const [playerName, setPlayerName] = useState<string>();
  return (
    <EventBasedModal
      openEvent={UpdatePlayer}
      closeEvent={PlayerUpdated}
      title={
        playerName ? (
          <>
            {intl.formatMessage({ defaultMessage: "Edit player" })}:{" "}
            <Typography.Text type="secondary">{playerName}</Typography.Text>
          </>
        ) : (
          <>{intl.formatMessage({ defaultMessage: "Edit player" })}</>
        )
      }
      onClose={() => setPlayerName(undefined)}
    >
      {({ playerId }) => (
        <PlayerUpdateForm playerId={playerId} setPlayerName={setPlayerName} />
      )}
    </EventBasedModal>
  );
});
