import * as jp from "jsonpath";

import { CurrencyUtilities } from "./currency_utilities";

type Key = string | number | boolean;

type KeyOf<T> = Extract<keyof T, Key>;

type PathsInternal<T, Path extends string = ""> = T extends Record<
  string,
  unknown
>
  ? {
      [K in KeyOf<T>]: PathsInternal<
        T[K],
        Path extends "" ? K : `${Path}.${K}`
      >;
    }[KeyOf<T>]
  : T extends Array<unknown>
  ? PathsInternal<T[0], Path extends "" ? "*" : `${Path}.*`>
  : T extends number
  ? Path
  : never;

type Paths<T> = PathsInternal<T>;

export function dollarsToCents<O extends Record<string, unknown>>(
  object: O,
  paths: Paths<O>[],
): O {
  const clone = JSON.parse(JSON.stringify(object));
  paths.forEach((path: Key) => {
    jp.apply(clone, path.toString(), (value: number) => {
      if (typeof value !== "number") {
        return value;
      }
      return CurrencyUtilities.toMinorUnits(value);
    });
  });
  return clone;
}

export function centsToDollars<O extends Record<string, unknown>>(
  object: O,
  paths: Paths<O>[],
): O {
  const clone = JSON.parse(JSON.stringify(object));
  paths.forEach((path: Key) => {
    jp.apply(clone, path.toString(), (value: number) => {
      if (typeof value !== "number") {
        return value;
      }
      return CurrencyUtilities.toMainUnits(value);
    });
  });
  return clone;
}
