import MethodBuilder from "../../method-builder";
import { TPaymentReportItem, TPaging } from "../../types";

export class PaymentsReportAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
    ) & {
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      // enum(pending, approved, rejected, null)
      verificationStatus?: string | null;

      //enum(requested, success, failed, prohibited, verification)
      status?: string;

      //enum(deposit, withdraw)
      type?: string;

      providerStringId?: string;

      //enum(crypto, bank_account, phone, card)
      methodType?: string;

      paging: TPaging;
    },
    {
      data: TPaymentReportItem[];
      total: number;
    }
  >()
    .withName("PaymentReport.filter")
    .build();
}
