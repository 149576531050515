import { DeleteOutlined, RestOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  AgentAPI,
  BannerAPI,
  CashierAPI,
  HallAPI,
  KioskAPI,
  PlayerAPI,
} from "@/api";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import AreYouSure from "../_common_/are-you-sure";

type Entity =
  | { agentId: number }
  | { hallId: number }
  | { cashierId: number }
  | { playerId: number }
  | { kioskId: number }
  | { bannerId: number };

interface IProps {
  entity: Entity;
  isDeleted: boolean;
  onSuccess?: () => void;
  size?: SizeType;
}

export const DeleteAction = observer(
  ({ entity, isDeleted, onSuccess, size = "middle" }: IProps) => {
    const intl = useIntl();
    const { permissionsStore, intlService } = useGlobalStore();

    const submitQuery = useMemo(
      () =>
        new Query<void, void>(async () => {
          try {
            if (!isDeleted) {
              if ("agentId" in entity) {
                await AgentAPI.delete({ id: entity.agentId });
              } else if ("hallId" in entity) {
                await HallAPI.delete({ id: entity.hallId });
              } else if ("cashierId" in entity) {
                await CashierAPI.delete({ id: entity.cashierId });
              } else if ("playerId" in entity) {
                await PlayerAPI.delete({ id: entity.playerId });
              } else if ("kioskId" in entity) {
                await KioskAPI.delete({ id: entity.kioskId });
              } else if ("bannerId" in entity) {
                await BannerAPI.delete({ id: entity.bannerId });
              }
            } else {
              if ("agentId" in entity) {
                await AgentAPI.undelete({ id: entity.agentId });
              } else if ("hallId" in entity) {
                await HallAPI.undelete({ id: entity.hallId });
              } else if ("cashierId" in entity) {
                await CashierAPI.undelete({ id: entity.cashierId });
              } else if ("playerId" in entity) {
                await PlayerAPI.undelete({ id: entity.playerId });
              } else if ("kioskId" in entity) {
                await KioskAPI.undelete({ id: entity.kioskId });
              } else if ("bannerId" in entity) {
                await BannerAPI.undelete({ id: entity.bannerId });
              }
            }

            onSuccess?.();
          } catch (error) {
            if (error instanceof Error) {
              notification.error({
                message: "",
                description: intlService.formatError(error),
              });
            }
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isDeleted],
    );

    if (
      "agentId" in entity &&
      (!permissionsStore.has("DeleteAgent") ||
        (isDeleted && !permissionsStore.has("UndeleteAgent")))
    ) {
      return null;
    } else if (
      "hallId" in entity &&
      (!permissionsStore.has("DeleteHall") ||
        (isDeleted && !permissionsStore.has("UndeleteHall")))
    ) {
      return null;
    } else if (
      "cashierId" in entity &&
      (!permissionsStore.has("DeleteCashier") ||
        (isDeleted && !permissionsStore.has("UndeleteCashier")))
    ) {
      return null;
    } else if (
      "playerId" in entity &&
      (!permissionsStore.has("DeletePlayer") ||
        (isDeleted && !permissionsStore.has("UndeletePlayer")))
    ) {
      return null;
    } else if (
      "kioskId" in entity &&
      (!permissionsStore.has("DeleteKiosk") ||
        (isDeleted && !permissionsStore.has("UndeleteKiosk")))
    ) {
      return null;
    }

    return !isDeleted ? (
      <AreYouSure onYes={() => submitQuery.submit()}>
        <Button
          size={size}
          icon={<DeleteOutlined />}
          title={intl.formatMessage({
            defaultMessage: "Delete",
          })}
          loading={submitQuery.isPending}
        />
      </AreYouSure>
    ) : (
      <Button
        size={size}
        icon={<RestOutlined />}
        title={intl.formatMessage({
          defaultMessage: "Restore",
        })}
        onClick={() => submitQuery.submit()}
        loading={submitQuery.isPending}
      />
    );
  },
);
