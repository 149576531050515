import yup from "@/yup";

export function useAgentCreateSchema() {
  return yup.object({
    name: yup.string().required().min(3),
    login: yup.string().required().min(3),
    password: yup.string().required().min(6),
    isBalanceUnlimited: yup.boolean().required(),
    currency: yup.string().when("isBalanceUnlimited", {
      is: false,
      then: (schema) => schema.required(),
    }),
    canHaveChildren: yup.boolean().required(),
  });
}

export type TAgentCreateSchema = yup.InferType<
  ReturnType<typeof useAgentCreateSchema>
>;
