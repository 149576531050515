import yup from "@/yup";

export function useAgentDepositSchema() {
  return yup.object({
    amount: yup.number().money().min(0).required(),
  });
}

export type TAgentDepositSchema = yup.InferType<
  ReturnType<typeof useAgentDepositSchema>
>;
