import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Input, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { AgentsStore, useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { GeneralSelectorErrors } from "@/ui/_common_/general-selector-errors";
import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";

import AgentCreateModal from "./agent-create-modal";
import AgentsTable from "./agents-table";
import { EditAgentModal } from "./edit";

const Agents: FC = observer(() => {
  const globalStore = useGlobalStore();
  const { permissionsStore } = globalStore;
  const [agentsStore] = useState(() => new AgentsStore(globalStore));

  const intl = useIntl();

  const { clientSelectorStore, agentSelectorStore, subagentSelectorStore } =
    agentsStore.generalSelectorsStore;

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: "Agents" })}
      isContentLoading={!agentsStore.generalSelectorsStore.isReady}
    >
      <AgentCreateModal agentsStore={agentsStore} />
      <EditAgentModal />
      {(permissionsStore.has("SelectClient") ||
        permissionsStore.has("SelectAgent") ||
        permissionsStore.has("SelectSubagent")) && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            {permissionsStore.has("SelectClient") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={clientSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select client",
                  })}
                />
              </Col>
            )}
            {permissionsStore.has("SelectAgent") &&
              !!clientSelectorStore.selectedId && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
            {permissionsStore.has("SelectSubagent") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={subagentSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select subagent",
                  })}
                />
              </Col>
            )}
          </Row>
        </Card>
      )}
      {!!clientSelectorStore.selectedId && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            {permissionsStore.has("ViewDeletedAgents") && (
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  onChange={(event) => {
                    agentsStore.includeDeleted = event.target.checked;
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Include deleted" })}
                </Checkbox>
              </Col>
            )}
            <Col
              xs={24}
              sm={10}
              md={8}
              lg={6}
              xl={4}
              style={{ marginLeft: "auto" }}
            >
              <Input.Search
                placeholder={intl.formatMessage({
                  defaultMessage: "Agent ID or name",
                })}
                allowClear
                defaultValue={agentsStore.filterQuery.searchQuery}
                onSearch={(searchQuery) => {
                  agentsStore.filterQuery.setSearchQuery(searchQuery);
                  agentsStore.filter();
                }}
              />
            </Col>
            <Col>
              <Button
                onClick={agentsStore.agentCreateStore.modal.open}
                icon={<PlusOutlined />}
              >
                {intl.formatMessage({ defaultMessage: "Create" })}
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      <Card size="small">
        <Space direction="vertical">
          <GeneralSelectorErrors store={agentsStore.generalSelectorsStore} />
          <ErrorsFormatter queries={[agentsStore.filterQuery]} />
          <AgentsTable agentsStore={agentsStore} />
        </Space>
      </Card>
    </Page>
  );
});

const FreshAgents: FC = observer(() => {
  const location = useLocation();
  const state = location?.state as Record<string, any> | null;
  return <Agents key={state?.agentsPageKey} />;
});

export default FreshAgents;
