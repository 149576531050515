import { TGamesOrderItem, TPaging } from "..";
import MethodBuilder from "../method-builder";

class GamesOrderAPI {
  static filter = new MethodBuilder<
    {
      agentId: number;
      providerId?: number;
      searchQuery?: string;
      orderBy: [
        (
          | "gameId"
          | "gameStringId"
          | "gameName"
          | "gameOrder"
          | "defaultGameOrder"
          | "realGameOrder"
        ),
        "asc" | "desc",
      ];
      paging: TPaging;
    },
    {
      data: TGamesOrderItem[];
      total: number;
    }
  >()
    .withName("GamesOrder.filter")
    .build();

  static update = new MethodBuilder<
    {
      agentId: number;
      gameId: number;
      // если null, то использовать глобальную «популярность»
      gameOrder: number | null;
    },
    void
  >()
    .withName("GamesOrder.update")
    .build();

  static export = new MethodBuilder<
    {
      agentId: number;

      format: "csv";
    },
    { data: string }
  >()
    .withName("GamesOrder.export")
    .build();

  static import = new MethodBuilder<
    {
      agentId: number;

      format: "csv";
      data: string;
    },
    void
  >()
    .withName("GamesOrder.import")
    .build();

  static reset = new MethodBuilder<{ agentId: number }, void>()
    .withName("GamesOrder.reset")
    .build();
}

export default GamesOrderAPI;
