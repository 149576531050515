import { TManager, TPaging } from "..";
import MethodBuilder from "../method-builder";

class ManagerAPI {
  static getById = new MethodBuilder<{ id: number }, TManager>()
    .withName("Manager.getById")
    .build();

  static filter = new MethodBuilder<
    {
      searchQuery?: string;
      paging: TPaging;
    },
    {
      data: TManager[];
      total: number;
    }
  >()
    .withName("Manager.filter")
    .build();
}

export default ManagerAPI;
