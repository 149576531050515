import { useMemo, useEffect } from "react";
import { useIntl } from "react-intl";

import { SportClubAPI } from "@/api";
import { EditClub, ClubCreated, ClubEdited } from "@/events";
import ItemsQuery from "@/models/items-query";
import { useGlobalStore } from "@/stores";

import { Props } from "./table";

export function useSportClubsTableState({ partnerId }: Props) {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const query = useMemo(() => {
    const query = new ItemsQuery(SportClubAPI.filter, {
      isOrderEnabled: false,
      isPaginationEnabled: true,
      isSearchEnabled: true,
    });
    query.submit({ partnerId });
    return query;
  }, [partnerId]);

  useEffect(() => {
    const listener = () => {
      query.submit({ partnerId });
    };

    eventBusService.subscribe(ClubCreated, listener);
    eventBusService.subscribe(ClubEdited, listener);

    return () => {
      eventBusService.unsubscribe(ClubCreated, listener);
      eventBusService.unsubscribe(ClubEdited, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId]);

  function openEditClubModal(id: number) {
    eventBusService.publish(new EditClub({ id }));
  }

  return {
    intl,
    query,

    openEditClubModal,
  };
}

export type SportClubsTableState = ReturnType<typeof useSportClubsTableState>;
