import { Button, Popover } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { memo } from "react";

import { ParentNodeIcon } from "../icons";

import { AncestorsTree, TEntity } from "./ancestors-tree";

type Props = {
  entity: TEntity;
  size?: SizeType;
};

export const AncestorsTreeButton = memo(({ entity, size = "small" }: Props) => {
  return (
    <Popover
      content={<AncestorsTree entity={entity} />}
      trigger="click"
      placement="topRight"
    >
      <Button
        size={size}
        icon={<ParentNodeIcon width={50 / 4} height={50 / 4} />}
      />
    </Popover>
  );
});

AncestorsTreeButton.displayName = "AncestorsTreeButton";
