import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Input, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { HallsStore, useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { GeneralSelectorErrors } from "@/ui/_common_/general-selector-errors";
import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";

import HallCreateModal from "./hall-create-modal";
import { HallSettingsModal } from "./hall-settings-modal";
import HallsTable from "./halls-table";

function Halls() {
  const globalStore = useGlobalStore();
  const [hallsStore] = useState(() => new HallsStore(globalStore));
  const intl = useIntl();
  const { permissionsStore } = globalStore;

  const { clientSelectorStore, agentSelectorStore, subagentSelectorStore } =
    hallsStore.generalSelectorsStore;

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: "Shops" })}
      isContentLoading={!hallsStore.generalSelectorsStore.isReady}
    >
      <HallCreateModal hallsStore={hallsStore} />
      <HallSettingsModal />
      {(permissionsStore.has("SelectClient") ||
        permissionsStore.has("SelectAgent") ||
        permissionsStore.has("SelectSubagent")) && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            {permissionsStore.has("SelectClient") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={clientSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select client",
                  })}
                />
              </Col>
            )}
            {permissionsStore.has("SelectAgent") &&
              !!clientSelectorStore.selectedId && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
            {permissionsStore.has("SelectSubagent") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={subagentSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select subagent",
                  })}
                />
              </Col>
            )}
          </Row>
        </Card>
      )}
      {!!clientSelectorStore.selectedId && (
        <Card size="small">
          <Row gutter={[16, 16]} wrap={true}>
            {permissionsStore.has("ViewDeletedHalls") && (
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  onChange={(event) => {
                    hallsStore.includeDeleted = event.target.checked;
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Include deleted" })}
                </Checkbox>
              </Col>
            )}
            <Col
              xs={24}
              sm={10}
              md={8}
              lg={6}
              xl={4}
              style={{ marginLeft: "auto" }}
            >
              <Input.Search
                placeholder={intl.formatMessage({
                  defaultMessage: "Shop ID or username",
                })}
                allowClear
                defaultValue={hallsStore.filterQuery.searchQuery}
                onSearch={(searchQuery) => {
                  hallsStore.filterQuery.setSearchQuery(searchQuery);
                  hallsStore.filter();
                }}
              />
            </Col>
            <Col>
              <Button
                onClick={hallsStore.hallCreateStore.modal.open}
                icon={<PlusOutlined />}
              >
                {intl.formatMessage({ defaultMessage: "Create" })}
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      <Card size="small">
        <Space direction="vertical">
          <GeneralSelectorErrors store={hallsStore.generalSelectorsStore} />
          <ErrorsFormatter queries={[hallsStore.filterQuery]} />
          <HallsTable hallsStore={hallsStore} />
        </Space>
      </Card>
    </Page>
  );
}

export default observer(Halls);
