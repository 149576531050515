import { Col, Modal, Row, Space } from "antd";
import React from "react";
import { useIntl } from "react-intl";

import { TBonusReportItem } from "@/api";
import MoneyFormatter from "@/ui/_common_/money-formatter";

type Props = {
  bonusReportItem: TBonusReportItem;
  onClose(): void;
};

export const DetailsModal: React.FC<Props> = ({ onClose, bonusReportItem }) => {
  const {
    finishType,
    size,
    cooldown,
    happyHours,
    playerBalanceThreshold,
    isWagerEnabled,
    wager,
  } = bonusReportItem;
  const intl = useIntl();
  const _ = intl.formatMessage;
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Details" })}
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <Space direction="vertical">
        <Row>
          <Col span={10}>
            {intl.formatMessage({ defaultMessage: "Finish type" })}:
          </Col>
          <Col span={12}>
            {finishType
              ? _({ id: `reports/bonuses/finish_type/${finishType}` })
              : "—"}
          </Col>
        </Row>
        <Row>
          <Col span={10}>{intl.formatMessage({ defaultMessage: "Size" })}:</Col>
          <Col span={12}>{size !== null ? `${size}%` : "—"}</Col>
        </Row>
        <Row>
          <Col span={10}>
            {intl.formatMessage({ defaultMessage: "Cooldown" })}:
          </Col>
          <Col span={12}>{cooldown ?? "—"}</Col>
        </Row>
        <Row>
          <Col span={10}>
            {intl.formatMessage({ defaultMessage: "Happy hours" })}:
          </Col>
          <Col span={12}>
            {happyHours !== null
              ? happyHours.isEnabled
                ? intl.formatMessage({ defaultMessage: "Yes" })
                : intl.formatMessage({ defaultMessage: "No" })
              : "—"}
          </Col>
        </Row>
        {happyHours && (
          <>
            <Row>
              <Col span={10}>
                {intl.formatMessage({ defaultMessage: "Happy hours range" })}:
              </Col>
              <Col span={12}>
                {new Date(happyHours.dateTimeRange.from).toLocaleTimeString()}
                {" - "}
                {new Date(happyHours.dateTimeRange.to).toLocaleTimeString()}
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                {intl.formatMessage({ defaultMessage: "Boost" })}:
              </Col>
              <Col span={12}>{happyHours.boost}%</Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={10}>
            {intl.formatMessage({ defaultMessage: "Player balance threshold" })}
            :
          </Col>
          <Col span={12}>
            <MoneyFormatter cents={playerBalanceThreshold ?? undefined} />
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            {intl.formatMessage({ defaultMessage: "Wager enabled" })}:
          </Col>
          <Col span={12}>{isWagerEnabled ? "Yes" : "No"}</Col>
        </Row>
        <Row>
          <Col span={10}>
            {intl.formatMessage({ defaultMessage: "Wager amount" })}:
          </Col>
          <Col span={12}>
            <MoneyFormatter cents={wager ?? undefined} />
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};
