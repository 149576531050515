import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Input, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { CreateKiosk, OpenKiosksSettings } from "@/events";
import { KiosksStore, useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { GeneralSelectorErrors } from "@/ui/_common_/general-selector-errors";
import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";
import DepositBalance from "@/ui/players/deposit";
import WithdrawBalance from "@/ui/players/withdraw";

import { AddKioskModal } from "./add-kiosk-modal";
import { KioskSettingsModal } from "./kiosk-settings-modal";
import { KiosksSettingsModal } from "./kiosks-settings-modal";
import KiosksTable from "./kiosks-table";
import { PanicSwitch } from "./panic-switch";
import VoucherRedeemModal from "./voucher-redeem-modal";

function Kiosks() {
  const globalStore = useGlobalStore();
  const [kiosksStore] = useState(() => new KiosksStore(globalStore));
  const intl = useIntl();
  const { eventBusService, permissionsStore, userStore } = globalStore;

  const {
    clientSelectorStore,
    agentSelectorStore,
    subagentSelectorStore,
    hallSelectorStore,
  } = kiosksStore.generalSelectorsStore;

  const { user } = userStore;

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: "Kiosks" })}
      isContentLoading={!kiosksStore.generalSelectorsStore.isReady}
    >
      <DepositBalance.Modal />
      <WithdrawBalance.Modal />

      <AddKioskModal />
      <KioskSettingsModal />
      <KiosksSettingsModal />

      <VoucherRedeemModal voucherRedeemStore={kiosksStore.voucherRedeemStore} />
      {(permissionsStore.has("SelectClient") ||
        permissionsStore.has("SelectAgent") ||
        permissionsStore.has("SelectSubagent") ||
        permissionsStore.has("SelectHall")) && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            {permissionsStore.has("SelectClient") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={clientSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select client",
                  })}
                />
              </Col>
            )}
            {permissionsStore.has("SelectAgent") &&
              !!clientSelectorStore.selectedId && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
            {permissionsStore.has("SelectSubagent") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={subagentSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select subagent",
                  })}
                />
              </Col>
            )}
            {permissionsStore.has("SelectHall") &&
              (clientSelectorStore.selectedId ||
                agentSelectorStore.selectedId) && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
          </Row>
        </Card>
      )}
      {!!hallSelectorStore.selectedId && (
        <Card size="small">
          <Row gutter={[16, 16]} wrap={true}>
            {permissionsStore.has("ViewDeletedKiosks") && (
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  onChange={(event) => {
                    kiosksStore.includeDeleted = event.target.checked;
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Include deleted" })}
                </Checkbox>
              </Col>
            )}
            <Col style={{ marginLeft: "auto" }}>
              <PanicSwitch hallId={hallSelectorStore.selectedId} />
            </Col>
            {!["HallAdmin", "Cashier"].includes(user.role) && (
              <Col>
                <Button
                  onClick={() =>
                    eventBusService.publish(
                      new OpenKiosksSettings({
                        clientId: clientSelectorStore.selectedId!,
                        hallId: hallSelectorStore.selectedId!,
                      }),
                    )
                  }
                >
                  {intl.formatMessage({ defaultMessage: "Settings" })}
                </Button>
              </Col>
            )}
            <Col>
              <Button
                onClick={() => {
                  const hallId = hallSelectorStore.selectedId;
                  if (hallId) {
                    kiosksStore.voucherRedeemStore.openModal(hallId);
                  }
                }}
              >
                {intl.formatMessage({ defaultMessage: "Redeem voucher" })}
              </Button>
            </Col>
            <Col xs={24} sm={10} md={8} lg={6} xl={4}>
              <Input.Search
                placeholder={intl.formatMessage({
                  defaultMessage: "Kiosk ID or username",
                })}
                allowClear
                defaultValue={kiosksStore.filterQuery.searchQuery}
                onSearch={(searchQuery) => {
                  kiosksStore.filterQuery.setSearchQuery(searchQuery);
                  kiosksStore.filter();
                }}
              />
            </Col>
            {permissionsStore.has("CreateKiosk") && (
              <Col>
                <Button
                  onClick={() => {
                    eventBusService.publish(
                      new CreateKiosk({
                        hallId: hallSelectorStore.selectedId!,
                      }),
                    );
                  }}
                  icon={<PlusOutlined />}
                >
                  {intl.formatMessage({ defaultMessage: "Create" })}
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      )}
      <Card size="small">
        <Space direction="vertical">
          <GeneralSelectorErrors store={kiosksStore.generalSelectorsStore} />
          <ErrorsFormatter queries={[kiosksStore.filterQuery]} />
          <KiosksTable kiosksStore={kiosksStore} />
        </Space>
      </Card>
    </Page>
  );
}

export default observer(Kiosks);
