import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { KioskAccessAPI } from "@/api";
import { CloseEditKioskAccessModal } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import {
  EditKioskAccessFormSchema,
  useEditKioskAccessFormSchema,
} from "./form.schema";

export interface EditKioskAccessFormProps {
  id: number;
  hallId: number;
  clientId: number;
  kioskId?: number;
}

export function useEditKioskAccessFormState({
  id,
  clientId,
  hallId,
  kioskId,
}: EditKioskAccessFormProps) {
  const intl = useIntl();

  const schema = useEditKioskAccessFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditKioskAccessFormSchema>({ resolver });

  const query = useMemo(() => {
    return new Query(KioskAccessAPI.update);
  }, []);

  const { eventBusService } = useGlobalStore();

  const handleSubmit = form.handleSubmit(async (values) => {
    await query.submit({ id, clientId, hallId, kioskId, ...values });

    if (!query.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: intl.formatMessage({
        defaultMessage: "The kiosk access was successfully added.",
      }),
    });

    eventBusService.publish(new CloseEditKioskAccessModal({}));
  });

  return { intl, form, handleSubmit, query };
}

export type EditKioskAccessFormState = ReturnType<
  typeof useEditKioskAccessFormState
>;
