import { Tabs } from "antd";
import { FC } from "react";

import Page from "@/ui/_common_/page";

import JackpotSlotTemplate from "./jackpot-slot-template";
import JackpotTemplates from "./jackpot-templates";
import JackpotWeights from "./jackpot-weights";

const Jackpots: FC = () => {
  return (
    <Page title={"Jackpots"}>
      <Tabs defaultActiveKey="templates">
        <Tabs.TabPane tab={"Templates"} key="templates">
          <JackpotTemplates />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Slot templates"} key="slot-templates">
          <JackpotSlotTemplate />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Weight"} key="weights">
          <JackpotWeights />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

export default Jackpots;
