import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
} from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useResetForm } from "@/hooks";
import { useHallCreateSchema, THallCreateSchema } from "@/schemas";
import { HallsStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Password from "@/ui/_common_/password";
import Selector from "@/ui/_common_/selector";
import { generatePassword } from "@/utilities";
import yup from "@/yup";

type TProps = {
  hallsStore: HallsStore;
};

function HallCreateForm({ hallsStore }: TProps) {
  const { hallCreateStore } = hallsStore;

  const hallCreateSchema = useHallCreateSchema();
  const schema = useMemo(() => {
    if (hallCreateStore.parentCurrency === null) {
      return hallCreateSchema;
    }
    return hallCreateSchema.concat(
      yup.object({
        currency: yup.string().equals([hallCreateStore.parentCurrency]),
      }),
    );
  }, [hallCreateSchema, hallCreateStore.parentCurrency]);

  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm<THallCreateSchema>({
    defaultValues: {
      currency: hallCreateStore.parentCurrency ?? undefined,
      lobbySettings: {
        playersIdleTimeout: 0,
        isLogOutButtonEnabled: true,
      },
    },
    resolver,
  });

  useResetForm(hallCreateStore.createQuery, reset);

  useEffect(() => {
    setValue("currency", hallCreateStore.parentCurrency ?? (undefined as any));
  }, [setValue, hallCreateStore.parentCurrency]);

  useEffect(() => () => hallCreateStore.dispose(), [hallCreateStore]);

  const intl = useIntl();

  return (
    <Spin spinning={hallCreateStore.createQuery.isPending}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[hallCreateStore.createQuery]} />
        <Form
          labelCol={{ span: 10 }}
          labelAlign="left"
          layout="horizontal"
          onFinish={handleSubmit(hallCreateStore.handleSubmit)}
        >
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Parent agent" })}
          >
            <Selector
              placeholder={intl.formatMessage({
                defaultMessage: "Select parent agent",
              })}
              store={hallCreateStore.parentAgentSelectorStore}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Name" })}
            validateStatus={has(errors, "name") ? "error" : ""}
            help={errors.name?.message}
          >
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter name",
                  })}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin username" })}
            validateStatus={has(errors, "login") ? "error" : ""}
            help={errors.login?.message}
          >
            <Controller
              control={control}
              name="login"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter username",
                  })}
                  // disable autocomplete
                  readOnly
                  onFocus={(e) => e.target.removeAttribute("readonly")}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin password" })}
            validateStatus={has(errors, "password") ? "error" : ""}
            help={errors.password?.message}
          >
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter password",
                  })}
                  onCopy={() => {
                    const password = getValues().password ?? "";
                    if (password) {
                      navigator.clipboard.writeText(password);
                    }
                  }}
                  onGenerate={() => setValue("password", generatePassword())}
                  // disable autocomplete
                  readOnly
                  onFocus={(e) => e.target.removeAttribute("readonly")}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Unlimited balance" })}
            validateStatus={has(errors, "isBalanceUnlimited") ? "error" : ""}
            help={errors.isBalanceUnlimited?.message}
          >
            <Controller
              control={control}
              name="isBalanceUnlimited"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={field.onChange} />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Currency",
            })}
            validateStatus={has(errors, "currency") ? "error" : ""}
            help={errors.currency?.message}
          >
            {hallCreateStore.parentCurrency !== null ? (
              <Input disabled value={hallCreateStore.parentCurrency} />
            ) : (
              <Controller
                control={control}
                name="currency"
                defaultValue=""
                render={({ field }) => (
                  <CurrencySelector
                    {...field}
                    value={field.value ? field.value : undefined}
                  />
                )}
              />
            )}
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Auto print receipts",
            })}
            validateStatus={has(errors, "autoPrintReceipts") ? "error" : ""}
            help={errors.autoPrintReceipts?.message}
          >
            <Controller
              control={control}
              name="autoPrintReceipts"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={field.onChange} />
              )}
            />
          </Form.Item>
          <Typography.Title level={5} style={{ marginTop: 32 }}>
            {intl.formatMessage({
              defaultMessage: "Lobby settings",
            })}
          </Typography.Title>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Players idle timeout  (min)",
            })}
            validateStatus={
              has(errors, "lobbySettings.playersIdleTimeout") ? "error" : ""
            }
            help={errors?.lobbySettings?.playersIdleTimeout?.message}
          >
            <Controller
              control={control}
              name="lobbySettings.playersIdleTimeout"
              defaultValue={0}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Logout button enabled",
            })}
            validateStatus={
              has(errors, "lobbySettings.isLogOutButtonEnabled") ? "error" : ""
            }
            help={errors?.lobbySettings?.isLogOutButtonEnabled?.message}
          >
            <Controller
              control={control}
              name="lobbySettings.isLogOutButtonEnabled"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={field.onChange} />
              )}
            />
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({ defaultMessage: "Create" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
}

export default observer(HallCreateForm);
