import { Drawer, Layout, Result, Space, Typography } from "antd";
import { Divider } from "antd/es";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useIsDesktop } from "@/hooks";
import ContentSpinner from "@/ui/_common_/content-spinner";
import { ErrorBoundary } from "@/ui/_common_/error-boundary";
import LocaleSelector from "@/ui/_common_/locale_selector";
import NumberFormatSettings from "@/ui/_common_/number_format_settings";
import Sidebar from "@/ui/_common_/sidebar";
import UserInfo from "@/ui/_common_/user-info";

import { version } from "../../../../package.json";

type TProps = {
  title: ReactNode;
  isContentLoading?: boolean;
  children: ReactNode;
};

function Page({ title, children, isContentLoading = false }: TProps) {
  const intl = useIntl();
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (!isDesktop) {
      setIsMenuHidden(true);
    }
  }, [isDesktop]);

  const menu = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
      }}
    >
      <UserInfo />
      <Divider />
      <Sidebar />
      <div style={{ marginTop: "auto" }}></div>
      <Divider />
      <div
        className="custom-sider__title"
        style={{
          marginTop: "auto",
        }}
      >
        {intl.formatMessage({
          defaultMessage: "Language",
        })}
      </div>
      <LocaleSelector
        style={{
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 8,
        }}
      />
      <div className="custom-sider__title">
        {intl.formatMessage({
          defaultMessage: "Number separators",
        })}
      </div>
      <NumberFormatSettings
        style={{
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 16,
        }}
      />
      <div className="custom-sider__version">v{version}</div>
    </div>
  );

  return (
    <Layout
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Layout.Sider
        theme="dark"
        breakpoint="md"
        collapsedWidth={0}
        width={256}
        collapsed={!isDesktop}
        onCollapse={(isCollapsed) => {
          setIsMenuHidden(isCollapsed);
        }}
        className="custom-sider"
      >
        {isDesktop && menu}
      </Layout.Sider>
      <Drawer
        placement="left"
        onClose={() => {
          setIsMenuHidden(true);
        }}
        open={!isDesktop && !isMenuHidden}
        bodyStyle={{ padding: "0" }}
        drawerStyle={{ backgroundColor: "#001529" }}
        className="custom-drawer"
        closable={false}
      >
        {!isDesktop && menu}
      </Drawer>
      <Layout style={{ position: "relative", overflow: "hidden" }}>
        <ContentSpinner isVisible={isContentLoading} />
        <ErrorBoundary
          fallback={
            <Result
              status="error"
              title={intl.formatMessage({ defaultMessage: "Unknown error" })}
            />
          }
        >
          <Layout.Content
            style={{ padding: "16px", minHeight: "auto", overflow: "auto" }}
          >
            <Space direction="vertical">
              <Typography.Title level={3} style={{ margin: 0, padding: 0 }}>
                {title}
              </Typography.Title>
              {children}
            </Space>
          </Layout.Content>
        </ErrorBoundary>
      </Layout>
    </Layout>
  );
}

export default observer(Page);
