import yup from "@/yup";

export function useFormSchema() {
  return yup.object({
    providerId: yup.number().integer().min(0).defined(),
    brandId: yup.number().integer().min(0).defined(),
    stringId: yup.string().min(1).defined(),
    name: yup.string().min(1).defined(),
    isJackpotsEnabled: yup.boolean().default(false).defined(),
    isBonusWagerEnabled: yup.boolean().default(false).defined(),
  });
}

export type FormSchema = yup.InferType<ReturnType<typeof useFormSchema>>;
