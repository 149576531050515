import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { PlayerAPI, TPlayer } from "@/api";
import Query from "@/models/query";
import { IntlService } from "@/services";

import { PlayersStore } from ".";

export class PlayerStore {
  fetchQuery = new Query(PlayerAPI.getById);
  updateQuery = new Query(PlayerAPI.update);

  constructor(
    private _player: TPlayer,
    public readonly playersStore: PlayersStore,
    public intlService: IntlService,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.intlService.intl;
  }

  get player() {
    return this._player;
  }

  fetch = async () => {
    await this.fetchQuery.submit({
      id: this.player.id,
    });

    if (!this.fetchQuery.isFulfilled) {
      return;
    }

    const player = this.fetchQuery.data;
    this.setPlayer(player);
  };

  block = async () => {
    await this.updateQuery.submit({
      id: this.player.id,
      isBlocked: true,
    });

    if (this.updateQuery.isFulfilled) {
      notification.success({
        message: "",
        description: this.intl.formatMessage({
          defaultMessage: "The player has been successfully blocked.",
        }),
      });

      this.fetch();
    } else if (this.updateQuery.error) {
      notification.error({
        message: "",
        description: this.intlService.formatError(this.updateQuery.error),
      });
    }
  };

  unblock = async () => {
    await this.updateQuery.submit({ id: this.player.id, isBlocked: false });

    if (this.updateQuery.isFulfilled) {
      notification.success({
        message: "",
        description: this.intl.formatMessage({
          defaultMessage: "The player has been successfully unblocked.",
        }),
      });

      this.fetch();
    } else if (this.updateQuery.error) {
      notification.error({
        message: "",
        description: this.intlService.formatError(this.updateQuery.error),
      });
    }
  };

  private setPlayer = (player: TPlayer) => {
    this._player = player;
  };
}
