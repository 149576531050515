import { notification, Space, Switch } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { HallAPI } from "@/api";
import { HallPanicUpdated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

interface Props {
  hallId: number;
}

export const PanicSwitch = observer(({ hallId }: Props) => {
  const { eventBusService } = useGlobalStore();

  const hallQuery = useMemo(() => {
    const query = new Query(HallAPI.getById);
    query.submit({ id: hallId });
    return query;
  }, [hallId]);

  const toggleQuery = useMemo(
    () =>
      new Query(async (isPanicEnabled: boolean) => {
        if (isPanicEnabled) {
          await HallAPI.enablePanic({ id: hallId });
        } else {
          await HallAPI.disablePanic({ id: hallId });
        }
        const hall = await HallAPI.getById({ id: hallId });
        hallQuery.resolve({ id: hallId }, hall);

        if (isPanicEnabled) {
          notification.success({
            message: "",
            description: intl.formatMessage({
              defaultMessage: "The panic mode has been successfully enabled.",
            }),
          });
        } else {
          notification.success({
            message: "",
            description: intl.formatMessage({
              defaultMessage: "The panic mode has been successfully disabled.",
            }),
          });
        }

        eventBusService.publish(new HallPanicUpdated({ hallId }));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const intl = useIntl();

  if (!hallQuery.isFulfilled) {
    return null;
  }

  const { isPanicEnabled } = hallQuery.data.lobbySettings;
  const backgroundColor = isPanicEnabled ? "#d00000" : undefined;

  return (
    <Space align="center" style={{ height: "100%" }}>
      {intl.formatMessage({ defaultMessage: "Panic" })}
      <Switch
        checked={isPanicEnabled}
        onChange={toggleQuery.submit}
        loading={toggleQuery.isPending}
        style={{ backgroundColor }}
      />
    </Space>
  );
});
