import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const form = useForm<ISchema>({
    resolver: yupResolver(schema),
  });

  if (state.cashierQuery.isIdle || state.cashierQuery.isPending) {
    return <FormSpinner />;
  }

  if (state.cashierQuery.isRejected) {
    return <ErrorsFormatter queries={[state.cashierQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.withdrawQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Cashier" })}>
          <Input disabled value={state.cashier?.name} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Amount" })}
          validateStatus={has(form.formState.errors, "amount") ? "error" : ""}
          help={form.formState.errors.amount?.message}
        >
          <Controller
            control={form.control}
            name="amount"
            render={({ field }) => (
              <Input
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter amount",
                })}
                {...field}
                type="number"
                autoFocus
              />
            )}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                state.withdrawQuery.isPending ||
                state.userStore.cashierQuery.isPending ||
                state.userStore.shiftQuery.isPending
              }
            >
              {intl.formatMessage({ defaultMessage: "Withdraw" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
