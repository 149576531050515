import { makeAutoObservable } from "mobx";

import { AgentAPI, TAgent, TPaging } from "@/api";
import Query from "@/models/query";
import { IntlService } from "@/services";

import { AgentDepositStore, AgentsStore, AgentWithdrawStore } from ".";

export class AgentStore {
  constructor(
    private _agent: TAgent,
    public readonly agentsStore: AgentsStore,
    public readonly parentAgentStore: AgentStore | undefined,
    public readonly nestingLevel: number,
    public readonly intlService: IntlService,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.intlService.intl;
  }

  get agent() {
    return this._agent;
  }

  public get isSweepstakesEnabled() {
    return this._agent.clientMode !== "normal";
  }

  private setAgent = (agent: TAgent) => {
    this._agent = agent;
  };

  private _isChildrenShown = false;

  get isChildrenShown() {
    return this._isChildrenShown;
  }

  toggleChildren() {
    this._isChildrenShown = !this._isChildrenShown;
    if (this.isChildrenShown) {
      this.fetchChildren();
    } else {
      this.setChildren([]);
    }
  }

  private _children: AgentStore[] = [];

  get children() {
    if (!this.agent.ownSubagentsCount) {
      return undefined;
    }
    return this._children;
  }

  private setChildren = (children: AgentStore[]) => {
    this._children = children;
  };

  private _totalChildren = 0;

  get totalChildren() {
    return this._totalChildren;
  }

  setTotalChildren = (totalChildren: number) => {
    this._totalChildren = totalChildren;
  };

  private _childrenPagination: TPaging = {
    limit: 50,
    offset: 0,
  };

  get childrenPagination() {
    return this._childrenPagination;
  }

  setChildrenPagination = (pagination: TPaging) => {
    this._childrenPagination = pagination;
  };

  get hasMoreChildren() {
    if (!this.totalChildren) {
      return false;
    }
    if (!this.children) {
      return false;
    }
    return this.totalChildren > this.children.length;
  }

  fetchQuery = new Query(AgentAPI.getById);
  updateQuery = new Query(AgentAPI.update);
  childrenQuery = new Query(AgentAPI.filter);

  withdrawStore = new AgentWithdrawStore(this);
  depositStore = new AgentDepositStore(this);

  fetch = async () => {
    await this.fetchQuery.submit({
      id: this.agent.id,
    });

    if (!this.fetchQuery.isFulfilled) {
      return;
    }

    const agent = this.fetchQuery.data;
    this.setAgent(agent);
  };

  fetchChildren = async ({ shouldFetchNextPage = false } = {}) => {
    await this.childrenQuery.submit({
      ...this.agentsStore.includeDeletedParams,
      parentAgentId: this.agent.id,
      nestingLevel: 0,
      paging: this._childrenPagination,
    });

    if (!this.childrenQuery.isFulfilled) {
      return;
    }

    const { data, total } = this.childrenQuery.data;
    const agentStores = data.map(
      (agent) =>
        new AgentStore(
          agent,
          this.agentsStore,
          this,
          this.nestingLevel + 1,
          this.intlService,
        ),
    );
    this.setChildren(
      shouldFetchNextPage ? [...this._children, ...agentStores] : agentStores,
    );
    this.setTotalChildren(total);
  };

  fetchMoreChildren = async () => {
    this.setChildrenPagination({
      limit: 50,
      offset: this._childrenPagination.offset + 50,
    });
    this.fetchChildren({ shouldFetchNextPage: true });
  };
}
