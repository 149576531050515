import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { FormView } from "./form.v";
import { FormViewModel } from "./form.vm";

export type Props = {
  cashierId: number;
};

export const Form: FC<Props> = observer((props) => {
  const { eventBusService, intlService } = useGlobalStore();
  const vm = useViewModel(
    () => new FormViewModel(eventBusService, intlService),
    props,
  );
  return <FormView vm={vm} />;
});

Form.displayName = "EditLimit.Form";
