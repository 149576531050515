import { useEffect, useState } from "react";

import { UserAPI } from "@/api";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

export function usePanicService() {
  const { userStore } = useGlobalStore();

  const [userQuery] = useState(() => new Query(UserAPI.getCurrent));

  useEffect(() => {
    if (!userStore.user) {
      return;
    }

    const id = window.setInterval(() => userQuery.submit(), 5000);
    return () => window.clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user]);
}
