import { grey } from "@ant-design/colors";
import { Button, Card, Col, Row, Select, Space, Spin, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment-timezone";
import { useIntl } from "react-intl";

import { TDailyProfitReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import { MonthPicker } from "@/ui/_common_/month-picker";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { DailyProfitReportState } from "./DailyProfitReportState";

function DailyProfitReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new DailyProfitReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  return (
    <Page title={intl.formatMessage({ defaultMessage: "Daily profit report" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}

          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={state.providerSelectorStore}
              placeholder={intl.formatMessage({
                defaultMessage: "Select provider",
              })}
            />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <MonthPicker store={state} />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Select
              placeholder="Select a time zone"
              showSearch
              style={{ width: "100%" }}
              value={state.timeZone}
              onChange={state.setTimeZone}
            >
              {moment.tz.names().map((name) => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin
          spinning={
            state.filterByCurrencyQuery.isPending ||
            state.filterByAgentQuery.isPending ||
            state.filterByHallQuery.isPending
          }
        >
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                state.filterByCurrencyQuery,
                state.filterByAgentQuery,
                state.filterByHallQuery,
              ]}
            />
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={state.tab}
              onChange={(value) => (state.tab = value)}
            >
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Currency" })}
                key="currency"
              >
                <Table
                  dataSource={state.filterByCurrencyQuery.items}
                  showHeader={!!state.filterByCurrencyQuery.items.length}
                  rowKey={(item) => `${item.currency}_${item.providerId}`}
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Provider" })}
                    dataIndex="providerName"
                    render={(providerName) => providerName ?? "—"}
                  />
                  {state.filterByCurrencyQuery.items.length > 0 &&
                    state.filterByCurrencyQuery.items[0].dailyProfit.map(
                      (item, index) => (
                        <Table.Column
                          key={`dailyProfit.${item.date}`}
                          title={item.date}
                          render={(_, item: TDailyProfitReportItem) => {
                            const { profit } = item.dailyProfit[index];
                            return (
                              <span style={{ color: !profit ? grey[0] : "" }}>
                                <MoneyFormatter cents={profit} />
                              </span>
                            );
                          }}
                          align="right"
                        />
                      ),
                    )}
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Total" })}
                    dataIndex="total"
                    align="right"
                    render={(total) => <MoneyFormatter cents={total} />}
                  />
                </Table>
                <Pagination
                  query={state.filterByCurrencyQuery}
                  onChange={() => {
                    state.filterByCurrency({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Agent" })}
                key="agent"
              >
                <Table
                  dataSource={state.filterByAgentQuery.items}
                  showHeader={!!state.filterByAgentQuery.items.length}
                  rowKey={(item) =>
                    `${item.currency}_${item.providerId}_${item.agentId}`
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Agent" })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Provider" })}
                    dataIndex="providerName"
                    render={(providerName) => providerName ?? "—"}
                  />
                  {state.filterByAgentQuery.items.length > 0 &&
                    state.filterByAgentQuery.items[0].dailyProfit.map(
                      (item, index) => (
                        <Table.Column
                          key={`dailyProfit.${item.date}`}
                          title={item.date}
                          render={(_, item: TDailyProfitReportItem) => {
                            const { profit } = item.dailyProfit[index];
                            return (
                              <span style={{ color: !profit ? grey[0] : "" }}>
                                <MoneyFormatter cents={profit} />
                              </span>
                            );
                          }}
                          align="right"
                        />
                      ),
                    )}
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Total" })}
                    dataIndex="total"
                    align="right"
                    render={(total) => <MoneyFormatter cents={total} />}
                  />
                </Table>
                <Pagination
                  query={state.filterByAgentQuery}
                  onChange={() => {
                    state.filterByAgent({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Shop" })}
                key="hall"
              >
                <Table
                  dataSource={state.filterByHallQuery.items}
                  showHeader={!!state.filterByHallQuery.items.length}
                  rowKey={(item) =>
                    `${item.currency}_${item.providerId}_${item.agentId}_${item.hallId}`
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Agent" })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Shop" })}
                    dataIndex="hallName"
                    render={(hallName) => hallName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Provider" })}
                    dataIndex="providerName"
                    render={(providerName) => providerName ?? "—"}
                  />
                  {state.filterByHallQuery.items.length > 0 &&
                    state.filterByHallQuery.items[0].dailyProfit.map(
                      (item, index) => (
                        <Table.Column
                          key={`dailyProfit.${item.date}`}
                          title={item.date}
                          render={(_, item: TDailyProfitReportItem) => {
                            const { profit } = item.dailyProfit[index];
                            return (
                              <span style={{ color: !profit ? grey[0] : "" }}>
                                <MoneyFormatter cents={profit} />
                              </span>
                            );
                          }}
                          align="right"
                        />
                      ),
                    )}
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Total" })}
                    dataIndex="total"
                    align="right"
                    render={(total) => <MoneyFormatter cents={total} />}
                  />
                </Table>
                <Pagination
                  query={state.filterByHallQuery}
                  onChange={() => {
                    state.filterByHall({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(DailyProfitReport);
