import { notification } from "antd";
import { isUndefined } from "lodash-es";
import { makeAutoObservable, runInAction } from "mobx";

import {
  AgentAPI,
  CashierAPI,
  ClientAPI,
  GameAPI,
  HallAPI,
  JackpotAPI,
  ProviderAPI,
  TBrand,
  TGame,
  TJackpot,
  TProvider,
  TWebsite,
  WebsiteAPI,
} from "@/api";
import BrandAPI from "@/api/modules/brand";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { TUserUpdateSchema, TWebsitesUpdateSchema } from "@/schemas";

import { GlobalStore } from "..";

export class UserUpdateStore {
  constructor(private readonly globalStore: GlobalStore) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.globalStore.intlService.intl;
  }

  modal = new Modal();

  clientUpdateQuery = new Query(ClientAPI.update);
  agentUpdateQuery = new Query(AgentAPI.update);
  hallUpdateQuery = new Query(HallAPI.update);
  cashierUpdateQuery = new Query(CashierAPI.update);

  websitesQuery = new Query(WebsiteAPI.filter);
  enableWebsitesQuery = new Query(WebsiteAPI.enable);
  disableWebsitesQuery = new Query(WebsiteAPI.disable);

  _websites: TWebsite[] = [];

  get websites() {
    return this._websites;
  }

  private setWebsites(websites: TWebsite[]) {
    this._websites = websites;
  }

  fetchWebsites = async () => {
    const { user } = this.globalStore.userStore;

    let params:
      | { clientId: number }
      | { hallId: number }
      | { agentId: number }
      | { kioskId: number }
      | undefined;
    if (user.role === "HallAdmin") {
      params = { hallId: user.hallId };
    } else if (user.role === "AgentAdmin") {
      params = { agentId: user.agentId };
    } else if (user.role === "ClientAdmin") {
      params = { clientId: user.clientId };
    }

    if (params !== undefined) {
      await this.websitesQuery.submit(params);

      if (!this.websitesQuery.isFulfilled) {
        return;
      }

      const { data } = this.websitesQuery.data;
      this.setWebsites(data);
    }
  };

  handleUserUpdate = async (values: TUserUpdateSchema) => {
    if (values.password === null) {
      return;
    }
    const { password, autoEnableGames, cashierSettings } = values;
    const { user } = this.globalStore.userStore;
    if (user.role === "ClientAdmin") {
      await this.clientUpdateQuery.submit({
        id: user.clientId,
        password,
        cashierSettings,
      });
    } else if (user.role === "AgentAdmin") {
      await this.agentUpdateQuery.submit({
        id: user.agentId,
        password,
        autoEnableGames,
        cashierSettings,
      });
      this.globalStore.userStore.fetchAgent(user.agentId);
    } else if (user.role === "HallAdmin") {
      await this.hallUpdateQuery.submit({
        id: user.hallId,
        password,
        autoEnableGames,
        cashierSettings,
      });
      this.globalStore.userStore.fetchHall(user.hallId);
    } else if (user.role === "Cashier") {
      await this.cashierUpdateQuery.submit({
        id: user.cashierId,
        password,
      });
    }
    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The settings have been successfully updated.",
      }),
    });
  };

  handleWebsitesUpdate = async (values: TWebsitesUpdateSchema) => {
    const { user } = this.globalStore.userStore;
    const params: { clientId?: number; agentId?: number } = {};
    if (user.role === "ClientAdmin") {
      params["clientId"] = user.clientId;
    } else if (user.role === "AgentAdmin") {
      params["agentId"] = user.agentId;
    }

    const websiteIdsToEnable = Object.values(values.websites)
      .filter((w) => w.isChecked)
      .map((w) => w.id);
    const websiteIdsToDisable = Object.values(values.websites)
      .filter((w) => !w.isChecked)
      .map((w) => w.id);

    await Promise.all([
      this.enableWebsitesQuery.submit({
        ...params,
        ids: websiteIdsToEnable,
      }),
      this.disableWebsitesQuery.submit({
        ...params,
        ids: websiteIdsToDisable,
      }),
    ]);

    if (
      !this.enableWebsitesQuery.isFulfilled ||
      !this.disableWebsitesQuery.isFulfilled
    ) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The website settings have been successfully updated.",
      }),
    });

    this.fetchWebsites();
  };

  get entity() {
    const { user } = this.globalStore.userStore;

    let entity:
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | undefined = undefined;

    if (user.role === "ClientAdmin") {
      entity = { clientId: user.clientId };
    } else if (user.role === "AgentAdmin") {
      entity = { agentId: user.agentId };
    } else if (user.role === "HallAdmin") {
      entity = { hallId: user.hallId };
    } else if (user.role === "Cashier") {
      entity = { hallId: user.hallId };
    }

    return entity;
  }

  jackpotsQuery = new Query(JackpotAPI.filter);

  private _jackpots: TJackpot[] = [];

  get jackpots() {
    return this._jackpots.filter((jackpot) => jackpot.id === 3);
  }

  setJackpots = (jackpots: TJackpot[]) => {
    this._jackpots = jackpots;
  };

  fetchJackpots = async () => {
    if (!this.entity) {
      return;
    }

    await this.jackpotsQuery.submit(this.entity);

    if (!this.jackpotsQuery.isFulfilled) {
      return;
    }

    const { data } = this.jackpotsQuery.data;
    this.setJackpots(data);
  };

  _selectedProviderId?: number;

  get selectedProviderId() {
    return this._selectedProviderId;
  }

  _selectedBrandId?: number;

  get selectedBrandId() {
    return this._selectedBrandId;
  }

  _gamesState: Record<number, boolean> = {};
  setSelectedProviderId = (id: number) => {
    this._selectedProviderId = id;
    const brand = this.filteredBrands.find((brand) => brand.providerId === id)
      ?.id;
    this.setSelectedBrandId(brand);

    const brandsElement = document.querySelector(".brands .ant-card-body");
    brandsElement?.scrollTo(0, 0);
  };
  setSelectedBrandId = (id?: number) => {
    this._selectedBrandId = id;

    const gamesElement = document.querySelector(".games .ant-card-body");
    gamesElement?.scrollTo(0, 0);
  };

  providersQuery = new Query(ProviderAPI.filter);
  brandsQuery = new Query(BrandAPI.getAll);
  gamesQuery = new Query(GameAPI.filter);
  enableGamesQuery = new Query(GameAPI.enable);
  disableGamesQuery = new Query(GameAPI.disable);

  _providers: TProvider[] = [];

  get providers() {
    return this._providers;
  }

  _brands: TBrand[] = [];

  get brands() {
    return this._brands;
  }

  _games: TGame[] = [];

  get games() {
    return this._games;
  }

  get filteredProviders() {
    const filteredProviders = [];

    for (const provider of this._providers) {
      const games = this._games.filter(
        (game) => game.providerId === provider.id,
      );

      if (!games.length) {
        continue;
      }

      const isChecked = games.every((game) => this._gamesState[game.id]);

      const isIndeterminate =
        !isChecked && games.some((game) => this._gamesState[game.id]);

      filteredProviders.push({
        ...provider,
        isChecked,
        isIndeterminate,
      });
    }

    filteredProviders.sort((a, b) => a.name.localeCompare(b.name));

    return filteredProviders;
  }

  get checkedProvidersCount() {
    return this.filteredProviders.filter((provider) => provider.isChecked)
      .length;
  }

  get filteredBrands() {
    const filteredBrands = [];

    for (const brand of this._brands) {
      if (brand.providerId !== this._selectedProviderId) {
        continue;
      }

      const games = this._games.filter((game) => game.brandId === brand.id);

      if (!games.length) {
        continue;
      }

      const isChecked = games.every((game) => this._gamesState[game.id]);

      const isIndeterminate =
        !isChecked && games.some((game) => this._gamesState[game.id]);

      filteredBrands.push({
        ...brand,
        isChecked,
        isIndeterminate,
      });
    }

    filteredBrands.sort((a, b) => a.name.localeCompare(b.name));

    return filteredBrands;
  }

  get filteredGames() {
    const filteredGames = this._games
      .filter((game) => {
        return (
          game.providerId === this._selectedProviderId &&
          game.brandId === this._selectedBrandId
        );
      })
      .map((game) => ({
        ...game,
        isChecked: this._gamesState[game.id],
      }));

    filteredGames.sort((a, b) => a.name.localeCompare(b.name));

    return filteredGames;
  }

  get checkedGamesCount() {
    return Object.values(this._gamesState).filter((isChecked) => isChecked)
      .length;
  }

  private setGames = (games: TGame[]) => {
    this._games = games;
    const gamesState: Record<number, boolean> = {};
    games.forEach((game) => {
      const isChecked = game.isEnabled;
      if (!isUndefined(isChecked)) {
        gamesState[game.id] = isChecked;
      }
    });
    this._gamesState = gamesState;
  };

  fetchGames = async () => {
    await Promise.all([
      this.providersQuery.submit({}),
      this.brandsQuery.submit(),
      this.gamesQuery.submit({
        ...this.entity,
        paging: { limit: 3000, offset: 0 },
      }),
    ]);

    runInAction(() => {
      if (
        this.providersQuery.isFulfilled &&
        this.brandsQuery.isFulfilled &&
        this.gamesQuery.isFulfilled
      ) {
        this._providers = this.providersQuery.data.data;
        this._brands = this.brandsQuery.data.data;
        this.setGames(this.gamesQuery.data.data);
        if (this.filteredProviders[0]) {
          this.setSelectedProviderId(this.filteredProviders[0].id);
        }
      }
    });
  };

  handleProviderCheck = (providerId: number) => {
    this.setSelectedProviderId(providerId);
    const gamesState: Record<number, boolean> = {};
    const games = this._games.filter((game) => game.providerId === providerId);
    const isChecked = games.every((game) => this._gamesState[game.id]);
    games.forEach((game) => (gamesState[game.id] = !isChecked));
    this._gamesState = { ...this._gamesState, ...gamesState };
  };

  handleProviderCheckAll = () => {
    const gamesState: Record<number, boolean> = {};
    this._games.forEach((game) => {
      gamesState[game.id] =
        this.checkedProvidersCount !== this.filteredProviders.length;
    });
    this._gamesState = gamesState;
  };

  handleBrandCheck = (brandId: number) => {
    this.setSelectedBrandId(brandId);
    const gamesState: Record<number, boolean> = {};
    const games = this._games.filter(
      (game) =>
        game.providerId === this._selectedProviderId &&
        game.brandId === this._selectedBrandId,
    );
    const isChecked = games.every((game) => this._gamesState[game.id]);
    games.forEach((game) => (gamesState[game.id] = !isChecked));
    this._gamesState = { ...this._gamesState, ...gamesState };
  };

  handleBrandCheckAll = () => {
    if (this._selectedProviderId) {
      this.handleProviderCheck(this._selectedProviderId);
    }
  };

  handleGameCheck = (gameId: number) => {
    this._gamesState[gameId] = !this._gamesState[gameId];
  };

  handleGameCheckAll = () => {
    if (this._selectedBrandId) {
      this.handleBrandCheck(this._selectedBrandId);
    }
  };

  handleGamesSave = async () => {
    const entries = Object.entries(this._gamesState);

    const enabledGames = entries
      .filter(([, isChecked]) => isChecked)
      .map(([id]) => +id);

    const disabledGames = entries
      .filter(([, isChecked]) => !isChecked)
      .map(([id]) => +id);

    await Promise.all([
      this.enableGamesQuery.submit({
        ...this.entity,
        ids: enabledGames,
      }),
      this.disableGamesQuery.submit({
        ...this.entity,
        ids: disabledGames,
      }),
    ]);

    if (
      !this.enableGamesQuery.isFulfilled ||
      !this.disableGamesQuery.isFulfilled
    ) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The game settings have been successfully updated.",
      }),
    });

    this.fetchGames();
  };
}
