import { observer } from "mobx-react-lite";

import { useImportPlayersFormState } from "./form.state";
import { ImportPlayersFormView } from "./form.view";

export type ImportPlayersFormProps = {
  hallId: number;
};

export const ImportPlayersForm = observer<ImportPlayersFormProps>((props) => {
  const state = useImportPlayersFormState(props);
  return <ImportPlayersFormView state={state} />;
});
