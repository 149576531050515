import { Tabs } from "antd";
import { FC } from "react";

import Page from "@/ui/_common_/page";

import WebsiteImagePacks from "./website-image-packs";
import WebsiteTemplates from "./website-templates";
import WebsitesDomains from "./websites-domains";

const Websites: FC = () => {
  return (
    <Page title={"Websites"}>
      <Tabs defaultActiveKey={"domains"}>
        <Tabs.TabPane tab={"Domains"} key="domains">
          <WebsitesDomains />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Templates"} key="templates">
          <WebsiteTemplates />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Image packs"} key="image-packs">
          <WebsiteImagePacks />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

export default Websites;
