import { TJackpot, TJackpotSlot } from "..";
import MethodBuilder from "../method-builder";

type TJackpotFilterOutput = {
  data: TJackpot[];
  total: number;
};

type TJackpotUpdateInput = (
  | { clientId: number }
  | { agentId: number }
  | { hallId: number }
) & {
  id: number;

  slots?: Omit<TJackpotSlot, "value">[];
  expectedMonthlyBetSum?: number;
  isPayoutEnabled?: boolean;
  isEnabled?: boolean;
};

class JackpotAPI {
  static filter = new MethodBuilder<
    | {
        clientId: number;
      }
    | {
        agentId: number;
      }
    | {
        hallId: number;
      },
    TJackpotFilterOutput
  >()
    .withName("Jackpot.filter")
    .build();

  static update = new MethodBuilder<TJackpotUpdateInput, void>()
    .withName("Jackpot.update")
    .build();

  static sync = new MethodBuilder<
    (
      | {
          clientId: number;
        }
      | {
          agentId: number;
        }
      | {
          hallId: number;
        }
    ) & {
      id: number;
    },
    void
  >()
    .withName("Jackpot.sync")
    .build();

  static reset = new MethodBuilder<
    {
      id: number;
      hallId: number;
      slotIds: number[];
    },
    void
  >()
    .withName("Jackpot.reset")
    .build();

  static grantAccess = new MethodBuilder<
    (
      | {
          clientId: number;
        }
      | {
          agentId: number;
        }
      | {
          hallId: number;
        }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Jackpot.grantAccess")
    .build();

  static revokeAccess = new MethodBuilder<
    (
      | {
          clientId: number;
        }
      | {
          agentId: number;
        }
      | {
          hallId: number;
        }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Jackpot.revokeAccess")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("Jackpot.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("Jackpot.export")
    .build();
}

export default JackpotAPI;
