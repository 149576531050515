import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Space, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { THistoryReportSession } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { HistoryReportState } from "./HistoryReportState";
import ActionsModal from "./actions";

function History() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new HistoryReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  return (
    <Page title={intl.formatMessage({ defaultMessage: "History report" })}>
      <ActionsModal store={state.actionsStore} />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.kioskSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select kiosk",
                  })}
                />
              </Col>
            </>
          )}
          <Col xs={24} md={8} lg={6} xl={4} style={{ marginLeft: "auto" }}>
            <Input
              placeholder={intl.formatMessage({ defaultMessage: "Enter ID" })}
              allowClear
              defaultValue={state.searchQuery}
              onChange={(event) => state.setSearchQuery(event.target.value)}
              suffix={<SearchOutlined />}
            />
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={state.providerSelectorStore}
              placeholder={intl.formatMessage({
                defaultMessage: "Select provider",
              })}
            />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={state.gameSelectorStore}
              placeholder={intl.formatMessage({
                defaultMessage: "Select game",
              })}
            />
          </Col>
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={
                !state.playerSelectorStore.selectedId &&
                !state.kioskSelectorStore.selectedId &&
                !state.searchQuery.length
              }
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              dataSource={state.filterQuery.items}
              showHeader={!!state.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              loading={state.filterQuery.isPending}
              pagination={false}
            >
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Session ID" })}
                dataIndex="id"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Player",
                })}
                dataIndex="playerName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Provider" })}
                dataIndex="providerName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Game" })}
                dataIndex="gameName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Start time" })}
                dataIndex="createdAt"
                render={(_, item: THistoryReportSession) =>
                  new Date(item.createdAt).toLocaleString()
                }
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Bet" })}
                dataIndex="bet"
                render={(_, item: THistoryReportSession) => (
                  <MoneyFormatter cents={item.bet} />
                )}
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Win" })}
                dataIndex="win"
                render={(_, item: THistoryReportSession) => (
                  <MoneyFormatter cents={item.win} />
                )}
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Revenue" })}
                render={(_, item: THistoryReportSession) => (
                  <MoneyFormatter cents={item.bet - item.win} />
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex="currency"
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Details" })}
                render={(item: THistoryReportSession) => (
                  <Button
                    size="small"
                    onClick={() => {
                      state.actionsStore.sessionId = item.id;
                      state.actionsStore.modal.open();
                    }}
                  >
                    {intl.formatMessage({ defaultMessage: "Actions" })}
                  </Button>
                )}
              />
            </Table>
            <Pagination
              query={state.filterQuery}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(History);
