import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { AgentWithdrawStore } from "@/stores";

import AgentWithdrawForm from "./agent-withdraw-form";

type TProps = {
  agentWithdrawStore: AgentWithdrawStore;
};

function AgentWithdrawModal({ agentWithdrawStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Withdraw balance" })}
      open={agentWithdrawStore.modal.isOpen}
      onCancel={agentWithdrawStore.modal.close}
      footer={null}
      destroyOnClose={true}
    >
      <AgentWithdrawForm agentWithdrawStore={agentWithdrawStore} />
    </Modal>
  );
}

export default observer(AgentWithdrawModal);
