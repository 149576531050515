import { QuestionCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  useCashierUpdateLimitSchema,
  TCashierUpdateLimitSchema,
} from "@/schemas";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { CurrencyUtilities } from "@/utilities";

import { FormViewModel } from "./form.vm";

type Props = {
  vm: FormViewModel;
};

export const FormView: FC<Props> = observer(({ vm }) => {
  const intl = useIntl();
  const schema = useCashierUpdateLimitSchema();
  const resolver = yupResolver(schema);
  const form = useForm<TCashierUpdateLimitSchema>({ resolver });

  useEffect(() => {
    form.setValue(
      "transactionsLimit",
      CurrencyUtilities.toMainUnits(vm.cashier?.transactionsLimit ?? 0),
    );
  }, [form, vm.cashier?.transactionsLimit]);

  if (!vm.cashier || !vm.hall) {
    return <FormSpinner />;
  }

  if (vm.getQuery.isRejected || vm.getHallQuery.isRejected) {
    return <ErrorsFormatter queries={[vm.getQuery, vm.getHallQuery]} />;
  }

  return (
    <Form
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={form.handleSubmit(vm.handleSubmit)}
    >
      <Form.Item label={intl.formatMessage({ defaultMessage: "Cashier" })}>
        <Input disabled value={vm.cashier.login} />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Sum" })}>
        <Input.Group compact style={{ display: "flex", flexDirection: "row" }}>
          <Input
            readOnly
            value={CurrencyUtilities.toMainUnits(
              vm.cashier?.transactionsSum ?? 0,
            )}
            suffix={vm.hall?.currency}
          />
          <Button
            icon={<UndoOutlined rotate={180} />}
            style={{ minWidth: 38 }}
            onClick={vm.handleReset}
            loading={vm.getQuery.isPending || vm.resetQuery.isPending}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ defaultMessage: "Limit" })}
        validateStatus={
          has(form.formState.errors, "transactionsLimit") ? "error" : ""
        }
        help={form.formState.errors.transactionsLimit?.message}
      >
        <Controller
          control={form.control}
          name="transactionsLimit"
          render={({ field }) => (
            <Input
              {...field}
              suffix={vm.hall?.currency}
              addonAfter={
                <Tooltip
                  title={intl.formatMessage({
                    defaultMessage: 'Use "0" to disable limit',
                  })}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              }
            />
          )}
        />
      </Form.Item>
      <Row justify="end" gutter={12}>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={vm.updateQuery.isPending}
          >
            {intl.formatMessage({ defaultMessage: "Save" })}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

FormView.displayName = "EditLimit.Form.View";
