import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useFormState } from "./form.state";
import { EditFormView } from "./form.view";

export type Entity = { clientId: number };

export type FormProps = {
  entity: Entity;
};

export const EditForm: FC<FormProps> = observer((props) => {
  const state = useFormState(props);
  return <EditFormView state={state} />;
});
