import { Space, Form, Row, Button, Col } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import { FormItem, FormSwitch, FormTextArea } from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";
import { AlternativePatch } from "../alternative-patch";

import { AgentLobbySettingsState } from "./agent-lobby-settings.state";

interface Props {
  state: AgentLobbySettingsState;
}

export const AgentLobbySettingsView = observer(({ state }: Props) => {
  const {
    agentId,
    intl,
    form,
    clientQuery,
    parentAgentQuery,
    agentQuery,
    isContactsShown,
    isAutoEnableGamesShown,
    submitQuery,
  } = state;

  if (
    (clientQuery.isIdle && parentAgentQuery.isIdle) ||
    clientQuery.isPending ||
    parentAgentQuery.isPending ||
    agentQuery.isIdle ||
    agentQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (
    clientQuery.isRejected ||
    parentAgentQuery.isRejected ||
    agentQuery.isRejected
  ) {
    return (
      <ErrorsFormatter queries={[clientQuery, parentAgentQuery, agentQuery]} />
    );
  }

  const agent = agentQuery.data;

  return (
    <Space direction="vertical">
      {agent.currency !== null && (
        <AlternativePatch entity={{ agentId }} currency={agent.currency} />
      )}
      {(isContactsShown || isAutoEnableGamesShown) && (
        <>
          <ErrorsFormatter queries={[submitQuery]} />
          <Form
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            onFinish={form.handleSubmit(submitQuery.submit)}
          >
            {isContactsShown && (
              <>
                <FormItem
                  form={form}
                  path="lobbySettings.isContactsShown"
                  label={intl.formatMessage({
                    defaultMessage: "Show contacts",
                  })}
                >
                  <FormSwitch
                    form={form}
                    path="lobbySettings.isContactsShown"
                  />
                </FormItem>
                <FormItem
                  form={form}
                  path="lobbySettings.contacts"
                  label={intl.formatMessage({ defaultMessage: "Contacts" })}
                >
                  <FormTextArea
                    form={form}
                    path="lobbySettings.contacts"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Enter contacts",
                    })}
                  />
                </FormItem>
              </>
            )}
            {isAutoEnableGamesShown && (
              <FormItem
                form={form}
                path="autoEnableGames"
                label={intl.formatMessage({
                  defaultMessage: "Automatically enable games",
                })}
              >
                <FormSwitch form={form} path="autoEnableGames" />
              </FormItem>
            )}
            <Row justify="end" gutter={12}>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitQuery.isPending}
                >
                  {intl.formatMessage({ defaultMessage: "Save" })}
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Space>
  );
});
