import MethodBuilder from "../method-builder";
import { TPaging } from "../types";

type TSportClub = {
  id: number;
  partnerId: number;
  description: string;
};

export class SportClubAPI {
  static getById = new MethodBuilder<{ id: number }, TSportClub>()
    .withName("SportClub.getById")
    .build();

  static create = new MethodBuilder<
    {
      id: number;
      partnerId: number;
      description: string;
    },
    void
  >()
    .withName("SportClub.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      description: string;
    },
    void
  >()
    .withName("SportClub.update")
    .build();

  static filter = new MethodBuilder<
    {
      partnerId?: number;
      // club id, partner id
      searchQuery?: string;
      paging: TPaging;
    },
    {
      data: TSportClub[];
      total: number;
    }
  >()
    .withName("SportClub.filter")
    .build();
}
