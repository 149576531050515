import { reaction } from "mobx";
import { useEffect } from "react";

import Query from "@/models/query";

export function useResetForm(query: Query, reset: () => void) {
  useEffect(() => {
    const dispose = reaction(
      () => query.isFulfilled,
      () => {
        if (query.isFulfilled) {
          reset();
        }
      },
    );
    return () => dispose();
  }, [query.isFulfilled, reset]);
}
