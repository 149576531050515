import yup from "@/yup";

export function useAgentLobbySettingsSchema() {
  return yup.object({
    lobbySettings: yup.object({
      isContactsShown: yup.boolean(),
      contacts: yup.string().nullable(),
    }),
    autoEnableGames: yup.boolean(),
  });
}

export type AgentLobbySettingsSchema = yup.InferType<
  ReturnType<typeof useAgentLobbySettingsSchema>
>;
