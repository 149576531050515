import { Grid, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { CloseKiosksSettings, OpenKiosksSettings } from "@/events";
import { useGlobalStore } from "@/stores";
import { isTruthy } from "@/utilities";

import { ErrorHandler } from "../_common_/error-handler";
import { EventBasedModal } from "../_common_/event-based-modal";
import { KioskAccess } from "../kiosk-access";

import { KiosksSettingsForm } from "./kiosks-settings-form";
import { ReceiptList } from "./receipt-list";

export const KiosksSettingsModal = observer(() => {
  const intl = useIntl();
  const { userStore } = useGlobalStore();
  const { role } = userStore.user;
  const breakpoint = Grid.useBreakpoint();
  const isGeneralTabShown = ["Admin", "Manager", "ClientAdmin"].includes(role);
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Kiosks Settings" })}
      openEvent={OpenKiosksSettings}
      closeEvent={CloseKiosksSettings}
      className="modal_with-tabs"
    >
      {({ clientId, hallId }) => (
        <Tabs
          destroyInactiveTabPane={true}
          items={[
            isGeneralTabShown && {
              key: "general",
              label: intl.formatMessage({ defaultMessage: "General" }),
              children: <KiosksSettingsForm hallId={hallId} />,
            },
            {
              key: "kiosk-access",
              label: intl.formatMessage({ defaultMessage: "Kiosk Access" }),
              children: (
                <KioskAccess.List clientId={clientId} hallId={hallId} />
              ),
            },
            {
              key: "receipts",
              label: intl.formatMessage({ defaultMessage: "Receipts" }),
              children: <ReceiptList hallId={hallId} />,
              className: breakpoint.xs ? "subtabs" : undefined,
            },
          ]
            .filter(isTruthy)
            .map((tab) => ({
              ...tab,
              children: <ErrorHandler>{tab.children}</ErrorHandler>,
            }))}
        />
      )}
    </EventBasedModal>
  );
});
