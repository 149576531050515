import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { HallAPI, KioskAPI } from "@/api";
import { KioskCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { AddKioskFormProps } from "./add-kiosk-form";
import {
  AddKioskFormSchema,
  useAddKioskFormSchema,
} from "./add-kiosk-form.schema";

export function useAddKioskFormState({ hallId }: AddKioskFormProps) {
  const intl = useIntl();

  const schema = useAddKioskFormSchema();
  const form = useForm<AddKioskFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "kiosk",
      isAlwaysAuthorized: "default",
      isPayoutAllowed: false,
    },
  });

  const hallQuery = useMemo(() => {
    const query = new Query(HallAPI.getById);
    query.submit({ id: hallId });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: AddKioskFormSchema) => {
      await KioskAPI.create({ hallId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The kiosk has been successfully created.",
        }),
      });

      eventBusService.publish(new KioskCreated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, form, hallQuery, submitQuery };
}

export type AddKioskFormState = ReturnType<typeof useAddKioskFormState>;
