import { Alert, Button } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { ReceiptEditor } from "@/ui/receipt-editor";

import { PosTemplateState } from "./pos-template.state";

interface Props {
  state: PosTemplateState;
}

export const PosTemplateView = observer<Props>(
  ({ state: { intl, templateQuery, submitQuery, deleteQuery } }) => {
    if (templateQuery.isRejected) {
      return <ErrorsFormatter queries={[templateQuery]} />;
    }

    if (!templateQuery.data) {
      return <FormSpinner />;
    }

    return (
      <ReceiptEditor
        defaultFontSize={16}
        scaleFontSize={(size) => size}
        lines={templateQuery.data.data}
        submitQuery={submitQuery}
        header={
          templateQuery.data.isInherited ? (
            <Alert
              type="warning"
              description={intl.formatMessage({
                defaultMessage: "The template is inherited from the parent",
              })}
            />
          ) : (
            <div>
              <Button
                onClick={() => deleteQuery.submit()}
                loading={deleteQuery.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Delete" })}
              </Button>
            </div>
          )
        }
      />
    );
  },
);
