import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { PermissionsAPI } from "@/api";
import Query from "@/models/query";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { Bonuses } from "@/ui/bonuses";
import { DemoGames } from "@/ui/demo-games";
import { Games } from "@/ui/games";
import { JackpotSettingsForm } from "@/ui/jackpots";
import { ClientLobbySettings } from "@/ui/lobby";
import { RulesTabs } from "@/ui/rules";
import { Sweepstakes } from "@/ui/sweepstakes";
import { Websites } from "@/ui/websites";
import { isTruthy } from "@/utilities";

import { GeneralForm } from "../general-form";
import { OtherForm } from "../other-form";

type Props = {
  clientId: number;
  setClientName(name: string): void;
};

export const EditClientTabs = observer<Props>(({ clientId, setClientName }) => {
  const intl = useIntl();

  const permissionsQuery = useMemo(() => {
    const query = new Query(PermissionsAPI.get);
    query.submit({ clientId });
    return query;
  }, [clientId]);

  if (permissionsQuery.isIdle || permissionsQuery.isPending) {
    return <FormSpinner />;
  }

  if (permissionsQuery.isRejected) {
    return <ErrorsFormatter queries={[permissionsQuery]} />;
  }

  return (
    <Tabs
      destroyInactiveTabPane={true}
      items={[
        {
          key: "general",
          label: intl.formatMessage({ defaultMessage: "General" }),
          children: (
            <GeneralForm clientId={clientId} setClientName={setClientName} />
          ),
        },
        {
          key: "lobby",
          label: intl.formatMessage({ defaultMessage: "Lobby" }),
          children: <ClientLobbySettings clientId={clientId} />,
        },
        {
          key: "websites",
          label: intl.formatMessage({ defaultMessage: "Websites" }),
          children: <Websites.EditForm entity={{ clientId }} />,
        },
        {
          key: "games",
          label: intl.formatMessage({ defaultMessage: "Games" }),
          className: "subtabs",
          children: (
            <Tabs
              destroyInactiveTabPane={true}
              items={[
                {
                  key: "games",
                  label: intl.formatMessage({ defaultMessage: "Games" }),
                  children: <Games.EditForm entity={{ clientId }} />,
                },
                {
                  key: "guest-games",
                  label: intl.formatMessage({ defaultMessage: "Guest games" }),
                  children: <DemoGames.EditForm entity={{ clientId }} />,
                },
              ]}
            />
          ),
        },
        {
          key: "bonuses",
          label: intl.formatMessage({ defaultMessage: "Bonuses" }),
          className: "subtabs",
          children: <Bonuses.Tabs entity={{ clientId }} />,
        },
        permissionsQuery.data.jackpots && {
          key: "jackpots",
          label: intl.formatMessage({ defaultMessage: "Jackpots" }),
          children: <JackpotSettingsForm entity={{ clientId }} />,
        },
        permissionsQuery.data.sweepstakes && {
          key: "sweepstakes",
          label: intl.formatMessage({ defaultMessage: "Sweepstakes" }),
          children: <Sweepstakes.EditForm entity={{ clientId }} />,
        },
        {
          key: "other",
          label: intl.formatMessage({ defaultMessage: "Other" }),
          children: <OtherForm clientId={clientId} />,
        },
        {
          key: "rules-and-terms",
          className: "subtabs",
          label: intl.formatMessage({ defaultMessage: "Rules and Terms" }),
          children: <RulesTabs entity={{ clientId }} />,
        },
      ].filter(isTruthy)}
    />
  );
});
