import { notification } from "antd";
import { isAfter, differenceInHours } from "date-fns";
import { makeAutoObservable } from "mobx";

import { BonusAPI, HallAPI, PlayerAPI } from "@/api";
import { PlayerBalanceDeposited } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { UserStore } from "@/stores";
import { CurrencyUtilities } from "@/utilities";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    private userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hallQuery = new Query(HallAPI.getById);

  get hall() {
    return this.hallQuery.data!;
  }

  playerQuery = new Query(PlayerAPI.getById);

  get player() {
    return this.playerQuery.data!;
  }

  bonusesQuery = new Query(BonusAPI.filter);

  get bonuses() {
    return this.bonusesQuery.data!.data;
  }

  private _kioskId?: number;

  private get kioskId() {
    return this._kioskId;
  }

  private set kioskId(id: number | undefined) {
    this._kioskId = id;
  }

  get availableBalance() {
    if (!this.hall) {
      return null;
    }
    if (this.hall.isBalanceUnlimited) {
      return null;
    }
    return this.hall.balance;
  }

  get notAvailableWarning() {
    const bonus = this.player.bonus;

    if (bonus === null) {
      return null;
    }

    if (bonus.count > 0) {
      return this.intlService.intl.formatMessage({
        defaultMessage: "The player already has an activated bonus.",
      });
    }

    if (
      bonus.lastIssuedAt !== null &&
      isAfter(new Date(bonus.lastIssuedAt), new Date())
    ) {
      const hours = differenceInHours(new Date(bonus.lastIssuedAt), new Date());
      return this.intlService.intl.formatMessage(
        {
          defaultMessage:
            "You can only issue a bonus after {hours} hours. The cooldown is not expired yet.",
        },
        { hours },
      );
    }

    return null;
  }

  onViewMount = async ({ playerId, kioskId }: Props) => {
    this.kioskId = kioskId;
    this.fetchTask.submit({ playerId });
  };

  onViewUnmount = () => {
    this.kioskId = undefined;
    this.hallQuery.reset();
    this.playerQuery.reset();
    this.bonusesQuery.reset();
  };

  fetchTask = new Query(async ({ playerId }: { playerId: number }) => {
    const player = await PlayerAPI.getById({ id: playerId });

    this.playerQuery.resolve({ id: playerId }, player);

    await Promise.all([
      this.hallQuery.submit({ id: player.hallId }),
      this.bonusesQuery.submit({ hallId: player.hallId }),
    ]);
  });

  submitTask = new Query(async ({ amount, bonusId }: ISchema) => {
    await PlayerAPI.depositMoney({
      id: this.player.id,
      kioskId: this.kioskId,
      bonusId,
      amount: CurrencyUtilities.toMinorUnits(amount),
    });

    const promises = [
      this.userStore.hall && this.userStore.fetchHall(this.userStore.hall.id),
      this.userStore.cashier &&
        this.userStore.fetchCashier(this.userStore.cashier.id),
    ].filter(Boolean);

    await Promise.all(promises);

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage:
          "The money has been successfully deposited to the balance.",
      }),
    });

    this.eventBusService.publish(
      new PlayerBalanceDeposited({ playerId: this.player.id }),
    );
  });
}
