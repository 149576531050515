import { Button, Col, Form, Row, Space, Switch } from "antd";
import { observer } from "mobx-react-lite";

import { ConfiguredByParentAlert } from "@/ui/_common_/configured-by-parent-alert";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import {
  FormErrors,
  FormInput,
  FormItem,
  FormSwitch,
} from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { TFortuneWheelSettingsState } from "./fortune-wheel-settings.state";

export interface IFortuneWheelSettingsViewProps {
  state: TFortuneWheelSettingsState;
}

export const FortuneWheelSettingsView = observer(
  ({ state }: IFortuneWheelSettingsViewProps) => {
    const {
      intl,
      readOnly,
      fortuneWheelQuery,
      toggleAccessQuery,
      form,
      submitQuery,
    } = state;

    if (fortuneWheelQuery.isIdle || fortuneWheelQuery.isPending) {
      return <FormSpinner />;
    }

    if (fortuneWheelQuery.isRejected) {
      return <ErrorsFormatter queries={[fortuneWheelQuery]} />;
    }

    const fortuneWheel = fortuneWheelQuery.data;

    return (
      <Space direction="vertical">
        <ConfiguredByParentAlert />
        <ErrorsFormatter
          queries={[state.toggleAccessQuery, state.submitQuery]}
        />
        {!state.selfEdit && (
          <Form
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            layout="horizontal"
          >
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Has access" })}
            >
              <Switch
                loading={toggleAccessQuery.isPending}
                checked={fortuneWheel !== null}
                onChange={toggleAccessQuery.submit}
              />
            </Form.Item>
          </Form>
        )}
        {fortuneWheel && (
          <Form
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            onFinish={form.handleSubmit(submitQuery.submit)}
          >
            <FormItem
              form={form}
              path="isEnabled"
              label={intl.formatMessage({ defaultMessage: "Enabled" })}
            >
              <FormSwitch form={form} path="isEnabled" disabled={readOnly} />
            </FormItem>
            <FormItem
              form={form}
              path="cooldown"
              label={intl.formatMessage({ defaultMessage: "Cooldown" })}
            >
              <FormInput form={form} path="cooldown" disabled={readOnly} />
            </FormItem>
            <FormItem
              form={form}
              path="maxDepositAge"
              label={intl.formatMessage({
                defaultMessage: "How long has deposit been",
              })}
            >
              <FormInput form={form} path="maxDepositAge" disabled={readOnly} />
            </FormItem>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Slots" })}
              colon={false}
            >
              <Row gutter={[8, 8]}>
                <Col span={12} style={{ textAlign: "center" }}>
                  {intl.formatMessage({ defaultMessage: "Amount" })}
                </Col>
                <Col span={12} style={{ textAlign: "center" }}>
                  {intl.formatMessage({ defaultMessage: "Wins in month" })}
                </Col>
              </Row>
            </Form.Item>
            {fortuneWheel.sectors.map((sector, index) => (
              <Form.Item
                key={sector.id}
                label={sector.id}
                help={
                  <FormErrors
                    form={form}
                    paths={[
                      `sectors.${index}.value`,
                      `sectors.${index}.winFrequency`,
                    ]}
                  />
                }
              >
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <FormInput
                      form={form}
                      path={`sectors.${index}.value`}
                      disabled={readOnly}
                    />
                  </Col>
                  <Col span={12}>
                    <FormInput
                      form={form}
                      path={`sectors.${index}.winFrequency`}
                      disabled={readOnly}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            {!readOnly && (
              <Row justify="end" gutter={12}>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitQuery.isPending}
                  >
                    {intl.formatMessage({ defaultMessage: "Save" })}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Space>
    );
  },
);
