import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { ClientsStore } from "@/stores";

import ClientCreateForm from "./client-create-form";

type TProps = {
  clientsStore: ClientsStore;
};

function ClientCreateModal({ clientsStore }: TProps) {
  const { clientCreateStore } = clientsStore;

  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "New client" })}
      open={clientCreateStore.modal.isOpen}
      onCancel={clientCreateStore.modal.close}
      width={720}
      footer={null}
      destroyOnClose={true}
    >
      <ClientCreateForm clientsStore={clientsStore} />
    </Modal>
  );
}

export default observer(ClientCreateModal);
