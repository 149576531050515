import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI, ClientAPI, HallAPI } from "@/api";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { PermissionsStore } from "@/stores";

import { Entity, Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public permissionsStore: PermissionsStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _entity?: Entity;

  get entity() {
    return this._entity!;
  }

  private set entity(value: Entity | undefined) {
    this._entity = value;

    this.fetch();
  }

  private _readOnly = false;

  get readOnly() {
    return this._readOnly;
  }

  private set readOnly(value: boolean) {
    this._readOnly = value;
  }

  clientQuery = new Query(ClientAPI.getById);
  parentAgentQuery = new Query(AgentAPI.getById);
  agentQuery = new Query(AgentAPI.getById);
  hallQuery = new Query(HallAPI.getById);

  onViewMount = async (props: Props) => {
    this.entity = props.entity;
    this.readOnly = props.readOnly ?? false;
  };

  onViewUpdate = async (props: Props) => {
    this.entity = props.entity;
    this.readOnly = props.readOnly ?? false;
  };

  onViewUnmount = () => {
    this.entity = undefined;
    this.readOnly = false;
  };

  fetch = async () => {
    if (!this.entity) {
      return;
    }

    if ("clientId" in this.entity) {
      await this.clientQuery.submit({ id: this.entity.clientId });
    } else if ("agentId" in this.entity) {
      await this.agentQuery.submit({ id: this.entity.agentId });
    } else if ("hallId" in this.entity) {
      await this.hallQuery.submit({ id: this.entity.hallId });
    }

    if ("agentId" in this.entity) {
      if (!this.agentQuery.isFulfilled) {
        return;
      }
      const agent = this.agentQuery.data;
      if (agent.parentAgentId) {
        await this.parentAgentQuery.submit({ id: agent.id });
      } else {
        await this.clientQuery.submit({ id: agent.clientId });
      }
    } else if ("hallId" in this.entity) {
      if (!this.hallQuery.isFulfilled) {
        return;
      }
      const hall = this.hallQuery.data;
      if (hall.agentId) {
        await this.agentQuery.submit({ id: hall.agentId });
      } else {
        await this.clientQuery.submit({ id: hall.clientId });
      }
    }
  };

  get isIdleOrPending() {
    if (!this.entity) {
      return true;
    }

    if ("clientId" in this.entity) {
      return this.clientQuery.isIdle || this.clientQuery.isPending;
    } else if ("agentId" in this.entity) {
      if (!this.agentQuery.isFulfilled) {
        return this.agentQuery.isIdle || this.agentQuery.isPending;
      }
      const agent = this.agentQuery.data;
      if (agent.parentAgentId) {
        return this.parentAgentQuery.isIdle || this.parentAgentQuery.isPending;
      } else {
        return this.clientQuery.isIdle || this.clientQuery.isPending;
      }
    } else if ("hallId" in this.entity) {
      if (!this.hallQuery.isFulfilled) {
        return this.hallQuery.isIdle || this.hallQuery.isPending;
      }
      const hall = this.hallQuery.data;
      if (hall.agentId) {
        return this.agentQuery.isIdle || this.agentQuery.isPending;
      } else {
        return this.clientQuery.isIdle || this.clientQuery.isPending;
      }
    }
  }

  get isRejected() {
    return (
      this.clientQuery.isRejected ||
      this.agentQuery.isRejected ||
      this.parentAgentQuery.isRejected ||
      this.hallQuery.isRejected
    );
  }

  get hasAccess() {
    if (!this.entity) {
      return false;
    }

    if ("clientId" in this.entity) {
      return true;
    }

    if ("agentId" in this.entity) {
      if (!this.agentQuery.isFulfilled) {
        return;
      }
      const agent = this.agentQuery.data;
      if (agent.parentAgentId) {
        return this.parentAgentQuery.data?.lobbySettings?.isContactsShown;
      }
      return this.clientQuery.data?.lobbySettings?.isContactsShown;
    }

    if ("hallId" in this.entity) {
      if (!this.hallQuery.isFulfilled) {
        return;
      }
      const hall = this.hallQuery.data;
      if (hall.agentId) {
        return this.agentQuery.data?.lobbySettings?.isContactsShown;
      }
      return this.clientQuery.data?.lobbySettings?.isContactsShown;
    }

    return false;
  }

  get defaultIsContactsShown() {
    if (!this.entity) {
      return false;
    }

    if ("clientId" in this.entity) {
      return this.clientQuery.data?.lobbySettings?.isContactsShown;
    }

    if ("agentId" in this.entity) {
      return this.agentQuery.data?.lobbySettings?.isContactsShown;
    }

    if ("hallId" in this.entity) {
      return this.hallQuery.data?.lobbySettings?.isContactsShown;
    }

    return false;
  }

  get defaultContacts() {
    if (!this.entity) {
      return "";
    }

    if ("clientId" in this.entity) {
      return this.clientQuery.data?.contacts;
    }

    if ("agentId" in this.entity) {
      return this.agentQuery.data?.lobbySettings?.contacts;
    }

    if ("hallId" in this.entity) {
      return this.hallQuery.data?.lobbySettings?.contacts;
    }

    return "";
  }

  updateQuery = new Query(async (values: ISchema) => {
    if (!this.entity) {
      return;
    }

    if ("clientId" in this.entity && this.clientQuery.isFulfilled) {
      await ClientAPI.update({
        id: this.entity.clientId,
        contacts: values.contacts,
        lobbySettings: {
          isContactsShown: values.isContactsShown,
        },
      });
    } else if ("agentId" in this.entity && this.agentQuery.isFulfilled) {
      await AgentAPI.update({
        id: this.entity.agentId,
        lobbySettings: values,
      });
    } else if ("hallId" in this.entity && this.hallQuery.isFulfilled) {
      await HallAPI.update({
        id: this.entity.hallId,
        lobbySettings: values,
      });
    }
  });

  handleSubmit = async (values: ISchema) => {
    await this.updateQuery.submit(values);

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The contact settings have been successfully updated.",
      }),
    });
  };
}
