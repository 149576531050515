import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { CloseEditCategoryModal, OpenEditCategoryModal } from "@/events";
import { useGlobalStore } from "@/stores";

export function useEditCategoryModalState() {
  const intl = useIntl();
  const [opened, setOpened] = useState(false);
  const [id, setId] = useState<number>();

  const { eventBusService } = useGlobalStore();

  useEffect(() => {
    const listener = (event: OpenEditCategoryModal) => {
      setOpened(true);
      setId(event.payload.id);
    };

    eventBusService.subscribe(OpenEditCategoryModal, listener);

    return () => {
      eventBusService.unsubscribe(OpenEditCategoryModal, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function close() {
    setOpened(false);
    setId(undefined);
    eventBusService.publish(new CloseEditCategoryModal({}));
  }

  return { intl, opened, close, id };
}

export type EditCategoryModalState = ReturnType<
  typeof useEditCategoryModalState
>;
