import { Alert } from "antd";
import { useContext } from "react";
import { useIntl } from "react-intl";

import { ConfiguredByParentContext } from "./context";

export function ConfiguredByParentAlert() {
  const intl = useIntl();

  const value = useContext(ConfiguredByParentContext);

  if (!value) {
    return null;
  }

  return (
    <Alert
      type="warning"
      message={intl.formatMessage({
        defaultMessage: "Read only: Configured by the parent",
      })}
    />
  );
}
