import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { SearchParamKey } from "@/constants";

export function useEntitiesSearchParams() {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    for (const key of searchParams.keys()) {
      if (
        ![
          SearchParamKey.ClientId as string,
          SearchParamKey.AgentId as string,
          SearchParamKey.HallId as string,
          SearchParamKey.PlayerId as string,
        ].includes(key)
      ) {
        searchParams.delete(key);
      }
    }
    return searchParams.toString();
  }, [location]);
}
