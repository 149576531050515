import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { KioskAPI, TKiosk } from "@/api";
import Query from "@/models/query";
import { IntlService } from "@/services";

import { KiosksStore } from "./kiosks-store";

export class KioskStore {
  constructor(
    private _kiosk: TKiosk,
    public readonly kiosksStore: KiosksStore,
    public readonly intlService: IntlService,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.intlService.intl;
  }

  get kiosk() {
    return this._kiosk;
  }

  private setKiosk = (kiosk: TKiosk) => {
    this._kiosk = kiosk;
  };

  fetchQuery = new Query(KioskAPI.getById);
  updateQuery = new Query(KioskAPI.update);

  fetch = async () => {
    await this.fetchQuery.submit({
      id: this.kiosk.id,
    });

    if (!this.fetchQuery.isFulfilled) {
      return;
    }

    const kiosk = this.fetchQuery.data;
    this.setKiosk(kiosk);
  };

  togglePanicQuery = new Query<void, void>(async () => {
    const isPanicEnabled = !this.kiosk.isPanicEnabled;

    await KioskAPI.update({
      id: this.kiosk.id,
      isPanicEnabled,
    });

    await new Promise((resolve) => setTimeout(resolve, 3000));

    await this.fetch();

    if (isPanicEnabled) {
      notification.success({
        message: "",
        description: this.intl.formatMessage({
          defaultMessage: "The panic mode has been successfully enabled.",
        }),
      });
    } else {
      notification.success({
        message: "",
        description: this.intl.formatMessage({
          defaultMessage: "The panic mode has been successfully disabled.",
        }),
      });
    }
  });
}
