import yup from "@/yup";

export function usePlayerCreateFormSchema() {
  return yup.object({
    login: yup.string().min(3).required(),
    name: yup.string().min(6).max(255),
    email: yup.string().email().min(6).max(255),
    phone: yup.string().min(6).max(16),
    password: yup.string().min(6).required(),
    balance: yup.number().money().min(0).required(),
    bonusId: yup.number().integer(),
  });
}
export type PlayerCreateFormSchema = yup.InferType<
  ReturnType<typeof usePlayerCreateFormSchema>
>;
