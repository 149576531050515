import { makeAutoObservable } from "mobx";

import { HallAPI } from "@/api";
import { HallUpdated } from "@/events";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";

import { GeneralSelectorsStore, GlobalStore, HallStore } from "..";

import { HallCreateStore } from "./hall-create-store";

export class HallsStore {
  constructor(public readonly globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    const { searchParams } = this.routerService;

    if (searchParams["action"] === "new") {
      this.hallCreateStore.modal.open();
    }

    this.generalSelectorsStore = new GeneralSelectorsStore(
      this.globalStore,
      this.filter,
      true,
      true,
      true,
      false,
    );

    this.filter();

    this.eventBusService.subscribe(HallUpdated, (event) => {
      const { hallId } = event.payload;
      const hallStore = this.hallStores.find(
        (hallStore) => hallStore.hall.id === hallId,
      );
      hallStore?.fetch();
    });
  }

  get intlService() {
    return this.globalStore.intlService;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get eventBusService() {
    return this.globalStore.eventBusService;
  }

  public readonly generalSelectorsStore: GeneralSelectorsStore;

  hallCreateStore = new HallCreateStore(this);

  private _hallStores: HallStore[] = [];

  get hallStores() {
    return this._hallStores;
  }

  setHallStores = (hallStores: HallStore[]) => {
    this._hallStores = hallStores;
  };

  filterQuery = new ItemsQuery(HallAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
    initialOrder: ["id", "desc"],
  });

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
    this.filter();
  }

  private _currencyCode?: string;

  get currencyCode() {
    return this._currencyCode;
  }

  setCurrencyCode = (currencyCode?: string) => {
    this._currencyCode = currencyCode;
  };

  filter = async (options?: TSubmitOptions) => {
    if (!this.generalSelectorsStore.clientSelectorStore.selectedId) {
      this.setHallStores([]);

      return;
    }
    const includeDeletedParams = this.permissionsStore.has("ViewDeletedHalls")
      ? { includeDeleted: this.includeDeleted }
      : {};
    const commonParams = {
      currency: this._currencyCode,
      ...includeDeletedParams,
    };
    const clientId = this.generalSelectorsStore.clientSelectorStore.selectedId;
    const agentId =
      this.generalSelectorsStore.subagentSelectorStore.selectedId ??
      this.generalSelectorsStore.agentSelectorStore.selectedId;
    const params = agentId
      ? { agentId, ...commonParams }
      : { clientId, ...commonParams };

    await this.filterQuery.submit(params, options);

    if (!this.filterQuery.isFulfilled) {
      return;
    }

    const { items } = this.filterQuery;
    const hallStores = items.map(
      (hall) => new HallStore(hall, this, this.intlService),
    );
    this.setHallStores(hallStores);
  };
}
