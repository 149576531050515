import { TPaging, TSportReportAggregatedItem, TSportReportItem } from "../..";
import MethodBuilder from "../../method-builder";

export class SportReportAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
      | { kioskId: number }
      | { uid: string }
    ) & {
      paging: TPaging;
      currency?: string;
      // enum('outcome', 'cancelled', 'created', 'cashout')
      status?: string;
      isPayed?: boolean;
      isOnlyBonus?: boolean;
      isOnlyWin?: boolean;
      isOnlyLose?: boolean;
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
    },
    {
      data: TSportReportItem[];
      total: number;
    }
  >()
    .withName("SportReport.filter")
    .build();

  static aggregate = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
      | { kioskId: number }
    ) & {
      groupBy: "currency" | "hall" | "player";
      currency?: string;
      // enum('outcome', 'cancelled', 'created', 'cashout')
      status?: string;
      // enum('win', 'lost', 'cancelled')
      outcomeStatus?: string;
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      paging: TPaging;
    },
    {
      data: Array<TSportReportAggregatedItem>;
      total: number;
    }
  >()
    .withName("SportReport.aggregate")
    .build();
}
