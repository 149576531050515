import yup from "@/yup";

export function useNewPartnerFormSchema() {
  return yup.object({
    id: yup.number().integer().required(),
    currency: yup.string().max(3).default("").required(),
    description: yup.string().min(3).default("").required(),
  });
}

export type NewPartnerFormSchema = yup.InferType<
  ReturnType<typeof useNewPartnerFormSchema>
>;
