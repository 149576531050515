import { TReceipt, TReceiptLine } from "..";
import MethodBuilder from "../method-builder";

export class ReceiptAPI {
  static update = new MethodBuilder<
    {
      hallId: number;
      type: "withdrawal" | "deposit" | "deposit_fail" | "player_registration";
      lines: TReceiptLine[];
    },
    void
  >()
    .withName("Receipt.update")
    .build();

  static getById = new MethodBuilder<
    {
      hallId: number;
      type: "withdrawal" | "deposit" | "deposit_fail" | "player_registration";
    },
    TReceipt
  >()
    .withName("Receipt.getById")
    .build();

  static getList = new MethodBuilder<{ hallId: number }, { data: TReceipt[] }>()
    .withName("Receipt.getList")
    .build();
}
