import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { GamesOrderState } from "./GamesOrderState";
import { GamesOrderView } from "./GamesOrderView";

export type Props = {};

export const GamesOrder: FC<Props> = observer((props) => {
  const { permissionsStore, routerService, eventBusService, userStore } =
    useGlobalStore();
  const state = useViewModel(
    () =>
      new GamesOrderState(
        permissionsStore,
        routerService,
        eventBusService,
        userStore,
      ),
    props,
  );
  return <GamesOrderView state={state} />;
});
