import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { ContentState } from "./ContentState";
import { ContentView } from "./ContentView";

type Props = {};

export const Content: FC<Props> = observer((props) => {
  const { eventBusService, permissionsStore } = useGlobalStore();
  const state = useViewModel(
    () => new ContentState(eventBusService, permissionsStore),
    props,
  );
  return <ContentView state={state} />;
});
