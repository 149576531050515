import { makeAutoObservable } from "mobx";

import { AgentAPI, ClientAPI, HallAPI } from "@/api";
import { SearchParamKey } from "@/constants";
import { parseInteger } from "@/utilities";

import { GlobalStore } from "../global";

import { SelectorStore } from ".";

export class GeneralSelectorsStore {
  constructor(
    private readonly globalStore: GlobalStore,
    private readonly fetch: () => void,
    private readonly isClientSelectorEnabled = true,
    private readonly isAgentSelectorEnabled = true,
    private readonly isSubagentSelectorEnabled = true,
    private readonly isHallSelectorEnabled = true,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    const { user, client, agent, hall } = this.userStore;

    if (hall) {
      this.clientSelectorStore.setSelectedId(hall.clientId, false);
      this.agentSelectorStore.setSelectedId(hall.agentId ?? undefined, false);
      this.hallSelectorStore.setSelectedId(hall.id, false);
    } else if (agent) {
      this.clientSelectorStore.setSelectedId(agent.clientId, false);
      this.agentSelectorStore.setSelectedId(agent.id, false);
      this.subagentSelectorStore.setParameters({
        parentAgentId: agent.id,
      });
      this.hallSelectorStore.setParameters({
        agentId: agent.id,
      });
    } else if (client) {
      this.clientSelectorStore.setSelectedId(client.id, false);
      this.agentSelectorStore.setParameters({
        clientId: client.id,
      });
      this.hallSelectorStore.setParameters({
        clientId: client.id,
      });
    }

    const { searchParams } = this.routerService;

    const initialClientId = parseInteger(searchParams[SearchParamKey.ClientId]);
    const initialAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.AgentId])
        : undefined;
    const initialHallId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.HallId])
        : undefined;

    if (this.permissionsStore.has("SelectClient")) {
      this.clientSelectorStore.setParameters({
        managerId: user.role === "Manager" ? user.id : undefined,
      });
      this.clientSelectorStore.setSelectedId(initialClientId);
    }

    if (this.permissionsStore.has("SelectAgent")) {
      if (this.clientSelectorStore.selectedId) {
        this.agentSelectorStore.setParameters({
          clientId: this.clientSelectorStore.selectedId,
        });
      }
      this.agentSelectorStore.setSelectedId(initialAgentId);
    }

    if (this.permissionsStore.has("SelectSubagent")) {
      if (this.agentSelectorStore.selectedId) {
        this.subagentSelectorStore.setParameters({
          parentAgentId: this.agentSelectorStore.selectedId,
        });
      } else if (this.clientSelectorStore.selectedId) {
        this.subagentSelectorStore.setParameters({
          parentAgentId: this.clientSelectorStore.selectedId,
        });
      }
      if (agent?.id !== initialAgentId) {
        this.subagentSelectorStore.setSelectedId(initialAgentId);
      }
    }

    if (this.permissionsStore.has("SelectHall")) {
      if (this.subagentSelectorStore.selectedId) {
        this.hallSelectorStore.setParameters({
          agentId: this.subagentSelectorStore.selectedId,
        });
      } else if (this.agentSelectorStore.selectedId) {
        this.hallSelectorStore.setParameters({
          agentId: this.agentSelectorStore.selectedId,
        });
      } else if (this.clientSelectorStore.selectedId) {
        this.hallSelectorStore.setParameters({
          clientId: this.clientSelectorStore.selectedId,
        });
      }
      this.hallSelectorStore.setSelectedId(initialHallId);
    }

    this.includeDeleted();

    if (
      this.clientSelectorStore.parameters &&
      isClientSelectorEnabled &&
      this.permissionsStore.has("SelectClient")
    ) {
      this.clientSelectorStore.fetchItems();
    }

    if (
      this.agentSelectorStore.parameters &&
      isAgentSelectorEnabled &&
      this.permissionsStore.has("SelectAgent")
    ) {
      this.agentSelectorStore.fetchItems();
    }

    if (
      this.subagentSelectorStore.parameters &&
      isSubagentSelectorEnabled &&
      this.permissionsStore.has("SelectSubagent")
    ) {
      this.subagentSelectorStore.fetchItems();
    }

    if (
      this.hallSelectorStore.parameters &&
      isHallSelectorEnabled &&
      this.permissionsStore.has("SelectHall")
    ) {
      this.hallSelectorStore.fetchItems();
    }

    setTimeout(() => this.updateSearchParams(), 0);
  }

  private get routerService() {
    return this.globalStore.routerService;
  }

  private get userStore() {
    return this.globalStore.userStore;
  }

  private get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  private handleClientSelect = () => {
    const clientId = this.clientSelectorStore.selectedId;
    if (clientId) {
      this.agentSelectorStore.setParameters({
        clientId,
      });
      this.hallSelectorStore.setParameters({
        clientId,
      });

      this.includeDeleted();

      if (this.isAgentSelectorEnabled) {
        this.agentSelectorStore.fetchItems();
      }
      if (this.isHallSelectorEnabled) {
        this.hallSelectorStore.fetchItems();
      }
    }
    this.agentSelectorStore.setSelectedId();
    this.subagentSelectorStore.setSelectedId();
    this.hallSelectorStore.setSelectedId();
    this.fetch();

    this.includeDeleted();
    this.updateSearchParams();
  };

  private handleAgentSelect = () => {
    const clientId = this.clientSelectorStore.selectedId;
    const agentId = this.agentSelectorStore.selectedId;
    if (agentId) {
      this.hallSelectorStore.setParameters({
        agentId,
      });
    } else if (clientId) {
      this.hallSelectorStore.setParameters({
        clientId,
      });
    }
    this.includeDeleted();
    if (this.hallSelectorStore.parameters && this.isHallSelectorEnabled) {
      this.hallSelectorStore.fetchItems();
    }
    this.subagentSelectorStore.setSelectedId();
    this.hallSelectorStore.setSelectedId();
    this.fetch();

    this.updateSearchParams();
  };

  private handleSubagentSelect = () => {
    const clientId = this.clientSelectorStore.selectedId;
    const agentId = this.agentSelectorStore.selectedId;
    const subagentId = this.subagentSelectorStore.selectedId;
    if (subagentId) {
      this.hallSelectorStore.setParameters({
        agentId: subagentId,
      });
    } else if (agentId) {
      this.hallSelectorStore.setParameters({
        agentId,
      });
    } else if (clientId) {
      this.hallSelectorStore.setParameters({
        clientId,
      });
    }
    this.includeDeleted();
    if (this.hallSelectorStore.parameters && this.isHallSelectorEnabled) {
      this.hallSelectorStore.fetchItems();
    }
    this.hallSelectorStore.setSelectedId();
    this.fetch();

    this.updateSearchParams();
  };

  private handleHallSelect = () => {
    this.fetch();

    this.updateSearchParams();
  };

  public readonly clientSelectorStore = new SelectorStore({
    filterMethod: ClientAPI.filter,
    getByIdMethod: ClientAPI.getById,
    labelKey: "name",
    onSelect: this.handleClientSelect,
  });

  public readonly agentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleAgentSelect,
  });

  public readonly subagentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleSubagentSelect,
  });

  public readonly hallSelectorStore = new SelectorStore({
    filterMethod: HallAPI.filter,
    getByIdMethod: HallAPI.getById,
    labelKey: "name",
    onSelect: this.handleHallSelect,
  });

  get isReady() {
    if (
      this.isClientSelectorEnabled &&
      this.permissionsStore.has("SelectClient") &&
      this.clientSelectorStore.selectedId &&
      !this.clientSelectorStore.selectedItem
    ) {
      return false;
    }
    if (
      this.isAgentSelectorEnabled &&
      this.permissionsStore.has("SelectAgent") &&
      this.agentSelectorStore.selectedId &&
      !this.agentSelectorStore.selectedItem
    ) {
      return false;
    }
    if (
      this.isSubagentSelectorEnabled &&
      this.permissionsStore.has("SelectSubagent") &&
      this.subagentSelectorStore.selectedId &&
      !this.subagentSelectorStore.selectedItem
    ) {
      return false;
    }
    return !(
      this.isHallSelectorEnabled &&
      this.permissionsStore.has("SelectHall") &&
      this.hallSelectorStore.selectedId &&
      !this.hallSelectorStore.selectedItem
    );
  }

  private includeDeleted() {
    if (this.permissionsStore.has("ViewDeletedAgents")) {
      if (this.clientSelectorStore.parameters) {
        this.clientSelectorStore.parameters["includeDeleted"] = true;
      }
      if (this.agentSelectorStore.parameters) {
        this.agentSelectorStore.parameters["includeDeleted"] = true;
      }
      if (this.subagentSelectorStore.parameters) {
        this.subagentSelectorStore.parameters["includeDeleted"] = true;
      }
    }
    if (this.permissionsStore.has("ViewDeletedHalls")) {
      if (this.hallSelectorStore.parameters) {
        this.hallSelectorStore.parameters["includeDeleted"] = true;
      }
    }
  }

  updateSearchParams() {
    const urlSearchParams = new URLSearchParams(
      this.routerService.location.search,
    );

    if (this.clientSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ClientId,
        this.clientSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ClientId);
    }

    if (this.subagentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.subagentSelectorStore.selectedId.toString(),
      );
    } else if (this.agentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.agentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.AgentId);
    }

    if (this.hallSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.HallId,
        this.hallSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.HallId);
    }

    this.routerService.replace({
      search: urlSearchParams.toString(),
    });
  }
}
