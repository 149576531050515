import MethodBuilder from "../../method-builder";
import {
  THistoryReportAction,
  THistoryReportSession,
  TPaging,
} from "../../types";

export class HistoryReportAPI {
  static filterSessions = new MethodBuilder<
    (
      | { playerId: number }
      | { kioskId: number }
      // поиск по id
      | { searchQuery: string }
    ) &
      ({ providerId?: number } | { gameId?: number }) & {
        dateTimeRange: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      },
    {
      data: THistoryReportSession[];
      total: number;
    }
  >()
    .withName("HistoryReport.filterSessions")
    .build();

  static filterActions = new MethodBuilder<
    (
      | { playerId: number }
      | { kioskId: number }
      // поиск по id, external id, round id
      | { searchQuery: string }
    ) &
      ({ providerId?: number } | { gameId?: number }) & {
        sessionId: string;
        dateTimeRange: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      },
    {
      data: THistoryReportAction[];
      total: number;
    }
  >()
    .withName("HistoryReport.filterActions")
    .build();

  static getActionDetails = new MethodBuilder<{ id: number }, string>()
    .withName("HistoryReport.getActionDetails")
    .build();
}
