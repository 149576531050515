import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { KioskAccessAPI } from "@/api";
import {
  OpenAddKioskAccessModal,
  CloseAddKioskAccessModal,
  KioskAccessRemoved,
  OpenEditKioskAccessModal,
} from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

export interface KioskAccessListProps {
  clientId: number;
  hallId: number;
  kioskId?: number;
}

export function useKioskAccessListState({
  clientId,
  hallId,
  kioskId,
}: KioskAccessListProps) {
  const intl = useIntl();

  const query = useMemo(() => {
    const query = new Query(KioskAccessAPI.getById);
    query.submit({ clientId, hallId, kioskId });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { eventBusService } = useGlobalStore();

  function refresh() {
    query.submit({ clientId, hallId, kioskId });
  }

  useEffect(() => {
    eventBusService.subscribe(CloseAddKioskAccessModal, refresh);
    eventBusService.subscribe(KioskAccessRemoved, refresh);
    return () => {
      eventBusService.unsubscribe(CloseAddKioskAccessModal, refresh);
      eventBusService.unsubscribe(KioskAccessRemoved, refresh);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openAddAccessModal() {
    eventBusService.publish(
      new OpenAddKioskAccessModal({ clientId, hallId, kioskId }),
    );
  }

  function openEditAccessModal(id: number) {
    return eventBusService.publish(
      new OpenEditKioskAccessModal({ id, clientId, hallId, kioskId }),
    );
  }

  return {
    clientId,
    hallId,
    kioskId,
    intl,
    query,
    openAddAccessModal,
    openEditAccessModal,
  };
}

export type KioskAccessListState = ReturnType<typeof useKioskAccessListState>;
