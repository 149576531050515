import { useEffect, useMemo, useRef } from "react";

export interface ViewModel<P> {
  onViewMount?: (props: P) => void;
  onViewUpdate?: (props: P) => void;
  onViewUnmount?: () => void;
}

type ViewModelFactory<VM> = VM extends ViewModel<any> ? () => VM : any;

type Args<VM> = VM extends ViewModel<infer P> ? [props: P] : [];

export function useViewModel<VM>(
  viewModelFactory: ViewModelFactory<VM>,
  ...args: Args<VM>
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewModel = useMemo(viewModelFactory, []);
  const isFirstRender = useRef(true);
  const props = useMemo(() => args[0], [args]);

  useEffect(() => {
    if (isFirstRender.current) {
      viewModel.onViewMount?.(props);
      isFirstRender.current = false;
    }
    return () => {
      viewModel.onViewUnmount?.();
      isFirstRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewModel]);

  useEffect(() => {
    if (!isFirstRender.current) {
      viewModel.onViewUpdate?.(props);
    }
  }, [viewModel, props]);

  return viewModel;
}
