import { observer } from "mobx-react-lite";
import { useState } from "react";

import { GlobalSearchState } from "./global-search.state";
import { GlobalSearchView } from "./global-search.view";

export const GlobalSearch = observer(() => {
  const [state] = useState(() => new GlobalSearchState());
  return <GlobalSearchView state={state} />;
});
