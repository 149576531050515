import { green } from "@ant-design/colors";
import { Button, Card, Col, Row, Select, Space, Spin, Table } from "antd";
import classNames from "classnames";
import { snakeCase } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { TConversation } from "@/api";
import { useEntitiesSearchParams } from "@/hooks";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import BasePage from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";

import { InstantMessagesState } from "./instant-messages-state";

function InstantMessages() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new InstantMessagesState(globalStore), {});
  const intl = useIntl();
  const search = useEntitiesSearchParams();

  return (
    <BasePage
      title={intl.formatMessage({ defaultMessage: "Instant messages" })}
    >
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={state.clientSelectorStore}
              placeholder={intl.formatMessage({
                defaultMessage: "Select client",
              })}
            />
          </Col>
          {!!state.clientSelectorStore.selectedId && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.agentSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select an agent",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.hallSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select shop",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.playerSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select player",
                })}
              />
            </Col>
          )}
          <Col xs={24} md={8} lg={6} xl={4}>
            <Select
              style={{ width: "100%" }}
              value={state.status}
              onChange={state.setStatus}
              placeholder={intl.formatMessage({
                defaultMessage: "Select state",
              })}
              options={[
                {
                  value: "active",
                  label: intl.formatMessage({
                    defaultMessage: "Active",
                  }),
                },
                {
                  value: "inactive",
                  label: intl.formatMessage({
                    defaultMessage: "Solved",
                  }),
                },
              ]}
            />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Select
              value={state.isReadByAdmin}
              onChange={(value) => (state.isReadByAdmin = value)}
              style={{ width: "100%" }}
              placeholder={intl.formatMessage({
                defaultMessage: "All, Read, Unread",
              })}
              options={[
                {
                  value: null,
                  label: intl.formatMessage({
                    defaultMessage: "All",
                  }),
                },
                {
                  value: true,
                  label: intl.formatMessage({
                    defaultMessage: "Read",
                  }),
                },
                {
                  value: false,
                  label: intl.formatMessage({
                    defaultMessage: "Unreaded",
                  }),
                },
              ]}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              showHeader={!!state.filterQuery.data?.length}
              dataSource={state.filterQuery.data}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
              components={{
                body: {
                  row: (props: any) => {
                    const item = props.children?.[0]?.props?.record;
                    const type = snakeCase(item?.type);
                    return (
                      <tr
                        {...props}
                        className={classNames(
                          props.className,
                          type === "out_reserved"
                            ? "ant-table-row-color-gray"
                            : "",
                        )}
                      />
                    );
                  },
                },
              }}
            >
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex="id"
                render={(id) => (
                  <Link
                    style={{ display: "flex", justifyContent: "center" }}
                    to={{
                      pathname: `/instant-messages/${id}`,
                      search,
                    }}
                  >
                    {id}
                  </Link>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Client" })}
                dataIndex="clientName"
                render={(clientName) => clientName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Agent" })}
                dataIndex="agentName"
                render={(agentName) => agentName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Hall" })}
                dataIndex="hallName"
                render={(hallName) => hallName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Player" })}
                dataIndex="playerName"
                render={(playerName) => playerName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "State" })}
                render={(value) =>
                  value?.isActive
                    ? intl.formatMessage({ defaultMessage: "Active" })
                    : intl.formatMessage({ defaultMessage: "Solved" })
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Last message" })}
                render={(_, item: TConversation) => {
                  const createdAt = new Date(
                    item.lastMsg.createdAt,
                  ).toLocaleString();
                  const text = item.lastMsg.text;
                  const isReadByAdmin = item.isReadByAdmin;

                  return (
                    <Row align="middle" gutter={5}>
                      <span style={{ marginRight: "20px" }}>{createdAt}</span>
                      <Link
                        style={{
                          maxWidth: "300px",
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        to={{
                          pathname: `/instant-messages/${item.id}`,
                          search,
                        }}
                      >
                        {text}
                      </Link>
                      {!isReadByAdmin && (
                        <span
                          style={{
                            marginLeft: "auto",
                            marginRight: "30px",
                            color: green[6],
                            fontWeight: "bold",
                          }}
                        >
                          {intl
                            .formatMessage({
                              defaultMessage: "New",
                            })
                            .toUpperCase()}
                        </span>
                      )}
                    </Row>
                  );
                }}
              />
            </Table>
          </Space>
        </Spin>
      </Card>
    </BasePage>
  );
}

export const Page = observer(InstantMessages);
