import { Button, Card, Checkbox, Col, Divider, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { FormattedMessage } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import Selector from "@/ui/_common_/selector";

import { FormState } from "./form.state";

interface Props {
  state: FormState;
}

export const FormView = observer<Props>(({ state }) => {
  if (
    state.gameQuery.isIdle ||
    state.gameQuery.isPending ||
    state.tagsQuery.isIdle ||
    state.tagsQuery.isPending ||
    (state.providerSelectorStore.selectedId &&
      !state.providerSelectorStore.selectedItem) ||
    (state.brandSelectorStore.selectedId &&
      !state.brandSelectorStore.selectedItem)
  ) {
    return <FormSpinner />;
  }

  return (
    <form onSubmit={state.handleSubmit}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[state.tagsQuery, state.submitQuery]} />
        <Row gutter={[12, 12]}>
          <Col xs={24} md={16}>
            <Card size="small" style={{ height: "100%" }}>
              <Form
                labelCol={{ span: 10 }}
                labelAlign="left"
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                component="div"
              >
                <FormItem
                  form={state.form}
                  path="providerId"
                  label={"Provider"}
                >
                  <Selector
                    store={state.providerSelectorStore}
                    placeholder={"Select provider"}
                  />
                </FormItem>
                {state.providerId && (
                  <FormItem form={state.form} path="brandId" label={"Brand"}>
                    <Selector
                      store={state.brandSelectorStore}
                      placeholder={"Select brand"}
                    />
                  </FormItem>
                )}
                <Divider />
                <FormItem form={state.form} path="stringId" label={"String ID"}>
                  <FormInput
                    form={state.form}
                    path="stringId"
                    placeholder={"Enter string id"}
                  />
                </FormItem>
                <FormItem form={state.form} path="name" label={"Name"}>
                  <FormInput
                    form={state.form}
                    path="name"
                    placeholder={"Enter name"}
                  />
                </FormItem>
                <FormItem
                  form={state.form}
                  path="isJackpotsEnabled"
                  label={"Jackpots allowed"}
                >
                  <FormSwitch form={state.form} path="isJackpotsEnabled" />
                </FormItem>
                <FormItem
                  form={state.form}
                  path="isBonusWagerEnabled"
                  label={"Bonus Wagering"}
                >
                  <FormSwitch form={state.form} path="isBonusWagerEnabled" />
                </FormItem>
              </Form>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card
              size="small"
              title={<FormattedMessage defaultMessage="Tags" />}
            >
              <Space
                direction="vertical"
                style={{ maxHeight: 300, overflow: "auto" }}
              >
                {state.tags.map((tag) => (
                  <Checkbox
                    key={tag.id}
                    checked={state.tagIds.includes(tag.id)}
                    onChange={(event) =>
                      event.target.checked
                        ? state.addTag(tag.id)
                        : state.removeTag(tag.id)
                    }
                  >
                    {tag.name}
                  </Checkbox>
                ))}
              </Space>
            </Card>
          </Col>
        </Row>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.submitQuery.isPending}
            >
              {"Save"}
            </Button>
          </Col>
        </Row>
      </Space>
    </form>
  );
});
