import { CheckboxOptionType, Radio, RadioGroupProps } from "antd";
import { observer } from "mobx-react-lite";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

interface Props<FV extends FieldValues>
  extends Pick<RadioGroupProps, "optionType" | "buttonStyle"> {
  form: UseFormReturn<FV>;
  path: Path<FV>;
  options: CheckboxOptionType[];
}

export const FormRadioGroup = observer(
  <FV extends FieldValues>({
    form,
    path,
    options,
    optionType,
    buttonStyle,
  }: Props<FV>) => {
    return (
      <Controller
        control={form.control}
        name={path}
        render={({ field }) => (
          <Radio.Group
            {...field}
            optionType={optionType}
            buttonStyle={buttonStyle}
            options={options}
          />
        )}
      />
    );
  },
);
