import { get } from "lodash-es";

import { ERROR_CODES } from "@/constants";
import { Logger } from "@/utilities";

import APIError from "./api-error";

type TResponse<T> = {
  jsonrpc: string;
  id: number;
} & (
  | { result: T }
  | {
      error: {
        code: number;
        message: string;
        data?: unknown;
      };
    }
);

type TRequest = {
  jsonrpc: "2.0";
  id: number;
  method: string;
  params: Record<string, any>;
};

type TListener<T> = {
  resolve: (data: T) => void;
  reject: (error: Error) => void;
};

class Client {
  static ENPOIND = "/rpc";

  private id = 1;

  private timeout = 0;
  private requests: TRequest[] = [];
  private listeners: Map<number, TListener<any>> = new Map();

  async send<T>(method: string, params: unknown = {}): Promise<T> {
    clearTimeout(this.timeout);

    // deep clone
    const requestString = JSON.stringify({
      jsonrpc: "2.0",
      id: this.id++,
      method,
      params,
    });
    const request: TRequest = JSON.parse(requestString);

    Logger.log("-->", request);

    this.requests.push(request);

    return new Promise((resolve, reject) => {
      this.listeners.set(request.id, { resolve, reject });
      this.timeout = window.setTimeout(this.processRequests, 50);
    });
  }

  private processRequests = async () => {
    // deep clone
    const body = JSON.stringify(this.requests);
    const requests: TRequest[] = JSON.parse(body);

    this.requests = [];

    const response = await fetch(Client.ENPOIND, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      body,
    });
    const responses: TResponse<any>[] = await response.json();

    if (!Array.isArray(responses)) {
      const error = new APIError("The response is not JSON-RPC 2.0.");
      return requests.forEach(({ id }) => {
        this.reject(id, error);
      });
    }
    try {
      requests.forEach((request) => {
        const id = request.id;

        const response = responses.find((response) => response.id === id);

        if (response === undefined) {
          const error = new APIError("The response is not JSON-RPC 2.0.");
          return this.reject(id, error);
        }

        Logger.log("<--", response);

        if (
          !("jsonrpc" in response) ||
          (!("result" in response) && !("error" in response))
        ) {
          const error = new APIError("The response is not JSON-RPC 2.0.");
          return this.reject(id, error);
        }

        if ("error" in response) {
          const error = response.error;
          const message = get(
            error,
            "message",
            "The response is not JSON-RPC 2.0.",
          );
          const code = get(error, "data.code", get(error, "code", -1));

          if (code === ERROR_CODES.PANIC_ENABLED) {
            const url = get(error, "data.panicUrl", "https://google.com");
            window.location.replace(url);
          }
          if (
            code === ERROR_CODES.NOT_AUTHORIZED &&
            window.location.pathname !== "/log-in"
          ) {
            window.location.replace("/log-in");
          }

          return this.reject(id, new APIError(message, code));
        }

        return this.resolve(id, response.result);
      });
    } catch (error) {
      requests.forEach(({ id }) => {
        this.reject(id, error as Error);
      });
    }
  };

  private reject = (requestId: number, error: Error) => {
    this.listeners.get(requestId)?.reject(error);
    this.listeners.delete(requestId);
  };

  private resolve = (requestId: number, data: any) => {
    this.listeners.get(requestId)?.resolve(data);
    this.listeners.delete(requestId);
  };
}

const client = new Client();

export default client;
