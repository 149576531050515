import { observer } from "mobx-react-lite";

import { useAgentOtherSettingsState } from "./agent-other-settings.state";
import { AgentOtherSettingsView } from "./agent-other-settings.view";

export interface AgentOtherSettingsProps {
  agentId: number;
}

export const AgentOtherSettings = observer((props: AgentOtherSettingsProps) => {
  const state = useAgentOtherSettingsState(props);
  return <AgentOtherSettingsView state={state} />;
});
