import { green, red } from "@ant-design/colors";
import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Table, Row, Col, Button } from "antd";
import { observer } from "mobx-react-lite";

import { DiceIcon } from "@/ui/_common_/icons";

import { DeleteAction } from "../delete-action";

import { RubricatorTableState } from "./table.state";

type Props = {
  state: RubricatorTableState;
};

export const RubricatorTableView = observer<Props>(({ state }) => {
  return (
    <Table
      loading={state.query.isPending}
      bordered
      dataSource={state.categoies}
      columns={[
        {
          key: "id",
          dataIndex: "id",
          title: "ID",
        },
        {
          key: "name",
          dataIndex: "names.en",
          width: "100%",
          title: "Name",
          render: (_, item) => {
            const paddingLeft = item.parentId ? 20 : 0;
            const fontWeight = !item.parentId ? "bold" : "normal";
            return (
              <div style={{ paddingLeft, fontWeight }}>{item.names.en}</div>
            );
          },
        },
        {
          key: "isActive",
          dataIndex: "isActive",
          align: "center",
          title: "Enabled",
          render: (isActive) =>
            isActive ? (
              <CheckOutlined style={{ color: green[6] }} />
            ) : (
              <CloseOutlined style={{ color: red[6] }} />
            ),
        },
        {
          key: "order",
          dataIndex: "order",
          align: "right",
          title: "Order",
        },
        {
          key: "tag",
          dataIndex: "tag",
          title: state.intl.formatMessage({ defaultMessage: "Tag" }),
          render: (tag: string | null) => tag ?? "—",
        },
        {
          key: "actions",
          align: "right",
          title: "Actions",
          render: (_, category) => {
            return (
              <Row gutter={[12, 12]} justify="end" wrap={false}>
                {!category.parentId && (
                  <Col>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => state.openNewCategoryModal(category.id)}
                    />
                  </Col>
                )}
                <Col>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => {
                      state.openEditCategoryModal(category.id);
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    size="small"
                    icon={<DiceIcon style={{ width: 16, height: 16 }} />}
                    onClick={() => {
                      state.openCategoryGamesModal(
                        category.id,
                        category.names.en,
                      );
                    }}
                  />
                </Col>
                <Col>
                  <DeleteAction categoryId={category.id} />
                </Col>
              </Row>
            );
          },
        },
      ]}
      pagination={false}
      size="small"
    />
  );
});
