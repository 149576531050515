import MethodBuilder from "../../method-builder";
import { TBonusReportItem, TPaging } from "../../types";

export class BonusReportAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
    ) & {
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      currency?: string;
      paging: TPaging;
    },
    {
      data: TBonusReportItem[];
      total: number;
    }
  >()
    .withName("BonusReport.filter")
    .build();
}
