import { IReactionDisposer } from "mobx";
import { createContext, useContext } from "react";

import { GlobalStore } from "./global";

export * from "./global";
export * from "./user";
export * from "./currency";
export * from "./permissions";
export * from "./clients";
export * from "./agents";
export * from "./halls";
export * from "./kiosks";
export * from "./players";
export * from "./selectors";
export * from "./cashiers";

const globalStore = new GlobalStore();

const GlobalStoreContext = createContext(globalStore);

export function useGlobalStore(): GlobalStore {
  return useContext(GlobalStoreContext);
}

export interface DisposableStore {
  disposers: IReactionDisposer[];
  dispose: () => void;
}

interface StoresProviderProps {
  children: React.ReactNode;
}

export function StoresProvider({ children }: StoresProviderProps) {
  return (
    <GlobalStoreContext.Provider value={globalStore}>
      {children}
    </GlobalStoreContext.Provider>
  );
}
