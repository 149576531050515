import { TVoucherTemplate } from "..";
import MethodBuilder from "../method-builder";

export class VoucherTemplateAPI {
  static getById = new MethodBuilder<
    { hallId: number } | { agentId: number },
    {
      data: TVoucherTemplate[];
      isInherited: boolean;
    }
  >()
    .withName("VoucherTemplate.getById")
    .build();

  static update = new MethodBuilder<
    ({ hallId: number } | { agentId: number }) & {
      template: string;
    },
    void
  >()
    .withName("VoucherTemplate.update")
    .build();

  static delete = new MethodBuilder<
    { hallId: number } | { agentId: number },
    void
  >()
    .withName("VoucherTemplate.delete")
    .build();
}
