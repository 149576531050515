import { Button, Col, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormSwitch,
  FormTextArea,
} from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { RulesFormState } from "./form.state";

interface Props {
  state: RulesFormState;
}

export const RulesFormView = observer(({ state }: Props) => {
  const { intl, form, fetchQuery, submitQuery, deleteQuery } = state;

  if (fetchQuery.isRejected) {
    return <ErrorsFormatter queries={[fetchQuery]} />;
  }

  if (fetchQuery.isIdle || fetchQuery.isPending) {
    return <FormSpinner />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery, deleteQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="text"
          label={intl.formatMessage({ defaultMessage: "Text" })}
        >
          <FormTextArea
            form={form}
            path="text"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter text",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="version"
          label={intl.formatMessage({ defaultMessage: "Version" })}
        >
          <FormInput
            form={form}
            path="version"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter version",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="showInLobby"
          label={intl.formatMessage({ defaultMessage: "Show in lobby" })}
        >
          <FormSwitch form={form} path="showInLobby" />
        </FormItem>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Update at" })}>
          {fetchQuery.data
            ? new Date(fetchQuery.data.updateAt).toLocaleString()
            : "—"}
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              loading={deleteQuery.isPending}
              onClick={deleteQuery.submit}
            >
              {intl.formatMessage({ defaultMessage: "Delete" })}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
