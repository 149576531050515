import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const StatusSelect = observer<Props>(({ value, onChange }) => {
  const intl = useIntl();
  return (
    <Select
      placeholder={intl.formatMessage({
        defaultMessage: "Select status",
      })}
      options={[
        {
          label: intl.formatMessage({ defaultMessage: "Requested" }),
          value: "requested",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Success" }),
          value: "success",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Failed" }),
          value: "failed",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Prohibited" }),
          value: "prohibited",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Verification" }),
          value: "verification",
        },
      ]}
      value={value}
      onChange={onChange}
      onClear={onChange}
      allowClear
      style={{ width: "100%" }}
    />
  );
});
