import { mapValues } from "lodash-es";

import yup from "@/yup";

export function useWebsitesUpdateSchema() {
  return yup.object({
    websites: yup.lazy((websites) =>
      yup
        .object(
          mapValues(websites, () => {
            return yup
              .object({
                id: yup.number().required(),
                isChecked: yup.boolean().required(),
              })
              .required();
          }),
        )
        .required(),
    ),
  });
}

export type TWebsitesUpdateSchema = yup.InferType<
  ReturnType<typeof useWebsitesUpdateSchema>
>;
