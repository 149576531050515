import yup from "@/yup";

export function useRulesFormSchema() {
  return yup.object({
    text: yup.string().defined(),
    version: yup.string().defined(),
    showInLobby: yup.boolean().defined(),
  });
}

export type RulesFormSchema = yup.InferType<
  ReturnType<typeof useRulesFormSchema>
>;
