import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Alert, Button, Col, Divider, Grid, Row, Space, Tabs } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { ReceiptEditorState } from "./editor.state";
import { JSONModal } from "./json-modal";
import { LineEditor } from "./line-editor";
import { UniqueLine } from "./types";

import "./editor.style.css";

interface Props {
  state: ReceiptEditorState;
}

export const ReceiptEditorView = observer<Props>(
  ({
    state: {
      intl,

      header,
      scaleFontSize,

      form,
      lines,
      addLine,
      removeLine,

      selectedLineIndex,
      selectedLine,

      handleClick,
      handleDragStart,
      handleDragEnd,

      handleSubmit,
      handleCancel,

      submitQuery,
      cancelQuery,

      handleJSONClick,

      sensors,
    },
  }) => {
    const breakpoint = Grid.useBreakpoint();

    const preview = (
      <div className="receipt-editor__preview">
        <div className="receipt-editor__lines">
          <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
          >
            <SortableContext
              items={lines}
              strategy={verticalListSortingStrategy}
            >
              {lines.map((line) => (
                <SortableLine
                  key={line.id}
                  scaleFontSize={scaleFontSize}
                  line={line}
                  isSelected={selectedLine?.id === line.id}
                  onClick={handleClick}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <div className="receipt-editor__controls">
          <button
            type="button"
            className="receipt-editor__control"
            onClick={addLine}
          >
            ＋
          </button>
          <button
            type="button"
            className="receipt-editor__control"
            onClick={removeLine}
          >
            －
          </button>
        </div>
      </div>
    );

    const editor = (
      <form className="receipt-editor__line-editor" onSubmit={handleSubmit}>
        {header && (
          <>
            {header}
            <Divider />
          </>
        )}
        {selectedLine ? (
          <LineEditor
            key={selectedLineIndex}
            index={selectedLineIndex}
            form={form}
          />
        ) : (
          <div className="receipt-editor__alert-container">
            <Alert
              showIcon
              message={intl.formatMessage({
                defaultMessage: "Please select line to edit",
              })}
            />
          </div>
        )}
        <Row justify="end" gutter={12} style={{ marginTop: 16 }}>
          <Col>
            <JSONModal />
            <Button type="link" onClick={handleJSONClick}>
              {intl.formatMessage({ defaultMessage: "JSON" })}
            </Button>
          </Col>
          {cancelQuery && (
            <Col>
              <Button onClick={handleCancel} loading={cancelQuery.isPending}>
                {intl.formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </form>
    );

    if (breakpoint.xs) {
      return (
        <Space direction="vertical">
          <Tabs
            type="line"
            size="small"
            items={[
              {
                key: "preview",
                label: intl.formatMessage({ defaultMessage: "Preview" }),
                children: preview,
              },
              {
                key: "editor",
                label: intl.formatMessage({ defaultMessage: "Editor" }),
                children: editor,
              },
            ]}
          />
        </Space>
      );
    }

    return (
      <div className="receipt-editor__columns">
        {preview}
        {editor}
      </div>
    );
  },
);

interface SortableLineProps {
  scaleFontSize(size: number): number;
  onClick(lineId: number): void;
  line: UniqueLine;
  isSelected: boolean;
}

function SortableLine({
  scaleFontSize,
  onClick,
  line,
  isSelected,
}: SortableLineProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: line.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames("receipt-editor__line", {
        "receipt-editor__line_selected": isSelected,
        "receipt-editor__line_dragging": isDragging,
        "receipt-editor__line_align_left": line.align === "l",
        "receipt-editor__line_align_center": line.align === "c",
        "receipt-editor__line_align_right": line.align === "r",
      })}
      style={style}
      {...attributes}
      {...listeners}
      onClick={() => onClick(line.id)}
    >
      {line.type === "text" ? (
        <div
          className="receipt-editor__text"
          style={{
            fontSize: scaleFontSize(line.size) + "px",
            lineHeight: scaleFontSize(line.size) * 1.5 + "px",
            minHeight: scaleFontSize(line.size) * 1.5 + "px",
          }}
        >
          {line.text}
        </div>
      ) : (
        <div
          className="receipt-editor__qr-code"
          style={{
            width: scaleFontSize(line.size) * 3,
            height: scaleFontSize(line.size) * 3,
            fontSize: scaleFontSize(line.size) / 2 + "px",
          }}
        >
          {line.text}
        </div>
      )}
    </div>
  );
}
