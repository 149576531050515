import { makeAutoObservable } from "mobx";

import {
  AgentAPI,
  BonusAPI,
  BonusTemplateAPI,
  FortuneWheelAPI,
  HallAPI,
  TBonus,
  TBonusTemplate,
  TFortuneWheel,
} from "@/api";
import { BonusUpdated } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { PermissionsStore, UserStore } from "@/stores";
import { Entity } from "@/types";
import { getParentEntity } from "@/utilities";

import { Props } from "./List";

export class ListState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public permissionsStore: PermissionsStore,
    private userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _entity?: Entity;

  get entity() {
    return this._entity!;
  }

  private set entity(entity: Entity) {
    this._entity = entity;
  }

  private _readOnly = false;

  get readOnly() {
    return this._readOnly;
  }

  private set readOnly(value: boolean) {
    this._readOnly = value;
  }

  private _selfEdit = false;

  get selfEdit() {
    return this._selfEdit;
  }

  private set selfEdit(value: boolean) {
    this._selfEdit = value;
  }

  onViewMount = async ({
    entity,
    readOnly = false,
    selfEdit = false,
  }: Props) => {
    this.entity = entity;
    this.readOnly = readOnly;
    this.selfEdit = selfEdit;

    this.fetchData();

    this.eventBusService.subscribe(BonusUpdated, this.fetchData);
  };

  onViewUnmount = () => {
    this.bonusesQuery.reset();
    this.bonusTemplatesQuery.reset();

    this.eventBusService.unsubscribe(BonusUpdated, this.fetchData);
  };

  private fetchData = async () => {
    const parentEntity = await getParentEntity(this.entity);

    await Promise.all(
      [
        "hallId" in this.entity &&
          this.hallQuery.submit({ id: this.entity.hallId }),
        this.bonusesQuery.submit(this.entity),
        parentEntity && this.parentBonusesQuery.submit(parentEntity),
        this.bonusTemplatesQuery.submit({}),
        this.fortuneWheelQuery.submit(this.entity),
        parentEntity && this.parentFortuneWheelQuery.submit(parentEntity),
      ].filter(Boolean),
    );

    if (
      !this.bonusesQuery.isFulfilled ||
      !this.bonusTemplatesQuery.isFulfilled
    ) {
      return;
    }

    this.bonuses = this.bonusesQuery.data.data;
    this.parentBonuses = this.parentBonusesQuery.data?.data ?? [];
    this.templates = this.bonusTemplatesQuery.data.data;
    this.fortuneWheel = this.fortuneWheelQuery.data;
    this.parentFortuneWheel = this.parentFortuneWheelQuery.data;
  };

  agentQuery = new Query(AgentAPI.getById);
  hallQuery = new Query(HallAPI.getById);

  bonusesQuery = new Query(BonusAPI.filter);
  parentBonusesQuery = new Query(BonusAPI.filter);
  bonusTemplatesQuery = new Query(BonusTemplateAPI.getAll);

  fortuneWheelQuery = new Query(FortuneWheelAPI.get);
  parentFortuneWheelQuery = new Query(FortuneWheelAPI.get);

  private _bonuses?: TBonus[];

  get bonuses() {
    return this._bonuses;
  }

  private set bonuses(value: TBonus[] | undefined) {
    this._bonuses = value;
  }

  private _parentBonuses?: TBonus[];

  get parentBonuses() {
    return this._parentBonuses;
  }

  private set parentBonuses(value: TBonus[] | undefined) {
    this._parentBonuses = value;
  }

  private _templates?: TBonusTemplate[];

  get templates() {
    return this._templates;
  }

  private set templates(value: TBonusTemplate[] | undefined) {
    this._templates = value;
  }

  private _fortuneWheel?: TFortuneWheel | null;

  get fortuneWheel() {
    return this._fortuneWheel;
  }

  private set fortuneWheel(value: TFortuneWheel | null | undefined) {
    this._fortuneWheel = value;
  }

  private _parentFortuneWheel?: TFortuneWheel | null;

  get parentFortuneWheel() {
    return this._parentFortuneWheel;
  }

  private set parentFortuneWheel(value: TFortuneWheel | null | undefined) {
    this._parentFortuneWheel = value;
  }

  get hasAccessToFortuneWheel() {
    if (this.selfEdit) {
      return !!this.fortuneWheel;
    }

    if ("clientId" in this.entity) {
      return true;
    }

    return !!this.parentFortuneWheel;
  }

  get isCommonTabVisible() {
    return (
      ("hallId" in this.entity || "agentId" in this.entity) &&
      this.userStore.user.role !== "Cashier"
    );
  }

  get hasAccessToCashbackBonus() {
    if (this.selfEdit) {
      return !!this.cashbackBonus;
    }

    if ("clientId" in this.entity) {
      return true;
    }

    return this.parentBonusesQuery.data?.data.some(
      (bonus) => bonus.type === "cashback",
    );
  }

  get cashbackBonus() {
    return this.bonuses!.find((b) => b.type === "cashback");
  }

  get parentCashbackBonus() {
    return this.parentBonuses?.find((b) => b.type === "cashback");
  }

  get cashbackTemplates() {
    return this.templates!.filter((t) => t.type === "cashback");
  }

  get hasAccessToBouncebackBonus() {
    if (this.selfEdit) {
      return !!this.bouncebackBonus;
    }

    if ("clientId" in this.entity) {
      return true;
    }

    return this.parentBonusesQuery.data?.data.some(
      (bonus) => bonus.type === "bounceback",
    );
  }

  get bouncebackBonus() {
    return this.bonuses!.find((b) => b.type === "bounceback");
  }

  get parentBouncebackBonus() {
    return this.parentBonuses?.find((b) => b.type === "bounceback");
  }

  get bouncebackTemplates() {
    return this.templates!.filter((t) => t.type === "bounceback");
  }

  get hasAccessToLastchanceBonus() {
    if (this.selfEdit) {
      return !!this.lastchanceBonus;
    }

    if ("clientId" in this.entity) {
      return true;
    }

    return this.parentBonusesQuery.data?.data.some(
      (bonus) => bonus.type === "lastchance",
    );
  }

  get lastchanceBonus() {
    return this.bonuses!.find((b) => b.type === "lastchance");
  }

  get parentLastchanceBonus() {
    return this.parentBonuses?.find((b) => b.type === "lastchance");
  }

  get hasAccessToDailyLoginRewards() {
    if (this.selfEdit) {
      return false;
    }

    if (!("hallId" in this.entity)) {
      return false;
    }

    return !!this.hallQuery.data?.isB2C;
  }

  get lastchanceTemplates() {
    return this.templates!.filter((t) => t.type === "lastchance");
  }

  get isReady() {
    return (
      this.entity !== undefined &&
      this.bonuses !== undefined &&
      this.templates !== undefined &&
      this.fortuneWheel !== undefined
    );
  }

  get isPending() {
    return (
      this.hallQuery.isPending ||
      this.bonusesQuery.isPending ||
      this.bonusTemplatesQuery.isPending ||
      this.parentBonusesQuery.isPending ||
      this.fortuneWheelQuery.isPending ||
      this.parentFortuneWheelQuery.isPending
    );
  }

  get isRejected() {
    return (
      this.hallQuery.isRejected ||
      this.bonusesQuery.isRejected ||
      this.bonusTemplatesQuery.isRejected ||
      this.parentBonusesQuery.isRejected ||
      this.parentFortuneWheelQuery.isRejected ||
      this.fortuneWheelQuery.isRejected
    );
  }
}
