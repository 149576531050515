import { observer } from "mobx-react-lite";

import { useHallSettingsFormState } from "./hall-settings-form.state";
import { HallSettingsFormView } from "./hall-settings-form.view";

export interface HallSettingsFormProps {
  hallId: number;
  setHallName(name: string): void;
}

export const HallSettingsForm = observer<HallSettingsFormProps>((props) => {
  const state = useHallSettingsFormState(props);
  return <HallSettingsFormView state={state} />;
});
