import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";

import Query from "@/models/query";

type TProps<I extends { id: number }, O extends { id: number }> = {
  placeholder: string;
  query: Query<I, O>;
  id?: number;
  labelKey: keyof O;
  initialItem?: O;
};

function ReadOnlySelector<I extends { id: number }, O extends { id: number }>({
  placeholder,
  query,
  id,
  labelKey,
  initialItem,
}: TProps<I, O>) {
  const item = useMemo(() => {
    if (initialItem) {
      return initialItem;
    }
    if (query.isFulfilled) {
      return query.data;
    }
    return undefined;
  }, [initialItem, query.isFulfilled, query.data]);

  useEffect(() => {
    if (((id && !item) || item?.id !== id) && !query.isPending) {
      query.submit({ id } as I);
    }
  }, [id, item, query, query.isIdle]);

  return (
    <Select
      placeholder={placeholder}
      loading={query.isPending}
      value={(item?.[labelKey] ?? "—") as string}
      disabled
    />
  );
}

export default observer(ReadOnlySelector);
