import { green, red } from "@ant-design/colors";
import {
  CaretDownOutlined,
  EditOutlined,
  InfoOutlined,
  LockOutlined,
  MinusOutlined,
  PlusOutlined,
  QrcodeOutlined,
  ReloadOutlined,
  SwapOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Alert,
  Card,
  Divider,
  Empty,
  Popover,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { Observer, observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  UpdatePlayer,
  DepositPlayerBalance,
  ExchangePlayerBalance,
  WithdrawPlayerBalance,
} from "@/events";
import { PlayersStore, PlayerStore, useGlobalStore } from "@/stores";
import { AncestorsTreeButton } from "@/ui/_common_/ancestors-tree";
import AreYouSure from "@/ui/_common_/are-you-sure";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";
import { DeleteAction } from "@/ui/delete-action";

type TProps = {
  playersStore: PlayersStore;
};

function PlayersTable({ playersStore: state }: TProps) {
  const { eventBusService, userStore } = useGlobalStore();
  const { cashier } = userStore;
  const isActionsDisabled =
    cashier?.isShiftModeEnabled && !cashier.isShiftStarted;
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<PlayerStore> | SorterResult<PlayerStore>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        ["id", "login", "createdAt"].includes(sorter.columnKey)
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
      state.filter();
    },
    [state],
  );

  const intl = useIntl();

  const isSweepstatesEnabled =
    state.playerStores.at(0)?.player?.sweepstake !== null;

  const isDualBalanceSweepstake =
    state.playerStores.at(0)?.player?.sweepstake !== null &&
    state.playerStores.at(0)?.player?.sweepstake?.credit === undefined;

  return (
    <Spin spinning={state.filterQuery.isPending}>
      <Space direction="vertical">
        <Row>
          <Col xs={0} sm={24}>
            <Table
              dataSource={state.playerStores}
              showHeader={!!state.playerStores.length}
              size="small"
              rowKey={(playerStore) => playerStore.player.id}
              bordered
              pagination={false}
              components={{ body: { row: PlayersTableRow } }}
              onChange={handleTableChange}
            >
              <Table.Column
                key="id"
                title={intl.formatMessage({
                  defaultMessage: "ID",
                })}
                dataIndex={["player", "id"]}
                align="right"
                sorter={true}
                defaultSortOrder={
                  state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
                }
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="agentName"
                title={intl.formatMessage({
                  defaultMessage: "Agent",
                })}
                dataIndex={["player", "agentName"]}
                render={(agentName) => agentName ?? "—"}
              />
              <Table.Column
                key="hallName"
                title={intl.formatMessage({
                  defaultMessage: "Shop",
                })}
                dataIndex={["player", "hallName"]}
                render={(_, playerStore: PlayerStore) => {
                  return (
                    <Row gutter={[8, 8]} wrap={false}>
                      {userStore.user.role !== "HallAdmin" &&
                        userStore.user.role !== "Cashier" && (
                          <Col>
                            <AncestorsTreeButton
                              entity={{ hallId: playerStore.player.hallId }}
                            />
                          </Col>
                        )}
                      <Col>{playerStore.player.hallName}</Col>
                    </Row>
                  );
                }}
              />
              <Table.Column
                key="login"
                title={intl.formatMessage({
                  defaultMessage: "Username",
                })}
                dataIndex={["player", "login"]}
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
                render={(login: string, playerStore: PlayerStore) => (
                  <Observer>
                    {() => (
                      <>
                        {login}{" "}
                        {!!playerStore.player.deletedAt && (
                          <Tag>
                            {intl.formatMessage({ defaultMessage: "DELETED" })}
                          </Tag>
                        )}
                      </>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Name",
                })}
                dataIndex={["player", "name"]}
                render={(_, playerStore: PlayerStore) => (
                  <Observer>
                    {() => <>{playerStore.player.name || "—"}</>}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Email",
                })}
                dataIndex={["player", "email"]}
                render={(_, playerStore: PlayerStore) => (
                  <Observer>
                    {() => <>{playerStore.player.email || "—"}</>}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Phone",
                })}
                dataIndex={["player", "phone"]}
                render={(_, playerStore: PlayerStore) => (
                  <Observer>
                    {() => <>{playerStore.player.phone || "—"}</>}
                  </Observer>
                )}
              />
              {!isSweepstatesEnabled ? (
                <Table.Column
                  title={intl.formatMessage({ defaultMessage: "Balance" })}
                  dataIndex={["player", "balance"]}
                  align="right"
                  render={(_, playerStore: PlayerStore) => (
                    <Observer>
                      {() => (
                        <Row wrap={false} gutter={8} justify="end">
                          <Col>
                            <Button
                              type="primary"
                              style={{
                                borderColor: red[3],
                                backgroundColor: red[3],
                              }}
                              disabled={
                                playerStore.player.balance === null ||
                                isActionsDisabled
                              }
                              size="small"
                              icon={<MinusOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Withdraw",
                              })}
                              onClick={async () => {
                                eventBusService.publish(
                                  new WithdrawPlayerBalance({
                                    playerId: playerStore.player.id,
                                  }),
                                );
                              }}
                            />
                          </Col>
                          <Col flex="auto">
                            <MoneyFormatter
                              cents={playerStore.player.balance ?? 0}
                            />
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              style={{
                                borderColor: green[3],
                                backgroundColor: green[3],
                              }}
                              size="small"
                              icon={<PlusOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Deposit",
                              })}
                              onClick={async () => {
                                eventBusService.publish(
                                  new DepositPlayerBalance({
                                    playerId: playerStore.player.id,
                                  }),
                                );
                              }}
                              disabled={isActionsDisabled}
                            />
                          </Col>
                        </Row>
                      )}
                    </Observer>
                  )}
                />
              ) : (
                <>
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Entries" })}
                    dataIndex={["player", "sweepstake"]}
                    align="right"
                    render={(_, playerStore: PlayerStore) => (
                      <Row wrap={false} gutter={8}>
                        <Col>
                          <Button
                            type="primary"
                            style={{
                              borderColor: green[3],
                              backgroundColor: green[3],
                            }}
                            size="small"
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Deposit",
                            })}
                            onClick={async () => {
                              eventBusService.publish(
                                new DepositPlayerBalance({
                                  playerId: playerStore.player.id,
                                }),
                              );
                            }}
                          />
                        </Col>
                        {isDualBalanceSweepstake &&
                          userStore.user.role !== "HallAdmin" &&
                          userStore.user.role !== "Cashier" && (
                            <Col>
                              <Button
                                size="small"
                                icon={<SwapOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "Exchange",
                                })}
                                onClick={async () => {
                                  eventBusService.publish(
                                    new ExchangePlayerBalance({
                                      playerId: playerStore.player.id,
                                      operationType: "entries-to-total-win",
                                    }),
                                  );
                                }}
                              />
                            </Col>
                          )}
                        <Col style={{ marginLeft: "auto" }}>
                          <MoneyFormatter
                            cents={playerStore.player.sweepstake?.entries ?? 0}
                            fractionDigits={0}
                          />
                        </Col>
                        {!isDualBalanceSweepstake && (
                          <Col>
                            <Button
                              type="primary"
                              style={{
                                borderColor: red[3],
                                backgroundColor: red[3],
                              }}
                              size="small"
                              icon={<MinusOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Withdraw",
                              })}
                              onClick={async () => {
                                eventBusService.publish(
                                  new WithdrawPlayerBalance({
                                    playerId: playerStore.player.id,
                                  }),
                                );
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                    )}
                  />
                  {isDualBalanceSweepstake && (
                    <Table.Column
                      title={intl.formatMessage({
                        defaultMessage: "Total Win",
                      })}
                      dataIndex={["player", "sweepstake"]}
                      align="right"
                      render={(_, playerStore: PlayerStore) => (
                        <Row wrap={false} gutter={8} justify="end">
                          <Col>
                            <MoneyFormatter
                              cents={playerStore.player.sweepstake?.win ?? 0}
                            />
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              style={{
                                borderColor: red[3],
                                backgroundColor: red[3],
                              }}
                              size="small"
                              icon={<MinusOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Withdraw",
                              })}
                              onClick={async () => {
                                eventBusService.publish(
                                  new WithdrawPlayerBalance({
                                    playerId: playerStore.player.id,
                                  }),
                                );
                              }}
                            />
                          </Col>
                          {userStore.user.role !== "HallAdmin" &&
                            userStore.user.role !== "Cashier" && (
                              <Col>
                                <Button
                                  size="small"
                                  icon={<SwapOutlined />}
                                  title={intl.formatMessage({
                                    defaultMessage: "Exchange",
                                  })}
                                  onClick={async () => {
                                    eventBusService.publish(
                                      new ExchangePlayerBalance({
                                        playerId: playerStore.player.id,
                                        operationType: "total-win-to-entries",
                                      }),
                                    );
                                  }}
                                />
                              </Col>
                            )}
                        </Row>
                      )}
                    />
                  )}
                </>
              )}
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex={["player", "currency"]}
                align="right"
              />
              <Table.Column
                key="createdAt"
                title={intl.formatMessage({
                  defaultMessage: "Created at",
                })}
                dataIndex={["player", "createdAt"]}
                align="right"
                render={(createdAt) => new Date(createdAt).toLocaleString()}
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Actions" })}
                render={(_, playerStore: PlayerStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            icon={<ReloadOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Reload",
                            })}
                            onClick={playerStore.fetch}
                          />
                        </Col>
                        {userStore.user.role !== "Cashier" && (
                          <Col>
                            <Button
                              size="small"
                              icon={<EditOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Edit",
                              })}
                              onClick={() => {
                                eventBusService.publish(
                                  new UpdatePlayer({
                                    playerId: playerStore.player.id,
                                  }),
                                );
                              }}
                            />
                          </Col>
                        )}
                        {userStore.user.role !== "Cashier" && (
                          <Col>
                            <Link
                              to={{
                                pathname: `/players/${playerStore.player.id}`,
                                search: window.location.search,
                              }}
                            >
                              <Button
                                size="small"
                                icon={<InfoOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "Details",
                                })}
                              />
                            </Link>
                          </Col>
                        )}
                        <Col>
                          <AreYouSure
                            content={
                              playerStore.player.login.startsWith("kiosk_") ? (
                                <Alert
                                  style={{ maxWidth: 320 }}
                                  type="error"
                                  message={intl.formatMessage({
                                    defaultMessage: "Important!",
                                  })}
                                  description={intl.formatMessage({
                                    defaultMessage:
                                      "Blocking a player belonging to a Kiosk will make it impossible to accept money at the kiosk.",
                                  })}
                                />
                              ) : null
                            }
                            onYes={playerStore.block}
                            disabled={playerStore.player.isBlocked}
                          >
                            <Button
                              size="small"
                              icon={
                                playerStore.player.isBlocked ? (
                                  <UnlockOutlined />
                                ) : (
                                  <LockOutlined />
                                )
                              }
                              title={
                                playerStore.player.isBlocked
                                  ? intl.formatMessage({
                                      defaultMessage: "Unblock",
                                    })
                                  : intl.formatMessage({
                                      defaultMessage: "Block",
                                    })
                              }
                              onClick={
                                playerStore.player.isBlocked
                                  ? playerStore.unblock
                                  : undefined
                              }
                            />
                          </AreYouSure>
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            icon={<QrcodeOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "QR code",
                            })}
                            onClick={() => {
                              state.playerTokenModalState.open(
                                playerStore.player.hallId,
                                playerStore.player.id,
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <DeleteAction
                            size="small"
                            entity={{ playerId: playerStore.player.id }}
                            isDeleted={!!playerStore.player.deletedAt}
                            onSuccess={state.filter}
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
            </Table>
          </Col>
          <Col xs={24} sm={0}>
            <Space direction="vertical">
              {!state.playerStores.length && <Empty />}
              {state.playerStores.map((playerStore) => (
                <Card
                  key={playerStore.player.id}
                  size="small"
                  className={classNames({
                    "ant-table-row-color-gray": !!playerStore.player.deletedAt,
                  })}
                >
                  {!!playerStore.player.deletedAt && (
                    <Tag>
                      {intl.formatMessage({ defaultMessage: "DELETED" })}
                    </Tag>
                  )}
                  <Row gutter={[8, 8]}>
                    <Col
                      flex={1}
                      style={{
                        fontWeight: "bold",
                        color: playerStore.player.isBlocked ? "red" : "",
                      }}
                    >
                      {playerStore.player.login}{" "}
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {playerStore.player.id}
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col style={{ color: "gray" }}>
                      {intl.formatMessage({ defaultMessage: "Shop" })}:{" "}
                      {playerStore.player.hallName}
                    </Col>
                  </Row>
                  {playerStore.player.name && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({ defaultMessage: "Name" })}:{" "}
                        {playerStore.player.name}
                      </Col>
                    </Row>
                  )}
                  {playerStore.player.email && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({ defaultMessage: "Email" })}:{" "}
                        {playerStore.player.email}
                      </Col>
                    </Row>
                  )}
                  <Divider type="horizontal" style={{ margin: "8px 0" }} />
                  <Row wrap={false} gutter={[8, 8]} align="middle">
                    {!isDualBalanceSweepstake ? (
                      <>
                        <Col>
                          <Button
                            type="primary"
                            style={{
                              borderColor: red[3],
                              backgroundColor: red[3],
                            }}
                            disabled={isActionsDisabled}
                            icon={<MinusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Withdraw",
                            })}
                            onClick={async () => {
                              eventBusService.publish(
                                new WithdrawPlayerBalance({
                                  playerId: playerStore.player.id,
                                }),
                              );
                            }}
                          />
                        </Col>
                        <Col flex={1} style={{ textAlign: "right" }}>
                          {playerStore.player.sweepstake ? (
                            <>
                              <MoneyFormatter
                                cents={playerStore.player.sweepstake.entries}
                                fractionDigits={0}
                              />
                            </>
                          ) : (
                            <>
                              <MoneyFormatter
                                cents={playerStore.player.balance ?? 0}
                              />{" "}
                              {playerStore.player.currency}
                            </>
                          )}
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            style={{
                              borderColor: green[3],
                              backgroundColor: green[3],
                            }}
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Deposit",
                            })}
                            onClick={async () => {
                              eventBusService.publish(
                                new DepositPlayerBalance({
                                  playerId: playerStore.player.id,
                                }),
                              );
                            }}
                            disabled={isActionsDisabled}
                          />
                        </Col>
                      </>
                    ) : (
                      <Col flex={1}>
                        <Space direction="vertical">
                          <Row gutter={4} justify="end">
                            <Col flex={1}>
                              {intl.formatMessage({
                                defaultMessage: "Entries",
                              })}
                              {": "}
                            </Col>
                            <Col>
                              <MoneyFormatter
                                cents={
                                  playerStore.player.sweepstake?.entries ?? 0
                                }
                                fractionDigits={0}
                              />
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{
                                  borderColor: green[3],
                                  backgroundColor: green[3],
                                }}
                                icon={<PlusOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "Deposit",
                                })}
                                onClick={async () => {
                                  eventBusService.publish(
                                    new DepositPlayerBalance({
                                      playerId: playerStore.player.id,
                                    }),
                                  );
                                }}
                                disabled={isActionsDisabled}
                              />
                            </Col>
                          </Row>
                          <Row gutter={4} justify="end">
                            <Col flex={1}>
                              {intl.formatMessage({
                                defaultMessage: "Total win",
                              })}
                              {": "}
                            </Col>
                            <Col>
                              <MoneyFormatter
                                cents={playerStore.player.sweepstake?.win ?? 0}
                              />{" "}
                              {playerStore.player.currency}
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{
                                  borderColor: red[3],
                                  backgroundColor: red[3],
                                }}
                                disabled={isActionsDisabled}
                                icon={<MinusOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "Withdraw",
                                })}
                                onClick={async () => {
                                  eventBusService.publish(
                                    new WithdrawPlayerBalance({
                                      playerId: playerStore.player.id,
                                    }),
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </Space>
                      </Col>
                    )}
                    <Col>
                      <Divider type="vertical" />
                    </Col>
                    <Col>
                      <Button
                        icon={<ReloadOutlined />}
                        title={intl.formatMessage({ defaultMessage: "Reload" })}
                        onClick={playerStore.fetch}
                      />
                    </Col>
                    <Col>
                      <Popover
                        trigger="click"
                        zIndex={999}
                        content={
                          <Row gutter={[16, 8]}>
                            {userStore.user.role !== "HallAdmin" &&
                              userStore.user.role !== "Cashier" && (
                                <Col>
                                  <AncestorsTreeButton
                                    size="middle"
                                    entity={{
                                      hallId: playerStore.player.hallId,
                                    }}
                                  />
                                </Col>
                              )}
                            {isDualBalanceSweepstake &&
                              userStore.user.role !== "HallAdmin" &&
                              userStore.user.role !== "Cashier" && (
                                <Col>
                                  <Button
                                    icon={<SwapOutlined />}
                                    title={intl.formatMessage({
                                      defaultMessage: "Exchange",
                                    })}
                                    onClick={async () => {
                                      eventBusService.publish(
                                        new ExchangePlayerBalance({
                                          playerId: playerStore.player.id,
                                          operationType: "entries-to-total-win",
                                        }),
                                      );
                                    }}
                                  />
                                </Col>
                              )}
                            {userStore.user.role !== "Cashier" && (
                              <Col>
                                <Button
                                  icon={<EditOutlined />}
                                  title={intl.formatMessage({
                                    defaultMessage: "Edit",
                                  })}
                                  onClick={() => {
                                    eventBusService.publish(
                                      new UpdatePlayer({
                                        playerId: playerStore.player.id,
                                      }),
                                    );
                                  }}
                                />
                              </Col>
                            )}
                            <Col>
                              <AreYouSure
                                content={
                                  playerStore.player.login.startsWith(
                                    "kiosk_",
                                  ) ? (
                                    <Alert
                                      type="error"
                                      message={intl.formatMessage({
                                        defaultMessage: "Important!",
                                      })}
                                      description={intl.formatMessage({
                                        defaultMessage:
                                          "Blocking a player belonging to a Kiosk will make it impossible to accept money at the kiosk.",
                                      })}
                                    />
                                  ) : null
                                }
                                onYes={playerStore.block}
                                disabled={playerStore.player.isBlocked}
                              >
                                <Button
                                  icon={
                                    playerStore.player.isBlocked ? (
                                      <UnlockOutlined />
                                    ) : (
                                      <LockOutlined />
                                    )
                                  }
                                  title={
                                    playerStore.player.isBlocked
                                      ? intl.formatMessage({
                                          defaultMessage: "Unblock",
                                        })
                                      : intl.formatMessage({
                                          defaultMessage: "Block",
                                        })
                                  }
                                  onClick={
                                    playerStore.player.isBlocked
                                      ? playerStore.unblock
                                      : undefined
                                  }
                                />
                              </AreYouSure>
                            </Col>
                            <Col>
                              <Button
                                icon={<QrcodeOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "QR code",
                                })}
                                onClick={() => {
                                  state.playerTokenModalState.open(
                                    playerStore.player.hallId,
                                    playerStore.player.id,
                                  );
                                }}
                              />
                            </Col>
                            <Col>
                              <DeleteAction
                                entity={{ playerId: playerStore.player.id }}
                                isDeleted={!!playerStore.player.deletedAt}
                                onSuccess={state.filter}
                              />
                            </Col>
                          </Row>
                        }
                        placement="bottomRight"
                      >
                        <Button icon={<CaretDownOutlined />} />
                      </Popover>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          query={state.filterQuery}
          onChange={() => {
            state.filter({ preservePageNumber: true });
          }}
        />
      </Space>
    </Spin>
  );
}

type TChild = { props: { record: PlayerStore } };

type TPlayersTableRowProps = {
  className: string;
  children: TChild | TChild[];
};

const PlayersTableRow = observer(function PlayersTableRow(
  props: TPlayersTableRowProps,
) {
  if (!Array.isArray(props.children)) {
    return <tr {...props} />;
  }
  const playerStore = props.children[0].props.record;
  if (playerStore.fetchQuery.isPending || playerStore.updateQuery.isPending) {
    return (
      <tr className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>
    );
  }
  return (
    <tr
      {...props}
      className={classNames(props.className, {
        "ant-table-row-color-red": playerStore.player.isBlocked,
        "ant-table-row-color-gray": !!playerStore.player.deletedAt,
      })}
    />
  );
});

export default observer(PlayersTable);
