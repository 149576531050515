import { Button, Card, Col, Row, Space, Spin, Table } from "antd";
import classNames from "classnames";
import { snakeCase } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TSweepstakesReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { SweepstakesReportState } from "./sweepstakes-report.state";

export const SweepstakesReport = observer(() => {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new SweepstakesReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  const { formatMessage: _ } = intl;
  return (
    <Page title={intl.formatMessage({ defaultMessage: "Entries Changing" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>

      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              dataSource={state.filterQuery.items}
              showHeader={!!state.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
              components={{
                body: {
                  row: (props: any) => {
                    const item = props.children?.[0]?.props?.record;
                    const type = snakeCase(item?.type);
                    return (
                      <tr
                        {...props}
                        className={classNames(
                          props.className,
                          type === "out_reserved"
                            ? "ant-table-row-color-gray"
                            : "",
                        )}
                      />
                    );
                  },
                },
              }}
            >
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex="id"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Date/time" })}
                dataIndex="createdAt"
                render={(_, item: TSweepstakesReportItem) =>
                  new Date(item.createdAt).toLocaleString()
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Agent" })}
                dataIndex="agentName"
                render={(agentName) => agentName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Shop" })}
                dataIndex="hallName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Player" })}
                dataIndex="playerName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Type" })}
                dataIndex="type"
                render={(type) =>
                  _({
                    id: `reports/sweepstakes/operation_type/${snakeCase(type)}`,
                    defaultMessage: type,
                  })
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Entries" })}
                dataIndex="entries"
                render={(value: number) => (
                  <>
                    {value > 0 ? "+" : ""}
                    <MoneyFormatter cents={value} fractionDigits={0} />
                  </>
                )}
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Total Win" })}
                dataIndex="totalWin"
                render={(value: number) => (
                  <>
                    {value > 0 ? "+" : ""}
                    <MoneyFormatter cents={value} />
                  </>
                )}
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Currency" })}
                dataIndex="currency"
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "User" })}
                dataIndex="userName"
              />
            </Table>
            <Pagination
              query={state.filterQuery}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
});
