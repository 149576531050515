import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { HallAPI } from "@/api";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { THallWithdrawSchema } from "@/schemas";
import { CurrencyUtilities } from "@/utilities";

import { HallStore } from ".";

export class HallWithdrawStore {
  constructor(public readonly hallStore: HallStore) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.hallStore.intlService.intl;
  }

  modal = new Modal();
  withdrawQuery = new Query(HallAPI.withdrawMoney);

  private _amount?: number;

  get amount() {
    return this._amount;
  }

  setAmount = (amount?: number) => {
    this._amount = amount;
  };

  handleSubmit = async (values: THallWithdrawSchema) => {
    await this.withdrawQuery.submit({
      ...values,
      id: this.hallStore.hall.id,
      amount: CurrencyUtilities.toMinorUnits(values.amount),
    });

    if (!this.withdrawQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage:
          "The money has been successfully withdrawn from the balance.",
      }),
    });

    this.modal.close();

    this.hallStore.fetch();
  };
}
