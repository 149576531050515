import { yupResolver } from "@hookform/resolvers/yup";
import { MenuProps, notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { GameCategoryAPI, LanguageAPI } from "@/api";
import { CategoryCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { useQueriesState } from "@/utilities/querie-state";

import { NewCategoryFormProps } from "./form";
import { NewCategoryFormSchema, useNewCategoryFormSchema } from "./form.schema";

export function useNewCategoryFormState({ parentId }: NewCategoryFormProps) {
  const intl = useIntl();

  const parentCategoryQuery = useMemo(() => {
    const query = new Query(async () => {
      if (!parentId) {
        return null;
      }
      return await GameCategoryAPI.getById({ id: parentId });
    });
    query.submit({});
    return query;
  }, [parentId]);

  const parentCategory = parentCategoryQuery.data;

  const languagesQuery = useMemo(() => {
    const query = new Query(async () => {
      return await LanguageAPI.getAll();
    });
    query.submit({});
    return query;
  }, []);

  const languages = languagesQuery.data?.data ?? [];

  const schema = useNewCategoryFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<NewCategoryFormSchema>({
    resolver,
    defaultValues: {
      names: { en: "" },
      parentId: parentId ?? undefined,
      isActive: true,
      tag: null,
    },
  });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async ({ tag = null, ...data }: NewCategoryFormSchema) => {
      await GameCategoryAPI.create({ ...data, tag });

      notification.success({
        message: "",
        description: "Category created successfully!",
      });

      eventBusService.publish(new CategoryCreated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  const queriesState = useQueriesState([parentCategoryQuery, languagesQuery]);

  const names = form.watch("names");

  const usedLanguages = Object.keys(names);
  const unusedLanguages = languages
    .map((language) => language.code)
    .filter((code) => !usedLanguages.includes(code));

  const addLanguageMenu: MenuProps = {
    onClick(item) {
      const names = form.watch("names", {});

      form.setValue("names", { ...names, [item.key]: "" });
    },
    items: unusedLanguages.map((code) => ({
      key: code,
      label: code.toUpperCase(),
    })),
  };

  const removeLanguage = (code: string) => {
    const names = form.watch("names", {});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [code]: _, ...rest } = names;

    form.setValue("names", rest);
  };

  return {
    parentId,
    intl,
    queriesState,
    parentCategory,
    languages,
    form,
    handleSubmit,
    mutation,

    usedLanguages,
    unusedLanguages,
    addLanguageMenu,
    removeLanguage,
  };
}

export type NewCategoryFormState = ReturnType<typeof useNewCategoryFormState>;
