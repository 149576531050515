import { Alert, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";

import { HistoryReportAPI } from "@/api";
import hljs from "@/hljs";
import Query from "@/models/query";

type TProps = {
  id: number;
};

function ActionDetails({ id }: TProps) {
  const query = useMemo(() => new Query(HistoryReportAPI.getActionDetails), []);
  useEffect(() => {
    query.submit({ id });
    return () => {
      query.reset();
    };
  }, [query, id]);
  if (query.isIdle || query.isPending) {
    return (
      <Spin spinning={true}>
        <div style={{ width: 100, height: 100 }} />
      </Spin>
    );
  }
  if (query.isRejected) {
    return <Alert type="error" message={query.error} />;
  }
  const formattedCode = JSON.stringify(JSON.parse(query.data), null, 2);
  const highlightedCode = hljs.highlight(formattedCode, {
    language: "json",
  }).value;
  return (
    <pre>
      <code dangerouslySetInnerHTML={{ __html: highlightedCode }} />
    </pre>
  );
}

export default observer(ActionDetails);
