import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { DailyLoginRewardAPI } from "@/api/modules/daily-login-reward";
import Query from "@/models/query";
import {
  hoursToSeconds,
  secondsToHours,
} from "@/utilities/date-time-utilities";

import { DailyLoginRewardsFormProps } from "./form";
import {
  DailyLoginRewardsFormSchema,
  useDailyLoginRewardsFormSchema,
} from "./form.schema";

export function useDailyLoginRewardsFormState(
  props: DailyLoginRewardsFormProps,
) {
  const { hallId } = props;

  const intl = useIntl();

  const schema = useDailyLoginRewardsFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<DailyLoginRewardsFormSchema>({
    resolver,
    defaultValues: {
      isActive: false,
      cooldown: 0,
      sequenceSettings: {},
    },
    reValidateMode: "onSubmit",
  });

  const query = useMemo(() => {
    const query = new Query(DailyLoginRewardAPI.get);
    query.submit({ hallId }).then(() => {
      let values = schema.cast(query.data, {
        stripUnknown: true,
        assert: false,
      });
      values = secondsToHours(values, ["cooldown"]);
      form.reset(values);
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutation = useMemo(
    () =>
      new Query(
        async (...args: Parameters<typeof DailyLoginRewardAPI.update>) => {
          await DailyLoginRewardAPI.update(...args);

          notification.success({
            message: "",
            description: intl.formatMessage({
              defaultMessage: "Daily login rewards updated successfully",
            }),
          });
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit((values) => {
    values = hoursToSeconds(values, ["cooldown"]);
    const sequenceSettings = Object.fromEntries(
      Object.entries(values.sequenceSettings).filter(([_, value]) => value),
    ) as DailyLoginRewardsFormSchema["sequenceSettings"];
    mutation.submit({ hallId, ...values, sequenceSettings });
  });

  return { ...props, intl, form, handleSubmit, query, mutation };
}

export type DailyLoginRewardsFormState = ReturnType<
  typeof useDailyLoginRewardsFormState
>;
