import MethodBuilder from "../../method-builder";
import { TPaging, TVouchersReportItem } from "../../types";

export class VouchersReportAPI {
  static filter = new MethodBuilder<
    ({ hallId: number } | { playerId: number }) & {
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      status?: "issued" | "redeemed";
      paging: TPaging;
    },
    {
      data: TVouchersReportItem[];
      total: number;
    }
  >()
    .withName("VouchersReport.filter")
    .build();
}
