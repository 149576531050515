import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Table } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import { JackpotTemplateAPI } from "@/api";
import { useExportHandler, useImportHandler } from "@/hooks";
import Query from "@/models/query";

function JackpotTemplates() {
  const [orderBy, setOrderBy] = useState<["id", "asc" | "desc"]>(["id", "asc"]);

  const [getAllQuery] = useState(() => {
    const query = new Query(JackpotTemplateAPI.getAll);
    query.submit({});
    return query;
  });
  const [importQuery] = useState(() => new Query(JackpotTemplateAPI.import));
  const [exportQuery] = useState(() => new Query(JackpotTemplateAPI.export));

  const handleImportClick = useImportHandler({ getAllQuery, importQuery });

  const handleExportClick = useExportHandler({
    exportQuery,
    fileName: "jackpot-templates.csv",
  });

  useEffect(() => {
    getAllQuery.submit({ orderBy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  const handleTableChange = useCallback(
    (
      pagination: any,
      filters: any,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        sorter.columnKey === "id"
      ) {
        setOrderBy([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
    },
    [],
  );

  return (
    <Space direction="vertical">
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              loading={importQuery.isPending}
              onClick={handleImportClick}
            >
              {"Import"}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<UploadOutlined />}
              loading={exportQuery.isPending}
              onClick={handleExportClick}
            >
              {"Export"}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Table
          loading={getAllQuery.isPending}
          dataSource={getAllQuery.isFulfilled ? getAllQuery.data.data : []}
          pagination={false}
          size="small"
          rowKey="id"
          onChange={handleTableChange}
        >
          <Table.Column
            title={"ID"}
            key="id"
            dataIndex="id"
            sorter={true}
            defaultSortOrder={orderBy[1] === "asc" ? "ascend" : "descend"}
            sortDirections={["ascend", "descend", "ascend"]}
          />
          <Table.Column title={"Name"} dataIndex="name" width="100%" />
          <Table.Column
            title={"Expected Monthly ΣBET"}
            dataIndex="expectedMonthlyBetSum"
          />
          <Table.Column
            title={"Payout enabled"}
            dataIndex="isPayoutEnabled"
            render={(isPayoutEnabled) => (isPayoutEnabled ? "Yes" : "No")}
          />
          <Table.Column
            title={"Enabled"}
            dataIndex="isEnabled"
            render={(isEnabled) => (isEnabled ? "Yes" : "No")}
          />
        </Table>
      </Card>
    </Space>
  );
}

export default observer(JackpotTemplates);
