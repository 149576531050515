import { startOfMonth, endOfDay, isValid } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment-timezone";

import {
  AgentAPI,
  ClientAPI,
  DailyProfitReportAPI,
  HallAPI,
  ProviderAPI,
} from "@/api";
import { SearchParamKey } from "@/constants";
import { ViewModel } from "@/hooks/use-view-model";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import { GlobalStore, SelectorStore } from "@/stores";
import { parseInteger } from "@/utilities";

export class DailyProfitReportState implements ViewModel<unknown> {
  constructor(private globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount() {
    this.useInitialSearchParams();
    this.updateSearchParams();
    this.updateSelectorParameters();
    this.apply();
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get userStore() {
    return this.globalStore.userStore;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  _tab = "currency";

  get tab() {
    return this._tab;
  }

  set tab(value: string) {
    this._tab = value;
    this.apply();
  }

  private handleClientSelect = () => {
    this.agentSelectorStore.setSelectedId();
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleAgentSelect = () => {
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleSubagentSelect = () => {
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleHallSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleProviderSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  public readonly clientSelectorStore = new SelectorStore({
    filterMethod: ClientAPI.filter,
    getByIdMethod: ClientAPI.getById,
    labelKey: "name",
    onSelect: this.handleClientSelect,
  });

  public readonly agentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleAgentSelect,
  });

  public readonly hallSelectorStore = new SelectorStore({
    filterMethod: HallAPI.filter,
    getByIdMethod: HallAPI.getById,
    labelKey: "name",
    onSelect: this.handleHallSelect,
  });

  public readonly providerSelectorStore = new SelectorStore({
    filterMethod: ProviderAPI.filter,
    getByIdMethod: ProviderAPI.getById,
    labelKey: "name",
    onSelect: this.handleProviderSelect,
    isPaginationEnabled: false,
  });

  private _currency?: string = (() => {
    if (this.userStore.hall) {
      return this.userStore.hall.currency;
    } else if (this.userStore.agent) {
      return this.userStore.agent.currency ?? undefined;
    } else if (this.userStore.client) {
      return this.userStore.client.currency ?? undefined;
    }
  })();

  get currency() {
    return this._currency;
  }

  setCurrency = (currency?: string) => {
    this._currency = currency;
    this.updateSearchParams();
  };

  private _dateTimeRange: [Date, Date] = [
    startOfMonth(new Date()),
    endOfDay(new Date()),
  ];

  get dateTimeRange() {
    return this._dateTimeRange;
  }

  setDateTimeRange = (dateTimeRange: [Date, Date]) => {
    this._dateTimeRange = dateTimeRange;
    this.updateSearchParams();
  };

  private _timeZone: string = moment.tz.guess(true);

  get timeZone() {
    return this._timeZone;
  }

  setTimeZone = (timeZone: string) => {
    this._timeZone = timeZone;
    this.updateSearchParams();
  };

  get entity() {
    let params: {
      clientId: number;
      agentId?: number;
      hallId?: number;
      providerId?: number;
    } | null = null;
    if (this.clientSelectorStore.selectedId) {
      params = {
        clientId: this.clientSelectorStore.selectedId,
      };
    }
    if (this.agentSelectorStore.selectedId && params !== null) {
      params.agentId = this.agentSelectorStore.selectedId;
    }
    if (this.hallSelectorStore.selectedId && params !== null) {
      params.hallId = this.hallSelectorStore.selectedId;
    }
    if (this.providerSelectorStore.selectedId && params !== null) {
      params.providerId = this.providerSelectorStore.selectedId;
    }
    return params;
  }

  filterByCurrencyQuery = new ItemsQuery(DailyProfitReportAPI.filter, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  filterByCurrency = async (options?: TSubmitOptions) => {
    if (this.entity === null) {
      return;
    }

    const from = moment(this.dateTimeRange[0])
      .tz(this.timeZone, true)
      .toISOString(true);

    const to = moment(this.dateTimeRange[1])
      .tz(this.timeZone, true)
      .toISOString(true);

    await this.filterByCurrencyQuery.submit(
      {
        ...this.entity,
        groupBy: "currency",
        currency: this.currency,
        dateTimeRange: { from, to },
      },
      options,
    );
  };

  filterByAgentQuery = new ItemsQuery(DailyProfitReportAPI.filter, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  filterByAgent = async (options?: TSubmitOptions) => {
    if (this.entity === null) {
      return;
    }

    const from = moment(this.dateTimeRange[0])
      .tz(this.timeZone, true)
      .toISOString(true);

    const to = moment(this.dateTimeRange[1])
      .tz(this.timeZone, true)
      .toISOString(true);

    await this.filterByAgentQuery.submit(
      {
        ...this.entity,
        groupBy: "agent",
        currency: this.currency,
        dateTimeRange: { from, to },
      },
      options,
    );
  };

  filterByHallQuery = new ItemsQuery(DailyProfitReportAPI.filter, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  filterByHall = async (options?: TSubmitOptions) => {
    if (this.entity === null) {
      return;
    }

    const from = moment(this.dateTimeRange[0])
      .tz(this.timeZone, true)
      .toISOString(true);

    const to = moment(this.dateTimeRange[1])
      .tz(this.timeZone, true)
      .toISOString(true);

    await this.filterByHallQuery.submit(
      {
        ...this.entity,
        groupBy: "hall",
        currency: this.currency,
        dateTimeRange: { from, to },
      },
      options,
    );
  };

  apply = async () => {
    const method = {
      currency: this.filterByCurrency,
      agent: this.filterByAgent,
      hall: this.filterByHall,
    }[this.tab];
    await method?.();
  };

  private useInitialSearchParams = () => {
    const { client, agent, hall } = this.userStore;

    if (client) {
      this.clientSelectorStore.setSelectedId(client.id, false);
    } else if (agent) {
      this.clientSelectorStore.setSelectedId(agent.clientId, false);
      this.agentSelectorStore.setSelectedId(agent.id, false);
    } else if (hall) {
      this.clientSelectorStore.setSelectedId(hall.clientId, false);
      this.agentSelectorStore.setSelectedId(hall.agentId ?? undefined, false);
      this.hallSelectorStore.setSelectedId(hall.id, false);
    }

    const { searchParams } = this.routerService;
    const initialClientId = parseInteger(searchParams[SearchParamKey.ClientId]);
    const initialAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.AgentId])
        : undefined;
    const initialHallId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.HallId])
        : undefined;

    runInAction(() => {
      this._currency = searchParams[SearchParamKey.Currency];

      const df = new Date(searchParams[SearchParamKey.DateFrom]);
      const dt = new Date(searchParams[SearchParamKey.DateTo]);
      if (isValid(df) && isValid(dt) && df < dt) {
        this._dateTimeRange = [df, dt];
      }

      const timeZones = moment.tz.names();
      const timeZone = searchParams[SearchParamKey.TimeZone];
      if (timeZones.includes(timeZone)) {
        this._timeZone = timeZone;
      }
    });

    if (this.permissionsStore.has("SelectClient")) {
      this.clientSelectorStore.setSelectedId(initialClientId);
    }
    if (this.clientSelectorStore.selectedId) {
      if (this.permissionsStore.has("SelectAgent")) {
        this.agentSelectorStore.setSelectedId(initialAgentId);
      }
      if (this.permissionsStore.has("SelectHall")) {
        this.hallSelectorStore.setSelectedId(initialHallId);
      }
    }
  };

  private updateSearchParams() {
    const urlSearchParams = new URLSearchParams(
      this.routerService.location.search,
    );

    if (this.clientSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ClientId,
        this.clientSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ClientId);
    }

    if (this.agentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.agentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.AgentId);
    }

    if (this.hallSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.HallId,
        this.hallSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.HallId);
    }

    if (this.providerSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ProviderId,
        this.providerSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ProviderId);
    }

    if (this.currency) {
      urlSearchParams.set(SearchParamKey.Currency, this.currency);
    } else {
      urlSearchParams.delete(SearchParamKey.Currency);
    }

    urlSearchParams.set(
      SearchParamKey.DateFrom,
      moment(this.dateTimeRange[0]).toISOString(true),
    );
    urlSearchParams.set(
      SearchParamKey.DateTo,
      moment(this.dateTimeRange[1]).toISOString(true),
    );

    urlSearchParams.set(SearchParamKey.TimeZone, this.timeZone);

    this.routerService.replace({
      search: urlSearchParams.toString(),
    });
  }

  private updateSelectorParameters = () => {
    const oldClientParameters = this.clientSelectorStore.parameters;
    const oldAgentParameters = this.agentSelectorStore.parameters;
    const oldHallParameters = this.hallSelectorStore.parameters;
    const oldProviderParameters = this.providerSelectorStore.parameters;

    let newClientParameters = oldClientParameters;
    let newAgentParameters = oldAgentParameters;
    let newHallParameters = oldHallParameters;
    let newProviderParameters = oldProviderParameters;

    const clientId = this.clientSelectorStore.selectedId;
    const agentId = this.agentSelectorStore.selectedId;

    const { user } = this.userStore;
    newClientParameters = {
      managerId: user.role === "Manager" ? user.id : undefined,
    };

    newProviderParameters = {};

    if (clientId) {
      newAgentParameters = { clientId };
      newHallParameters = { clientId };
    }

    if (agentId) {
      newHallParameters = { agentId };
    }

    if (
      this.permissionsStore.has("SelectClient") &&
      JSON.stringify(oldClientParameters) !==
        JSON.stringify(newClientParameters)
    ) {
      this.clientSelectorStore.setParameters(newClientParameters);
      this.clientSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldAgentParameters) !== JSON.stringify(newAgentParameters)
    ) {
      this.agentSelectorStore.setParameters(newAgentParameters);
      this.agentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectHall") &&
      JSON.stringify(oldHallParameters) !== JSON.stringify(newHallParameters)
    ) {
      this.hallSelectorStore.setParameters(newHallParameters);
      this.hallSelectorStore.fetchItems();
    }

    if (
      JSON.stringify(oldProviderParameters) !==
      JSON.stringify(newProviderParameters)
    ) {
      this.providerSelectorStore.setParameters(newProviderParameters);
      this.providerSelectorStore.fetchItems();
    }
  };
}
