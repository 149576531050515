import { useIntl } from "react-intl";

import { OpenAddKioskAccessModal, CloseAddKioskAccessModal } from "@/events";

import { EventBasedModal } from "../../_common_/event-based-modal";

import { AddKioskAccessForm } from "./form";

export const AddKioskAccessModal = () => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Add kiosk access" })}
      openEvent={OpenAddKioskAccessModal}
      closeEvent={CloseAddKioskAccessModal}
    >
      {({ clientId, hallId, kioskId }) => (
        <AddKioskAccessForm
          clientId={clientId}
          hallId={hallId}
          kioskId={kioskId}
        />
      )}
    </EventBasedModal>
  );
};
