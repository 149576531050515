import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { CashierAPI, HallAPI } from "@/api";
import { CashierCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { CashierCreateFormProps } from "./cashier-create-form";
import {
  CashierCreateFormSchema,
  useCashierCreateFormSchema,
} from "./cashier-create-form.schema";

export function useCashierCreateFormState({ hallId }: CashierCreateFormProps) {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const schema = useCashierCreateFormSchema();
  const form = useForm<CashierCreateFormSchema>({
    resolver: yupResolver(schema),
  });

  const hallQuery = useMemo(() => {
    const query = new Query(HallAPI.getById);
    query.submit({ id: hallId });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuery = useMemo(() => {
    return new Query(async (values: CashierCreateFormSchema) => {
      await CashierAPI.create({ hallId, ...values, transactionsLimit: 0 });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The cashier has been successfully created.",
        }),
      });

      eventBusService.publish(new CashierCreated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hallId,
    intl,
    form,
    hallQuery,
    submitQuery,
  };
}

export type CashierCreateFormState = ReturnType<
  typeof useCashierCreateFormState
>;
