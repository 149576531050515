import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { RuleAPI } from "@/api";
import Query from "@/models/query";

import { RulesFormProps } from "./form";
import { RulesFormSchema, useRulesFormSchema } from "./form.schema";

export function useRulesFormState({ entity, type }: RulesFormProps) {
  const intl = useIntl();

  const schema = useRulesFormSchema();
  const form = useForm<RulesFormSchema>({
    resolver: yupResolver(schema),
  });

  const fetchQuery = useMemo(() => {
    const query = new Query(RuleAPI.getById);
    query.submit({ ...entity, type });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchQuery.data === undefined) {
      return;
    }

    if (fetchQuery.data === null) {
      form.reset({
        text: "",
        version: "",
        showInLobby: false,
      });
      return;
    }

    const values = schema.cast(fetchQuery.data, { stripUnknown: true });
    form.reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchQuery.data]);

  const submitQuery = useMemo(() => {
    const query = new Query(async (values: RulesFormSchema) => {
      await RuleAPI.update({
        ...entity,
        rule: {
          type,
          ...values,
        },
      });

      const rule = await RuleAPI.getById({ type, ...entity });
      fetchQuery.resolve({ type, ...entity }, rule);

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The settings have been successfully updated.",
        }),
      });
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteQuery = useMemo(() => {
    return new Query(async () => {
      await RuleAPI.delete({ ...entity, type });

      const rule = await RuleAPI.getById({ type, ...entity });
      fetchQuery.resolve({ type, ...entity }, rule);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, form, fetchQuery, submitQuery, deleteQuery };
}

export type RulesFormState = ReturnType<typeof useRulesFormState>;
