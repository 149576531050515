import { TCurrentBonus, TPaging, TPlayer, TPlayerInfo } from "..";
import MethodBuilder from "../method-builder";

class PlayerAPI {
  static filter = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      currency?: string;
      searchQuery?: string;
      includeDeleted?: boolean;
      orderBy?: ["id" | "login" | "createdAt", "asc" | "desc"];
      paging: TPaging;
    },
    {
      data: TPlayer[];
      total: number;
    }
  >()
    .withName("Player.filter")
    .build();

  static getById = new MethodBuilder<{ id: number }, TPlayer>()
    .withName("Player.getById")
    .build();

  static create = new MethodBuilder<
    {
      hallId: number;
      login: string;
      name: string;
      email: string;
      phone: string;
      password: string;
      balance?: number;
      isBlocked?: boolean;
      bonusId?: number;
    },
    TPlayer
  >()
    .withName("Player.create")
    .build();

  static generate = new MethodBuilder<
    {
      hallId: number;
      initialBalance: number;
    },
    TPlayer & { password: string }
  >()
    .withName("Player.generate")
    .build();

  static massGenerate = new MethodBuilder<
    {
      hallId: number;
      prefix: string;
      initialBalance: number;
      websiteId: number;
      quantity: number;
    },
    Array<{
      login: string;
      password: string;
      balance: number;
      currency: string;
    }>
  >()
    .withName("Player.massGenerate")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      name?: string;
      email?: string;
      phone?: string;
      password?: string;
      isBlocked?: boolean;
    },
    void
  >()
    .withName("Player.update")
    .build();

  static depositMoney = new MethodBuilder<
    {
      id: number;
      amount: number;
      bonusId?: number;
      kioskId?: number;
    },
    void
  >()
    .withName("Player.depositMoney")
    .build();

  static withdrawMoney = new MethodBuilder<
    {
      id: number;
      amount: number;
      kioskId?: number;
    },
    void
  >()
    .withName("Player.withdrawMoney")
    .build();

  static getAuthToken = new MethodBuilder<{ id: number }, string>()
    .withName("Player.getAuthToken")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Player.delete")
    .build();

  static undelete = new MethodBuilder<{ id: number }, void>()
    .withName("Player.undelete")
    .build();

  static getInfo = new MethodBuilder<{ id: number }, TPlayerInfo>()
    .withName("Player.getInfo")
    .build();

  static getCurrentBonus = new MethodBuilder<
    {
      id: number;
      paging: TPaging;
    },
    {
      data: TCurrentBonus[];
      total: number;
    }
  >()
    .withName("Player.getCurrentBonus")
    .build();

  static cancelBonus = new MethodBuilder<
    { id: number; type: "fullCancel" | "wagerReset" },
    void
  >()
    .withName("Player.cancelBonus")
    .build();

  static import = new MethodBuilder<
    {
      hallId: number;
      format: "csv";
      data: string;
    },
    void
  >()
    .withName("Player.import")
    .build();
}

export default PlayerAPI;
