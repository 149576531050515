import { observer } from "mobx-react-lite";
import { FC } from "react";

import { GeneralSelectorsStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";

type Props = {
  store: GeneralSelectorsStore;
};
export const GeneralSelectorErrors: FC<Props> = observer(({ store }) => {
  return (
    <ErrorsFormatter
      queries={[
        store.clientSelectorStore.getByIdQuery,
        store.clientSelectorStore.filterQuery,

        store.agentSelectorStore.getByIdQuery,
        store.agentSelectorStore.filterQuery,

        store.subagentSelectorStore.getByIdQuery,
        store.subagentSelectorStore.filterQuery,

        store.hallSelectorStore.getByIdQuery,
        store.hallSelectorStore.filterQuery,
      ]}
    />
  );
});
