import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, List, Space, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { match } from "ts-pattern";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { ReceiptEditor } from "../receipt-editor";

import { ReceiptListState } from "./receipt-list.state";

interface Props {
  state: ReceiptListState;
}

export const ReceiptListView = observer<Props>(
  ({
    state: {
      intl,

      listQuery,
      submitQuery,
      cancelQuery,

      selectedReceipt,
      setSelectedReceipt,

      addReceiptTask,
    },
  }) => {
    if (selectedReceipt) {
      return (
        <ReceiptEditor
          defaultFontSize={8}
          scaleFontSize={(size) => size * 2}
          lines={selectedReceipt.lines}
          submitQuery={submitQuery}
          cancelQuery={cancelQuery}
          header={
            <Form
              component="div"
              size="small"
              labelCol={{ xs: { span: 8 } }}
              labelAlign="right"
              wrapperCol={{ xs: { span: 16 } }}
              layout="horizontal"
            >
              <ErrorsFormatter queries={[submitQuery]} />
              <Form.Item label={intl.formatMessage({ defaultMessage: "Type" })}>
                <ReceiptType>{selectedReceipt.type}</ReceiptType>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ defaultMessage: "Paper width" })}
              >
                {intl.formatMessage(
                  { defaultMessage: "{width}mm" },
                  { width: selectedReceipt.width.toFixed(0) },
                )}
              </Form.Item>
            </Form>
          }
        />
      );
    }

    if (listQuery.isIdle || listQuery.isPending) {
      return <FormSpinner />;
    }

    if (listQuery.isRejected) {
      return <ErrorsFormatter queries={[listQuery]} />;
    }

    return (
      <List
        style={{ width: "100%", padding: 8 }}
        header={
          <Typography.Title level={4}>
            {intl.formatMessage({ defaultMessage: "Templates" })}
          </Typography.Title>
        }
        footer={
          <List.Item
            actions={[
              <Spin
                key="add-button"
                spinning={addReceiptTask.isPending}
                size="small"
              >
                <Dropdown
                  menu={{
                    items: [
                      "withdrawal",
                      "deposit",
                      "deposit_fail",
                      "player_registration",
                    ].map((key) => ({
                      key,
                      label: <ReceiptType>{key}</ReceiptType>,
                    })),
                    onClick: ({ key }) => addReceiptTask.submit({ type: key }),
                  }}
                >
                  <Button size="small">
                    <Space>
                      {intl.formatMessage({ defaultMessage: "Add" })}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Spin>,
            ]}
          >
            {" "}
          </List.Item>
        }
        dataSource={listQuery.data.data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                size="small"
                key="edit-button"
                onClick={() => setSelectedReceipt(item)}
              >
                {intl.formatMessage({ defaultMessage: "Edit" })}
              </Button>,
            ]}
          >
            <ReceiptType>{item.type}</ReceiptType>
          </List.Item>
        )}
        rowKey="type"
      />
    );
  },
);

function ReceiptType({ children }: { children: string }) {
  const intl = useIntl();
  return (
    <>
      {match(children)
        .with("deposit", () =>
          intl.formatMessage({
            defaultMessage: "Deposit",
          }),
        )
        .with("deposit_fail", () =>
          intl.formatMessage({
            defaultMessage: "Deposit fail",
          }),
        )
        .with("withdrawal", () =>
          intl.formatMessage({
            defaultMessage: "Withdrawal",
          }),
        )
        .with("player_registration", () =>
          intl.formatMessage({
            defaultMessage: "Player registration",
          }),
        )
        .otherwise(() =>
          intl.formatMessage({
            defaultMessage: "Unknown type",
          }),
        )}
    </>
  );
}
