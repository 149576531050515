import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { TPermission, useGlobalStore } from "@/stores";

type Props = {
  permission: TPermission;
};

export const PermissionGuard: FC<Props> = observer(({ permission }) => {
  const { permissionsStore } = useGlobalStore();
  if (!permissionsStore.has(permission)) {
    return <Navigate to="log-in" replace={true} />;
  }
  return <Outlet />;
});
