import { TWebsiteTemplate } from "..";
import MethodBuilder from "../method-builder";

class WebsiteTemplateAPI {
  static getById = new MethodBuilder<
    { id: number },
    {
      id: number;
      stringId: string;
      name: string;
      description: string;
    }
  >()
    .withName("WebsiteTemplate.getById")
    .build();

  static getAll = new MethodBuilder<void, { data: TWebsiteTemplate[] }>()
    .withName("WebsiteTemplate.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("WebsiteTemplate.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("WebsiteTemplate.export")
    .build();

  static create = new MethodBuilder<
    {
      stringId: string;
      name: string;
      description: string;
    },
    void
  >()
    .withName("WebsiteTemplate.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      name?: string;
      description?: string;
    },
    void
  >()
    .withName("WebsiteTemplate.update")
    .build();
}

export default WebsiteTemplateAPI;
