import yup from "@/yup";

export function useFortuneWheelSettingsSchema() {
  const sectorSchema = yup.object({
    id: yup.number().integer().required(),
    value: yup.number().money().moreThan(0).required(),
    winFrequency: yup.number().integer().min(0).required(),
  });

  return yup.object({
    isEnabled: yup.boolean().required(),
    cooldown: yup.number().integer().min(1).required(),
    maxDepositAge: yup.number().integer().min(0).required(),
    sectors: yup.array(sectorSchema).required(),
  });
}

export type TFortuneWheelSettingsSchema = yup.InferType<
  ReturnType<typeof useFortuneWheelSettingsSchema>
>;
