import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { FinishShift, ShiftFinished } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Shift closing" })}
      openEvent={FinishShift}
      closeEvent={ShiftFinished}
    >
      {({ cashierId }) => <Form cashierId={cashierId} />}
    </EventBasedModal>
  );
});
