import { Space, Form, Row, Button, Col } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormPassword,
  FormSelect,
} from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { BonusInfo } from "./bonus-info";
import { PlayerCreateFormState } from "./player-create-form.state";

interface Props {
  state: PlayerCreateFormState;
}

export const PlayerCreateFormView = observer(({ state }: Props) => {
  const {
    intl,
    form,
    hallQuery,
    hall,
    bonusesQuery,
    bonusesOptions,
    selectedBonus,
    submitQuery,
  } = state;

  if (
    hallQuery.isIdle ||
    hallQuery.isPending ||
    bonusesQuery.isIdle ||
    bonusesQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (hallQuery.isRejected || bonusesQuery.isRejected) {
    return <ErrorsFormatter queries={[hallQuery, bonusesQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="login"
          label={intl.formatMessage({ defaultMessage: "Username" })}
          isRequired={true}
        >
          <FormInput
            form={form}
            path="login"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter username",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="password"
          label={intl.formatMessage({ defaultMessage: "Password" })}
          isRequired={true}
        >
          <FormPassword
            form={form}
            path="password"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter password",
            })}
          />
        </FormItem>
        {!hallQuery.data.cashierSettings.isShortPlayerCreation && (
          <>
            <FormItem
              form={form}
              path="name"
              label={intl.formatMessage({ defaultMessage: "Name" })}
            >
              <FormInput
                form={form}
                path="name"
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter name",
                })}
              />
            </FormItem>
            <FormItem
              form={form}
              path="email"
              label={intl.formatMessage({ defaultMessage: "Email" })}
            >
              <FormInput
                form={form}
                path="email"
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter email",
                })}
              />
            </FormItem>
            <FormItem
              form={form}
              path="phone"
              label={intl.formatMessage({ defaultMessage: "Phone" })}
            >
              <FormInput
                form={form}
                path="phone"
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter phone",
                })}
              />
            </FormItem>
          </>
        )}
        <FormItem
          form={form}
          path="balance"
          label={intl.formatMessage({ defaultMessage: "Balance" })}
        >
          <FormInput
            form={form}
            path="balance"
            type="number"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter balance",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="bonusId"
          label={intl.formatMessage({ defaultMessage: "Bonus" })}
        >
          <Space direction="vertical">
            <FormSelect
              form={form}
              path="bonusId"
              placeholder={intl.formatMessage({
                defaultMessage: "Select bonus",
              })}
              options={bonusesOptions}
            />
            <BonusInfo hall={hall} bonus={selectedBonus} />
          </Space>
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
