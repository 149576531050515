import yup from "@/yup";

export function useNewWebsiteDomainFormSchema() {
  return yup.object({
    domain: yup.string().required(),
    imgPackId: yup.number().required(),
    title: yup.string().required(),
    templateId: yup.number().required(),
  });
}

export type NewWebsiteDomainFormSchema = yup.InferType<
  ReturnType<typeof useNewWebsiteDomainFormSchema>
>;
