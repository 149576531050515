import { TJackpotWeight } from "..";
import MethodBuilder from "../method-builder";

class JackpotWeightAPI {
  static getAll = new MethodBuilder<void, { data: TJackpotWeight[] }>()
    .withName("JackpotWeight.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("JackpotWeight.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("JackpotWeight.export")
    .build();
}

export default JackpotWeightAPI;
