import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Typography,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { Observer, observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { UpdateClient } from "@/events";
import { ClientsStore, ClientStore, useGlobalStore } from "@/stores";
import Pagination from "@/ui/_common_/pagination";

type TProps = {
  clientsStore: ClientsStore;
};

function ClientsTable({ clientsStore: state }: TProps) {
  const { eventBusService } = useGlobalStore();
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ClientStore> | SorterResult<ClientStore>[],
    ) => {
      if (typeof filters["client.currency"]?.[0] === "string") {
        state.setCurrencyCode(filters["client.currency"][0]);
      } else {
        state.setCurrencyCode(undefined);
      }
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        (sorter.columnKey === "id" || sorter.columnKey === "name")
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
      state.filter();
    },
    [state],
  );

  const intl = useIntl();

  return (
    <Spin spinning={state.filterQuery.isPending}>
      <Space direction="vertical">
        <Row>
          <Col xs={0} sm={24}>
            <Table
              dataSource={state.clientStores}
              size="small"
              rowKey={(clientStore) => clientStore.client.id}
              bordered
              pagination={false}
              components={{ body: { row: ClientsTableRow } }}
              onChange={handleTableChange}
            >
              <Table.Column
                key="id"
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex={["client", "id"]}
                align="right"
                sorter={true}
                defaultSortOrder={
                  state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
                }
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="name"
                title={intl.formatMessage({ defaultMessage: "Name" })}
                dataIndex={["client", "name"]}
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
                render={(name, clientStore: ClientStore) => (
                  <>
                    {name}{" "}
                    {clientStore.isSweepstakesEnabled && (
                      <Tag color="green">
                        {intl.formatMessage({ defaultMessage: "Sweepstakes" })}
                      </Tag>
                    )}
                  </>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Manager" })}
                dataIndex={["client", "managerName"]}
                width="100%"
                render={(managerName) => managerName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Actions" })}
                render={(_, clientStore: ClientStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8} justify="end">
                        <Col>
                          <Button
                            size="small"
                            icon={<EditOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Edit",
                            })}
                            onClick={() => {
                              eventBusService.publish(
                                new UpdateClient({
                                  clientId: clientStore.client.id,
                                }),
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Agents",
                })}
                align="right"
                render={(_, clientStore: ClientStore) => (
                  <Link
                    to={{
                      pathname: "/agents",
                      search: new URLSearchParams({
                        cid: clientStore.client.id.toString(),
                      }).toString(),
                    }}
                  >
                    {clientStore.client.agentsCount}
                  </Link>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Shops",
                })}
                align="right"
                render={(_, clientStore: ClientStore) => (
                  <Link
                    to={{
                      pathname: "/shops",
                      search: new URLSearchParams({
                        cid: clientStore.client.id.toString(),
                      }).toString(),
                    }}
                  >
                    {clientStore.client.hallsCount}
                  </Link>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Players",
                })}
                align="right"
                render={(_, clientStore: ClientStore) => (
                  <Link
                    to={{
                      pathname: "/players",
                      search: new URLSearchParams({
                        cid: clientStore.client.id.toString(),
                      }).toString(),
                    }}
                  >
                    {clientStore.client.playersCount}
                  </Link>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Kiosks",
                })}
                align="right"
                render={(_, clientStore: ClientStore) => (
                  <Link
                    to={{
                      pathname: "/kiosks",
                      search: new URLSearchParams({
                        cid: clientStore.client.id.toString(),
                      }).toString(),
                    }}
                  >
                    {clientStore.client.kiosksCount}
                  </Link>
                )}
              />
            </Table>
          </Col>
          <Col xs={24} sm={0}>
            {!state.clientStores.length && <Empty />}
            <Space direction="vertical">
              {state.clientStores.map((clientStore) => (
                <Card key={clientStore.client.id} size="small">
                  <Row gutter={[8, 8]}>
                    {clientStore.isSweepstakesEnabled && (
                      <Tag color="green">
                        {intl.formatMessage({
                          defaultMessage: "Sweepstakes",
                        })}
                      </Tag>
                    )}
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col
                      flex={1}
                      style={{
                        fontWeight: "bold",
                        color: clientStore.client.isBlocked ? "red" : "",
                      }}
                    >
                      {clientStore.client.name}
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {clientStore.client.id}
                    </Col>
                  </Row>
                  {clientStore.client.managerName && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({ defaultMessage: "Manager" })}:{" "}
                        {clientStore.client.managerName}
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[16, 8]}>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Agents" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/agents",
                          search: new URLSearchParams({
                            cid: clientStore.client.id.toString(),
                          }).toString(),
                        }}
                      >
                        {clientStore.client.agentsCount}
                      </Link>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Shops" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/shops",
                          search: new URLSearchParams({
                            cid: clientStore.client.id.toString(),
                          }).toString(),
                        }}
                      >
                        {clientStore.client.hallsCount}
                      </Link>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Players" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/players",
                          search: new URLSearchParams({
                            cid: clientStore.client.id.toString(),
                          }).toString(),
                        }}
                      >
                        {clientStore.client.playersCount}
                      </Link>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Kiosks" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/kiosks",
                          search: new URLSearchParams({
                            cid: clientStore.client.id.toString(),
                          }).toString(),
                        }}
                      >
                        {clientStore.client.kiosksCount}
                      </Link>
                    </Col>
                  </Row>
                  <Divider type="horizontal" style={{ margin: "8px 0" }} />
                  <Row
                    wrap={false}
                    gutter={[8, 8]}
                    align="middle"
                    justify="end"
                  >
                    <Col>
                      <Button
                        icon={<EditOutlined />}
                        title={intl.formatMessage({
                          defaultMessage: "Edit",
                        })}
                        onClick={() => {
                          eventBusService.publish(
                            new UpdateClient({
                              clientId: clientStore.client.id,
                            }),
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          query={state.filterQuery}
          onChange={() => {
            state.filter({ preservePageNumber: true });
          }}
        />
      </Space>
    </Spin>
  );
}

type TChild = { props: { record: ClientStore } };

type TClientsTableRowProps = {
  className: string;
  children: TChild | TChild[];
};

const ClientsTableRow = observer(function ClientsTableRow(
  props: TClientsTableRowProps,
) {
  if (!Array.isArray(props.children)) {
    return <tr {...props} />;
  }
  const clientStore = props.children[0].props.record;
  if (clientStore.fetchQuery.isPending || clientStore.updateQuery.isPending) {
    return (
      <tr className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>
    );
  }
  return (
    <tr
      {...props}
      className={classNames(props.className, {
        "ant-table-row-color-red": clientStore.client.isBlocked,
      })}
    />
  );
});

export default observer(ClientsTable);
