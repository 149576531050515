import yup from "@/yup";

export function useCreateVouchersFormSchema() {
  return yup.object({
    websiteId: yup.number().required(),
    prefix: yup.string().min(0).max(10).defined(),
    initialBalance: yup.number().money().min(0).required(),
    quantity: yup.number().integer().min(1).max(250).required(),
  });
}

export type CreateVouchersFormSchema = yup.InferType<
  ReturnType<typeof useCreateVouchersFormSchema>
>;
