import MethodBuilder from "../method-builder";
import { TSlider } from "../types";

export class SliderAPI {
  static get = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      includeDeleted?: boolean;
    },
    TSlider
  >()
    .withName("Slider.get")
    .build();

  static clone = new MethodBuilder<
    { id: number } & (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
    ),
    void
  >()
    .withName("Slider.clone")
    .build();
}
