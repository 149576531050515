import yup from "@/yup";

export function useReceiptEditorSchema() {
  return yup.object({
    lines: yup
      .array(
        yup.object({
          id: yup.number().integer().required(),
          type: yup.mixed<"text" | "qr">().oneOf(["text", "qr"]).required(),
          align: yup.mixed<"l" | "c" | "r">().oneOf(["l", "c", "r"]).required(),
          size: yup.number().integer().min(1).max(50).required(),
          text: yup.string().defined(),
        }),
      )
      .required(),
  });
}

export type ReceiptEditorSchema = yup.InferType<
  ReturnType<typeof useReceiptEditorSchema>
>;
