import { observer } from "mobx-react-lite";

import { Entity } from "@/types";

import { useJackpotSettingsFormState } from "./jackpot-settings-form.state";
import { JackpotSettingsFormView } from "./jackpot-settings-form.view";

export interface JackpotSettingsFormProps {
  entity: Entity;
}

export const JackpotSettingsForm = observer(
  (props: JackpotSettingsFormProps) => {
    const state = useJackpotSettingsFormState(props);
    return <JackpotSettingsFormView {...{ state }} />;
  },
);
