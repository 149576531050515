import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { BonusAPI, TBonus, TBonusTemplate } from "@/api";
import { BonusUpdated } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { PermissionsStore } from "@/stores";
import { Entity } from "@/types";
import { dollarsToCents } from "@/utilities";

import { Props } from "./Form";
import {
  IBouncebackSchema,
  ICashbackSchema,
  ILastchanceSchema,
} from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public permissionsStore: PermissionsStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _entity?: Entity;

  get entity() {
    return this._entity!;
  }

  private _selfEdit = false;

  get selfEdit() {
    return this._selfEdit;
  }

  private _readOnly = false;

  get readOnly() {
    return this._readOnly;
  }

  private _bonusType?: TBonus["type"];

  get bonusType() {
    return this._bonusType;
  }

  private get bonusId() {
    return {
      cashback: 1,
      bounceback: 2,
      lastchance: 3,
    }[this._bonusType!];
  }

  private _bonus?: TBonus;

  get bonus() {
    return this._bonus!;
  }

  private _parentBonus?: TBonus;

  get parentBonus() {
    return this._parentBonus;
  }

  private _templates?: TBonusTemplate[];

  get templates() {
    return this._templates!;
  }

  get hasAccess() {
    return !!this.bonus;
  }

  onViewMount = async (props: Props) => {
    this._entity = props.entity;
    this._readOnly = props.readOnly ?? false;
    this._selfEdit = props.selfEdit ?? false;
    this._bonusType = props.bonusType;
    this._bonus = props.bonus;
    this._parentBonus = props.parentBonus;
    this._templates = props.templates;
  };

  onViewUpdate = async (props: Props) => {
    this._entity = props.entity;
    this._readOnly = props.readOnly ?? false;
    this._selfEdit = props.selfEdit ?? false;
    this._bonusType = props.bonusType;
    this._bonus = props.bonus;
    this._parentBonus = props.parentBonus;
    this._templates = props.templates;
  };

  onViewUnmount = () => {
    this._entity = undefined;
    this._bonusType = undefined;
    this._bonus = undefined;
    this._templates = undefined;
  };

  get isIdleOrPending() {
    if (
      this.entity === undefined ||
      this._bonusType === undefined ||
      this.templates === undefined
    ) {
      return true;
    }

    return false;
  }

  grandAccessQuery = new Query(BonusAPI.grantAccess);
  revokeAccessQuery = new Query(BonusAPI.revokeAccess);

  toggleAccess = async () => {
    if (this.hasAccess) {
      await this.revokeAccessQuery.submit({
        ...this.entity,
        ids: [this.bonusId],
      });

      if (!this.revokeAccessQuery.isFulfilled) {
        return;
      }
    } else {
      await this.grandAccessQuery.submit({
        ...this.entity,
        ids: [this.bonusId],
      });

      if (!this.grandAccessQuery.isFulfilled) {
        return;
      }
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The bonus settings have been successfully updated.",
      }),
    });

    this.eventBusService.publish(new BonusUpdated({ bonusId: this.bonusId }));
  };

  updateQuery = new Query(BonusAPI.update);

  handleSubmit = async (
    values: ILastchanceSchema & IBouncebackSchema & ICashbackSchema,
  ) => {
    const newValues = dollarsToCents(values, [
      "minDeposit",
      "maxDeposit",
      "playerBalanceThreshold",
      "thresholdForAutoIssue",
    ]);

    await this.updateQuery.submit({ ...this.entity, ...newValues });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The bonus settings have been successfully updated.",
      }),
    });

    this.eventBusService.publish(new BonusUpdated({ bonusId: this.bonusId }));
  };
}
