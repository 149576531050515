import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { StartShift, ShiftStarted } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Shift opening" })}
      openEvent={StartShift}
      closeEvent={ShiftStarted}
    >
      {({ cashierId }) => <Form cashierId={cashierId} />}
    </EventBasedModal>
  );
});
