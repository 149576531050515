import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row, Space, Switch } from "antd";
import { notification } from "antd/es";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI, ClientAPI, HallAPI, SweepstakesAPI } from "@/api";
import Query from "@/models/query";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSelect } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { Schema, useSchema } from "./schema";

type Entity = { clientId: number } | { agentId: number } | { hallId: number };

type EditFormProps = { entity: Entity };

export const EditForm = observer(({ entity }: EditFormProps) => {
  const intl = useIntl();
  const schema = useSchema();
  const form = useForm<Schema>({
    resolver: yupResolver(schema),
  });

  const sweepstakeQuery = useMemo(() => {
    const query = new Query(SweepstakesAPI.get);
    query.submit(entity).then(() => {
      form.reset(query.data);
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const multiplierQuery = useMemo(() => {
    const query = new Query(SweepstakesAPI.getSsMultiplier);
    query.submit();
    return query;
  }, []);

  const submitQuery = useMemo(
    () =>
      new Query(async (values: Schema) => {
        await SweepstakesAPI.update({
          ...entity,
          ...values,
        });

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "The sweepstakes has been successfully updated.",
          }),
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const parentLobbyExchangeQuery = useMemo(() => {
    const query = new Query(async () => {
      if ("clientId" in entity) {
        return true;
      } else if ("agentId" in entity) {
        const agent = await AgentAPI.getById({ id: entity.agentId });
        const parent = agent.parentAgentId
          ? await AgentAPI.getById({ id: agent.parentAgentId })
          : await ClientAPI.getById({ id: agent.clientId });
        return parent.lobbySettings.isExchangeActive;
      } else if ("hallId" in entity) {
        const hall = await HallAPI.getById({ id: entity.hallId });
        const parent = hall.agentId
          ? await AgentAPI.getById({ id: hall.agentId })
          : await ClientAPI.getById({ id: hall.clientId });
        return parent.lobbySettings.isExchangeActive;
      }
    });
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lobbyExchangeQuery = useMemo(
    () => {
      const query = new Query(async () => {
        if ("clientId" in entity) {
          const client = await ClientAPI.getById({ id: entity.clientId });
          return client.lobbySettings.isExchangeActive;
        } else if ("agentId" in entity) {
          const agent = await AgentAPI.getById({ id: entity.agentId });
          return agent.lobbySettings.isExchangeActive;
        } else {
          const hall = await HallAPI.getById({ id: entity.hallId });
          return hall.lobbySettings.isExchangeActive;
        }
      });
      query.submit({});
      return query;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const toogleLobbyExchangeQuery = useMemo(
    () =>
      new Query(async (isExchangeActive: boolean) => {
        if ("clientId" in entity) {
          await ClientAPI.update({
            id: entity.clientId,
            lobbySettings: { isExchangeActive },
          });
        } else if ("agentId" in entity) {
          await AgentAPI.update({
            id: entity.agentId,
            lobbySettings: { isExchangeActive },
          });
        } else {
          await HallAPI.update({
            id: entity.hallId,
            lobbySettings: { isExchangeActive },
          });
        }

        lobbyExchangeQuery.resolve({}, isExchangeActive);

        if (isExchangeActive) {
          notification.success({
            message: "",
            description: intl.formatMessage({
              defaultMessage:
                "The lobby balance exchange has been successfully enabled.",
            }),
          });
        } else {
          notification.success({
            message: "",
            description: intl.formatMessage({
              defaultMessage:
                "The lobby balance exchange has been successfully disabled.",
            }),
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (
    sweepstakeQuery.isIdle ||
    sweepstakeQuery.isPending ||
    multiplierQuery.isIdle ||
    multiplierQuery.isPending ||
    parentLobbyExchangeQuery.isIdle ||
    parentLobbyExchangeQuery.isPending ||
    lobbyExchangeQuery.isIdle ||
    lobbyExchangeQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (
    sweepstakeQuery.isRejected ||
    multiplierQuery.isRejected ||
    parentLobbyExchangeQuery.isRejected ||
    lobbyExchangeQuery.isRejected
  ) {
    return (
      <ErrorsFormatter
        queries={[
          sweepstakeQuery,
          multiplierQuery,
          parentLobbyExchangeQuery,
          lobbyExchangeQuery,
        ]}
      />
    );
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="multiplier"
          label={intl.formatMessage({ defaultMessage: "Multiplier" })}
        >
          <FormSelect
            form={form}
            path="multiplier"
            options={multiplierQuery.data}
            placeholder={intl.formatMessage({
              defaultMessage: "Select multiplier",
            })}
          />
        </FormItem>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Exchange enabled" })}
          validateStatus={
            has(form.formState.errors, "isExchangeEnabled") ? "error" : ""
          }
          help={form.formState.errors.isExchangeEnabled?.message}
        >
          <Controller
            control={form.control}
            name="isExchangeEnabled"
            render={({ field }) => <Switch checked={field.value} {...field} />}
          />
        </Form.Item>
        {parentLobbyExchangeQuery.data && (
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Lobby balance exchange",
            })}
          >
            <Switch
              checked={lobbyExchangeQuery.data}
              onChange={toogleLobbyExchangeQuery.submit}
              loading={toogleLobbyExchangeQuery.isPending}
            />
          </Form.Item>
        )}
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
