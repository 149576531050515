import { TShift } from "..";
import MethodBuilder from "../method-builder";

class ShiftAPI {
  static get = new MethodBuilder<{ cashierId: number }, TShift>()
    .withName("Shift.get")
    .build();

  static start = new MethodBuilder<
    {
      cashierId: number;
      beginningCash: number;
    },
    void
  >()
    .withName("Shift.start")
    .build();

  static finish = new MethodBuilder<
    {
      cashierId: number;
      endingCash: number;
    },
    void
  >()
    .withName("Shift.finish")
    .build();

  static withdrawMoney = new MethodBuilder<
    {
      cashierId: number;
      amount: number;
    },
    void
  >()
    .withName("Shift.withdrawMoney")
    .build();

  static depositMoney = new MethodBuilder<
    {
      cashierId: number;
      amount: number;
    },
    void
  >()
    .withName("Shift.depositMoney")
    .build();
}

export default ShiftAPI;
