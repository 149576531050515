import yup from "@/yup";

export function useCashierUpdateSchema() {
  return yup.object({
    password: yup
      .string()
      .min(6)
      .transform((value) => (!value ? undefined : value)),
    isShiftModeEnabled: yup.boolean().defined(),
  });
}

export type TCashierUpdateSchema = yup.InferType<
  ReturnType<typeof useCashierUpdateSchema>
>;
