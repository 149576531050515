import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { PlayerBalanceWithdrawn, WithdrawPlayerBalance } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Withdraw balance" })}
      openEvent={WithdrawPlayerBalance}
      closeEvent={PlayerBalanceWithdrawn}
    >
      {({ playerId, kioskId }) => {
        return <Form playerId={playerId} kioskId={kioskId} />;
      }}
    </EventBasedModal>
  );
});
