import { TJackpotTemplate } from "..";
import MethodBuilder from "../method-builder";

class JackpotTemplateAPI {
  static getAll = new MethodBuilder<
    { orderBy?: ["id", "asc" | "desc"] },
    { data: TJackpotTemplate[] }
  >()
    .withName("JackpotTemplate.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("JackpotTemplate.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("JackpotTemplate.export")
    .build();
}

export default JackpotTemplateAPI;
