import { Button, Col, Row, Space, Table } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../../_common_/errors-formatter";
import { FormSpinner } from "../../_common_/form-spinner";
import { AddKioskAccessModal } from "../add";
import { EditKioskAccessModal } from "../edit";
import { RemoveKioskAccessButton } from "../remove";

import { KioskAccessListState } from "./list.state";

interface Props {
  state: KioskAccessListState;
}

export const KioskAccessListView = observer(({ state }: Props) => {
  const {
    clientId,
    hallId,
    intl,
    query,
    openAddAccessModal,
    openEditAccessModal,
  } = state;

  if (query.isIdle || query.isPending) {
    return <FormSpinner />;
  }

  if (query.isRejected) {
    return <ErrorsFormatter queries={[query]} />;
  }

  return (
    <Space direction="vertical">
      <AddKioskAccessModal />
      <EditKioskAccessModal />
      <Table
        size="small"
        dataSource={query.data.access}
        columns={[
          {
            title: intl.formatMessage({ defaultMessage: "Name" }),
            dataIndex: "name",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Level" }),
            dataIndex: "type",
            width: "100%",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Actions" }),
            align: "right",
            render(_, row) {
              return (
                <Row justify="end" gutter={[12, 12]} wrap={false}>
                  <Col>
                    <RemoveKioskAccessButton
                      id={row.id}
                      clientId={clientId}
                      hallId={hallId}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="small"
                      onClick={() => openEditAccessModal(row.id)}
                    >
                      {intl.formatMessage({ defaultMessage: "Change PIN" })}
                    </Button>
                  </Col>
                </Row>
              );
            },
          },
        ]}
        pagination={false}
      />
      <Row justify="end">
        <Col>
          <Button onClick={openAddAccessModal}>
            {intl.formatMessage({ defaultMessage: "Add" })}
          </Button>
        </Col>
      </Row>
    </Space>
  );
});
