import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { UpdateHall } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { HallSettingsTabs } from "./hall-settings-tabs";

export const HallSettingsModal = observer(() => {
  const intl = useIntl();
  const [hallName, setHallName] = useState<string>();

  return (
    <EventBasedModal
      openEvent={UpdateHall}
      title={
        hallName ? (
          <>
            {intl.formatMessage({ defaultMessage: "Edit shop" })}:{" "}
            <Typography.Text type="secondary">{hallName}</Typography.Text>
          </>
        ) : (
          <>{intl.formatMessage({ defaultMessage: "Edit shop" })}</>
        )
      }
      onClose={() => setHallName(undefined)}
      className="modal_with-tabs"
    >
      {({ hallId }) => (
        <HallSettingsTabs hallId={hallId} setHallName={setHallName} />
      )}
    </EventBasedModal>
  );
});
