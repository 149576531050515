import yup from "@/yup";

export function useHallLobbySettingsSchema() {
  return yup.object({
    lobbySettings: yup.object({
      isContactsShown: yup.boolean(),
      contacts: yup.string().nullable(),
      playersIdleTimeout: yup.number().min(0),
      isLogOutButtonEnabled: yup.boolean(),
    }),
    autoEnableGames: yup.boolean(),
  });
}

export type HallLobbySettingsSchema = yup.InferType<
  ReturnType<typeof useHallLobbySettingsSchema>
>;
