import { useIntl } from "react-intl";

import { OpenEditKioskAccessModal, CloseEditKioskAccessModal } from "@/events";

import { EventBasedModal } from "../../_common_/event-based-modal";

import { EditKioskAccessForm } from "./form";

export const EditKioskAccessModal = () => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Edit kiosk access" })}
      openEvent={OpenEditKioskAccessModal}
      closeEvent={CloseEditKioskAccessModal}
    >
      {({ id, clientId, hallId, kioskId }) => (
        <EditKioskAccessForm
          id={id}
          clientId={clientId}
          hallId={hallId}
          kioskId={kioskId}
        />
      )}
    </EventBasedModal>
  );
};
