import MethodBuilder from "../method-builder";

type TSportSettings = {
  partnerId: number | null;
  clubId: number | null;
};

export class SportSettingsAPI {
  static get = new MethodBuilder<
    { agentId: number } | { hallId: number },
    TSportSettings
  >()
    .withName("SportSettings.get")
    .build();

  static update = new MethodBuilder<
    ({ agentId: number } | { hallId: number }) & { clubId: number | null },
    void
  >()
    .withName("SportSettings.update")
    .build();

  static reset = new MethodBuilder<
    { agentId: number } | { hallId: number },
    void
  >()
    .withName("SportSettings.reset")
    .build();
}
