import { observer } from "mobx-react-lite";

import { CloseAddBannerModal, OpenAddBannerModal } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { AddBannerForm } from "./add-form";

export const AddBannerModal = observer(() => {
  return (
    <EventBasedModal
      openEvent={OpenAddBannerModal}
      closeEvent={CloseAddBannerModal}
      title={"Create banner"}
    >
      {({ sliderId, entity }) => (
        <AddBannerForm sliderId={sliderId} entity={entity} />
      )}
    </EventBasedModal>
  );
});
