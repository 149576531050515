import {
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import { TWebsite, WebsiteAPI } from "@/api";
import {
  OpenEditWebsiteDomainModal,
  OpenNewWebsiteDomainModal,
  WebsiteDomainCreated,
  WebsiteDomainEdited,
} from "@/events";
import { useExportHandler, useImportHandler } from "@/hooks";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditWebsiteDomainModal } from "./edit";
import { NewWebsiteDomainModal } from "./new";

function WebsitesDomains() {
  const [getAllQuery] = useState(() => new Query(WebsiteAPI.getAll));
  const [importQuery] = useState(() => new Query(WebsiteAPI.import));
  const [exportQuery] = useState(() => new Query(WebsiteAPI.export));

  const handleImportClick = useImportHandler({ getAllQuery, importQuery });

  const handleExportClick = useExportHandler({
    exportQuery,
    fileName: "websites.csv",
  });

  const { eventBusService } = useGlobalStore();

  const handleCreateClick = () => {
    eventBusService.publish(new OpenNewWebsiteDomainModal({}));
  };

  useEffect(() => {
    getAllQuery.submit();

    const listener = () => getAllQuery.submit();

    eventBusService.subscribe(WebsiteDomainCreated, listener);
    eventBusService.subscribe(WebsiteDomainEdited, listener);

    return () => {
      eventBusService.unsubscribe(WebsiteDomainCreated, listener);
      eventBusService.unsubscribe(WebsiteDomainEdited, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllQuery]);

  return (
    <Space direction="vertical">
      <NewWebsiteDomainModal />
      <EditWebsiteDomainModal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              loading={importQuery.isPending}
              onClick={handleImportClick}
            >
              {"Import"}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<UploadOutlined />}
              loading={exportQuery.isPending}
              onClick={handleExportClick}
            >
              {"Export"}
            </Button>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Button icon={<PlusOutlined />} onClick={handleCreateClick}>
              {"Add domain"}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Table
          loading={getAllQuery.isPending}
          dataSource={getAllQuery.isFulfilled ? getAllQuery.data.data : []}
          pagination={false}
          size="small"
          rowKey="id"
          bordered
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              width: "1%",
            },
            {
              title: "URL",
              dataIndex: "domainName",
              width: "60%",
            },
            {
              title: "Certificate",
              children: [
                {
                  title: "Monitoring",
                  dataIndex: "isCertificateMonitoring",
                  render: (value) => <Switch checked={value} disabled />,
                  filters: [
                    {
                      text: "Yes",
                      value: true,
                    },
                    {
                      text: "No",
                      value: false,
                    },
                  ],
                  filterMultiple: false,
                  onFilter: (value, record) =>
                    record.isCertificateMonitoring === value,
                  align: "center",
                  width: "1%",
                },
                {
                  title: "Issuer",
                  dataIndex: ["certificate", "issuer"],
                  render: (value) => value ?? "—",
                  width: "20%",
                },
                {
                  title: "Expiration",
                  dataIndex: ["certificate", "validTo"],
                  render: (value) => {
                    if (!value) {
                      return "—";
                    }
                    const diff = moment(value).diff(moment(), "days");
                    const isDanger = diff < 10;
                    return (
                      <Tooltip overlay={new Date(value).toLocaleString()}>
                        <Typography.Text type={isDanger ? "danger" : undefined}>
                          {new Date(value).toLocaleDateString()}
                        </Typography.Text>
                      </Tooltip>
                    );
                  },
                },
              ],
              width: "40%",
            },
            {
              title: "Domain registration",
              children: [
                {
                  title: "Owner",
                  dataIndex: ["domainRegistration", "ownerId"],
                  align: "right",
                  render: (value) => value ?? "—",
                  width: "20%",
                },
                {
                  title: "Registrar",
                  dataIndex: ["domainRegistration", "registrar"],
                  render: (value, item) => {
                    if (!item.domainRegistration) {
                      return "—";
                    }
                    if (!item.domainRegistration.registrarURL) {
                      return item.domainRegistration.registrar;
                    }
                    return (
                      <a
                        href={item.domainRegistration.registrarURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.domainRegistration.registrar}
                      </a>
                    );
                  },
                  width: "20%",
                },
                {
                  title: "Expiration",
                  dataIndex: ["domainRegistration", "expiredAt"],
                  render: (value) => {
                    if (!value) {
                      return "—";
                    }
                    const diff = moment(value).diff(moment(), "days");
                    const isDanger = diff < 10;
                    return (
                      <Tooltip overlay={new Date(value).toLocaleString()}>
                        <Typography.Text type={isDanger ? "danger" : undefined}>
                          {new Date(value).toLocaleDateString()}
                        </Typography.Text>
                      </Tooltip>
                    );
                  },
                },
              ],
              width: "40%",
            },
            {
              title: "Actions",
              render: (_, item: TWebsite) => (
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  title={"Edit domain"}
                  onClick={() =>
                    eventBusService.publish(
                      new OpenEditWebsiteDomainModal({ id: item.id }),
                    )
                  }
                />
              ),
            },
          ]}
        />
      </Card>
    </Space>
  );
}

export default observer(WebsitesDomains);
