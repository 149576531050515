import { Col, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

type TProps = {
  label: string;
  value: ReactNode;
};

function UserItem({ label, value }: TProps) {
  return (
    <Row style={{ width: "100%", marginTop: "8px" }}>
      <Col>
        <Typography.Text>{label}:</Typography.Text>
      </Col>
      <Col style={{ marginLeft: "auto" }}>
        <Row>
          <Typography.Text style={{ textAlign: "right" }}>
            {value}
          </Typography.Text>
        </Row>
      </Col>
    </Row>
  );
}

export default observer(UserItem);
