import { green } from "@ant-design/colors";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

type Props = Omit<ButtonProps, "icon" | "onClick"> & {
  text: string;
};

export const CopyButton = observer<Props>(({ text, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef(0);

  async function handleClick() {
    await navigator.clipboard.writeText(text);

    setIsCopied(true);

    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  }

  return (
    <Button
      {...props}
      icon={
        isCopied ? (
          <CheckOutlined style={{ color: green.primary }} />
        ) : (
          <CopyOutlined />
        )
      }
      onClick={handleClick}
    />
  );
});
