import { Modal as AntdModal } from "antd";
import { observer } from "mobx-react-lite";
import { ReactElement, ReactNode } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { Event } from "@/services";
import { useGlobalStore } from "@/stores";

import { EventBasedModalViewModel } from "./event-based-modal.vm";

export type Newable<T> = { new (...args: any[]): T };

export type Payload<T> = T extends Event<infer P> ? P : never;

export type Props<
  OpenEvent extends Event<any>,
  CloseEvent extends Event<any>,
> = {
  title: ReactNode | ((payload: Payload<OpenEvent>) => ReactNode);
  openEvent: Newable<OpenEvent>;
  closeEvent?: Newable<CloseEvent>;
  onClose?: () => void;
  children: (payload: Payload<OpenEvent>) => ReactElement;
  className?: string;
  width?: number;
};

export const EventBasedModal = observer(
  <OpenEvent extends Event<any>, CloseEvent extends Event<any>>(
    props: Props<OpenEvent, CloseEvent>,
  ) => {
    const { eventBusService } = useGlobalStore();
    const vm = useViewModel(
      () =>
        new EventBasedModalViewModel(
          eventBusService,
          props.openEvent,
          props.closeEvent,
        ),
      props,
    );
    const title =
      typeof props.title === "function"
        ? vm.payload !== undefined
          ? props.title(vm.payload)
          : undefined
        : props.title;
    return (
      <AntdModal
        title={title}
        open={vm.payload !== undefined}
        onCancel={() => {
          vm.close();
          props.onClose?.();
        }}
        width={props.width ?? 720}
        footer={null}
        destroyOnClose={true}
        wrapClassName={props.className}
      >
        {vm.payload !== undefined && props.children(vm.payload)}
      </AntdModal>
    );
  },
);
