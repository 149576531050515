import yup from "@/yup";

export function useNewWebsiteTemplateFormSchema() {
  return yup.object({
    stringId: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
  });
}

export type NewWebsiteTemplateFormSchema = yup.InferType<
  ReturnType<typeof useNewWebsiteTemplateFormSchema>
>;
