import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { LocaleObject } from "yup/lib/locale";

import { CurrencyUtilities } from "@/utilities";
import yup from "@/yup";

import { validationErrors } from "./messages/validation_errors";

export const YupLocaleProvider = observer(({ children }) => {
  const intl = useIntl();

  const locale: LocaleObject = {
    mixed: {
      notType({ type }) {
        if (type === "number") {
          return intl.formatMessage(validationErrors.number.notType);
        }
        return intl.formatMessage(validationErrors.mixed.notType);
      },
      required() {
        return intl.formatMessage(validationErrors.mixed.required);
      },
      defined() {
        return intl.formatMessage(validationErrors.mixed.required);
      },
    },
    string: {
      min({ min }) {
        return intl.formatMessage(validationErrors.string.min, {
          min,
        });
      },
      max({ max }) {
        return intl.formatMessage(validationErrors.string.max, {
          max,
        });
      },
      email() {
        return intl.formatMessage(validationErrors.string.email);
      },
      url() {
        return intl.formatMessage(validationErrors.string.url);
      },
    },
    number: {
      min({ min }) {
        return intl.formatMessage(validationErrors.number.min, {
          min,
        });
      },
      max({ max }) {
        return intl.formatMessage(validationErrors.number.max, {
          max,
        });
      },
      lessThan({ less }) {
        return intl.formatMessage(validationErrors.number.lessThan, {
          less,
        });
      },
      moreThan({ more }) {
        return intl.formatMessage(validationErrors.number.moreThan, {
          more,
        });
      },
      integer() {
        return intl.formatMessage(validationErrors.number.integer);
      },
      positive() {
        return intl.formatMessage(validationErrors.number.positive);
      },
    },
  };

  yup.setLocale(locale);

  yup.addMethod(yup.NumberSchema, "money", function () {
    return this.test(
      "money",
      intl.formatMessage(validationErrors.number.money),
      (mainUnits) => {
        if (typeof mainUnits !== "number" || Number.isNaN(mainUnits)) {
          return false;
        }
        return CurrencyUtilities.isMainUnitsValid(mainUnits);
      },
    );
  });

  yup.addMethod(yup.StringSchema, "slug", function () {
    return this.matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      intl.formatMessage(validationErrors.string.slug),
    );
  });

  yup.addMethod(yup.StringSchema, "filePath", function () {
    return this.matches(
      /^(?:[a-z0-9]+(?:_[a-z0-9]+)*\/)*[a-z0-9]+(?:_[a-z0-9]+)*\.md$/,
      intl.formatMessage(validationErrors.string.filePath),
    );
  });

  yup.addMethod(yup.StringSchema, "pairingCode", function () {
    return this.matches(
      /\d{4}-\d{4}/,
      intl.formatMessage(validationErrors.string.pairingCode),
    );
  });

  yup.addMethod(yup.StringSchema, "cvv", function () {
    return this.matches(
      /^\d{3}$/,
      intl.formatMessage(validationErrors.string.cvv),
    );
  });

  yup.addMethod(yup.StringSchema, "pin", function () {
    return this.matches(
      /^\d{10}$/,
      intl.formatMessage(validationErrors.string.pin),
    );
  });

  return <>{children}</>;
});
