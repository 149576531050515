import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { GameCategoryAPI } from "@/api";
import { CategoryDeleted } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

type Props = {
  categoryId: number;
};

export const DeleteAction = observer<Props>(({ categoryId }) => {
  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async () => {
      await GameCategoryAPI.delete({ id: categoryId });

      eventBusService.publish(new CategoryDeleted({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      size="small"
      icon={<DeleteOutlined />}
      onClick={mutation.submit}
      loading={mutation.isPending}
    />
  );
});
