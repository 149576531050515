import { observer } from "mobx-react-lite";

import { usePlayerCreateFormState } from "./player-create-form.state";
import { PlayerCreateFormView } from "./player-create-form.view";

export interface PlayerCreateFormProps {
  hallId: number;
}

export const PlayerCreateForm = observer((props: PlayerCreateFormProps) => {
  const state = usePlayerCreateFormState(props);
  return <PlayerCreateFormView state={state} />;
});
