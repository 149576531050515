import { CurrencyUtilities } from "@/utilities";
import yup from "@/yup";

export function useJackpotSettingsFormSchema() {
  const slotSchema = yup.object({
    id: yup.number().integer().required(),

    minValue: yup.number().money().min(1).required(),
    maxValue: yup
      .number()
      .money()
      .when("minValue", {
        is: (value: number) => CurrencyUtilities.isMainUnitsValid(value),
        then: (schema) => schema.moreThan(yup.ref("minValue")),
      })
      .required(),
    defaultValue: yup
      .number()
      .money()
      .min(1)
      .when("minValue", {
        is: (value: number) => CurrencyUtilities.isMainUnitsValid(value),
        then: (schema) => schema.max(yup.ref("minValue")),
      })
      .required(),

    isCommunity: yup.boolean().required(),
    communityCooldown: yup.number().integer().min(0).required(),

    expectedMonthlyWinsCount: yup.number().integer().moreThan(0).required(),
  });

  return yup.object({
    id: yup.number().integer().required(),
    slots: yup.array(slotSchema).length(4).required(),
    expectedMonthlyBetSum: yup.number().money().moreThan(0).required(),
    isPayoutEnabled: yup.boolean().defined(),
    isEnabled: yup.boolean().defined(),
  });
}

export type JackpotSettingsFormSchema = yup.InferType<
  ReturnType<typeof useJackpotSettingsFormSchema>
>;
