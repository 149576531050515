import yup from "@/yup";

export function useNewClubFormSchema() {
  return yup.object({
    id: yup.number().integer().required(),
    description: yup.string().min(3).default("").required(),
  });
}

export type NewClubFormSchema = yup.InferType<
  ReturnType<typeof useNewClubFormSchema>
>;
