import { Alert } from "antd";
import { observer } from "mobx-react-lite";

import { ErrorBoundary } from "../error-boundary";

export const ErrorHandler = observer(({ children }) => (
  <ErrorBoundary
    renderFallback={(error) => (
      <Alert type="error" message="" description={error.message} />
    )}
  >
    {children}
  </ErrorBoundary>
));
