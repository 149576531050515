import yup from "@/yup";

export function useSchema() {
  return yup.object({
    amount: yup.number().money().min(0).required(),
    bonusId: yup.number(),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;
