import { useCallback, useEffect, useMemo, useState } from "react";

export function useMediaQuery(query: string): boolean {
  const media = useMemo(() => window.matchMedia(query), [query]);
  const [matches, setMatches] = useState(media.matches);

  const listener = useCallback(() => {
    setMatches(media.matches);
  }, [media.matches]);

  useEffect(() => {
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [listener, matches, media, query]);

  return matches;
}
