import { useMediaQuery } from "./use-media-query";

export function useIsXS() {
  return useMediaQuery("(max-width: 576px)");
}

export function useIsSM() {
  return useMediaQuery("(min-width: 576px)");
}

export function useIsMD() {
  return useMediaQuery("(min-width: 768px)");
}

export function useIsLG() {
  return useMediaQuery("(min-width: 992px)");
}

export function useIsXL() {
  return useMediaQuery("(min-width: 1200px)");
}

export function useIsXXL() {
  return useMediaQuery("(min-width: 1600px)");
}
