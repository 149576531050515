import { Tabs, TabsProps, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { OpenEditKioskModal } from "@/events";
import { useGlobalStore } from "@/stores";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Games } from "../games";
import { KioskAccessList } from "../kiosk-access/list";
import { Websites } from "../websites";

import { KioskSettingsForm } from "./kiosk-settings-form";

export const KioskSettingsModal = observer(() => {
  const intl = useIntl();
  const [kioskName, setKioskName] = useState<string>();
  const { userStore } = useGlobalStore();
  return (
    <EventBasedModal
      openEvent={OpenEditKioskModal}
      title={
        kioskName ? (
          <>
            {intl.formatMessage({ defaultMessage: "Edit kiosk" })}:{" "}
            <Typography.Text type="secondary">{kioskName}</Typography.Text>
          </>
        ) : (
          <>{intl.formatMessage({ defaultMessage: "Edit kiosk" })}</>
        )
      }
      onClose={() => setKioskName(undefined)}
      className="modal_with-tabs"
    >
      {({ clientId, hallId, kioskId }) => (
        <Tabs
          destroyInactiveTabPane={true}
          items={
            [
              {
                key: "kiosk",
                label: intl.formatMessage({ defaultMessage: "General" }),
                children: (
                  <KioskSettingsForm
                    hallId={hallId}
                    kioskId={kioskId}
                    setKioskName={setKioskName}
                  />
                ),
              },
              {
                key: "websites",
                label: intl.formatMessage({ defaultMessage: "Websites" }),
                children: <Websites.EditForm entity={{ kioskId }} />,
              },
              {
                key: "games",
                label: intl.formatMessage({ defaultMessage: "Games" }),
                children: <Games.EditForm entity={{ kioskId }} />,
              },
              !["HallAdmin", "Cashier"].includes(userStore.user.role) && {
                key: "access",
                label: intl.formatMessage({ defaultMessage: "Access" }),
                children: (
                  <KioskAccessList
                    clientId={clientId}
                    hallId={hallId}
                    kioskId={kioskId}
                  />
                ),
              },
            ].filter(Boolean) as TabsProps["items"]
          }
        />
      )}
    </EventBasedModal>
  );
});
