import { TSweepstake } from "..";
import MethodBuilder from "../method-builder";

class SweepstakesAPI {
  static get = new MethodBuilder<
    { clientId: number } | { agentId: number } | { hallId: number },
    TSweepstake
  >()
    .withName("Sweepstake.get")
    .build();

  static update = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      multiplier?: number;
      isExchangeEnabled?: boolean;
    },
    void
  >()
    .withName("Sweepstake.update")
    .build();

  static exchange = new MethodBuilder<
    {
      playerId: number;
      type: "winToEntries" | "entriesToWin";
      amount: number;
    },
    void
  >()
    .withName("Sweepstake.exchange")
    .build();

  static getSsMultiplier = new MethodBuilder<
    void,
    Array<{
      label: string;
      value: number;
    }>
  >()
    .withName("Dictionary.getSsMultiplier")
    .build();
}

export default SweepstakesAPI;
