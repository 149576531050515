import { observer } from "mobx-react-lite";

import { ClubCreated, CreateClub } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { NewClubForm } from "./form";

export const NewClubModal = observer(() => {
  return (
    <EventBasedModal
      title="New Club"
      openEvent={CreateClub}
      closeEvent={ClubCreated}
    >
      {({ partnerId }) => <NewClubForm partnerId={partnerId} />}
    </EventBasedModal>
  );
});
