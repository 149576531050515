import { Empty } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import BasePage from "@/ui/_common_/page";

import { Content } from "../content";

type Props = {};

export const Page: FC<Props> = observer(() => {
  const { slug } = useParams<{ slug: string }>();
  const intl = useIntl();
  return (
    <BasePage title={intl.formatMessage({ defaultMessage: "Knowledge base" })}>
      {slug ? <Content slug={slug} /> : <Empty />}
    </BasePage>
  );
});
