import MethodBuilder from "../method-builder";

type TAccess = {
  id: number;
  type: string;
  name: string;
};

type TKioskAccess = {
  type: string;
  access: TAccess[];
};

export class KioskAccessAPI {
  static getById = new MethodBuilder<
    {
      hallId: number;
      clientId: number;
      kioskId?: number;
    },
    TKioskAccess
  >()
    .withName("KioskAccess.getById")
    .build();

  static create = new MethodBuilder<
    {
      hallId: number;
      clientId: number;
      kioskId?: number;
      pin: string;
      name: string;
      type: string;
    },
    {
      kiosk: number | null;
      action: string;
    }
  >()
    .withName("KioskAccess.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      hallId: number;
      clientId: number;
      kioskId?: number;
      pin: string;
    },
    void
  >()
    .withName("KioskAccess.update")
    .build();

  static delete = new MethodBuilder<
    {
      id: number;
      hallId: number;
      clientId: number;
    },
    void
  >()
    .withName("KioskAccess.delete")
    .build();
}
