import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space, Spin } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { usePlayerGenerateSchema, TPlayerGenerateSchema } from "@/schemas";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import PlayerQrCodeForm from "../player-qr-code-form";

import { PlayerGenerateFormState } from "./player-generate-form.state";

type Props = {
  state: PlayerGenerateFormState;
};

export const PlayerGenerateForm: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = usePlayerGenerateSchema();
  const resolver = yupResolver(schema);
  const form = useForm<TPlayerGenerateSchema>({ resolver });

  if (state.hallQuery.isPending || state.websitesQuery.isPending) {
    return <FormSpinner />;
  } else if (state.hallQuery.isRejected || state.websitesQuery.isRejected) {
    return <ErrorsFormatter queries={[state.hallQuery, state.websitesQuery]} />;
  }

  if (state.hall && state.website && state.player) {
    return (
      <PlayerQrCodeForm
        date={new Date().toLocaleString()}
        website={state.website.domainName}
        login={state.player.login}
        password={state.player.password}
        balance={
          state.player.balance !== null
            ? (state.player.balance / 100).toFixed(2)
            : null
        }
        currency={state.hall.currency}
        entries={
          state.player.sweepstake !== null
            ? (state.player.sweepstake.entries / 100).toFixed(0)
            : null
        }
        totalWin={
          state.player.sweepstake !== null
            ? (state.player.sweepstake.win / 100).toFixed(2)
            : null
        }
        qrCodeData={`https://${state.website.domainName}/AuthByLP?l=${state.player.login}&p=${state.player.password}`}
      />
    );
  }

  return (
    <Spin spinning={state.generateQuery.isPending}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[state.generateQuery]} />
        <Form onFinish={form.handleSubmit(state.handleSubmit)}>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Balance" })}
            validateStatus={
              has(form.formState.errors, "balance") ? "error" : ""
            }
            help={form.formState.errors.balance?.message}
          >
            <Controller
              control={form.control}
              name="balance"
              defaultValue={undefined}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Balance",
                  })}
                  suffix={state.hall?.currency}
                  autoFocus
                  type="number"
                />
              )}
            />
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button onClick={state.onCancel}>
                {intl.formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({
                  defaultMessage: "Generate",
                })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
});
