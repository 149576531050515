import yup from "@/yup";

export function useHallWithdrawSchema() {
  return yup.object({
    amount: yup.number().money().min(0).required(),
  });
}

export type THallWithdrawSchema = yup.InferType<
  ReturnType<typeof useHallWithdrawSchema>
>;
