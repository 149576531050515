import { Alert, Button, Col, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import { FormInput, FormItem } from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";
import PlayerQrCodeForm from "../players/player-qr-code-form";

import { CreateVoucherFormState } from "./create-voucher-form.state";

interface Props {
  state: CreateVoucherFormState;
}

export const CreateVoucherFormView = observer<Props>(({ state }) => {
  const { intl, form, websiteQuery, website, submitQuery } = state;

  if (websiteQuery.isIdle || websiteQuery.isPending) {
    return <FormSpinner />;
  }

  if (websiteQuery.isRejected) {
    return <ErrorsFormatter queries={[websiteQuery]} />;
  }

  if (!website) {
    return (
      <Alert
        type="error"
        message={intl.formatMessage({
          defaultMessage:
            "The Shop does not have any accessible lobby. Add at least one lobby.",
        })}
      />
    );
  }

  if (submitQuery.isFulfilled) {
    const { player, hall } = submitQuery.data;
    return (
      <PlayerQrCodeForm
        date={new Date().toLocaleString()}
        website={website.domainName}
        login={player.login}
        password={player.password}
        balance={
          player.balance !== null ? (player.balance / 100).toFixed(2) : null
        }
        currency={hall.currency}
        entries={
          player.sweepstake !== null
            ? (player.sweepstake.entries / 100).toFixed(0)
            : null
        }
        totalWin={
          player.sweepstake !== null
            ? (player.sweepstake.win / 100).toFixed(2)
            : null
        }
        qrCodeData={`https://${website.domainName}/AuthByLP?l=${player.login}&p=${player.password}`}
      />
    );
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="initialBalance"
          label={intl.formatMessage({
            defaultMessage: "Initial balance",
          })}
        >
          <FormInput
            form={form}
            path="initialBalance"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter initial balance",
            })}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Create" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
