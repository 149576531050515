import { notification } from "antd";
import {
  autorun,
  IReactionDisposer,
  makeAutoObservable,
  runInAction,
} from "mobx";

import { AgentAPI } from "@/api";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { TAgentCreateSchema } from "@/schemas";

import { DisposableStore } from "..";
import { SelectorStore } from "../selectors";

import { AgentsStore } from ".";

export class AgentCreateStore implements DisposableStore {
  constructor(public readonly agentsStore: AgentsStore) {
    makeAutoObservable(this);

    const dispose = autorun(() => {
      const clientId = this.clientSelectorStore.selectedId;
      const agentId = this.agentSelectorStore.selectedId;
      const subagentId = this.subagentSelectorStore.selectedId;

      if (this.permissionsStore.has("SelectSubagent") && agentId) {
        runInAction(() => {
          this.parentAgentSelectorStore.setParameters({
            parentAgentId: agentId,
          });
          this.parentAgentSelectorStore.setSelectedId(subagentId);
          this.parentAgentSelectorStore.fetchItems();
        });
      } else if (this.permissionsStore.has("SelectAgent") && clientId) {
        runInAction(() => {
          this.parentAgentSelectorStore.setParameters({ clientId });
          this.parentAgentSelectorStore.setSelectedId(agentId);
          this.parentAgentSelectorStore.fetchItems();
        });
      }
    });
    this.disposers.push(dispose);
  }

  readonly disposers: IReactionDisposer[] = [];
  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
  };

  get userStore() {
    return this.agentsStore.globalStore.userStore;
  }

  get permissionsStore() {
    return this.agentsStore.globalStore.permissionsStore;
  }

  // get eventBusService() {
  //   return this.agentsStore.globalStore.eventBusService;
  // }

  get clientSelectorStore() {
    return this.agentsStore.generalSelectorsStore.clientSelectorStore;
  }

  get agentSelectorStore() {
    return this.agentsStore.generalSelectorsStore.agentSelectorStore;
  }

  get subagentSelectorStore() {
    return this.agentsStore.generalSelectorsStore.subagentSelectorStore;
  }

  get intl() {
    return this.agentsStore.globalStore.intlService.intl;
  }

  get parentCurrency() {
    if (
      typeof this.parentAgentSelectorStore.selectedItem?.currency === "string"
    ) {
      return this.parentAgentSelectorStore.selectedItem.currency;
    }
    if (typeof this.subagentSelectorStore.selectedItem?.currency === "string") {
      return this.subagentSelectorStore.selectedItem.currency;
    }
    if (typeof this.agentSelectorStore.selectedItem?.currency === "string") {
      return this.agentSelectorStore.selectedItem.currency;
    }
    if (typeof this.clientSelectorStore.selectedItem?.currency === "string") {
      return this.clientSelectorStore.selectedItem?.currency;
    }
    if (typeof this.userStore.agent?.currency === "string") {
      return this.userStore.agent.currency;
    }
    return null;
  }

  parentAgentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
  });

  modal = new Modal();

  createQuery = new Query(AgentAPI.create);

  handleSubmit = async (values: TAgentCreateSchema) => {
    if (!this.clientSelectorStore.selectedId) {
      return;
    }

    const parentAgentId =
      this.parentAgentSelectorStore.selectedId ?? this.userStore.agent?.id;

    await this.createQuery.submit({
      clientId: this.clientSelectorStore.selectedId,
      parentAgentId,
      ...values,
    });

    if (!this.createQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The agent has been successfully created.",
      }),
    });

    this.modal.close();

    // const createdAgent = this.createQuery.data;
    // this.eventBusService.publish(
    //   new UpdateAgent({
    //     agentId: createdAgent.id,
    //   })
    // );

    this.agentsStore.filter();
  };
}
