import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useQueryResetter, useResetForm } from "@/hooks";
import { useAgentWithdrawSchema, TAgentWithdrawSchema } from "@/schemas";
import { AgentWithdrawStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import { CurrencyUtilities } from "@/utilities";

type TProps = {
  agentWithdrawStore: AgentWithdrawStore;
};

function AgentWithdrawForm({ agentWithdrawStore }: TProps) {
  const schema = useAgentWithdrawSchema();
  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<TAgentWithdrawSchema>({
    resolver,
  });

  useResetForm(agentWithdrawStore.withdrawQuery, reset);
  useQueryResetter(agentWithdrawStore.withdrawQuery);

  const intl = useIntl();

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[agentWithdrawStore.withdrawQuery]} />
      <Alert
        type="info"
        message={intl.formatMessage(
          {
            id: "HFlRzl",
            defaultMessage: "Available balance: {balance}.",
          },
          {
            balance: (
              <Typography.Link
                onClick={() =>
                  setValue(
                    "amount",
                    CurrencyUtilities.toMainUnits(
                      agentWithdrawStore.agentStore.agent.balance,
                    ),
                  )
                }
              >
                <MoneyFormatter
                  cents={agentWithdrawStore.agentStore.agent.balance}
                  isInfinity={
                    agentWithdrawStore.agentStore.agent.isBalanceUnlimited
                  }
                />{" "}
                {agentWithdrawStore.agentStore.agent.currency}
              </Typography.Link>
            ),
          },
        )}
      />
      <Form onFinish={handleSubmit(agentWithdrawStore.handleSubmit)}>
        <Form.Item
          label={intl.formatMessage({
            defaultMessage: "Amount",
          })}
          validateStatus={has(errors, "amount") ? "error" : ""}
          help={errors.amount?.message}
        >
          <Controller
            control={control}
            name="amount"
            defaultValue={undefined}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Amount",
                })}
                suffix={agentWithdrawStore.agentStore.agent.currency}
                autoFocus
                type="number"
              />
            )}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button onClick={agentWithdrawStore.modal.close}>
              {intl.formatMessage({ defaultMessage: "Cancel" })}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={agentWithdrawStore.withdrawQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Withdraw" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}

export default observer(AgentWithdrawForm);
