import { mapValues } from "lodash-es";

import yup from "@/yup";

export function useNewCategoryFormSchema() {
  return yup.object({
    names: yup.lazy((value) =>
      yup.object(
        mapValues(value, () => yup.string().min(2).default("").defined()),
      ),
    ),
    isActive: yup.boolean().defined(),
    order: yup.number().defined(),
    parentId: yup.number().nullable().defined().default(null),
    tag: yup
      .mixed<"header" | "menu" | "bottom" | null | undefined>()
      .oneOf(["header", "menu", "bottom", null, undefined]),
  });
}

export type NewCategoryFormSchema = yup.InferType<
  ReturnType<typeof useNewCategoryFormSchema>
>;
