import MethodBuilder from "../../method-builder";
import {
  TFortuneWheelReportAggregatedItem,
  TFortuneWheelReportItem,
  TPaging,
} from "../../types";

export class FortuneWheelReportAPI {
  static filter = new MethodBuilder<
    ExclusifyUnion<
      (
        | { clientId: number }
        | { agentId: number }
        | { hallId: number }
        | { playerId: number }
      ) & {
        currency?: string;
        dateTimeRange?: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      }
    >,
    {
      data: TFortuneWheelReportItem[];
      total: number;
    }
  >()
    .withName("FortuneWheelReport.filter")
    .build();

  static aggregate = new MethodBuilder<
    ExclusifyUnion<
      (
        | { clientId: number }
        | { agentId: number }
        | { hallId: number }
        | { playerId: number }
      ) & {
        groupBy: "currency" | "hall" | "player";
        currency?: string;
        dateTimeRange?: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      }
    >,
    {
      data: TFortuneWheelReportAggregatedItem[];
      total: number;
    }
  >()
    .withName("FortuneWheelReport.aggregate")
    .build();
}
