import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { ClientAPI } from "@/api";
import { ClientUpdated } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { PermissionsStore } from "@/stores";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public permissionsStore: PermissionsStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clientQuery = new Query(ClientAPI.getById);

  get client() {
    return this.clientQuery.data!;
  }

  onViewMount = async ({ clientId }: Props) => {
    await this.clientQuery.submit({ id: clientId });
  };

  onViewUnmount = () => {
    this.clientQuery.reset();
  };

  updateQuery = new Query(ClientAPI.update);

  handleSubmit = async (values: ISchema) => {
    if (!this.client) {
      return;
    }

    await this.updateQuery.submit({
      id: this.client.id,
      ...values,
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The client has been successfully updated.",
      }),
    });

    this.eventBusService.publish(
      new ClientUpdated({ clientId: this.client.id }),
    );
  };
}
