import { InputRef } from "antd";
import React, { useRef } from "react";
import { useIntl } from "react-intl";

import { LineEditorProps } from "./line-editor";

export function useLineEditorState({ index, form }: LineEditorProps) {
  const intl = useIntl();

  const textRef = useRef<InputRef>(null);

  function handlePlaceholderClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    const placeholder = event.currentTarget.innerText;
    const text = form.getValues(`lines.${index}.text`);
    form.setValue(`lines.${index}.text`, text + placeholder);
  }

  return { intl, index, form, textRef, handlePlaceholderClick };
}

export type LineEditorState = ReturnType<typeof useLineEditorState>;
