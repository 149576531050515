import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { CashierAPI } from "@/api";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { TCashierUpdateSchema } from "@/schemas";

import { CashiersStore } from "./cashiers-store";

import { CashierStore } from ".";

export class CashierUpdateStore {
  constructor(
    public readonly cashiersStore: CashiersStore,
    public readonly cashierStore: CashierStore,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.cashiersStore.globalStore.intlService.intl;
  }

  get hallSelectorStore() {
    return this.cashiersStore.generalSelectorsStore.hallSelectorStore;
  }

  modal = new Modal();
  updateQuery = new Query(CashierAPI.update);

  handleSubmit = async (values: TCashierUpdateSchema) => {
    const params: Parameters<typeof CashierAPI.update>[0] = {
      ...values,
      id: this.cashierStore.cashier.id,
    };

    if (typeof values.password === "string") {
      params.password = values.password;
    }

    await this.updateQuery.submit(params);

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The cashier has been successfully updated.",
      }),
    });

    this.modal.close();

    this.cashiersStore.filter();
  };
}
