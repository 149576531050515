import { observer } from "mobx-react-lite";

import { useKiosksSettingsFormState } from "./form.state";
import { KiosksSettingsFormView } from "./form.view";

export interface KiosksSettingsFormProps {
  hallId: number;
}

export const KiosksSettingsForm = observer<KiosksSettingsFormProps>((props) => {
  const state = useKiosksSettingsFormState(props);
  return <KiosksSettingsFormView state={state} />;
});
