import { Card, Checkbox, Col, Input, Row, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

type TKey = number;

type TItem = {
  key: TKey;
  label: string;
  isSelected?: boolean;
  isHidden?: boolean;
  isChecked: boolean;
  isIndeterminate?: boolean;
};

type TProps = {
  title: string;
  items: TItem[];
  isSelectable?: boolean;
  onSelect?: (key: TKey) => void;
  onCheck?: (key: TKey) => void;
  onCheckAll?: () => void;
};

function CheckboxList({
  title,
  items,
  isSelectable = true,
  onSelect,
  onCheck,
  onCheckAll,
}: TProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      return (
        item.isHidden !== true &&
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [items, searchQuery]);
  const intl = useIntl();
  const itemsCount = items.filter((item) => item.isHidden !== true).length;
  const checkedItemsCount = items.filter((item) => item.isChecked).length;

  return (
    <Card
      title={
        <>
          <Row justify="space-between" style={{ alignItems: "baseline" }}>
            <Col>
              <Checkbox
                checked={checkedItemsCount > 0}
                indeterminate={
                  checkedItemsCount > 0 && checkedItemsCount !== itemsCount
                }
                onClick={onCheckAll}
              />
              <Typography.Text strong>{title}</Typography.Text>
            </Col>
            <Col>
              <Typography.Text strong>
                {checkedItemsCount} / {itemsCount}
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Input
              placeholder={intl.formatMessage({ defaultMessage: "Search..." })}
              size="small"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
          </Row>
        </>
      }
      className={classNames("checkbox-list", {
        "checkbox-list_selectable": isSelectable && !!itemsCount,
      })}
    >
      {!itemsCount && (
        <Row style={{ height: "100%" }} justify="center" align="middle">
          {intl.formatMessage({ defaultMessage: "No data" })}
        </Row>
      )}

      {filteredItems.map((item) => (
        <div
          className={classNames("checkbox-list__item", {
            "checkbox-list__item_selected": item.isSelected,
          })}
          key={item.key}
          onClick={() => onSelect?.(item.key)}
        >
          <div className="checkbox-list__checkbox">
            <Checkbox
              checked={item.isChecked}
              indeterminate={item.isIndeterminate}
              onClick={(event) => {
                onCheck?.(item.key);
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          </div>
          <div className="checkbox-list__label">{item.label}</div>
        </div>
      ))}
    </Card>
  );
}

export default observer(CheckboxList);
