import {
  CaretDownOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Typography,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { last } from "lodash-es";
import { Observer, observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { UpdateAgent } from "@/events";
import { useCurrencyFilters } from "@/hooks";
import { AgentsStore, AgentStore, useGlobalStore } from "@/stores";
import { AncestorsTreeButton } from "@/ui/_common_/ancestors-tree";
import ExpandButton from "@/ui/_common_/expand-button";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";
import { DeleteAction } from "@/ui/delete-action";

import DepositModal from "../agent-deposit-modal";
import WithdrawModal from "../agent-withdraw-modal";

type TProps = { agentsStore: AgentsStore };

function AgentsTable({ agentsStore: state }: TProps) {
  const { userStore, eventBusService } = useGlobalStore();
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<AgentStore> | SorterResult<AgentStore>[],
    ) => {
      if (typeof filters["agent.currency"]?.[0] === "string") {
        state.setCurrencyCode(filters["agent.currency"][0]);
      } else {
        state.setCurrencyCode(undefined);
      }
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        (sorter.columnKey === "id" || sorter.columnKey === "name")
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
      state.filter();
    },
    [state],
  );
  const currencyFilters = useCurrencyFilters();

  const intl = useIntl();

  return (
    <Spin spinning={state.filterQuery.isPending}>
      <Space direction="vertical">
        <Row>
          <Col xs={0} sm={24}>
            <Table
              dataSource={state.agentStores}
              size="small"
              rowKey={(agentStore) => agentStore.agent.id}
              bordered
              pagination={false}
              components={{ body: { row: AgentsTableRow } }}
              onChange={handleTableChange}
              expandable={{
                childrenColumnName: "__children__",
              }}
            >
              <Table.Column
                key="id"
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex={["agent", "id"]}
                align="right"
                sorter={true}
                defaultSortOrder={
                  state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
                }
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="name"
                title={intl.formatMessage({ defaultMessage: "Name" })}
                dataIndex={["agent", "name"]}
                width="100%"
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
                render={(_, agentStore: AgentStore) => (
                  <Row
                    gutter={12}
                    wrap={false}
                    style={{ marginLeft: 28 * agentStore.nestingLevel }}
                    align="middle"
                  >
                    <Col>
                      <AncestorsTreeButton
                        entity={{ agentId: agentStore.agent.id }}
                      />
                    </Col>
                    <Col>
                      <ExpandButton
                        isExpandable={agentStore.agent.ownSubagentsCount > 0}
                        isExpanded={agentStore.isChildrenShown}
                        onClick={() => agentStore.toggleChildren()}
                      />
                    </Col>
                    <Col>
                      {agentStore.agent.name}{" "}
                      {!!agentStore.agent.deletedAt && (
                        <Tag>
                          {intl.formatMessage({ defaultMessage: "DELETED" })}
                        </Tag>
                      )}
                      {["Admin", "Manager"].includes(userStore.user.role) &&
                        agentStore.isSweepstakesEnabled && (
                          <Tag color="green">
                            {intl.formatMessage({
                              defaultMessage: "Sweepstakes",
                            })}
                          </Tag>
                        )}
                    </Col>
                  </Row>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Balance" })}
                dataIndex={["agent", "balance"]}
                align="right"
                render={(_, agentStore: AgentStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8} justify="end">
                        <Col>
                          <WithdrawModal
                            agentWithdrawStore={agentStore.withdrawStore}
                          />
                          <Button
                            size="small"
                            icon={<MinusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Withdraw",
                            })}
                            onClick={async () => {
                              await agentStore.fetch();
                              agentStore.withdrawStore.modal.open();
                            }}
                            disabled={agentStore.agent.isBalanceUnlimited}
                          />
                        </Col>
                        <Col flex="auto">
                          <MoneyFormatter
                            cents={agentStore.agent.balance}
                            isInfinity={agentStore.agent.isBalanceUnlimited}
                          />
                        </Col>
                        <Col>
                          <DepositModal
                            agentDepositStore={agentStore.depositStore}
                          />
                          <Button
                            size="small"
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Deposit",
                            })}
                            onClick={async () => {
                              await agentStore.fetch();
                              agentStore.depositStore.modal.open();
                            }}
                            disabled={agentStore.agent.isBalanceUnlimited}
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Currency" })}
                dataIndex={["agent", "currency"]}
                filters={currencyFilters}
                filterMultiple={false}
                align="right"
                render={(currency: string | null) => currency ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Actions" })}
                render={(_, agentStore: AgentStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            icon={<ReloadOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Reload",
                            })}
                            onClick={agentStore.fetch}
                          />
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            icon={<EditOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Edit",
                            })}
                            onClick={() => {
                              eventBusService.publish(
                                new UpdateAgent({
                                  agentId: agentStore.agent.id,
                                }),
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <DeleteAction
                            size="small"
                            entity={{ agentId: agentStore.agent.id }}
                            isDeleted={!!agentStore.agent.deletedAt}
                            onSuccess={state.filter}
                          />
                        </Col>
                        <Col style={{ marginLeft: "auto" }}>
                          <Link
                            to={{
                              pathname: "/agents",
                              search: new URLSearchParams({
                                cid: agentStore.agent.clientId.toString(),
                                aid: agentStore.agent.id.toString(),
                                action: "new",
                              }).toString(),
                            }}
                            state={{ agentsPageKey: Math.random() }}
                          >
                            <Button size="small" icon={<PlusOutlined />}>
                              {intl.formatMessage({ defaultMessage: "Agent" })}
                            </Button>
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            to={{
                              pathname: "/shops",
                              search: new URLSearchParams({
                                cid: agentStore.agent.clientId.toString(),
                                aid: agentStore.agent.id.toString(),
                                action: "new",
                              }).toString(),
                            }}
                          >
                            <Button size="small" icon={<PlusOutlined />}>
                              {intl.formatMessage({ defaultMessage: "Shop" })}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Agents" })}
                align="right"
                render={(_, agentStore: AgentStore) => (
                  <>
                    <Link
                      to={{
                        pathname: "/agents",
                        search: new URLSearchParams({
                          cid: agentStore.agent.clientId.toString(),
                          aid: agentStore.agent.id.toString(),
                        }).toString(),
                      }}
                      state={{ agentsPageKey: Math.random() }}
                    >
                      {agentStore.agent.ownSubagentsCount}
                    </Link>{" "}
                    <Typography.Text type="secondary">
                      ({agentStore.agent.subagentsCount})
                    </Typography.Text>
                  </>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Shops" })}
                align="right"
                render={(_, agentStore: AgentStore) => (
                  <>
                    <Link
                      to={{
                        pathname: "/shops",
                        search: new URLSearchParams({
                          cid: agentStore.agent.clientId.toString(),
                          aid: agentStore.agent.id.toString(),
                        }).toString(),
                      }}
                    >
                      {agentStore.agent.ownHallsCount}
                    </Link>{" "}
                    <Typography.Text type="secondary">
                      ({agentStore.agent.hallsCount})
                    </Typography.Text>
                  </>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Players" })}
                align="right"
                render={(_, agentStore: AgentStore) => (
                  <Typography.Text type="secondary">
                    {agentStore.agent.playersCount}
                  </Typography.Text>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Kiosks" })}
                align="right"
                render={(_, agentStore: AgentStore) => (
                  <Typography.Text type="secondary">
                    {agentStore.agent.kiosksCount}
                  </Typography.Text>
                )}
              />
            </Table>
          </Col>
          <Col xs={24} sm={0}>
            {!state.agentStores.length && <Empty />}
            <Space direction="vertical">
              {state.agentStores.map((agentStore) => (
                <>
                  <Card
                    key={agentStore.agent.id}
                    size="small"
                    className={classNames({
                      "ant-table-row-color-gray": !!agentStore.agent.deletedAt,
                    })}
                    style={{ marginLeft: 16 * agentStore.nestingLevel }}
                  >
                    <Row gutter={[8, 8]}>
                      {!!agentStore.agent.deletedAt && (
                        <Tag>
                          {intl.formatMessage({ defaultMessage: "DELETED" })}
                        </Tag>
                      )}
                      {["Admin", "Manager"].includes(userStore.user.role) &&
                        agentStore.isSweepstakesEnabled && (
                          <Tag color="green">
                            {intl.formatMessage({
                              defaultMessage: "Sweepstakes",
                            })}
                          </Tag>
                        )}
                    </Row>
                    <Row gutter={[8, 8]} align="middle">
                      {agentStore.agent.ownSubagentsCount > 0 && (
                        <Col>
                          <ExpandButton
                            isExpandable={true}
                            isExpanded={agentStore.isChildrenShown}
                            onClick={() => agentStore.toggleChildren()}
                          />
                        </Col>
                      )}
                      <Col
                        style={{
                          fontWeight: "bold",
                          color: agentStore.agent.isBlocked ? "red" : "",
                        }}
                      >
                        {agentStore.agent.name}
                      </Col>
                      <Col style={{ marginLeft: "auto", textAlign: "right" }}>
                        {agentStore.agent.id}
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({ defaultMessage: "Admin" })}:{" "}
                        {agentStore.agent.login}
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                      <Col style={{ textAlign: "center" }}>
                        <Typography.Text strong>
                          {intl.formatMessage({ defaultMessage: "Agents" })}
                        </Typography.Text>
                        <br />
                        <Link
                          to={{
                            pathname: "/agents",
                            search: new URLSearchParams({
                              cid: agentStore.agent.clientId.toString(),
                              aid: agentStore.agent.id.toString(),
                            }).toString(),
                          }}
                          state={{ agentsPageKey: Math.random() }}
                        >
                          {agentStore.agent.ownSubagentsCount}
                        </Link>{" "}
                        <Typography.Text type="secondary">
                          ({agentStore.agent.subagentsCount})
                        </Typography.Text>
                      </Col>
                      <Col style={{ textAlign: "center" }}>
                        <Typography.Text strong>
                          {intl.formatMessage({ defaultMessage: "Shops" })}
                        </Typography.Text>
                        <br />
                        <Link
                          to={{
                            pathname: "/shops",
                            search: new URLSearchParams({
                              cid: agentStore.agent.clientId.toString(),
                              aid: agentStore.agent.id.toString(),
                            }).toString(),
                          }}
                        >
                          {agentStore.agent.ownHallsCount}
                        </Link>{" "}
                        <Typography.Text type="secondary">
                          ({agentStore.agent.hallsCount})
                        </Typography.Text>
                      </Col>
                      <Col style={{ textAlign: "center" }}>
                        <Typography.Text strong>
                          {intl.formatMessage({ defaultMessage: "Players" })}
                        </Typography.Text>
                        <br />
                        <Link
                          to={{
                            pathname: "/players",
                            search: new URLSearchParams({
                              cid: agentStore.agent.clientId.toString(),
                              aid: agentStore.agent.id.toString(),
                            }).toString(),
                          }}
                        >
                          {agentStore.agent.ownPlayersCount}
                        </Link>{" "}
                        <Typography.Text type="secondary">
                          ({agentStore.agent.playersCount})
                        </Typography.Text>
                      </Col>
                      <Col style={{ textAlign: "center" }}>
                        <Typography.Text strong>
                          {intl.formatMessage({ defaultMessage: "Kiosks" })}
                        </Typography.Text>
                        <br />
                        <Typography.Text type="secondary">
                          {agentStore.agent.ownKiosksCount} (
                          {agentStore.agent.kiosksCount})
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Divider type="horizontal" style={{ margin: "8px 0" }} />
                    <Row wrap={false} gutter={[8, 8]} align="middle">
                      <Col>
                        <WithdrawModal
                          agentWithdrawStore={agentStore.withdrawStore}
                        />
                        <Button
                          icon={<MinusOutlined />}
                          title={intl.formatMessage({
                            defaultMessage: "Withdraw",
                          })}
                          onClick={async () => {
                            await agentStore.fetch();
                            agentStore.withdrawStore.modal.open();
                          }}
                          disabled={agentStore.agent.isBalanceUnlimited}
                        />
                      </Col>
                      <Col flex={1} style={{ textAlign: "right" }}>
                        <MoneyFormatter
                          cents={agentStore.agent.balance}
                          isInfinity={agentStore.agent.isBalanceUnlimited}
                        />{" "}
                        {agentStore.agent.currency}
                      </Col>
                      <Col>
                        <DepositModal
                          agentDepositStore={agentStore.depositStore}
                        />
                        <Button
                          icon={<PlusOutlined />}
                          title={intl.formatMessage({
                            defaultMessage: "Deposit",
                          })}
                          onClick={async () => {
                            await agentStore.fetch();
                            agentStore.depositStore.modal.open();
                          }}
                          disabled={agentStore.agent.isBalanceUnlimited}
                        />
                      </Col>
                      <Col>
                        <Divider type="vertical" />
                      </Col>
                      <Col>
                        <Button
                          icon={<ReloadOutlined />}
                          title={intl.formatMessage({
                            defaultMessage: "Reload",
                          })}
                          onClick={agentStore.fetch}
                        />
                      </Col>
                      <Col>
                        <Popover
                          trigger="click"
                          zIndex={999}
                          content={
                            <Row gutter={[16, 8]}>
                              <Col>
                                <AncestorsTreeButton
                                  size="middle"
                                  entity={{ agentId: agentStore.agent.id }}
                                />
                              </Col>
                              <Col>
                                <Button
                                  icon={<EditOutlined />}
                                  title={intl.formatMessage({
                                    defaultMessage: "Edit",
                                  })}
                                  onClick={() => {
                                    eventBusService.publish(
                                      new UpdateAgent({
                                        agentId: agentStore.agent.id,
                                      }),
                                    );
                                  }}
                                />
                              </Col>
                              <Col>
                                <DeleteAction
                                  entity={{ agentId: agentStore.agent.id }}
                                  isDeleted={!!agentStore.agent.deletedAt}
                                  onSuccess={state.filter}
                                />
                              </Col>
                            </Row>
                          }
                          placement="bottomRight"
                        >
                          <Button icon={<CaretDownOutlined />} />
                        </Popover>
                      </Col>
                    </Row>
                  </Card>
                  {agentStore.childrenQuery.isPending &&
                    !agentStore.children?.length && (
                      <Card
                        style={{
                          marginLeft: 16 * (agentStore.nestingLevel + 1),
                        }}
                      >
                        <FormSpinner />
                      </Card>
                    )}
                </>
              ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          query={state.filterQuery}
          onChange={() => {
            state.filter({ preservePageNumber: true });
          }}
        />
      </Space>
    </Spin>
  );
}

type TChild = { props: { record: AgentStore } };

type TAgentsTableRowProps = {
  className: string;
  children: TChild | TChild[];
};

const AgentsTableRow = observer(function AgentsTableRow(
  props: TAgentsTableRowProps,
) {
  const intl = useIntl();
  if (!Array.isArray(props.children)) {
    return <tr {...props} />;
  }
  const agentStore = props.children[0].props.record;
  const isLastChild =
    last(agentStore.parentAgentStore?.children) === agentStore;
  const rows = [];
  if (agentStore.fetchQuery.isPending || agentStore.updateQuery.isPending) {
    rows.push(
      <tr key="loading-1" className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>,
    );
  } else {
    rows.push(
      <tr
        key="blocked-1"
        {...props}
        className={classNames(props.className, {
          "ant-table-row-color-red": agentStore.agent.isBlocked,
          "ant-table-row-color-gray": !!agentStore.agent.deletedAt,
        })}
      />,
    );
  }
  if (agentStore.childrenQuery.isPending && !agentStore.children?.length) {
    rows.push(
      <tr key="loading-2" className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>,
    );
  } else if (isLastChild && agentStore?.parentAgentStore?.children) {
    if (agentStore.parentAgentStore.childrenQuery.isPending) {
      rows.push(
        <tr key="loading-2" className={props.className}>
          <td colSpan={99999}>
            <Spin size="small">
              <Button size="small" style={{ visibility: "hidden" }}>
                LOADING...
              </Button>
            </Spin>
          </td>
        </tr>,
      );
    } else if (agentStore.parentAgentStore.hasMoreChildren) {
      rows.push(
        <tr key="load-more-2" className={props.className}>
          <td
            colSpan={99999}
            style={{
              backgroundColor: "rgb(250, 250, 250)",
              cursor: "pointer",
            }}
            onClick={agentStore.parentAgentStore.fetchMoreChildren}
          >
            <Row justify="center">
              {intl.formatMessage({
                defaultMessage: "Load more",
              })}
            </Row>
          </td>
        </tr>,
      );
    }
  }
  return <>{rows}</>;
});

export default observer(AgentsTable);
