import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { FormState } from "./FormState";
import { FormView } from "./FormView";

export type Props = {
  agentId: number;
  gameId: number;
  gameOrder: number | null;
};

export const Form: FC<Props> = observer((props) => {
  const { eventBusService } = useGlobalStore();
  const state = useViewModel(() => new FormState(eventBusService), props);
  return <FormView state={state} />;
});
