import { Button, Col, Form, Row, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { EditWebsiteTemplateFormState } from "./form.state";

type Props = {
  state: EditWebsiteTemplateFormState;
};

export const EditWebsiteTemplateFormView = observer<Props>(({ state }) => {
  const { intl, query, handleSubmit, form, mutation } = state;

  if (query.isIdle || query.isPending) {
    return <FormSpinner />;
  }

  if (query.isRejected) {
    return <ErrorsFormatter queries={[query]} />;
  }

  const template = query.data;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[mutation]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "ID" })}>
          <Typography.Text>{template.id}</Typography.Text>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "String ID" })}>
          <Typography.Text>{template.stringId}</Typography.Text>
        </Form.Item>
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({ defaultMessage: "Enter name" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="description"
          label={intl.formatMessage({ defaultMessage: "Description" })}
        >
          <FormInput
            form={form}
            path="description"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter description",
            })}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
