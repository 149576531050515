import { Row, Tag, Col } from "antd";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import { TBonus, THall } from "@/api";
import MoneyFormatter from "@/ui/_common_/money-formatter";

interface Props {
  hall?: THall;
  bonus?: TBonus;
}

export function BonusInfo({ hall, bonus }: Props) {
  const intl = useIntl();

  if (!hall || !bonus) {
    return null;
  }

  return (
    <Row gutter={[0, 8]}>
      {[
        <Tag key="depositPercentage">
          {intl.formatMessage(
            { defaultMessage: "Bonus: {value}%" },
            { value: bonus.depositPercentage },
          )}
        </Tag>,
        "minDeposit" in bonus ? (
          <Tag key="minDeposit">
            {intl.formatMessage(
              { defaultMessage: "Deposit range: {min}-{max}" },
              {
                min: <MoneyFormatter cents={bonus.minDeposit} />,
                max: <MoneyFormatter cents={bonus.maxDeposit} />,
              },
            )}
          </Tag>
        ) : (
          false
        ),
        "isWagerEnabled" in bonus
          ? bonus.isWagerEnabled && (
              <Tag key="isWagerEnabled">
                {intl.formatMessage(
                  { defaultMessage: "Wager: {value}" },
                  { value: hall.bonusSettings.bonusWager },
                )}
              </Tag>
            )
          : false,
        "cooldown" in bonus
          ? bonus.cooldown > 0 && (
              <Tag key="cooldown">
                {intl.formatMessage(
                  { defaultMessage: "Cooldown: {hours}h" },
                  { hours: bonus.cooldown },
                )}
              </Tag>
            )
          : false,
        "happyHours" in bonus
          ? bonus.happyHours.isEnabled && (
              <Tag key="happyHours">
                <FormattedMessage
                  defaultMessage="Happy hours: {from}-{to}, boost: <b>{boost}%</b>"
                  values={{
                    from: new Date(bonus.happyHours.dateTimeRange.from)
                      .toTimeString()
                      .slice(0, 5),
                    to: new Date(bonus.happyHours.dateTimeRange.to)
                      .toTimeString()
                      .slice(0, 5),
                    boost: bonus.happyHours.boost,
                    b: (text) => <b>{text}</b>,
                  }}
                />
              </Tag>
            )
          : false,
        (() => {
          if (
            !bonus ||
            !("happyHours" in bonus) ||
            !bonus.happyHours.isEnabled
          ) {
            return false;
          }

          const from = moment();
          const to = moment();

          const { dateTimeRange } = bonus.happyHours;

          from.hours(moment(dateTimeRange.from).hours());
          from.minutes(moment(dateTimeRange.from).minutes());

          to.hours(moment(dateTimeRange.to).hours());
          to.minutes(moment(dateTimeRange.to).minutes());

          if (!moment().isBetween(from, to)) {
            return false;
          }

          return (
            <Tag color="blue">
              {intl.formatMessage({
                defaultMessage: "It's happy hour time.",
              })}
            </Tag>
          );
        })(),
      ]
        .filter(Boolean)
        .map((e, i) => (
          <Col key={i}>{e}</Col>
        ))}
    </Row>
  );
}
