import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { SportClubAPI } from "@/api";
import { ClubEdited } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditClubFormProps } from "./form";
import { EditClubFormSchema, useEditClubFormSchema } from "./form.schema";

export function useEditClubFormState({ id }: EditClubFormProps) {
  const intl = useIntl();

  const schema = useEditClubFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditClubFormSchema>({ resolver });

  const query = useMemo(() => {
    const query = new Query(async () => {
      const Club = await SportClubAPI.getById({ id });

      const values = schema.cast(Club, {
        assert: false,
        stripUnknown: true,
      });
      form.reset(values);

      return Club;
    });
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (values: EditClubFormSchema) => {
      await SportClubAPI.update({ id, ...values });

      notification.success({
        message: "",
        description: "Club updated successfully!",
      });

      eventBusService.publish(new ClubEdited({}));
    });
  }, [eventBusService, id]);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  return {
    intl,
    query,
    form,
    handleSubmit,
    mutation,
  };
}

export type EditClubFormState = ReturnType<typeof useEditClubFormState>;
