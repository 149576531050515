import { Space } from "antd";
import * as jp from "jsonpath";
import { observer } from "mobx-react-lite";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface Props<FV extends FieldValues> {
  form: UseFormReturn<FV>;
  paths: Path<FV>[];
}

export const FormErrors = observer(
  <FV extends FieldValues>({ form, paths }: Props<FV>) => {
    const messages = paths.flatMap((path) => {
      return jp.query(form.formState.errors, `${path}.message`) as string[];
    });
    return (
      <Space direction="vertical">
        {messages.map((message, index) => (
          <div key={index} className="ant-form-item-explain-error">
            {message}
          </div>
        ))}
      </Space>
    );
  },
);
