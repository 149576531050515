import { observer } from "mobx-react-lite";

import { useEditPartnerFormState } from "./form.state";
import { EditPartnerFormView } from "./form.view";

export type EditPartnerFormProps = {
  id: number;
};

export const EditPartnerForm = observer<EditPartnerFormProps>((props) => {
  const state = useEditPartnerFormState(props);
  return <EditPartnerFormView state={state} />;
});
