import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { SportPartnerAPI } from "@/api";
import { PartnerCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { NewPartnerFormSchema, useNewPartnerFormSchema } from "./form.schema";

export function useNewPartnerFormState() {
  const intl = useIntl();

  const schema = useNewPartnerFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<NewPartnerFormSchema>({ resolver });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (values: NewPartnerFormSchema) => {
      await SportPartnerAPI.create(values);

      notification.success({
        message: "",
        description: "Partner created successfully!",
      });

      eventBusService.publish(new PartnerCreated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  return {
    intl,
    form,
    handleSubmit,
    mutation,
  };
}

export type NewPartnerFormState = ReturnType<typeof useNewPartnerFormState>;
