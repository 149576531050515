import yup from "@/yup";

export function useEditWebsiteTemplateFormSchema() {
  return yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
  });
}

export type EditWebsiteTemplateFormSchema = yup.InferType<
  ReturnType<typeof useEditWebsiteTemplateFormSchema>
>;
