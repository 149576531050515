import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";

import { EntityTreeAPI, TEntityTreeItem } from "@/api";
import Query from "@/models/query";

import ErrorsFormatter from "../errors-formatter";
import { FormSpinner } from "../form-spinner";

import { AncestorNode } from "./ancestor-node";

export type TEntity =
  | { clientId: number }
  | { agentId: number }
  | { hallId: number };

type TTreeNode = {
  key: string;
  title: string;
  link: string;
};

type Props = {
  entity: TEntity;
};

export const AncestorsTree = observer(({ entity }: Props) => {
  const [query] = useState(() => new Query(EntityTreeAPI.getAncestorsTree));

  useEffect(() => {
    query.submit(entity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  if (query.isIdle || query.isPending) {
    return <FormSpinner />;
  }

  if (query.isRejected) {
    return <ErrorsFormatter queries={[query]} isClosable={false} />;
  }

  const items = query.data.data;
  const leaf = findLeaf(items, entity);
  const nodes = buildNodes(items, leaf);

  return (
    <div role="tree" className="ant-tree ant-tree-icon-hide ant-tree-show-line">
      <div className="ant-tree-list">
        <div className="ant-tree-list-holder">
          <div>
            <div className="ant-tree-list-holder-inner">
              {nodes.map((node, index) => (
                <AncestorNode
                  key={node.key}
                  level={index}
                  title={node.title}
                  link={node.link}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function findLeaf(items: TEntityTreeItem[], entity: TEntity) {
  return items.find((item) => {
    if ("clientId" in entity) {
      return item.type === "Client" && item.entity.id === entity.clientId;
    }
    if ("agentId" in entity) {
      return item.type === "Agent" && item.entity.id === entity.agentId;
    }
    if ("hallId" in entity) {
      return item.type === "Hall" && item.entity.id === entity.hallId;
    }
  });
}

function buildNodes(
  items: TEntityTreeItem[],
  leaf?: TEntityTreeItem,
): TTreeNode[] {
  if (!leaf) {
    return [];
  }

  const nodes: TTreeNode[] = [];
  let item: TEntityTreeItem | undefined = leaf;
  while (item) {
    nodes.unshift({
      key: item.type + item.entity.id,
      title: item.entity.name,
      link: buildLink(item),
    });
    item = findParent(items, item);
  }

  return nodes;
}

function findParent(
  items: TEntityTreeItem[],
  item?: TEntityTreeItem,
): TEntityTreeItem | undefined {
  if (!item) {
    return;
  }

  if (item.type === "Client") {
    return;
  }

  if (item.type === "Agent") {
    return items.find((parent) => {
      if (item.entity.parentAgentId) {
        return (
          parent.type === "Agent" &&
          parent.entity.id === item.entity.parentAgentId
        );
      }
      return (
        parent.type === "Client" && parent.entity.id === item.entity.clientId
      );
    });
  }

  if (item.type === "Hall") {
    return items.find((parent) => {
      if (item.entity.agentId) {
        return (
          parent.type === "Agent" && parent.entity.id === item.entity.agentId
        );
      }
      return (
        parent.type === "Client" && parent.entity.id === item.entity.clientId
      );
    });
  }
}

function buildLink(item: TEntityTreeItem) {
  const endpoint = {
    Client: "clients",
    Agent: "agents",
    Hall: "shops",
  }[item.type];

  const searchParams = new URLSearchParams();

  if (item.type === "Agent" || item.type === "Hall") {
    searchParams.set("cid", item.entity.clientId.toString());
  }

  if (item.type === "Agent") {
    if (item.entity.parentAgentId) {
      searchParams.set("aid", item.entity.parentAgentId.toString());
    }
  }

  if (item.type === "Hall") {
    if (item.entity.agentId) {
      searchParams.set("aid", item.entity.agentId.toString());
    }
  }

  searchParams.set("sq", item.entity.name);

  return `/${endpoint}?${searchParams.toString()}`;
}
