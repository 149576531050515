import { TFortuneWheel, TSector } from "..";
import MethodBuilder from "../method-builder";

export class FortuneWheelAPI {
  static get = new MethodBuilder<
    { clientId: number } | { hallId: number } | { agentId: number },
    TFortuneWheel | null
  >()
    .withName("FortuneWheel.get")
    .build();

  static update = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      sectors?: TSector[];
      isEnabled?: boolean;
      cooldown?: number;
      maxDepositAge?: number;
    },
    void
  >()
    .withName("FortuneWheel.update")
    .build();

  static grantAccess = new MethodBuilder<
    { clientId: number } | { hallId: number } | { agentId: number },
    void
  >()
    .withName("FortuneWheel.grantAccess")
    .build();

  static revokeAccess = new MethodBuilder<
    { clientId: number } | { hallId: number } | { agentId: number },
    void
  >()
    .withName("FortuneWheel.revokeAccess")
    .build();
}
