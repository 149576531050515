import { makeAutoObservable } from "mobx";

import { ClientAPI, ManagerAPI } from "@/api";
import { ClientUpdated } from "@/events";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";

import { ClientCreateStore, ClientStore, GlobalStore } from "..";
import { SelectorStore } from "../selectors";

export class ClientsStore {
  constructor(public globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    const { searchParams } = this.routerService;

    if (searchParams["action"] === "new") {
      this.clientCreateStore.modal.open();
    }

    if (this.permissionsStore.has("SelectManager")) {
      this.managerSelectorStore.setParameters({});
      this.managerSelectorStore.fetchItems();
    }

    const { user } = this.userStore;
    if (user.role === "Manager") {
      this.managerSelectorStore.setSelectedId(user.id);
    }

    this.filter();

    this.eventBusService.subscribe(ClientUpdated, (event) => {
      const { clientId } = event.payload;
      const clientStore = this.clientStores.find(
        (store) => store.client.id === clientId,
      );
      clientStore?.fetch();
    });
  }

  get eventBusService() {
    return this.globalStore.eventBusService;
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get userStore() {
    return this.globalStore.userStore;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  handleManagerSelect = () => {
    this.filter();
  };

  managerSelectorStore = new SelectorStore({
    filterMethod: ManagerAPI.filter,
    getByIdMethod: ManagerAPI.getById,
    labelKey: "name",
    onSelect: this.handleManagerSelect,
  });

  clientCreateStore = new ClientCreateStore(this);

  private _clientStores: ClientStore[] = [];

  get clientStores() {
    return this._clientStores;
  }

  private setClientStores = (clientStores: ClientStore[]) => {
    this._clientStores = clientStores;
  };

  filterQuery = new ItemsQuery(ClientAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
    initialOrder: ["id", "desc"],
  });

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(includeDeleted: boolean) {
    this._includeDeleted = includeDeleted;
    this.filter();
  }

  private _currencyCode?: string;

  get currencyCode() {
    return this._currencyCode;
  }

  setCurrencyCode = (currencyCode?: string) => {
    this._currencyCode = currencyCode;
  };

  filter = async (options?: TSubmitOptions) => {
    const includeDeletedParams = this.permissionsStore.has("ViewDeletedAgents")
      ? { includeDeleted: this.includeDeleted }
      : {};
    const params = {
      managerId: this.managerSelectorStore.selectedId,
      currency: this._currencyCode,
      ...includeDeletedParams,
    };

    await this.filterQuery.submit(params, options);

    if (!this.filterQuery.isFulfilled) {
      return;
    }

    const { items } = this.filterQuery;
    const clientStores = items.map((client) => new ClientStore(client));
    this.setClientStores(clientStores);
  };
}
