import { Button, Checkbox, Col, Form, Row, Space, Switch, Table } from "antd";
import { Observer, observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { EditFormState, Website } from "./FormState";

type Props = {
  state: EditFormState;
};

export const EditFormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();

  if (state.isIdleOrPending) {
    return <FormSpinner />;
  }

  if (state.isRejected) {
    return (
      <ErrorsFormatter
        queries={[
          state.agentQuery,
          state.hallQuery,
          state.websitesQuery,
          state.childWebsitesQuery,
          state.parentWebsitesQuery,
        ]}
      />
    );
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter
        queries={[state.grantAccessQuery, state.revokeAccessQuery]}
      />
      {"clientId" in state.entity && (
        <Row justify="end">
          <Col>
            <Checkbox
              checked={state.isOccupiedWebsitesHidden}
              onChange={(event) => {
                state.isOccupiedWebsitesHidden = event.target.checked;
              }}
            >
              {intl.formatMessage({ defaultMessage: "Hide occupied websites" })}
            </Checkbox>
          </Col>
        </Row>
      )}
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={state.handleSubmit}
      >
        <Table
          rowKey="id"
          dataSource={state.websites}
          size="small"
          pagination={false}
        >
          <Table.Column
            key="id"
            dataIndex="id"
            title={intl.formatMessage({ defaultMessage: "ID" })}
          />
          <Table.Column
            dataIndex="domainName"
            title={intl.formatMessage({ defaultMessage: "Domain name" })}
          />
          {Object.hasOwn(state.entity, "hallId") && (
            <Table.Column
              dataIndex="hasPriority"
              title={intl.formatMessage({ defaultMessage: "POS" })}
              align="right"
              render={(_, website: Website) => (
                <Observer>
                  {() => (
                    <Switch
                      disabled={!website.isEnabled || website.isOccupied}
                      checked={state.priorityWebsiteId === website.id}
                      onChange={() => state.handlePriorityChange(website)}
                    />
                  )}
                </Observer>
              )}
            />
          )}
          <Table.Column
            dataIndex="hasAccess"
            align="right"
            title={intl.formatMessage({ defaultMessage: "Has access" })}
            render={(_, website: Website) => (
              <Observer>
                {() => (
                  <Switch
                    disabled={website.isOccupied}
                    checked={website.hasAccess}
                    onChange={() => state.handleHasAccessChange(website)}
                  />
                )}
              </Observer>
            )}
          />
          <Table.Column
            dataIndex="isEnabled"
            align="right"
            title={intl.formatMessage({ defaultMessage: "Enabled" })}
            render={(_, website: Website) => (
              <Observer>
                {() => (
                  <Switch
                    disabled={website.isOccupied || !website.hasAccess}
                    checked={website.isEnabled}
                    onChange={() => state.handleEnableChange(website)}
                  />
                )}
              </Observer>
            )}
          />
        </Table>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                state.grantAccessQuery.isPending ||
                state.revokeAccessQuery.isPending
              }
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
