import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI } from "@/api";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { TAgentWithdrawSchema } from "@/schemas";
import { CurrencyUtilities } from "@/utilities";

import { AgentStore } from ".";

export class AgentWithdrawStore {
  constructor(public readonly agentStore: AgentStore) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.agentStore.intlService.intl;
  }

  modal = new Modal();
  withdrawQuery = new Query(AgentAPI.withdrawMoney);

  private _amount?: number;

  get amount() {
    return this._amount;
  }

  setAmount = (amount?: number) => {
    this._amount = amount;
  };

  handleSubmit = async (values: TAgentWithdrawSchema) => {
    await this.withdrawQuery.submit({
      ...values,
      id: this.agentStore.agent.id,
      amount: CurrencyUtilities.toMinorUnits(values.amount),
    });

    if (!this.withdrawQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage:
          "The money has been successfully withdrawn from the balance.",
      }),
    });

    this.modal.close();

    let agentStore: AgentStore | undefined = this.agentStore;
    while (agentStore !== undefined) {
      agentStore.fetch();
      agentStore = agentStore.parentAgentStore;
    }
  };
}
