import { TLanguage } from "..";
import MethodBuilder from "../method-builder";

class LanguageAPI {
  static getAll = new MethodBuilder<void, { data: TLanguage[] }>()
    .withName("Language.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("Language.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("Language.export")
    .build();
}

export default LanguageAPI;
