import { mapValues } from "lodash-es";
import { match, P } from "ts-pattern";

import yup from "@/yup";

export function useEditBannerFormSchema() {
  return yup.object({
    titles: yup.lazy((value) =>
      yup.object(mapValues(value, () => yup.string().default("").defined())),
    ),
    subtitles: yup.lazy((value) =>
      yup.object(mapValues(value, () => yup.string().default("").defined())),
    ),
    type: yup
      .mixed<"simple" | "text" | "bonus">()
      .oneOf(["simple", "text", "bonus"])
      .defined(),
    actionType: yup
      .mixed<"url" | "provider" | "tag" | "game" | null>()
      .oneOf(["url", "provider", "tag", "game", null])
      .defined(),
    actionValue: yup.mixed().when(["actionType"], (actionType) => {
      return match(actionType)
        .with(null, () => yup.mixed().oneOf([null]))
        .with("url", () => yup.string().url().defined())
        .with(P.union("provider", "game", "tag"), () =>
          yup.string().min(1).defined(),
        )
        .exhaustive();
    }),
    order: yup.number().integer().defined(),
    isActive: yup.boolean().defined(),
    bonusId: yup.number().integer().nullable(),
  });
}

export type EditBannerFormSchema = yup.InferType<
  ReturnType<typeof useEditBannerFormSchema>
>;
