import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { CashierAPI, ShiftAPI, TCashier } from "@/api";
import Query from "@/models/query";

import { CashiersStore, CashierUpdateStore } from ".";

export class CashierStore {
  constructor(
    private cashiersStore: CashiersStore,
    private _cashier: TCashier,
    public shiftQuery?: Query<
      Parameters<typeof ShiftAPI.get>[0],
      Awaited<ReturnType<typeof ShiftAPI.get>>
    >,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.cashiersStore.globalStore.intlService.intl;
  }

  get cashier() {
    return this._cashier;
  }

  get shift() {
    return this.shiftQuery?.data;
  }

  private setCashier = (cashier: TCashier) => {
    this._cashier = cashier;
  };

  fetchQuery = new Query(CashierAPI.getById);
  updateQuery = new Query(CashierAPI.update);

  updateStore = new CashierUpdateStore(this.cashiersStore, this);

  fetch = async () => {
    await this.fetchQuery.submit({
      id: this.cashier.id,
    });

    if (!this.fetchQuery.isFulfilled) {
      return;
    }

    const cashier = this.fetchQuery.data;
    this.setCashier(cashier);
  };

  block = async () => {
    await this.updateQuery.submit({
      id: this.cashier.id,
      isBlocked: true,
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The cashier has been successfully blocked.",
      }),
    });

    await this.fetch();
  };

  unblock = async () => {
    await this.updateQuery.submit({
      id: this.cashier.id,
      isBlocked: false,
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The cashier has been successfully unblocked.",
      }),
    });

    await this.fetch();
  };
}
