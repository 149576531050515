import { Tabs } from "antd";
import { useIntl } from "react-intl";

import { RulesForm } from "./form";

interface Props {
  entity: { clientId: number } | { agentId: number };
}

export function RulesTabs({ entity }: Props) {
  const intl = useIntl();
  return (
    <Tabs
      type="line"
      size="small"
      destroyInactiveTabPane={true}
      items={[
        {
          key: "terms-and-conditions",
          label: intl.formatMessage({ defaultMessage: "Terms and conditions" }),
          children: <RulesForm entity={entity} type="TermsAndConditions" />,
        },
        {
          key: "sweepstakes-rules",
          label: intl.formatMessage({ defaultMessage: "Sweepstakes rules" }),
          children: <RulesForm entity={entity} type="SweepstakesRules" />,
        },
      ]}
    />
  );
}
