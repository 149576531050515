import MethodBuilder from "../../method-builder";
import { TPaging, TSettlementsReportItem } from "../../types";

export class SettlementsReportAPI {
  static filter = new MethodBuilder<
    (
      | {
          clientId: number;
        }
      | {
          agentId: number;
        }
      | {
          hallId: number;
        }
    ) & {
      payer?: { agentId: number };
      recipient?: { agentId: number } | { hallId: number };

      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      paging: TPaging;
    },
    {
      data: TSettlementsReportItem[];
      total: number;
    }
  >()
    .withName("SettlementsReport.filter")
    .build();
}
