import { makeAutoObservable } from "mobx";

import { PlayerAPI } from "@/api";
import Query from "@/models/query";

export class PlayerTokenModalState {
  constructor() {
    makeAutoObservable(this);
  }

  private _isOpen = false;

  get isOpen() {
    return this._isOpen;
  }

  private set isOpen(value: boolean) {
    this._isOpen = value;
  }

  open = (hallId: number, playerId: number) => {
    this.playerQuery.submit({ id: playerId });
    this.tokenQuery.submit({ id: playerId });

    this.isOpen = true;
  };

  close = () => {
    this.isOpen = false;

    this.playerQuery.reset();
    this.tokenQuery.reset();
  };

  playerQuery = new Query(PlayerAPI.getById);
  tokenQuery = new Query(PlayerAPI.getAuthToken);

  get player() {
    return this.playerQuery.data;
  }

  get token() {
    return this.tokenQuery.data;
  }
}
