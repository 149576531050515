import { debounce } from "lodash-es";
import { action, autorun, makeAutoObservable } from "mobx";

import { PlayerAPI } from "@/api";
import {
  CreatePlayer,
  PlayerBalanceDeposited,
  PlayerBalanceExchanged,
  PlayerBalanceWithdrawn,
  PlayerCreated,
  PlayersImported,
  PlayerUpdated,
  VoucherCreated,
} from "@/events";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import { PlayerGenerateModalState } from "@/ui/players/player-generate-modal";
import { PlayerTokenModalState } from "@/ui/players/player-token-modal/player-token-modal.state";

import { GeneralSelectorsStore, PlayerStore } from "..";
import { GlobalStore } from "../global";

export class PlayersStore {
  public readonly generalSelectorsStore: GeneralSelectorsStore;

  playerGenerateModalState = new PlayerGenerateModalState({
    onGenerate: () => {
      this.filter();

      if (this.userStore.user.role === "Cashier") {
        this.userStore.fetchCashier(this.userStore.cashier!.id);
      }
    },
  });
  playerTokenModalState = new PlayerTokenModalState();

  filterQuery = new ItemsQuery(PlayerAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
    initialOrder: ["id", "desc"],
  });

  constructor(public globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.generalSelectorsStore = new GeneralSelectorsStore(
      this.globalStore,
      this.filter,
    );

    this.filter();

    const { routerService, eventBusService } = this.globalStore;

    autorun(() => {
      if (
        routerService.searchParams["action"] === "new" &&
        this.generalSelectorsStore.hallSelectorStore.selectedId
      ) {
        eventBusService.publish(
          new CreatePlayer({
            hallId: this.generalSelectorsStore.hallSelectorStore.selectedId,
          }),
        );
      }
    });

    eventBusService.subscribe(PlayerCreated, () => {
      this.filter();
    });

    eventBusService.subscribe(PlayerUpdated, () => {
      this.filter();
    });

    eventBusService.subscribe(PlayerBalanceDeposited, (event) => {
      const { playerId } = event.payload;
      const playerStore = this.playerStores.find(
        (playerStore) => playerStore.player.id === playerId,
      );
      playerStore?.fetch();
    });

    eventBusService.subscribe(PlayerBalanceWithdrawn, (event) => {
      const { playerId } = event.payload;
      const playerStore = this.playerStores.find(
        (playerStore) => playerStore.player.id === playerId,
      );
      playerStore?.fetch();
    });

    eventBusService.subscribe(PlayerBalanceExchanged, (event) => {
      const { playerId } = event.payload;
      const playerStore = this.playerStores.find(
        (playerStore) => playerStore.player.id === playerId,
      );
      playerStore?.fetch();
    });

    eventBusService.subscribe(VoucherCreated, () => {
      this.filter();
    });

    eventBusService.subscribe(PlayersImported, () => {
      this.filter();
    });
  }

  get intlService() {
    return this.globalStore.intlService;
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get userStore() {
    return this.globalStore.userStore;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  private _playerStores: PlayerStore[] = [];

  get playerStores() {
    return this._playerStores;
  }

  setPlayerStores = (playerStores: PlayerStore[]) => {
    this._playerStores = playerStores;
  };

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
    this.filter();
  }

  filter = debounce(
    action("filter", async (options?: TSubmitOptions) => {
      let params:
        | { clientId: number }
        | { agentId: number }
        | { hallId: number }
        | null = null;
      if (this.generalSelectorsStore.hallSelectorStore.selectedId) {
        params = {
          hallId: this.generalSelectorsStore.hallSelectorStore.selectedId,
        };
      } else if (this.generalSelectorsStore.agentSelectorStore.selectedId) {
        params = {
          agentId: this.generalSelectorsStore.agentSelectorStore.selectedId,
        };
      } else if (this.generalSelectorsStore.clientSelectorStore.selectedId) {
        params = {
          clientId: this.generalSelectorsStore.clientSelectorStore.selectedId,
        };
      }
      if (params === null) {
        this.setPlayerStores([]);
        return;
      }
      const includeDeletedParams = this.permissionsStore.has(
        "ViewDeletedPlayers",
      )
        ? { includeDeleted: this.includeDeleted }
        : {};
      await this.filterQuery.submit(
        { ...params, ...includeDeletedParams },
        options,
      );

      if (!this.filterQuery.isFulfilled) {
        return;
      }

      const { items } = this.filterQuery;
      const playerStores = items.map(
        (player) => new PlayerStore(player, this, this.intlService),
      );
      this.setPlayerStores(playerStores);
    }),
    250,
  );
}
