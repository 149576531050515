import yup from "@/yup";

export function useKiosksSettingsFormSchema() {
  return yup.object({
    lobbyThemeStringId: yup.string().min(1).required(),
    frontendThemeStringId: yup.string(),
  });
}

export type KiosksSettingsFormSchema = yup.InferType<
  ReturnType<typeof useKiosksSettingsFormSchema>
>;
