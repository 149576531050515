import { observer } from "mobx-react-lite";

import { OpenCategoryGamesModal } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { CategoryGamesForm } from "./form";

export const CategoryGamesModal = observer(() => {
  return (
    <EventBasedModal
      title={({ name }) => name}
      openEvent={OpenCategoryGamesModal}
    >
      {({ id }) => <CategoryGamesForm id={id} />}
    </EventBasedModal>
  );
});
