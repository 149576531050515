import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space, Switch } from "antd";
import { has } from "lodash-es";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import AreYouSure from "@/ui/_common_/are-you-sure";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import Password from "@/ui/_common_/password";
import Selector from "@/ui/_common_/selector";
import { ClientModeSelect } from "@/ui/clients/ui/client-mode-select";
import { generatePassword } from "@/utilities";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  useEffect(() => {
    reaction(
      () => state.updateQuery.isFulfilled,
      () => form.resetField("password"),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.clientQuery.isIdle || state.clientQuery.isPending) {
    return <FormSpinner />;
  }

  if (state.clientQuery.isRejected) {
    return <ErrorsFormatter queries={[state.clientQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.updateQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        {state.permissionsStore.has("SelectManager") && (
          <Form.Item label={intl.formatMessage({ defaultMessage: "Manager" })}>
            <Selector
              placeholder={intl.formatMessage({
                defaultMessage: "Select manager",
              })}
              store={state.managerSelectorStore}
            />
          </Form.Item>
        )}
        <Form.Item label={intl.formatMessage({ defaultMessage: "Name" })}>
          <Input value={state.client.name} disabled />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Admin username" })}
        >
          <Input value={state.client.login} disabled />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Admin password" })}
          validateStatus={has(form.formState.errors, "password") ? "error" : ""}
          help={form.formState.errors.password?.message}
        >
          <Controller
            control={form.control}
            name="password"
            render={({ field }) => (
              <Password
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter password",
                })}
                onCopy={() => {
                  const password = form.getValues().password ?? "";
                  if (password) {
                    navigator.clipboard.writeText(password);
                  }
                }}
                onGenerate={() => form.setValue("password", generatePassword())}
                // disable autocomplete
                readOnly
                onFocus={(e) => e.target.removeAttribute("readonly")}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Main currency" })}
        >
          <Input value={state.client!.currency ?? "—"} disabled />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Blocked" })}
          validateStatus={
            has(form.formState.errors, "isBlocked") ? "error" : ""
          }
          help={form.formState.errors.isBlocked?.message}
        >
          <Controller
            control={form.control}
            name="isBlocked"
            defaultValue={state.client.isBlocked}
            render={({ field }) => (
              <AreYouSure
                onYes={() => form.setValue("isBlocked", true)}
                onNo={() => form.setValue("isBlocked", false)}
                disabled={!!field.value}
              >
                <Switch checked={!!field.value} onChange={field.onChange} />
              </AreYouSure>
            )}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Mode" })}>
          <ClientModeSelect value={state.client!.clientMode ?? "—"} disabled />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.updateQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
