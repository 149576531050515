import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { HallsStore } from "@/stores";

import HallCreateForm from "./hall-create-form";

type TProps = {
  hallsStore: HallsStore;
};

function HallCreateModal({ hallsStore }: TProps) {
  const { hallCreateStore } = hallsStore;
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "New shop" })}
      open={hallCreateStore.modal.isOpen}
      onCancel={hallCreateStore.modal.close}
      width={720}
      footer={null}
      destroyOnClose={true}
    >
      <HallCreateForm hallsStore={hallsStore} />
    </Modal>
  );
}

export default observer(HallCreateModal);
