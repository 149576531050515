import { Form, Typography, Row, Col, Button } from "antd";
import { template as compileTemplate } from "lodash-es";
import { observer } from "mobx-react-lite";
import QRCode from "qrcode.react";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

import Query from "@/models/query";
import { PrintService } from "@/services";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";

import template from "../qr-code.html";

const compiledTemplate = compileTemplate(template);

type Props = {
  date: string;
  website: string;
  login: string;
  balance: string | null;
  currency: string | null;
  entries: string | null;
  totalWin: string | null;
  qrCodeData: string;
} & ({ password: string } | { token: string });

const PlayerQrCodeForm: FC<Props> = observer((props) => {
  const intl = useIntl();

  const printQuery = useMemo(
    () =>
      new Query(async () => {
        const html = compiledTemplate({
          ...props,
          password: "password" in props ? props.password : undefined,
        });
        await PrintService.print(html);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <ErrorsFormatter queries={[printQuery]} />
      <iframe
        id="frame"
        style={{ position: "absolute", visibility: "hidden" }}
      />
      <Form labelCol={{ span: 6 }}>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Date" })}>
          <Typography.Paragraph style={{ margin: 0 }}>
            {new Date().toLocaleString()}
          </Typography.Paragraph>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Website" })}>
          <Typography.Paragraph copyable style={{ margin: 0 }}>
            {props.website}
          </Typography.Paragraph>
        </Form.Item>
        {props.login && (
          <Form.Item label={intl.formatMessage({ defaultMessage: "Username" })}>
            <Typography.Paragraph copyable style={{ margin: 0 }}>
              {props.login}
            </Typography.Paragraph>
          </Form.Item>
        )}
        {"password" in props && (
          <Form.Item label={intl.formatMessage({ defaultMessage: "Password" })}>
            <Typography.Paragraph copyable style={{ margin: 0 }}>
              {props.password}
            </Typography.Paragraph>
          </Form.Item>
        )}
        {props.balance !== null && props.currency !== null && (
          <Form.Item label={intl.formatMessage({ defaultMessage: "Balance" })}>
            <Typography.Paragraph style={{ margin: 0 }}>
              {props.balance} {props.currency}
            </Typography.Paragraph>
          </Form.Item>
        )}
        {props.entries !== null && props.totalWin !== null && (
          <>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Entries" })}
            >
              <Typography.Paragraph style={{ margin: 0 }}>
                {props.entries}
              </Typography.Paragraph>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Total Win" })}
            >
              <Typography.Paragraph style={{ margin: 0 }}>
                {props.totalWin}
              </Typography.Paragraph>
            </Form.Item>
          </>
        )}
        <Form.Item label={intl.formatMessage({ defaultMessage: "QR Code" })}>
          <QRCode value={props.qrCodeData} />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              onClick={printQuery.submit}
              loading={printQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Print" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default PlayerQrCodeForm;
