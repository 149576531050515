import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";

import { ContentState } from "./ContentState";
import { ContentView } from "./ContentView";

export type Props = {
  slug: string;
};

export const Content: FC<Props> = observer((props) => {
  const state = useViewModel(() => new ContentState(), props);
  return <ContentView state={state} />;
});
