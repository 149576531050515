import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { CreateKiosk, KioskCreated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { AddKioskForm } from "./add-kiosk-form";

export const AddKioskModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      openEvent={CreateKiosk}
      closeEvent={KioskCreated}
      title={intl.formatMessage({ defaultMessage: "New kiosk" })}
    >
      {({ hallId }) => <AddKioskForm hallId={hallId} />}
    </EventBasedModal>
  );
});
