import { Button, Col, Row, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import { FormSpinner } from "../_common_/form-spinner";
import Selector from "../_common_/selector";

import { SportSettingsState } from "./form.state";

type Props = {
  state: SportSettingsState;
};

export const SportSettingsView = observer<Props>(({ state }) => {
  const {
    intl,
    query,
    mutation,
    resetMutation,
    partnerSelectorStore,
    clubSelectorStore,
    permissionsStore,
  } = state;

  if (!permissionsStore.has("ViewSportSettings")) {
    return null;
  }

  if (query.isIdle || query.isPending) {
    return <FormSpinner />;
  }

  return (
    <Space direction="vertical">
      <Typography.Text strong>Sport</Typography.Text>
      <ErrorsFormatter queries={[mutation]} />
      <Selector store={partnerSelectorStore} placeholder="Select partner" />
      <Selector
        store={clubSelectorStore}
        placeholder="Select club"
        disabled={!partnerSelectorStore.selectedItem}
      />
      <Row justify="end" gutter={[12, 12]}>
        <Col>
          <Button
            onClick={resetMutation.submit}
            loading={resetMutation.isPending}
            danger
          >
            {intl.formatMessage({ defaultMessage: "Reset" })}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={mutation.isPending}
            onClick={mutation.submit}
            disabled={!clubSelectorStore.selectedItem}
          >
            {intl.formatMessage({ defaultMessage: "Save" })}
          </Button>
        </Col>
      </Row>
    </Space>
  );
});
