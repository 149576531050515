import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { JackpotWeightAPI } from "@/api";
import { useExportHandler, useImportHandler } from "@/hooks";
import Query from "@/models/query";

function JackpotWeights() {
  const [getAllQuery] = useState(() => new Query(JackpotWeightAPI.getAll));
  const [importQuery] = useState(() => new Query(JackpotWeightAPI.import));
  const [exportQuery] = useState(() => new Query(JackpotWeightAPI.export));

  const handleImportClick = useImportHandler({ getAllQuery, importQuery });

  const handleExportClick = useExportHandler({
    exportQuery,
    fileName: "jackpot-weights.csv",
  });

  useEffect(() => {
    getAllQuery.submit();
  }, [getAllQuery]);

  return (
    <Space direction="vertical">
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              loading={importQuery.isPending}
              onClick={handleImportClick}
            >
              {"Import"}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<UploadOutlined />}
              loading={exportQuery.isPending}
              onClick={handleExportClick}
            >
              {"Export"}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Table
          loading={getAllQuery.isPending}
          dataSource={getAllQuery.isFulfilled ? getAllQuery.data.data : []}
          pagination={false}
          size="small"
          rowKey="gameName"
        >
          <Table.Column title={"Game name"} dataIndex="gameName" width="100%" />
          <Table.Column title={"Weight"} dataIndex="weight" />
          <Table.Column title={"Numerator"} dataIndex="numerator" />
        </Table>
      </Card>
    </Space>
  );
}

export default observer(JackpotWeights);
