import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { upperFirst } from "lodash-es";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { BonusAPI, HallAPI, PlayerAPI } from "@/api";
import { PlayerCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { dollarsToCents } from "@/utilities";

import { PlayerCreateFormProps } from "./player-create-form";
import {
  PlayerCreateFormSchema,
  usePlayerCreateFormSchema,
} from "./player-create-form.schema";

export function usePlayerCreateFormState({ hallId }: PlayerCreateFormProps) {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const schema = usePlayerCreateFormSchema();
  const form = useForm<PlayerCreateFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: { balance: 0 },
  });
  const formValues = form.watch();

  const { hallQuery, bonusesQuery } = useMemo(() => {
    const hallQuery = new Query(HallAPI.getById);
    const bonusesQuery = new Query(BonusAPI.filter);
    hallQuery.submit({ id: hallId });
    bonusesQuery.submit({ hallId });
    return { hallQuery, bonusesQuery };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bonusesOptions =
    bonusesQuery.data?.data?.map((bonus) => ({
      label: upperFirst(bonus.type),
      value: bonus.id,
    })) ?? [];

  const submitQuery = useMemo(() => {
    return new Query(async (values: PlayerCreateFormSchema) => {
      values = dollarsToCents(values, ["balance"]);

      await PlayerAPI.create({
        hallId,
        ...values,
        name: values.name ?? "",
        email: values.email ?? "",
        phone: values.phone ?? "",
      });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The user has been successfully created.",
        }),
      });

      eventBusService.publish(new PlayerCreated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hall = hallQuery.data;

  const bonusId = form.watch("bonusId");
  const selectedBonus = bonusesQuery.data?.data.find((b) => b.id === bonusId);

  return {
    hallId,
    intl,
    form,
    formValues,
    hallQuery,
    hall,
    bonusesQuery,
    bonusesOptions,
    selectedBonus,
    submitQuery,
  };
}

export type PlayerCreateFormState = ReturnType<typeof usePlayerCreateFormState>;
