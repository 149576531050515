import { TBonusTemplate } from "..";
import MethodBuilder from "../method-builder";

class BonusTemplateAPI {
  static getAll = new MethodBuilder<
    { orderBy?: ["id", "asc" | "desc"] },
    { data: TBonusTemplate[]; total: number }
  >()
    .withName("BonusTemplate.getAll")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("BonusTemplate.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("BonusTemplate.export")
    .build();
}

export default BonusTemplateAPI;
