import { Spin } from "antd";

type Props = {
  isVisible?: boolean;
};

function FullscreenSpinner({ isVisible = true }: Props) {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        zIndex: 10000,
      }}
    >
      <Spin size="large" />
    </div>
  );
}

export default FullscreenSpinner;
