import { Button, Col, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem } from "@/ui/_common_/form-helpers";
import Selector from "@/ui/_common_/selector";

import { FormState } from "./form.state";

interface Props {
  state: FormState;
}

export const FormView = observer<Props>(({ state }) => {
  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.submitQuery]} />
      <Form
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onFinish={state.handleSubmit}
      >
        <FormItem form={state.form} path="providerId" label={"Provider"}>
          <Selector
            store={state.providerSelectorStore}
            placeholder={"Select provider"}
          />
        </FormItem>
        <FormItem form={state.form} path="stringId" label={"String ID"}>
          <FormInput
            form={state.form}
            path="stringId"
            placeholder={"Enter string id"}
          />
        </FormItem>
        <FormItem form={state.form} path="name" label={"Name"}>
          <FormInput form={state.form} path="name" placeholder={"Enter name"} />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.submitQuery.isPending}
            >
              {"Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
