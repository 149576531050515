import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { HallWithdrawStore } from "@/stores";

import HallWithdrawForm from "./hall-withdraw-form";

type TProps = {
  hallWithdrawStore: HallWithdrawStore;
};

function HallWithdrawModal({ hallWithdrawStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Withdraw balance" })}
      open={hallWithdrawStore.modal.isOpen}
      onCancel={hallWithdrawStore.modal.close}
      footer={null}
      destroyOnClose={true}
    >
      <HallWithdrawForm hallWithdrawStore={hallWithdrawStore} />
    </Modal>
  );
}

export default observer(HallWithdrawModal);
