import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { ClientAPI, ManagerAPI } from "@/api";
import { UpdateClient } from "@/events";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { TClientCreateSchema } from "@/schemas";

import { SelectorStore } from "../selectors";

import { ClientsStore } from ".";

export class ClientCreateStore {
  constructor(public readonly clientsStore: ClientsStore) {
    makeAutoObservable(this);

    const { user } = this.userStore;
    if (user.role === "Manager") {
      this.managerSelectorStore.setSelectedId(user.id);
    }

    if (this.permissionsStore.has("SelectManager")) {
      this.managerSelectorStore.setParameters({});
      this.managerSelectorStore.fetchItems();
    }
  }

  get eventBusService() {
    return this.clientsStore.globalStore.eventBusService;
  }

  get intl() {
    return this.clientsStore.globalStore.intlService.intl;
  }

  get userStore() {
    return this.clientsStore.userStore;
  }

  get permissionsStore() {
    return this.clientsStore.permissionsStore;
  }

  modal = new Modal();

  managerSelectorStore = new SelectorStore({
    filterMethod: ManagerAPI.filter,
    getByIdMethod: ManagerAPI.getById,
    labelKey: "name",
  });

  createQuery = new Query(ClientAPI.create);

  handleSubmit = async (values: TClientCreateSchema) => {
    await this.createQuery.submit({
      managerId: this.managerSelectorStore.selectedId,
      ...values,
    });

    if (!this.createQuery.isFulfilled) {
      return;
    }

    const createdClient = this.createQuery.data;

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The client has been successfully created.",
      }),
    });

    this.modal.close();

    this.eventBusService.publish(
      new UpdateClient({ clientId: createdClient.id }),
    );

    this.clientsStore.filter();
  };
}
