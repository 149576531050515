import { grey } from "@ant-design/colors";
import { EditOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Row, Col, Button, Spin, Space, Empty } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";

import { ContentState } from "./ContentState";

type Props = {
  state: ContentState;
};

export const ContentView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();

  if (state.articlesQuery.isIdle || state.articlesQuery.isPending) {
    return (
      <Card size="small">
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      </Card>
    );
  }

  if (state.articlesQuery.isRejected) {
    return <ErrorsFormatter queries={[state.articlesQuery]} />;
  }

  return (
    <Space direction="vertical">
      {state.isCreateAllowed && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            <Col style={{ marginLeft: "auto" }}>
              <Button onClick={state.handleCreateClick} icon={<PlusOutlined />}>
                {intl.formatMessage({ defaultMessage: "Create" })}
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      <Card size="small">
        <Space direction="vertical">
          {!state.categories.length ? (
            <Empty />
          ) : (
            state.categories.map((category) => (
              <Space direction="vertical" key={category.id}>
                <Row align="middle" gutter={[8, 8]}>
                  <Col>
                    <Link
                      to={`/kb#${category.slug}`}
                      style={{ display: "flex", width: 12, height: 12 }}
                    >
                      <LinkOutlined style={{ fontSize: 12 }} />
                    </Link>
                  </Col>
                  <Col>
                    <h3
                      id={category.slug}
                      style={{ lineHeight: 1, marginBottom: 0 }}
                    >
                      {category.name}
                    </h3>
                  </Col>
                </Row>
                <ul style={{ marginBottom: 0, paddingLeft: 34 }}>
                  {category.articles.map((article) => (
                    <li key={article.id}>
                      <div style={{ display: "flex", gap: 4 }}>
                        <Link
                          to={`/kb/${article.slug}`}
                          style={{
                            color: !article.isEnabled ? grey.primary : "",
                          }}
                        >
                          {article.name}
                        </Link>
                        {state.isUpdateAllowed && (
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            size="small"
                            onClick={state.handleEditClick.bind(
                              state,
                              article.id,
                            )}
                            style={{
                              color: !article.isEnabled ? grey.primary : "",
                            }}
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </Space>
            ))
          )}
        </Space>
      </Card>
    </Space>
  );
});
