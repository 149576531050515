import { Switch } from "antd";
import { observer } from "mobx-react-lite";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

import AreYouSure from "../are-you-sure";

interface Props<FV extends FieldValues> {
  form: UseFormReturn<FV>;
  path: Path<FV>;
  isConfirmationNeeded?: boolean;
  disabled?: boolean;
}

export const FormSwitch = observer(
  <FV extends FieldValues>({
    form,
    path,
    isConfirmationNeeded = false,
    disabled = false,
  }: Props<FV>) => {
    return (
      <Controller
        control={form.control}
        name={path}
        render={({ field }) => {
          if (isConfirmationNeeded) {
            return (
              <AreYouSure
                onYes={() => field.onChange(true)}
                onNo={() => field.onChange(false)}
                disabled={!!field.value}
              >
                <Switch {...field} checked={field.value} disabled={disabled} />
              </AreYouSure>
            );
          }

          return (
            <Switch {...field} checked={field.value} disabled={disabled} />
          );
        }}
      />
    );
  },
);
