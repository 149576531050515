import yup from "@/yup";

export function useDailyLoginRewardsFormSchema() {
  const sequenceSettingsSchema = yup.object({
    isActive: yup.boolean().defined(),
    sequence: yup.number().integer().min(1).max(10).required(),
    reward: yup.string().required(),
    rewardType: yup.string().required(),
  });

  return yup.object({
    isActive: yup.boolean().defined(),
    cooldown: yup.number().required(),
    sequenceSettings: yup
      .object({
        "0": yup.array(sequenceSettingsSchema),
        "1": yup.array(sequenceSettingsSchema),
        "2": yup.array(sequenceSettingsSchema),
        "3": yup.array(sequenceSettingsSchema),
        "4": yup.array(sequenceSettingsSchema),
        "5": yup.array(sequenceSettingsSchema),
      })
      .required(),
  });
}

export type DailyLoginRewardsFormSchema = yup.InferType<
  ReturnType<typeof useDailyLoginRewardsFormSchema>
>;
