import { TUser } from "..";
import MethodBuilder from "../method-builder";

class UserAPI {
  static getCurrent = new MethodBuilder<void, TUser>()
    .withName("User.getCurrent")
    .build();

  static logIn = new MethodBuilder<{ login: string; password: string }, TUser>()
    .withName("User.logIn")
    .build();

  static logOut = new MethodBuilder<void, void>()
    .withName("User.logOut")
    .build();
}

export default UserAPI;
