import { observer } from "mobx-react-lite";

import { useKioskSettingsFormState } from "./kiosk-settings-form.state";
import { KioskSettingsFormView } from "./kiosk-settings-form.view";

export interface KioskSettingsFormProps {
  hallId: number;
  kioskId: number;
  setKioskName(name: string): void;
}

export const KioskSettingsForm = observer<KioskSettingsFormProps>((props) => {
  const state = useKioskSettingsFormState(props);
  return <KioskSettingsFormView state={state} />;
});
