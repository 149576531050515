import { Form, FormItemProps, Space } from "antd";
import * as jp from "jsonpath";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

type FixedPaths<P extends string> =
  P extends `${infer Head}.${number}.${infer Trail}`
    ? `${Head}.*.${Trail}` | `${Head}.${number}.${Trail}`
    : P;

interface Props<FV extends FieldValues>
  extends Pick<FormItemProps, "label" | "validateStatus" | "help" | "tooltip"> {
  form: UseFormReturn<FV>;
  path: FixedPaths<Path<FV>>;
  isRequired?: boolean;
  isColonShown?: boolean;
  children: ReactNode;
}

export const FormItem = observer(
  <FV extends FieldValues>({
    form,
    path,
    label,
    tooltip,
    isRequired = false,
    isColonShown = true,
    children,
  }: Props<FV>) => {
    const messages = jp.query(
      form.formState.errors,
      `${path}.message`,
    ) as string[];

    return (
      <Form.Item
        label={label}
        tooltip={tooltip}
        help={
          <Space direction="vertical">
            {messages.map((message, index) => (
              <div key={index} className="ant-form-item-explain-error">
                {message}
              </div>
            ))}
          </Space>
        }
        required={isRequired}
        colon={isColonShown}
      >
        {children}
      </Form.Item>
    );
  },
);
