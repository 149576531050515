import MethodBuilder from "../../method-builder";
import { TSweepstakesReportItem, TPaging } from "../../types";

export class SweepstakesReportAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
    ) & {
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      paging: TPaging;
    },
    {
      data: TSweepstakesReportItem[];
      total: number;
    }
  >()
    .withName("SweepstakeReport.filter")
    .build();
}
