import yup from "@/yup";

export function usePlayerGenerateSchema() {
  return yup.object({
    balance: yup.number().money().min(0).required(),
  });
}

export type TPlayerGenerateSchema = yup.InferType<
  ReturnType<typeof usePlayerGenerateSchema>
>;
