import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { OpenNewWebsiteDomainModal, WebsiteDomainCreated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { NewWebsiteDomainForm } from "./form";

export const NewWebsiteDomainModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Add domain" })}
      openEvent={OpenNewWebsiteDomainModal}
      closeEvent={WebsiteDomainCreated}
    >
      {() => <NewWebsiteDomainForm />}
    </EventBasedModal>
  );
});
