import { observer } from "mobx-react-lite";

import {
  EditKioskAccessFormProps,
  useEditKioskAccessFormState,
} from "./form.state";
import { EditKioskAccessFormView } from "./form.view";

export const EditKioskAccessForm = observer(
  (props: EditKioskAccessFormProps) => {
    const state = useEditKioskAccessFormState(props);
    return <EditKioskAccessFormView state={state} />;
  },
);
