import { Form, FormItemProps } from "antd";
import { omit } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FieldError } from "react-hook-form";

type TProps = FormItemProps & {
  error?: FieldError;
};

function FormItem(props: TProps) {
  const formItemProps = omit(props, "error");
  if (props.error) {
    return (
      <Form.Item
        {...formItemProps}
        validateStatus="error"
        help={props.error.message}
      />
    );
  }
  return <Form.Item {...formItemProps} />;
}

export default observer(FormItem);
