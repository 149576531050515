import { Button, Card, Col, Row, Space, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { ShiftsReportState } from "./ShiftsReportState";

function ShiftsReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new ShiftsReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;

  return (
    <Page title={intl.formatMessage({ defaultMessage: "Shifts report" })}>
      {permissionsStore.has("SelectCashier") && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            {permissionsStore.has("SelectClient") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.clientSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select client",
                  })}
                />
              </Col>
            )}
            {!!state.clientSelectorStore.selectedId && (
              <>
                {permissionsStore.has("SelectAgent") && (
                  <Col xs={24} md={8} lg={6} xl={4}>
                    <Selector
                      store={state.agentSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select an agent",
                      })}
                    />
                  </Col>
                )}
                {permissionsStore.has("SelectSubagent") && (
                  <Col xs={24} md={8} lg={6} xl={4}>
                    <Selector
                      store={state.subagentSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select subagent",
                      })}
                    />
                  </Col>
                )}
                {permissionsStore.has("SelectHall") && (
                  <Col xs={24} md={8} lg={6} xl={4}>
                    <Selector
                      store={state.hallSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select shop",
                      })}
                    />
                  </Col>
                )}
                {state.hallSelectorStore.selectedId && (
                  <Col xs={24} md={8} lg={6} xl={4}>
                    <Selector
                      store={state.cashierSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select cashier",
                      })}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </Card>
      )}
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              dataSource={state.filterQuery.items}
              showHeader={!!state.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
            >
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Cashier" })}
                dataIndex="cashierName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Opened at" })}
                align="right"
                dataIndex="startedAt"
                render={(value) => new Date(value).toLocaleString()}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Closed at" })}
                align="right"
                dataIndex="finishedAt"
                render={(value) =>
                  value ? new Date(value).toLocaleString() : "—"
                }
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Beginning cash",
                })}
                align="right"
                dataIndex="beginningCash"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Cash added" })}
                align="right"
                dataIndex="putIn"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Cash bleed" })}
                align="right"
                dataIndex="takeOut"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Bill in" })}
                align="right"
                dataIndex="deposit"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Bill out" })}
                align="right"
                dataIndex="withdraw"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Ending cash",
                })}
                align="right"
                dataIndex="endingCash"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Profit" })}
                align="right"
                dataIndex="profit"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Bounceback" })}
                align="right"
                dataIndex="bounceback"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Cashback" })}
                align="right"
                dataIndex="cashback"
                render={(value) => <MoneyFormatter cents={value} />}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Status" })}
                dataIndex="status"
                render={(value) =>
                  value === "started"
                    ? intl.formatMessage({ defaultMessage: "Opened" })
                    : intl.formatMessage({ defaultMessage: "Closed" })
                }
              />
            </Table>
            <Pagination
              query={state.filterQuery}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(ShiftsReport);
