import { observer } from "mobx-react-lite";

import { Entity } from "@/types";

import { useEditBannerFormState } from "./edit-form.state";
import { EditBannerFormView } from "./edit-form.view";

export type EditBannerFormProps = {
  id: number;
  entity: Entity | null;
};

export const EditBannerForm = observer<EditBannerFormProps>((props) => {
  const state = useEditBannerFormState(props);
  return <EditBannerFormView state={state} />;
});
