import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify";
import remarkFrontmatter from "remark-frontmatter";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { Plugin, unified } from "unified";
import { visit } from "unist-util-visit";

export class MarkdownService {
  constructor(
    private markdown: string,
    private url: string,
  ) {}

  compile() {
    return unified()
      .use(remarkParse)
      .use(remarkFrontmatter)
      .use(remarkGfm)
      .use(remarkRehype)
      .use(this.fixImageURL)
      .use(rehypeSanitize)
      .use(rehypeStringify)
      .process(this.markdown);
  }

  private fixImageURL: Plugin = () => (tree) => {
    visit(tree, "element", (node: any) => {
      if (node.tagName === "img") {
        try {
          // absolute path
          const url = new URL(node.properties.src);
          node.properties.src = url.toString();
        } catch (_) {
          // relative path
          try {
            const url = new URL(node.properties.src, this.url);
            node.properties.src = url.toString();
          } catch (_) {
            // invalid path
          }
        }
      }
    });
  };
}
