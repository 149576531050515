import {
  TAgent,
  TAgentBonusSettings,
  TAgentCashierSettings,
  TAgentLobbySettings,
  TPaging,
} from "..";
import MethodBuilder from "../method-builder";

class AgentAPI {
  static create = new MethodBuilder<
    {
      clientId: number;
      parentAgentId?: number;

      name: string;

      login: string;
      password: string;

      isBalanceUnlimited: boolean;
      currency?: string;

      canHaveChildren: boolean;
    },
    TAgent
  >()
    .withName("Agent.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      password?: string;
      isBalanceUnlimited?: boolean;
      isBlocked?: boolean;
      autoEnableGames?: boolean;
      canHaveChildren?: boolean;
      configuredByParent?: boolean;
      bonusSettings?: Partial<TAgentBonusSettings>;
      lobbySettings?: Partial<TAgentLobbySettings>;
      cashierSettings?: Partial<TAgentCashierSettings>;
    },
    TAgent
  >()
    .withName("Agent.update")
    .build();

  static getById = new MethodBuilder<{ id: number }, TAgent>()
    .withName("Agent.getById")
    .build();

  static filter = new MethodBuilder<
    (
      | {
          clientId: number;
        }
      | {
          parentAgentId: number;
        }
    ) & {
      nestingLevel?: number;
      searchQuery?: string;
      currency?: string;
      includeDeleted?: boolean;
      paging: TPaging;
    },
    { data: TAgent[]; total: number }
  >()
    .withName("Agent.filter")
    .build();

  static withdrawMoney = new MethodBuilder<
    { id: number; amount: number },
    void
  >()
    .withName("Agent.withdrawMoney")
    .build();

  static depositMoney = new MethodBuilder<
    { id: number; amount: number },
    void
  >()
    .withName("Agent.depositMoney")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Agent.delete")
    .build();

  static undelete = new MethodBuilder<{ id: number }, void>()
    .withName("Agent.undelete")
    .build();
}

export default AgentAPI;
