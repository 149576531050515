import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { CreatePlayer, PlayerCreated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { PlayerCreateForm } from "./player-create-form";

export const PlayerCreateModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      openEvent={CreatePlayer}
      closeEvent={PlayerCreated}
      title={intl.formatMessage({ defaultMessage: "New player" })}
    >
      {({ hallId }) => <PlayerCreateForm hallId={hallId} />}
    </EventBasedModal>
  );
});
