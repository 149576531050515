import { useCallback } from "react";

import { useGlobalStore } from "@/stores";
import { CurrencyUtilities } from "@/utilities";

export const useMoneyFormatter = () => {
  const { intlService } = useGlobalStore();
  const formatMoney = useCallback(
    (cents: number, currency?: string, fractionDigits = 2) => {
      const dollars = CurrencyUtilities.toMainUnits(cents);
      const options: Intl.NumberFormatOptions = {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      };
      if (currency) {
        options.style = "currency";
        options.currency = currency;
      }
      const formatter = new Intl.NumberFormat(
        intlService.locale.replace("_", "-"),
        options,
      );
      const parts = formatter.formatToParts(dollars);
      return parts
        .map((part) => {
          if (part.type === "group") {
            part.value = intlService.groupSeparator;
          } else if (part.type === "decimal") {
            part.value = intlService.decimalSeparator;
          }
          return part.value;
        })
        .join("");
    },
    [
      intlService.locale,
      intlService.groupSeparator,
      intlService.decimalSeparator,
    ],
  );
  return { formatMoney };
};
