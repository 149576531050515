import { Button, Col, Row, Select, Space, Transfer } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { CategoryGamesFormState } from "./form.state";

type Props = {
  state: CategoryGamesFormState;
};

export const CategoryGamesFormView = observer<Props>(({ state }) => {
  const {
    query,
    selectedBrandId,
    setSelectedBrandId,
    brandOptions,
    gameItems,
    targetKeys,
    setTargetKeys,
    mutation,
  } = state;

  if (query.isPending) {
    return <FormSpinner />;
  }

  if (query.isRejected) {
    return <ErrorsFormatter queries={[query]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[mutation]} />
      <Select
        style={{ width: 200 }}
        options={brandOptions}
        value={selectedBrandId}
        onChange={setSelectedBrandId}
        placeholder="Select Brand"
        allowClear
      />
      <Transfer
        className="w-full"
        dataSource={gameItems}
        render={(item) => item.name}
        titles={["Available Games", "Enabled Games"]}
        targetKeys={targetKeys}
        onChange={setTargetKeys}
        listStyle={{ flex: 1, height: 300 }}
        showSearch
        filterOption={(search, item) => {
          const query = search.toLowerCase().trim();
          return (
            item.name.toLowerCase().includes(query) ||
            item.stringId.toLowerCase().includes(query)
          );
        }}
      />
      <Row justify="end" gutter={12}>
        <Col>
          <Button
            type="primary"
            loading={mutation.isPending}
            onClick={mutation.submit}
          >
            {"Save"}
          </Button>
        </Col>
      </Row>
    </Space>
  );
});
