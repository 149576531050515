import { Row, Select } from "antd";
import { observer } from "mobx-react-lite";
import { CSSProperties, FC } from "react";
import { useIntl } from "react-intl";

import { useGlobalStore } from "@/stores";

type TProps = {
  style?: CSSProperties;
};

export const NumberFormatSettings: FC<TProps> = observer(({ style = {} }) => {
  const intl = useIntl();
  const { intlService } = useGlobalStore();
  return (
    <Row style={{ ...style, columnGap: 8 }}>
      <Select
        value={intlService.groupSeparator}
        onChange={(value) => (intlService.groupSeparator = value)}
        style={{ flex: 1 }}
        size="small"
      >
        <Select.Option value={","}>,</Select.Option>
        <Select.Option value={"."}>.</Select.Option>
        <Select.Option value={" "}>
          {intl.formatMessage({
            defaultMessage: "Space",
          })}
        </Select.Option>
        <Select.Option value={""}>
          {intl.formatMessage({
            defaultMessage: "None",
          })}
        </Select.Option>
      </Select>
      <Select
        value={intlService.decimalSeparator}
        onChange={(value) => (intlService.decimalSeparator = value)}
        style={{ flex: 1 }}
        size="small"
      >
        <Select.Option value={","}>,</Select.Option>
        <Select.Option value={"."}>.</Select.Option>
      </Select>
    </Row>
  );
});
