import { EditOutlined } from "@ant-design/icons";
import { Table, Row, Col, Button } from "antd";
import { observer } from "mobx-react-lite";

import { SportClubsTableState } from "./table.state";

type Props = {
  state: SportClubsTableState;
};

export const SportClubsTableView = observer<Props>(({ state }) => {
  return (
    <Table
      loading={state.query.isPending}
      bordered
      dataSource={state.query.items}
      columns={[
        {
          key: "id",
          dataIndex: "id",
          title: "ID",
        },
        {
          key: "partnerId",
          dataIndex: "partnerId",
          title: "Partner ID",
        },
        {
          key: "description",
          dataIndex: "description",
          width: "100%",
          title: "Description",
        },
        {
          key: "actions",
          align: "right",
          title: "Actions",
          render: (_, Club) => {
            return (
              <Row gutter={[12, 12]} justify="end" wrap={false}>
                <Col>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => {
                      state.openEditClubModal(Club.id);
                    }}
                  />
                </Col>
              </Row>
            );
          },
        },
      ]}
      pagination={false}
      size="small"
    />
  );
});
