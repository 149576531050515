import MethodBuilder from "../../method-builder";
import { TDailyProfitReportItem, TPaging } from "../../types";

export class DailyProfitReportAPI {
  static filter = new MethodBuilder<
    {
      clientId: number;
      agentId?: number;
      hallId?: number;
      providerId?: number;
      groupBy: "currency" | "hall" | "agent";
      currency?: string;
      dateTimeRange: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      paging: TPaging;
    },
    {
      data: TDailyProfitReportItem[];
      total: number;
    }
  >()
    .withName("DailyProfitReport.filter")
    .build();
}
