import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { OpenEditWebsiteTemplateModal } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditWebsiteTemplateForm } from "./form";

export const EditWebsiteTemplateModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Edit template" })}
      openEvent={OpenEditWebsiteTemplateModal}
    >
      {({ id }) => <EditWebsiteTemplateForm id={id} />}
    </EventBasedModal>
  );
});
