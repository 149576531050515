import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { AgentsStore } from "@/stores";

import AgentCreateForm from "./agent-create-form";

type TProps = {
  agentsStore: AgentsStore;
};

function AgentCreateModal({ agentsStore }: TProps) {
  const { agentCreateStore } = agentsStore;

  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "New agent" })}
      open={agentCreateStore.modal.isOpen}
      onCancel={agentCreateStore.modal.close}
      width={720}
      footer={null}
      destroyOnClose={true}
    >
      <AgentCreateForm agentsStore={agentsStore} />
    </Modal>
  );
}

export default observer(AgentCreateModal);
