import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { CreateVoucher } from "@/events";
import { useGlobalStore } from "@/stores";

import { EventBasedModal } from "../_common_/event-based-modal";

import { CreateVoucherForm } from "./create-voucher-form";
import { CreateVouchersForm } from "./create-vouchers-form";

export const CreateVoucherModal = observer(() => {
  const intl = useIntl();
  const { userStore } = useGlobalStore();
  const isCashier = userStore.user.role === "Cashier";
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Create voucher" })}
      openEvent={CreateVoucher}
      className={isCashier ? "" : "modal_with-tabs"}
    >
      {({ hallId }) =>
        isCashier ? (
          <CreateVoucherForm hallId={hallId} />
        ) : (
          <Tabs
            items={[
              {
                key: "single",
                label: intl.formatMessage({ defaultMessage: "Single" }),
                children: <CreateVoucherForm hallId={hallId} />,
              },
              {
                key: "multiple",
                label: intl.formatMessage({ defaultMessage: "Multiple" }),
                children: <CreateVouchersForm hallId={hallId} />,
              },
            ]}
            destroyInactiveTabPane={true}
          />
        )
      }
    </EventBasedModal>
  );
});
