import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { CashierAPI, ShiftAPI } from "@/api";
import { CashRegisterDeposited } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { UserStore } from "@/stores";
import { CurrencyUtilities } from "@/utilities";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  cashierQuery = new Query(CashierAPI.getById);

  get cashier() {
    return this.cashierQuery.data;
  }

  onViewMount = ({ cashierId }: Props) => {
    this.cashierQuery.submit({ id: cashierId });
  };

  onViewUnmount = () => {
    this.cashierQuery.reset();
  };

  depositQuery = new Query(ShiftAPI.depositMoney);

  handleSubmit = async ({ amount }: ISchema) => {
    if (!this.cashier) {
      return;
    }

    await this.depositQuery.submit({
      cashierId: this.cashier.id,
      amount: CurrencyUtilities.toMinorUnits(amount),
    });

    if (!this.depositQuery.isFulfilled) {
      return;
    }

    await this.userStore.fetchCashier(this.cashier.id);

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The cash register has been successfully deposited.",
      }),
    });

    this.eventBusService.publish(
      new CashRegisterDeposited({ cashierId: this.cashier.id }),
    );
  };
}
