import { makeAutoObservable } from "mobx";

import { CashierAPI, ShiftAPI } from "@/api";
import { CashierCreated, CreateCashier, ShiftFinished } from "@/events";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import Query from "@/models/query";

import { CashierStore, GeneralSelectorsStore } from "..";
import { GlobalStore } from "../global";

export class CashiersStore {
  constructor(public globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.generalSelectorsStore = new GeneralSelectorsStore(
      this.globalStore,
      this.filter,
    );

    this.filter();

    this.eventBusService.subscribe(ShiftFinished, () => this.filter());
    this.eventBusService.subscribe(CashierCreated, () => this.filter());
  }

  onMount() {
    const hallId = this.generalSelectorsStore.hallSelectorStore.selectedId;
    if (this.routerService.searchParams["action"] === "new" && hallId) {
      this.eventBusService.publish(new CreateCashier({ hallId }));
    }
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  get eventBusService() {
    return this.globalStore.eventBusService;
  }

  public readonly generalSelectorsStore: GeneralSelectorsStore;

  private _cashierStores: CashierStore[] = [];

  get cashierStores() {
    return this._cashierStores;
  }

  setCashierStores = (cashierStores: CashierStore[]) => {
    this._cashierStores = cashierStores;
  };

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
    this.filter();
  }

  filterQuery = new ItemsQuery(CashierAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
    initialOrder: ["id", "desc"],
  });

  shiftsQuery = new Query(async (cashierIds: Array<number | undefined>) => {
    const promises = cashierIds.map(async (cashierId) => {
      if (!cashierId) {
        return undefined;
      }
      const query = new Query(ShiftAPI.get);
      await query.submit({ cashierId });
      return query;
    });
    return await Promise.all(promises);
  });

  filter = async (options?: TSubmitOptions) => {
    if (!this.generalSelectorsStore.hallSelectorStore.selectedId) {
      this.setCashierStores([]);
      return;
    }
    const includeDeletedParams = this.permissionsStore.has(
      "ViewDeletedCashiers",
    )
      ? { includeDeleted: this.includeDeleted }
      : {};
    await this.filterQuery.submit(
      {
        hallId: this.generalSelectorsStore.hallSelectorStore.selectedId,
        ...includeDeletedParams,
      },
      options,
    );

    if (!this.filterQuery.isFulfilled) {
      return;
    }

    const { items } = this.filterQuery;

    const cashierIds = items.map((item) => {
      if (!item.isShiftStarted) {
        return undefined;
      }
      return item.id;
    });

    await this.shiftsQuery.submit(cashierIds);

    if (!this.shiftsQuery.isFulfilled) {
      return;
    }

    const shifts = this.shiftsQuery.data;

    const cashierStores = items.map(
      (cashier, index) => new CashierStore(this, cashier, shifts[index]),
    );
    this.setCashierStores(cashierStores);
  };
}
