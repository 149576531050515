import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { autorun } from "mobx";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { FortuneWheelAPI } from "@/api";
import Query from "@/models/query";
import { centsToDollars, dollarsToCents } from "@/utilities";
import {
  hoursToSeconds,
  secondsToHours,
} from "@/utilities/date-time-utilities";

import { IFortuneWheelSettingsProps } from "./fortune-wheel-settings";
import {
  useFortuneWheelSettingsSchema,
  TFortuneWheelSettingsSchema,
} from "./fortune-wheel-settings.schema";

export function useFortuneWheelSettingsState({
  entity,
  selfEdit = false,
  readOnly = false,
}: IFortuneWheelSettingsProps) {
  const intl = useIntl();

  const schema = useFortuneWheelSettingsSchema();
  const form = useForm<TFortuneWheelSettingsSchema>({
    resolver: yupResolver(schema),
  });

  const fortuneWheelQuery = useMemo(() => {
    const query = new Query(FortuneWheelAPI.get);
    query.submit(entity);
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      return autorun(() => {
        const fortuneWheel = fortuneWheelQuery.data;
        if (fortuneWheel) {
          let values = secondsToHours(fortuneWheel, [
            "cooldown",
            "maxDepositAge",
          ]);
          values = centsToDollars(values, ["sectors.*.value"]);
          form.reset(values);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const toggleAccessQuery = useMemo(
    () => {
      return new Query(async () => {
        if (fortuneWheelQuery.data) {
          await FortuneWheelAPI.revokeAccess(entity);
        } else {
          await FortuneWheelAPI.grantAccess(entity);
        }
        const fortuneWheel = await FortuneWheelAPI.get(entity);
        fortuneWheelQuery.resolve(entity, fortuneWheel);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const submitQuery = useMemo(() => {
    return new Query(async (values: TFortuneWheelSettingsSchema) => {
      let newValues = dollarsToCents(values, ["sectors.*.value"]);
      newValues = hoursToSeconds(newValues, ["cooldown", "maxDepositAge"]);
      await FortuneWheelAPI.update({ ...entity, ...newValues });
      const fortuneWheel = await FortuneWheelAPI.get(entity);
      fortuneWheelQuery.resolve(entity, fortuneWheel);

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage:
            "The fortune wheel settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intl,
    entity,
    selfEdit,
    readOnly,
    fortuneWheelQuery,
    toggleAccessQuery,
    form,
    submitQuery,
  };
}

export type TFortuneWheelSettingsState = ReturnType<
  typeof useFortuneWheelSettingsState
>;
