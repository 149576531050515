import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { OpenEditWebsiteDomainModal } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditWebsiteDomainForm } from "./form";

export const EditWebsiteDomainModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Edit domain" })}
      openEvent={OpenEditWebsiteDomainModal}
    >
      {({ id }) => <EditWebsiteDomainForm id={id} />}
    </EventBasedModal>
  );
});
