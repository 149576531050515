import yup from "@/yup";

export function useAgentOtherSettingsSchema() {
  return yup.object({
    cashierSettings: yup.object({
      isShortPlayerCreation: yup.boolean(),
    }),
  });
}

export type AgentOtherSettingsSchema = yup.InferType<
  ReturnType<typeof useAgentOtherSettingsSchema>
>;
