import { useMemo } from "react";

import { TAgent, TClient, THall, TUser } from "@/api";

export function useClientId(client?: TClient, agent?: TAgent, hall?: THall) {
  return useMemo(() => {
    if (client) {
      return client.id;
    }
    if (agent) {
      return agent.clientId;
    }
    if (hall) {
      return hall.clientId;
    }
    return null;
  }, [client, agent, hall]);
}

export function useAgentId(agent?: TAgent) {
  return useMemo(() => {
    if (agent) {
      return agent.id;
    }
    return null;
  }, [agent]);
}

export function useHallId(user: TUser) {
  return useMemo(() => {
    if ("hallId" in user) {
      return user.hallId;
    }
    return null;
  }, [user]);
}

export function useBalance(agent?: TAgent, hall?: THall) {
  return useMemo(() => {
    if (agent) {
      return {
        value: agent.balance,
        isUnlimited: agent.isBalanceUnlimited,
        currency: agent.currency,
      };
    }
    if (hall) {
      return {
        value: hall.balance,
        isUnlimited: hall.isBalanceUnlimited,
        currency: hall.currency,
      };
    }
    return null;
  }, [agent, hall]);
}
