import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const MethodSelect = observer<Props>(({ value, onChange }) => {
  const intl = useIntl();
  return (
    <Select
      placeholder={intl.formatMessage({
        defaultMessage: "Select method",
      })}
      options={[
        {
          label: intl.formatMessage({ defaultMessage: "Crypto" }),
          value: "crypto",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Card" }),
          value: "card",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Bank account" }),
          value: "bank_account",
        },
        {
          label: intl.formatMessage({ defaultMessage: "Phone" }),
          value: "phone",
        },
      ]}
      value={value}
      onChange={onChange}
      onClear={onChange}
      allowClear
      style={{ width: "100%" }}
    />
  );
});
