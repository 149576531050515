import { TGamesReportAggregatedItem, TGamesReportItem, TPaging } from "../..";
import MethodBuilder from "../../method-builder";

export class GamesReportAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
      | { kioskId: number }
    ) &
      ({ providerId?: number } | { gameId?: number }) & {
        currency?: string;
        dateTimeRange?: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      },
    {
      data: TGamesReportItem[];
      total: number;
    }
  >()
    .withName("GamesReport.filter")
    .build();

  static aggregate = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { playerId: number }
      | { kioskId: number }
    ) &
      ({ providerId?: number } | { gameId?: number }) & {
        groupBy: "provider" | "game";
        orderBy?: [
          "rtp" | "profit" | "actionsCount" | "gameName",
          "asc" | "desc",
        ];
        currency?: string;
        dateTimeRange?: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      },
    {
      data: TGamesReportAggregatedItem[];
      total: number;
    }
  >()
    .withName("GamesReport.aggregate")
    .build();
}
