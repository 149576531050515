import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Input, Layout, Row, Space, Spin } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useResetForm } from "@/hooks";
import { TUserLogInSchema, useUserLogInSchema } from "@/schemas";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import FullscreenSpinner from "@/ui/_common_/fullscreen-spinner";

function LogIn() {
  const { userStore, currencyStore, intlService } = useGlobalStore();
  const { logInQuery } = userStore;

  const {
    userQuery,
    clientQuery,
    agentQuery,
    hallQuery,
    cashierQuery,
    shiftQuery,
  } = userStore;
  const { currenciesQuery } = currencyStore;

  useEffect(() => {
    userStore.fetchUser();
  }, [userStore]);
  const schema = useUserLogInSchema();
  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<TUserLogInSchema>({
    defaultValues: {
      login: "",
      password: "",
    },
    resolver,
  });

  useResetForm(userStore.logInQuery, reset);

  const intl = useIntl();

  if (
    userQuery.isPending ||
    clientQuery.isPending ||
    agentQuery.isPending ||
    hallQuery.isPending ||
    cashierQuery.isPending ||
    shiftQuery.isPending ||
    currenciesQuery.isPending ||
    intlService.messagesQuery.isPending ||
    intlService.antdLocaleQuery.isPending
  ) {
    return <FullscreenSpinner />;
  }

  return (
    <Layout
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
      }}
    >
      <Row style={{ flex: 1 }} justify="center" align="middle">
        <Col xs={24} md={16} lg={10}>
          <Spin spinning={logInQuery.isPending}>
            <Card style={{ width: "100%" }}>
              <Space direction="vertical">
                <ErrorsFormatter queries={[logInQuery]} />
                <Form
                  labelCol={{ span: 6 }}
                  labelAlign="left"
                  wrapperCol={{ span: 18 }}
                  layout="horizontal"
                  onFinish={handleSubmit(userStore.logIn)}
                >
                  <Form.Item
                    label={intl.formatMessage({
                      defaultMessage: "Username",
                    })}
                    validateStatus={has(errors, "login") ? "error" : ""}
                    help={errors.login?.message}
                  >
                    <Controller
                      control={control}
                      name="login"
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter username",
                          })}
                        />
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ defaultMessage: "Password" })}
                    validateStatus={has(errors, "password") ? "error" : ""}
                    help={errors.password?.message}
                  >
                    <Controller
                      control={control}
                      name="password"
                      defaultValue=""
                      render={({ field }) => (
                        <Input.Password
                          {...field}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter password",
                          })}
                        />
                      )}
                    />
                  </Form.Item>
                  <Row justify="end">
                    <Button type="primary" htmlType="submit">
                      {intl.formatMessage({ defaultMessage: "Log in" })}
                    </Button>
                  </Row>
                </Form>
              </Space>
            </Card>
          </Spin>
        </Col>
      </Row>
    </Layout>
  );
}

export default observer(LogIn);
