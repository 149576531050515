import { makeAutoObservable } from "mobx";

import { KioskAPI } from "@/api";
import {
  KioskCreated,
  CloseEditKioskModal,
  PlayerBalanceDeposited,
  PlayerBalanceWithdrawn,
} from "@/events";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";

import { GeneralSelectorsStore, KioskStore } from "..";
import { GlobalStore } from "../global";
import { VoucherRedeemStore } from "../vouchers";

export class KiosksStore {
  public readonly generalSelectorsStore: GeneralSelectorsStore;
  voucherRedeemStore = new VoucherRedeemStore(this.intlService);

  filterQuery = new ItemsQuery(KioskAPI.filter, {
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
    initialOrder: ["id", "desc"],
  });

  constructor(public globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.generalSelectorsStore = new GeneralSelectorsStore(
      this.globalStore,
      this.filter,
    );

    this.filter();

    const { eventBusService } = this.globalStore;

    eventBusService.subscribe(PlayerBalanceDeposited, (event) => {
      const { playerId } = event.payload;
      const kioskStore = this.kioskStores.find(
        (kioskStore) => kioskStore.kiosk.playerId === playerId,
      );
      kioskStore?.fetch();
    });

    eventBusService.subscribe(PlayerBalanceWithdrawn, (event) => {
      const { playerId } = event.payload;
      const kioskStore = this.kioskStores.find(
        (kioskStore) => kioskStore.kiosk.playerId === playerId,
      );
      kioskStore?.fetch();
    });

    eventBusService.subscribe(CloseEditKioskModal, (event) => {
      const { kioskId } = event.payload;
      const kioskStore = this.kioskStores.find(
        (kioskStore) => kioskStore.kiosk.id === kioskId,
      );
      kioskStore?.fetch();
    });

    eventBusService.subscribe(KioskCreated, () => {
      this.filter();
    });
  }

  get intlService() {
    return this.globalStore.intlService;
  }

  get intl() {
    return this.globalStore.intlService.intl;
  }

  get userStore() {
    return this.globalStore.userStore;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  private _kioskStores: KioskStore[] = [];

  get kioskStores() {
    return this._kioskStores;
  }

  private get routerService() {
    return this.globalStore.routerService;
  }

  setKioskStores = (kioskStores: KioskStore[]) => {
    this._kioskStores = kioskStores;
  };

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
    this.filter();
  }

  filter = async (options?: TSubmitOptions) => {
    if (!this.generalSelectorsStore.hallSelectorStore.selectedId) {
      this.setKioskStores([]);
      return;
    }
    const includeDeletedParams = this.permissionsStore.has("ViewDeletedKiosks")
      ? { includeDeleted: this.includeDeleted }
      : {};
    await this.filterQuery.submit(
      {
        hallId: this.generalSelectorsStore.hallSelectorStore.selectedId,
        ...includeDeletedParams,
      },
      options,
    );

    if (!this.filterQuery.isFulfilled) {
      return;
    }

    const { items } = this.filterQuery;
    const kioskStores = items.map(
      (kiosk) => new KioskStore(kiosk, this, this.intlService),
    );
    this.setKioskStores(kioskStores);
  };
}
