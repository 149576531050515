import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Col, Form, Input, Row, Space, Spin } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { TClientMode } from "@/api";
import { useResetForm } from "@/hooks";
import { useClientCreateSchema, TClientCreateSchema } from "@/schemas";
import { ClientsStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { InfoIcon } from "@/ui/_common_/icons";
import Password from "@/ui/_common_/password";
import Selector from "@/ui/_common_/selector";
import { generatePassword } from "@/utilities";

import { ClientModeSelect } from "../ui/client-mode-select";

type TProps = {
  clientsStore: ClientsStore;
};

function ClientCreateForm({ clientsStore }: TProps) {
  const { clientCreateStore } = clientsStore;
  const schema = useClientCreateSchema();
  const resolver = yupResolver(schema);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm<TClientCreateSchema>({
    resolver,
  });
  const isClientModeSS =
    (watch("clientMode", "normal") as TClientMode) !== ("normal" || "");

  useResetForm(clientCreateStore.createQuery, reset);

  const intl = useIntl();

  return (
    <Spin spinning={clientCreateStore.createQuery.isPending}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[clientCreateStore.createQuery]} />
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleSubmit(clientCreateStore.handleSubmit)}
        >
          {clientCreateStore.permissionsStore.has("SelectManager") && (
            <Form.Item
              label={intl.formatMessage({ defaultMessage: "Manager" })}
            >
              <Selector
                placeholder={intl.formatMessage({
                  defaultMessage: "Select manager",
                })}
                store={clientCreateStore.managerSelectorStore}
              />
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Name" })}
            validateStatus={has(errors, "name") ? "error" : ""}
            help={errors.name?.message}
          >
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter name",
                  })}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin username" })}
            validateStatus={has(errors, "login") ? "error" : ""}
            help={errors.login?.message}
          >
            <Controller
              control={control}
              name="login"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter username",
                  })}
                  readOnly
                  onFocus={(e) => e.target.removeAttribute("readonly")}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin password" })}
            validateStatus={has(errors, "password") ? "error" : ""}
            help={errors.password?.message}
          >
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter password",
                  })}
                  onCopy={() => {
                    const password = getValues().password ?? "";
                    if (password) {
                      navigator.clipboard.writeText(password);
                    }
                  }}
                  onGenerate={() => setValue("password", generatePassword())}
                  readOnly
                  onFocus={(e) => e.target.removeAttribute("readonly")}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Main currency" })}
            validateStatus={has(errors, "currency") ? "error" : ""}
            help={errors.currency?.message}
          >
            <Controller
              control={control}
              name="currency"
              defaultValue={undefined}
              render={({ field }) => (
                <CurrencySelector
                  {...field}
                  value={field.value ? field.value : undefined}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Mode" })}
            validateStatus={has(errors, "Mode") ? "error" : ""}
            help={errors.clientMode?.message}
          >
            <Space direction="vertical">
              <Controller
                control={control}
                name="clientMode"
                render={({ field }) => (
                  <ClientModeSelect
                    {...field}
                    value={field.value ? field.value : undefined}
                  />
                )}
              />
              {isClientModeSS && (
                <Alert
                  type="warning"
                  showIcon
                  icon={<InfoIcon style={{ width: 20 }} />}
                  message={intl.formatMessage(
                    {
                      defaultMessage:
                        "Attention! You can choose the Sweepstakes mode only when creating a Client.{br}By enabling this mode, you will not be able to turn it off in the future.",
                    },
                    { br: <br /> },
                  )}
                />
              )}
            </Space>
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({ defaultMessage: "Create" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
}

export default observer(ClientCreateForm);
