import { yupResolver } from "@hookform/resolvers/yup";
import { forEach, template as compileTemplate } from "lodash-es";
import { ChangeEvent, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { PlayerAPI, WebsiteAPI } from "@/api";
import { VoucherCreated } from "@/events";
import Query from "@/models/query";
import { PrintService } from "@/services";
import { useGlobalStore } from "@/stores";
import { dollarsToCents } from "@/utilities";

import { CreateVouchersFormProps } from "./create-vouchers-form";
import {
  CreateVouchersFormSchema,
  useCreateVouchersFormSchema,
} from "./create-vouchers-form.schema";
import template from "./create-vouchers-form.template.html";

const compiledTemplate = compileTemplate(template);

export function useCreateVouchersFormState({
  hallId,
}: CreateVouchersFormProps) {
  const intl = useIntl();

  const schema = useCreateVouchersFormSchema();
  const form = useForm<CreateVouchersFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: { prefix: "" },
  });

  const websitesQuery = useMemo(() => {
    const query = new Query(WebsiteAPI.filter);
    query.submit({ hallId }).then(() => {
      const websites = query.data?.data ?? [];
      if (!websites.length) {
        return;
      }
      const website =
        websites.find((website) => website.hasPriority) ?? websites[0];
      form.setValue("websiteId", website.id);
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(
    () =>
      new Query(async (values: CreateVouchersFormSchema) => {
        const players = await PlayerAPI.massGenerate({
          hallId,
          ...dollarsToCents(values, ["initialBalance"]),
        });

        eventBusService.publish(new VoucherCreated({}));

        return { players };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const websites = websitesQuery.data?.data ?? [];

  const players = useMemo(
    () => submitQuery.data?.players ?? [],
    [submitQuery.data?.players],
  );

  const websiteOptions = websites.map((website) => ({
    label: website.domainName,
    value: website.id,
  }));

  const websiteId = form.watch("websiteId");

  const website = websites.find((website) => website.id === websiteId);

  const [primaryTitle, setPrimaryTitle] = useState("Voucher");
  const [secondaryTitle, setSecondaryTitle] = useState("");
  const [note, setNote] = useState(
    [
      "1. Access the website specified on the voucher using a computer or mobile phone.",
      "2. Use the login and password provided on the voucher to access the games.",
      "3. If you wish to deposit or withdraw funds, please contact our cashier by phone or visit our shop.",
    ].join("\n"),
  );

  function handlePrimaryTitleChange(event: ChangeEvent<HTMLInputElement>) {
    setPrimaryTitle(event.target.value);
  }

  function handleSecondaryTitleChange(event: ChangeEvent<HTMLInputElement>) {
    setSecondaryTitle(event.target.value);
  }

  function handleNoteChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNote(event.target.value);
  }

  const printQuery = useMemo(
    () =>
      new Query(async () => {
        if (!website) {
          return;
        }

        const html = compiledTemplate({
          forEach,

          date: new Date().toLocaleString(),
          website: website.domainName,
          primaryTitle,
          secondaryTitle,
          note,

          players,
        });

        await PrintService.print(html);
      }),

    [note, players, primaryTitle, secondaryTitle, website],
  );

  return {
    intl,
    form,
    websitesQuery,
    websiteOptions,

    submitQuery,

    primaryTitle,
    secondaryTitle,
    note,

    handlePrimaryTitleChange,
    handleSecondaryTitleChange,
    handleNoteChange,

    printQuery,
  };
}

export type CreateVouchersFormState = ReturnType<
  typeof useCreateVouchersFormState
>;
