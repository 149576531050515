import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import { useGlobalStore } from "@/stores";

export const AuthGuard: FC = observer(() => {
  const globalStore = useGlobalStore();
  const location = useLocation();
  if (!globalStore.userStore.user) {
    const from = location.pathname + location.search + location.hash;
    const search = new URLSearchParams({ from }).toString();
    return <Navigate to={{ pathname: "/log-in", search }} replace={true} />;
  }
  return <Outlet />;
});
