import { makeAutoObservable } from "mobx";

import { PlayerAPI, SweepstakesAPI } from "@/api";
import { PlayerBalanceExchanged } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { UserStore } from "@/stores";
import { SweepstakeOperationType } from "@/types";
import { CurrencyUtilities } from "@/utilities";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    private userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  playerQuery = new Query(PlayerAPI.getById);

  get player() {
    return this.playerQuery.data!;
  }

  sweepstakeQuery = new Query(SweepstakesAPI.get);

  get sweepstake() {
    return this.sweepstakeQuery.data!;
  }

  get isIdleOrPending() {
    return (
      this.playerQuery.isIdle ||
      this.playerQuery.isPending ||
      this.sweepstakeQuery.isIdle ||
      this.sweepstakeQuery.isPending
    );
  }

  get isRejected() {
    return this.playerQuery.isRejected || this.sweepstakeQuery.isRejected;
  }

  private _operationType: SweepstakeOperationType = "entries-to-total-win";

  get operationType() {
    return this._operationType;
  }

  onViewMount = async ({ playerId, operationType: type }: Props) => {
    this._operationType = type;

    await this.playerQuery.submit({ id: playerId });
    await this.sweepstakeQuery.submit({ hallId: this.player!.hallId });
  };

  onViewUnmount = () => {
    this.playerQuery.reset();
    this.sweepstakeQuery.reset();
  };

  exchangeQuery = new Query(SweepstakesAPI.exchange);

  get isExchangePending() {
    return (
      this.exchangeQuery.isPending ||
      this.userStore.cashierQuery.isPending ||
      this.userStore.shiftQuery.isPending
    );
  }

  handleSubmit = async ({ type, amount }: ISchema) => {
    if (!this.player) {
      return;
    }

    await this.exchangeQuery.submit({
      type,
      playerId: this.player.id,
      amount: CurrencyUtilities.toMinorUnits(amount),
    });

    if (!this.exchangeQuery.isFulfilled) {
      return;
    }

    if (this.userStore.cashier) {
      await this.userStore.fetchCashier(this.userStore.cashier.id);
    }

    this.eventBusService.publish(
      new PlayerBalanceExchanged({ playerId: this.player.id }),
    );

    await this.onViewMount({
      playerId: this.player.id,
      operationType: this._operationType,
    });
  };
}
