import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { BrandAPI, ProviderAPI } from "@/api";
import { BrandCreated } from "@/events";
import Query from "@/models/query";
import { SelectorStore, useGlobalStore } from "@/stores";

import { FormSchema, useFormSchema } from "./form.schema";

export function useFormState() {
  const intl = useIntl();

  const schema = useFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<FormSchema>({ resolver });

  const providerSelectorStore = useMemo(() => {
    const store = new SelectorStore({
      filterMethod: ProviderAPI.filter,
      getByIdMethod: ProviderAPI.getById,
      labelKey: "name",
      onSelect(provider) {
        if (provider?.id) {
          form.setValue("providerId", provider.id);
        } else {
          form.resetField("providerId");
        }
      },
    });
    store.setParameters({});
    store.fetchItems();
    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: FormSchema) => {
      await BrandAPI.massBrandCreate({ brands: [values] });
      eventBusService.publish(new BrandCreated({}));
      message.success({
        content: "The brand was successfully created.",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = form.handleSubmit((values) => {
    submitQuery.submit(values);
  });

  return {
    intl,

    form,

    submitQuery,

    providerSelectorStore,

    handleSubmit,
  };
}

export type FormState = ReturnType<typeof useFormState>;
