import { Button, Card, Col, Row, Space, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TSettlementsReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { SettlementsReportState } from "./SettlementsReportState";

function SettlementsReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new SettlementsReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  return (
    <Page title={intl.formatMessage({ defaultMessage: "Settlement report" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {permissionsStore.has("SelectAgent") &&
            !!state.clientSelectorStore.selectedId && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.agentSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select an agent",
                  })}
                />
              </Col>
            )}
          {permissionsStore.has("SelectHall") &&
            !!state.clientSelectorStore.selectedId && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.hallSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select shop",
                  })}
                />
              </Col>
            )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      {!!state.clientSelectorStore.selectedId && (
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} lg={12}>
            <Card
              title={intl.formatMessage({ defaultMessage: "Payer" })}
              size="small"
            >
              <Row>
                {permissionsStore.has("SelectAgent") && (
                  <Col xs={24}>
                    <Selector
                      store={state.payerAgentSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select an agent",
                      })}
                    />
                  </Col>
                )}
                {permissionsStore.has("SelectSubagent") && (
                  <Col xs={24}>
                    <Selector
                      store={state.payerSubagentSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select subagent",
                      })}
                    />
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card
              title={intl.formatMessage({ defaultMessage: "Recipient" })}
              size="small"
            >
              <Row gutter={[12, 12]} wrap={true}>
                {permissionsStore.has("SelectAgent") && (
                  <Col xs={24} lg={12}>
                    <Selector
                      store={state.recipientAgentSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select an agent",
                      })}
                    />
                  </Col>
                )}
                {permissionsStore.has("SelectSubagent") && (
                  <Col xs={24} lg={12}>
                    <Selector
                      store={state.recipientSubagentSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select subagent",
                      })}
                    />
                  </Col>
                )}
                {permissionsStore.has("SelectHall") && (
                  <Col xs={24} lg={12}>
                    <Selector
                      store={state.recipientHallSelectorStore}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select shop",
                      })}
                    />
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      )}
      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              dataSource={state.filterQuery.items}
              showHeader={!!state.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
            >
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "ID",
                })}
                dataIndex="id"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Date/time" })}
                dataIndex="createdAt"
                render={(_, item: TSettlementsReportItem) =>
                  new Date(item.createdAt).toLocaleString()
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Payer" })}
                dataIndex="payerName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Recipient" })}
                dataIndex="recipientName"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Amount",
                })}
                dataIndex="amount"
                render={(_, item: TSettlementsReportItem) => (
                  <MoneyFormatter cents={item.amount} />
                )}
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex="currency"
                align="right"
              />
            </Table>
            <Pagination
              query={state.filterQuery}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(SettlementsReport);
