import Query from "@/models/query";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";

export function useQueriesState(queries: Query[]) {
  const isLoading = queries.some((query) => query.isIdle || query.isPending);
  const isRejected = queries.some((query) => query.isRejected);
  const error = <ErrorsFormatter queries={queries} />;

  return {
    isLoading,
    isRejected,
    error,
  };
}
