import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { omit } from "lodash-es";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { JackpotAPI, JackpotSlotTemplateAPI, JackpotTemplateAPI } from "@/api";
import { JACKPOT_ID } from "@/constants";
import Query from "@/models/query";
import {
  CurrencyUtilities,
  omitNulls,
  dollarsToCents,
  centsToDollars,
} from "@/utilities";

import { JackpotSettingsFormProps } from "./jackpot-settings-form";
import {
  useJackpotSettingsFormSchema,
  JackpotSettingsFormSchema,
} from "./jackpot-settings-form.schema";

export function useJackpotSettingsFormState({
  entity,
}: JackpotSettingsFormProps) {
  const intl = useIntl();

  const schema = useJackpotSettingsFormSchema();

  const form = useForm<JackpotSettingsFormSchema>({
    resolver: yupResolver(schema),
  });

  const formValues = form.watch();

  const { jackpotsQuery, templatesQuery, slotTemplatesQuery } = useMemo(() => {
    const jackpotsQuery = new Query(JackpotAPI.filter);
    const templatesQuery = new Query(JackpotTemplateAPI.getAll);
    const slotTemplatesQuery = new Query(JackpotSlotTemplateAPI.getAll);
    jackpotsQuery.submit(entity);
    templatesQuery.submit({});
    slotTemplatesQuery.submit();
    return { jackpotsQuery, templatesQuery, slotTemplatesQuery };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useMemo(() => {
    return (
      jackpotsQuery.isIdle ||
      jackpotsQuery.isPending ||
      templatesQuery.isIdle ||
      templatesQuery.isPending ||
      slotTemplatesQuery.isIdle ||
      slotTemplatesQuery.isPending
    );
  }, [
    jackpotsQuery.isIdle,
    jackpotsQuery.isPending,
    slotTemplatesQuery.isIdle,
    slotTemplatesQuery.isPending,
    templatesQuery.isIdle,
    templatesQuery.isPending,
  ]);

  const jackpot = useMemo(() => {
    const jackpot = jackpotsQuery.data?.data.at(0);
    if (!jackpot) {
      return;
    }
    const values = schema.cast(jackpot, {
      stripUnknown: true,
    });
    const newValues = centsToDollars(values, [
      "expectedMonthlyBetSum",
      "slots.*.defaultValue",
      "slots.*.minValue",
      "slots.*.maxValue",
    ]);
    form.reset(newValues);
    return jackpot;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jackpotsQuery.data]);

  const templates = useMemo(() => {
    return templatesQuery.data?.data ?? [];
  }, [templatesQuery.data]);

  const slotTemplates = useMemo(() => {
    return slotTemplatesQuery.data?.data ?? [];
  }, [slotTemplatesQuery.data]);

  const toggleAccessQuery = useMemo(() => {
    return new Query(async () => {
      if (jackpot) {
        await JackpotAPI.revokeAccess({ ...entity, ids: [JACKPOT_ID] });
      } else {
        await JackpotAPI.grantAccess({ ...entity, ids: [JACKPOT_ID] });
      }

      const jackpots = await JackpotAPI.filter(entity);
      jackpotsQuery.resolve(entity, jackpots);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jackpot, jackpotsQuery]);

  function handleTemplateSelect(templateId: number) {
    const jackpot = jackpotsQuery.data!.data.at(0)!;
    const stripedJackpotValues = schema.cast(jackpot, {
      stripUnknown: true,
    });
    const jackpotValues = centsToDollars(stripedJackpotValues, [
      "expectedMonthlyBetSum",
      "slots.*.defaultValue",
      "slots.*.minValue",
      "slots.*.maxValue",
    ]);

    const template = templates.find((template) => template.id === templateId)!;
    const templateValues = omit(omitNulls(template), "id");

    const slotTemplateValues = jackpot.slots.map((slot) => {
      const slotTemplate = slotTemplates.find(
        (slotTemplate) =>
          slotTemplate.id === slot.id &&
          slotTemplate.jackpotTemplateId == templateId,
      );

      const slotValues = omitNulls(slotTemplate ?? {});

      return { ...slot, ...slotValues };
    });

    const values = schema.cast(
      {
        ...jackpotValues,
        ...templateValues,
        slots: slotTemplateValues,
      },
      { stripUnknown: true },
    );

    form.reset(values);
  }

  const isPayoutWarningVisible = useMemo(() => {
    const slots = formValues.slots ?? [];
    return (
      !formValues.isPayoutEnabled &&
      slots.some(({ id, maxValue }) => {
        const slot = jackpot?.slots?.find((slot) => slot.id === id);
        const value = slot?.value ?? 0;
        return CurrencyUtilities.toMainUnits(value) > maxValue;
      })
    );
  }, [formValues, jackpot]);

  const isCooldownWarningVisibile = useMemo(() => {
    const slots = formValues.slots ?? [];
    return slots.some((slot) => slot.isCommunity && !+slot.communityCooldown);
  }, [formValues]);

  const totalWins = useMemo(() => {
    const slots = formValues.slots ?? [];
    return slots.map((slot) => {
      const a = +(slot?.minValue ?? 0);
      const b = +(slot?.maxValue ?? 0);
      const c = +(slot?.expectedMonthlyWinsCount ?? 0);
      const result = ((a + b) / 2) * c;
      if (!Number.isFinite(result) || Number.isNaN(result)) {
        return 0;
      }
      return result;
    });
  }, [formValues.slots]);

  const totalWinSum = useMemo(() => {
    return totalWins.reduce((sum, total) => sum + total, 0);
  }, [totalWins]);

  const avgPercentage = useMemo(() => {
    const slots = formValues.slots ?? [];
    const G = slots
      .map((slot, index) => {
        const d = totalWins.at(index)!;
        const e = +slot.defaultValue;
        const c = +slot.expectedMonthlyWinsCount;
        const g = d - e * c;
        return g;
      })
      .reduce((sum, g) => sum + g, 0);
    const f = +(formValues.expectedMonthlyBetSum ?? 0);
    const j = 1;
    const result = (G / f) * j * 100;
    if (!Number.isFinite(result) || Number.isNaN(result)) {
      return 0;
    }
    return result;
  }, [formValues, totalWins]);

  const reset = useCallback(
    async (slotIds: number[]) => {
      if (!("hallId" in entity)) {
        return;
      }

      await JackpotAPI.reset({
        id: jackpot!.id,
        hallId: entity.hallId,
        slotIds,
      });

      const jackpots = await JackpotAPI.filter(entity);
      jackpotsQuery.resolve(entity, jackpots);

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The jackpot values have been successfully reset.",
        }),
      });
    },
    [entity, intl, jackpot, jackpotsQuery],
  );

  const refreshQuery = useMemo(() => {
    return new Query<void, void>(async () => {
      const jackpots = await JackpotAPI.filter(entity);
      jackpotsQuery.resolve(entity, jackpots);

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage:
            "The jackpot values have been successfully refreshed.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetQueries = useMemo(() => {
    return Array(4)
      .fill(0)
      .map((value, index) => new Query<void, void>(() => reset([index])));
  }, [reset]);

  const resetQuery = useMemo(
    () => new Query<void, void>(() => reset([0, 1, 2, 3])),
    [reset],
  );

  const syncQuery = useMemo(() => {
    return new Query<void, void>(async () => {
      await JackpotAPI.sync({ ...entity, id: jackpot!.id });

      const jackpots = await JackpotAPI.filter(entity);
      jackpotsQuery.resolve(entity, jackpots);

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage:
            "The jackpot settings have been successfully synchronized.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jackpot]);

  const submitQuery = useMemo(() => {
    return new Query(async (values: JackpotSettingsFormSchema) => {
      const newValues = dollarsToCents(values, [
        "expectedMonthlyBetSum",
        "slots.*.defaultValue",
        "slots.*.minValue",
        "slots.*.maxValue",
      ]);

      await JackpotAPI.update({ ...entity, ...newValues });

      const jackpots = await JackpotAPI.filter(entity);
      jackpotsQuery.resolve(entity, jackpots);

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage:
            "The jackpot settings have been successfully updated.",
        }),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intl,
    form,
    entity,
    isLoading,
    jackpot,
    templates,
    toggleAccessQuery,
    handleTemplateSelect,
    totalWins,
    totalWinSum,
    avgPercentage,
    refreshQuery,
    resetQueries,
    resetQuery,
    syncQuery,
    submitQuery,
    isPayoutWarningVisible,
    isCooldownWarningVisibile,
  };
}

export type JackpotSettingsFormState = ReturnType<
  typeof useJackpotSettingsFormState
>;
