import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { WebsiteTemplateAPI } from "@/api";
import { WebsiteTemplateCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import {
  NewWebsiteTemplateFormSchema,
  useNewWebsiteTemplateFormSchema,
} from "./form.schema";

export function useNewWebsiteTemplateFormState() {
  const intl = useIntl();

  const schema = useNewWebsiteTemplateFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<NewWebsiteTemplateFormSchema>({ resolver });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(
    () =>
      new Query(async (values: NewWebsiteTemplateFormSchema) => {
        await WebsiteTemplateAPI.create(values);

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "Website template created",
          }),
        });

        eventBusService.publish(new WebsiteTemplateCreated({}));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit((values) => {
    mutation.submit(values);
  });

  return { intl, form, handleSubmit, mutation };
}

export type NewWebsiteTemplateFormState = ReturnType<
  typeof useNewWebsiteTemplateFormState
>;
