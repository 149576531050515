import { TBonus } from "..";
import MethodBuilder from "../method-builder";

class BonusAPI {
  static filter = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      searchQuery?: string;
    },
    {
      data: TBonus[];
      total: number;
    }
  >()
    .withName("Bonus.filter")
    .build();

  static getAll = new MethodBuilder<
    void,
    {
      data: Array<{
        id: number;
        type: string;
        stringId: string;
      }>;
      total: number;
    }
  >()
    .withName("Bonus.getAll")
    .build();

  static update = new MethodBuilder<
    (
      | {
          clientId: number;
        }
      | {
          agentId: number;
        }
      | {
          hallId: number;
        }
    ) & {
      id: number;
      minDeposit?: number;
      maxDeposit?: number;
      depositPercentage?: number;
      maxActivationsCount?: number;
      playerBalanceThreshold?: number;
      cooldown?: number;
      isWagerEnabled?: boolean;
      isEnabled?: boolean;
    },
    void
  >()
    .withName("Bonus.update")
    .build();

  static grantAccess = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Bonus.grantAccess")
    .build();

  static revokeAccess = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Bonus.revokeAccess")
    .build();
}

export default BonusAPI;
