import { makeAutoObservable } from "mobx";

import { ViewModel } from "@/hooks/use-view-model";
import { Event, EventBusService } from "@/services";

import { Newable, Payload, Props } from "./event-based-modal";

export class EventBasedModalViewModel<
  OpenEvent extends Event<any>,
  CloseEvent extends Event<any>,
> implements ViewModel<Props<OpenEvent, CloseEvent>>
{
  constructor(
    private eventBusService: EventBusService,
    private openEvent: Newable<OpenEvent>,
    private closeEvent?: Newable<CloseEvent>,
  ) {
    makeAutoObservable<
      EventBasedModalViewModel<any, any>,
      "openEvent" | "closeEvent"
    >(
      this,
      {
        openEvent: false,
        closeEvent: false,
      },
      { autoBind: true },
    );
  }

  private _payload?: Payload<OpenEvent>;

  get payload() {
    return this._payload;
  }

  onViewMount = () => {
    this.eventBusService.subscribe(this.openEvent, this.handleModalEvent);
    if (this.closeEvent) {
      this.eventBusService.subscribe(this.closeEvent, this.handleCloseEvent);
    }
  };

  onViewUnmount = () => {
    this.eventBusService.unsubscribe(this.openEvent, this.handleModalEvent);
    if (this.closeEvent) {
      this.eventBusService.unsubscribe(this.closeEvent, this.handleCloseEvent);
    }

    this.close();
  };

  handleModalEvent = (event: OpenEvent) => {
    this.open(event.payload);
  };

  handleCloseEvent = () => {
    this.close();
  };

  private open(payload: Payload<OpenEvent>) {
    this._payload = payload;
  }

  close() {
    this._payload = undefined;
  }
}
