import { Button, Col, Form, Row } from "antd";
import { FC } from "react";

import { FormInput, FormItem, FormRadioGroup } from "../_common_/form-helpers";

import { LineEditorState } from "./line-editor.state";

interface Props {
  state: LineEditorState;
}

export const LineEditorView: FC<Props> = ({
  state: { intl, index, form, textRef, handlePlaceholderClick },
}) => (
  <Form
    component="div"
    labelCol={{ span: 6 }}
    labelAlign="right"
    wrapperCol={{ span: 18 }}
    layout="horizontal"
    size="small"
  >
    <FormItem
      form={form}
      path={`lines.${index}.type`}
      label={intl.formatMessage({ defaultMessage: "Type" })}
    >
      <FormRadioGroup
        form={form}
        path={`lines.${index}.type`}
        options={[
          {
            label: intl.formatMessage({ defaultMessage: "Text" }),
            value: "text",
          },
          {
            label: intl.formatMessage({ defaultMessage: "QR Code" }),
            value: "qr",
          },
        ]}
      />
    </FormItem>
    <FormItem
      form={form}
      path={`lines.${index}.align`}
      label={intl.formatMessage({ defaultMessage: "Align" })}
    >
      <FormRadioGroup
        form={form}
        path={`lines.${index}.align`}
        options={[
          {
            label: intl.formatMessage({ defaultMessage: "Left" }),
            value: "l",
          },
          {
            label: intl.formatMessage({ defaultMessage: "Center" }),
            value: "c",
          },
          {
            label: intl.formatMessage({ defaultMessage: "Right" }),
            value: "r",
          },
        ]}
      />
    </FormItem>
    <FormItem
      form={form}
      path={`lines.${index}.size`}
      label={intl.formatMessage({ defaultMessage: "Size" })}
    >
      <FormInput
        form={form}
        path={`lines.${index}.size`}
        placeholder={intl.formatMessage({ defaultMessage: "Enter size" })}
      />
    </FormItem>
    <FormItem
      form={form}
      path={`lines.${index}.text`}
      label={intl.formatMessage({ defaultMessage: "Text" })}
    >
      <FormInput
        inputRef={textRef}
        form={form}
        path={`lines.${index}.text`}
        placeholder={intl.formatMessage({ defaultMessage: "Text" })}
      />
      <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %AMOUNT%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %CURRENCY%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %PIN%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %CVV%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %DATE%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %TIME%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %LOGIN%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %PASSWORD%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %URL%
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={handlePlaceholderClick}
          >
            %QR_CVV_PIN%
          </Button>
        </Col>
      </Row>
    </FormItem>
  </Form>
);
