import { TEntityTreeItem } from "..";
import MethodBuilder from "../method-builder";

class EntityTreeAPI {
  static getAncestorsTree = new MethodBuilder<
    { clientId: number } | { agentId: number } | { hallId: number },
    {
      data: TEntityTreeItem[];
      total: number;
    }
  >()
    .withName("EntityTree.getAncestorsTree")
    .build();
}

export default EntityTreeAPI;
