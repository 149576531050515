import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { WebsiteTemplateAPI } from "@/api";
import { WebsiteTemplateEdited } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditWebsiteTemplateFormProps } from "./form";
import {
  EditWebsiteTemplateFormSchema,
  useEditWebsiteTemplateFormSchema,
} from "./form.schema";

export function useEditWebsiteTemplateFormState(
  props: EditWebsiteTemplateFormProps,
) {
  const intl = useIntl();

  const query = useMemo(() => {
    const query = new Query(WebsiteTemplateAPI.getById);
    query.submit({ id: props.id });
    return query;
  }, [props.id]);

  const schema = useEditWebsiteTemplateFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditWebsiteTemplateFormSchema>({ resolver });

  useEffect(() => {
    const values = schema.cast(query.data, {
      stripUnknown: true,
      assert: false,
    });
    form.reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(
    () =>
      new Query(async (values: EditWebsiteTemplateFormSchema) => {
        await WebsiteTemplateAPI.update({ id: props.id, ...values });

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "Website template updated",
          }),
        });

        eventBusService.publish(new WebsiteTemplateEdited({}));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.id],
  );

  const handleSubmit = form.handleSubmit((values) => {
    mutation.submit(values);
  });

  return { intl, query, form, handleSubmit, mutation };
}

export type EditWebsiteTemplateFormState = ReturnType<
  typeof useEditWebsiteTemplateFormState
>;
