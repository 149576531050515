import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { HallDepositStore } from "@/stores";

import HallDepositForm from "./hall-deposit-form";

type TProps = {
  hallDepositStore: HallDepositStore;
};

function HallDepositModal({ hallDepositStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Deposit balance" })}
      open={hallDepositStore.modal.isOpen}
      onCancel={hallDepositStore.modal.close}
      footer={null}
      destroyOnClose={true}
    >
      <HallDepositForm hallDepositStore={hallDepositStore} />
    </Modal>
  );
}

export default observer(HallDepositModal);
