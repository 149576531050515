import { observer } from "mobx-react-lite";

import { useAgentLobbySettingsState } from "./agent-lobby-settings.state";
import { AgentLobbySettingsView } from "./agent-lobby-settings.view";

export interface AgentLobbySettingsProps {
  agentId: number;
}

export const AgentLobbySettings = observer((props: AgentLobbySettingsProps) => {
  const state = useAgentLobbySettingsState(props);
  return <AgentLobbySettingsView state={state} />;
});
