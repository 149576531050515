import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { WebsiteAPI, WebsiteImagePackAPI, WebsiteTemplateAPI } from "@/api";
import { WebsiteDomainEdited } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditWebsiteDomainFormProps } from "./form";
import {
  EditWebsiteDomainFormSchema,
  useEditWebsiteDomainFormSchema,
} from "./form.schema";

export function useEditWebsiteDomainFormState(
  props: EditWebsiteDomainFormProps,
) {
  const intl = useIntl();

  const query = useMemo(() => {
    const query = new Query(WebsiteAPI.getById);
    query.submit({ id: props.id });
    return query;
  }, [props.id]);

  const imagePacksQuery = useMemo(() => {
    const query = new Query(WebsiteImagePackAPI.getAll);
    query.submit();
    return query;
  }, []);

  const templatesQuery = useMemo(() => {
    const query = new Query(WebsiteTemplateAPI.getAll);
    query.submit();
    return query;
  }, []);

  const schema = useEditWebsiteDomainFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditWebsiteDomainFormSchema>({ resolver });

  useEffect(() => {
    const values = schema.cast(query.data, {
      stripUnknown: true,
      assert: false,
    });
    form.reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(
    () =>
      new Query(async (values: EditWebsiteDomainFormSchema) => {
        await WebsiteAPI.update({ id: props.id, ...values });

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "Website domain updated",
          }),
        });

        eventBusService.publish(new WebsiteDomainEdited({}));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.id],
  );

  const handleSubmit = form.handleSubmit((values) => {
    mutation.submit(values);
  });

  return {
    intl,
    query,
    imagePacksQuery,
    templatesQuery,
    form,
    handleSubmit,
    mutation,
  };
}

export type EditWebsiteDomainFormState = ReturnType<
  typeof useEditWebsiteDomainFormState
>;
