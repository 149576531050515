import { observer } from "mobx-react-lite";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

import { generatePassword } from "@/utilities";

import Password from "../password";

interface Props<FV extends FieldValues> {
  form: UseFormReturn<FV>;
  path: Path<FV>;
  placeholder: string;
}

export const FormPassword = observer(
  <FV extends FieldValues>({ form, path, placeholder }: Props<FV>) => {
    return (
      <Controller
        control={form.control}
        name={path}
        render={({ field }) => (
          <Password
            {...field}
            placeholder={placeholder}
            onCopy={() => navigator.clipboard.writeText(field.value)}
            onGenerate={() => field.onChange(generatePassword())}
          />
        )}
      />
    );
  },
);
