import { makeAutoObservable } from "mobx";

class Modal {
  constructor() {
    makeAutoObservable(this);
  }

  isOpen = false;

  open = () => {
    this.isOpen = true;
  };

  close = () => {
    this.isOpen = false;
  };
}

export default Modal;
