import yup from "@/yup";

export function useSchema() {
  return yup.object({
    multiplier: yup.number().integer().required(),
    isExchangeEnabled: yup.boolean().defined(),
  });
}

export type Schema = yup.InferType<ReturnType<typeof useSchema>>;
