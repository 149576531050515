import {
  BulbOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Input, InputProps, InputRef } from "antd";
import { omit } from "lodash-es";
import { observer } from "mobx-react-lite";
import { forwardRef, Ref, useCallback, useState } from "react";

type TProps = InputProps & {
  onCopy?: () => void;
  onGenerate?: () => void;
};

function Password(props: TProps, ref: Ref<InputRef>) {
  const [isHidden, setIsHidden] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsHidden(!isHidden);
  }, [isHidden]);

  const handleGenerateClick = useCallback(() => {
    setIsHidden(false);
    props.onGenerate?.();
  }, [props]);

  return (
    <Input.Group compact style={{ display: "flex", flexDirection: "row" }}>
      <Input
        ref={ref}
        {...omit(props, ["onCopy", "onGenerate"])}
        type={isHidden ? "password" : "text"}
        style={{ ...props.style, flex: 1 }}
        // disable autocomplete
        readOnly
        onFocus={(e) => e.target.removeAttribute("readonly")}
      />
      <Button
        icon={isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        onClick={handleVisibilityChange}
      />
      <Button icon={<CopyOutlined />} onClick={props.onCopy} />
      <Button icon={<BulbOutlined />} onClick={handleGenerateClick} />
    </Input.Group>
  );
}

export default observer(forwardRef(Password));
