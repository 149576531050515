import MethodBuilder from "../method-builder";
import { TGame, TGameByCategory, TPaging } from "../types";

type TMassGameCreate = {
  stringId: string;
  name: string;
  brandId: number;
  providerId: number;
  isJackpotsEnabled: boolean;
};

class GameAPI {
  static massCreate = new MethodBuilder<
    { games: TMassGameCreate[] },
    { data: TGame[]; total: number }
  >()
    .withName("Game.massCreate")
    .build();

  static filter = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      providerId?: number;
      searchQuery?: string;
      orderBy?: ["id" | "name" | "order", "asc" | "desc"];
      paging: TPaging;
    },
    {
      data: TGame[];
      total: number;
    }
  >()
    .withName("Game.filter")
    .build();

  static getById = new MethodBuilder<{ id: number }, TGame>()
    .withName("Game.getById")
    .build();

  static update = new MethodBuilder<
    {
      id: number;

      order?: number;
      tagIds?: number[];
      name?: string;
      stringId?: string;
      isJackpotsEnabled?: boolean;
      isEnabled?: boolean;
    },
    void
  >()
    .withName("Game.update")
    .build();

  static enable = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Game.enable")
    .build();

  static disable = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Game.disable")
    .build();

  static grantAccess = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
      setToChildren?: boolean;
    },
    void
  >()
    .withName("Game.grantAccess")
    .build();

  static revokeAccess = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
      setToChildren?: boolean;
    },
    void
  >()
    .withName("Game.revokeAccess")
    .build();

  static setToChildren = new MethodBuilder<
    { clientId: number } | { agentId: number } | { hallId: number },
    void
  >()
    .withName("Game.setToChildren")
    .build();

  static updateActiveList = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { kioskId: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Game.updateActiveList")
    .build();

  static getAllByCategoryId = new MethodBuilder<
    { id: number },
    { data: TGameByCategory[] }
  >()
    .withName("Game.getAllByCategoryId")
    .build();

  static getAllWithoutCategory = new MethodBuilder<
    { paging: TPaging },
    { data: TGameByCategory[] }
  >()
    .withName("Game.getAllWithoutCategory")
    .build();
}

export default GameAPI;
