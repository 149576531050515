import { notification } from "antd";
import { template as compileTemplate } from "lodash-es";
import { makeAutoObservable } from "mobx";

import { CashierAPI, HallAPI, ShiftAPI } from "@/api";
import { ShiftStarted } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService, PrintService } from "@/services";
import { UserStore } from "@/stores";
import { CurrencyUtilities } from "@/utilities";

import { Props } from "./Form";
import template from "./receipt.html";
import { ISchema } from "./schema";

const generateHtml = compileTemplate(template);

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hallQuery = new Query(HallAPI.getById);

  get hall() {
    return this.hallQuery.data;
  }

  cashierQuery = new Query(CashierAPI.getById);

  get cashier() {
    return this.cashierQuery.data;
  }

  onViewMount = async ({ cashierId }: Props) => {
    await this.cashierQuery.submit({ id: cashierId });

    if (!this.cashierQuery.isFulfilled) {
      return;
    }

    const id = this.cashierQuery.data.hallId;
    await this.hallQuery.submit({ id });
  };

  onViewUnmount = () => {
    this.cashierQuery.reset();
  };

  startShiftQuery = new Query(ShiftAPI.start);

  handleSubmit = async ({ beginningCash, printReceipt }: ISchema) => {
    if (!this.cashier) {
      return;
    }

    await this.startShiftQuery.submit({
      cashierId: this.cashier.id,
      beginningCash: CurrencyUtilities.toMinorUnits(beginningCash),
    });

    if (!this.startShiftQuery.isFulfilled) {
      return;
    }

    await this.userStore.fetchCashier(this.cashier.id);

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The shift has been successfully opened.",
      }),
    });

    this.eventBusService.publish(
      new ShiftStarted({ cashierId: this.cashier.id }),
    );

    if (printReceipt) {
      this.printReceipt(beginningCash);
    }
  };

  printReceipt = (beginningCash: number) => {
    if (!this.hall || !this.cashier) {
      return;
    }

    const html = generateHtml({
      hall: this.hall,
      cashier: this.cashier,
      beginningCash,
      date: new Date().toLocaleString(),
    });

    PrintService.print(html);
  };
}
