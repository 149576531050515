import { TTag } from "@/api";

import MethodBuilder from "../method-builder";

class TagAPI {
  static getAll = new MethodBuilder<
    void,
    {
      data: TTag[];
      total: number;
    }
  >()
    .withName("Tag.getAll")
    .build();
}

export default TagAPI;
