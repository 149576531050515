import { AgentAPI, HallAPI } from "@/api";
import { Entity } from "@/types";

export async function getParentEntity(entity: Entity): Promise<Entity | null> {
  if ("agentId" in entity) {
    const agent = await AgentAPI.getById({ id: entity.agentId });
    if (agent.parentAgentId) {
      return { agentId: agent.parentAgentId };
    } else {
      return { clientId: agent.clientId };
    }
  }

  if ("hallId" in entity) {
    const hall = await HallAPI.getById({ id: entity.hallId });
    if (hall.agentId) {
      return { agentId: hall.agentId };
    } else {
      return { clientId: hall.clientId };
    }
  }

  return null;
}
