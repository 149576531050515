import { useIntl } from "react-intl";

import yup from "@/yup";

export function useEditKioskAccessFormSchema() {
  const intl = useIntl();
  return yup.object({
    pin: yup
      .string()
      .matches(
        /^[0-9]{6,}$/,
        intl.formatMessage({ defaultMessage: "Invalid PIN" }),
      )
      .required(),
  });
}

export type EditKioskAccessFormSchema = yup.InferType<
  ReturnType<typeof useEditKioskAccessFormSchema>
>;
