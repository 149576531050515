import { makeAutoObservable } from "mobx";

import {
  GameAPI,
  HallAPI,
  HistoryReportAPI,
  KioskAPI,
  PlayerAPI,
  ProviderAPI,
} from "@/api";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import Modal from "@/models/modal";
import Query from "@/models/query";

import { HistoryReportState } from "../HistoryReportState";

class HistoryReportActionsStore {
  constructor(public readonly historyReportStore: HistoryReportState) {
    makeAutoObservable(this);
  }

  modal = new Modal();

  get userRole() {
    return this.historyReportStore.userStore.user.role;
  }

  get filterSessionRequest() {
    return this.historyReportStore.filterQuery.request;
  }

  hallQuery = new Query(HallAPI.getById);

  playerQuery = new Query(PlayerAPI.getById);

  get playerId(): number | undefined {
    if (!this.filterSessionRequest) {
      return undefined;
    }
    if ("playerId" in this.filterSessionRequest) {
      return this.filterSessionRequest.playerId;
    }
    return undefined;
  }

  kioskQuery = new Query(KioskAPI.getById);

  get kioskId(): number | undefined {
    if (!this.filterSessionRequest) {
      return undefined;
    }
    if ("kioskId" in this.filterSessionRequest) {
      return this.filterSessionRequest.kioskId;
    }
    return undefined;
  }

  providerQuery = new Query(ProviderAPI.getById);

  get providerId(): number | undefined {
    if (!this.filterSessionRequest) {
      return undefined;
    }
    if ("providerId" in this.filterSessionRequest) {
      return this.filterSessionRequest.providerId;
    }
    return undefined;
  }

  gameQuery = new Query(GameAPI.getById);

  get gameId(): number | undefined {
    if (!this.filterSessionRequest) {
      return undefined;
    }
    if ("gameId" in this.filterSessionRequest) {
      return this.filterSessionRequest.gameId;
    }
    return undefined;
  }

  get isReady() {
    return (
      (!this.providerId || this.providerQuery.isFulfilled) &&
      this.hallQuery.isFulfilled &&
      this.filterQuery.isFulfilled
    );
  }

  get dateTimeRange() {
    return this.historyReportStore.dateTimeRange;
  }

  private _sessionId?: string;

  get sessionId(): string | undefined {
    return this._sessionId;
  }

  set sessionId(value: string | undefined) {
    this._sessionId = value;
  }

  private _searchQuery = "";

  get searchQuery(): string {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    this._searchQuery = value;
  }

  filterQuery = new ItemsQuery(HistoryReportAPI.filterActions, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  filter = async (options?: TSubmitOptions) => {
    if ((!this.playerId && !this.kioskId) || !this.sessionId) {
      return;
    }

    let firstGroupParams:
      | { playerId: number }
      | { kioskId: number }
      | { searchQuery: string }
      | null = null;
    if (this.searchQuery.length > 0) {
      firstGroupParams = { searchQuery: this.searchQuery };
    } else if (this.playerId) {
      firstGroupParams = { playerId: this.playerId };
    } else if (this.kioskId) {
      firstGroupParams = { kioskId: this.kioskId };
    }
    if (firstGroupParams === null) {
      return;
    }

    let secondGroupParams: { providerId?: number } | { gameId?: number } = {};
    if (this.gameId) {
      secondGroupParams = { gameId: this.gameId };
    } else if (this.providerId) {
      secondGroupParams = {
        providerId: this.providerId,
      };
    }

    await this.filterQuery.submit(
      {
        ...firstGroupParams,
        ...secondGroupParams,
        sessionId: this.sessionId,
        dateTimeRange: {
          from: this.dateTimeRange[0].toISOString(),
          to: this.dateTimeRange[1].toISOString(),
        },
      },
      options,
    );
  };

  fetch = async () => {
    await Promise.all([
      this.fetchPlayer(),
      this.fetchKiosk(),
      this.fetchGame(),
    ]);
    await Promise.all([this.fetchHall(), this.fetchProvider()]);
  };

  reset = async () => {
    this.hallQuery.reset();
    this.playerQuery.reset();
    this.kioskQuery.reset();
    this.providerQuery.reset();
    this.gameQuery.reset();
    this.filterQuery.reset();
  };

  private fetchHall = async () => {
    if (this.playerQuery.isFulfilled) {
      await this.hallQuery.submit({
        id: this.playerQuery.data.hallId,
      });
    } else if (this.kioskQuery.isFulfilled) {
      await this.hallQuery.submit({
        id: this.kioskQuery.data.hallId,
      });
    }
  };

  private fetchPlayer = async () => {
    if (this.playerId) {
      await this.playerQuery.submit({
        id: this.playerId,
      });
    }
  };

  private fetchKiosk = async () => {
    if (this.kioskId) {
      await this.kioskQuery.submit({
        id: this.kioskId,
      });
    }
  };

  private fetchProvider = async () => {
    if (this.providerId) {
      await this.providerQuery.submit({
        id: this.providerId,
      });
    } else if (this.gameQuery.isFulfilled) {
      await this.providerQuery.submit({
        id: this.gameQuery.data.providerId,
      });
    }
  };

  private fetchGame = async () => {
    if (this.gameId) {
      await this.gameQuery.submit({
        id: this.gameId,
      });
    }
  };
}

export default HistoryReportActionsStore;
