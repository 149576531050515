import yup from "@/yup";

export function useHallDepositSchema() {
  return yup.object({
    amount: yup.number().money().min(0).required(),
  });
}

export type THallDepositSchema = yup.InferType<
  ReturnType<typeof useHallDepositSchema>
>;
