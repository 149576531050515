import { notification } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { BrandAPI, DemoGameAPI, ProviderAPI } from "@/api";
import Query from "@/models/query";

import { FormProps } from "./form";

export function useFormState(props: FormProps) {
  const intl = useIntl();

  const carouselRef = useRef<CarouselRef>(null);

  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(
    null,
  );
  const [selectedBrandId, setSelectedBrandId] = useState<number | null>(null);
  const [checkedGames, setCheckedGames] = useState(new Set<number>());

  const providersQuery = useMemo(() => {
    const query = new Query(async () => {
      return await ProviderAPI.filter({ ...props.entity });
    });
    query.submit({});
    return query;
  }, [props.entity]);

  const brandsQuery = useMemo(() => {
    const query = new Query(async () => {
      return await BrandAPI.getAll();
    });
    query.submit({});
    return query;
  }, []);

  const gamesQuery = useMemo(() => {
    const query = new Query(async () => {
      return await DemoGameAPI.filter({
        ...props.entity,
        paging: { limit: 3000, offset: 0 },
      });
    });
    query.submit({});
    return query;
  }, [props.entity]);

  const isLoading = useMemo(() => {
    return (
      providersQuery.isIdle ||
      providersQuery.isPending ||
      brandsQuery.isIdle ||
      brandsQuery.isPending ||
      gamesQuery.isIdle ||
      gamesQuery.isPending
    );
  }, [
    brandsQuery.isIdle,
    brandsQuery.isPending,
    gamesQuery.isIdle,
    gamesQuery.isPending,
    providersQuery.isIdle,
    providersQuery.isPending,
  ]);

  const isRejected = useMemo(() => {
    return (
      providersQuery.isRejected ||
      brandsQuery.isRejected ||
      gamesQuery.isRejected
    );
  }, [
    brandsQuery.isRejected,
    gamesQuery.isRejected,
    providersQuery.isRejected,
  ]);

  const providers = useMemo(
    () => providersQuery.data?.data || [],
    [providersQuery.data?.data],
  );
  const brands = useMemo(
    () => brandsQuery.data?.data || [],
    [brandsQuery.data?.data],
  );
  const games = useMemo(
    () => gamesQuery.data?.data || [],
    [gamesQuery.data?.data],
  );

  useEffect(() => {
    const providerId =
      providers
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .at(0)?.id ?? null;

    const brandId =
      brands
        .filter((brand) => brand.providerId === providerId)
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .at(0)?.id ?? null;

    const checkedGames = games
      .filter((game) => game.isEnabled)
      .map((game) => game.id);

    setSelectedProviderId(providerId);
    setSelectedBrandId(brandId);
    setCheckedGames(new Set(checkedGames));
  }, [providers, brands, games]);

  const providerCheckboxList = useMemo(() => {
    const checkboxList = providers
      .map((provider) => {
        const providerGames = games.filter(
          (game) => game.providerId === provider.id,
        );

        const isHidden = !providerGames.length;

        const isSelected = provider.id === selectedProviderId;

        const isChecked =
          !!providerGames.length &&
          providerGames.every((g) => checkedGames.has(g.id));

        const isIndeterminate =
          !isChecked && providerGames.some((g) => checkedGames.has(g.id));

        return {
          key: provider.id,
          label: provider.name,
          isHidden,
          isSelected,
          isChecked,
          isIndeterminate,
        };
      })
      .filter(({ isHidden }) => !isHidden);

    checkboxList.sort((a, b) => a.label.localeCompare(b.label));

    return checkboxList;
  }, [checkedGames, games, providers, selectedProviderId]);

  const handleProviderSelect = useCallback(
    (providerId: number) => {
      carouselRef.current?.goTo(1);
      setSelectedProviderId(providerId);
      const providerBrands = brands.filter(
        (brand) => brand.providerId === providerId,
      );
      setSelectedBrandId(providerBrands.at(0)?.id ?? null);
    },
    [brands],
  );

  const handleProviderCheck = useCallback(
    (providerId: number) => {
      setSelectedProviderId(providerId);

      const providerGames = games.filter(
        (game) => game.providerId === providerId,
      );

      const isProviderChecked = providerGames.every((game) =>
        checkedGames.has(game.id),
      );

      if (isProviderChecked) {
        providerGames.forEach((game) => checkedGames.delete(game.id));
      } else {
        providerGames.forEach((game) => checkedGames.add(game.id));
      }

      setCheckedGames(new Set(checkedGames));
    },
    [checkedGames, games],
  );

  const handleProviderCheckAll = useCallback(() => {
    const isChecked = providerCheckboxList.every(
      (provider) => provider.isChecked,
    );

    setCheckedGames(
      isChecked ? new Set() : new Set(games.map((game) => game.id)),
    );
  }, [games, providerCheckboxList]);

  const brandCheckboxList = useMemo(() => {
    const checkboxList = brands
      .filter((brand) => brand.providerId === selectedProviderId)
      .map((brand) => {
        const brandGames = games.filter((game) => game.brandId === brand.id);

        const isHidden = !brandGames.length;

        const isSelected = brand.id === selectedBrandId;

        const isChecked =
          !!brandGames.length &&
          brandGames.every((g) => checkedGames.has(g.id));

        const isIndeterminate =
          !isChecked && brandGames.some((g) => checkedGames.has(g.id));

        return {
          key: brand.id,
          label: brand.name,
          isHidden,
          isSelected,
          isChecked,
          isIndeterminate,
        };
      })
      .filter(({ isHidden }) => !isHidden);

    checkboxList.sort((a, b) => a.label.localeCompare(b.label));

    return checkboxList;
  }, [brands, checkedGames, games, selectedBrandId, selectedProviderId]);

  const handleBrandSelect = useCallback((brandId: number) => {
    carouselRef.current?.goTo(2);
    setSelectedBrandId(brandId);
  }, []);

  const handleBrandCheck = useCallback(
    (brandId: number) => {
      setSelectedBrandId(brandId);

      const brandGames = games.filter((game) => game.brandId === brandId);

      const isBrandChecked = brandGames.every((game) =>
        checkedGames.has(game.id),
      );

      if (isBrandChecked) {
        brandGames.forEach((game) => checkedGames.delete(game.id));
      } else {
        brandGames.forEach((game) => checkedGames.add(game.id));
      }

      setCheckedGames(new Set(checkedGames));
    },
    [checkedGames, games],
  );

  const handleBrandCheckAll = useCallback(() => {
    if (!selectedProviderId) {
      return;
    }
    handleProviderCheck(selectedProviderId);
  }, [handleProviderCheck, selectedProviderId]);

  const gameCheckboxList = useMemo(() => {
    const checkboxList = games
      .filter((game) => game.providerId === selectedProviderId)
      .filter((game) => game.brandId === selectedBrandId)
      .map((game) => {
        return {
          key: game.id,
          label: game.name,
          isChecked: checkedGames.has(game.id),
        };
      });

    checkboxList.sort((a, b) => a.label.localeCompare(b.label));

    return checkboxList;
  }, [checkedGames, games, selectedBrandId, selectedProviderId]);

  const handleGameCheck = useCallback(
    (gameId: number) => {
      if (checkedGames.has(gameId)) {
        checkedGames.delete(gameId);
      } else {
        checkedGames.add(gameId);
      }

      setCheckedGames(new Set(checkedGames));
    },
    [checkedGames],
  );

  const handleGameCheckAll = useCallback(() => {
    if (!selectedBrandId) {
      return;
    }
    handleBrandCheck(selectedBrandId);
  }, [handleBrandCheck, selectedBrandId]);

  const totalGamesCount = useMemo(() => {
    return games
      .filter((game) =>
        providers.some((provider) => provider.id === game.providerId),
      )
      .filter((game) =>
        brands.some(
          (brand) =>
            brand.id === game.brandId && game.providerId === brand.providerId,
        ),
      ).length;
  }, [brands, games, providers]);

  const checkedGamesCount = useMemo(() => {
    return checkedGames.size;
  }, [checkedGames]);

  const mutation = useMemo(() => {
    return new Query(async () => {
      await DemoGameAPI.updateActiveList({
        ...props.entity,
        ids: Array.from(checkedGames),
      });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The game settings have been successfully updated.",
        }),
      });
    });
  }, [checkedGames, intl, props.entity]);

  return {
    intl,

    entity: props.entity,

    providersQuery,
    brandsQuery,
    gamesQuery,

    mutation,

    isLoading,
    isRejected,

    providerCheckboxList,
    handleProviderSelect,
    handleProviderCheck,
    handleProviderCheckAll,

    brandCheckboxList,
    handleBrandSelect,
    handleBrandCheck,
    handleBrandCheckAll,

    gameCheckboxList,
    handleGameCheck,
    handleGameCheckAll,

    totalGamesCount,
    checkedGamesCount,

    carouselRef,
  };
}

export type FormState = ReturnType<typeof useFormState>;
