import { useIntl } from "react-intl";

import yup from "@/yup";

export function useAddKioskAccessFormSchema() {
  const intl = useIntl();
  return yup.object({
    name: yup.string().min(1).required(),
    type: yup
      .mixed<"engineer" | "stat">()
      .oneOf(["engineer", "stat"])
      .required(),
    pin: yup
      .string()
      .matches(
        /^[0-9]{6,}$/,
        intl.formatMessage({ defaultMessage: "Invalid PIN" }),
      )
      .required(),
  });
}

export type AddKioskAccessFormSchema = yup.InferType<
  ReturnType<typeof useAddKioskAccessFormSchema>
>;
