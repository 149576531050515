import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { WebsiteAPI, WebsiteImagePackAPI, WebsiteTemplateAPI } from "@/api";
import { WebsiteDomainCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import {
  NewWebsiteDomainFormSchema,
  useNewWebsiteDomainFormSchema,
} from "./form.schema";

export function useNewWebsiteDomainFormState() {
  const intl = useIntl();

  const schema = useNewWebsiteDomainFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<NewWebsiteDomainFormSchema>({ resolver });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(
    () =>
      new Query(async (values: NewWebsiteDomainFormSchema) => {
        await WebsiteAPI.create(values);

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "Website domain created",
          }),
        });

        eventBusService.publish(new WebsiteDomainCreated({}));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit((values) => {
    mutation.submit(values);
  });

  const imagePacksQuery = useMemo(() => {
    const query = new Query(WebsiteImagePackAPI.getAll);
    query.submit();
    return query;
  }, []);

  const templatesQuery = useMemo(() => {
    const query = new Query(WebsiteTemplateAPI.getAll);
    query.submit();
    return query;
  }, []);

  return {
    intl,
    imagePacksQuery,
    templatesQuery,
    form,
    handleSubmit,
    mutation,
  };
}

export type NewWebsiteDomainFormState = ReturnType<
  typeof useNewWebsiteDomainFormState
>;
