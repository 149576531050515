import { notification } from "antd";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import Query from "@/models/query";

type TImportParameters = {
  getAllQuery: Query<any, any>;
  importQuery: Query<{ format: "csv"; data: string }, void>;
};

export function useImportHandler({
  getAllQuery,
  importQuery,
}: TImportParameters) {
  const intl = useIntl();
  return useCallback(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "text/csv";
    input.multiple = false;
    input.addEventListener("change", () => {
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.addEventListener("load", async (event) => {
        if (!event.target) {
          return;
        }
        const data = event.target.result;

        if (typeof data !== "string") {
          return;
        }

        await importQuery.submit({ format: "csv", data });

        if (!importQuery.isFulfilled) {
          return;
        }

        notification.success({
          message: "",
          description: intl.formatMessage({
            defaultMessage: "File successfully imported.",
          }),
        });

        await getAllQuery.submit({});
      });
    });
    input.click();
  }, [getAllQuery, importQuery, intl]);
}
