import { observer } from "mobx-react-lite";

import { useEditClubFormState } from "./form.state";
import { EditClubFormView } from "./form.view";

export type EditClubFormProps = {
  id: number;
};

export const EditClubForm = observer<EditClubFormProps>((props) => {
  const state = useEditClubFormState(props);
  return <EditClubFormView state={state} />;
});
