import { Space, Form, Row, Button, Col, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormPassword,
  FormSwitch,
} from "../../_common_/form-helpers";
import { FormSpinner } from "../../_common_/form-spinner";

import { CashierCreateFormState } from "./cashier-create-form.state";

interface Props {
  state: CashierCreateFormState;
}

export const CashierCreateFormView = observer(({ state }: Props) => {
  const { intl, form, hallQuery, submitQuery } = state;

  if (hallQuery.isIdle || hallQuery.isPending) {
    return <FormSpinner />;
  }

  if (hallQuery.isRejected) {
    return <ErrorsFormatter queries={[hallQuery]} />;
  }

  const hall = hallQuery.data;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Shop" })}>
          <Typography.Text copyable>{hall.name}</Typography.Text>
        </Form.Item>
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
          isRequired={true}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter name",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="login"
          label={intl.formatMessage({ defaultMessage: "Login" })}
          isRequired={true}
        >
          <FormInput
            form={form}
            path="login"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter login",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="password"
          label={intl.formatMessage({ defaultMessage: "Password" })}
          isRequired={true}
        >
          <FormPassword
            form={form}
            path="password"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter password",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isShiftModeEnabled"
          label={intl.formatMessage({ defaultMessage: "Shift mode" })}
          isRequired={true}
        >
          <FormSwitch form={form} path="isShiftModeEnabled" />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
