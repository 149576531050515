import { observer } from "mobx-react-lite";

import { useHallLobbySettingsState } from "./hall-lobby-settings.state";
import { HallLobbySettingsView } from "./hall-lobby-settings.view";

export interface HallLobbySettingsProps {
  hallId: number;
}

export const HallLobbySettings = observer((props: HallLobbySettingsProps) => {
  const state = useHallLobbySettingsState(props);
  return <HallLobbySettingsView state={state} />;
});
