import { Button, Col, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem } from "@/ui/_common_/form-helpers";

import { NewWebsiteTemplateFormState } from "./form.state";

type Props = {
  state: NewWebsiteTemplateFormState;
};

export const NewWebsiteTemplateFormView = observer<Props>(({ state }) => {
  const { intl, handleSubmit, form, mutation } = state;
  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[mutation]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <FormItem
          form={form}
          path="stringId"
          label={intl.formatMessage({ defaultMessage: "String ID" })}
        >
          <FormInput
            form={form}
            path="stringId"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter string ID",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({ defaultMessage: "Enter name" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="description"
          label={intl.formatMessage({ defaultMessage: "Description" })}
        >
          <FormInput
            form={form}
            path="description"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter description",
            })}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Create" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
