import { notification } from "antd";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { BrandAPI, GameAPI, GameCategoryAPI } from "@/api";
import { CategoryEdited } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { CategoryGamesFormProps } from "./form";

export function useCategoryGamesFormState({ id }: CategoryGamesFormProps) {
  const intl = useIntl();

  const [targetKeys, setTargetKeys] = useState([] as string[]);

  const query = useMemo(() => {
    const query = new Query(async (_: void) => {
      const promises = [
        BrandAPI.getAll(),
        GameAPI.getAllByCategoryId({ id }),
        GameAPI.getAllWithoutCategory({ paging: { limit: 10_000, offset: 0 } }),
      ] as const;
      const [brands, enabledGames, availableGames] =
        await Promise.all(promises);
      setTargetKeys(enabledGames.data.map((game) => game.id.toString()));
      return {
        brands,
        enabledGames,
        availableGames,
      };
    });
    query.submit();
    return query;
  }, [id]);

  const [selectedBrandId, setSelectedBrandId] = useState<number>();

  const brandOptions = useMemo(() => {
    return (
      query.data?.brands.data.map((brand) => ({
        label: brand.name,
        value: brand.id,
      })) ?? []
    );
  }, [query.data]);

  const gameItems = useMemo(() => {
    if (!query.data) {
      return [];
    }
    const { enabledGames, availableGames } = query.data;
    return [
      ...enabledGames.data.map((game) => ({
        ...game,
        key: game.id.toString(),
      })),
      ...availableGames.data.map((game) => ({
        ...game,
        key: game.id.toString(),
      })),
    ].filter((game) => {
      if (!selectedBrandId) {
        return true;
      }
      return game.brandId === selectedBrandId;
    });
  }, [query.data, selectedBrandId]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async () => {
      await GameCategoryAPI.updateGames({
        categoryId: id,
        gameIds: targetKeys.map((key) => parseInt(key, 10)),
      });

      notification.success({
        message: "",
        description: "Category games updated successfully!",
      });

      eventBusService.publish(new CategoryEdited({}));
    });
  }, [eventBusService, id, targetKeys]);

  return {
    intl,
    query,
    gameItems,
    selectedBrandId,
    setSelectedBrandId,
    brandOptions,
    targetKeys,
    setTargetKeys,
    mutation,
  };
}

export type CategoryGamesFormState = ReturnType<
  typeof useCategoryGamesFormState
>;
