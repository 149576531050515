import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useQueryResetter, useResetForm } from "@/hooks";
import { useHallWithdrawSchema, THallWithdrawSchema } from "@/schemas";
import { HallWithdrawStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import { CurrencyUtilities } from "@/utilities";

type TProps = {
  hallWithdrawStore: HallWithdrawStore;
};

function HallWithdrawForm({ hallWithdrawStore }: TProps) {
  const schema = useHallWithdrawSchema();
  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<THallWithdrawSchema>({
    resolver,
  });

  useResetForm(hallWithdrawStore.withdrawQuery, reset);
  useQueryResetter(hallWithdrawStore.withdrawQuery);

  const intl = useIntl();

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[hallWithdrawStore.withdrawQuery]} />
      <Alert
        type="info"
        message={intl.formatMessage(
          {
            id: "HFlRzl",
            defaultMessage: "Available balance: {balance}.",
          },
          {
            balance: (
              <Typography.Link
                onClick={() =>
                  setValue(
                    "amount",
                    CurrencyUtilities.toMainUnits(
                      hallWithdrawStore.hallStore.hall.balance,
                    ),
                  )
                }
              >
                <MoneyFormatter
                  cents={hallWithdrawStore.hallStore.hall.balance}
                  isInfinity={
                    hallWithdrawStore.hallStore.hall.isBalanceUnlimited
                  }
                />{" "}
                {hallWithdrawStore.hallStore.hall.currency}
              </Typography.Link>
            ),
          },
        )}
      />
      <Form onFinish={handleSubmit(hallWithdrawStore.handleSubmit)}>
        <Form.Item
          label={intl.formatMessage({
            defaultMessage: "Amount",
          })}
          validateStatus={has(errors, "amount") ? "error" : ""}
          help={errors.amount?.message}
        >
          <Controller
            control={control}
            name="amount"
            defaultValue={undefined}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Amount",
                })}
                suffix={hallWithdrawStore.hallStore.hall.currency}
                autoFocus
                type="number"
              />
            )}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button onClick={hallWithdrawStore.modal.close}>
              {intl.formatMessage({ defaultMessage: "Cancel" })}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={hallWithdrawStore.withdrawQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Withdraw" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}

export default observer(HallWithdrawForm);
