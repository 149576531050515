import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { AgentDepositStore } from "@/stores";

import AgentDepositForm from "./agent-deposit-form";

type TProps = {
  agentDepositStore: AgentDepositStore;
};

function AgentDepositModal({ agentDepositStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Deposit balance" })}
      open={agentDepositStore.modal.isOpen}
      onCancel={agentDepositStore.modal.close}
      footer={null}
      destroyOnClose={true}
    >
      <AgentDepositForm agentDepositStore={agentDepositStore} />
    </Modal>
  );
}

export default observer(AgentDepositModal);
