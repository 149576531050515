import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem } from "@/ui/_common_/form-helpers";

import { NewPartnerFormState } from "./form.state";

type Props = {
  state: NewPartnerFormState;
};

export const NewPartnerFormView = observer<Props>(({ state }) => {
  const { form, handleSubmit, mutation } = state;

  return (
    <Form
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={handleSubmit}
    >
      <ErrorsFormatter queries={[mutation]} />
      <FormItem form={form} path="id" label={"ID"}>
        <FormInput form={form} path="id" placeholder={"Enter ID"} />
      </FormItem>
      <FormItem form={form} path="currency" label={"Currency"}>
        <FormInput form={form} path="currency" placeholder={"Enter currency"} />
      </FormItem>
      <FormItem form={form} path="description" label={"Description"}>
        <FormInput
          form={form}
          path="description"
          placeholder={"Enter description"}
        />
      </FormItem>
      <Row justify="end" gutter={12}>
        <Col>
          <Button type="primary" htmlType="submit" loading={mutation.isPending}>
            {"Save"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});
