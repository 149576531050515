import { observer } from "mobx-react-lite";
import { FC } from "react";

import { UpdateGame } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  return (
    <EventBasedModal title={"Edit game"} openEvent={UpdateGame}>
      {({ id }) => <Form id={id} />}
    </EventBasedModal>
  );
});
