import { ClockCircleOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import {
  ClockIcon,
  CoinsIcon,
  LoyaltyCard,
  SoccerIcon,
} from "@/ui/_common_/icons";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import BasePage from "@/ui/_common_/page";

import { Chat } from "./chat";
import { InstantMessagesDetailsState } from "./instant-messages-details-state";

const iconStyle = { width: 20, marginRight: "4px" };

function InstantMessagesDetails() {
  const { conversationId } = useParams<{ conversationId: string }>();
  const intl = useIntl();
  const globalStore = useGlobalStore();

  const state = useViewModel(
    () => new InstantMessagesDetailsState(globalStore),
    { conversationId: Number(conversationId) },
  );

  if (
    state.playerQuery.isIdle ||
    state.playerQuery.isPending ||
    state.conversationQuery.isPending ||
    state.conversationQuery.isIdle
  ) {
    return (
      <BasePage
        title={intl.formatMessage({
          defaultMessage: "Conversation details",
        })}
      >
        <FormSpinner />
      </BasePage>
    );
  }

  if (state.playerQuery.isRejected || state.conversationQuery.isRejected) {
    return (
      <BasePage
        title={intl.formatMessage({
          defaultMessage: "Conversation details",
        })}
      >
        <ErrorsFormatter
          queries={[state.playerQuery, state.conversationQuery]}
        />
      </BasePage>
    );
  }

  const { searchParams: search } = state;
  const player = state.playerQuery.data;
  const conversation = state.conversationQuery.data;

  return (
    <BasePage title="">
      <Row
        gutter={[10, 10]}
        align="stretch"
        className="instant-messages-details"
      >
        <Col md={24} xl={12}>
          <Chat state={state} player={player} conversation={conversation} />
        </Col>
        <Col md={24} xl={12}>
          <Space
            direction="vertical"
            style={{ maxHeight: "90dvh", overflowY: "auto" }}
          >
            <Card>
              <Descriptions column={1}>
                <Descriptions.Item
                  label={intl.formatMessage({ defaultMessage: "Participants" })}
                >
                  {conversation.users.map((user) => user.login).join(", ")}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card
              title={
                <Space direction="horizontal" align="start">
                  <Col>
                    <h4>{intl.formatMessage({ defaultMessage: "Player" })}</h4>
                  </Col>
                  <Col>
                    <span>{player.login}</span>
                  </Col>
                  <Col>
                    <span>{player.id}</span>
                  </Col>
                </Space>
              }
            >
              <Descriptions column={{ xs: 1, sm: 1, lg: 3 }}>
                <Descriptions.Item
                  span={24}
                  label={intl.formatMessage({
                    defaultMessage: "Registered At",
                  })}
                >
                  {moment(new Date(player.createdAt)).format(
                    "DD.MM.YYYY HH:mm",
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={intl.formatMessage({
                    defaultMessage: "Client",
                  })}
                >
                  {conversation?.clientName ?? "—"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={intl.formatMessage({
                    defaultMessage: "Agent",
                  })}
                >
                  {conversation?.agentName ?? "—"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={intl.formatMessage({
                    defaultMessage: "Shop",
                  })}
                >
                  {conversation?.hallName ?? "—"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card title={intl.formatMessage({ defaultMessage: "Financial" })}>
              <Descriptions column={1}>
                <Descriptions.Item
                  label={intl.formatMessage({ defaultMessage: "Last deposit" })}
                >
                  <Row gutter={[24, 0]}>
                    <Col>
                      {player.lastDepositAmount !== 0 ? (
                        <MoneyFormatter cents={player.lastDepositAmount} />
                      ) : (
                        "—"
                      )}
                    </Col>
                    <Col>
                      <ClockCircleOutlined />{" "}
                      {player.lastDepositDate !== null
                        ? moment(new Date(player.lastDepositDate)).format(
                            "DD.MM.YYYY HH:mm",
                          )
                        : "—"}
                    </Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item
                  label={intl.formatMessage({
                    defaultMessage: "Last withdraw",
                  })}
                >
                  <Row gutter={[24, 0]}>
                    <Col>
                      {player.lastWithdrawAmount !== 0 ? (
                        <MoneyFormatter cents={player.lastWithdrawAmount} />
                      ) : (
                        "—"
                      )}
                    </Col>
                    <Col>
                      <ClockCircleOutlined />{" "}
                      {player.lastWithdrawDate !== null
                        ? moment(new Date(player.lastWithdrawDate)).format(
                            "DD.MM.YYYY HH:mm",
                          )
                        : "—"}
                    </Col>
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card
              title={intl.formatMessage({ defaultMessage: "Player Reports" })}
            >
              <Row justify="space-between">
                <Col>
                  <Row>
                    <CoinsIcon style={iconStyle} />
                    <Link to={{ pathname: "/reports/financial", search }}>
                      {intl.formatMessage({ defaultMessage: "Financial" })}
                    </Link>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <SoccerIcon style={iconStyle} />
                    <Link to={{ pathname: "/reports/sport", search }}>
                      {intl.formatMessage({
                        defaultMessage: "Sport bets history",
                      })}
                    </Link>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <ClockIcon style={iconStyle} />
                    <Link to={{ pathname: "/reports/history", search }}>
                      {intl.formatMessage({ defaultMessage: "History" })}
                    </Link>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <LoyaltyCard style={iconStyle} />
                    <Link to={{ pathname: "/reports/bonuses", search }}>
                      {intl.formatMessage({ defaultMessage: "Bonuses" })}
                    </Link>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Space>
        </Col>
      </Row>
    </BasePage>
  );
}

export const Page = observer(InstantMessagesDetails);
