import { makeAutoObservable } from "mobx";

import { ClientAPI, TClient } from "@/api";
import Query from "@/models/query";

export class ClientStore {
  constructor(private _client: TClient) {
    makeAutoObservable(this);
  }

  get client() {
    return this._client;
  }

  public get isSweepstakesEnabled() {
    return this._client.clientMode !== "normal";
  }

  private setClient = (client: TClient) => {
    this._client = client;
  };

  fetchQuery = new Query(ClientAPI.getById);
  updateQuery = new Query(ClientAPI.update);

  fetch = async () => {
    await this.fetchQuery.submit({
      id: this.client.id,
    });

    if (!this.fetchQuery.isFulfilled) {
      return;
    }

    const client = this.fetchQuery.data;
    this.setClient(client);
  };
}
