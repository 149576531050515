import {
  DownloadOutlined,
  EditOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Row,
  Col,
  Button,
  Space,
  Table,
  TablePaginationConfig,
  Input,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { FC, Fragment, useCallback, useMemo } from "react";

import { TGamesOrderItem } from "@/api";
import { EditGameOrder } from "@/events";
import { useExportHandler, useImportHandler } from "@/hooks";
import Query from "@/models/query";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { GamesOrderState } from "./GamesOrderState";
import Edit from "./edit";

type Props = {
  state: GamesOrderState;
};

export const GamesOrderView: FC<Props> = observer(({ state }) => {
  const getAllQuery = useMemo(
    () =>
      new Query<void, void>(async () => {
        await state.filter();
      }),
    [state],
  );

  const importQuery = useMemo(
    () =>
      new Query<{ data: string; format: "csv" }, void>(async ({ data }) => {
        await state.import(data);
      }),
    [state],
  );

  const exportQuery = useMemo(
    () =>
      new Query<{ format: "csv" }, { data: string }>(async () => {
        return await state.export();
      }),
    [state],
  );

  const handleImportClick = useImportHandler({
    getAllQuery,
    importQuery,
  });

  const handleExportClick = useExportHandler({
    exportQuery,
    fileName: "games-order.csv",
  });

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<TGamesOrderItem> | SorterResult<TGamesOrderItem>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        [
          "gameId",
          "gameStringId",
          "gameName",
          "gameOrder",
          "defaultGameOrder",
          "realGameOrder",
        ].includes(sorter.columnKey)
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
        state.filter();
      }
    },
    [state],
  );

  return (
    <Page title={"Games order"}>
      <Edit.Modal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {state.permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={"Select client"}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {state.permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={"Select an agent"}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.providerSelectorStore}
                  placeholder={"Select provider"}
                />
              </Col>
            </>
          )}
          <Col xs={24} md={8} lg={6} xl={4} style={{ marginLeft: "auto" }}>
            <Input
              placeholder={"Enter ID or name"}
              allowClear
              defaultValue={state.filterQuery.searchQuery}
              onChange={(event) =>
                state.filterQuery.setSearchQuery(event.target.value)
              }
              suffix={<SearchOutlined />}
            />
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!!state.clientSelectorStore.selectedId && (
            <Fragment>
              <Col>
                <Button
                  icon={<DownloadOutlined />}
                  loading={importQuery.isPending}
                  onClick={handleImportClick}
                >
                  {"Import"}
                </Button>
              </Col>
              <Col>
                <Button
                  icon={<UploadOutlined />}
                  loading={exportQuery.isPending}
                  onClick={handleExportClick}
                >
                  {"Export"}
                </Button>
              </Col>
            </Fragment>
          )}
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              onClick={() => state.resetTask.submit()}
              loading={state.resetTask.isPending}
              disabled={!state.agentSelectorStore.selectedId}
            >
              {"Reset"}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={3}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.agentSelectorStore.selectedId}
              onClick={() => state.filter()}
            >
              {"Apply"}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Space direction="vertical">
          <ErrorsFormatter queries={[state.filterQuery]} />
          <Table
            dataSource={state.items}
            showHeader={!!state.filterQuery.items.length}
            rowKey={(item) => item.gameId}
            size="small"
            bordered
            pagination={false}
            loading={state.filterQuery.isPending}
            onChange={handleTableChange}
          >
            <Table.Column
              title={"ID"}
              dataIndex="gameId"
              key="gameId"
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              title={"SID"}
              dataIndex="gameStringId"
              key="gameStringId"
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              title={"Name"}
              dataIndex="gameName"
              key="gameName"
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
              width="100%"
            />
            <Table.Column
              title={"Default order"}
              align="right"
              dataIndex="defaultGameOrder"
              key="defaultGameOrder"
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              key="gameOrder"
              dataIndex="gameOrder"
              title={"Order"}
              align="right"
              render={(value) => value ?? "—"}
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              key="realGameOrder"
              dataIndex="realGameOrder"
              title={"Real order"}
              align="right"
              render={(value) => value ?? "—"}
              sorter={true}
              defaultSortOrder={
                state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
              }
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              title={"Actions"}
              align="right"
              render={(_, item: TGamesOrderItem) => (
                <Row wrap={false} gutter={[8, 8]} justify="end">
                  <Col>
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      title={"Edit"}
                      onClick={() => {
                        state.eventBusService.publish(
                          new EditGameOrder({
                            agentId: state.agentSelectorStore.selectedId!,
                            gameId: item.gameId,
                            gameOrder: item.gameOrder,
                          }),
                        );
                      }}
                    />
                  </Col>
                </Row>
              )}
            />
          </Table>
          <Pagination
            query={state.filterQuery}
            onChange={() => {
              state.filter({ preservePageNumber: true });
            }}
          />
        </Space>
      </Card>
    </Page>
  );
});
