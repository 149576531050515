import { Location, Action } from "history";
import { makeAutoObservable } from "mobx";

import { history } from "./history";

type IRouterService = {
  action: Action;
  location: Location;
  searchParams: Record<string, string | undefined>;
};

export class RouterService implements IRouterService {
  constructor() {
    makeAutoObservable(this);

    history.listen(({ action, location }) => {
      this.action = action;
      this.location = location;
    });
  }

  private _action: Action = history.action;

  get action() {
    return this._action;
  }

  private set action(value: Action) {
    this._action = value;
  }

  private _location: Location = history.location;

  get location() {
    return this._location;
  }

  private set location(value: Location) {
    this._location = value;
  }

  get searchParams() {
    return Object.fromEntries(
      new URLSearchParams(this.location.search).entries(),
    );
  }

  push = history.push;

  replace = history.replace;

  back = history.back;

  forward = history.forward;

  go = history.go;
}
