import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { KnowledgeBaseAPI, TAccessControlList } from "@/api";
import { ArticleUpdated } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService } from "@/services";
import { UserStore } from "@/stores";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = async ({ articleId }: Props) => {
    await Promise.all([
      this.articleQuery.submit({ id: articleId }),
      this.categoriesQuery.submit(),
    ]);
  };

  articleQuery = new Query(KnowledgeBaseAPI.getArticle);

  get article() {
    return this.articleQuery.data!;
  }

  get acl() {
    const acl: TAccessControlList = [
      { role: "Admin", permissions: [] },
      { role: "Manager", permissions: [] },
      { role: "ClientAdmin", permissions: [] },
      { role: "AgentAdmin", permissions: [] },
      { role: "HallAdmin", permissions: [] },
      { role: "Cashier", permissions: [] },
    ];
    this.article.accessControlList.forEach((entry) => {
      if (entry.permissions.includes("Read")) {
        acl.find((e) => e.role === entry.role)?.permissions.push("Read");
      }
    });
    return acl;
  }

  categoriesQuery = new Query(KnowledgeBaseAPI.getCategories);

  get categories() {
    return this.categoriesQuery.data!.data;
  }

  updateQuery = new Query(KnowledgeBaseAPI.updateArticle);

  handleSubmit = async (values: ISchema) => {
    await this.updateQuery.submit({
      id: this.article.id,
      ...values,
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The article has been successfully updated.",
      }),
    });

    const event = new ArticleUpdated({ articleId: this.article.id });
    this.eventBusService.publish(event);
  };
}
