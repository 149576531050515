import yup from "@/yup";

export function useSchema() {
  return yup.object({
    isContactsShown: yup.boolean(),
    contacts: yup.string().nullable(),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;
