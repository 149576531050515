import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { CashierAPI, HallAPI } from "@/api";
import { CashierLimitEdited } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { TCashierUpdateLimitSchema } from "@/schemas";
import { EventBusService, IntlService } from "@/services";
import { CurrencyUtilities } from "@/utilities";

import { Props } from "./form";

export class FormViewModel implements ViewModel<Props> {
  constructor(
    public readonly eventBusService: EventBusService,
    public readonly intlService: IntlService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async onViewMount({ cashierId: id }: Props) {
    await this.getQuery.submit({ id });

    if (this.getQuery.isFulfilled) {
      await this.getHallQuery.submit({
        id: this.getQuery.data.hallId,
      });
    }
  }

  getQuery = new Query(CashierAPI.getById);
  updateQuery = new Query(CashierAPI.update);
  resetQuery = new Query(CashierAPI.resetTransactionsSum);

  getHallQuery = new Query(HallAPI.getById);

  get cashier() {
    return this.getQuery.data;
  }

  get hall() {
    return this.getHallQuery.data;
  }

  async handleReset() {
    await this.resetQuery.submit({ id: this.cashier!.id });
    await this.getQuery.submit({ id: this.cashier!.id });

    if (!this.resetQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage:
          "The cashier's transactions sum has been successfully reset.",
      }),
    });

    this.eventBusService.publish(
      new CashierLimitEdited({ cashierId: this.cashier!.id }),
    );
  }

  async handleSubmit(values: TCashierUpdateLimitSchema) {
    await this.updateQuery.submit({
      id: this.cashier!.id,
      transactionsLimit: CurrencyUtilities.toMinorUnits(
        values.transactionsLimit,
      ),
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage:
          "The cashier's transactions limit has been successfully updated.",
      }),
    });

    this.eventBusService.publish(
      new CashierLimitEdited({ cashierId: this.cashier!.id }),
    );
  }
}
