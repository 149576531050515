import { isValid, endOfDay, startOfDay } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";

import { AgentAPI, ClientAPI, HallAPI, SettlementsReportAPI } from "@/api";
import { SearchParamKey } from "@/constants";
import { ViewModel } from "@/hooks/use-view-model";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import { GlobalStore, SelectorStore } from "@/stores";
import { parseInteger } from "@/utilities";

export class SettlementsReportState implements ViewModel<unknown> {
  constructor(private globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = () => {
    this.useInitialSearchParams();
    this.updateSearchParams();
    this.updateSelectorParameters();
    this.apply();
  };

  get userStore() {
    return this.globalStore.userStore;
  }
  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  private handleClientSelect = () => {
    this.payerAgentSelectorStore.setSelectedId();
    this.agentSelectorStore.setSelectedId();
    this.hallSelectorStore.setSelectedId();
    this.recipientAgentSelectorStore.setSelectedId();
    this.recipientHallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleAgentSelect = () => {
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleHallSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handlePayerAgentSelect = () => {
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handlePayerSubagentSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleRecipientAgentSelect = () => {
    this.recipientHallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleRecipientSubagentSelect = () => {
    this.recipientHallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleRecipientHallSelect = () => {
    this.recipientAgentSelectorStore.setSelectedId();
    this.recipientSubagentSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  public readonly clientSelectorStore = new SelectorStore({
    filterMethod: ClientAPI.filter,
    getByIdMethod: ClientAPI.getById,
    labelKey: "name",
    onSelect: this.handleClientSelect,
  });

  public readonly agentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleAgentSelect,
  });

  public readonly hallSelectorStore = new SelectorStore({
    filterMethod: HallAPI.filter,
    getByIdMethod: HallAPI.getById,
    labelKey: "name",
    onSelect: this.handleHallSelect,
  });

  public readonly payerAgentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handlePayerAgentSelect,
  });

  public readonly payerSubagentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handlePayerSubagentSelect,
  });

  public readonly recipientAgentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleRecipientAgentSelect,
  });

  public readonly recipientSubagentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleRecipientSubagentSelect,
  });

  public readonly recipientHallSelectorStore = new SelectorStore({
    filterMethod: HallAPI.filter,
    getByIdMethod: HallAPI.getById,
    labelKey: "name",
    onSelect: this.handleRecipientHallSelect,
  });

  private _dateTimeRange: [Date, Date] = [
    startOfDay(new Date()),
    endOfDay(new Date()),
  ];

  get dateTimeRange() {
    return this._dateTimeRange;
  }

  setDateTimeRange = (dateTimeRange: [Date, Date]) => {
    this._dateTimeRange = dateTimeRange;

    this.updateSearchParams();
  };

  filterQuery = new ItemsQuery(SettlementsReportAPI.filter, {
    isSearchEnabled: false,
    isOrderEnabled: false,
    isPaginationEnabled: true,
  });

  filter = async (options?: TSubmitOptions) => {
    if (!this.clientSelectorStore.selectedId) {
      return;
    }

    let inputEntityQuery:
      | { clientId: number }
      | { agentId: number }
      | { hallId: number } = {
      clientId: this.clientSelectorStore.selectedId,
    };

    if (this.agentSelectorStore.selectedId) {
      inputEntityQuery = { agentId: this.agentSelectorStore.selectedId };
    }

    if (this.hallSelectorStore.selectedId) {
      inputEntityQuery = { hallId: this.hallSelectorStore.selectedId };
    }

    let payer: { agentId: number } | undefined = undefined;

    if (this.payerSubagentSelectorStore.selectedId) {
      payer = { agentId: this.payerSubagentSelectorStore.selectedId };
    } else if (this.payerAgentSelectorStore.selectedId) {
      payer = { agentId: this.payerAgentSelectorStore.selectedId };
    }

    let recipient: { agentId: number } | { hallId: number } | undefined =
      undefined;
    if (this.recipientSubagentSelectorStore.selectedId) {
      recipient = { agentId: this.recipientSubagentSelectorStore.selectedId };
    } else if (this.recipientAgentSelectorStore.selectedId) {
      recipient = { agentId: this.recipientAgentSelectorStore.selectedId };
    } else if (this.recipientHallSelectorStore.selectedId) {
      recipient = { hallId: this.recipientHallSelectorStore.selectedId };
    }

    await this.filterQuery.submit(
      {
        ...inputEntityQuery,
        payer,
        recipient,
        dateTimeRange: {
          from: this.dateTimeRange[0].toISOString(),
          to: this.dateTimeRange[1].toISOString(),
        },
      },
      options,
    );
  };

  apply = async () => {
    this.filterQuery.setPageNumber(1);

    await this.filter();
  };

  private useInitialSearchParams = () => {
    const { client, agent } = this.userStore;

    if (client) {
      this.clientSelectorStore.setSelectedId(client.id, false);
    } else if (agent) {
      this.clientSelectorStore.setSelectedId(agent.clientId, false);
      this.agentSelectorStore.setSelectedId(agent.id, false);
    }

    const { searchParams } = this.routerService;
    const initialClientId = parseInteger(searchParams[SearchParamKey.ClientId]);
    const initialAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.AgentId])
        : undefined;
    const initialPayerAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.PayerAgentId])
        : undefined;
    const initialHallId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.HallId])
        : undefined;

    const initialRecipientAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.RecipientAgentId])
        : undefined;
    const initialRecipientHallId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.RecipientHallId])
        : undefined;

    runInAction(() => {
      const df = new Date(searchParams[SearchParamKey.DateFrom]);
      const dt = new Date(searchParams[SearchParamKey.DateTo]);
      if (isValid(df) && isValid(dt) && df < dt) {
        this._dateTimeRange = [df, dt];
      }
    });

    if (this.permissionsStore.has("SelectClient")) {
      this.clientSelectorStore.setSelectedId(initialClientId);
    }
    if (this.clientSelectorStore.selectedId) {
      if (this.permissionsStore.has("SelectAgent")) {
        this.payerAgentSelectorStore.setSelectedId(initialPayerAgentId);
        this.recipientAgentSelectorStore.setSelectedId(initialRecipientAgentId);
        this.agentSelectorStore.setSelectedId(initialAgentId);
      }

      if (this.permissionsStore.has("SelectSubagent")) {
        this.payerSubagentSelectorStore.setSelectedId(initialPayerAgentId);
        this.recipientSubagentSelectorStore.setSelectedId(
          initialRecipientAgentId,
        );
      }
      if (this.permissionsStore.has("SelectHall")) {
        this.recipientHallSelectorStore.setSelectedId(initialRecipientHallId);
        this.hallSelectorStore.setSelectedId(initialHallId);
      }
    }
  };

  private updateSearchParams() {
    const urlSearchParams = new URLSearchParams(
      this.routerService.location.search,
    );

    if (this.clientSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.ClientId,
        this.clientSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ClientId);
    }

    if (this.agentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.agentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.AgentId);
    }

    if (this.hallSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.HallId,
        this.hallSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.HallId);
    }

    if (this.payerSubagentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.PayerAgentId,
        this.payerSubagentSelectorStore.selectedId.toString(),
      );
    } else if (this.payerAgentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.PayerAgentId,
        this.payerAgentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.PayerAgentId);
    }

    if (this.recipientHallSelectorStore.selectedId) {
      urlSearchParams.delete(SearchParamKey.RecipientAgentId);
      urlSearchParams.set(
        SearchParamKey.RecipientHallId,
        this.recipientHallSelectorStore.selectedId.toString(),
      );
    } else if (this.recipientSubagentSelectorStore.selectedId) {
      urlSearchParams.delete(SearchParamKey.RecipientHallId);
      urlSearchParams.set(
        SearchParamKey.RecipientAgentId,
        this.recipientSubagentSelectorStore.selectedId.toString(),
      );
    } else if (this.recipientAgentSelectorStore.selectedId) {
      urlSearchParams.delete(SearchParamKey.RecipientHallId);
      urlSearchParams.set(
        SearchParamKey.RecipientAgentId,
        this.recipientAgentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.RecipientHallId);
      urlSearchParams.delete(SearchParamKey.RecipientAgentId);
    }

    urlSearchParams.set("df", this.dateTimeRange[0].toISOString());
    urlSearchParams.set("dt", this.dateTimeRange[1].toISOString());

    this.routerService.replace({ search: urlSearchParams.toString() });
  }

  private updateSelectorParameters = () => {
    const oldClientParameters = this.clientSelectorStore.parameters;
    const oldAgentParameters = this.agentSelectorStore.parameters;
    const oldHallParameters = this.hallSelectorStore.parameters;

    const oldPayerAgentParameters = this.payerAgentSelectorStore.parameters;
    const oldPayerSubagentParameters =
      this.payerSubagentSelectorStore.parameters;
    const oldRecipientAgentParameters =
      this.recipientAgentSelectorStore.parameters;
    const oldRecipientSubagentParameters =
      this.recipientSubagentSelectorStore.parameters;
    const oldRecipientHallParameters =
      this.recipientHallSelectorStore.parameters;

    let newClientParameters = oldClientParameters;
    let newAgentParameters = oldAgentParameters;
    let newHallParameters = oldHallParameters;

    let newPayerAgentParameters = oldPayerAgentParameters;
    let newPayerSubagentParameters = oldPayerSubagentParameters;
    let newRecipientAgentParameters = oldRecipientAgentParameters;
    let newRecipientSubagentParameters = oldRecipientSubagentParameters;
    let newRecipientHallParameters = oldRecipientHallParameters;
    const clientId = this.clientSelectorStore.selectedId;
    const agentId = this.agentSelectorStore.selectedId;

    const { user, agent } = this.userStore;

    newClientParameters = {
      managerId: user.role === "Manager" ? user.id : undefined,
    };

    if (agentId) {
      newHallParameters = { agentId };
    }

    if (agent) {
      newPayerSubagentParameters = { parentAgentId: agent.id };
      newRecipientSubagentParameters = { parentAgentId: agent.id };
      newRecipientHallParameters = { agentId: agent.id };
    } else if (clientId) {
      newPayerAgentParameters = { clientId };
      newRecipientAgentParameters = { clientId };
      newRecipientHallParameters = { clientId };
      newAgentParameters = { clientId };
      newHallParameters = { clientId };
    }

    if (
      this.permissionsStore.has("SelectClient") &&
      JSON.stringify(oldClientParameters) !==
        JSON.stringify(newClientParameters)
    ) {
      this.clientSelectorStore.setParameters(newClientParameters);
      this.clientSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldAgentParameters) !== JSON.stringify(newAgentParameters)
    ) {
      this.agentSelectorStore.setParameters(newAgentParameters);
      this.agentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectHall") &&
      JSON.stringify(oldHallParameters) !== JSON.stringify(newHallParameters)
    ) {
      this.hallSelectorStore.setParameters(newHallParameters);
      this.hallSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectHall") &&
      JSON.stringify(oldRecipientHallParameters) !==
        JSON.stringify(newRecipientHallParameters)
    ) {
      this.recipientHallSelectorStore.setParameters(newRecipientHallParameters);
      this.recipientHallSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldPayerAgentParameters) !==
        JSON.stringify(newPayerAgentParameters)
    ) {
      this.payerAgentSelectorStore.setParameters(newPayerAgentParameters);
      this.payerAgentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectSubagent") &&
      JSON.stringify(oldPayerSubagentParameters) !==
        JSON.stringify(newPayerSubagentParameters)
    ) {
      this.payerSubagentSelectorStore.setParameters(newPayerSubagentParameters);
      this.payerSubagentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldRecipientAgentParameters) !==
        JSON.stringify(newRecipientAgentParameters)
    ) {
      this.recipientAgentSelectorStore.setParameters(
        newRecipientAgentParameters,
      );
      this.recipientAgentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectSubagent") &&
      JSON.stringify(oldRecipientSubagentParameters) !==
        JSON.stringify(newRecipientSubagentParameters)
    ) {
      this.recipientSubagentSelectorStore.setParameters(
        newRecipientSubagentParameters,
      );
      this.recipientSubagentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectHall") &&
      JSON.stringify(oldRecipientHallParameters) !==
        JSON.stringify(newRecipientHallParameters)
    ) {
      this.recipientHallSelectorStore.setParameters(newRecipientHallParameters);
      this.recipientHallSelectorStore.fetchItems();
    }
  };
}
