import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { VoucherRedeemStore } from "@/stores/vouchers";

import VoucherRedeemForm from "./voucher-redeem-form";

type TProps = {
  voucherRedeemStore: VoucherRedeemStore;
};

function VoucherRedeemModal({ voucherRedeemStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Redeem voucher" })}
      open={voucherRedeemStore.isModalVisible}
      onCancel={voucherRedeemStore.closeModal}
      footer={null}
      destroyOnClose={true}
    >
      <VoucherRedeemForm voucherRedeemStore={voucherRedeemStore} />
    </Modal>
  );
}

export default observer(VoucherRedeemModal);
