import { makeAutoObservable, runInAction } from "mobx";

import { ConversationAPI, PlayerAPI, TMessage } from "@/api";
import { SearchParamKey } from "@/constants";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { GlobalStore } from "@/stores";

type Props = {
  conversationId: number;
};

const FETCH_MESSAGES_INTERVAL = 15_000;

export class InstantMessagesDetailsState implements ViewModel<Props> {
  public readonly messagesQuery = new Query(ConversationAPI.getMessages);
  public readonly nextMessageSequenceQuery = new Query(
    ConversationAPI.getMessages,
  );

  public readonly conversationQuery = new Query(ConversationAPI.getById);
  public readonly playerQuery = new Query(PlayerAPI.getInfo);
  public readonly sendMessageQuery = new Query(ConversationAPI.sendMessage);
  public readonly conversationCloseQuery = new Query(ConversationAPI.close);
  public readonly deleteMessageQuery = new Query(ConversationAPI.deleteMessage);

  public isNextMessageSequenceLoading = false;

  private _intervalId: number | undefined;
  private _message = "";
  private _messages: TMessage[] = [];
  private _limit = 20;
  private _startSeqNum = 0;

  constructor(private globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onViewMount = async ({ conversationId }: Props) => {
    if (!conversationId || isNaN(conversationId)) {
      this.routerService.push("/instant-messages");
    }

    this._intervalId = window.setInterval(
      () => this.fetchMessages(conversationId),
      FETCH_MESSAGES_INTERVAL,
    );

    await this.fetchMessages(conversationId);
    await this.conversationQuery.submit({ conversationId });

    if (this.conversationQuery?.data?.playerId) {
      await this.playerQuery.submit({
        id: Number(this.conversationQuery.data.playerId),
      });
    }
  };

  onViewUnmount() {
    if (this._intervalId !== undefined) {
      window.clearInterval(this._intervalId);
    }

    this.messagesQuery.reset();
  }

  private filterNewMessages(messages: TMessage[]): TMessage[] {
    return messages.filter(
      (newMessage) =>
        !this._messages.some(
          (existingMessage) =>
            existingMessage.messageId === newMessage.messageId,
        ),
    );
  }

  async closeConversation() {
    if (!this.conversationQuery.data?.id) {
      return;
    }

    await this.conversationCloseQuery.submit({
      conversationId: this.conversationQuery.data.id,
    });

    this.routerService.push({
      pathname: "/instant-messages",
      search: this.searchParams,
    });
  }

  async deleteMessage(messageId: number) {
    await this.deleteMessageQuery.submit({ messageId });

    if (!this.deleteMessageQuery.error) {
      runInAction(() => {
        this._messages = this._messages.map((message) =>
          message.messageId === messageId
            ? { ...message, deletedAt: new Date().toLocaleString() }
            : message,
        );
      });
    }
  }

  async sendMessage() {
    if (
      !this.message ||
      !this.conversationQuery.data?.hallId ||
      !this.conversationQuery.data?.playerId
    ) {
      return;
    }

    await this.sendMessageQuery.submit({
      hallId: Number(this.conversationQuery.data.hallId),
      toPlayerId: Number(this.conversationQuery.data.playerId),
      conversationId: this.conversationQuery.data.id,
      text: this.message,
    });

    this.message = "";

    await this.messagesQuery.submit({
      startSeqNum: this._startSeqNum,
      conversationId: this.conversationQuery.data.id,
      limit: this._limit,
    });

    runInAction(() => {
      if (this.messagesQuery.data?.length) {
        const newMessages = this.filterNewMessages(this.messagesQuery.data);
        this._messages = [...newMessages, ...this._messages];
      }
    });
  }

  async nextMessageSequence() {
    runInAction(() => {
      this.isNextMessageSequenceLoading = true;
    });

    const nextSeqNum =
      this.nextMessageSequenceQuery.data?.at(-1)?.seqNum ??
      this.messagesQuery.data?.at(-1)?.seqNum;

    if (!this.conversationQuery.data?.id) {
      return;
    }

    if (nextSeqNum) {
      await this.nextMessageSequenceQuery.submit({
        startSeqNum: -nextSeqNum,
        conversationId: this.conversationQuery.data.id,
        limit: this._limit,
      });
    }

    runInAction(() => {
      if (this.nextMessageSequenceQuery.data?.length) {
        const newMessages = this.filterNewMessages(
          this.nextMessageSequenceQuery.data,
        );
        this._messages = [...this._messages, ...newMessages];
      }

      this.isNextMessageSequenceLoading = false;
    });
  }

  async fetchMessages(conversationId: number) {
    if (!conversationId || this.isNextMessageSequenceLoading) {
      return;
    }

    await this.messagesQuery.submit({
      startSeqNum: this._startSeqNum,
      conversationId,
      limit: this._limit,
    });

    runInAction(() => {
      if (this.messagesQuery.data?.length) {
        const newMessages = this.filterNewMessages(this.messagesQuery.data);
        this._messages = [...newMessages, ...this._messages];
      }
    });
  }

  set message(value: string) {
    this._message = value;
  }

  get message() {
    return this._message;
  }

  get messages() {
    return this._messages;
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get searchParams() {
    return new URLSearchParams({
      [SearchParamKey.ClientId]:
        this.conversationQuery.data?.clientId?.toString() || "",
      [SearchParamKey.PlayerId]:
        this.conversationQuery.data?.playerId?.toString() || "",
    }).toString();
  }
}
