import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tabs,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import { TGamesReportAggregatedItem, TGamesReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { GamesReportState } from "./GamesReportState";

function GamesReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new GamesReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;

  const handleDetailsTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<TGamesReportItem> | SorterResult<TGamesReportItem>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        ["gameName", "rtp", "profit", "actionsCount"].includes(sorter.columnKey)
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
        state.filter();
      }
    },
    [state],
  );

  const handleProvidersTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter:
        | SorterResult<TGamesReportAggregatedItem>
        | SorterResult<TGamesReportAggregatedItem>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        ["rtp", "profit", "actionsCount"].includes(sorter.columnKey)
      ) {
        state.aggregateByProviderQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
        state.aggregateByProvider();
      }
    },
    [state],
  );

  const handleGamesTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter:
        | SorterResult<TGamesReportAggregatedItem>
        | SorterResult<TGamesReportAggregatedItem>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        ["rtp", "profit", "actionsCount", "gameName"].includes(sorter.columnKey)
      ) {
        state.aggregateByGameQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
        state.aggregateByGame();
      }
    },
    [state],
  );

  return (
    <Page title={intl.formatMessage({ defaultMessage: "Game report" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.kioskSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select kiosk",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={state.providerSelectorStore}
              placeholder={intl.formatMessage({
                defaultMessage: "Select provider",
              })}
            />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={state.gameSelectorStore}
              placeholder={intl.formatMessage({
                defaultMessage: "Select game",
              })}
            />
          </Col>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin
          spinning={
            state.filterQuery.isPending ||
            state.aggregateByProviderQuery.isPending ||
            state.aggregateByGameQuery.isPending
          }
        >
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                state.filterQuery,
                state.aggregateByProviderQuery,
                state.aggregateByGameQuery,
              ]}
            />
            <Tabs
              activeKey={state.tab}
              onChange={(value) => (state.tab = value)}
              destroyInactiveTabPane={true}
            >
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Detailed" })}
                key="detailed"
              >
                <Table
                  dataSource={state.filterQuery.items}
                  showHeader={!!state.filterQuery.items.length}
                  rowKey={(item) => state.filterQuery.items.indexOf(item)}
                  size="small"
                  bordered
                  pagination={false}
                  onChange={handleDetailsTableChange}
                >
                  {state.filterQuery.items.length > 0 && (
                    <>
                      {"clientName" in state.filterQuery.items[0] && (
                        <Table.Column
                          title={intl.formatMessage({
                            defaultMessage: "Client",
                          })}
                          dataIndex="clientName"
                        />
                      )}
                      {"agentName" in state.filterQuery.items[0] && (
                        <Table.Column
                          title={intl.formatMessage({
                            defaultMessage: "Agent",
                          })}
                          dataIndex="agentName"
                        />
                      )}
                      {"hallName" in state.filterQuery.items[0] && (
                        <Table.Column
                          title={intl.formatMessage({ defaultMessage: "Shop" })}
                          dataIndex="hallName"
                        />
                      )}
                      {"playerName" in state.filterQuery.items[0] && (
                        <Table.Column
                          title={intl.formatMessage({
                            defaultMessage: "Player",
                          })}
                          dataIndex="playerName"
                        />
                      )}
                      {"kioskName" in state.filterQuery.items[0] && (
                        <Table.Column
                          title={intl.formatMessage({
                            defaultMessage: "Kiosk",
                          })}
                          dataIndex="kioskName"
                        />
                      )}
                    </>
                  )}
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Provider" })}
                    dataIndex="providerName"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Game" })}
                    dataIndex="gameName"
                    key="gameName"
                    defaultSortOrder={
                      state.filterQuery.order[1] === "asc"
                        ? "ascend"
                        : "descend"
                    }
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bet" })}
                    dataIndex="bet"
                    render={(bet) => <MoneyFormatter cents={bet} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Win" })}
                    dataIndex="win"
                    render={(win) => <MoneyFormatter cents={win} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Profit" })}
                    dataIndex="profit"
                    render={(profit) => <MoneyFormatter cents={profit} />}
                    key="profit"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "RTP" })}
                    dataIndex="rtp"
                    render={(rtp: number) => rtp.toFixed(4)}
                    key="rtp"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Rounds" })}
                    dataIndex="roundsCount"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Actions" })}
                    dataIndex="actionsCount"
                    key="actionsCount"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.filterQuery}
                  onChange={() => {
                    state.filter({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Providers" })}
                key="providers"
              >
                <Table
                  dataSource={state.aggregateByProviderQuery.items}
                  showHeader={!!state.aggregateByProviderQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByProviderQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                  onChange={handleProvidersTableChange}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Provider" })}
                    dataIndex="providerName"
                    key="providerName"
                    defaultSortOrder={
                      state.aggregateByProviderQuery.order[1] === "asc"
                        ? "ascend"
                        : "descend"
                    }
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bet" })}
                    dataIndex="bet"
                    render={(bet: number) => <MoneyFormatter cents={bet} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Win" })}
                    dataIndex="win"
                    render={(win: number) => <MoneyFormatter cents={win} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Profit" })}
                    dataIndex="profit"
                    render={(profit: number) => (
                      <MoneyFormatter cents={profit} />
                    )}
                    key="profit"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "RTP" })}
                    dataIndex="rtp"
                    render={(rtp: number) => rtp.toFixed(4)}
                    key="rtp"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Rounds" })}
                    dataIndex="roundsCount"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Actions" })}
                    dataIndex="actionsCount"
                    key="actionsCount"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByProviderQuery}
                  onChange={() => {
                    state.aggregateByProvider({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Games" })}
                key="games"
              >
                <Table
                  dataSource={state.aggregateByGameQuery.items}
                  showHeader={!!state.aggregateByGameQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByGameQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                  onChange={handleGamesTableChange}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Game" })}
                    dataIndex="gameName"
                    key="gameName"
                    defaultSortOrder={
                      state.aggregateByGameQuery.order[1] === "asc"
                        ? "ascend"
                        : "descend"
                    }
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bet" })}
                    dataIndex="bet"
                    render={(bet: number) => <MoneyFormatter cents={bet} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Win" })}
                    dataIndex="win"
                    render={(win: number) => <MoneyFormatter cents={win} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Profit" })}
                    dataIndex="profit"
                    render={(profit: number) => (
                      <MoneyFormatter cents={profit} />
                    )}
                    key="profit"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "RTP" })}
                    dataIndex="rtp"
                    render={(rtp: number) => rtp.toFixed(4)}
                    key="rtp"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Rounds" })}
                    dataIndex="roundsCount"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Actions" })}
                    dataIndex="actionsCount"
                    key="actionsCount"
                    sorter={true}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByGameQuery}
                  onChange={() => {
                    state.aggregateByGame({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(GamesReport);
