import { DatePicker } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { FC } from "react";

import { useGlobalStore } from "@/stores";

type Props = {
  store: Store;
};

type Store = {
  dateTimeRange: [Date, Date];
  setDateTimeRange: (dateTimeRange: [Date, Date]) => void;
};

export const MonthPicker: FC<Props> = observer(({ store }) => {
  const { intlService } = useGlobalStore();
  return (
    <DatePicker
      picker="month"
      style={{ width: "100%" }}
      clearIcon={false}
      value={moment(store.dateTimeRange[0])}
      format="MMM YYYY"
      locale={intlService.antdLocale["DatePicker"]}
      onChange={(month) => {
        if (!month) {
          return;
        }

        const from = month.clone().startOf("month");
        let to = month.clone().endOf("month");

        const startOfMonth = moment().startOf("month");
        const endOfMonth = moment().endOf("month");

        if (month.isBetween(startOfMonth, endOfMonth)) {
          to = moment().endOf("day");
        }

        store.setDateTimeRange([from.toDate(), to.toDate()]);
      }}
      disabledDate={(date) => {
        const endOfMonth = moment().endOf("month");
        return date.isAfter(endOfMonth);
      }}
    />
  );
});
