import { observer } from "mobx-react-lite";

import { useEditWebsiteTemplateFormState } from "./form.state";
import { EditWebsiteTemplateFormView } from "./form.view";

export type EditWebsiteTemplateFormProps = {
  id: number;
};

export const EditWebsiteTemplateForm = observer<EditWebsiteTemplateFormProps>(
  (props) => {
    const state = useEditWebsiteTemplateFormState(props);
    return <EditWebsiteTemplateFormView state={state} />;
  },
);
