import { useIntl } from "react-intl";

import { TKiosk } from "@/api";

interface IProps {
  kiosk: TKiosk;
}

export function Status({ kiosk }: IProps) {
  const intl = useIntl();
  if (kiosk.isOffline) {
    return (
      <span style={{ color: "red" }}>
        {intl.formatMessage({ defaultMessage: "Offline" })}
      </span>
    );
  }
  if (kiosk.isPanicEnabled) {
    return (
      <span style={{ color: "red" }}>
        {intl.formatMessage({ defaultMessage: "Panic" })}
      </span>
    );
  }
  if (kiosk.isBlocked) {
    return (
      <span style={{ color: "red" }}>
        {intl.formatMessage({ defaultMessage: "Block" })}
      </span>
    );
  }
  if (kiosk.playerLogin) {
    return <span style={{ color: "green" }}>{kiosk.playerLogin}</span>;
  }
  return <>Empty</>;
}
