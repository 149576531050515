import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, PageHeader, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";

import { EditClubModal } from "./edit";
import { NewClubModal } from "./new";
import { SportClubsPageState } from "./page.state";
import { SportClubsTable } from "./table";

type Props = { state: SportClubsPageState };

export const SportClubsPageView = observer<Props>(({ state }) => {
  return (
    <Page title={<PageHeader title="Sport Clubs" onBack={state.goBack} />}>
      <NewClubModal />
      <EditClubModal />
      <Card size="small">
        <Space direction="vertical">
          <Row>
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                placeholder="Select partner"
                store={state.partnerStore}
              />
            </Col>
            {state.partnerId && (
              <Col style={{ marginLeft: "auto" }}>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => state.openNewClubModal()}
                >
                  {"Create"}
                </Button>
              </Col>
            )}
          </Row>
          <SportClubsTable partnerId={state.partnerId} />
        </Space>
      </Card>
    </Page>
  );
});
