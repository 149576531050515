import MethodBuilder from "../../method-builder";
import {
  TJackpotsReportAggregatedItem,
  TJackpotsReportItem,
  TPaging,
} from "../../types";

export class JackpotsReportAPI {
  static filter = new MethodBuilder<
    ExclusifyUnion<
      (
        | { clientId: number }
        | { agentId: number }
        | { hallId: number }
        | { playerId: number }
        | { kioskId: number }
      ) & {
        currency?: string;
        dateTimeRange?: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        slotId?: number;
        paging: TPaging;
      }
    >,
    {
      data: TJackpotsReportItem[];
      total: number;
    }
  >()
    .withName("JackpotsReport.filter")
    .build();

  static aggregate = new MethodBuilder<
    ExclusifyUnion<
      (
        | { clientId: number }
        | { agentId: number }
        | { hallId: number }
        | { playerId: number }
        | { kioskId: number }
      ) & {
        groupBy: "currency" | "player" | "kiosk" | "hall";
        currency?: string;
        dateTimeRange?: {
          // ISO 8601
          from: string;
          // ISO 8601
          to: string;
        };
        paging: TPaging;
      }
    >,
    {
      data: TJackpotsReportAggregatedItem[];
      total: number;
    }
  >()
    .withName("JackpotsReport.aggregate")
    .build();
}
