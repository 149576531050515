import { makeAutoObservable } from "mobx";

import { GameAPI, ProviderAPI, TagAPI, TProvider } from "@/api";
import BrandAPI from "@/api/modules/brand";
import ItemsQuery, { TSubmitOptions } from "@/models/items-query";
import Query from "@/models/query";
import { SelectorStore } from "@/stores";

export class GamesViewModel {
  constructor() {
    makeAutoObservable(this);

    this.providerSelectorStore.setParameters({});
    this.providerSelectorStore.fetchItems();

    this.brandsQuery.submit();

    this.fetch();
  }

  selectedProviderId?: number;

  handleProviderSelect = (provider?: TProvider) => {
    this.selectedProviderId = provider?.id;
    this.selectedBrandId = undefined;
    this.fetch();
  };

  providerSelectorStore = new SelectorStore({
    filterMethod: ProviderAPI.filter,
    getByIdMethod: ProviderAPI.getById,
    labelKey: "name",
    onSelect: this.handleProviderSelect,
    isPaginationEnabled: false,
  });

  brandsQuery = new Query(BrandAPI.getAll);

  selectedBrandId?: number;

  setSelectedBrandId = (brandId?: number) => {
    this.selectedBrandId = brandId;
    this.fetch();
  };

  get filteredBrands() {
    return (
      this.brandsQuery.data?.data?.filter(
        (brand) => brand.providerId === this.selectedProviderId,
      ) ?? []
    );
  }

  gamesQuery = new ItemsQuery(GameAPI.filter, {
    initialOrder: ["id", "asc"],
    isSearchEnabled: true,
    isOrderEnabled: true,
    isPaginationEnabled: true,
  });

  tagQuery = new Query(TagAPI.getAll);

  fetch = async (options?: TSubmitOptions) => {
    const filter = this.selectedBrandId
      ? { brandId: this.selectedBrandId }
      : { providerId: this.selectedProviderId };

    await Promise.all([
      this.tagQuery.submit(),
      this.gamesQuery.submit(filter, options),
    ]);
  };
}
