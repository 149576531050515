import { Space, Form, Row, Button, Col, Checkbox } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../_common_/errors-formatter";
import { FormItem, FormSelect, FormSwitch } from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { ClientLobbySettingsState } from "./client-lobby-settings.state";

interface Props {
  state: ClientLobbySettingsState;
}

export const ClientLobbySettingsView = observer(({ state }: Props) => {
  const {
    intl,
    form,
    clientQuery,
    languagesQuery,
    defaultLanguageOptions,
    languagesOptions,
    handleLanguageCheck,
    submitQuery,
  } = state;

  if (
    clientQuery.isIdle ||
    clientQuery.isPending ||
    languagesQuery.isIdle ||
    languagesQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (clientQuery.isRejected || languagesQuery.isRejected) {
    return <ErrorsFormatter queries={[clientQuery, languagesQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="lobbySettings.isContactsShown"
          label={intl.formatMessage({ defaultMessage: "Show contacts" })}
        >
          <FormSwitch form={form} path="lobbySettings.isContactsShown" />
        </FormItem>
        <FormItem
          form={form}
          path="lobbySettings.languages"
          label={intl.formatMessage({ defaultMessage: "Languages" })}
        >
          {languagesOptions.map((option) => (
            <div key={option.name}>
              <Checkbox
                name={option.name}
                checked={option.value}
                onChange={handleLanguageCheck}
              >
                {option.label}
              </Checkbox>
            </div>
          ))}
        </FormItem>
        <FormItem
          form={form}
          path="lobbySettings.defaultLang"
          label={intl.formatMessage({ defaultMessage: "Default language" })}
        >
          <FormSelect
            form={form}
            path="lobbySettings.defaultLang"
            options={defaultLanguageOptions}
          />
        </FormItem>
        <FormItem
          form={form}
          path="lobbySettings.directRegistration"
          label={intl.formatMessage({
            defaultMessage: "Registration in lobby",
          })}
        >
          <FormSwitch form={form} path="lobbySettings.directRegistration" />
        </FormItem>

        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
