import { Alert, Modal } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { FormSpinner } from "@/ui/_common_/form-spinner";

import PlayerQrCodeForm from "../player-qr-code-form";

import { PlayerTokenModalState } from "./player-token-modal.state";

type Props = {
  state: PlayerTokenModalState;
};

export const PlayerTokenModal: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({
        defaultMessage: "QR code",
      })}
      open={state.isOpen}
      onCancel={state.close}
      width={720}
      footer={null}
      destroyOnClose={true}
    >
      {state.player && state.token ? (
        state.player.domain ? (
          <PlayerQrCodeForm
            date={new Date().toLocaleString()}
            website={state.player.domain}
            login={state.player.login}
            token={state.token}
            balance={
              state.player.balance !== null
                ? ((state.player.balance ?? 0) / 100).toFixed(2)
                : null
            }
            currency={state.player.currency}
            entries={
              state.player.sweepstake !== null
                ? (state.player.sweepstake.entries / 100).toFixed(0)
                : null
            }
            totalWin={
              state.player.sweepstake !== null
                ? (state.player.sweepstake.win / 100).toFixed(2)
                : null
            }
            qrCodeData={`https://${state.player.domain}/AuthByToken?token=${state.token}`}
          />
        ) : (
          <Alert
            type="error"
            message={intl.formatMessage({
              defaultMessage: "The player does not have website.",
            })}
          />
        )
      ) : (
        <FormSpinner />
      )}
    </Modal>
  );
});
