import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Row, Tabs, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormSelect,
  FormSwitch,
} from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { NewCategoryFormState } from "./form.state";

type Props = {
  state: NewCategoryFormState;
};

export const NewCategoryFormView = observer<Props>(({ state }) => {
  const {
    parentId,
    parentCategory,

    queriesState,

    form,
    handleSubmit,
    mutation,

    usedLanguages,
    unusedLanguages,
    addLanguageMenu,
    removeLanguage,
  } = state;

  if (queriesState.isLoading) {
    return <FormSpinner />;
  }

  if (queriesState.isRejected) {
    return queriesState.error;
  }

  return (
    <Form
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={handleSubmit}
    >
      <ErrorsFormatter queries={[mutation]} />
      {parentCategory && (
        <Form.Item label={"Parent category"}>
          <Typography.Text>{parentCategory.names.en}</Typography.Text>
        </Form.Item>
      )}
      <Tabs
        className="tabs--container"
        type="editable-card"
        size="small"
        hideAdd={true}
        items={usedLanguages.map((code) => ({
          key: code,
          label: code.toUpperCase(),
          closable: code !== "en",
          children: (
            <FormItem form={form} path={`names.${code}`} label={"Name"}>
              <FormInput
                form={form}
                path={`names.${code}`}
                placeholder={"Enter name"}
              />
            </FormItem>
          ),
        }))}
        onEdit={(key, action) => {
          if (action === "remove") {
            removeLanguage(key as string);
          }
        }}
        tabBarExtraContent={{
          right: !!unusedLanguages.length && (
            <Dropdown menu={addLanguageMenu} trigger={["click"]}>
              <Button size="small">
                <PlusOutlined />
              </Button>
            </Dropdown>
          ),
        }}
      />
      <FormItem form={form} path="order" label={"Order"}>
        <FormInput form={form} path="order" placeholder={"Enter order"} />
      </FormItem>
      <FormItem form={form} path="isActive" label={"Active"}>
        <FormSwitch form={form} path="isActive" />
      </FormItem>
      {!parentId && (
        <FormItem form={form} path="tag" label={"Tag"}>
          <FormSelect
            form={form}
            path="tag"
            placeholder={"Select tag"}
            options={["header", "menu", "bottom"].map((value) => ({
              value,
              label: value,
            }))}
            allowClear
          />
        </FormItem>
      )}
      <Row justify="end" gutter={12}>
        <Col>
          <Button type="primary" htmlType="submit" loading={mutation.isPending}>
            {"Save"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});
