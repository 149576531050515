import { Link, useLocation } from "react-router-dom";

type Props = {
  level: number;
  title: string;
  link: string;
};

export function AncestorNode({ level, title, link }: Props) {
  const location = useLocation();
  return (
    <div
      className="ant-tree-treenode ant-tree-treenode-switcher-open ant-tree-treenode-leaf-last"
      draggable="false"
      aria-grabbed="false"
    >
      <span aria-hidden="true" className="ant-tree-indent">
        {new Array(!level ? 0 : level - 1).fill(0).map((_, index) => (
          <span
            key={index}
            className="ant-tree-indent-unit ant-tree-indent-unit-start ant-tree-indent-unit-end"
          />
        ))}
      </span>
      {!!level && (
        <span className="ant-tree-switcher ant-tree-switcher-noop">
          <span className="ant-tree-switcher-leaf-line"></span>
        </span>
      )}
      <span className="ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal">
        <Link
          to={link}
          reloadDocument={link.startsWith(location.pathname)}
          className="ant-tree-title"
        >
          {title}
        </Link>
      </span>
    </div>
  );
}
