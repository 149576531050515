import { Button, Col, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../../_common_/errors-formatter";
import { FormInput, FormItem, FormSelect } from "../../_common_/form-helpers";

import { AddKioskAccessFormState } from "./form.state";

interface Props {
  state: AddKioskAccessFormState;
}

export const AddKioskAccessFormView = observer(({ state }: Props) => {
  const { intl, form, handleSubmit, query } = state;
  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[query]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter name...",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="type"
          label={intl.formatMessage({ defaultMessage: "Level" })}
        >
          <FormSelect
            form={form}
            path="type"
            placeholder={intl.formatMessage({
              defaultMessage: "Select level...",
            })}
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Engineer" }),
                value: "engineer",
              },
              {
                label: intl.formatMessage({ defaultMessage: "Stat" }),
                value: "stat",
              },
            ]}
          />
        </FormItem>
        <FormItem
          form={form}
          path="pin"
          label={intl.formatMessage({ defaultMessage: "PIN" })}
        >
          <FormInput
            type="number"
            form={form}
            path="pin"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter pin...",
            })}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button type="primary" htmlType="submit" loading={query.isPending}>
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
