import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { EditFormState } from "./FormState";
import { EditFormView } from "./FormView";

export type Entity =
  | { clientId: number }
  | { agentId: number }
  | { hallId: number }
  | { kioskId: number };

export type Props = {
  entity: Entity;
};

export const EditForm: FC<Props> = observer((props) => {
  const { intlService, permissionsStore } = useGlobalStore();
  const vm = useViewModel(
    () => new EditFormState(intlService, permissionsStore),
    props,
  );
  return <EditFormView state={vm} />;
});
