import { observer } from "mobx-react-lite";

import { useCreateVouchersFormState } from "./create-vouchers-form.state";
import { CreateVouchersFormView } from "./create-vouchers-form.view";

export interface CreateVouchersFormProps {
  hallId: number;
}

export const CreateVouchersForm = observer((props: CreateVouchersFormProps) => {
  const state = useCreateVouchersFormState(props);
  return <CreateVouchersFormView state={state} />;
});
