import DatePicker from "antd/lib/date-picker";
import { observer } from "mobx-react-lite";
import "antd/es/date-picker/style/css";
import moment from "moment";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useGlobalStore } from "@/stores";

type TStore = {
  dateTimeRange: [Date, Date];
  setDateTimeRange: (dateTimeRange: [Date, Date]) => void;
};

type TProps<S extends TStore> = {
  store: S;
};

function DateRangePicker<S extends TStore>({ store }: TProps<S>) {
  const { intlService } = useGlobalStore();
  const intl = useIntl();
  const messages = useMemo(
    () => ({
      today: intl.formatMessage({ defaultMessage: "Today" }),
      yesterday: intl.formatMessage({ defaultMessage: "Yesterday" }),
      thisWeek: intl.formatMessage({ defaultMessage: "This week" }),
      lastWeek: intl.formatMessage({ defaultMessage: "Last week" }),
      thisMonth: intl.formatMessage({ defaultMessage: "This month" }),
      lastMonth: intl.formatMessage({ defaultMessage: "Last month" }),
      lastSixMonths: intl.formatMessage({
        defaultMessage: "Last 6 months",
      }),
    }),
    [intl],
  );

  return (
    <DatePicker.RangePicker
      style={{ width: "100%" }}
      clearIcon={false}
      allowEmpty={[false, false]}
      value={[moment(store.dateTimeRange[0]), moment(store.dateTimeRange[1])]}
      showTime
      ranges={{
        [messages.today]: () => [
          moment().startOf("day"),
          moment().endOf("day"),
        ],
        [messages.yesterday]: () => [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ],
        [messages.thisWeek]: () => [
          moment().startOf("week"),
          moment().endOf("day"),
        ],
        [messages.lastWeek]: () => [
          moment().subtract(1, "weeks").startOf("week"),
          moment().subtract(1, "weeks").endOf("week"),
        ],
        [messages.thisMonth]: () => [
          moment().startOf("month"),
          moment().endOf("day"),
        ],
        [messages.lastMonth]: () => [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ],
        [messages.lastSixMonths]: () => [
          moment().subtract(6, "months").startOf("day"),
          moment().endOf("day"),
        ],
      }}
      onChange={(range) => {
        if (range && range[0] && range[1]) {
          store.setDateTimeRange([range[0].toDate(), range[1].toDate()]);
        }
      }}
      locale={intlService.antdLocale.DatePicker}
    />
  );
}

export default observer(DateRangePicker);
