import { observer } from "mobx-react-lite";

import { CloseEditBannerModal, OpenEditBannerModal } from "@/events";
import { useGlobalStore } from "@/stores";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditBannerForm } from "./edit-form";

export const EditBannerModal = observer(() => {
  const { eventBusService } = useGlobalStore();
  return (
    <EventBasedModal
      openEvent={OpenEditBannerModal}
      closeEvent={CloseEditBannerModal}
      title={"Edit banner"}
      onClose={() => eventBusService.publish(new CloseEditBannerModal({}))}
    >
      {({ id, entity }) => <EditBannerForm id={id} entity={entity} />}
    </EventBasedModal>
  );
});
