import yup from "@/yup";

export function useAgentWithdrawSchema() {
  return yup.object({
    amount: yup.number().money().min(0).required(),
  });
}

export type TAgentWithdrawSchema = yup.InferType<
  ReturnType<typeof useAgentWithdrawSchema>
>;
