import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useMoneyFormatter } from "@/hooks";

type TProps = {
  cents?: number;
  isInfinity?: boolean;
  currency?: string | null;
  fractionDigits?: number;
};

const MoneyFormatter: FC<TProps> = ({
  cents,
  isInfinity = false,
  currency,
  fractionDigits = 2,
}) => {
  const { formatMoney } = useMoneyFormatter();
  if (isInfinity) {
    return <>∞</>;
  }
  if (cents === undefined) {
    return <>—</>;
  }
  return <>{formatMoney(cents, currency ?? undefined, fractionDigits)}</>;
};

export default observer(MoneyFormatter);
