import { makeAutoObservable } from "mobx";

import { HallAPI, PlayerAPI, WebsiteAPI } from "@/api";
import Query from "@/models/query";
import { TPlayerGenerateSchema } from "@/schemas";
import { CurrencyUtilities } from "@/utilities";

type Params = {
  onGenerate: () => void;
  onCancel: () => void;
  hallId: number;
};

export class PlayerGenerateFormState {
  public readonly onGenerate: () => void;
  public readonly onCancel: () => void;
  public readonly hallId: number;

  constructor({ onGenerate, onCancel, hallId }: Params) {
    makeAutoObservable(this);

    this.onGenerate = onGenerate;
    this.onCancel = onCancel;
    this.hallId = hallId;

    this.hallQuery.submit({ id: hallId });
    this.websitesQuery.submit({ hallId });
  }

  public readonly hallQuery = new Query(HallAPI.getById);
  public readonly websitesQuery = new Query(WebsiteAPI.filter);
  public readonly generateQuery = new Query(PlayerAPI.generate);

  public get hall() {
    return this.hallQuery.data;
  }

  public get websites() {
    return this.websitesQuery.data?.data;
  }

  public get website() {
    if (!this.websites || !this.websites.length) {
      return undefined;
    }
    const website = this.websites.find((w) => w.hasPriority);
    if (website) {
      return website;
    }
    return this.websites[0];
  }

  public get player() {
    return this.generateQuery.data;
  }

  handleSubmit = async (values: TPlayerGenerateSchema) => {
    await this.generateQuery.submit({
      hallId: this.hallId,
      initialBalance: CurrencyUtilities.toMinorUnits(values.balance),
    });
    this.onGenerate();
  };
}
