import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { BrandAPI, GameAPI, ProviderAPI, TagAPI } from "@/api";
import { GameUpdated } from "@/events";
import Query from "@/models/query";
import { SelectorStore, useGlobalStore } from "@/stores";

import { FormProps } from "./form";
import { FormSchema, useFormSchema } from "./form.schema";

export function useFormState({ id }: FormProps) {
  const intl = useIntl();

  const schema = useFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<FormSchema>({ resolver });

  const gameQuery = useMemo(() => {
    const query = new Query(GameAPI.getById);
    query.submit({ id }).then(() => {
      const values = schema.cast(query.data, { stripUnknown: true });
      form.reset(values);

      providerSelectorStore.setSelectedId(values.providerId);
      providerSelectorStore.setParameters({});
      providerSelectorStore.fetchItems();

      brandSelectorStore.setSelectedId(values.brandId);
      brandSelectorStore.setParameters({ providerId: values.providerId });
      brandSelectorStore.fetchItems();
    });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerId = form.watch("providerId");
  const tagIds = form.watch("tagIds", []);

  const providerSelectorStore = useMemo(() => {
    return new SelectorStore({
      filterMethod: ProviderAPI.filter,
      getByIdMethod: ProviderAPI.getById,
      labelKey: "name",
      onSelect(provider) {
        if (provider?.id) {
          form.setValue("providerId", provider.id);

          brandSelectorStore.setParameters({ providerId: provider.id });
          brandSelectorStore.setSelectedId();
          brandSelectorStore.fetchItems();
        } else {
          form.resetField("providerId");
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandSelectorStore = useMemo(() => {
    return new SelectorStore({
      filterMethod: BrandAPI.filter,
      getByIdMethod: BrandAPI.getById,
      labelKey: "name",
      onSelect(brand) {
        if (brand?.id) {
          form.setValue("brandId", brand.id);
        } else {
          form.resetField("brandId");
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagsQuery = useMemo(() => {
    const query = new Query(TagAPI.getAll);
    query.submit();
    return query;
  }, []);

  const tags = tagsQuery.data?.data ?? [];

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: FormSchema) => {
      await GameAPI.update({ id, ...values });
      eventBusService.publish(new GameUpdated({}));
      message.success({
        content: "The game was successfully updated.",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = form.handleSubmit((values) => {
    submitQuery.submit(values);
  });

  function addTag(id: number) {
    const ids = form.getValues("tagIds");
    form.setValue("tagIds", [...ids, id]);
  }

  function removeTag(id: number) {
    const ids = form.getValues("tagIds")?.filter((i) => i !== id) ?? [];
    form.setValue("tagIds", ids);
  }

  return {
    intl,

    form,
    providerId,
    tagIds,

    gameQuery,
    tagsQuery,
    submitQuery,

    providerSelectorStore,
    brandSelectorStore,

    tags,

    addTag,
    removeTag,

    handleSubmit,
  };
}

export type FormState = ReturnType<typeof useFormState>;
