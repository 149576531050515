import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TBanner } from "@/api";
import { OpenAddBannerModal, OpenEditBannerModal } from "@/events";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";
import { DeleteAction } from "@/ui/delete-action";

import { AddBannerModal } from "./add-modal";
import { EditBannerModal } from "./edit-modal";
import { BannersPageState } from "./page.state";

function BannersPage() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new BannersPageState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore, eventBusService } = globalStore;
  return (
    <Page title={"Lobby banners"}>
      <AddBannerModal />
      <EditBannerModal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={"Select client"}
              />
            </Col>
          )}
          {(state.clientSelectorStore.selectedId ?? 0) > 0 && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={"Select an agent"}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={"Select subagent"}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={"Select shop"}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("ViewDeletedAgents") && (
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                onChange={(event) => {
                  state.includeDeleted = event.target.checked;
                }}
              >
                {"Include deleted"}
              </Checkbox>
            </Col>
          )}
          <Col style={{ marginLeft: "auto" }}>
            <Button
              onClick={() => {
                if (state.query.data?.id) {
                  eventBusService.publish(
                    new OpenAddBannerModal({
                      sliderId: state.query.data.id,
                      entity: state.entity,
                    }),
                  );
                }
              }}
              icon={<PlusOutlined />}
            >
              {"Create"}
            </Button>
          </Col>
        </Row>
      </Card>
      {!!state.query.data?.isInherited && (
        <Spin spinning={state.cloneQuery.isPending}>
          <Alert
            type="warning"
            showIcon
            message={intl.formatMessage(
              {
                defaultMessage:
                  "These banners are received from the parent.{br}<a>Clone</a> banners for yourself if you want to edit them.",
              },
              {
                br: <br />,
                a: (text) => (
                  <Typography.Link onClick={() => state.cloneQuery.submit()}>
                    {text}
                  </Typography.Link>
                ),
              },
            )}
          />
        </Spin>
      )}
      <Card size="small">
        <Spin spinning={state.query.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.query]} />
            <Table
              dataSource={state.query.data?.banners}
              showHeader={!!state.query.data?.banners.length}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
              onRow={(banner) => {
                if (banner.deletedAt) {
                  return { className: "ant-table-row-color-red" };
                }
                if (!banner.isActive) {
                  return { className: "ant-table-row-color-gray" };
                }
                return {};
              }}
            >
              <Table.Column dataIndex="id" title={"ID"} />
              <Table.Column
                title={"Title & Subtitle"}
                render={(_, banner: TBanner) => (
                  <Space direction="vertical">
                    <div style={{ width: 300, whiteSpace: "break-spaces" }}>
                      {BannersPageState.renderTitle(banner)}
                    </div>
                    <div style={{ width: 300, whiteSpace: "break-spaces" }}>
                      {BannersPageState.renderSubtitle(banner)}
                    </div>
                  </Space>
                )}
                width="100%"
              />
              <Table.Column
                title={"Enabled"}
                dataIndex="isActive"
                render={(value: boolean) => <Switch checked={value} disabled />}
              />
              <Table.Column
                title={"Image"}
                align="center"
                render={(_, banner: TBanner) => {
                  const image =
                    banner.backgroundDesktop ||
                    banner.backgroundDesktopExtrabet;
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 80,
                      }}
                    >
                      {image ? (
                        <img
                          key={Date.now()}
                          src={image}
                          style={{ height: "100%" }}
                        />
                      ) : (
                        "—"
                      )}
                    </div>
                  );
                }}
              />
              <Table.Column title={"Order"} dataIndex="order" align="right" />
              {(state.clientSelectorStore.selectedId === -1 ||
                !state.query.data?.isInherited) && (
                <Table.Column
                  title={"Actions"}
                  render={(_, banner: TBanner) => {
                    return (
                      <Row justify="end" wrap={false} gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            icon={<EditOutlined />}
                            title={"Edit"}
                            onClick={() => {
                              eventBusService.publish(
                                new OpenEditBannerModal({
                                  id: banner.id,
                                  entity: state.entity,
                                }),
                              );
                            }}
                          />
                        </Col>
                        {state.clientSelectorStore.selectedId !== -1 && (
                          <Col>
                            <DeleteAction
                              entity={{ bannerId: banner.id }}
                              isDeleted={!!banner.deletedAt}
                              onSuccess={() => state.apply()}
                              size="small"
                            />
                          </Col>
                        )}
                      </Row>
                    );
                  }}
                  align="right"
                />
              )}
            </Table>
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(BannersPage);
