import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { FormState } from "./FormState";
import { FormView } from "./FormView";

export type Props = {
  clientId: number;
  setClientName(name: string): void;
};

export const Form: FC<Props> = observer((props) => {
  const { eventBusService, intlService, permissionsStore } = useGlobalStore();
  const vm = useViewModel(
    () => new FormState(eventBusService, intlService, permissionsStore),
    props,
  );
  return <FormView state={vm} />;
});
