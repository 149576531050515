import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { has, kebabCase, snakeCase } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { TAccessControlList } from "@/api";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

const DEFAULT_ACL: TAccessControlList = [
  { role: "Admin", permissions: ["Read"] },
  { role: "Manager", permissions: ["Read"] },
  { role: "ClientAdmin", permissions: ["Read"] },
  { role: "AgentAdmin", permissions: ["Read"] },
  { role: "HallAdmin", permissions: ["Read"] },
  { role: "Cashier", permissions: ["Read"] },
];

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const _ = intl.formatMessage;
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  if (state.categoriesQuery.isIdle || state.categoriesQuery.isPending) {
    return <FormSpinner />;
  }

  if (state.categoriesQuery.isRejected) {
    return <ErrorsFormatter queries={[state.categoriesQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.createQuery]} />
      <Form
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Category" })}
          validateStatus={
            has(form.formState.errors, "categoryId") ? "error" : ""
          }
          help={form.formState.errors.categoryId?.message}
        >
          <Controller
            control={form.control}
            name="categoryId"
            render={({ field }) => (
              <Select
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select category",
                })}
              >
                {state.categories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Title" })}
          validateStatus={has(form.formState.errors, "name") ? "error" : ""}
          help={form.formState.errors.name?.message}
        >
          <Controller
            control={form.control}
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  const value = event.target.value;
                  const slug = kebabCase(value);
                  const filePath = snakeCase(value) + ".md";
                  if (!form.formState.touchedFields["slug"]) {
                    form.setValue("slug", slug);
                  }
                  if (!form.formState.touchedFields["filePath"]) {
                    form.setValue("filePath", filePath);
                  }
                }}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter title",
                })}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Slug" })}
          validateStatus={has(form.formState.errors, "slug") ? "error" : ""}
          help={form.formState.errors.slug?.message}
        >
          <Controller
            control={form.control}
            name="slug"
            render={({ field }) => (
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter slug",
                })}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "File path" })}
          validateStatus={has(form.formState.errors, "filePath") ? "error" : ""}
          help={form.formState.errors.filePath?.message}
        >
          <Controller
            control={form.control}
            name="filePath"
            render={({ field }) => (
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter file path",
                })}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Access" })}
          validateStatus={
            has(form.formState.errors, "accessControlList") ? "error" : ""
          }
        >
          <Controller
            control={form.control}
            name="accessControlList"
            defaultValue={DEFAULT_ACL}
            render={({ field }) => (
              <Row gutter={[8, 8]} wrap={true}>
                {field.value.slice(3).map((entry) => (
                  <Col key={entry.role} xs={24}>
                    <Space direction="horizontal">
                      <Checkbox
                        checked={entry.permissions.includes("Read")}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          if (isChecked) {
                            entry.permissions.push("Read");
                          } else {
                            entry.permissions = [];
                          }
                          field.onChange([...field.value]);
                        }}
                      >
                        {_({ id: `role/${entry.role}` })}
                      </Checkbox>
                    </Space>
                  </Col>
                ))}
              </Row>
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Enabled" })}
          validateStatus={
            has(form.formState.errors, "isEnabled") ? "error" : ""
          }
          help={form.formState.errors.isEnabled?.message}
        >
          <Controller
            control={form.control}
            name="isEnabled"
            defaultValue={true}
            render={({ field }) => <Switch {...field} checked={field.value} />}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.createQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Create" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
