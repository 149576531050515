import MethodBuilder from "../method-builder";

type TAlternativePatch = {
  id: number;
  name: string;
};

export class AlternativePatchAPI {
  static getAll = new MethodBuilder<
    void,
    {
      data: TAlternativePatch[];
      total: number;
    }
  >()
    .withName("AlternativePatch.getAll")
    .build();

  static filter = new MethodBuilder<
    {
      currency: string;
    },
    {
      data: TAlternativePatch[];
      total: number;
    }
  >()
    .withName("AlternativePatch.filter")
    .build();

  static apply = new MethodBuilder<
    { patchId: number | null; hallId?: number; agentId?: number },
    void
  >()
    .withName("AlternativePatch.apply")
    .build();
}
