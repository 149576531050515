import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { KioskAccessAPI } from "@/api";
import { CloseAddKioskAccessModal } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import {
  AddKioskAccessFormSchema,
  useAddKioskAccessFormSchema,
} from "./form.schema";

export interface AddKioskAccessFormProps {
  hallId: number;
  clientId: number;
  kioskId?: number;
}

export function useAddKioskAccessFormState({
  clientId,
  hallId,
  kioskId,
}: AddKioskAccessFormProps) {
  const intl = useIntl();

  const schema = useAddKioskAccessFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<AddKioskAccessFormSchema>({ resolver });

  const query = useMemo(() => {
    return new Query(KioskAccessAPI.create);
  }, []);

  const { eventBusService } = useGlobalStore();

  const handleSubmit = form.handleSubmit(async (values) => {
    await query.submit({ clientId, hallId, kioskId, ...values });

    if (!query.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: intl.formatMessage({
        defaultMessage: "The kiosk access was successfully added.",
      }),
    });

    eventBusService.publish(new CloseAddKioskAccessModal({}));
  });

  return { intl, form, handleSubmit, query };
}

export type AddKioskAccessFormState = ReturnType<
  typeof useAddKioskAccessFormState
>;
