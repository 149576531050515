import MethodBuilder from "../method-builder";
import { TRule } from "../types";

export class RuleAPI {
  static getById = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      type: "TermsAndConditions" | "SweepstakesRules";
    },
    TRule | null
  >()
    .withName("Rule.getById")
    .build();

  static update = new MethodBuilder<
    ({ clientId: number } | { agentId: number }) & {
      rule: {
        type: "TermsAndConditions" | "SweepstakesRules";
        text: string;
        version: string;
        showInLobby: boolean;
      };
    },
    TRule
  >()
    .withName("Rule.update")
    .build();

  static delete = new MethodBuilder<
    ({ clientId: number } | { agentId: number }) & {
      type: "TermsAndConditions" | "SweepstakesRules";
    },
    TRule
  >()
    .withName("Rule.delete")
    .build();
}
