import { TPaging, TShiftsReportItem } from "../..";
import MethodBuilder from "../../method-builder";

export class ShiftsReportAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | { cashierId: number }
    ) & {
      dateTimeRange?: {
        // ISO 8601
        from: string;
        // ISO 8601
        to: string;
      };
      paging: TPaging;
    },
    {
      data: TShiftsReportItem[];
      total: number;
    }
  >()
    .withName("ShiftsReport.filter")
    .build();
}
