import { Button, Card, Col, Row, Select, Space, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TVouchersReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { VouchersReportState } from "./VouchersReportState";

function VouchersReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new VouchersReportState(globalStore), {});
  const intl = useIntl();
  const _ = intl.formatMessage;
  const { permissionsStore } = globalStore;

  return (
    <Page title={intl.formatMessage({ defaultMessage: "Voucher report" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Select
              style={{ width: "100%" }}
              placeholder={intl.formatMessage({
                defaultMessage: "Select a status",
              })}
              options={[
                {
                  label: _({ id: "voucher-status/issued" }),
                  value: "issued",
                },
                {
                  label: _({ id: "voucher-status/redeemed" }),
                  value: "redeemed",
                },
              ]}
              allowClear
              onChange={(value) => {
                state.setStatus(value);
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={
                !state.hallSelectorStore.selectedId &&
                !state.playerSelectorStore.selectedId
              }
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              dataSource={state.filterQuery.items}
              showHeader={!!state.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
            >
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "ID",
                })}
                dataIndex="id"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Created" })}
                dataIndex="createdAt"
                render={(_, item: TVouchersReportItem) =>
                  new Date(item.createdAt).toLocaleString()
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Creator" })}
                dataIndex="creator"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Shop",
                })}
                dataIndex="hallName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Cashier" })}
                dataIndex="cashierName"
                render={(name) => name ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Player",
                })}
                dataIndex="playerName"
                render={(name) => name ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Updated",
                })}
                dataIndex="updatedAt"
                render={(_, item: TVouchersReportItem) =>
                  new Date(item.updatedAt).toLocaleString()
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Transaction ID" })}
                dataIndex="transactionId"
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Status",
                })}
                dataIndex="status"
                align="right"
                render={(status) => _({ id: `voucher-status/${status}` })}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "PIN" })}
                dataIndex="pin"
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "CVV" })}
                dataIndex="cvv"
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Amount",
                })}
                dataIndex="amount"
                render={(_, item: TVouchersReportItem) => (
                  <MoneyFormatter cents={item.amount} />
                )}
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex="currency"
                align="right"
              />
            </Table>
            <Pagination
              query={state.filterQuery}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(VouchersReport);
