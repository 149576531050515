import { Space, Form, Row, Button, Col, Input } from "antd";
import { observer } from "mobx-react-lite";

import { CopyButton } from "../_common_/copy-button";
import ErrorsFormatter from "../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormSwitch,
  FormTextArea,
} from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";
import { AlternativePatch } from "../alternative-patch";

import { HallLobbySettingsState } from "./hall-lobby-settings.state";

interface Props {
  state: HallLobbySettingsState;
}

export const HallLobbySettingsView = observer(({ state }: Props) => {
  const {
    hallId,
    intl,
    form,
    clientQuery,
    agentQuery,
    hallQuery,
    isContactsShown,
    isAutoEnableGamesShown,
    submitQuery,
  } = state;

  if (
    (clientQuery.isIdle && agentQuery.isIdle) ||
    clientQuery.isPending ||
    agentQuery.isPending ||
    hallQuery.isIdle ||
    hallQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (clientQuery.isRejected || agentQuery.isRejected || hallQuery.isRejected) {
    return <ErrorsFormatter queries={[clientQuery, agentQuery, hallQuery]} />;
  }

  const hall = hallQuery.data;

  return (
    <Space direction="vertical">
      <AlternativePatch entity={{ hallId }} currency={hall.currency} />
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        {isContactsShown && (
          <>
            <FormItem
              form={form}
              path="lobbySettings.isContactsShown"
              label={intl.formatMessage({ defaultMessage: "Show contacts" })}
            >
              <FormSwitch form={form} path="lobbySettings.isContactsShown" />
            </FormItem>
            <FormItem
              form={form}
              path="lobbySettings.contacts"
              label={intl.formatMessage({ defaultMessage: "Contacts" })}
            >
              <FormTextArea
                form={form}
                path="lobbySettings.contacts"
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter contacts",
                })}
              />
            </FormItem>
          </>
        )}
        <FormItem
          form={form}
          path="lobbySettings.playersIdleTimeout"
          label={intl.formatMessage({
            defaultMessage: "Players idle timeout (min)",
          })}
        >
          <FormInput
            form={form}
            path="lobbySettings.playersIdleTimeout"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter timeout",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="lobbySettings.isLogOutButtonEnabled"
          label={intl.formatMessage({
            defaultMessage: "Logout button enabled",
          })}
        >
          <FormSwitch form={form} path="lobbySettings.isLogOutButtonEnabled" />
        </FormItem>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Jackpot token" })}
        >
          {hall.jackpotToken ? (
            <Input.Group
              compact
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Input value={hall.jackpotToken} readOnly />
              <CopyButton text={hall.jackpotToken} />
            </Input.Group>
          ) : (
            "—"
          )}
        </Form.Item>
        {isAutoEnableGamesShown && (
          <FormItem
            form={form}
            path="autoEnableGames"
            label={intl.formatMessage({
              defaultMessage: "Automatically enable games",
            })}
          >
            <FormSwitch form={form} path="autoEnableGames" />
          </FormItem>
        )}
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
