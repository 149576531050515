import { observer } from "mobx-react-lite";

import { useDailyLoginRewardsFormState } from "./form.state";
import { DailyLoginRewardsFormView } from "./form.view";

export type DailyLoginRewardsFormProps = { hallId: number };

export const DailyLoginRewardsForm = observer<DailyLoginRewardsFormProps>(
  (props) => {
    const state = useDailyLoginRewardsFormState(props);
    return <DailyLoginRewardsFormView state={state} />;
  },
);
