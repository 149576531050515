import { makeAutoObservable, reaction } from "mobx";

import {
  AgentAPI,
  ClientAPI,
  HallAPI,
  SliderAPI,
  TBanner,
  TClient,
} from "@/api";
import { SearchParamKey } from "@/constants";
import { CloseAddBannerModal, CloseEditBannerModal } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { GlobalStore, SelectorStore } from "@/stores";
import { parseInteger } from "@/utilities";

export class BannersPageState implements ViewModel<unknown> {
  static renderTitle(banner: TBanner) {
    return (
      banner.titles.find((title) => title.lang === "en")?.value ??
      banner.titles.at(0)?.value ??
      "—"
    );
  }

  static renderSubtitle(banner: TBanner) {
    return (
      banner.subtitles.find((subtitle) => subtitle.lang === "en")?.value ??
      banner.subtitles.at(0)?.value ??
      "—"
    );
  }

  constructor(private globalStore: GlobalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.eventBusService.subscribe(CloseAddBannerModal, () => this.apply());
    this.eventBusService.subscribe(CloseEditBannerModal, () => this.apply());
  }

  onViewMount() {
    this.useInitialSearchParams();
    this.updateSearchParams();
    this.updateSelectorParameters();

    reaction(
      () => this.internalEntity,
      () => this.apply(),
    );

    this.apply();
  }

  get routerService() {
    return this.globalStore.routerService;
  }

  get userStore() {
    return this.globalStore.userStore;
  }

  get permissionsStore() {
    return this.globalStore.permissionsStore;
  }

  get eventBusService() {
    return this.globalStore.eventBusService;
  }

  private _includeDeleted = false;

  get includeDeleted() {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
    this.apply();
  }

  private handleClientSelect = () => {
    this.agentSelectorStore.setSelectedId();
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleAgentSelect = () => {
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleSubagentSelect = () => {
    this.hallSelectorStore.setSelectedId();

    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  private handleHallSelect = () => {
    this.updateSearchParams();
    this.updateSelectorParameters();
  };

  public readonly clientSelectorStore = new SelectorStore({
    filterMethod: ClientAPI.filter,
    getByIdMethod: ClientAPI.getById,
    labelKey: "name",
    onSelect: this.handleClientSelect,
    pinnedItems: [
      {
        id: -1,
        name: "Global banners",
      } as TClient,
    ],
  });

  public readonly agentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleAgentSelect,
  });

  public readonly subagentSelectorStore = new SelectorStore({
    filterMethod: AgentAPI.filter,
    getByIdMethod: AgentAPI.getById,
    labelKey: "name",
    onSelect: this.handleSubagentSelect,
  });

  public readonly hallSelectorStore = new SelectorStore({
    filterMethod: HallAPI.filter,
    getByIdMethod: HallAPI.getById,
    labelKey: "name",
    onSelect: this.handleHallSelect,
  });

  private get internalEntity() {
    let params:
      | { clientId: number }
      | { agentId: number }
      | { hallId: number }
      | null = null;
    if (this.hallSelectorStore.selectedId) {
      params = { hallId: this.hallSelectorStore.selectedId };
    } else if (this.subagentSelectorStore.selectedId) {
      params = { agentId: this.subagentSelectorStore.selectedId };
    } else if (this.agentSelectorStore.selectedId) {
      params = { agentId: this.agentSelectorStore.selectedId };
    } else if (this.clientSelectorStore.selectedId) {
      params = { clientId: this.clientSelectorStore.selectedId };
    }
    return params;
  }

  get entity() {
    if (
      this.internalEntity !== null &&
      "clientId" in this.internalEntity &&
      this.internalEntity.clientId === -1
    ) {
      return null;
    }
    return this.internalEntity;
  }

  query = new Query(async (_: void) => {
    if (!this.internalEntity) {
      return;
    }
    return await SliderAPI.get({
      ...this.internalEntity,
      includeDeleted: this.includeDeleted,
    });
  });

  apply = async () => {
    await this.query.submit();
  };

  cloneQuery = new Query(async (_: void) => {
    if (!this.internalEntity) {
      return;
    }
    await SliderAPI.clone({ id: this.query.data!.id, ...this.internalEntity });
    await this.apply();
  });

  private useInitialSearchParams = () => {
    const { client, agent, hall } = this.userStore;

    if (client) {
      this.clientSelectorStore.setSelectedId(client.id, false);
    } else if (agent) {
      this.clientSelectorStore.setSelectedId(agent.clientId, false);
      this.agentSelectorStore.setSelectedId(agent.id, false);
    } else if (hall) {
      this.clientSelectorStore.setSelectedId(hall.clientId, false);
      this.agentSelectorStore.setSelectedId(hall.agentId ?? undefined, false);
      this.hallSelectorStore.setSelectedId(hall.id, false);
    }

    const { searchParams } = this.routerService;
    const initialClientId = parseInteger(searchParams[SearchParamKey.ClientId]);
    const initialAgentId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.AgentId])
        : undefined;
    const initialHallId =
      initialClientId || this.clientSelectorStore.selectedId
        ? parseInteger(searchParams[SearchParamKey.HallId])
        : undefined;

    if (this.permissionsStore.has("SelectClient")) {
      this.clientSelectorStore.setSelectedId(initialClientId);
    }
    if (this.clientSelectorStore.selectedId) {
      if (this.permissionsStore.has("SelectAgent")) {
        this.agentSelectorStore.setSelectedId(initialAgentId);
      }
      if (
        this.permissionsStore.has("SelectSubagent") &&
        agent?.id !== initialAgentId
      ) {
        this.subagentSelectorStore.setSelectedId(initialAgentId);
      }
      if (this.permissionsStore.has("SelectHall")) {
        this.hallSelectorStore.setSelectedId(initialHallId);
      }
    }
  };

  private updateSearchParams() {
    const urlSearchParams = new URLSearchParams(
      this.routerService.location.search,
    );

    if (
      this.clientSelectorStore.selectedId &&
      this.clientSelectorStore.selectedId > 0
    ) {
      urlSearchParams.set(
        SearchParamKey.ClientId,
        this.clientSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.ClientId);
    }

    if (this.subagentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.subagentSelectorStore.selectedId.toString(),
      );
    } else if (this.agentSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.AgentId,
        this.agentSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.AgentId);
    }

    if (this.hallSelectorStore.selectedId) {
      urlSearchParams.set(
        SearchParamKey.HallId,
        this.hallSelectorStore.selectedId.toString(),
      );
    } else {
      urlSearchParams.delete(SearchParamKey.HallId);
    }

    this.routerService.replace({
      search: urlSearchParams.toString(),
    });
  }

  private updateSelectorParameters = () => {
    const oldClientParameters = this.clientSelectorStore.parameters;
    const oldAgentParameters = this.agentSelectorStore.parameters;
    const oldSubagentParameters = this.subagentSelectorStore.parameters;
    const oldHallParameters = this.hallSelectorStore.parameters;

    let newClientParameters = oldClientParameters;
    let newAgentParameters = oldAgentParameters;
    let newSubagentParameters = oldSubagentParameters;
    let newHallParameters = oldHallParameters;

    const clientId = this.clientSelectorStore.selectedId;
    const agentId = this.agentSelectorStore.selectedId;
    const subagentId = this.subagentSelectorStore.selectedId;

    const { user } = this.userStore;
    newClientParameters = {
      managerId: user.role === "Manager" ? user.id : undefined,
    };

    if (clientId) {
      newAgentParameters = { clientId };
      newSubagentParameters = { clientId };
      newHallParameters = { clientId };
    }

    if (agentId) {
      newSubagentParameters = { parentAgentId: agentId };
      newHallParameters = { agentId };
    }

    if (subagentId) {
      const agentId = subagentId;
      newHallParameters = { agentId };
    }

    if (
      this.permissionsStore.has("SelectClient") &&
      JSON.stringify(oldClientParameters) !==
        JSON.stringify(newClientParameters)
    ) {
      this.clientSelectorStore.setParameters(newClientParameters);
      this.clientSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectAgent") &&
      JSON.stringify(oldAgentParameters) !== JSON.stringify(newAgentParameters)
    ) {
      this.agentSelectorStore.setParameters(newAgentParameters);
      this.agentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectSubagent") &&
      JSON.stringify(oldSubagentParameters) !==
        JSON.stringify(newSubagentParameters)
    ) {
      this.subagentSelectorStore.setParameters(newSubagentParameters);
      this.subagentSelectorStore.fetchItems();
    }

    if (
      this.permissionsStore.has("SelectHall") &&
      JSON.stringify(oldHallParameters) !== JSON.stringify(newHallParameters)
    ) {
      this.hallSelectorStore.setParameters(newHallParameters);
      this.hallSelectorStore.fetchItems();
    }
  };
}
