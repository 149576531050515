import { TConversation, TMessage } from "..";
import MethodBuilder from "../method-builder";

export class ConversationAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { hallId: number }
      | { agentId: number }
      | { playerId: number }
    ) & {
      status?: string;
      isReadByAdmin?: boolean;
    },
    TConversation[]
  >()
    .withName("Conversation.filter")
    .build();

  static getById = new MethodBuilder<
    { conversationId: number },
    TConversation
  >()
    .withName("Conversation.getById")
    .build();

  static getMessages = new MethodBuilder<
    {
      conversationId: number;
      startSeqNum: number;
      limit?: number;
    },
    TMessage[]
  >()
    .withName("Conversation.getMessages")
    .build();

  static close = new MethodBuilder<
    {
      conversationId: number;
    },
    void
  >()
    .withName("Conversation.close")
    .build();

  static deleteMessage = new MethodBuilder<
    {
      messageId: number;
    },
    void
  >()
    .withName("Conversation.deleteMessage")
    .build();

  static sendMessage = new MethodBuilder<
    {
      conversationId?: number;
      hallId: number;
      text: string;
      toPlayerId?: number;
    },
    {
      messageId: number;
      conversationId: number;
      seqNum: number;
    }
  >()
    .withName("Conversation.sendMessage")
    .build();
}
