import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  useEffect(() => {
    if (state.submitTask.isFulfilled) {
      form.reset();
    }
  }, [form, state.submitTask.isFulfilled]);

  if (state.fetchTask.isIdle || state.fetchTask.isPending) {
    return <FormSpinner />;
  }

  if (state.fetchTask.isRejected) {
    return <ErrorsFormatter queries={[state.fetchTask]} />;
  }

  const { agent, game } = state.fetchTask.data;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.submitTask]} />
      <Form
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.submitTask.submit)}
      >
        <Form.Item label={"Agent"}>
          <Input disabled value={agent.name} />
        </Form.Item>
        <Form.Item label={"Game"}>
          <Input disabled value={game.name} />
        </Form.Item>
        <Form.Item
          label={"Order"}
          validateStatus={has(form.formState.errors, "order") ? "error" : ""}
          help={form.formState.errors.order?.message}
        >
          <Controller
            control={form.control}
            name="order"
            defaultValue={state.gameOrder ?? undefined}
            render={({ field }) => (
              <Input
                placeholder={"Enter an order"}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value || undefined);
                }}
                type="number"
                autoFocus
                allowClear
              />
            )}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.submitTask.isPending}
            >
              {"Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
