import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { TGame } from "@/api";
import {
  UpdateGame,
  GameUpdated,
  CreateGame,
  GameCreated,
  CreateBrand,
} from "@/events";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { NewGameModal } from "./add";
import { NewBrandModal } from "./add-brand";
import { EditGameModal } from "./edit";
import { GamesViewModel } from "./games.vm";

function Games() {
  const { eventBusService } = useGlobalStore();
  const vm = useMemo(() => new GamesViewModel(), []);

  useEffect(() => {
    const listener = () => vm.fetch();

    eventBusService.subscribe(GameCreated, listener);
    eventBusService.subscribe(GameUpdated, listener);
    return () => {
      eventBusService.unsubscribe(GameCreated, listener);
      eventBusService.unsubscribe(GameUpdated, listener);
    };
  }, [eventBusService, vm]);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<TGame> | SorterResult<TGame>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        ["id", "name", "order"].includes(sorter.columnKey)
      ) {
        vm.gamesQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
        vm.fetch();
      }
    },
    [vm],
  );

  return (
    <Page title={"Games"}>
      <NewGameModal />
      <EditGameModal />
      <NewBrandModal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Selector
              store={vm.providerSelectorStore}
              placeholder={"Select provider"}
            />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Select
              placeholder={"Select brand"}
              showSearch
              showArrow
              allowClear
              loading={vm.brandsQuery.isPending}
              value={vm.selectedBrandId}
              onChange={(brandId) => vm.setSelectedBrandId(brandId)}
              options={vm.filteredBrands.map((brand) => ({
                label: brand.name,
                value: brand.id,
              }))}
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase()) ??
                false
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col
            xs={24}
            sm={10}
            md={8}
            lg={6}
            xl={4}
            style={{ marginLeft: "auto" }}
          >
            <Input.Search
              placeholder={"Search..."}
              allowClear
              defaultValue={vm.gamesQuery.searchQuery}
              onSearch={(searchQuery) => {
                vm.gamesQuery.setSearchQuery(searchQuery);
                vm.fetch();
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() =>
                eventBusService.publish(
                  new CreateGame({
                    providerId: vm.providerSelectorStore.selectedId,
                    brandId: vm.selectedBrandId,
                  }),
                )
              }
            >
              <FormattedMessage defaultMessage="Add game" />
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => eventBusService.publish(new CreateBrand({}))}
            >
              <FormattedMessage defaultMessage="Add brand" />
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Space direction="vertical">
          <ErrorsFormatter queries={[vm.tagQuery, vm.gamesQuery]} />
          <Table
            loading={vm.tagQuery.isPending || vm.gamesQuery.isPending}
            dataSource={vm.gamesQuery.items}
            pagination={false}
            size="small"
            rowKey="id"
            onChange={handleTableChange}
          >
            <Table.Column
              title={"ID"}
              dataIndex="id"
              align="right"
              key="id"
              sorter={true}
              defaultSortOrder={
                vm.gamesQuery.order[1] === "asc" ? "ascend" : "descend"
              }
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column title={"SID"} dataIndex="stringId" />
            <Table.Column title={"Provider"} dataIndex="providerName" />
            <Table.Column title={"Brand"} dataIndex="brandName" />
            <Table.Column
              title={"Name"}
              dataIndex="name"
              width="100%"
              key="name"
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              title={"Tags"}
              dataIndex="tagIds"
              render={(tagIds: number[]) => {
                if (!tagIds.length) {
                  return "—";
                }
                return tagIds
                  .map((tagId) => {
                    const tag = (vm.tagQuery.data?.data ?? []).find(
                      (t) => t.id === tagId,
                    );
                    return tag?.name;
                  })
                  .join(", ");
              }}
            />
            <Table.Column
              title={"JP Enabled"}
              dataIndex="isJackpotsEnabled"
              key="isJackpotsEnabled"
              align="right"
              render={(value: boolean) => <Switch checked={value} />}
            />
            <Table.Column
              title={"Bonus Wagering"}
              dataIndex="isBonusWagerEnabled"
              key="isBonusWagerEnabled"
              align="right"
              render={(value: boolean) => <Switch checked={value} />}
            />
            <Table.Column
              title={"Sort order"}
              dataIndex="order"
              key="order"
              align="right"
              sorter={true}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Table.Column
              title={"Enabled"}
              dataIndex="isEnabled"
              align="right"
              render={(isEnabled: boolean) => <Switch checked={isEnabled} />}
            />

            <Table.Column
              title={"Actions"}
              align="right"
              render={(_, item: TGame) => (
                <Row wrap={false} gutter={[8, 8]} justify="end">
                  <Col>
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      title={"Edit"}
                      onClick={() => {
                        eventBusService.publish(
                          new UpdateGame({ id: item.id }),
                        );
                      }}
                    />
                  </Col>
                </Row>
              )}
            />
          </Table>
          <Pagination
            query={vm.gamesQuery}
            onChange={() => {
              vm.fetch({ preservePageNumber: true });
            }}
          />
        </Space>
      </Card>
    </Page>
  );
}

export default observer(Games);
