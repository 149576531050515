import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import Query from "@/models/query";

import { useReceiptEditorState } from "./editor.state";
import { ReceiptEditorView } from "./editor.view";
import { Line } from "./types";

export interface ReceiptEditorProps {
  header?: ReactNode;
  lines: Line[];
  submitQuery: Query<{ lines: Line[] }, void>;
  cancelQuery?: Query<void, void>;
  defaultFontSize: number;
  scaleFontSize(size: number): number;
}

export const ReceiptEditor = observer<ReceiptEditorProps>((props) => {
  const state = useReceiptEditorState(props);
  return <ReceiptEditorView state={state} />;
});
