import { observer } from "mobx-react-lite";

import { useCashierCreateFormState } from "./cashier-create-form.state";
import { CashierCreateFormView } from "./cashier-create-form.view";

export interface CashierCreateFormProps {
  hallId: number;
}

export const CashierCreateForm = observer((props: CashierCreateFormProps) => {
  const state = useCashierCreateFormState(props);
  return <CashierCreateFormView state={state} />;
});
