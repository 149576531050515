import { TVoucher } from "..";
import MethodBuilder from "../method-builder";

class VoucherAPI {
  static redeem = new MethodBuilder<
    {
      hallId: number;
      cvv: string;
      pin: string;
    },
    TVoucher
  >()
    .withName("Voucher.redeem")
    .build();
}

export default VoucherAPI;
