import yup from "@/yup";

export function useCashierCreateFormSchema() {
  return yup.object({
    name: yup.string().min(3).required(),
    login: yup.string().min(3).required(),
    password: yup.string().min(6).required(),
    isShiftModeEnabled: yup.boolean().defined().default(false),
  });
}
export type CashierCreateFormSchema = yup.InferType<
  ReturnType<typeof useCashierCreateFormSchema>
>;
