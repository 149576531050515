import { observer } from "mobx-react-lite";

import { useCreateVoucherFormState } from "./create-voucher-form.state";
import { CreateVoucherFormView } from "./create-voucher-form.view";

export interface CreateVoucherFormProps {
  hallId: number;
}

export const CreateVoucherForm = observer<CreateVoucherFormProps>((props) => {
  const state = useCreateVoucherFormState(props);
  return <CreateVoucherFormView state={state} />;
});
