import { Component, ReactNode } from "react";

type Props = { children: ReactNode } & (
  | { fallback: ReactNode }
  | { renderFallback(error: Error): ReactNode }
);

export class ErrorBoundary extends Component<Props> {
  state = {
    error: null as Error | null,
  };

  constructor(props: Props) {
    super(props);
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    if (this.state.error !== null) {
      if ("renderFallback" in this.props) {
        return this.props.renderFallback(this.state.error);
      }
      return this.props.fallback;
    }
    return this.props.children;
  }
}
