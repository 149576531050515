import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ReactNode } from "react";

type TProps = {
  label: string;
  tooltip: ReactNode;
  placement?: TooltipPlacement | undefined;
};

const iconStyle = {
  marginLeft: "4px",
};

function LabelWithTooltip({ label, tooltip }: TProps) {
  return (
    <Tooltip title={tooltip}>
      {label}
      <InfoCircleOutlined style={iconStyle} />
    </Tooltip>
  );
}

export default LabelWithTooltip;
