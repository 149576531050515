import { observer } from "mobx-react-lite";

import {
  AddKioskAccessFormProps,
  useAddKioskAccessFormState,
} from "./form.state";
import { AddKioskAccessFormView } from "./form.view";

export const AddKioskAccessForm = observer((props: AddKioskAccessFormProps) => {
  const state = useAddKioskAccessFormState(props);
  return <AddKioskAccessFormView state={state} />;
});
