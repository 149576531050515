import { Space, Form, Input, Row, Col, Button } from "antd";
import { observer } from "mobx-react-lite";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import ErrorsFormatter from "../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormRadioGroup,
  FormSelect,
  FormSwitch,
} from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { AddKioskFormState } from "./add-kiosk-form.state";

interface Props {
  state: AddKioskFormState;
}

export const AddKioskFormView = observer(({ state }: Props) => {
  const { intl, form, hallQuery, submitQuery } = state;

  if (hallQuery.isIdle || hallQuery.isPending) {
    return <FormSpinner />;
  }

  if (hallQuery.isRejected) {
    return <ErrorsFormatter queries={[hallQuery]} />;
  }

  const hall = hallQuery.data;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Shop" })}>
          {hall.name}
        </Form.Item>
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({ defaultMessage: "Enter name" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="pairingCode"
          label={intl.formatMessage({ defaultMessage: "Pairing code" })}
        >
          <Controller
            control={form.control}
            name="pairingCode"
            defaultValue=""
            render={({ field }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <InputMask
                onChange={field.onChange}
                value={field.value}
                mask="9999-9999"
                maskPlaceholder="0000-0000"
              >
                <Input
                  placeholder={intl.formatMessage({
                    defaultMessage: "0000-0000",
                  })}
                />
              </InputMask>
            )}
          />
        </FormItem>
        <FormItem
          form={form}
          path="type"
          label={intl.formatMessage({ defaultMessage: "Type" })}
        >
          <FormSelect
            form={form}
            path="type"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Kiosk" }),
                value: "kiosk",
              },
              {
                label: intl.formatMessage({ defaultMessage: "Exchanger" }),
                value: "exchanger",
              },
              {
                label: intl.formatMessage({ defaultMessage: "Internet Cafe" }),
                value: "internetcafe",
              },
            ]}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isAlwaysAuthorized"
          label={intl.formatMessage({ defaultMessage: "Always authorized" })}
        >
          <FormRadioGroup
            form={form}
            path="isAlwaysAuthorized"
            optionType="button"
            buttonStyle="solid"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Yes" }),
                value: "true",
              },
              {
                label: intl.formatMessage({ defaultMessage: "No" }),
                value: "false",
              },
              {
                label: intl.formatMessage({ defaultMessage: "Default" }),
                value: "default",
              },
            ]}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isPayoutAllowed"
          label={intl.formatMessage({ defaultMessage: "Payout allowed" })}
        >
          <FormSwitch form={form} path="isPayoutAllowed" />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Create" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
