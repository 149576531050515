import { blue, red } from "@ant-design/colors";
import { Row, Col, Space, Card } from "antd";
import moment from "moment";
import React, { memo } from "react";
import { useIntl } from "react-intl";

import { TMessage } from "@/api";

import { DeleteMessage } from "./actions";
import { IChatProps } from "./types";

interface IMessageProps extends Omit<IChatProps, "conversation"> {
  item: TMessage;
}

export const Message = memo(({ item, state, player }: IMessageProps) => {
  const intl = useIntl();
  const isFromPlayer = item.from.userId === player.id;
  const isDeleted = Boolean(item.deletedAt);

  return (
    <Row
      justify={isFromPlayer ? "start" : "end"}
      style={{ marginBottom: "8px" }}
    >
      <Col>
        <Space
          size={[2, 2]}
          direction="vertical"
          style={{ position: "relative" }}
        >
          <Card
            style={{
              background: isFromPlayer ? blue[2] : "#d3d3d3",
              borderRadius: "12px",
              maxWidth: "400px",
              wordBreak: "break-word",
              opacity: isDeleted ? 0.5 : 1,
            }}
            aria-label={intl.formatMessage({ defaultMessage: "Message" })}
          >
            {isDeleted ? <del>{item.text}</del> : item.text}
          </Card>
          <span
            style={{
              whiteSpace: "nowrap",
              opacity: 0.8,
              display: "flex",
              alignItems: "center",
              justifyContent: isFromPlayer ? "flex-start" : "flex-end",
              fontSize: 10,
              marginRight: "8px",
            }}
          >
            {item.from.userName}
            {" | "}
            {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
            {isDeleted ? (
              <span
                style={{ color: red[4], fontSize: "12px", marginLeft: "8px" }}
              >
                {intl
                  .formatMessage({ defaultMessage: "Deleted" })
                  .toUpperCase()}
              </span>
            ) : (
              <DeleteMessage state={state} item={item} />
            )}
          </span>
        </Space>
      </Col>
    </Row>
  );
});

Message.displayName = "Message";
