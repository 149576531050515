import { observer } from "mobx-react-lite";

import { EditPartner, PartnerEdited } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditPartnerForm } from "./form";

export const EditPartnerModal = observer(() => {
  return (
    <EventBasedModal
      title="Edit partner"
      openEvent={EditPartner}
      closeEvent={PartnerEdited}
    >
      {({ id }) => <EditPartnerForm id={id} />}
    </EventBasedModal>
  );
});
