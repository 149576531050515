import { notification } from "antd";
import { template as compileTemplate } from "lodash-es";
import { makeAutoObservable } from "mobx";

import { CashierAPI, HallAPI, ShiftAPI } from "@/api";
import { ShiftFinished } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService, PrintService } from "@/services";
import { UserStore } from "@/stores";
import { CurrencyUtilities } from "@/utilities";

import { Props } from "./Form";
import template from "./receipt.html";
import { ISchema } from "./schema";

const generateHtml = compileTemplate(template);

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    public userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hallQuery = new Query(HallAPI.getById);

  get hall() {
    return this.hallQuery.data;
  }

  cashierQuery = new Query(CashierAPI.getById);

  get cashier() {
    return this.cashierQuery.data;
  }

  shiftQuery = new Query(ShiftAPI.get);

  get shift() {
    return this.shiftQuery.data;
  }

  get financialActivity() {
    return this.shift?.financialActivity;
  }

  onViewMount = async ({ cashierId }: Props) => {
    await Promise.all([
      this.cashierQuery.submit({ id: cashierId }),
      this.shiftQuery.submit({ cashierId }),
    ]);

    if (!this.cashierQuery.isFulfilled) {
      return;
    }

    const id = this.cashierQuery.data.hallId;
    await this.hallQuery.submit({ id });
  };

  onViewUnmount = () => {
    this.cashierQuery.reset();
    this.shiftQuery.reset();
  };

  finishShiftQuery = new Query(ShiftAPI.finish);

  handleSubmit = async ({ endingCash, printReceipt }: ISchema) => {
    if (!this.cashier) {
      return;
    }

    await this.finishShiftQuery.submit({
      cashierId: this.cashier.id,
      endingCash: CurrencyUtilities.toMinorUnits(endingCash),
    });

    if (!this.finishShiftQuery.isFulfilled) {
      return;
    }

    if (this.userStore.user.role === "Cashier") {
      await this.userStore.fetchCashier(this.cashier.id);
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage: "The shift has been successfully closed.",
      }),
    });

    this.eventBusService.publish(
      new ShiftFinished({ cashierId: this.cashier.id }),
    );

    if (printReceipt) {
      this.printReceipt();
    }
  };

  printReceipt = () => {
    if (!this.hall || !this.cashier || !this.shift || !this.financialActivity) {
      return;
    }

    const html = generateHtml({
      hall: this.hall,
      cashier: this.cashier,
      financialActivity: this.financialActivityInMainUnits,
      startedAt: new Date(this.shift.startedAt).toLocaleString(),
      finishedAt: new Date().toLocaleString(),
    });

    PrintService.print(html);
  };

  get financialActivityInMainUnits() {
    if (!this.financialActivity) {
      return undefined;
    }
    const entries = Object.entries(this.financialActivity).map(
      ([key, value]) => [
        key,
        {
          ...value,
          total: CurrencyUtilities.toMainUnits(value.total),
        },
      ],
    );
    return Object.fromEntries(entries);
  }
}
