import yup from "@/yup";

export function useSchema() {
  const aceSchema = yup
    .object()
    .shape({
      role: yup.string().required(),
      permissions: yup.array().of(yup.string()).required(),
    })
    .required();

  const aclSchema = yup.array().of(aceSchema).required();

  return yup.object({
    categoryId: yup.number().required(),
    slug: yup.string().slug().required(),
    name: yup.string().required(),
    filePath: yup.string().filePath().required(),
    accessControlList: aclSchema,
    isEnabled: yup.boolean().defined(),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;
