import { TCashier, TPaging } from "..";
import MethodBuilder from "../method-builder";

class CashierAPI {
  static create = new MethodBuilder<
    {
      hallId: number;

      name: string;

      login: string;
      password: string;

      transactionsLimit: number;

      isShiftModeEnabled: boolean;
    },
    TCashier
  >()
    .withName("Cashier.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;

      password?: string;

      transactionsLimit?: number;

      isBlocked?: boolean;
      isShiftModeEnabled?: boolean;
    },
    void
  >()
    .withName("Cashier.update")
    .build();

  static getById = new MethodBuilder<{ id: number }, TCashier>()
    .withName("Cashier.getById")
    .build();

  static filter = new MethodBuilder<
    {
      hallId: number;
      searchQuery?: string;
      orderBy?: ["id" | "login", "asc" | "desc"];
      includeDeleted?: boolean;
      paging: TPaging;
    },
    {
      data: TCashier[];
      total: number;
    }
  >()
    .withName("Cashier.filter")
    .build();

  static resetTransactionsSum = new MethodBuilder<{ id: number }, void>()
    .withName("Cashier.resetTransactionsSum")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Cashier.delete")
    .build();

  static undelete = new MethodBuilder<{ id: number }, void>()
    .withName("Cashier.undelete")
    .build();
}

export default CashierAPI;
