import MethodBuilder from "@/api/method-builder";

import { TGlobalSearchEntityType, TGlobalSearchItem, TPaging } from "..";

class GlobalSearchAPI {
  static filter = new MethodBuilder<
    {
      searchBy: TGlobalSearchEntityType[];
      searchQuery: string;
      paging: TPaging;
    },
    {
      data: TGlobalSearchItem[];
      total: number;
    }
  >()
    .withName("Search.filter")
    .build();
}

export default GlobalSearchAPI;
