import { observer } from "mobx-react-lite";

import { useHallSpecialSettingsState } from "./hall-special-settings.state";
import { HallSpecialSettingsView } from "./hall-special-settings.view";

export type HallSpecialSettingsProps = {
  hallId: number;
  currency?: string;
};

export const HallSpecialSettings = observer<HallSpecialSettingsProps>(
  (props) => {
    const state = useHallSpecialSettingsState(props);
    return <HallSpecialSettingsView state={state} />;
  },
);
