import yup from "@/yup";

export function useVoucherRedeemSchema() {
  return yup.object({
    cvv: yup.string().cvv().required(),
    pin: yup.string().pin().required(),
  });
}

export type TVoucherRedeemSchema = yup.InferType<
  ReturnType<typeof useVoucherRedeemSchema>
>;
