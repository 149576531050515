import { observer } from "mobx-react-lite";
import { FC } from "react";

import { EditGameOrder, GameOrderEdited } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  return (
    <EventBasedModal
      title={"Edit game order"}
      openEvent={EditGameOrder}
      closeEvent={GameOrderEdited}
    >
      {({ agentId, gameId, gameOrder }) => (
        <Form agentId={agentId} gameId={gameId} gameOrder={gameOrder} />
      )}
    </EventBasedModal>
  );
});
