import yup from "@/yup";

export function useEditWebsiteDomainFormSchema() {
  return yup.object({
    imgPackId: yup.number().required(),
    title: yup.string().required(),
    templateId: yup.number().required(),
  });
}

export type EditWebsiteDomainFormSchema = yup.InferType<
  ReturnType<typeof useEditWebsiteDomainFormSchema>
>;
