import {
  THallLobbySettings,
  THall,
  TPaging,
  THallCashierSettings,
  THallSettings,
} from "..";
import MethodBuilder from "../method-builder";

class HallAPI {
  static create = new MethodBuilder<
    {
      clientId: number;
      agentId?: number;

      name: string;

      login: string;
      password: string;

      isBalanceUnlimited: boolean;
      currency: string;

      lobbySettings: Omit<
        THallLobbySettings,
        | "isExchangeActive"
        | "isContactsShown"
        | "isPanicEnabled"
        | "panicUrl"
        | "contacts"
      >;

      autoPrintReceipts: boolean;
    },
    THall
  >()
    .withName("Hall.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      password?: string;
      isBalanceUnlimited?: boolean;
      isBlocked?: boolean;
      isPanicBlock?: boolean;
      bonusWager?: number;
      autoPrintReceipts?: boolean;
      autoEnableGames?: boolean;
      lobbySettings?: Partial<THallLobbySettings>;
      cashierSettings?: Partial<THallCashierSettings>;
      configuredByParent?: boolean;
    },
    THall
  >()
    .withName("Hall.update")
    .build();

  static getById = new MethodBuilder<{ id: number }, THall>()
    .withName("Hall.getById")
    .build();

  static filter = new MethodBuilder<
    ({ clientId: number } | { agentId: number }) & {
      searchQuery?: string;
      currency?: string;
      includeDeleted?: boolean;
      paging: TPaging;
    },
    {
      data: THall[];
      total: number;
    }
  >()
    .withName("Hall.filter")
    .build();

  static withdrawMoney = new MethodBuilder<
    { id: number; amount: number },
    void
  >()
    .withName("Hall.withdrawMoney")
    .build();

  static depositMoney = new MethodBuilder<
    { id: number; amount: number },
    void
  >()
    .withName("Hall.depositMoney")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Hall.delete")
    .build();

  static undelete = new MethodBuilder<{ id: number }, void>()
    .withName("Hall.undelete")
    .build();

  static enablePanic = new MethodBuilder<{ id: number }, void>()
    .withName("Hall.enablePanic")
    .build();

  static disablePanic = new MethodBuilder<{ id: number }, void>()
    .withName("Hall.disablePanic")
    .build();

  static getSettings = new MethodBuilder<{ id: number }, THallSettings>()
    .withName("Hall.getSettings")
    .build();

  static setSettings = new MethodBuilder<
    {
      id: number;
      lobbyThemeStringId: string;
      frontendThemeStringId?: string;
    },
    void
  >()
    .withName("Hall.setSettings")
    .build();

  static warmUp = new MethodBuilder<{ id: number }, void>()
    .withName("Hall.warmUp")
    .build();
}

export default HallAPI;
