import {
  TClient,
  TClientCashierSettings,
  TClientLobbySettings,
  TClientMode,
  TPaging,
} from "..";
import MethodBuilder from "../method-builder";

class ClientAPI {
  static create = new MethodBuilder<
    {
      managerId?: number;

      name: string;

      login: string;
      password: string;

      clientMode: TClientMode;
      currency?: string;

      contacts?: string;
      termsAndConditions?: string;
      notes?: string;
    },
    TClient
  >()
    .withName("Client.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;

      managerId?: number;
      password?: string;
      isBlocked?: boolean;
      lobbySettings?: Partial<TClientLobbySettings>;
      cashierSettings?: Partial<TClientCashierSettings>;
      contacts?: string | null;
      termsAndConditions?: string;
      notes?: string;

      shouldRemoveManager?: boolean;
    },
    TClient
  >()
    .withName("Client.update")
    .build();

  static getById = new MethodBuilder<{ id: number }, TClient>()
    .withName("Client.getById")
    .build();

  static filter = new MethodBuilder<
    {
      managerId?: number;
      searchQuery?: string;
      orderBy?: ["id" | "name", "asc" | "desc"];
      includeDeleted?: boolean;
      paging: TPaging;
    },
    {
      data: TClient[];
      total: number;
    }
  >()
    .withName("Client.filter")
    .build();
}

export default ClientAPI;
