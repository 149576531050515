import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { UpdateArticle, ArticleUpdated } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { Form } from "../form";

export const Modal: FC = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "Edit article" })}
      openEvent={UpdateArticle}
      closeEvent={ArticleUpdated}
    >
      {({ articleId }) => <Form articleId={articleId} />}
    </EventBasedModal>
  );
});
