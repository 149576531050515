import MethodBuilder from "../method-builder";

type Category = "0" | "1" | "2" | "3" | "4" | "5";

type TSequenceSettings = {
  sequence: number;
  reward: string;
  rewardType: string;
  isActive: boolean;
};

type TDailyLoginReward = {
  isActive: boolean;
  cooldown: number; // in seconds
  sequenceSettings: Record<Category, TSequenceSettings[]>;
};

export class DailyLoginRewardAPI {
  static get = new MethodBuilder<{ hallId: number }, TDailyLoginReward | null>()
    .withName("DailyLoginReward.get")
    .build();

  static update = new MethodBuilder<
    {
      hallId: number;
      isActive: boolean;
      sequenceSettings: Record<Category, TSequenceSettings[] | undefined>;
      cooldown: number; // in seconds
    },
    void
  >()
    .withName("DailyLoginReward.update")
    .build();
}
