import { observer } from "mobx-react-lite";

import { useFormState } from "./form.state";
import { FormView } from "./form.view";

export type FormProps = {
  providerId?: number;
  brandId?: number;
};

export const Form = observer<FormProps>((props) => {
  const state = useFormState(props);
  return <FormView state={state} />;
});
