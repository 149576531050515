import { yupResolver } from "@hookform/resolvers/yup";
import { sortBy } from "lodash-es";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { HallAPI, PlayerAPI, WebsiteAPI } from "@/api";
import { VoucherCreated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { dollarsToCents } from "@/utilities";

import { CreateVoucherFormProps } from "./create-voucher-form";
import {
  CreateVoucherFormSchema,
  useCreateVoucherFormSchema,
} from "./create-voucher-form.schema";

export function useCreateVoucherFormState({ hallId }: CreateVoucherFormProps) {
  const intl = useIntl();

  const schema = useCreateVoucherFormSchema();
  const form = useForm<CreateVoucherFormSchema>({
    resolver: yupResolver(schema),
  });

  const { eventBusService } = useGlobalStore();

  const websiteQuery = useMemo(() => {
    const query = new Query(async () => {
      return await WebsiteAPI.filter({ hallId });
    });
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const website = useMemo(() => {
    const websites = websiteQuery.data?.data ?? [];
    return websites.find((w) => w.hasPriority) ?? sortBy(websites, "id").at(0);
  }, [websiteQuery.data?.data]);

  const submitQuery = useMemo(
    () =>
      new Query(async ({ initialBalance }: CreateVoucherFormSchema) => {
        const values = dollarsToCents({ hallId, initialBalance }, [
          "initialBalance",
        ]);
        const [player, hall] = await Promise.all([
          PlayerAPI.generate(values),
          HallAPI.getById({ id: hallId }),
        ]);

        eventBusService.publish(new VoucherCreated({}));

        return { player, hall };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { intl, form, websiteQuery, website, submitQuery };
}

export type CreateVoucherFormState = ReturnType<
  typeof useCreateVoucherFormState
>;
