import { Button, Col, Form, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { EditClubFormState } from "./form.state";

type Props = {
  state: EditClubFormState;
};

export const EditClubFormView = observer<Props>(({ state }) => {
  const { query, form, handleSubmit, mutation } = state;

  if (query.isIdle || query.isPending) {
    return <FormSpinner />;
  }

  if (query.isRejected) {
    return <ErrorsFormatter queries={[query]} />;
  }

  return (
    <Form
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={handleSubmit}
    >
      <ErrorsFormatter queries={[mutation]} />
      <Form.Item label={"ID"}>
        <Typography.Text>{query.data.id}</Typography.Text>
      </Form.Item>
      <Form.Item label={"Partner ID"}>
        <Typography.Text>{query.data.partnerId}</Typography.Text>
      </Form.Item>
      <FormItem form={form} path="description" label={"Description"}>
        <FormInput
          form={form}
          path="description"
          placeholder={"Enter description"}
        />
      </FormItem>
      <Row justify="end" gutter={12}>
        <Col>
          <Button type="primary" htmlType="submit" loading={mutation.isPending}>
            {"Save"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});
