export function parseInteger(value: string) {
  if (!/\d+/.test(value)) {
    return undefined;
  }
  const integer = Number.parseInt(value, 10);
  if (!Number.isSafeInteger(integer)) {
    return undefined;
  }
  return integer;
}
