import { notification } from "antd";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { PlayerAPI } from "@/api";
import { PlayersImported } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { ImportPlayersFormProps } from "./form";

export function useImportPlayersFormState(props: ImportPlayersFormProps) {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async ({ data }: { data: string }) => {
      await PlayerAPI.import({
        hallId: props.hallId,
        format: "csv",
        data,
      });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "Players imported successfully",
        }),
      });

      eventBusService.publish(new PlayersImported({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hallId]);

  function handleUpload() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = async () => {
      const data = await input.files?.[0]?.text();
      if (!data) {
        return;
      }
      mutation.submit({ data });
    };
    input.click();
  }

  return { ...props, intl, handleUpload, mutation };
}

export type ImportPlayersFormState = ReturnType<
  typeof useImportPlayersFormState
>;
