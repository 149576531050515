import { TWebsite } from "..";
import MethodBuilder from "../method-builder";

class WebsiteAPI {
  static filter = new MethodBuilder<
    (
      | { clientId: number }
      | { hallId: number }
      | { agentId: number }
      | { kioskId: number }
    ) & {
      searchQuery?: string;
    },
    {
      data: TWebsite[];
      total: number;
    }
  >()
    .withName("Website.filter")
    .build();

  static grantAccess = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Website.grantAccess")
    .build();

  static revokeAccess = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Website.revokeAccess")
    .build();

  static enable = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Website.enable")
    .build();

  static disable = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      ids: number[];
    },
    void
  >()
    .withName("Website.disable")
    .build();

  static setPriority = new MethodBuilder<
    {
      hallId: number;
      websiteId: number | null;
    },
    void
  >()
    .withName("Website.setPriority")
    .build();

  static getAll = new MethodBuilder<void, { data: TWebsite[] }>()
    .withName("Website.getAll")
    .build();

  static getById = new MethodBuilder<
    { id: number },
    {
      id: number;
      domain: string;
      imgPackId: number;
      title: string;
      templateId: number;
    }
  >()
    .withName("Website.getById")
    .build();

  static import = new MethodBuilder<{ format: "csv"; data: string }, void>()
    .withName("Website.import")
    .build();

  static export = new MethodBuilder<{ format: "csv" }, { data: string }>()
    .withName("Website.export")
    .build();

  static create = new MethodBuilder<
    {
      domain: string;
      imgPackId: number;
      title: string;
      templateId: number;
    },
    {
      id: number;
      domain: string;
      imgPackId: number;
      title: string;
      templateId: number;
    }
  >()
    .withName("Website.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      imgPackId?: number;
      title?: string;
      templateId?: number;
    },
    void
  >()
    .withName("Website.update")
    .build();
}

export default WebsiteAPI;
