import { UseFormReturn } from "react-hook-form";

import { ReceiptEditorSchema } from "./editor.schema";
import { useLineEditorState } from "./line-editor.state";
import { LineEditorView } from "./line-editor.view";

export interface LineEditorProps {
  index: number;
  form: UseFormReturn<ReceiptEditorSchema>;
}

export function LineEditor(props: LineEditorProps) {
  const state = useLineEditorState(props);
  return <LineEditorView state={state} />;
}
