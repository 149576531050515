import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { CloseReceiptJSONModal, OpenReceiptJSONModal } from "./events";
import { JSONForm } from "./json-form";

export const JSONModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      title={intl.formatMessage({ defaultMessage: "JSON" })}
      openEvent={OpenReceiptJSONModal}
      closeEvent={CloseReceiptJSONModal}
    >
      {({ uniqueLines }) => <JSONForm uniqueLines={uniqueLines} />}
    </EventBasedModal>
  );
});
