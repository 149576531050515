import { observer } from "mobx-react-lite";

import { useAddKioskFormState } from "./add-kiosk-form.state";
import { AddKioskFormView } from "./add-kiosk-form.view";

export interface AddKioskFormProps {
  hallId: number;
}

export const AddKioskForm = observer((props: AddKioskFormProps) => {
  const state = useAddKioskFormState(props);
  return <AddKioskFormView state={state} />;
});
