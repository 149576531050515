import { observer } from "mobx-react-lite";

import { useEditWebsiteDomainFormState } from "./form.state";
import { EditWebsiteDomainFormView } from "./form.view";

export type EditWebsiteDomainFormProps = {
  id: number;
};

export const EditWebsiteDomainForm = observer<EditWebsiteDomainFormProps>(
  (props) => {
    const state = useEditWebsiteDomainFormState(props);
    return <EditWebsiteDomainFormView state={state} />;
  },
);
