import { makeAutoObservable } from "mobx";

import { RouterService } from "@/router";
import { EventBusService, IntlService } from "@/services";

import { CurrencyStore, PermissionsStore, UserStore } from "..";

export class GlobalStore {
  constructor() {
    makeAutoObservable(this);
  }

  intlService = new IntlService();
  routerService = new RouterService();
  currencyStore = new CurrencyStore();
  eventBusService = new EventBusService();
  userStore = new UserStore(this);
  permissionsStore = new PermissionsStore(this.userStore);
}
