import { Tabs, TabsProps } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { HallAPI, PermissionsAPI } from "@/api";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { ConfiguredByParentProvider } from "@/ui/_common_/configured-by-parent-alert";
import { ErrorHandler } from "@/ui/_common_/error-handler";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { Bonuses } from "@/ui/bonuses";
import { Games } from "@/ui/games";
import { JackpotSettingsForm } from "@/ui/jackpots";
import { HallLobbySettings } from "@/ui/lobby";
import { Sweepstakes } from "@/ui/sweepstakes";
import { Websites } from "@/ui/websites";
import { isTruthy } from "@/utilities";

import { HallOtherSettings } from "./hall-other-settings";
import { HallSettingsForm } from "./hall-settings-form";
import { HallSpecialSettings } from "./hall-special-settings";

interface Props {
  hallId: number;
  setHallName(name: string): void;
}

export const HallSettingsTabs = observer(({ hallId, setHallName }: Props) => {
  const intl = useIntl();

  const role = useMemo(() => {
    const { userStore } = useGlobalStore();
    return userStore.user.role;
  }, []);

  const hallQuery = useMemo(() => {
    const query = new Query(async () => {
      return await HallAPI.getById({ id: hallId });
    });
    query.submit({});
    return query;
  }, [hallId]);

  const permissionsQuery = useMemo(() => {
    const query = new Query(PermissionsAPI.get);
    query.submit({ hallId });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (permissionsQuery.isIdle || permissionsQuery.isPending) {
    return <FormSpinner />;
  }

  if (permissionsQuery.isRejected) {
    return <ErrorsFormatter queries={[permissionsQuery]} />;
  }

  return (
    <Tabs
      destroyInactiveTabPane={true}
      onChange={() => hallQuery.submit({})}
      items={(
        [
          {
            key: "general",
            label: intl.formatMessage({ defaultMessage: "General" }),
            children: (
              <HallSettingsForm hallId={hallId} setHallName={setHallName} />
            ),
          },
          {
            key: "lobby",
            label: intl.formatMessage({ defaultMessage: "Lobby" }),
            children: <HallLobbySettings hallId={hallId} />,
          },
          {
            key: "websites",
            label: intl.formatMessage({ defaultMessage: "Websites" }),
            children: <Websites.EditForm entity={{ hallId }} />,
          },
          {
            key: "games",
            label: intl.formatMessage({ defaultMessage: "Games" }),
            children: <Games.EditForm entity={{ hallId }} />,
          },
          {
            key: "bonuses",
            label: intl.formatMessage({ defaultMessage: "Bonuses" }),
            className: "subtabs",
            children: (
              <ConfiguredByParentProvider
                value={hallQuery.data?.configuredByParent ?? false}
              >
                <Bonuses.Tabs entity={{ hallId }} />
              </ConfiguredByParentProvider>
            ),
          },
          permissionsQuery.data.jackpots && {
            key: "jackpots",
            label: intl.formatMessage({ defaultMessage: "Jackpots" }),
            children: (
              <ConfiguredByParentProvider
                value={hallQuery.data?.configuredByParent ?? false}
              >
                <JackpotSettingsForm entity={{ hallId }} />
              </ConfiguredByParentProvider>
            ),
          },
          permissionsQuery.data.sweepstakes && {
            key: "sweepstakes",
            label: intl.formatMessage({ defaultMessage: "Sweepstakes" }),
            children: <Sweepstakes.EditForm entity={{ hallId }} />,
          },
          {
            key: "other",
            label: intl.formatMessage({ defaultMessage: "Other" }),
            children: <HallOtherSettings hallId={hallId} />,
          },
          ["Admin", "Manager"].includes(role) && {
            key: "special",
            label: "Special",
            children: (
              <HallSpecialSettings
                hallId={hallId}
                currency={hallQuery.data?.currency}
              />
            ),
          },
        ] satisfies Array<NonNullable<TabsProps["items"]>[0] | false>
      )
        .filter(isTruthy)
        .map((tab) => ({
          ...tab,
          children: <ErrorHandler>{tab.children}</ErrorHandler>,
        }))}
    />
  );
});
