import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { DefaultOptionType } from "antd/lib/select";
import { observer } from "mobx-react-lite";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

interface Props<FV extends FieldValues> {
  form: UseFormReturn<FV>;
  path: Path<FV>;
  options: DefaultOptionType[];
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  showSearch?: boolean;
  size?: SizeType;
  allowClear?: boolean;
}

export const FormSelect = observer(
  <FV extends FieldValues>({
    form,
    path,
    options,
    placeholder,
    isLoading = false,
    disabled = false,
    size = "middle",
    allowClear = false,
  }: Props<FV>) => {
    return (
      <Controller
        control={form.control}
        name={path}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value === "" ? undefined : field.value}
            options={options}
            placeholder={placeholder}
            loading={isLoading}
            disabled={disabled}
            size={size}
            allowClear={allowClear}
          />
        )}
      />
    );
  },
);
