import { notification } from "antd";
import { lowerCase, template as compileTemplate, upperFirst } from "lodash-es";
import { makeAutoObservable } from "mobx";

import { HallAPI, PlayerAPI } from "@/api";
import { PlayerBalanceWithdrawn } from "@/events";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { EventBusService, IntlService, PrintService } from "@/services";
import { UserStore } from "@/stores";
import { CurrencyUtilities } from "@/utilities";

import template from "../../receipt.html";

import { Props } from "./Form";
import { ISchema } from "./schema";

const compiledTemplate = compileTemplate(template);

export class FormState implements ViewModel<Props> {
  constructor(
    private eventBusService: EventBusService,
    private intlService: IntlService,
    private userStore: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hallQuery = new Query(HallAPI.getById);

  get hall() {
    return this.hallQuery.data!;
  }

  playerQuery = new Query(PlayerAPI.getById);

  get player() {
    return this.playerQuery.data!;
  }

  private _kioskId?: number;

  private get kioskId() {
    return this._kioskId;
  }

  private set kioskId(id: number | undefined) {
    this._kioskId = id;
  }

  get isIdleOrPending() {
    return (
      this.hallQuery.isIdle ||
      this.hallQuery.isPending ||
      this.playerQuery.isIdle ||
      this.playerQuery.isPending
    );
  }

  get isRejected() {
    return this.hallQuery.isRejected || this.playerQuery.isRejected;
  }

  get currentBalance() {
    if (!this.player) {
      return 0;
    }
    return this.player.balance ?? this.player.sweepstake?.win ?? 0;
  }

  get availableBalance() {
    if (!this.player) {
      return 0;
    }

    if (this.player.sweepstake?.redeem !== undefined) {
      return this.player.sweepstake.redeem;
    }

    if (this.player.sweepstake?.win !== undefined) {
      return this.player.sweepstake.win;
    }

    const balance = this.player.balance;

    if (typeof balance !== "number") {
      return 0;
    }

    if (this.player.bonus === null) {
      return balance;
    }

    if (this.player.bonus.remainingWager === 0) {
      return balance;
    }

    return Math.max(balance - this.player.bonus.amount, 0);
  }

  get isBonusAlertShown() {
    if (this.player.balance === null) {
      return false;
    }

    return this.player.bonus?.remainingWager !== 0;
  }

  onViewMount = async ({ playerId, kioskId }: Props) => {
    this.kioskId = kioskId;
    await this.playerQuery.submit({ id: playerId });
    await this.hallQuery.submit({ id: this.player!.hallId });
  };

  onViewUnmount = () => {
    this.kioskId = undefined;
    this.hallQuery.reset();
    this.playerQuery.reset();
  };

  withdrawQuery = new Query(PlayerAPI.withdrawMoney);

  get isWithdrawPending() {
    return (
      this.withdrawQuery.isPending ||
      this.userStore.cashierQuery.isPending ||
      this.userStore.shiftQuery.isPending
    );
  }

  handleSubmit = async ({ amount }: ISchema) => {
    if (!this.player) {
      return;
    }

    await this.withdrawQuery.submit({
      id: this.player.id,
      kioskId: this.kioskId,
      amount: CurrencyUtilities.toMinorUnits(amount),
    });

    if (!this.withdrawQuery.isFulfilled) {
      return;
    }

    const promises = [
      this.userStore.hall && this.userStore.fetchHall(this.userStore.hall.id),
      this.userStore.cashier &&
        this.userStore.fetchCashier(this.userStore.cashier.id),
    ];

    await Promise.all(promises);

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage:
          "The money has been successfully withdrawn from the balance.",
      }),
    });

    if (this.hall.autoPrintReceipts) {
      const html = compiledTemplate({
        type: "withdraw",
        date: new Date().toLocaleString(),
        player: this.player?.login,
        amount: amount,
        balance: CurrencyUtilities.toMainUnits(this.player.balance ?? 0),
        currency: this.player.currency,
        role: upperFirst(lowerCase(this.userStore.user.role)),
        user: this.userStore.user.name,
      });
      PrintService.print(html);
    }

    this.eventBusService.publish(
      new PlayerBalanceWithdrawn({ playerId: this.player.id }),
    );
  };
}
