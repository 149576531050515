import MethodBuilder from "../method-builder";
import { TSportCoupon } from "../types";

export class SportCouponAPI {
  static getByUid = new MethodBuilder<
    {
      uid: string;
    },
    TSportCoupon
  >()
    .withName("SportCoupon.getByUid")
    .build();

  static payout = new MethodBuilder<
    {
      uid: string;
      byCash: boolean;
    },
    {
      amount: number;
      currency: string;
    }
  >()
    .withName("SportCoupon.payout")
    .build();
}
