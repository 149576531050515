import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { pick } from "lodash-es";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { HallAPI, KioskAPI } from "@/api";
import { CloseEditKioskModal } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { omitNulls } from "@/utilities";

import { KioskSettingsFormProps } from "./kiosk-settings-form";
import {
  KioskSettingsFormSchema,
  useKioskSettingsFormSchema,
} from "./kiosk-settings-form.schema";

export function useKioskSettingsFormState({
  kioskId,
  hallId,
  setKioskName,
}: KioskSettingsFormProps) {
  const intl = useIntl();

  const schema = useKioskSettingsFormSchema();
  const form = useForm<KioskSettingsFormSchema>({
    resolver: yupResolver(schema),
  });

  const { kioskQuery, hallQuery } = useMemo(() => {
    const hallQuery = new Query(HallAPI.getById);
    const kioskQuery = new Query(KioskAPI.getById);
    hallQuery.submit({ id: hallId });
    kioskQuery.submit({ id: kioskId }).then(() => {
      const kiosk = kioskQuery.data!;
      setKioskName(kiosk.name);

      const paths = Object.keys(schema.fields);
      form.reset(omitNulls(pick(kiosk, paths)));
    });
    return { kioskQuery, hallQuery };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restartQuery = useMemo(() => {
    return new Query(() => KioskAPI.restart({ id: kioskId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: KioskSettingsFormSchema) => {
      await KioskAPI.update({ id: kioskId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The kiosk has been successfully updated.",
        }),
      });

      eventBusService.publish(new CloseEditKioskModal({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intl,
    form,
    kioskQuery,
    hallQuery,
    restartQuery,
    submitQuery,
  };
}

export type KioskSettingsFormState = ReturnType<
  typeof useKioskSettingsFormState
>;
