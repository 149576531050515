import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { UpdateAgent } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditAgentTabs } from "../tabs";

export const EditAgentModal = observer(() => {
  const intl = useIntl();
  const [agentName, setAgentName] = useState<string>();
  const title = useMemo(
    () =>
      agentName ? (
        <>
          {intl.formatMessage({ defaultMessage: "Edit Agent" })}:{" "}
          <Typography.Text type="secondary">{agentName}</Typography.Text>
        </>
      ) : (
        <>{intl.formatMessage({ defaultMessage: "Edit Agent" })}</>
      ),
    [intl, agentName],
  );
  return (
    <EventBasedModal
      title={title}
      openEvent={UpdateAgent}
      className="modal_with-tabs"
      onClose={() => setAgentName(undefined)}
    >
      {({ agentId }) => (
        <EditAgentTabs agentId={agentId} setAgentName={setAgentName} />
      )}
    </EventBasedModal>
  );
});
