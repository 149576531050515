import { PropsWithChildren } from "react";

import { ConfiguredByParentContext } from "./context";

interface Props {
  value: boolean;
}

export function ConfiguredByParentProvider({
  value,
  children,
}: PropsWithChildren<Props>) {
  return (
    <ConfiguredByParentContext.Provider value={value}>
      {children}
    </ConfiguredByParentContext.Provider>
  );
}
