import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { ReceiptAPI, TReceipt } from "@/api";
import Query from "@/models/query";

import { ReceiptListProps } from "./receipt-list";

export function useReceiptListState({ hallId }: ReceiptListProps) {
  const intl = useIntl();

  const [selectedReceipt, setSelectedReceipt] = useState<TReceipt>();

  const listQuery = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await ReceiptAPI.getList({ hallId });
    });
    query.submit();
    return query;
  }, [hallId]);

  const submitQuery = useMemo(() => {
    const query = new Query(async ({ lines }: { lines: TReceipt["lines"] }) => {
      if (!selectedReceipt) {
        return;
      }
      await ReceiptAPI.update({
        hallId,
        type: selectedReceipt.type,
        lines,
      });
    });
    return query;
  }, [hallId, selectedReceipt]);

  const cancelQuery = useMemo(() => {
    const query = new Query<void, void>(async () => {
      setSelectedReceipt(undefined);
    });
    return query;
  }, []);

  const addReceiptTask = useMemo(
    () =>
      new Query(async ({ type }: { type: string }) => {
        // @ts-ignore
        const receipt = await ReceiptAPI.getById({ hallId, type });
        setSelectedReceipt(receipt);
      }),

    [hallId],
  );

  return {
    hallId,

    intl,

    listQuery,
    submitQuery,
    cancelQuery,

    selectedReceipt,
    setSelectedReceipt,

    addReceiptTask,
  };
}

export type ReceiptListState = ReturnType<typeof useReceiptListState>;
