import { Button, Col, Form, Row, Table, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { TSportReportItem } from "@/api";
import { SportCouponAPI } from "@/api/modules/sport-coupon";
import { SportCouponPayout } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import MoneyFormatter from "@/ui/_common_/money-formatter";

type Props = {
  item: TSportReportItem;
};

export const DetailsForm = observer<Props>(({ item }) => {
  const intl = useIntl();

  const couponQuery = useMemo(() => {
    const query = new Query(async () => {
      return await SportCouponAPI.getByUid({ uid: item.uid });
    });
    query.submit({});
    return query;
  }, [item.uid]);

  const { eventBusService } = useGlobalStore();

  const payoutMutation = useMemo(() => {
    return new Query(async () => {
      await SportCouponAPI.payout({ uid: item.uid, byCash: true });
      couponQuery.submit({});
      eventBusService.publish(new SportCouponPayout({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (couponQuery.isIdle || couponQuery.isPending) {
    return <FormSpinner />;
  }

  if (couponQuery.isRejected) {
    return <ErrorsFormatter queries={[couponQuery]} />;
  }

  const coupon = couponQuery.data;

  return (
    <Form
      size="small"
      labelCol={{ span: 4 }}
      labelAlign="left"
      wrapperCol={{ span: 20 }}
      layout="horizontal"
    >
      <ErrorsFormatter queries={[payoutMutation]} />
      <Form.Item label={intl.formatMessage({ defaultMessage: "UID" })}>
        {item.uid}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Description" })}>
        <Typography.Text ellipsis>{item.description}</Typography.Text>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Odds" })}>
        {item.odds}
      </Form.Item>
      <Form.Item
        hidden={!item.oddsBefore}
        label={intl.formatMessage({ defaultMessage: "Odds Before" })}
      >
        {item.oddsBefore}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Bet Type" })}>
        {item.type === "multiple"
          ? intl.formatMessage({ defaultMessage: "Multiple" })
          : intl.formatMessage({ defaultMessage: "Single" })}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Bet" })}>
        <MoneyFormatter cents={item.bet} />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Win" })}>
        <Row gutter={[12, 12]}>
          <Col>
            <MoneyFormatter cents={item.win} />
          </Col>
          {coupon.payoutAvailable && (
            <Col>
              <Button
                type="primary"
                size="small"
                loading={payoutMutation.isPending}
                onClick={() => payoutMutation.submit({})}
              >
                {intl.formatMessage({ defaultMessage: "Payout" })}
              </Button>
            </Col>
          )}
        </Row>
      </Form.Item>
      {item.bonusAmount > 0 && (
        <>
          <Form.Item label={intl.formatMessage({ defaultMessage: "Bonus" })}>
            <MoneyFormatter cents={item.bonusAmount} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Total Win" })}
          >
            <MoneyFormatter cents={item.totalWin} />
          </Form.Item>
        </>
      )}
      <Form.Item label={intl.formatMessage({ defaultMessage: "Reference" })}>
        {item.reference}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Status" })}>
        <div
          className={classNames({
            ["ant-table-row-color-red"]: item.outcomeStatus === "lost",
            ["ant-table-row-color-green"]: item.outcomeStatus === "win",
          })}
        >
          {item.status} {item.outcomeStatus && <b>({item.outcomeStatus})</b>}
        </div>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ defaultMessage: "Payout" })}>
        {item.isPayed
          ? intl.formatMessage({ defaultMessage: "Yes" })
          : intl.formatMessage({ defaultMessage: "No" })}
      </Form.Item>
      <Table
        size="small"
        dataSource={coupon.events}
        bordered
        columns={[
          {
            title: intl.formatMessage({ defaultMessage: "Date" }),
            dataIndex: "eventDate",
            render: (value: string) =>
              new Date(value).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
          },
          {
            title: intl.formatMessage({ defaultMessage: "Name" }),
            dataIndex: "name",
            width: "100%",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Odds" }),
            dataIndex: "odds",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Sport" }),
            dataIndex: "sport",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Category" }),
            dataIndex: "categoryName",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Market" }),
            dataIndex: "marketName",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Sel" }),
            dataIndex: "selName",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Status" }),
            dataIndex: "status",
            render: (value: string) => value ?? "—",
          },
        ]}
        pagination={false}
      />
    </Form>
  );
});
