import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space, Switch } from "antd";
import { Empty } from "antd/es";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  if (state.isIdleOrPending) {
    return <FormSpinner />;
  }

  if (state.isRejected) {
    return (
      <ErrorsFormatter
        queries={[
          state.clientQuery,
          state.agentQuery,
          state.parentAgentQuery,
          state.hallQuery,
        ]}
      />
    );
  }

  if (!state.hasAccess) {
    return <Empty />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.updateQuery]} />
      <Form
        labelCol={{ span: 10 }}
        labelAlign="left"
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item
          label={intl.formatMessage({
            defaultMessage: "Show contacts",
          })}
          validateStatus={
            has(form.formState.errors, "isContactsShown") ? "error" : ""
          }
          help={form.formState.errors.isContactsShown?.message}
        >
          <Controller
            control={form.control}
            name="isContactsShown"
            defaultValue={state.defaultIsContactsShown}
            render={({ field }) => (
              <Switch
                checked={field.value}
                onChange={field.onChange}
                disabled={state.readOnly}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Contacts" })}
          validateStatus={has(form.formState.errors, "contacts") ? "error" : ""}
          help={form.formState.errors.contacts?.message}
        >
          <Controller
            control={form.control}
            name="contacts"
            defaultValue={state.defaultContacts}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                value={field.value ?? undefined}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter contacts",
                })}
                readOnly={state.readOnly}
              />
            )}
          />
        </Form.Item>
        {!state.readOnly && (
          <Row justify="end" gutter={12}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={state.updateQuery.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Save" })}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Space>
  );
});
