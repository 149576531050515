import {
  AlertOutlined,
  CaretDownOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  notification,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Typography,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { Observer, observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { APIError } from "@/api";
import { UpdateHall } from "@/events";
import { useCurrencyFilters } from "@/hooks";
import { HallsStore, HallStore, useGlobalStore } from "@/stores";
import { AncestorsTreeButton } from "@/ui/_common_/ancestors-tree";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";
import { DeleteAction } from "@/ui/delete-action";

import DepositModal from "../hall-deposit-modal";
import WithdrawModal from "../hall-withdraw-modal";

type TProps = {
  hallsStore: HallsStore;
};

function HallsTable({ hallsStore: state }: TProps) {
  const { eventBusService, userStore } = useGlobalStore();

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<HallStore> | SorterResult<HallStore>[],
    ) => {
      if (typeof filters["hall.currency"]?.[0] === "string") {
        state.setCurrencyCode(filters["hall.currency"][0]);
      } else {
        state.setCurrencyCode(undefined);
      }
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        (sorter.columnKey === "id" || sorter.columnKey === "name")
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
      state.filter();
    },
    [state],
  );
  const currencyFilters = useCurrencyFilters();

  const intl = useIntl();
  const { formatMessage: fm } = intl;

  return (
    <Spin spinning={state.filterQuery.isPending}>
      <Space direction="vertical">
        <Row>
          <Col xs={0} sm={24}>
            <Table
              dataSource={state.hallStores}
              showHeader={!!state.hallStores.length}
              size="small"
              rowKey={(hallStore) => hallStore.hall.id}
              bordered
              pagination={false}
              components={{ body: { row: HallsTableRow } }}
              onChange={handleTableChange}
            >
              <Table.Column
                key="id"
                title={intl.formatMessage({
                  defaultMessage: "ID",
                })}
                dataIndex={["hall", "id"]}
                align="right"
                sorter={true}
                defaultSortOrder={
                  state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
                }
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="agent"
                title={intl.formatMessage({
                  defaultMessage: "Agent",
                })}
                dataIndex={["hall", "agentName"]}
                align="left"
                render={(agentName) => (!agentName ? "—" : agentName)}
              />
              <Table.Column
                key="login"
                title={intl.formatMessage({
                  defaultMessage: "Admin",
                })}
                dataIndex={["hall", "login"]}
                align="left"
                render={(_, hallStore: HallStore) => {
                  return (
                    <Row gutter={[8, 8]} wrap={false}>
                      <Col>
                        <AncestorsTreeButton
                          entity={{ hallId: hallStore.hall.id }}
                        />
                      </Col>
                      <Col>{hallStore.hall.login}</Col>
                    </Row>
                  );
                }}
              />
              <Table.Column
                key="name"
                title={intl.formatMessage({ defaultMessage: "Name" })}
                dataIndex={["hall", "name"]}
                width="100%"
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
                render={(name: string, hallStore: HallStore) => (
                  <Observer>
                    {() => (
                      <>
                        {name}{" "}
                        {!!hallStore.hall.deletedAt && (
                          <Tag>
                            {intl.formatMessage({ defaultMessage: "DELETED" })}
                          </Tag>
                        )}
                        {["Admin", "Manager"].includes(userStore.user.role) &&
                          hallStore.isSweepstakesEnabled && (
                            <Tag color="green">
                              {intl.formatMessage({
                                defaultMessage: "Sweepstakes",
                              })}
                            </Tag>
                          )}
                        {hallStore.hall.lobbySettings.isPanicEnabled && (
                          <AlertOutlined style={{ color: "red" }} />
                        )}
                      </>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Balance" })}
                dataIndex={["hall", "balance"]}
                align="right"
                render={(_, hallStore: HallStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8} justify="end">
                        <Col>
                          <WithdrawModal
                            hallWithdrawStore={hallStore.withdrawStore}
                          />
                          <Button
                            size="small"
                            icon={<MinusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Withdraw",
                            })}
                            onClick={async () => {
                              await hallStore.fetch();
                              hallStore.withdrawStore.modal.open();
                            }}
                            disabled={hallStore.hall.isBalanceUnlimited}
                          />
                        </Col>
                        <Col flex="auto">
                          <MoneyFormatter
                            cents={hallStore.hall.balance}
                            isInfinity={hallStore.hall.isBalanceUnlimited}
                          />
                        </Col>
                        <Col>
                          <DepositModal
                            hallDepositStore={hallStore.depositStore}
                          />
                          <Button
                            size="small"
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Deposit",
                            })}
                            onClick={async () => {
                              await hallStore.fetch();
                              hallStore.depositStore.modal.open();
                            }}
                            disabled={hallStore.hall.isBalanceUnlimited}
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex={["hall", "currency"]}
                filters={currencyFilters}
                filterMultiple={false}
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Actions" })}
                render={(_, hallStore: HallStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            icon={<ReloadOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Reload",
                            })}
                            onClick={async () => {
                              await hallStore.fetch();
                              if (hallStore.fetchQuery.isRejected) {
                                const { error } = hallStore.fetchQuery;
                                if (error instanceof APIError) {
                                  notification.error({
                                    message: fm({
                                      id: `api_errors/code_${error.code}`,
                                    }),
                                  });
                                }
                              }
                            }}
                          />
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            icon={<EditOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Edit",
                            })}
                            onClick={() => {
                              eventBusService.publish(
                                new UpdateHall({ hallId: hallStore.hall.id }),
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <DeleteAction
                            size="small"
                            entity={{ hallId: hallStore.hall.id }}
                            isDeleted={!!hallStore.hall.deletedAt}
                            onSuccess={state.filter}
                          />
                        </Col>
                        <Col style={{ marginLeft: "auto" }}>
                          <Link
                            to={{
                              pathname: "/cashiers",
                              search: new URLSearchParams({
                                cid: hallStore.hall.clientId.toString(),
                                aid: hallStore.hall.agentId?.toString() ?? "",
                                hid: hallStore.hall.id.toString(),
                                action: "new",
                              }).toString(),
                            }}
                          >
                            <Button size="small" icon={<PlusOutlined />}>
                              {intl.formatMessage({
                                defaultMessage: "Cashier",
                              })}
                            </Button>
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            to={{
                              pathname: "/players",
                              search: new URLSearchParams({
                                cid: hallStore.hall.clientId.toString(),
                                aid: hallStore.hall.agentId?.toString() ?? "",
                                hid: hallStore.hall.id.toString(),
                                action: "new",
                              }).toString(),
                            }}
                          >
                            <Button size="small" icon={<PlusOutlined />}>
                              {intl.formatMessage({
                                defaultMessage: "Player",
                              })}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Cashiers",
                })}
                align="right"
                render={(_, hallStore: HallStore) => (
                  <Link
                    to={{
                      pathname: "/cashiers",
                      search: new URLSearchParams({
                        cid: hallStore.hall.clientId.toString(),
                        aid: hallStore.hall.agentId?.toString() ?? "",
                        hid: hallStore.hall.id.toString(),
                      }).toString(),
                    }}
                  >
                    {hallStore.hall.cashiersCount}
                  </Link>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Players",
                })}
                align="right"
                render={(_, hallStore: HallStore) => (
                  <Link
                    to={{
                      pathname: "/players",
                      search: new URLSearchParams({
                        cid: hallStore.hall.clientId.toString(),
                        aid: hallStore.hall.agentId?.toString() ?? "",
                        hid: hallStore.hall.id.toString(),
                      }).toString(),
                    }}
                  >
                    {hallStore.hall.playersCount}
                  </Link>
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Kiosks",
                })}
                align="right"
                render={(_, hallStore: HallStore) => (
                  <Link
                    to={{
                      pathname: "/kiosks",
                      search: new URLSearchParams({
                        cid: hallStore.hall.clientId.toString(),
                        aid: hallStore.hall.agentId?.toString() ?? "",
                        hid: hallStore.hall.id.toString(),
                      }).toString(),
                    }}
                  >
                    {hallStore.hall.kiosksCount}
                  </Link>
                )}
              />
            </Table>
          </Col>
          <Col xs={24} sm={0}>
            {!state.hallStores.length && <Empty />}
            <Space direction="vertical">
              {state.hallStores.map((hallStore) => (
                <Card
                  key={hallStore.hall.id}
                  size="small"
                  className={classNames({
                    "ant-table-row-color-gray": !!hallStore.hall.deletedAt,
                  })}
                >
                  <Row gutter={[8, 8]}>
                    {!!hallStore.hall.deletedAt && (
                      <Tag>
                        {intl.formatMessage({ defaultMessage: "DELETED" })}
                      </Tag>
                    )}
                    {["Admin", "Manager"].includes(userStore.user.role) &&
                      hallStore.isSweepstakesEnabled && (
                        <Tag color="green">
                          {intl.formatMessage({
                            defaultMessage: "Sweepstakes",
                          })}
                        </Tag>
                      )}
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col
                      flex={1}
                      style={{
                        fontWeight: "bold",
                        color: hallStore.hall.isBlocked ? "red" : "",
                      }}
                    >
                      {hallStore.hall.name}{" "}
                      {!!hallStore.hall.lobbySettings.isPanicEnabled && (
                        <AlertOutlined style={{ color: "red" }} />
                      )}
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {hallStore.hall.id}
                    </Col>
                  </Row>
                  {hallStore.hall.name && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({ defaultMessage: "Admin" })}:{" "}
                        {hallStore.hall.login}
                      </Col>
                    </Row>
                  )}
                  {hallStore.hall.agentName && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({ defaultMessage: "Agent" })}:{" "}
                        {hallStore.hall.agentName}
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[16, 8]}>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Cashiers" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/cashiers",
                          search: new URLSearchParams({
                            cid: hallStore.hall.clientId.toString(),
                            aid: hallStore.hall.agentId?.toString() ?? "",
                            hid: hallStore.hall.id.toString(),
                          }).toString(),
                        }}
                      >
                        {hallStore.hall.cashiersCount}
                      </Link>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Players" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/players",
                          search: new URLSearchParams({
                            cid: hallStore.hall.clientId.toString(),
                            aid: hallStore.hall.agentId?.toString() ?? "",
                            hid: hallStore.hall.id.toString(),
                          }).toString(),
                        }}
                      >
                        {hallStore.hall.playersCount}
                      </Link>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                      <Typography.Text strong>
                        {intl.formatMessage({ defaultMessage: "Kiosks" })}
                      </Typography.Text>
                      <br />
                      <Link
                        to={{
                          pathname: "/kiosks",
                          search: new URLSearchParams({
                            cid: hallStore.hall.clientId.toString(),
                            aid: hallStore.hall.agentId?.toString() ?? "",
                            hid: hallStore.hall.id.toString(),
                          }).toString(),
                        }}
                      >
                        {hallStore.hall.kiosksCount}
                      </Link>
                    </Col>
                  </Row>
                  <Divider type="horizontal" style={{ margin: "8px 0" }} />
                  <Row wrap={false} gutter={[8, 8]} align="middle">
                    <Col>
                      <WithdrawModal
                        hallWithdrawStore={hallStore.withdrawStore}
                      />
                      <Button
                        icon={<MinusOutlined />}
                        title={intl.formatMessage({
                          defaultMessage: "Withdraw",
                        })}
                        onClick={async () => {
                          await hallStore.fetch();
                          hallStore.withdrawStore.modal.open();
                        }}
                        disabled={hallStore.hall.isBalanceUnlimited}
                      />
                    </Col>
                    <Col flex={1} style={{ textAlign: "right" }}>
                      <MoneyFormatter
                        cents={hallStore.hall.balance}
                        isInfinity={hallStore.hall.isBalanceUnlimited}
                      />{" "}
                      {hallStore.hall.currency}
                    </Col>
                    <Col>
                      <DepositModal hallDepositStore={hallStore.depositStore} />
                      <Button
                        icon={<PlusOutlined />}
                        title={intl.formatMessage({
                          defaultMessage: "Deposit",
                        })}
                        onClick={async () => {
                          await hallStore.fetch();
                          hallStore.depositStore.modal.open();
                        }}
                        disabled={hallStore.hall.isBalanceUnlimited}
                      />
                    </Col>
                    <Col>
                      <Divider type="vertical" />
                    </Col>
                    <Col>
                      <Button
                        icon={<ReloadOutlined />}
                        title={intl.formatMessage({ defaultMessage: "Reload" })}
                        onClick={hallStore.fetch}
                      />
                    </Col>
                    <Col>
                      <Popover
                        trigger="click"
                        zIndex={999}
                        content={
                          <Row gutter={[16, 8]}>
                            <Col>
                              <AncestorsTreeButton
                                size="middle"
                                entity={{ hallId: hallStore.hall.id }}
                              />
                            </Col>
                            <Col>
                              <Button
                                icon={<EditOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "Edit",
                                })}
                                onClick={() => {
                                  eventBusService.publish(
                                    new UpdateHall({
                                      hallId: hallStore.hall.id,
                                    }),
                                  );
                                }}
                              />
                            </Col>
                            <Col>
                              <Button
                                icon={<AlertOutlined />}
                                onClick={hallStore.togglePanicQuery.submit}
                                loading={hallStore.togglePanicQuery.isPending}
                              />
                            </Col>
                            <Col>
                              <DeleteAction
                                entity={{ hallId: hallStore.hall.id }}
                                isDeleted={!!hallStore.hall.deletedAt}
                                onSuccess={state.filter}
                              />
                            </Col>
                          </Row>
                        }
                        placement="bottomRight"
                      >
                        <Button icon={<CaretDownOutlined />} />
                      </Popover>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          query={state.filterQuery}
          onChange={() => {
            state.filter({ preservePageNumber: true });
          }}
        />
      </Space>
    </Spin>
  );
}

type TChild = { props: { record: HallStore } };

type THallsTableRowProps = {
  className: string;
  children: TChild | TChild[];
};

const HallsTableRow = observer(function HallsTableRow(
  props: THallsTableRowProps,
) {
  if (!Array.isArray(props.children)) {
    return <tr {...props} />;
  }
  const hallStore = props.children[0].props.record;
  if (hallStore.fetchQuery.isPending || hallStore.updateQuery.isPending) {
    return (
      <tr className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>
    );
  }
  return (
    <tr
      {...props}
      className={classNames(props.className, {
        "ant-table-row-color-red": hallStore.hall.isBlocked,
        "ant-table-row-color-gray": !!hallStore.hall.deletedAt,
      })}
    />
  );
});

export default observer(HallsTable);
