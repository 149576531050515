import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Form,
  Input,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useResetForm } from "@/hooks";
import { TVoucherRedeemSchema, useVoucherRedeemSchema } from "@/schemas";
import { VoucherRedeemStore } from "@/stores/vouchers";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";

type TProps = {
  voucherRedeemStore: VoucherRedeemStore;
};

function VoucherRedeemForm({ voucherRedeemStore }: TProps) {
  const schema = useVoucherRedeemSchema();
  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<TVoucherRedeemSchema>({
    resolver,
  });

  useResetForm(voucherRedeemStore.redeemQuery, reset);

  const intl = useIntl();

  if (voucherRedeemStore.redeemQuery.isFulfilled) {
    return (
      <>
        <Result
          status="success"
          title={
            <Typography.Title level={5}>
              {intl.formatMessage({
                defaultMessage: "The voucher has been successfully redeemed.",
              })}
            </Typography.Title>
          }
          subTitle={
            <Typography.Title level={4}>
              <MoneyFormatter
                cents={voucherRedeemStore.redeemQuery.data.amount}
              />
              {" " + voucherRedeemStore.redeemQuery.data.currency}
            </Typography.Title>
          }
        />
        <Row justify="end" gutter={12}>
          <Col>
            <Button onClick={voucherRedeemStore.closeModal}>
              {intl.formatMessage({ defaultMessage: "OK" })}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Spin spinning={voucherRedeemStore.redeemQuery.isPending}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[voucherRedeemStore.redeemQuery]} />
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleSubmit(voucherRedeemStore.handleSubmit)}
        >
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "CVV" })}
            validateStatus={has(errors, "cvv") ? "error" : ""}
            help={errors.cvv?.message}
          >
            <Controller
              control={control}
              name="cvv"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter CVV",
                  })}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "PIN" })}
            validateStatus={has(errors, "pin") ? "error" : ""}
            help={errors.pin?.message}
          >
            <Controller
              control={control}
              name="pin"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter PIN",
                  })}
                />
              )}
            />
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button onClick={voucherRedeemStore.closeModal}>
                {intl.formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({ defaultMessage: "Redeem" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
}

export default observer(VoucherRedeemForm);
