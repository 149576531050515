import { useCallback } from "react";

import Query from "@/models/query";

type TExportParameters = {
  exportQuery: Query<{ format: "csv" }, { data: string }>;
  fileName: string;
};

export function useExportHandler({ exportQuery, fileName }: TExportParameters) {
  return useCallback(async () => {
    await exportQuery.submit({ format: "csv" });

    if (!exportQuery.isFulfilled) {
      return;
    }

    const { data } = exportQuery.data;
    const blob = new Blob([data], {
      type: "text/csv",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }, [exportQuery, fileName]);
}
