import { makeAutoObservable } from "mobx";

import { GlobalSearchAPI, TGlobalSearchEntityType } from "@/api";
import Query from "@/models/query";

export class GlobalSearchState {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _searchBy: TGlobalSearchEntityType[] = [
    "Agent",
    "Hall",
    "Cashier",
    "Player",
    "Kiosk",
  ];

  get searchBy() {
    return this._searchBy;
  }

  set searchBy(value: TGlobalSearchEntityType[]) {
    this._searchBy = value;
  }

  private _searchQuery = "";

  get searchQuery() {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    this._searchQuery = value;
  }

  filterQuery = new Query(GlobalSearchAPI.filter);

  fetch = async () => {
    await this.filterQuery.submit({
      searchQuery: this.searchQuery,
      searchBy: this.searchBy,
      paging: {
        limit: 100,
        offset: 0,
      },
    });
  };
}
