import { useIntl } from "react-intl";

import yup from "@/yup";

export function useClientLobbySettingsSchema() {
  const intl = useIntl();
  const t7e = intl.formatMessage;
  return yup.object({
    lobbySettings: yup.object({
      isContactsShown: yup.boolean(),
      languages: yup.array(yup.string().required()),
      defaultLang: yup
        .string()
        .test(
          "default-language",
          t7e({ id: "api_errors/code_8001" }),
          function (defaultLanguage = "") {
            const languages: string[] = this.parent.languages ?? [];
            return languages.includes(defaultLanguage);
          },
        ),
      directRegistration: yup.boolean().required(),
    }),
  });
}

export type ClientLobbySettingsSchema = yup.InferType<
  ReturnType<typeof useClientLobbySettingsSchema>
>;
