import MethodBuilder from "../method-builder";
import { TPaging } from "../types";

type TSportPartner = {
  id: number;
  currency: string;
  description: string;
};

export class SportPartnerAPI {
  static getById = new MethodBuilder<{ id: number }, TSportPartner>()
    .withName("SportPartner.getById")
    .build();

  static create = new MethodBuilder<
    {
      id: number;
      currency: string;
      description: string;
    },
    TSportPartner
  >()
    .withName("SportPartner.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      description: string;
    },
    void
  >()
    .withName("SportPartner.update")
    .build();

  static filter = new MethodBuilder<
    {
      // partner id, currency
      searchQuery?: string;
      currency?: string;
      paging: TPaging;
    },
    {
      data: TSportPartner[];
      total: number;
    }
  >()
    .withName("SportPartner.filter")
    .build();
}
