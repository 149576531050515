import { TDemoGame, TPaging } from "..";
import MethodBuilder from "../method-builder";

class DemoGameAPI {
  static filter = new MethodBuilder<
    {
      clientId: number;
      orderBy?: ["id" | "name" | "order", "asc" | "desc"];
      paging: TPaging;
    },
    {
      data: TDemoGame[];
      total: number;
    }
  >()
    .withName("DemoGame.filter")
    .build();

  static updateActiveList = new MethodBuilder<
    {
      clientId: number;
      ids: number[];
    },
    void
  >()
    .withName("DemoGame.updateActiveList")
    .build();
}

export default DemoGameAPI;
