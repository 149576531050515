import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { CashierUpdateStore } from "@/stores";

import CashierUpdateForm from "./cashier-update-form";

type TProps = {
  cashierUpdateStore: CashierUpdateStore;
};

function CashierUpdateModal({ cashierUpdateStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Edit cashier" })}
      open={cashierUpdateStore.modal.isOpen}
      onCancel={cashierUpdateStore.modal.close}
      width={720}
      footer={null}
      destroyOnClose={true}
    >
      <CashierUpdateForm cashierUpdateStore={cashierUpdateStore} />
    </Modal>
  );
}

export default observer(CashierUpdateModal);
