import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { PlayerGenerateForm } from "./player-generate-form";
import { PlayerGenerateModalState } from "./player-generate-modal.state";

type Props = {
  state: PlayerGenerateModalState;
};

export const PlayerGenerateModal: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "New player" })}
      open={state.isOpen}
      onCancel={state.close}
      width={720}
      footer={null}
      destroyOnClose={true}
    >
      {state.generateFormState && (
        <PlayerGenerateForm state={state.generateFormState} />
      )}
    </Modal>
  );
});
