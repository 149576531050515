import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Row, Space, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { match } from "ts-pattern";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormSelect,
  FormTextArea,
} from "@/ui/_common_/form-helpers";
import Selector from "@/ui/_common_/selector";

import { AddBannerFormState } from "./add-form.state";

interface Props {
  state: AddBannerFormState;
}

export const AddBannerFormView = observer<Props>(
  ({
    state: {
      form,

      mutation,

      type,
      actionType,

      handleSubmit,
      handleTabRemove,

      providerSelectorStore,
      gameSelectorStore,

      tagsOptions,
      bonusesOptions,

      usedLanguageCodes,
      unusedLanguageCodes,
      addLanguageMenu,
    },
  }) => {
    return (
      <Form
        size="small"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ErrorsFormatter queries={[mutation]} />
        <FormItem form={form} path="type" label={"Type"}>
          <FormSelect
            form={form}
            path="type"
            options={[
              {
                label: "Simple",
                value: "simple",
              },
              {
                label: "Text",
                value: "text",
              },
              {
                label: "Bonus",
                value: "bonus",
              },
            ]}
          />
        </FormItem>
        <Tabs
          className="tabs--container"
          type="editable-card"
          size="small"
          onEdit={(key, action) => {
            if (action === "remove") {
              handleTabRemove(key as string);
            }
          }}
          items={usedLanguageCodes.map((code) => ({
            key: code,
            label: code.toUpperCase(),
            closable: usedLanguageCodes.length > 1 && code !== "en",
            children: (
              <>
                <FormItem form={form} path={`titles.${code}`} label={"Title"}>
                  <FormInput
                    form={form}
                    path={`titles.${code}`}
                    placeholder={"Enter title"}
                  />
                </FormItem>
                {type !== "simple" ? (
                  <FormItem
                    form={form}
                    path={`subtitles.${code}`}
                    label={"Text"}
                  >
                    <FormTextArea
                      form={form}
                      path={`subtitles.${code}`}
                      placeholder={"Enter text"}
                    />
                  </FormItem>
                ) : (
                  <FormItem
                    form={form}
                    path={`subtitles.${code}`}
                    label={"Subtitle"}
                  >
                    <FormInput
                      form={form}
                      path={`subtitles.${code}`}
                      placeholder={"Enter subtitle"}
                    />
                  </FormItem>
                )}
              </>
            ),
          }))}
          hideAdd={true}
          tabBarExtraContent={{
            right: !!unusedLanguageCodes.length && (
              <Dropdown menu={addLanguageMenu} trigger={["click"]}>
                <Button size="small">
                  <PlusOutlined />
                </Button>
              </Dropdown>
            ),
          }}
        />
        <FormItem form={form} path="actionType" label={"Action"}>
          <FormSelect
            form={form}
            path="actionType"
            options={[
              {
                label: "None",
                value: null,
              },
              {
                label: "URL",
                value: "url",
              },
              {
                label: "Provider",
                value: "provider",
              },
              {
                label: "Tag",
                value: "tag",
              },
              {
                label: "Game",
                value: "game",
              },
            ]}
          />
        </FormItem>
        {match(actionType)
          .with("url", () => (
            <FormItem form={form} path="actionValue" label={"URL"}>
              <FormInput
                form={form}
                path="actionValue"
                placeholder={"Enter URL"}
              />
            </FormItem>
          ))
          .with("provider", () => (
            <FormItem form={form} path="actionValue" label={"Provider"}>
              <Selector
                store={providerSelectorStore}
                placeholder={"Select provider"}
              />
            </FormItem>
          ))
          .with("game", () => (
            <FormItem form={form} path="actionValue" label={"Game"}>
              <Space direction="vertical">
                <Selector
                  store={providerSelectorStore}
                  placeholder={"Select provider"}
                />
                <Selector
                  store={gameSelectorStore}
                  placeholder={"Select game"}
                />
              </Space>
            </FormItem>
          ))
          .with("tag", () => (
            <FormItem form={form} path="actionValue" label={"Tag"}>
              <FormSelect
                form={form}
                path="actionValue"
                options={tagsOptions}
                placeholder={"Select tag"}
              />
            </FormItem>
          ))
          .with(null, () => (
            <div style={{ display: "none" }}>
              <input
                type="hidden"
                {...form.register("actionValue", { value: null })}
              />
            </div>
          ))
          .otherwise(() => null)}
        {type === "bonus" ? (
          <FormItem form={form} path="bonusId" label={"Bonus"}>
            <FormSelect
              form={form}
              path="bonusId"
              options={bonusesOptions}
              placeholder={"Select bonus"}
            />
          </FormItem>
        ) : (
          <div style={{ display: "none" }}>
            <input
              type="hidden"
              {...form.register("bonusId", { value: undefined })}
            />
          </div>
        )}
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {"Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  },
);
