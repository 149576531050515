import MethodBuilder from "../method-builder";

class PermissionsAPI {
  static get = new MethodBuilder<
    { clientId: number } | { agentId: number } | { hallId: number },
    {
      jackpots: boolean;
      sweepstakes: boolean;
    }
  >()
    .withName("Permission.get")
    .build();
}

export default PermissionsAPI;
