import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row, Space, Spin, Switch, Table, Tabs } from "antd";
import { has } from "lodash-es";
import { autorun, IReactionDisposer, reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI, ClientAPI, PermissionsAPI, TWebsite } from "@/api";
import Query from "@/models/query";
import {
  TUserUpdateSchema,
  TWebsitesUpdateSchema,
  useUserUpdateSchema,
  useWebsitesUpdateSchema,
} from "@/schemas";
import { useGlobalStore, UserUpdateStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import JackpotUpdateForm from "@/ui/_common_/jackpot-update-form";
import Password from "@/ui/_common_/password";
import { AlternativePatch } from "@/ui/alternative-patch";
import { Bonuses } from "@/ui/bonuses";
import { EditContactsForm } from "@/ui/contacts";
import { Games } from "@/ui/games";
import { RulesTabs } from "@/ui/rules";
import { Sweepstakes } from "@/ui/sweepstakes";
import { generatePassword } from "@/utilities";

import { ConfiguredByParentProvider } from "../../configured-by-parent-alert";
import { FormItem, FormSwitch } from "../../form-helpers";
import { FormSpinner } from "../../form-spinner";

type TProps = {
  userUpdateStore: UserUpdateStore;
};

function UserUpdateForm({ userUpdateStore }: TProps) {
  const { userStore } = useGlobalStore();

  const userUpdateSchema = useUserUpdateSchema();
  const userUpdateForm = useForm<TUserUpdateSchema>({
    defaultValues: {},
    resolver: yupResolver(userUpdateSchema),
  });

  const websitesUpdateSchema = useWebsitesUpdateSchema();
  const websitesUpdateForm = useForm<TWebsitesUpdateSchema>({
    resolver: yupResolver(websitesUpdateSchema),
  });

  useEffect(() => {
    userStore.fetchInBackground();
    userUpdateStore.fetchWebsites();
    userUpdateStore.fetchGames();
    userUpdateStore.fetchJackpots();
  }, [userStore, userUpdateStore]);

  useEffect(() => {
    userUpdateStore.websites.forEach((website) => {
      if (typeof website.isEnabled !== "boolean") {
        return;
      }
      websitesUpdateForm.setValue(
        `websites.id_${website.id}.isChecked`,
        website.isEnabled,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateStore.websites]);

  const intl = useIntl();

  const parentQuery = useMemo(() => {
    const query = new Query(async () => {
      const agentId = userStore.hall?.agentId ?? userStore.agent?.parentAgentId;
      const clientId = userStore.hall?.clientId ?? userStore.agent?.clientId;
      if (agentId) {
        return AgentAPI.getById({ id: agentId });
      }
      if (clientId) {
        return ClientAPI.getById({ id: clientId });
      }
    });
    query.submit({});
    return query;
  }, [userStore.agent, userStore.hall]);

  useEffect(() => {
    const disposers: IReactionDisposer[] = [];

    disposers.push(
      reaction(
        () =>
          userUpdateStore.clientUpdateQuery.isFulfilled ||
          userUpdateStore.agentUpdateQuery.isFulfilled ||
          userUpdateStore.hallUpdateQuery.isFulfilled ||
          userUpdateStore.cashierUpdateQuery.isFulfilled,
        () => userUpdateForm.resetField("password"),
      ),
    );

    disposers.push(
      autorun(() => {
        userUpdateForm.setValue(
          "autoEnableGames",
          userStore.hall?.autoEnableGames ??
            userStore.agent?.autoEnableGames ??
            false,
        );
        userUpdateForm.setValue(
          "cashierSettings.isShortPlayerCreation",
          userStore.hall?.cashierSettings.isShortPlayerCreation ??
            userStore.agent?.cashierSettings.isShortPlayerCreation ??
            userStore.client?.cashierSettings.isShortPlayerCreation ??
            false,
        );
      }),
    );

    return () => disposers.forEach((dispose) => dispose());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [permissionsQuery] = useState(() => new Query(PermissionsAPI.get));

  useEffect(() => {
    permissionsQuery.submit(userUpdateStore.entity!);
  }, [permissionsQuery, userUpdateStore.entity]);

  if (
    userStore.userQuery.isIdle ||
    userStore.userQuery.isPending ||
    permissionsQuery.isIdle ||
    permissionsQuery.isPending ||
    parentQuery.isIdle ||
    parentQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (permissionsQuery.isRejected || parentQuery.isRejected) {
    return <ErrorsFormatter queries={[permissionsQuery, parentQuery]} />;
  }

  const parent = parentQuery.data;

  const isAutoEnableGamesShown =
    userStore.user.role !== "Cashier" &&
    (parent && "autoEnableGames" in parent ? parent.autoEnableGames : true);

  const isShortPlayerFormEnabled = [
    "ClientAdmin",
    "AgentAdmin",
    "HallAdmin",
  ].includes(userStore.user.role);

  const configuredByParent =
    userStore.hall?.configuredByParent ??
    userStore.agent?.configuredByParent ??
    false;

  return (
    <Tabs destroyInactiveTabPane={true}>
      {["ClientAdmin", "AgentAdmin", "HallAdmin", "Cashier"].includes(
        userStore.user.role,
      ) && (
        <Tabs.TabPane
          tab={intl.formatMessage({ defaultMessage: "General" })}
          key="general"
        >
          <Space direction="vertical">
            {userStore.user.role === "AgentAdmin" &&
              typeof userStore.agent?.currency === "string" && (
                <AlternativePatch
                  entity={{ agentId: userStore.user.agentId }}
                  currency={userStore.agent.currency}
                />
              )}
            <Spin
              spinning={
                userUpdateStore.clientUpdateQuery.isPending ||
                userUpdateStore.agentUpdateQuery.isPending ||
                userUpdateStore.hallUpdateQuery.isPending ||
                userUpdateStore.cashierUpdateQuery.isPending
              }
            >
              <Space direction="vertical">
                <ErrorsFormatter
                  queries={[
                    userUpdateStore.clientUpdateQuery,
                    userUpdateStore.agentUpdateQuery,
                    userUpdateStore.hallUpdateQuery,
                    userUpdateStore.cashierUpdateQuery,
                  ]}
                />
                <Form
                  labelCol={{ span: 8 }}
                  labelAlign="left"
                  wrapperCol={{ span: 16 }}
                  layout="horizontal"
                  onFinish={userUpdateForm.handleSubmit(
                    userUpdateStore.handleUserUpdate,
                  )}
                >
                  <Form.Item
                    label={intl.formatMessage({
                      defaultMessage: "New password",
                    })}
                    validateStatus={
                      has(userUpdateForm.formState.errors, "password")
                        ? "error"
                        : ""
                    }
                    help={userUpdateForm.formState.errors.password?.message}
                  >
                    <Controller
                      control={userUpdateForm.control}
                      name="password"
                      render={({ field }) => (
                        <Password
                          {...field}
                          value={field.value}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter password",
                          })}
                          onCopy={() => {
                            const password =
                              userUpdateForm.getValues().password ?? "";
                            if (password) {
                              navigator.clipboard.writeText(password);
                            }
                          }}
                          onGenerate={() =>
                            userUpdateForm.setValue(
                              "password",
                              generatePassword(),
                            )
                          }
                          // disable autocomplete
                          readOnly
                          onFocus={(e) => e.target.removeAttribute("readonly")}
                        />
                      )}
                    />
                  </Form.Item>
                  {isAutoEnableGamesShown && (
                    <FormItem
                      form={userUpdateForm}
                      path="autoEnableGames"
                      label={intl.formatMessage({
                        defaultMessage: "Automatically enable games",
                      })}
                    >
                      <FormSwitch
                        form={userUpdateForm}
                        path="autoEnableGames"
                      />
                    </FormItem>
                  )}
                  {isShortPlayerFormEnabled && (
                    <FormItem
                      form={userUpdateForm}
                      path="cashierSettings.isShortPlayerCreation"
                      label={intl.formatMessage({
                        defaultMessage: "Player creation: short form",
                      })}
                      isColonShown={false}
                    >
                      <FormSwitch
                        form={userUpdateForm}
                        path="cashierSettings.isShortPlayerCreation"
                      />
                    </FormItem>
                  )}
                  <Row justify="end" gutter={12}>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        {intl.formatMessage({ defaultMessage: "Save" })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Space>
            </Spin>
          </Space>
        </Tabs.TabPane>
      )}
      {["ClientAdmin", "AgentAdmin"].includes(userStore.user.role) && (
        <>
          <Tabs.TabPane
            tab={intl.formatMessage({ defaultMessage: "Websites" })}
            key="websites"
          >
            <Spin
              spinning={
                userUpdateStore.websitesQuery.isPending ||
                userUpdateStore.enableWebsitesQuery.isPending ||
                userUpdateStore.disableWebsitesQuery.isPending
              }
            >
              <Space direction="vertical">
                <ErrorsFormatter
                  queries={[
                    userUpdateStore.websitesQuery,
                    userUpdateStore.enableWebsitesQuery,
                    userUpdateStore.disableWebsitesQuery,
                  ]}
                />
                <Form
                  labelCol={{ span: 8 }}
                  labelAlign="left"
                  wrapperCol={{ span: 16 }}
                  layout="horizontal"
                  onFinish={websitesUpdateForm.handleSubmit(
                    userUpdateStore.handleWebsitesUpdate,
                  )}
                >
                  <Table
                    rowKey="id"
                    dataSource={userUpdateStore.websites}
                    size="small"
                    pagination={false}
                  >
                    <Table.Column
                      dataIndex="id"
                      title={intl.formatMessage({ defaultMessage: "ID" })}
                      render={(_, website: TWebsite) => (
                        <>
                          <input
                            type="hidden"
                            value={website.id}
                            {...websitesUpdateForm.register(
                              `websites.id_${website.id}.id`,
                            )}
                          />
                          {website.id}
                        </>
                      )}
                    />
                    <Table.Column
                      dataIndex="domainName"
                      title={intl.formatMessage({
                        defaultMessage: "Domain name",
                      })}
                    />
                    <Table.Column
                      dataIndex="isEnabled"
                      align="right"
                      render={(_, website: TWebsite) => (
                        <Controller
                          control={websitesUpdateForm.control}
                          name={`websites.id_${website.id}.isChecked`}
                          defaultValue={website.isEnabled}
                          render={({ field }) => (
                            <Switch
                              checked={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      )}
                    />
                  </Table>
                  <Row justify="end" gutter={12}>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        {intl.formatMessage({ defaultMessage: "Save" })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Space>
            </Spin>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={intl.formatMessage({ defaultMessage: "Games" })}
            key="games"
          >
            <Games.EditForm entity={userStore.entity!} />
          </Tabs.TabPane>
        </>
      )}
      {["ClientAdmin", "AgentAdmin", "HallAdmin", "Cashier"].includes(
        userStore.user.role,
      ) &&
        userStore.entity !== null && (
          <Tabs.TabPane
            key="bonuses"
            tab={intl.formatMessage({ defaultMessage: "Bonuses" })}
            className="subtabs"
          >
            <ConfiguredByParentProvider value={configuredByParent}>
              <Bonuses.Tabs
                entity={userStore.entity}
                readOnly={
                  ["HallAdmin", "Cashier"].includes(userStore.user.role) ||
                  configuredByParent
                }
                selfEdit={true}
              />
            </ConfiguredByParentProvider>
          </Tabs.TabPane>
        )}
      {permissionsQuery.data.jackpots && (
        <Tabs.TabPane
          tab={intl.formatMessage({ defaultMessage: "Jackpots" })}
          key="jackpots"
        >
          <Spin spinning={userUpdateStore.jackpotsQuery.isPending}>
            <ConfiguredByParentProvider value={configuredByParent}>
              <JackpotUpdateForm
                entity={userUpdateStore.entity!}
                jackpot={userUpdateStore.jackpots[0]}
                onRefresh={userUpdateStore.fetchJackpots}
                readOnly={
                  ["HallAdmin", "Cashier"].includes(userStore.user.role) ||
                  configuredByParent
                }
              />
            </ConfiguredByParentProvider>
          </Spin>
        </Tabs.TabPane>
      )}
      <Tabs.TabPane
        tab={intl.formatMessage({ defaultMessage: "Contacts" })}
        key="contacts"
      >
        <EditContactsForm
          entity={userUpdateStore.entity}
          readOnly={
            userStore.user.role === "HallAdmin" ||
            userStore.user.role === "Cashier"
          }
        />
      </Tabs.TabPane>
      {permissionsQuery.data.sweepstakes && (
        <Tabs.TabPane
          tab={intl.formatMessage({ defaultMessage: "Sweepstakes" })}
          key="sweepstakes"
        >
          <Sweepstakes.EditForm entity={userUpdateStore.entity!} />
        </Tabs.TabPane>
      )}
      {userUpdateStore.entity &&
        ("clientId" in userUpdateStore.entity ||
          "agentId" in userUpdateStore.entity) && (
          <Tabs.TabPane
            tab={intl.formatMessage({ defaultMessage: "Rules and Terms" })}
            key="rules-and-terms"
            className="subtabs"
          >
            <RulesTabs entity={userUpdateStore.entity} />
          </Tabs.TabPane>
        )}
    </Tabs>
  );
}

export default observer(UserUpdateForm);
