import { makeAutoObservable } from "mobx";

import { HallAPI, THall } from "@/api";
import Query from "@/models/query";
import { IntlService } from "@/services";

import { HallDepositStore, HallsStore, HallWithdrawStore } from ".";

export class HallStore {
  constructor(
    private _hall: THall,
    public hallsStore: HallsStore,
    public intlService: IntlService,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.intlService.intl;
  }

  get hall() {
    return this._hall;
  }

  public get isSweepstakesEnabled() {
    return this._hall.clientMode !== "normal";
  }

  private setHall = (hall: THall) => {
    this._hall = hall;
  };

  fetchQuery = new Query(HallAPI.getById);
  updateQuery = new Query(HallAPI.update);

  withdrawStore = new HallWithdrawStore(this);
  depositStore = new HallDepositStore(this);

  fetch = async () => {
    await this.fetchQuery.submit({
      id: this.hall.id,
    });

    if (!this.fetchQuery.isFulfilled) {
      return;
    }

    const hall = this.fetchQuery.data;
    this.setHall(hall);
  };

  togglePanicQuery = new Query(async () => {
    await HallAPI.update({
      id: this.hall.id,
      lobbySettings: {
        isPanicEnabled: !this.hall.lobbySettings.isPanicEnabled,
      },
    });
    const hall = await HallAPI.getById({
      id: this.hall.id,
    });
    this.setHall(hall);
  });
}
