import { observer } from "mobx-react-lite";

import { usePosTemplateState } from "./pos-template.state";
import { PosTemplateView } from "./pos-template.view";

export interface PosTemplateProps {
  entity: { agentId: number };
}

export const PosTemplate = observer<PosTemplateProps>((props) => {
  const state = usePosTemplateState(props);
  return <PosTemplateView state={state} />;
});
