import { notification } from "antd";

import { IS_VERBOSE } from "@/constants";

export class Logger {
  static log(...args: any[]) {
    if (!IS_VERBOSE) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  static error(...args: any[]) {
    if (!IS_VERBOSE) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(...args);
    notification.error({
      message: args.join(" "),
    });
  }
}
