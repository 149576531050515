import { debounce } from "lodash-es";
import { action, makeAutoObservable } from "mobx";
import { ReactNode } from "react";

import { TMethod, TPaging } from "@/api";
import Query from "@/models/query";

type TInput = {
  searchQuery?: string;
  paging?: TPaging;
};

type TOutput = {
  data: any[];
};

type TValue<O extends TOutput> = Unpacked<O["data"]>;

type TParams<I extends TInput> = DistributiveOmit<I, "searchQuery" | "paging">;

type TOptions<I extends TInput, O extends TOutput, T extends TValue<O>> = {
  filterMethod: TMethod<I, O>;
  getByIdMethod: TMethod<{ id: number }, T>;
  isPaginationEnabled?: boolean;
  onSelect?: (item?: T) => void;
  pinnedItems?: T[];
} & ({ labelKey: keyof T } | { renderLabel: (item: T) => ReactNode });

export class SelectorStore<
  I extends TInput,
  O extends TOutput,
  T extends TValue<O>,
> {
  private readonly filterMethod: TMethod<I, O>;
  private readonly getByIdMethod: TMethod<{ id: number }, T>;
  public readonly labelKey?: keyof T;
  public readonly renderLabel?: (item: T) => ReactNode;
  private readonly isPaginationEnabled: boolean;
  private readonly onSelect?: (item?: T) => void;
  public readonly filterQuery: Query<I, O>;
  public readonly getByIdQuery: Query<{ id: number }, T>;
  private readonly pinnedItems: T[];

  constructor({
    filterMethod,
    getByIdMethod,
    isPaginationEnabled = true,
    onSelect,
    pinnedItems = [],
    ...options
  }: TOptions<I, O, T>) {
    makeAutoObservable(this);

    this.filterMethod = filterMethod;
    this.getByIdMethod = getByIdMethod;
    if ("labelKey" in options) {
      this.labelKey = options.labelKey;
    }
    if ("renderLabel" in options) {
      this.renderLabel = options.renderLabel;
    }
    this.isPaginationEnabled = isPaginationEnabled;
    this.onSelect = onSelect;

    this.filterQuery = new Query(this.filterMethod);
    this.getByIdQuery = new Query(this.getByIdMethod);

    this.pinnedItems = pinnedItems;
  }

  _parameters?: TParams<I>;

  get parameters() {
    return this._parameters;
  }

  setParameters = (parameters?: TParams<I>) => {
    this._parameters = parameters;
  };

  _selectedId?: number = undefined;

  get selectedId() {
    return this._selectedId;
  }

  setSelectedId = async (selectedId?: number, isFetchNeeded = true) => {
    if (this._selectedId !== selectedId) {
      this._selectedId = selectedId;
    }
    this.searchQuery = "";
    const item = [...this.pinnedItems, ...this.items].find(
      (item) => item.id === selectedId,
    );
    if (item) {
      this.setSelectedItem(item);
    } else if (isFetchNeeded) {
      await this.fetchItem();
    }
  };

  _selectedItem?: T;

  get selectedItem() {
    return this._selectedItem;
  }

  private setSelectedItem = (selectedItem?: T) => {
    if (this._selectedItem?.id !== selectedItem?.id) {
      this._selectedItem = selectedItem;
    }
  };

  _items: T[] = [];

  get items() {
    return this._items;
  }

  private setItems = (items: T[]) => {
    this._items = items;
  };

  get options() {
    return [...this.pinnedItems, ...this.items].map((item) => ({
      value: item["id"],
      label: this.renderLabel
        ? this.renderLabel(item)
        : this.labelKey
        ? item[this.labelKey]
        : "—",
    }));
  }

  private limit = 20;
  private offset = 0;
  private searchQuery = "";

  fetchItem = async () => {
    if (!this.selectedId) {
      this.setSelectedItem();
      return;
    }

    await this.getByIdQuery.submit({ id: this.selectedId });

    if (!this.getByIdQuery.isFulfilled) {
      this.handleClear();
      return;
    }

    const item = this.getByIdQuery.data;
    this.setSelectedItem(item);
  };

  fetchItems = debounce(
    action("fetchItems", async ({ shouldFetchNextPage = false } = {}) => {
      if (this._parameters === undefined) {
        return;
      }

      if (shouldFetchNextPage) {
        this.offset += this.limit;
      } else {
        this.offset = 0;
      }

      const parameters: any = {
        ...this._parameters,
        searchQuery: this.searchQuery,
      };
      if (this.isPaginationEnabled) {
        parameters.paging = {
          limit: this.limit,
          offset: this.offset,
        };
      }

      await this.filterQuery.submit(parameters);

      if (!this.filterQuery.isFulfilled) {
        this.handleClear();
        return;
      }

      const { data } = this.filterQuery.data;
      if (
        this.filterQuery.request &&
        !this.filterQuery.request.paging?.offset
      ) {
        this.setItems(data);
      } else {
        this.setItems([...this.items, ...data]);
      }
    }),
    250,
  );

  handleSelect = ({ value: id }: { value: number }) => {
    this.setSelectedId(id);
    const item = this.items.find((item) => item.id === id);
    this.setSelectedItem(item);
    this.onSelect?.(item);
    this.searchQuery = "";
  };

  handleClear = () => {
    this.setSelectedId();
    this.setSelectedItem();
    this.onSelect?.();
    this.searchQuery = "";
  };

  handleSearch = (searchQuery: string) => {
    this.searchQuery = searchQuery;
    this.fetchItems();
  };

  handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!this.isPaginationEnabled) {
      return;
    }
    const element = event.nativeEvent.target as HTMLDivElement | null;
    if (element === null) {
      return;
    }
    if (
      element.offsetHeight + element.scrollTop >= element.scrollHeight &&
      this.filterQuery.isFulfilled
    ) {
      this.fetchItems({ shouldFetchNextPage: true });
    }
  };
}
