import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Row, Space, Spin, Switch } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useResetForm } from "@/hooks";
import { useAgentCreateSchema, TAgentCreateSchema } from "@/schemas";
import { AgentsStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Password from "@/ui/_common_/password";
import Selector from "@/ui/_common_/selector";
import { generatePassword } from "@/utilities";
import yup from "@/yup";

type TProps = {
  agentsStore: AgentsStore;
};

function AgentCreateForm({ agentsStore }: TProps) {
  const { agentCreateStore } = agentsStore;
  const agentCreateSchema = useAgentCreateSchema();
  const schema = useMemo(() => {
    if (agentCreateStore.parentCurrency === null) {
      return agentCreateSchema;
    }
    return agentCreateSchema.concat(
      yup.object({
        currency: yup.string().equals([agentCreateStore.parentCurrency]),
      }),
    );
  }, [agentCreateSchema, agentCreateStore.parentCurrency]);

  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm<TAgentCreateSchema>({
    defaultValues: {
      currency: agentCreateStore.parentCurrency ?? undefined,
    },
    resolver,
  });

  useResetForm(agentCreateStore.createQuery, reset);

  useEffect(() => {
    setValue("currency", agentCreateStore.parentCurrency ?? undefined);
  }, [setValue, agentCreateStore.parentCurrency]);

  useEffect(() => {
    return () => agentCreateStore.dispose();
  }, [agentCreateStore]);

  useEffect(() => {
    if (agentCreateStore.parentAgentSelectorStore.selectedItem !== undefined) {
      setValue(
        "isBalanceUnlimited",
        agentCreateStore.parentAgentSelectorStore.selectedItem
          .isBalanceUnlimited,
      );
    }
  }, [agentCreateStore.parentAgentSelectorStore.selectedItem, setValue]);

  const intl = useIntl();

  return (
    <Spin spinning={agentCreateStore.createQuery.isPending}>
      <Space direction="vertical">
        <ErrorsFormatter queries={[agentCreateStore.createQuery]} />
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleSubmit(agentCreateStore.handleSubmit)}
        >
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Parent agent" })}
          >
            <Selector
              placeholder={intl.formatMessage({
                defaultMessage: "Select parent agent",
              })}
              store={agentCreateStore.parentAgentSelectorStore}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Name" })}
            validateStatus={has(errors, "name") ? "error" : ""}
            help={errors.name?.message}
          >
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter name",
                  })}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin username" })}
            validateStatus={has(errors, "login") ? "error" : ""}
            help={errors.login?.message}
          >
            <Controller
              control={control}
              name="login"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter username",
                  })}
                  // disable autocomplete
                  readOnly
                  onFocus={(e) => e.target.removeAttribute("readonly")}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin password" })}
            validateStatus={has(errors, "password") ? "error" : ""}
            help={errors.password?.message}
          >
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter password",
                  })}
                  onCopy={() => {
                    const password = getValues().password ?? "";
                    if (password) {
                      navigator.clipboard.writeText(password);
                    }
                  }}
                  onGenerate={() => setValue("password", generatePassword())}
                  // disable autocomplete
                  readOnly
                  onFocus={(e) => e.target.removeAttribute("readonly")}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Unlimited balance" })}
            validateStatus={has(errors, "isBalanceUnlimited") ? "error" : ""}
            help={errors.isBalanceUnlimited?.message}
          >
            <Controller
              control={control}
              name="isBalanceUnlimited"
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  disabled={
                    !(
                      agentCreateStore.parentAgentSelectorStore.selectedItem
                        ?.isBalanceUnlimited ?? true
                    )
                  }
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Currency",
            })}
            validateStatus={has(errors, "currency") ? "error" : ""}
            help={errors.currency?.message}
          >
            <Controller
              control={control}
              name="currency"
              defaultValue=""
              render={({ field }) => (
                <CurrencySelector
                  {...field}
                  value={field.value ? field.value : undefined}
                  disabled={agentCreateStore.parentCurrency !== null}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Can create subagents",
            })}
            validateStatus={has(errors, "canHaveChildren") ? "error" : ""}
            help={errors.canHaveChildren?.message}
          >
            <Controller
              control={control}
              name="canHaveChildren"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={field.onChange} />
              )}
            />
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({ defaultMessage: "Create" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
}

export default observer(AgentCreateForm);
