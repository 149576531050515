import { observer } from "mobx-react-lite";

import { useClientLobbySettingsState } from "./client-lobby-settings.state";
import { ClientLobbySettingsView } from "./client-lobby-settings.view";

export interface ClientLobbySettingsProps {
  clientId: number;
}

export const ClientLobbySettings = observer(
  (props: ClientLobbySettingsProps) => {
    const state = useClientLobbySettingsState(props);
    return <ClientLobbySettingsView state={state} />;
  },
);
