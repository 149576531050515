import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSelect } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { KiosksSettingsFormState } from "./form.state";

interface Props {
  state: KiosksSettingsFormState;
}

export const KiosksSettingsFormView = observer<Props>(
  ({
    state: {
      intl,
      form,
      handleSubmit,
      query,
      mutation,
      themeOptions,
      frontendThemeOptions,
    },
  }) => {
    if (query.isIdle || query.isPending) {
      return <FormSpinner />;
    }

    if (query.isRejected) {
      return <ErrorsFormatter queries={[query]} />;
    }

    return (
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ErrorsFormatter queries={[mutation]} />
        <FormItem
          form={form}
          path="lobbyThemeStringId"
          label={intl.formatMessage({ defaultMessage: "Lobby theme" })}
        >
          <FormSelect
            form={form}
            path="lobbyThemeStringId"
            placeholder={intl.formatMessage({
              defaultMessage: "Select lobby theme...",
            })}
            options={themeOptions}
          />
        </FormItem>
        <FormItem
          form={form}
          path="frontendThemeStringId"
          label={intl.formatMessage({ defaultMessage: "V3 Lobby theme" })}
        >
          <FormSelect
            form={form}
            path="frontendThemeStringId"
            placeholder={intl.formatMessage({
              defaultMessage: "Select v3 lobby theme...",
            })}
            options={frontendThemeOptions}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  },
);
