import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useResetForm } from "@/hooks";
import { useAgentDepositSchema, TAgentDepositSchema } from "@/schemas";
import { AgentDepositStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import { CurrencyUtilities } from "@/utilities";

type TProps = {
  agentDepositStore: AgentDepositStore;
};

function AgentDepositForm({ agentDepositStore }: TProps) {
  const schema = useAgentDepositSchema();
  const resolver = yupResolver(schema);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<TAgentDepositSchema>({
    resolver,
  });

  useResetForm(agentDepositStore.depositQuery, reset);

  useEffect(() => {
    agentDepositStore.fetchAgent();
  }, [agentDepositStore]);

  const intl = useIntl();

  return (
    <Spin
      spinning={
        agentDepositStore.depositQuery.isPending ||
        agentDepositStore.agentQuery.isPending
      }
    >
      <Space direction="vertical">
        <ErrorsFormatter
          queries={[
            agentDepositStore.depositQuery,
            agentDepositStore.agentQuery,
          ]}
        />
        {agentDepositStore.isAvailableBalanceShown && (
          <Alert
            type="info"
            message={intl.formatMessage(
              {
                id: "HFlRzl",
                defaultMessage: "Available balance: {balance}.",
              },
              {
                balance: (
                  <Typography.Link
                    onClick={() =>
                      setValue(
                        "amount",
                        CurrencyUtilities.toMainUnits(
                          agentDepositStore.availableBalance,
                        ),
                      )
                    }
                  >
                    <MoneyFormatter
                      cents={agentDepositStore.availableBalance}
                    />{" "}
                    {agentDepositStore.agent?.currency}
                  </Typography.Link>
                ),
              },
            )}
          />
        )}
        <Form onFinish={handleSubmit(agentDepositStore.handleSubmit)}>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Amount",
            })}
            validateStatus={has(errors, "amount") ? "error" : ""}
            help={errors.amount?.message}
          >
            <Controller
              control={control}
              name="amount"
              defaultValue={undefined}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Amount",
                  })}
                  suffix={agentDepositStore.agentStore.agent.currency}
                  autoFocus
                  type="number"
                />
              )}
            />
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button onClick={agentDepositStore.modal.close}>
                {intl.formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={agentDepositStore.depositQuery.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Deposit" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Spin>
  );
}

export default observer(AgentDepositForm);
