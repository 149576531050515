import MethodBuilder from "../method-builder";
import { TBanner, TBannerTitle } from "../types";

export class BannerAPI {
  static get = new MethodBuilder<{ id: number }, TBanner>()
    .withName("Banner.get")
    .build();

  static create = new MethodBuilder<
    {
      sliderId: number;
      type: string;
      order?: number;
      bonusId?: number;
      actionType?: string | null;
      actionValue?: string | null;
      // min number of items: 1
      titles: TBannerTitle[];
      // min number of items: 1
      subtitles: TBannerTitle[];
    },
    TBanner
  >()
    .withName("Banner.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      type?: string;
      order?: number;
      isActive?: boolean;
      bonusId?: number | null;
      actionType?: string | null;
      actionValue?: string | null;
      // min number of items: 1
      titles?: TBannerTitle[];
      // min number of items: 1
      subtitles?: TBannerTitle[];
    },
    {}
  >()
    .withName("Banner.update")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Banner.delete")
    .build();

  static undelete = new MethodBuilder<{ id: number }, void>()
    .withName("Banner.undelete")
    .build();
}
