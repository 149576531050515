import { makeAutoObservable } from "mobx";

import { CurrencyAPI, TCurrency } from "@/api";
import Query from "@/models/query";

export class CurrencyStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _currencies!: TCurrency[];

  get currencies() {
    return this._currencies;
  }

  private setCurrencies = (currencies: TCurrency[]) => {
    this._currencies = currencies;
  };

  currenciesQuery = new Query(CurrencyAPI.getAll);

  fetch = async () => {
    await this.currenciesQuery.submit();

    if (!this.currenciesQuery.isFulfilled) {
      return;
    }

    const { data } = this.currenciesQuery.data;
    this.setCurrencies(data);
  };
}
