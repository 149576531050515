import { observer } from "mobx-react-lite";

import { useReceiptListState } from "./receipt-list.state";
import { ReceiptListView } from "./receipt-list.view";

export interface ReceiptListProps {
  hallId: number;
}

export const ReceiptList = observer<ReceiptListProps>((props) => {
  const state = useReceiptListState(props);
  return <ReceiptListView state={state} />;
});
