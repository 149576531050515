import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import Page from "@/ui/_common_/page";

import { EditPartnerModal } from "./edit";
import { NewPartnerModal } from "./new";
import { SportPartnersPageState } from "./page.state";
import { SportPartnersTable } from "./table";

type Props = { state: SportPartnersPageState };

export const SportPartnersPageView = observer<Props>(({ state }) => {
  return (
    <Page title="Sport Partners">
      <NewPartnerModal />
      <EditPartnerModal />
      <Card size="small">
        <Space direction="vertical">
          <Row justify="end">
            <Col>
              <Button
                icon={<PlusOutlined />}
                onClick={() => state.openNewPartnerModal()}
              >
                {"Create"}
              </Button>
            </Col>
          </Row>
          <SportPartnersTable />
        </Space>
      </Card>
    </Page>
  );
});
