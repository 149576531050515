import yup from "@/yup";

export function useSchema() {
  return yup.object({
    type: yup
      .mixed<"entriesToWin" | "winToEntries">()
      .oneOf(["entriesToWin", "winToEntries"])
      .required(),
    amount: yup
      .number()
      .when("type", {
        is: "entriesToWin",
        then: (schema) => schema.integer().min(1),
        otherwise: (schema) => schema.money().moreThan(0),
      })
      .required(),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;
