import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Input, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useIntl } from "react-intl";

import { ClientsStore, useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";

import ClientCreateModal from "./client-create-modal";
import ClientsTable from "./clients-table";
import Edit from "./edit";

function Clients() {
  const globalStore = useGlobalStore();
  const [clientsStore] = useState(() => new ClientsStore(globalStore));

  const intl = useIntl();

  return (
    <Page title={intl.formatMessage({ defaultMessage: "Clients" })}>
      <ClientCreateModal clientsStore={clientsStore} />
      <Edit.Modal />
      {clientsStore.permissionsStore.has("SelectManager") && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={clientsStore.managerSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select manager",
                })}
              />
            </Col>
          </Row>
        </Card>
      )}
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={(event) => {
                clientsStore.includeDeleted = event.target.checked;
              }}
            >
              {intl.formatMessage({ defaultMessage: "Include deleted" })}
            </Checkbox>
          </Col>
          <Col
            xs={24}
            sm={10}
            md={8}
            lg={6}
            xl={4}
            style={{ marginLeft: "auto" }}
          >
            <Input.Search
              placeholder={intl.formatMessage({
                defaultMessage: "Client ID or username",
              })}
              allowClear
              defaultValue={clientsStore.filterQuery.searchQuery}
              onSearch={(searchQuery) => {
                clientsStore.filterQuery.setSearchQuery(searchQuery);
                clientsStore.filter();
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={clientsStore.clientCreateStore.modal.open}
              icon={<PlusOutlined />}
            >
              {intl.formatMessage({ defaultMessage: "Create" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Space direction="vertical">
          <ErrorsFormatter queries={[clientsStore.filterQuery]} />
          <ClientsTable clientsStore={clientsStore} />
        </Space>
      </Card>
    </Page>
  );
}

export default observer(Clients);
