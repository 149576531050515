import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";

import { FormState } from "./FormState";
import { FormView } from "./FormView";

export type Entity =
  | { clientId: number }
  | { agentId: number }
  | { hallId: number };

export type Props = {
  entity?: Entity;
  readOnly?: boolean;
};

export const Form: FC<Props> = observer((props) => {
  const { eventBusService, intlService, permissionsStore } = useGlobalStore();
  const vm = useViewModel(
    () => new FormState(eventBusService, intlService, permissionsStore),
    props,
  );
  return <FormView state={vm} />;
});
