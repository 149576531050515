import { observer } from "mobx-react-lite";

import { useCategoryGamesFormState } from "./form.state";
import { CategoryGamesFormView } from "./form.view";

export type CategoryGamesFormProps = {
  id: number;
};

export const CategoryGamesForm = observer<CategoryGamesFormProps>((props) => {
  const state = useCategoryGamesFormState(props);
  return <CategoryGamesFormView state={state} />;
});
