export class PrintService {
  static async print(html: string) {
    return new Promise<void>((resolve) => {
      const frame = document.createElement("iframe");

      frame.style.position = "absolute";
      frame.style.visibility = "hidden";
      frame.style.width = "0px";
      frame.style.height = "0px";

      document.body.appendChild(frame);

      frame.contentDocument?.write(html);
      frame.contentDocument?.close();

      const handleLoad = () => {
        setTimeout(() => {
          frame.contentWindow?.focus();
          frame.contentWindow?.print();

          frame.removeEventListener("load", handleLoad);

          document.body.removeChild(frame);

          resolve();
        }, 1000);
      };

      frame.addEventListener("load", handleLoad);
    });
  }
}
