import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import { Entity } from "@/types";

import { ListState } from "./ListState";
import { ListView } from "./ListView";

export type Props = {
  entity: Entity;
  readOnly?: boolean;
  selfEdit?: boolean;
};

export const List: FC<Props> = observer(
  ({ readOnly = false, selfEdit = false, ...props }) => {
    const { eventBusService, intlService, permissionsStore, userStore } =
      useGlobalStore();
    const vm = useViewModel(
      () =>
        new ListState(
          eventBusService,
          intlService,
          permissionsStore,
          userStore,
        ),
      { readOnly, selfEdit, ...props },
    );
    return <ListView state={vm} />;
  },
);
