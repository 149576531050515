import { Event } from "@/services";

import { Line, UniqueLine } from "./types";

export class OpenReceiptJSONModal extends Event<{
  uniqueLines: UniqueLine[];
}> {
  name = "OpenReceiptJSONModal";
}

export class CloseReceiptJSONModal extends Event<Record<string, never>> {
  name = "CloseReceiptJSONModal";
}

export class SaveReceiptJSON extends Event<{ lines: Line[] }> {
  name = "SaveReceiptJSON";
}
