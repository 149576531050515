export abstract class Event<T> {
  constructor(public readonly payload: T) {}

  abstract name: string;
}

type Newable<T> = new (...args: any[]) => T;

type EventMap<E extends Event<any>> = Map<
  Newable<E>,
  Array<(event: E) => void>
>;

export class EventBusService {
  private listeners: EventMap<any> = new Map();

  subscribe<E extends Event<any>>(
    Event: Newable<E>,
    listener: (event: E) => void,
  ): void {
    const listeners = this.listeners.get(Event);
    if (!listeners) {
      this.listeners.set(Event, [listener]);
    } else {
      listeners.push(listener);
    }
  }

  unsubscribe<E extends Event<any>>(
    Event: Newable<E>,
    listener: (event: E) => void,
  ): void {
    const listeners = this.listeners.get(Event);
    listeners?.splice(listeners.indexOf(listener), 1);
  }

  publish<E extends Event<any>>(event: E): void {
    const Event = Object.getPrototypeOf(event).constructor;
    const listeners = this.listeners.get(Event);
    listeners?.forEach((listener) => listener(event));
  }
}
