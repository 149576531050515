import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI, TAgent } from "@/api";
import Modal from "@/models/modal";
import Query from "@/models/query";
import { TAgentDepositSchema } from "@/schemas";
import { CurrencyUtilities } from "@/utilities";

import { AgentStore } from ".";

export class AgentDepositStore {
  constructor(public readonly agentStore: AgentStore) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.agentStore.intlService.intl;
  }

  modal = new Modal();
  depositQuery = new Query(AgentAPI.depositMoney);

  private _amount?: number;

  get amount() {
    return this._amount;
  }

  setAmount = (amount?: number) => {
    this._amount = amount;
  };

  handleSubmit = async (values: TAgentDepositSchema) => {
    await this.depositQuery.submit({
      ...values,
      id: this.agentStore.agent.id,
      amount: CurrencyUtilities.toMinorUnits(values.amount),
    });

    if (!this.depositQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage:
          "The money has been successfully deposited to the balance.",
      }),
    });

    this.modal.close();

    let agentStore: AgentStore | undefined = this.agentStore;
    while (agentStore !== undefined) {
      agentStore.fetch();
      agentStore = agentStore.parentAgentStore;
    }
  };

  agentQuery = new Query(AgentAPI.getById);

  private _agent?: TAgent;

  get agent() {
    return this._agent;
  }

  setAgent = (agent?: TAgent) => {
    this._agent = agent;
  };

  fetchAgent = async () => {
    const { parentAgentId } = this.agentStore.agent;
    if (parentAgentId === null) {
      return;
    }

    await this.agentQuery.submit({ id: parentAgentId });

    if (!this.agentQuery.isFulfilled) {
      return;
    }

    const agent = this.agentQuery.data;
    this.setAgent(agent);
  };

  get isAvailableBalanceShown() {
    if (this.agentStore.agent.parentAgentId === null) {
      return false;
    }
    if (!this.agentQuery.isFulfilled) {
      return false;
    }
    return !this.agentQuery.data.isBalanceUnlimited;
  }

  get availableBalance() {
    return this.agent?.balance ?? 0;
  }
}
