import { Pagination as AntdPagination, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import TItemsQuery from "@/models/items-query";

type TProps = {
  query: TItemsQuery;
  onChange?: () => void;
};

function Pagination({ query, onChange }: TProps) {
  const handlePaginationChange = useCallback(
    (pageNumber: number, pageSize?: number) => {
      query.setPageNumber(pageNumber);
      if (pageSize) {
        query.setPageSize(pageSize);
      }
      onChange?.();
    },
    [query, onChange],
  );

  return (
    <Row justify="end">
      <AntdPagination
        showSizeChanger
        showTotal={() => true}
        current={query.pageNumber}
        pageSize={query.pageSize}
        total={query.total}
        onChange={handlePaginationChange}
        responsive={true}
      />
    </Row>
  );
}

export default observer(Pagination);
