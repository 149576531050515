export const localeByCode = {
  en: { code: "en", name: "English" },
  es: { code: "es", name: "Español" },
  tr: { code: "tr", name: "Türk" },
  de: { code: "de", name: "Deutsch" },
  pt: { code: "pt", name: "Português" },
  fr: { code: "fr", name: "Français" },
};

export type TLocaleCode = keyof typeof localeByCode;
