import { Button, Card, Col, Row, Space, Spin, Table, Tabs } from "antd";
import classNames from "classnames";
import { snakeCase } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TFortuneWheelReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { FortuneWheelReportState } from "./FortuneWheelReportState";

function FortuneWheelReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(
    () => new FortuneWheelReportState(globalStore),
    {},
  );
  const intl = useIntl();
  const { permissionsStore } = globalStore;

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: "Fortune wheel report" })}
    >
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({
                defaultMessage: "Apply",
              })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin
          spinning={
            state.filterQuery.isPending ||
            state.aggregateByCurrencyQuery.isPending ||
            state.aggregateByPlayerQuery.isPending ||
            state.aggregateByHallQuery.isPending
          }
        >
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                state.filterQuery,
                state.aggregateByCurrencyQuery,
                state.aggregateByPlayerQuery,
                state.aggregateByHallQuery,
              ]}
            />
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={state.tab}
              onChange={(value) => (state.tab = value)}
            >
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Detailed" })}
                key="detailed"
              >
                <Table
                  dataSource={state.filterQuery.items}
                  showHeader={!!state.filterQuery.items.length}
                  rowKey="id"
                  size="small"
                  bordered
                  pagination={false}
                  components={{
                    body: {
                      row: (props: any) => {
                        const item = props.children?.[0]?.props?.record;
                        const type = snakeCase(item?.type);
                        return (
                          <tr
                            {...props}
                            className={classNames(
                              props.className,
                              type === "out_reserved"
                                ? "ant-table-row-color-gray"
                                : "",
                            )}
                          />
                        );
                      },
                    },
                  }}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "ID" })}
                    dataIndex="id"
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Date/time",
                    })}
                    dataIndex="createdAt"
                    render={(_, item: TFortuneWheelReportItem) =>
                      new Date(item.createdAt).toLocaleString()
                    }
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Agent" })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Shop" })}
                    dataIndex="hallName"
                    render={(hallName) => hallName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Player" })}
                    dataIndex="playerName"
                    render={(playerName) => playerName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Sector" })}
                    dataIndex="sectorId"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Amount" })}
                    render={(_, item: TFortuneWheelReportItem) => (
                      <MoneyFormatter cents={item.amount} />
                    )}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.filterQuery}
                  onChange={() => {
                    state.filter({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Currency" })}
                key="currency"
              >
                <Table
                  dataSource={state.aggregateByCurrencyQuery.items}
                  showHeader={!!state.aggregateByCurrencyQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByCurrencyQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Amount" })}
                    dataIndex="amount"
                    render={(amount: number) => (
                      <MoneyFormatter cents={amount} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByCurrencyQuery}
                  onChange={() => {
                    state.aggregateByCurrency({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Shops" })}
                key="halls"
              >
                <Table
                  dataSource={state.aggregateByHallQuery.items}
                  showHeader={!!state.aggregateByHallQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByHallQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Agent" })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Shop" })}
                    dataIndex="hallName"
                    render={(hallName) => hallName ?? "—"}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Amount" })}
                    dataIndex="amount"
                    render={(amount: number) => (
                      <MoneyFormatter cents={amount} />
                    )}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.aggregateByHallQuery}
                  onChange={() => {
                    state.aggregateByHall({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Players" })}
                key="players"
              >
                <Table
                  dataSource={state.aggregateByPlayerQuery.items}
                  showHeader={!!state.aggregateByPlayerQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByPlayerQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Agent" })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Shop" })}
                    dataIndex="hallName"
                    render={(hallName) => hallName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Player" })}
                    dataIndex="playerName"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Amount" })}
                    dataIndex="amount"
                    render={(amount: number) => (
                      <MoneyFormatter cents={amount} />
                    )}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Currency" })}
                    dataIndex="currency"
                    align="right"
                  />
                </Table>
                <Pagination
                  query={state.aggregateByPlayerQuery}
                  onChange={() => {
                    state.aggregateByPlayer({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(FortuneWheelReport);
