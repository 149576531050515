import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { CurrencyUtilities } from "@/utilities";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });

  if (
    state.hallQuery.isIdle ||
    state.hallQuery.isPending ||
    state.cashierQuery.isIdle ||
    state.cashierQuery.isPending ||
    state.shiftQuery.isIdle ||
    state.shiftQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (
    state.hallQuery.isRejected ||
    state.cashierQuery.isRejected ||
    state.shiftQuery.isRejected
  ) {
    return (
      <ErrorsFormatter
        queries={[state.hallQuery, state.cashierQuery, state.shiftQuery]}
      />
    );
  }

  return (
    <Space direction="vertical">
      <Typography.Text strong>
        {intl.formatMessage({ defaultMessage: "Financial activity" })}
      </Typography.Text>
      <Table
        rowKey="type"
        dataSource={[
          {
            type: intl.formatMessage({ defaultMessage: "Cash added" }),
            count: state.financialActivity!.putIn.count,
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.putIn.total,
            ),
          },
          {
            type: intl.formatMessage({ defaultMessage: "Cash bleed" }),
            count: state.financialActivity!.takeOut.count,
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.takeOut.total,
            ),
          },
          {
            type: intl.formatMessage({ defaultMessage: "Bill in" }),
            count: state.financialActivity!.deposit.count,
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.deposit.total,
            ),
          },
          {
            type: intl.formatMessage({ defaultMessage: "Bill out" }),
            count: state.financialActivity!.withdraw.count,
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.withdraw.total,
            ),
          },
          {
            type: intl.formatMessage({ defaultMessage: "Profit" }),
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.profit.total,
            ),
          },
          {
            type: intl.formatMessage({ defaultMessage: "Bounceback" }),
            count: state.financialActivity!.bounceback.count,
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.bounceback.total,
            ),
          },
          {
            type: intl.formatMessage({ defaultMessage: "Cashback" }),
            count: state.financialActivity!.cashback.count,
            total: CurrencyUtilities.toMainUnits(
              state.financialActivity!.cashback.total,
            ),
          },
        ]}
        columns={[
          {
            title: intl.formatMessage({ defaultMessage: "Type" }),
            dataIndex: "type",
          },
          {
            title: intl.formatMessage({ defaultMessage: "Count" }),
            dataIndex: "count",
            align: "right",
            render: (_, record) => {
              const colSpan = record.count === undefined ? 0 : 1;
              return {
                children: record.count,
                props: { colSpan },
              };
            },
          },
          {
            title: intl.formatMessage({ defaultMessage: "Total" }),
            dataIndex: "total",
            align: "right",
            render: (_, record) => {
              const colSpan = record.count === undefined ? 2 : 1;
              return {
                children: record.total,
                props: { colSpan },
              };
            },
          },
        ]}
        size="small"
        pagination={false}
        bordered={true}
      />
      <ErrorsFormatter queries={[state.finishShiftQuery]} />
      <Typography.Text strong>
        {intl.formatMessage({ defaultMessage: "Shift closing" })}
      </Typography.Text>
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Cashier" })}>
          <Input disabled value={state.cashier?.name} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Beginning cash" })}
        >
          <Input
            value={CurrencyUtilities.toMainUnits(state.shift!.beginningCash)}
            disabled
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Profit" })}>
          <Input
            value={CurrencyUtilities.toMainUnits(
              state.financialActivity!.profit.total,
            )}
            disabled
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Cash in register" })}
          validateStatus={
            has(form.formState.errors, "endingCash") ? "error" : ""
          }
          help={form.formState.errors.endingCash?.message}
        >
          <Controller
            control={form.control}
            name="endingCash"
            render={({ field }) => (
              <Input
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter cash in register",
                })}
                {...field}
                type="number"
                autoFocus
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Print receipt" })}
        >
          <Controller
            control={form.control}
            name="printReceipt"
            defaultValue={false}
            render={({ field }) => <Switch {...field} checked={field.value} />}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                state.finishShiftQuery.isPending ||
                state.userStore.cashierQuery.isPending ||
                state.userStore.shiftQuery.isPending
              }
            >
              {intl.formatMessage({
                defaultMessage: "Close shift",
              })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
