import MethodBuilder, { TMethod } from "../method-builder";
import { TDictionaryPlayer, TPaging } from "../types";

export class DictionaryAPI {
  static getPlayers = new MethodBuilder<
    {
      clientId?: number;
      agentId?: number;
      hallId?: number;
      playerId?: number;
      searchQuery?: string;
      paging: TPaging;
    },
    {
      data: TDictionaryPlayer[];
      total: number;
    }
  >()
    .withName("Dictionary.getPlayers")
    .build();

  static getPlayer: TMethod<{ id: number }, TDictionaryPlayer> = async ({
    id,
  }) => {
    const { data } = await this.getPlayers({
      playerId: id,
      paging: { offset: 0, limit: 1 },
    });
    if (!data.length) {
      throw new Error("Player not found");
    }
    return data[0];
  };
}
