import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { UserUpdateStore } from "@/stores";

import UserUpdateForm from "./user-update-form";

type TProps = {
  userUpdateStore: UserUpdateStore;
};

function UserUpdateModal({ userUpdateStore }: TProps) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Edit user" })}
      open={userUpdateStore.modal.isOpen}
      onCancel={userUpdateStore.modal.close}
      width={720}
      footer={null}
      destroyOnClose={true}
      wrapClassName="modal_with-tabs"
    >
      <UserUpdateForm userUpdateStore={userUpdateStore} />
    </Modal>
  );
}

export default observer(UserUpdateModal);
