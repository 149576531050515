import { useIntl } from "react-intl";

import { CreatePartner } from "@/events";
import { useGlobalStore } from "@/stores";

export function useSportPartnersPageState() {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  function openNewPartnerModal() {
    eventBusService.publish(new CreatePartner({}));
  }

  return { intl, openNewPartnerModal };
}

export type SportPartnersPageState = ReturnType<
  typeof useSportPartnersPageState
>;
