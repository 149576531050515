import { Button, Popover, Space } from "antd";
import { observer } from "mobx-react-lite";
import { FC, ReactNode, useCallback, useState } from "react";
import { useIntl } from "react-intl";

type TProps = {
  content?: ReactNode;
  onYes: () => void;
  onNo?: () => void;
  disabled?: boolean;
};

const AreYouSure: FC<TProps> = ({
  content,
  disabled = false,
  onYes,
  onNo,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = useCallback(
    (isVisible) => {
      if (disabled && isVisible) {
        return setIsOpen(false);
      }
      setIsOpen(isVisible);
      if (!isVisible) {
        onNo?.();
      }
    },
    [disabled, onNo],
  );

  const intl = useIntl();

  return (
    <Popover
      title={intl.formatMessage({ defaultMessage: "Are you sure?" })}
      content={
        <Space direction="vertical">
          {content}
          <Space direction="horizontal">
            <Button onClick={() => handleOpenChange(false)} size="small">
              {intl.formatMessage({ defaultMessage: "No" })}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleOpenChange(false);
                onYes();
              }}
              size="small"
            >
              {intl.formatMessage({ defaultMessage: "Yes" })}
            </Button>
          </Space>
        </Space>
      }
      trigger="click"
      open={isOpen}
      onOpenChange={handleOpenChange}
      destroyTooltipOnHide={true}
    >
      {children}
    </Popover>
  );
};

export default observer(AreYouSure);
