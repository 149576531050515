import { TProvider } from "..";
import MethodBuilder from "../method-builder";

class ProviderAPI {
  static filter = new MethodBuilder<
    (
      | { clientId?: number }
      | { hallId?: number }
      | { agentId?: number }
      | { kioskId?: number }
    ) & {
      searchQuery?: string;
    },
    {
      data: TProvider[];
      total: number;
    }
  >()
    .withName("Provider.filter")
    .build();

  static getById = new MethodBuilder<{ id: number }, TProvider>()
    .withName("Provider.getById")
    .build();
}

export default ProviderAPI;
