import { observer } from "mobx-react-lite";

import { useEditCategoryFormState } from "./form.state";
import { EditCategoryFormView } from "./form.view";

export type EditCategoryFormProps = {
  id: number;
};

export const EditCategoryForm = observer<EditCategoryFormProps>((props) => {
  const state = useEditCategoryFormState(props);
  return <EditCategoryFormView state={state} />;
});
