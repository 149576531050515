import { observer } from "mobx-react-lite";

import { useSportClubsTableState } from "./table.state";
import { SportClubsTableView } from "./table.view";

export type Props = {
  partnerId?: number;
};

export const SportClubsTable = observer<Props>((props: Props) => {
  const state = useSportClubsTableState(props);
  return <SportClubsTableView state={state} />;
});
