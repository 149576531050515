import { observer } from "mobx-react-lite";

import { useRulesFormState } from "./form.state";
import { RulesFormView } from "./form.view";

export interface RulesFormProps {
  entity: { clientId: number } | { agentId: number };
  type: "TermsAndConditions" | "SweepstakesRules";
}

export const RulesForm = observer((props: RulesFormProps) => {
  const state = useRulesFormState(props);
  return <RulesFormView state={state} />;
});
