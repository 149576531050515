import {
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { TWebsiteTemplate, WebsiteTemplateAPI } from "@/api";
import {
  OpenEditWebsiteTemplateModal,
  OpenNewWebsiteTemplateModal,
  WebsiteTemplateCreated,
  WebsiteTemplateEdited,
} from "@/events";
import { useExportHandler, useImportHandler } from "@/hooks";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditWebsiteTemplateModal } from "./edit";
import { NewWebsiteTemplateModal } from "./new";

function WebsiteTemplates() {
  const { eventBusService } = useGlobalStore();

  const [getAllQuery] = useState(() => new Query(WebsiteTemplateAPI.getAll));
  const [importQuery] = useState(() => new Query(WebsiteTemplateAPI.import));
  const [exportQuery] = useState(() => new Query(WebsiteTemplateAPI.export));

  const handleImportClick = useImportHandler({ getAllQuery, importQuery });

  const handleExportClick = useExportHandler({
    exportQuery,
    fileName: "websites-templates.csv",
  });

  const handleCreateClick = () => {
    eventBusService.publish(new OpenNewWebsiteTemplateModal({}));
  };

  useEffect(() => {
    getAllQuery.submit();

    const listener = () => getAllQuery.submit();

    eventBusService.subscribe(WebsiteTemplateCreated, listener);
    eventBusService.subscribe(WebsiteTemplateEdited, listener);

    return () => {
      eventBusService.unsubscribe(WebsiteTemplateCreated, listener);
      eventBusService.unsubscribe(WebsiteTemplateEdited, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllQuery]);

  return (
    <Space direction="vertical">
      <NewWebsiteTemplateModal />
      <EditWebsiteTemplateModal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              loading={importQuery.isPending}
              onClick={handleImportClick}
            >
              {"Import"}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<UploadOutlined />}
              loading={exportQuery.isPending}
              onClick={handleExportClick}
            >
              {"Export"}
            </Button>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Button icon={<PlusOutlined />} onClick={handleCreateClick}>
              {"Add template"}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Table
          loading={getAllQuery.isPending}
          dataSource={getAllQuery.isFulfilled ? getAllQuery.data.data : []}
          pagination={false}
          size="small"
          rowKey="id"
        >
          <Table.Column title={"ID"} dataIndex="id" />
          <Table.Column title={"Name"} dataIndex="name" width="100%" />
          <Table.Column
            title={"Actions"}
            key="actions"
            render={(_, item: TWebsiteTemplate) => (
              <Button
                size="small"
                icon={<EditOutlined />}
                title={"Edit"}
                onClick={() => {
                  eventBusService.publish(
                    new OpenEditWebsiteTemplateModal({ id: item.id }),
                  );
                }}
              />
            )}
          />
        </Table>
      </Card>
    </Space>
  );
}

export default observer(WebsiteTemplates);
