import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import {
  CloseSportReportDetailsModal,
  OpenSportReportDetailsModal,
} from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { DetailsForm } from "./DetailsForm";

export const DetailsModal = observer(() => {
  const intl = useIntl();
  return (
    <EventBasedModal
      openEvent={OpenSportReportDetailsModal}
      closeEvent={CloseSportReportDetailsModal}
      title={intl.formatMessage({ defaultMessage: "Details" })}
      width={960}
    >
      {({ item }) => <DetailsForm item={item} />}
    </EventBasedModal>
  );
});
