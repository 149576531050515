import { PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Typography,
  Space,
  Input,
} from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  CreatePlayer,
  CreateVoucher,
  DepositCashRegister,
  OpenImportPlayersModal,
  StartShift,
} from "@/events";
import { PlayersStore, useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { GeneralSelectorErrors } from "@/ui/_common_/general-selector-errors";
import { InfoIcon } from "@/ui/_common_/icons";
import Page from "@/ui/_common_/page";
import Selector from "@/ui/_common_/selector";
import CurrentBonus from "@/ui/players/withdraw/modal-bonuses/index";

import { CreateVoucherModal } from "../vouchers";

import DepositBalance from "./deposit";
import { ExchangeBalance } from "./exchange";
import { ImportPlayersModal } from "./import";
import { PlayerCreateModal } from "./player-create-modal";
import { PlayerTokenModal } from "./player-token-modal/player-token-modal";
import { PlayerUpdateModal } from "./player-update-modal";
import PlayersTable from "./players-table";
import WithdrawBalance from "./withdraw";

function Players() {
  const globalStore = useGlobalStore();
  const [playersStore] = useState(() => new PlayersStore(globalStore));
  const intl = useIntl();
  const { eventBusService, permissionsStore, userStore } = globalStore;
  const { cashier, shift } = userStore;
  const isActionsDisabled =
    cashier?.isShiftModeEnabled && !cashier.isShiftStarted;

  const {
    clientSelectorStore,
    agentSelectorStore,
    subagentSelectorStore,
    hallSelectorStore,
  } = playersStore.generalSelectorsStore;

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: "Players" })}
      isContentLoading={!playersStore.generalSelectorsStore.isReady}
    >
      <DepositBalance.Modal />
      <WithdrawBalance.Modal />
      <ExchangeBalance.Modal />
      <CurrentBonus.Modal />
      <PlayerCreateModal />
      <PlayerUpdateModal />
      <CreateVoucherModal />
      <PlayerTokenModal state={playersStore.playerTokenModalState} />
      <ImportPlayersModal />

      {isActionsDisabled && (
        <Alert
          type="warning"
          showIcon
          icon={<InfoIcon style={{ width: 20 }} />}
          description={
            <FormattedMessage
              defaultMessage="Please <a>open the shift</a> to create player or deposit/withdraw a player's balance."
              values={{
                a: (text) => (
                  <Typography.Link
                    onClick={() => {
                      eventBusService.publish(
                        new StartShift({ cashierId: cashier.id }),
                      );
                    }}
                  >
                    {text}
                  </Typography.Link>
                ),
              }}
            />
          }
        />
      )}

      {cashier?.isShiftStarted && shift?.cash === 0 && (
        <Alert
          type="warning"
          showIcon
          icon={<InfoIcon style={{ width: 20 }} />}
          description={
            <FormattedMessage
              defaultMessage="Please <a>deposit cash register</a> to deposit/withdraw a player's balance."
              values={{
                a: (text) => (
                  <Typography.Link
                    onClick={() => {
                      eventBusService.publish(
                        new DepositCashRegister({ cashierId: cashier.id }),
                      );
                    }}
                  >
                    {text}
                  </Typography.Link>
                ),
              }}
            />
          }
        />
      )}

      {(permissionsStore.has("SelectClient") ||
        permissionsStore.has("SelectAgent") ||
        permissionsStore.has("SelectSubagent") ||
        permissionsStore.has("SelectHall")) && (
        <Card size="small">
          <Row gutter={[12, 12]} wrap={true}>
            {permissionsStore.has("SelectClient") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={clientSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select client",
                  })}
                />
              </Col>
            )}
            {permissionsStore.has("SelectAgent") &&
              !!clientSelectorStore.selectedId && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
            {permissionsStore.has("SelectSubagent") && (
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={subagentSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select subagent",
                  })}
                />
              </Col>
            )}
            {permissionsStore.has("SelectHall") &&
              (clientSelectorStore.selectedId ||
                agentSelectorStore.selectedId) && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
          </Row>
        </Card>
      )}
      {!!clientSelectorStore.selectedId && (
        <Card size="small">
          <Row gutter={[16, 16]} wrap={true}>
            {permissionsStore.has("ViewDeletedPlayers") && (
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  onChange={(event) => {
                    playersStore.includeDeleted = event.target.checked;
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Include deleted" })}
                </Checkbox>
              </Col>
            )}
            <Col
              xs={24}
              sm={10}
              md={8}
              lg={6}
              xl={4}
              style={{ marginLeft: "auto" }}
            >
              <Input.Search
                placeholder={intl.formatMessage({
                  defaultMessage: "Player ID or username",
                })}
                allowClear
                defaultValue={playersStore.filterQuery.searchQuery}
                onSearch={(searchQuery) => {
                  playersStore.filterQuery.setSearchQuery(searchQuery);
                  playersStore.filter();
                }}
              />
            </Col>
            {!!hallSelectorStore.selectedId && (
              <Col>
                <Button
                  onClick={() => {
                    eventBusService.publish(
                      new OpenImportPlayersModal({
                        hallId: hallSelectorStore.selectedId!,
                      }),
                    );
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Import players" })}
                </Button>
              </Col>
            )}
            {hallSelectorStore.selectedId !== undefined && (
              <Col>
                <Button
                  onClick={() => {
                    eventBusService.publish(
                      new CreateVoucher({
                        hallId: hallSelectorStore.selectedId!,
                      }),
                    );
                  }}
                  icon={<PlusOutlined />}
                  disabled={isActionsDisabled}
                >
                  {intl.formatMessage({ defaultMessage: "Create Voucher" })}
                </Button>
              </Col>
            )}
            {!!hallSelectorStore.selectedId && (
              <Col>
                <Button
                  onClick={() => {
                    const hallId = hallSelectorStore.selectedId!;
                    eventBusService.publish(new CreatePlayer({ hallId }));
                  }}
                  icon={<PlusOutlined />}
                  disabled={isActionsDisabled}
                >
                  {intl.formatMessage({ defaultMessage: "Create" })}
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      )}
      <Card size="small">
        <Space direction="vertical">
          <GeneralSelectorErrors store={playersStore.generalSelectorsStore} />
          <ErrorsFormatter queries={[playersStore.filterQuery]} />
          <PlayersTable playersStore={playersStore} />
        </Space>
      </Card>
    </Page>
  );
}

export default observer(Players);
