import { Empty, Space, Spin, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useIntl } from "react-intl";

import { ErrorHandler } from "@/ui/_common_/error-handler";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { isTruthy } from "@/utilities";

import { Form as CommonForm } from "../common-form";
import { DailyLoginRewardsForm } from "../daily-login-rewards";
import { Form } from "../form";
import { FortuneWheelSettings } from "../fortune-wheel-settings";

import { ListState } from "./ListState";

type Props = { state: ListState };

export const ListView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();

  if (state.isRejected) {
    return (
      <ErrorsFormatter
        style={{ padding: 16 }}
        queries={[
          state.hallQuery,
          state.bonusesQuery,
          state.parentBonusesQuery,
          state.bonusTemplatesQuery,
          state.parentFortuneWheelQuery,
          state.fortuneWheelQuery,
        ]}
      />
    );
  }

  if (!state.isReady) {
    return <FormSpinner />;
  }

  if (
    !state.hasAccessToCashbackBonus &&
    !state.hasAccessToBouncebackBonus &&
    !state.hasAccessToLastchanceBonus &&
    !state.hasAccessToFortuneWheel &&
    !state.hasAccessToDailyLoginRewards
  ) {
    return <Empty style={{ padding: 24 }} />;
  }

  return (
    <Space direction="vertical">
      <Tabs
        type="line"
        size="small"
        destroyInactiveTabPane
        items={[
          state.isCommonTabVisible && {
            key: "common",
            label: intl.formatMessage({ defaultMessage: "Common" }),
            children: (
              <CommonForm entity={state.entity} readOnly={state.readOnly} />
            ),
          },
          state.hasAccessToCashbackBonus && {
            key: "cashback",
            label: intl.formatMessage({ defaultMessage: "Cashback" }),
            children: (
              <Form
                entity={state.entity}
                readOnly={state.readOnly}
                selfEdit={state.selfEdit}
                bonusType="cashback"
                bonus={state.cashbackBonus}
                parentBonus={state.parentCashbackBonus}
                templates={state.cashbackTemplates}
              />
            ),
          },
          state.hasAccessToBouncebackBonus && {
            key: "bounceback",
            label: intl.formatMessage({ defaultMessage: "Bounceback" }),
            children: (
              <Form
                entity={state.entity}
                readOnly={state.readOnly}
                selfEdit={state.selfEdit}
                bonusType="bounceback"
                bonus={state.bouncebackBonus}
                parentBonus={state.parentBouncebackBonus}
                templates={state.bouncebackTemplates}
              />
            ),
          },
          state.hasAccessToLastchanceBonus && {
            key: "lastchance",
            label: intl.formatMessage({ defaultMessage: "Lastchance" }),
            children: (
              <Form
                entity={state.entity}
                readOnly={state.readOnly}
                selfEdit={state.selfEdit}
                bonusType="lastchance"
                bonus={state.lastchanceBonus}
                parentBonus={state.parentLastchanceBonus}
                templates={state.lastchanceTemplates}
              />
            ),
          },
          state.hasAccessToFortuneWheel && {
            key: "fortune-wheel",
            label: intl.formatMessage({ defaultMessage: "Wheel of Fortune" }),
            children: (
              <FortuneWheelSettings
                entity={state.entity}
                readOnly={state.readOnly}
                selfEdit={state.selfEdit}
              />
            ),
          },
          state.hasAccessToDailyLoginRewards && {
            key: "daily-login-rewards",
            label: intl.formatMessage({
              defaultMessage: "Daily Login Rewards",
            }),
            children: (
              <DailyLoginRewardsForm hallId={state.hallQuery.data!.id} />
            ),
          },
        ]
          .filter(isTruthy)
          .map(({ children, ...tab }) => ({
            ...tab,
            children: (
              <ErrorHandler>
                <Spin spinning={state.isPending}>{children}</Spin>
              </ErrorHandler>
            ),
          }))}
      />
    </Space>
  );
});
