import { defineMessage } from "react-intl";

export const validationErrors = {
  mixed: {
    notType: defineMessage({
      id: "validation_errors/mixed/not_type",
      defaultMessage: "The field is invalid.",
    }),
    required: defineMessage({
      id: "validation_errors/mixed/required",
      defaultMessage: "The field is required.",
    }),
  },
  string: {
    min: defineMessage({
      id: "validation_errors/string/min",
      defaultMessage: "The field must be at least {min} characters.",
    }),
    max: defineMessage({
      id: "validation_errors/string/max",
      defaultMessage: "The field must be at most {max} characters.",
    }),
    email: defineMessage({
      id: "validation_errors/string/email",
      defaultMessage: "The field must be a valid email.",
    }),
    url: defineMessage({
      id: "validation_errors/string/url",
      defaultMessage: "The field must be a valid URL.",
    }),
    slug: defineMessage({
      id: "validation_errors/string/slug",
      defaultMessage:
        "The field must be a valid slug. Use only lowercase letters, numbers, and hyphens.",
    }),
    filePath: defineMessage({
      id: "validation_errors/string/file_path",
      defaultMessage:
        "The field must be a valid file path. Use only lowercase letters, numbers, underscores, and slashes.",
    }),
    pairingCode: defineMessage({
      id: "validation_errors/string/pairing_code",
      defaultMessage: 'The pairing code must match the following: "XXXX-XXXX".',
    }),
    cvv: defineMessage({
      id: "validation_errors/string/cvv",
      defaultMessage: "The CVV must be exactly 3 digits.",
    }),
    pin: defineMessage({
      id: "validation_errors/string/pin",
      defaultMessage: "The PIN must be exactly 10 digits.",
    }),
  },
  number: {
    min: defineMessage({
      id: "validation_errors/number/min",
      defaultMessage: "The field must be greater than or equal to {min}.",
    }),
    max: defineMessage({
      id: "validation_errors/number/max",
      defaultMessage: "The field must be less than or equal to {max}.",
    }),
    lessThan: defineMessage({
      id: "validation_errors/number/less_than",
      defaultMessage: "The field must be less than {less}.",
    }),
    moreThan: defineMessage({
      id: "validation_errors/number/more_than",
      defaultMessage: "The field must be greater than {more}.",
    }),
    integer: defineMessage({
      id: "validation_errors/number/integer",
      defaultMessage: "The field must be an integer.",
    }),
    positive: defineMessage({
      id: "validation_errors/number/positive",
      defaultMessage: "The field must be a positive number.",
    }),
    // custom
    notType: defineMessage({
      id: "validation_errors/number/not_type",
      defaultMessage: "The field must be a number.",
    }),
    money: defineMessage({
      id: "validation_errors/number/money",
      defaultMessage: "The field must be a valid amount of money.",
    }),
  },
};
