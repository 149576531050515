import { useMemo } from "react";

import { useGlobalStore } from "@/stores";

export function useCurrencyFilters() {
  const { currencyStore } = useGlobalStore();
  const filters = useMemo(
    () =>
      currencyStore.currencies.map((currency) => ({
        text: currency.code,
        value: currency.code,
      })),
    [currencyStore.currencies],
  );
  return filters;
}
