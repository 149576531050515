import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Radio, Row, Space } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useMoneyFormatter, useResetForm } from "@/hooks";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { CurrencyUtilities } from "@/utilities";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);
  const form = useForm<ISchema>({ resolver });
  const { formatMoney } = useMoneyFormatter();
  useResetForm(state.exchangeQuery, form.reset);

  useEffect(() => {
    form.reset({
      type:
        state.operationType === "entries-to-total-win"
          ? "entriesToWin"
          : "winToEntries",
    });
  }, [form, state.operationType]);

  if (state.isIdleOrPending) {
    return <FormSpinner />;
  }

  if (state.isRejected) {
    return (
      <ErrorsFormatter queries={[state.playerQuery, state.sweepstakeQuery]} />
    );
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.exchangeQuery]} />
      <Form
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Player" })}>
          <Input value={state.player.login} readOnly />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Type" })}>
          <Controller
            control={form.control}
            name="type"
            defaultValue="entriesToWin"
            render={({ field }) => (
              <Radio.Group {...field}>
                <Radio.Button value="entriesToWin">
                  {intl.formatMessage({ defaultMessage: "Entries to Win" })}
                </Radio.Button>
                <Radio.Button value="winToEntries">
                  {intl.formatMessage({ defaultMessage: "Win to Entries" })}
                </Radio.Button>
              </Radio.Group>
            )}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Entries" })}>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() =>
              form.setValue(
                "amount",
                CurrencyUtilities.toMainUnits(
                  state.player.sweepstake?.entries ?? 0,
                ),
              )
            }
          >
            {formatMoney(state.player.sweepstake?.entries ?? 0, undefined, 0)}
          </Button>
          {" (" +
            formatMoney(
              Number.parseInt(
                String(
                  (state.player.sweepstake?.entries ?? 0) /
                    state.sweepstake.multiplier,
                ),
                10,
              ),
            ) +
            ")"}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Total win" })}>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() =>
              form.setValue(
                "amount",
                CurrencyUtilities.toMainUnits(
                  state.player.sweepstake?.win ?? 0,
                ),
              )
            }
          >
            {formatMoney(state.player.sweepstake?.win ?? 0)}
          </Button>
          {" (" +
            formatMoney(
              Number.parseInt(
                String(
                  (state.player.sweepstake?.win ?? 0) *
                    state.sweepstake.multiplier,
                ),
                10,
              ),
              undefined,
              0,
            ) +
            ")"}
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Amount" })}
          validateStatus={has(form.formState.errors, "amount") ? "error" : ""}
          help={form.formState.errors.amount?.message}
        >
          <Controller
            control={form.control}
            name="amount"
            render={({ field }) => (
              <Input
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter amount",
                })}
                {...field}
                autoFocus
              />
            )}
          />
        </Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.isExchangePending}
            >
              {intl.formatMessage({ defaultMessage: "Exchange" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
