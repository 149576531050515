import { useMemo, useEffect } from "react";
import { useIntl } from "react-intl";

import { SportPartnerAPI } from "@/api";
import { EditPartner, PartnerCreated, PartnerEdited } from "@/events";
import ItemsQuery from "@/models/items-query";
import { useGlobalStore } from "@/stores";

export function useSportPartnersTableState() {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const query = useMemo(() => {
    const query = new ItemsQuery(SportPartnerAPI.filter, {
      isOrderEnabled: false,
      isPaginationEnabled: true,
      isSearchEnabled: true,
    });
    query.submit({});
    return query;
  }, []);

  useEffect(() => {
    const listener = () => {
      query.submit({});
    };

    eventBusService.subscribe(PartnerCreated, listener);
    eventBusService.subscribe(PartnerEdited, listener);

    return () => {
      eventBusService.unsubscribe(PartnerCreated, listener);
      eventBusService.unsubscribe(PartnerEdited, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openEditPartnerModal(id: number) {
    eventBusService.publish(new EditPartner({ id }));
  }

  return {
    intl,
    query,

    openEditPartnerModal,
  };
}

export type SportPartnersTableState = ReturnType<
  typeof useSportPartnersTableState
>;
