import { makeAutoObservable } from "mobx";

import { PlayerGenerateFormState } from "./player-generate-form.state";

type Params = {
  onGenerate: () => void;
};

export class PlayerGenerateModalState {
  public readonly onGenerate: () => void;

  constructor({ onGenerate }: Params) {
    makeAutoObservable(this);

    this.onGenerate = onGenerate;
  }

  private _isOpen = false;

  public get isOpen() {
    return this._isOpen;
  }

  generateFormState?: PlayerGenerateFormState;

  open = (hallId: number) => {
    this.generateFormState = new PlayerGenerateFormState({
      onGenerate: this.onGenerate,
      onCancel: this.close,
      hallId,
    });
    this._isOpen = true;
  };

  close = () => {
    this._isOpen = false;
    this.generateFormState = undefined;
  };
}
