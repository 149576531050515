import { notification } from "antd";
import { omit } from "lodash-es";
import { makeAutoObservable } from "mobx";

import {
  JackpotAPI,
  JackpotSlotTemplateAPI,
  JackpotTemplateAPI,
  TJackpot,
  TJackpotSlots,
} from "@/api";
import Query from "@/models/query";
import { TJackpotUpdateSchema } from "@/schemas";
import { IntlService } from "@/services";
import { CurrencyUtilities } from "@/utilities";

import { TEntity } from "./jackpot-update-form";

export class JackpotUpdateStore {
  constructor(
    private _entity: TEntity,
    private _jackpot: TJackpot,
    private onRefresh: () => void,
    private intlService: IntlService,
  ) {
    makeAutoObservable(this);
  }

  get intl() {
    return this.intlService.intl;
  }

  get entity() {
    return this._entity;
  }

  setEntity(entity: TEntity) {
    this._entity = entity;
  }

  get jackpot() {
    return this._jackpot;
  }

  setJackpot(jackpot: TJackpot) {
    this._jackpot = jackpot;
  }

  updateQuery = new Query(JackpotAPI.update);
  syncQuery = new Query(JackpotAPI.sync);
  resetQuery = new Query(JackpotAPI.reset);
  jackpotTemplatesQuery = new Query(JackpotTemplateAPI.getAll);
  jackpotSlotTemplatesQuery = new Query(JackpotSlotTemplateAPI.getAll);

  get jackpotTemplates() {
    return this.jackpotTemplatesQuery.isFulfilled
      ? this.jackpotTemplatesQuery.data.data
      : [];
  }

  get jackpotSlotTemplates() {
    return this.jackpotSlotTemplatesQuery.isFulfilled
      ? this.jackpotSlotTemplatesQuery.data.data
      : [];
  }

  fetchTemplates = async () => {
    await Promise.all([
      this.jackpotTemplatesQuery.submit({}),
      this.jackpotSlotTemplatesQuery.submit(),
    ]);
  };

  update = async (values: TJackpotUpdateSchema) => {
    await this.updateQuery.submit({
      ...this.entity,
      ...values,
      expectedMonthlyBetSum:
        values.expectedMonthlyBetSum !== undefined
          ? CurrencyUtilities.toMinorUnits(values.expectedMonthlyBetSum)
          : undefined,
      slots: (values.slots?.map((slot) => ({
        ...omit(slot, "value"),
        minValue: CurrencyUtilities.toMinorUnits(slot.minValue),
        maxValue: CurrencyUtilities.toMinorUnits(slot.maxValue),
        defaultValue: CurrencyUtilities.toMinorUnits(slot.defaultValue),
      })) ?? []) as TJackpotSlots,
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The jackpot settings have been successfully updated.",
      }),
    });

    this.onRefresh();
  };

  reset = async (slotIds: number[]) => {
    if (!("hallId" in this.entity)) {
      throw new Error("The entity is not hall.");
    }

    await this.resetQuery.submit({
      ...this.entity,
      id: this.jackpot.id,
      slotIds,
    });

    if (!this.resetQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The jackpot values have been successfully reset.",
      }),
    });

    this.onRefresh();
  };

  resetAll = async () => {
    this.reset([0, 1, 2, 3]);
  };

  sync = async () => {
    await this.syncQuery.submit({
      ...this.entity,
      id: this.jackpot.id,
    });

    if (!this.syncQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intl.formatMessage({
        defaultMessage: "The jackpot values have been synced successfully.",
      }),
    });

    this.onRefresh();
  };
}
